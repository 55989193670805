import { useNavigate } from 'react-router-dom';
import cx from './index.module.less';

type Props = {
  isEmpty?: boolean;
  // 行业
  industryId?: number;
  // 我要 ...
  want?: string;
  // 做的任务描述
  industryName?: string;
  icon?: string;
  color?: string;
};

// 未来有需要可以抽出去
const TemplateCreateCard: React.FC<Props> = ({ isEmpty, industryId, want, industryName, icon, color }) => {
  // const history = useHistory();
  const navigete = useNavigate();

  const handleCheckTpl = () => {
    // industryId 根据id跳转
    navigete(industryId !== undefined ? `/template?industry=${industryId}` : '/template');
  };

  return (
    <div className={cx('tpl-card')} style={{ borderColor: color }} onClick={handleCheckTpl}>
      {isEmpty ? (
        <div className={cx('more')}>探索更多模板</div>
      ) : (
        <div>
          <div className={cx('want')}>{want}</div>
          <div className={cx('desc')}>{industryName}</div>
          <div className={cx('turn-to')}>查看模板 &gt;</div>
          <img className={cx('img')} src={icon} alt="" />
        </div>
      )}
    </div>
  );
};

export default TemplateCreateCard;

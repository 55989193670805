/* eslint-disable no-lone-blocks */
import React, { useEffect, useRef, forwardRef, useImperativeHandle, FC } from 'react';
import {
  List as _List,
  AutoSizer as _AutoSizer,
  CellMeasurer as _CellMeasurer,
  ListProps,
  AutoSizerProps,
  CellMeasurerProps,
} from 'react-virtualized';
import { Empty } from '@fuxi/eevee-ui';
import { useDebounceFn } from '@fuxi/eevee-hooks';
import { LogItem } from '@/store/realtimeLogs';

const List = _List as unknown as FC<ListProps>;
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;
const CellMeasurer = _CellMeasurer as unknown as FC<CellMeasurerProps>;

interface Prop<T> {
  // 渲染区域的高度
  containerHeight: number;
  // 要渲染的数据列表, 如LogItem[]
  list: T[];
  // list每行的渲染方法
  rowRender: (rowData: T & { index: number }) => JSX.Element | null;
  // 渲染区域的render回调
  onRowsRendered?: (renderProps: {
    overscanStartIndex: number;
    overscanStopIndex: number;
    startIndex: number;
    stopIndex: number;
  }) => void;
  onMousewheel?: (args: { scrollTop: number; scrollHeight: number }) => void;
  cache?: any;
  lastEarliestHistoryLog?: { log: LogItem };
  forceUpdate?: string | null;
  scrollToAlignment?: string;
  onListScroll?: () => void;
}

export const VirtualList = forwardRef((props: Prop<LogItem>, ref) => {
  {
    const {
      list,
      containerHeight,
      onMousewheel,
      onListScroll,
      rowRender,
      cache,
      lastEarliestHistoryLog,
      forceUpdate,
      scrollToAlignment = 'auto',
    } = props;
    const listRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      reRenderLogItem(index: number) {
        cache.clear(index);
        listRef?.current?.recomputeRowHeights(index);
      },
      scrollToRow(index: number) {
        listRef?.current?.scrollToRow(index);
      },
      getIsOverflow() {
        const realHeight = document.getElementById('virtualList')?.children?.[0]?.clientHeight || 0;
        return realHeight >= listRef?.current?.props?.height;
      },
    }));

    const { run } = useDebounceFn<(args: { scrollTop: number; scrollHeight: number }) => void>(
      args => {
        onMousewheel && onMousewheel(args);
      },
      {
        wait: 500,
      }
    );

    useEffect(() => {
      lastEarliestHistoryLog &&
        listRef?.current?.scrollToRow(list?.findIndex(item => item.uid === lastEarliestHistoryLog?.log?.uid));
    }, [lastEarliestHistoryLog]);

    useEffect(() => {
      if (forceUpdate) {
        console.log('虚拟列表重新渲染');
        listRef?.current.forceUpdateGrid();
        cache.clearAll();
      }
    }, [forceUpdate]);

    const rowRendererInCell = ({ index, key, style, parent }: any) => {
      return (
        <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
          <div
            style={{
              ...style,
              overflow: 'hidden',
            }}
            className="row">
            {rowRender({
              index,
              ...list[index],
            })}
          </div>
        </CellMeasurer>
      );
    };

    const onScroll = (e) => {
      run(e);
      onListScroll?.();
    }

    return (
      <div>
        <AutoSizer disableHeight>
          {({ width, height }) => (
            <List
              id="virtualList"
              noRowsRenderer={() => <Empty style={{ marginTop: 20 }} />}
              ref={listRef}
              overscanRowCount={30}
              deferredMeasurementCache={cache}
              onRowsRendered={props.onRowsRendered}
              width={width}
              height={containerHeight}
              rowCount={list.length}
              rowHeight={cache.rowHeight}
              rowRenderer={rowRendererInCell}
              onScroll={onScroll}
              scrollToAlignment={scrollToAlignment}
            />
          )}
        </AutoSizer>
      </div>
    );
  }
});

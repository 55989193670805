import React from 'react';
import { Row, Space } from '@fuxi/eevee-ui';
import creatorImg from '@/assets/image/creator.png';
import ProjectContainer from '../ProjectContainer';
import cx from './index.module.less';

const ProjectCreator: React.FC = () => {
  const container = ProjectContainer.useContainer();
  const { setShowCreateEntry } = container;
  return (
    <div className={cx('creator-container')}>
      <div className={cx('content')} onClick={() => setShowCreateEntry(true)}>
        <Space>
          <img alt="创建" src={creatorImg} />
          <div className={cx('text')}>
            <Row className={cx('title')}>新建项目</Row>
            <Row className={cx('sub-text')}>可视化开发你的项目</Row>
          </div>
          +
        </Space>
      </div>
    </div>
  );
};

export default ProjectCreator;

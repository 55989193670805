import Axios from './common';
import { ShanquanInstance } from './common';

const model = {
  //获取模型库列表
  getModelBucketId<T = any>(params) {
    return Axios.get<T>(`/api/v1/model/bucket`, params);
  },
  //获取模型库bucket_id
  getModels<T = any>(params) {
    return Axios.get<T>(`/api/v1/models`, params);
  },
  // 1. 模型文件上传，获取上传uuid
  getModelUploadUuid<T = any>() {
    return ShanquanInstance.get<T>(`/model/upload/uuid`);
  },
  // 2. 获取单个文件id
  getModelFileId<T = any>(params: {
    upload_uuid: string;
    filename: string;
  }) {
    return ShanquanInstance.get<T>(`/model/upload/id`, { params });
  },


  generateModelVersion<T = any>(params: {
    upload_uuid: string;
    name: string;
    format_id: number;
  }) {
    return ShanquanInstance.post<T>(`/model/version`, params);
  },

  //获取模型框架信息
  getModelFrameworkInfo(): Promise<any> {
    return ShanquanInstance.get<any>(`/model/frameworks`);
  },
  //获取模型框架版本信息
  getModelFrameworkVersionInfo(params): Promise<any> {
    return ShanquanInstance.get<any>(`/model/framework/${params?.framework}/versions`);
  },
  //获取模型框架版本存储格式信息
  getModelFrameworkVersionFormatInfo(params): Promise<any> {
    return ShanquanInstance.get<any>(
      `/model/framework/${params?.framework}/version/${params?.frameworkVersion}/formats`
    );
  },

};
export default model;

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAntdTable, useRequest } from '@fuxi/eevee-hooks';
import { Card, Flex, Table, Divider, Tabs, message, Radio } from '@fuxi/eevee-ui';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  setFormDataForVersionOfTrain,
  setFormDataForBaseOfTrain,
  setFormDataForConfigOfTrain,
  setFormDataForEvaluateOfTrain,
  setFormDataForInferenceOfTrain,
  setFormDataForModelServiceOfTrain,
  setFormDataExtra,
} from '@/store/aiTraining';

import { VersionBaseFieldNames } from './components/VersionBaseConfig';
import service from '../../service';
import EditText from '../../components/EditText';
import InputOutput from '../../components/InputOutput';
import { ProjectVersionConfigInfo } from './components/ProjectVersionConfigInfo';
import { getSorterTableData } from '../../utils/util';
import { VersionDetailInfo } from '../../types/ability';
import { getVersionDetailColumns, versionDetailInfoColumns } from './const';
import Descriptions, { DescriptionsItem } from '../../components/Descriptions';
import cx from './index.module.less';

const VersionDetail: React.FC = () => {
  const urlParams = useParams();
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const [detail, setDetail] = useState<VersionDetailInfo | null>(null);

  useRequest(
    () =>
      service.ability.getVersionsDetails({
        capability_uuid: urlParams.abilityId,
        capability_version_uuid: urlParams.versionId,
      }),
    {
      onSuccess: async data => {
        const { capability_total = {}, extra = {} } = data.data;

        const {
          capabilityVersion = {},
          capabilityVersionBase = {},
          capabilityVersionEvaluate = {},
          capabilityVersionService = {},
          capabilityVersionTrain = {},
          capabilityVersionModelService = {},
        } = capability_total;
        // 查询下模型的具体信息

        // 更新下表单的数据
        dispatch(setFormDataForVersionOfTrain(capabilityVersion));
        dispatch(setFormDataForBaseOfTrain(capabilityVersionBase));
        dispatch(setFormDataForConfigOfTrain(capabilityVersionTrain));
        dispatch(setFormDataForEvaluateOfTrain(capabilityVersionEvaluate));
        dispatch(setFormDataForInferenceOfTrain(capabilityVersionService));
        dispatch(setFormDataForModelServiceOfTrain(capabilityVersionModelService));
        dispatch(setFormDataExtra(extra));

        // 获取上次上传的ddl文件信息
        let base_dataset = {};
        if (capabilityVersionBase?.[VersionBaseFieldNames.DdlId] && extra?.dataset?.dataset_type === 'webdataset') {
          await service.ability.getDdlParserData(capabilityVersionBase?.[VersionBaseFieldNames.DdlId]).then(ddlRes => {
            if (ddlRes.status === 200) {
              const input_output = Object.entries(ddlRes.data.ddl).reduce((acc, [key, value]) => {
                acc[value] = key;
                return acc;
              }, {});

              base_dataset = {
                file_name: ddlRes?.data?.file_name,
                input: input_output?.[extra.dataset.input],
                output: input_output?.[extra.dataset.output],
              };
            }
          });
        } else {
        }

        setDetail({ ...data.data, base_dataset });
      },
    }
  );
  const { tableProps, refresh } = useAntdTable(
    getSorterTableData(service.ability.getVersionsDatasets, {
      extraParams: { abilityId: urlParams.abilityId, id: urlParams.versionId },
    })
  );
  const handelSave = async (name, val) => {
    try {
      await service.dataSet.editDatasets({ id: urlParams.versionId, [name]: val });
      message.success('保存成功');
      detail && setDetail({ ...detail, [name]: val });
    } catch (e) {}
  };
  // if (detail?.data) {
  //   detail.data.id = urlParams.versionId;
  // }

  return (
    <Flex column className={cx['detail-page-wrapper']}>
      <Card title="版本详情" bordered={false} className={cx['detail-head']}>
        <Descriptions column={3} items={versionDetailInfoColumns} data={detail || {}}></Descriptions>
      </Card>
      <Divider className={cx['detail-divider']} />
      <div className={cx['detail-body-tab']}>
        <Tabs
          tabBarStyle={{ marginBottom: 24 }}
          items={[
            {
              key: 'VersionConfigInfo',
              label: '版本配置信息',
              children: <ProjectVersionConfigInfo />,
            },
            {
              key: 'InputOutput',
              label: '输入输出示例',
              children: <InputOutput input={detail?.inputs} output={detail?.outputs} />,
            },
            {
              key: 'DataSet',
              label: '训练数据集',
              children: (
                <Table
                  columns={getVersionDetailColumns({ refresh, history })}
                  {...tableProps}
                  rowKey="id"
                  pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
                />
              ),
            },
          ]}
        />
      </div>
    </Flex>
  );
};

export default VersionDetail;

import bindClass from 'classnames/bind';
import React, { forwardRef, useImperativeHandle, useRef, useEffect, ReactNode } from 'react';
import {
  Switch,
  Form,
  Row,
  Typography,
  Layout,
  message,
  Radio,
  InputNumber,
  InputNumberProps,
} from '@fuxi/eevee-ui';
import { useParams, useSearchParams } from 'react-router-dom';

import { CurrentVersionStatus, FieldNamesForTrain, VersionStatus } from '@ai-training/constants/ability';

import { TrainFormDataConfig, FormValues } from '@ai-training/types/ability';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  selectIsEnableInferenceOfTrain,
  setIsEnableInferenceOfTrain,
  selectCurrentVersionStatus,
  selectFormDataForInferenceOfTrain,
  setFieldsForInferenceOfTrain,
  selectFormDataForModelServiceOfTrain,
  selectBaseCapability,
  selectFormDataForBaseOfTrain,
} from '@/store/aiTraining';
import { useAppSelector } from '@/hooks/useAppSelector';

import RuntimeConfigOfForm, { rightAlignedFormItemLayout } from '../RuntimeConfigOfForm';

import { convertOriginFormDataToValues, isFormDataEmpty } from '../NewAIBodyVersion';
import styles from './index.module.less';
import { useRequest } from '@fuxi/eevee-hooks';
import ability from '@ai-training/service/ability';

const { Text } = Typography;
const cx = bindClass.bind(styles);

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 21 },
};

type InferenceOfTrainProps = {
  children: React.ReactNode;
};

export const getFieldsForInferenceOfTrain = formDataForInferenceOfTrain => {
  // format the data to display
  const fields = convertOriginFormDataToValues(formDataForInferenceOfTrain);
  return {
    ...fields,
  };
};

export const getFieldsForModelServiceOfTrain = formDataForModelServiceOfTrain => {
  // format the data to display
  const fields = convertOriginFormDataToValues(formDataForModelServiceOfTrain);
  return {
    ...fields,
  };
};

const DeployOptions = [
  { label: '模型部署', value: 'modelDeploy' },
  { label: '镜像部署', value: 'imageDeploy' },
];

const InputNumberWithUnit = ({ unit = '', ...restProps }: InputNumberProps & { unit?: ReactNode }) => (
  <>
    <InputNumber {...restProps} />
    {unit}
  </>
);

const InferenceOfTrain = forwardRef((props: InferenceOfTrainProps, ref) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const runtimeConfigRef = useRef<any>({});
  const validateField = useRef<FieldNamesForTrain>();
  const currentVersionStatus = useAppSelector(selectCurrentVersionStatus);
  const baseCapability = useAppSelector(selectBaseCapability);
  const formDataBaseOfTrain = useAppSelector(selectFormDataForBaseOfTrain);
  const urlParams = useParams();

  // const modelList = useAppSelector(selectModelList);
  // 获取模型库
  const { loading: modelListLoading, data: models } = useRequest(
    () => ability.getModels({ project_id: urlParams.projectId })
    // {
    //   onSuccess: data => {
    //     const modelOptions = transformModelListToOptions(data.data);
    //     dispatch(setModelList(modelOptions));
    //   },
    // }
  );
  const isEnableInferenceOfTrain = useAppSelector(selectIsEnableInferenceOfTrain);
  const formDataForInferenceOfTrain = useAppSelector(selectFormDataForInferenceOfTrain);
  const formDataForModelServiceOfTrain = useAppSelector(selectFormDataForModelServiceOfTrain);
  const [searchParams] = useSearchParams();
  const versionStatus = searchParams.get('version_status');

  const deployMethod = Form.useWatch(FieldNamesForTrain.DeployMethod, form);
  const isServerBatchingOpen = Form.useWatch(FieldNamesForTrain.ServerBactching, form);
  const isDynamicLoadBalanceOpen = Form.useWatch(FieldNamesForTrain.DynamicLoadBalance, form);

  const handleEnableConfigOfTrain = (checked: boolean) => {
    dispatch(setIsEnableInferenceOfTrain(checked));
  };

  useImperativeHandle(ref, () => ({
    getFieldValues() {
      return form.getFieldsValue(true);
    },
    submit(
      cb: (
        finalInferenceFormData: TrainFormDataConfig,
        finalModelServiceFormData?: TrainFormDataConfig
      ) => Promise<void>
    ) {
      validateField.current = undefined;
      form
        .validateFields()
        .then(async (values: FormValues) => {
          const finalModelServiceFormData = {
            [FieldNamesForTrain.ServerBactching]: {},
            [FieldNamesForTrain.DynamicLoadBalance]: {},
          };
          const runtimeConfig = runtimeConfigRef.current?.extractDataFromFormValues?.(values) ?? {};
          const finalInferenceFormData: TrainFormDataConfig = {
            ...runtimeConfig,
          } as TrainFormDataConfig;

          // 默认是 8080 端口且是 http 协议
          finalInferenceFormData[FieldNamesForTrain.Ports] = [
            {
              port: 8080,
              protocol: 'http',
              external: true,
            },
          ];

          if (values[FieldNamesForTrain.DeployMethod] === 'modelDeploy') {
            finalModelServiceFormData[FieldNamesForTrain.Resource] =
              finalInferenceFormData?.[FieldNamesForTrain.Resource];

            finalModelServiceFormData[FieldNamesForTrain.ServerBactching][FieldNamesForTrain.ClientMaxBatch] =
              values?.[FieldNamesForTrain.ClientMaxBatch];

            if (values[FieldNamesForTrain.ServerBactching]) {
              finalModelServiceFormData[FieldNamesForTrain.ServerBactching][FieldNamesForTrain.ServerMaxBatch] =
                values?.[FieldNamesForTrain.ServerMaxBatch];
              finalModelServiceFormData[FieldNamesForTrain.ServerBactching][FieldNamesForTrain.ServerMaxDelay] =
                values?.[FieldNamesForTrain.ServerMaxDelay];
            }

            if (values[FieldNamesForTrain.DynamicLoadBalance]) {
              finalModelServiceFormData[FieldNamesForTrain.DynamicLoadBalance][
                FieldNamesForTrain.DynamicLoadBalanceEnable
              ] = true;
              finalModelServiceFormData[FieldNamesForTrain.DynamicLoadBalance][FieldNamesForTrain.LimitPerInstance] =
                values?.[FieldNamesForTrain.LimitPerInstance];
            }

            finalInferenceFormData[FieldNamesForTrain.ImageFile] = undefined;
          }

          // 调用回调函数，让其进行下一步
          typeof cb === 'function' && cb(finalInferenceFormData, finalModelServiceFormData);
        })
        .catch(err => {
          console.log(err);
          message.error('请完成所有必填项!');
        });
    },
  }));

  useEffect(() => {
    if (currentVersionStatus === CurrentVersionStatus.Update) {
      // 判断下是否有值
      if (isFormDataEmpty(formDataForInferenceOfTrain) && isFormDataEmpty(formDataForModelServiceOfTrain)) {
        if (versionStatus === VersionStatus.Update) {
          dispatch(setIsEnableInferenceOfTrain(false));
        }
        return;
      }
      const inferenceFields = getFieldsForInferenceOfTrain(formDataForInferenceOfTrain);
      const modelServiceFields = getFieldsForModelServiceOfTrain(formDataForModelServiceOfTrain);

      if (inferenceFields[FieldNamesForTrain.DeployMethod] === 'imageDeploy') {
        form.setFieldsValue(inferenceFields ?? {});
      } else {
        form.setFieldsValue({ ...inferenceFields, ...modelServiceFields } ?? {});
      }
    }
  }, [currentVersionStatus]);

  useEffect(() => {
    // 在组件卸载的时候，将表单数据保存到 store 中
    return () => {
      const fields = form.getFieldsValue(true);
      dispatch(setFieldsForInferenceOfTrain(fields));
    };
  }, []);

  const checkFormatId = () => {
    const modelNameInBase = formDataBaseOfTrain?.model_name?.[0];
    const modelList = models?.data;
    if (!modelNameInBase) {
      return true;
    }

    const model = modelList?.find((item: any) => item?.name === modelNameInBase);

    if (!model) {
      return true;
    }

    const formatId = (model as any)?.framework_format;

    if (!formatId || formatId === '-') {
      return true;
    }

    return false;
  };

  return (
    <Layout className={styles.container}>
      <Row className={styles.row}>
        <Text className={styles['train-config-text']}>推理配置</Text>
        <Switch checked={isEnableInferenceOfTrain} onChange={handleEnableConfigOfTrain} />
        <Text className={styles['train-config-desc']}>监督能力请进行推理配置，若无配置需要请关闭</Text>
      </Row>
      {isEnableInferenceOfTrain ? (
        <>
          <Form
            form={form}
            name="validate_other"
            className={styles['form-submit-resource']}
            labelAlign="right"
            initialValues={{
              [FieldNamesForTrain.IsFullTrain]: true,
              [FieldNamesForTrain.Resource]: {
                cpu: 1,
                cpuUnit: '核',
                memory: 1,
                memoryUnit: 'GB',
                gpu: 0,
              },
              [FieldNamesForTrain.Args]: [{ name: '', value: '' }],
              [FieldNamesForTrain.Env]: [{ name: '', value: '' }],
              [FieldNamesForTrain.DynamicLoadBalance]: false,
              [FieldNamesForTrain.ServerBactching]: false,
              [FieldNamesForTrain.DeployMethod]: 'imageDeploy',
            }}
            colon
            {...formItemLayout}>
            <div className={styles['config-item']}>
              <div className={styles['config-item-title']}>部署方式</div>
              <div className={styles['config-item-body']}>
                <Form.Item
                  required
                  label={'部署方式'}
                  name={FieldNamesForTrain.DeployMethod}
                  tooltip={checkFormatId() ? '未在基础信息中填写完整框架信息，不能使用模型部署' : undefined}>
                  <Radio.Group
                    disabled={checkFormatId()}
                    // onChange={checkFormatId}
                    options={DeployOptions}
                    style={{ backgroundColor: 'transparent' }}
                  />
                </Form.Item>

                {deployMethod === 'modelDeploy' && (
                  <>
                    <Form.Item
                      label={'样本最大批次'}
                      name={FieldNamesForTrain.ClientMaxBatch}
                      tooltip={'说明：每个预测请求中传递的最大样本批次'}
                      rules={[
                        { required: true, message: '请输入样本最大批次' },
                        form => {
                          return {
                            validator: (_, clientBatch) => {
                              if (!form.getFieldValue(FieldNamesForTrain.ServerBactching)) return Promise.resolve();
                              const serverBatch = form.getFieldValue(FieldNamesForTrain.ServerMaxBatch);
                              validateField.current === FieldNamesForTrain.ClientMaxBatch &&
                                form.validateFields([FieldNamesForTrain.ServerMaxBatch]);
                              return (clientBatch || 0) >= (serverBatch || 0)
                                ? Promise.resolve()
                                : Promise.reject('样本最大批次不能小于积攒请求数');
                            },
                          };
                        },
                      ]}>
                      <InputNumber
                        min={1}
                        max={2048}
                        precision={0}
                        onChange={() => (validateField.current = FieldNamesForTrain.ClientMaxBatch)}
                      />
                    </Form.Item>

                    <Form.Item
                      required
                      label={'服务Batching'}
                      name={FieldNamesForTrain.ServerBactching}
                      tooltip={`开启后，平台会将接收到的请求攒到指定个数后，才一起传递后端进行模型预测，若在指定的延迟时间内，未攒够指定个数，也会将积攒的请求传递到后端进行模型预测。`}>
                      <Switch />
                    </Form.Item>
                    {isServerBatchingOpen && (
                      <Form.Item label={' '} colon={false}>
                        <div className={styles['service-batching-config']}>
                          <Form.Item label={'积攒请求数'} labelCol={{ span: 5 }}>
                            <Form.Item
                              name={FieldNamesForTrain.ServerMaxBatch}
                              rules={[
                                { required: true, message: '请输入积攒请求数' },
                                form => {
                                  return {
                                    validator: (_, serverBatch) => {
                                      const clientBatch = form.getFieldValue(FieldNamesForTrain.ClientMaxBatch);
                                      validateField.current === FieldNamesForTrain.ServerMaxBatch &&
                                        form.validateFields([FieldNamesForTrain.ClientMaxBatch]);
                                      return (clientBatch || 0) >= (serverBatch || 0)
                                        ? Promise.resolve()
                                        : Promise.reject('积攒请求数不能大于样本最大批次');
                                    },
                                  };
                                },
                              ]}>
                              <InputNumberWithUnit
                                min={2}
                                max={1024}
                                precision={0}
                                onChange={() => (validateField.current = FieldNamesForTrain.ServerMaxBatch)}
                                unit={<span style={{ marginLeft: 8 }}>个</span>}
                              />
                            </Form.Item>
                            <div className={styles['server-batch-tooltip']}>
                              说明：最多积攒多少个请求后，一起传递到后端进行模型预测
                            </div>
                          </Form.Item>

                          <Form.Item label={'延迟时间'} labelCol={{ span: 5 }}>
                            <Form.Item
                              name={FieldNamesForTrain.ServerMaxDelay}
                              rules={[{ required: true, message: '请输入延迟时间' }]}>
                              <InputNumberWithUnit
                                min={1}
                                max={1000}
                                precision={0}
                                unit={<span style={{ marginLeft: 8 }}>ms</span>}
                              />
                            </Form.Item>
                            <div className={styles['server-delay-tooltip']}>
                              说明：从积攒第一个请求开始，最多等待多长时间；到时间后，无论积攒多少请求，都传递到后端进行模型预测
                            </div>
                          </Form.Item>
                        </div>
                      </Form.Item>
                    )}

                    <Form.Item
                      required
                      label={'动态负载均衡'}
                      name={FieldNamesForTrain.DynamicLoadBalance}
                      tooltip={'说明：开启动态负载均衡，可提升服务20%的tps，但模型服务的总tps最多可达20000'}>
                      <Switch />
                    </Form.Item>
                    {isDynamicLoadBalanceOpen && (
                      <Form.Item label={' '} colon={false}>
                        <div className={styles['service-batching-config']}>
                          <Form.Item label={'单实例tps限流'} labelCol={{ span: 6 }}>
                            <Form.Item
                              name={FieldNamesForTrain.LimitPerInstance}
                              rules={[{ required: true, message: '请输入单实例tps限流' }]}>
                              <InputNumberWithUnit min={1} max={100} precision={0} />
                            </Form.Item>
                            <div className={styles['replica-rate-limiting-tooltip']}>
                              说明：服务的总tps限制为单实例tps限流乘实例数
                            </div>
                          </Form.Item>
                        </div>
                      </Form.Item>
                    )}
                  </>
                )}
              </div>
            </div>

            <RuntimeConfigOfForm
              isInferenceConfig={true}
              ref={runtimeConfigRef}
              isModelDeploy={deployMethod === 'modelDeploy'}>
              <Form.Item label="开放端口" {...rightAlignedFormItemLayout} className={styles['narrow-label-offest']}>
                <Text>8080</Text>
              </Form.Item>
            </RuntimeConfigOfForm>
          </Form>
          {props.children}
        </>
      ) : (
        <div className={styles['empty-body']}>{props.children}</div>
      )}
    </Layout>
  );
});

export default InferenceOfTrain;

import { useState, useEffect } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Flex, Form, Input, Button, Select, message, Space } from '@fuxi/eevee-ui';

import service from '../../service';
import { firstLastNotStr, ruleMap } from '../../utils/validator';
import { getServiceUrlSuffix } from '../../../src/utils/environment';

const ReleaseService: React.FC = () => {
  const VITE_SERVICE_URL_SUFFIX = getServiceUrlSuffix();
  const urlParams = useParams();
  const history = useNavigate();
  const [urlState] = useSearchParams();
  const abilityIdInSearchParams = urlState.get('abilityId');
  const versionIdInSearchParams = urlState.get('versionId');
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const [abilityId, setAbilityId] = useState<string>(abilityIdInSearchParams || '');
  const [form] = Form.useForm();
  const { data: capability } = useRequest(() => service.ability.getCapability({ project_id: urlParams.projectId }));

  // 加载版本
  const { data: versions, run: versionsRun } = useRequest(
    params => service.ability.getCapabilityVersions(params).then(res => res.data),
    {
      manual: true,
    }
  );
  const handelAbilityChange = val => {
    setAbilityId(val);
    form.setFieldsValue({
      capability_version_id: null,
    });
    versionsRun({ capability_uuid: val });
  };
  const handelSave = () => {
    form
      .validateFields()
      .then(async values => {
        setSubmitLoading(true);
        values.domain = values.domain + VITE_SERVICE_URL_SUFFIX;
        const { data } = await service.onlineService.appStart({
          capability_uuid: values.abilityId,
          capability_version_uuid: values.capability_version_id,
          service_name: values.name,
          url: values.domain,
          description: values.desc,
        });
        message.success('发布成功');
        history(`../${data.id}`);
      })
      .catch(() => {
        setSubmitLoading(false);
      });
  };
  const cancel = () => {
    history(-1);
  };
  useEffect(() => {
    abilityIdInSearchParams && versionsRun({ capability_uuid: abilityIdInSearchParams });
  }, []);
  return (
    <Flex justifyCenter style={{ marginTop: 40 }}>
      <Form
        form={form}
        labelAlign="right"
        autoComplete="off"
        initialValues={{}}
        style={{ width: 490 }}
        labelCol={{ span: 5 }}>
        <Form.Item
          label="能力名称"
          name="abilityId"
          rules={[{ required: true, message: '请选择能力' }]}
          initialValue={abilityIdInSearchParams}>
          {!!abilityIdInSearchParams ? (
            <>{capability?.data.list.find(item => item.uuid === abilityIdInSearchParams)?.name}</>
          ) : (
            <Select
              showSearch
              listHeight={128}
              placeholder="请选择"
              optionFilterProp="label"
              onChange={handelAbilityChange}
              defaultValue={abilityIdInSearchParams}
              value={abilityId}
              options={capability?.data?.list?.map(item => ({ value: item.uuid, label: item.name }))}
            />
          )}
        </Form.Item>
        <Form.Item label="能力ID">
          <div>{abilityId}</div>
        </Form.Item>
        <Form.Item
          label="版本"
          name="capability_version_id"
          rules={[{ required: true, message: '请选择版本' }]}
          initialValue={versionIdInSearchParams}>
          {!!versionIdInSearchParams ? (
            <>{versions?.list.find(item => item.uuid === versionIdInSearchParams)?.code}</>
          ) : (
            <Select
              listHeight={128}
              placeholder="请选择"
              defaultValue={versionIdInSearchParams}
              options={versions?.list.map(item => ({ value: item.uuid, label: item.code }))}
            />
          )}
        </Form.Item>
        <Form.Item
          label="服务名称"
          name="name"
          tooltip="说明：只能输入数字、大小写英文字母以及连字符（-）"
          initialValue={''}
          rules={[
            { required: true, message: '请输入服务名称' },
            { pattern: /^[A-Za-z0-9-]+$/, message: '只能输入大小写字母、数字和连字符（-）' },
            firstLastNotStr('-'),
          ]}>
          <Input placeholder="请输入" maxLength={30} />
        </Form.Item>
        <Form.Item
          label="接口地址"
          name="domain"
          initialValue={''}
          tooltip="域名必须由小写字母、数字、“-”和“.”组成，首字符必须为字母或数字，尾字符必须为字母或数字!"
          rules={[{ required: true, message: '请输入接口地址' }, ruleMap.isDomain]}>
          <Input placeholder="请输入" addonAfter={VITE_SERVICE_URL_SUFFIX} maxLength={63} />
        </Form.Item>
        <Form.Item label="服务描述" name="desc" initialValue={''}>
          <Input.TextArea placeholder="请输入" maxLength={100} showCount />
        </Form.Item>
        <Space direction="horizontal" size={16}>
          <div />
        </Space>
        <Form.Item label=" " colon={false}>
          <Space>
            <Button onClick={cancel}>取消</Button>
            <Button type="primary" onClick={handelSave} loading={submitLoading}>
              确定
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default ReleaseService;

import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { useAntdTable, useRequest } from '@fuxi/eevee-hooks';
import { Card, Flex, Table, Button, Divider } from '@fuxi/eevee-ui';

import service from '../../service';
import { ProjectInfo } from '../../types/dataSet';
import { getSorterTableData } from '../../utils/util';
import { projectDetailColumns, getProjectVersionColumns } from './const';
import Descriptions from '../../components/Descriptions';
import cx from './index.module.less';

const ProjectDetail: React.FC = () => {
  const urlParams = useParams();
  const history = useNavigate();
  const [detail, setDetail] = useState<ProjectInfo | null>(null);

  useRequest(
    () =>
      service.dataSet.getDatasetsDetails({
        dataset_id: urlParams.dataId || '',
        is_common: false,
      }),
    {
      onSuccess: data => {
        setDetail(data.data);
      },
    }
  );

  const { tableProps, refresh } = useAntdTable(
    getSorterTableData(service.dataSet.getVersions, { extraParams: { dataset_id: urlParams.dataId } })
  );
  const handelAddVersion = () => {
    history(
      `createVersion/??projectId=${urlParams.projectId}&datasetId=${urlParams.dataId}&datasetName=${detail?.name}`
    );
  };

  return (
    <Flex column className={cx['detail-page-wrapper']}>
      <Card title="基本信息" className={cx['detail-head']}>
        <Descriptions column={3} items={projectDetailColumns} data={detail || {}}></Descriptions>
      </Card>
      <Divider className={cx['detail-divider']} />
      <Card title="数据集版本" className={cx['detail-body']}>
        <Button type="primary" onClick={handelAddVersion} style={{ marginBottom: 20 }}>
          <PlusOutlined />
          新增版本
        </Button>
        <Table
          columns={getProjectVersionColumns({
            history,
            refresh,
            dataId: urlParams.dataId,
            datasetName: detail?.name,
            projectId: urlParams.projectId,
            datasetId: detail?.id,
          })}
          {...tableProps}
          rowKey="id"
          pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
        />
      </Card>
    </Flex>
  );
};

export default ProjectDetail;

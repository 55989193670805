import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Table, Checkbox, Button } from '@fuxi/eevee-ui';
import { cloneDeep } from 'lodash';
import { TablePaginationConfig } from 'antd/lib';
import { useMutation } from '@tanstack/react-query';
import service from '@/service';
import { useAppSelector } from '@/hooks';
import { IInfoItem, blackSpaceRule } from '../const';
import cx from './index.module.less';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  editRecord: React.MutableRefObject<IInfoItem>;
  record: IInfoItem;
  index: number;
  handleSave: (record: IInfoItem, prevRecord?: IInfoItem) => void;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  editRecord,
  index,
  children,
  handleSave,
  ...restProps
}) => {
  const rules =
    dataIndex === 'data'
      ? [
          {
            required: true,
            message: `问题不能为空`,
          },
          blackSpaceRule,
        ]
      : [blackSpaceRule];

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={[...rules]}>
          <Input.TextArea
            maxLength={200}
            showCount
            onChange={e => {
              editRecord.current = { ...record, [dataIndex]: e.target.value.trim() };
            }}
            onBlur={e => handleSave({ ...record, [dataIndex]: e.target.value.trim() }, record)}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

// NOTE:标注数据后，前端会一起将页面对应数据改动，不需要刷新页面
const Text: React.FC<{
  inDelete: boolean;
  checkedList: any[];
  onCheckChange: (item: any) => void;
  list: IInfoItem[];
  fetch: () => void;
  tab: string;
  datasetVid: string;
  pagination: TablePaginationConfig;
}> = ({ inDelete, checkedList, onCheckChange, tab, list, datasetVid, pagination, fetch: getDetail }) => {
  const [form] = Form.useForm();
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const infoList = cloneDeep(list)?.map((item, idx) => ({ ...item, order: idx + 1, key: String(item.id) }));

  const [data, setData] = useState<IInfoItem[]>([]);
  const editRecord = useRef<IInfoItem>({});

  useEffect(() => {
    setData(infoList);
  }, [list]);

  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: IInfoItem) => record.key === editingKey;

  const edit = (record: Partial<IInfoItem>) => {
    form.setFieldsValue({ ...record });
    editRecord.current = record;
    setEditingKey(record.key as string);
  };

  const { mutate: saveAnnotation } = useMutation({
    mutationFn: (currentItem?: IInfoItem) =>
      service.ml.annotateData(datasetVid!, {
        data: currentItem?.data?.trim(),
        annotation: currentItem?.annotation?.trim(),
        id: currentItem?.id as number,
      }),
    onSuccess: () => {
      getDetail();
    },
  });

  useEffect(() => {
    setEditingKey('');
  }, [tab]);

  // 退出编辑态
  const save = async (record: IInfoItem) => {
    const newData = [...data];
    const index = newData.findIndex(item => editRecord?.current.id === item.id);

    newData.splice(index, 1, editRecord?.current);
    setData(newData);
    setEditingKey('');
    getDetail();
  };

  const handleSave = async (record: IInfoItem, prevRecord?: IInfoItem) => {
    const values = await form.validateFields().catch(err => {
      // NOTE: 只能回退到最初的状态，标注信息保存后，如果未退出编辑状态，页面不会刷新。
      form.setFieldsValue({ ...prevRecord });
    });
    if (!values) {
      return;
    }
    saveAnnotation({ ...record, ...values });
  };

  const columns = [
    {
      title: '序号',
      key: 'order',
      dataIndex: 'order',
      editable: false,
      width: '10%',
    },
    {
      title: '问题',
      dataIndex: 'data',
      editable: true,
      width: '35%',
      render: (text: string) => {
        return <div className={cx('long-text')}>{text}</div>;
      },
    },
    {
      title: '回答',
      dataIndex: 'annotation',
      editable: true,
      width: '40%',
      render: (text: string) => {
        return <div className={cx('long-text')}>{text}</div>;
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: IInfoItem) => {
        const editable = isEditing(record);
        return inDelete ? (
          <Checkbox
            checked={checkedList.includes(record.id)}
            onChange={e => {
              onCheckChange({ ...record, checked: e.target.checked });
            }}
          />
        ) : editable ? (
          <span>
            <Button type="link" onClick={() => save(record)} style={{ marginRight: 8 }}>
              完成标注
            </Button>
          </span>
        ) : (
          <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record)}>
            标注
          </Button>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IInfoItem) => ({
        record,
        editRecord,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        editing: isEditing(record),
      }),
    };
  });

  // 离开页面前保存当前编辑的数据
  function preventUnload(e: BeforeUnloadEvent) {
    fetch(`/ml/api/v1/ml/dataset/${datasetVid}/annotate`, {
      method: 'POST',
      body: JSON.stringify({ ...editRecord.current }),
      headers: {
        'Content-Type': 'application/json',
        Project_id: String(aiProjectId),
      },
      keepalive: true,
    });
  }

  useEffect(() => {
    window.addEventListener('beforeunload', preventUnload);
    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, []);

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          ...pagination,
          onChange: (page: number, pageSize?: number) => {
            pagination.onChange && pagination.onChange(page, pageSize as number);
            setEditingKey('');
          },
        }}
      />
    </Form>
  );
};

export default Text;

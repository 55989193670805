import Axios from 'axios';
import { ShanquanInstance } from './common';


import responseHandler from './utils/responseHandler';
import errorHandler from './utils/errorHandler';

export const uploadInstance = ShanquanInstance;
// uploadInstance 超时时间
uploadInstance.defaults.timeout = 1000 * 60 * 10;

uploadInstance.interceptors.response.use(responseHandler, errorHandler);
const CancelToken = Axios.CancelToken;

const upload = {
  // danlu sdk 升级

  // 数据集相关分片上传
  // 初始化uuid
  uuidInit<T = any>() {
    return ShanquanInstance.get<T>(`/dataset/upload/uuid`).then(res => {
      return res.data;
    });
  },
  // 初始化上传
  getUploadId(params) {
    return uploadInstance.get(`/dataset/upload/id`, {
      params: {
        upload_uuid: params.upload_uuid,
        filename: params.filename,
      }
    }).then(({ data }) => {
      return data.upload_id;
    });
  },
  // 分片上传
  uploadPart(body: FormData, signal: any): any {
    return uploadInstance.post(`/dataset/upload/part`, body, {
      signal,
    });
  },
  // 分片上传完成合并
  mergePart(body: {
    upload_uuid: string;
    upload_id: string;
    filename: string;
    part_etags: any;
  }) {
    return uploadInstance.post(`/dataset/upload/part/finish`, body);
  },

  // 取消已经上传的分片
  cancelUploaded(upload_uuid: string, filename: string) {
    return uploadInstance.post(`/dataset/upload/cancel`, {
      upload_uuid,
      filename,
    });
  },

  // 模型文件分片上传
  // 初始化uuid
  modelInitUploadUuid<T = any>() {
    return ShanquanInstance.get<T>(`/model/upload/uuid`).then(res => {
      return res.data;
    });
  },
  // 初始化单个文件id
  getModelUploadId(params) {
    return uploadInstance.get(`/model/upload/id`, {
      params: {
        upload_uuid: params.upload_uuid,
        filename: params.filename,
      }
    }).then(({ data }) => {
      return data.upload_id;
    });
  },
  // 请求分片url
  modelUploadPart(params: {
    upload_uuid: string;
    upload_id: string;
    filename: string;
    part_num: number;
  }, signal: any): any {
    return uploadInstance.get(`/model/upload/url`, { params, signal });
  },

  // 通过url上传分片
  uploadPartByUrl(url: string, file, signal: any): any {
    return Axios.put(url, file);
  },


  // 分片上传完成合并
  modelMergePart(body: {
    upload_uuid: string;
    upload_id: string;
    filename: string;
    part_etags: any;
  }) {
    return uploadInstance.post(`/model/upload/complete`, body);
  },


  // 模型文件上传完成合并
  mergeModelUpload(params) {
    return uploadInstance.put(`/upload/model`, params);
  },



  // 取消上传中的模型文件
  cancelModelUploaded(params: {
    upload_uuid,
    filename,
    upload_id,
  }) {
    return uploadInstance.post(`/model/upload/stop`, params);
  },

  // 删除已经上传的模型文件
  deleteModelUploaded(params: {
    upload_uuid,
    filename,
  }) {
    return uploadInstance.post(`/model/upload/cancel`, params);
  },


  uploadDDL(params) {
    return uploadInstance.post(`/capability/file`, params);
  },



};

export default upload;

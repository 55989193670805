import { useState, useEffect } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Space, Button, Input, Tooltip, Spin } from '@fuxi/eevee-ui';

import cx from './EditText.module.less';

interface Props {
  loading?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  onSave: (value: string) => void;
  maxLength?: number;
}

const EditText: React.FC<Props> = ({ value, onChange, onSave, maxLength = 50, loading }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>(value || '');
  const handelSave = () => {
    onSave?.(newValue);
    onChange?.(newValue);
    setIsEdit(false);
  };
  useEffect(() => {
    setNewValue(value || '');
  }, [value]);

  return (
    <div className={cx['edit-text-wrapper']}>
      {isEdit ? (
        <Space>
          <Input
            size="small"
            value={newValue}
            maxLength={maxLength}
            className={cx['edit-text-input']}
            onChange={value => setNewValue(value.target.value)}
          />
          <div className={cx['save-btn']} onClick={handelSave}>
            保存
          </div>
          <div className={cx['cancel-btn']} onClick={() => setIsEdit(false)}>
            取消
          </div>
        </Space>
      ) : (
        <>
          <Tooltip title={value}>
            <div className=" ellipsis">{value}</div>
          </Tooltip>
          <div
            className={`${cx['edit-btn']}`}
            onClick={() => {
              setIsEdit(true);
              setNewValue(value || '');
            }}>
            {loading ? <Spin size="small" /> : <EditOutlined style={{ color: '#386bf3' }} />}
          </div>
        </>
      )}
    </div>
  );
};

export default EditText;

import { gql } from '@apollo/client';

export const RESET_TERMINAL = gql`
  mutation ResetEdgeNode($projectId: ID!, $edgeNodeIds: [ID!]!) {
    resetEdgeNode(projectId: $projectId, edgeNodeIds: $edgeNodeIds)
  }
`;

export const UPDATE_TERMINAL = gql`
  mutation UpdateEdgeNode($projectId: ID!, $input: [UpdateEdgeNodeInput]) {
    updateEdgeNode(projectId: $projectId, input: $input) {
      id
      name
      status
      type
      serverId
      serverName
      mac
      nodeName
      createTime
      updateTime
      offlineTime
      sshUrl
      projectId
    }
  }
`;

import { useEffect, useState } from 'react';
import { Input } from '@fuxi/eevee-ui';
import { Checkbox, message, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import client from '@/service/graphql';
import { UPLOAD_FEEDBACK } from '@/service/schema/feedback/feedback-mutations';
import cx from './index.module.less';

interface FeedbackModalProps {
  visible: boolean;
  setFeedbackModalVisible: any;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = props => {
  const { visible, setFeedbackModalVisible } = props;
  const [feedbackContent, setFeedbackContent] = useState('');
  const [isAnonymous, setIsAnonymous] = useState(false);

  useEffect(() => {
    setFeedbackContent('');
    setIsAnonymous(false);
  }, [visible]);

  const handleOkCallback = async () => {
    if (!feedbackContent) {
      message.error('反馈不能为空');
      return;
    }
    const data = await client.mutate(UPLOAD_FEEDBACK, {
      input: { isAnonymous: isAnonymous, content: feedbackContent },
    });

    if (data) {
      message.success('反馈上传成功');
      closeModal();
    }
  };

  const handleAnonymousChange = (e: CheckboxChangeEvent) => {
    // isAnonymous = e.target.checked;
    setIsAnonymous(e.target.checked);
  };

  const closeModal = () => {
    setFeedbackModalVisible(false);
  };

  return (
    <Modal title="请填写您的需求" visible={visible} onCancel={closeModal} onOk={handleOkCallback}>
      <div>
        <Input.TextArea
          value={feedbackContent}
          onChange={e => setFeedbackContent(e.target.value)}
          autoComplete="off"
          autoSize={{ minRows: 5, maxRows: 5 }}
          showCount
          maxLength={500}
          placeholder="请填写您的需求及联系方式，500字符以内。"
        />
        <div className={cx('checkbox')}>
          <Checkbox checked={isAnonymous} onChange={e => handleAnonymousChange(e)}>
            匿名提交
          </Checkbox>
        </div>
      </div>
    </Modal>
  );
};

import React from 'react';
import { Descriptions as AntDescriptions, Tooltip } from '@fuxi/eevee-ui';

import cx from './Descriptions.module.less';

export interface ItemOptions<RecordType = any> {
  label: React.ReactNode;
  dataIndex: string;
  render?: (data: RecordType, item: ItemOptions) => React.ReactNode;
  prefixCls?: string;
  className?: string;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  span?: number;
  ellipsis?: boolean;
  isShow?: (RecordType) => boolean;
}
export type Items<RecordType = any> = ItemOptions<RecordType>[];
interface Props<RecordType = any> {
  items: Items<RecordType>;
  title?: string;
  column?: number;
  data: object;
  children?: React.ReactNode;
}
const Descriptions: React.FC<Props> = ({ items, data, title, column = 3, children }) => {
  const renderContent = (data, item) => {
    if (item.render) {
      return item.render(data, item);
    }
    if (item.ellipsis) {
      return (
        <Tooltip title={data[item.dataIndex]} placement="topLeft">
          <span className="ellipsis">{data[item.dataIndex]}</span>
        </Tooltip>
      );
    }
    return data[item.dataIndex];
  };
  const preChild = React.Children.toArray(children).length > 1 ? React.Children.toArray(children)[0] : null;
  const sufChild = React.Children.toArray(children).length > 1 ? React.Children.toArray(children)[1] : children;

  return (
    <AntDescriptions column={column} title={title} className={cx['descriptions-wrapper']}>
      {preChild}
      {items &&
        items.map(
          item =>
            (!item.isShow || item?.isShow(data)) && (
              <AntDescriptions.Item key={item.dataIndex} {...item}>
                {renderContent(data, item)}
              </AntDescriptions.Item>
            )
        )}
      {sufChild}
    </AntDescriptions>
  );
};
export const DescriptionsItem = AntDescriptions.Item;
export default Descriptions;

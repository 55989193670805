import React from 'react';
import { ResourceValue } from '@ai-training/types/ability';

import { VersionInfoText } from '../ConfigConstant';
import { Form } from 'antd';

interface ResourceConfigProps {
  value?: ResourceValue;
}

const ResourceConfig: React.FC<ResourceConfigProps> = ({ value = {} }) => {
  return (
    <>
      {Object.keys(value).length === 0 ? (
        <VersionInfoText />
      ) : (
        <>
          <Form.Item style={{ marginBottom: '0' }}>
            <VersionInfoText value={`CPU：${value?.cpu}${value?.cpuUnit === 'm' ? 'm' : '核'}`} />
          </Form.Item>
          <Form.Item style={{ marginBottom: '0' }}>
            <VersionInfoText value={`内存：${value?.memory}${value?.memoryUnit}`} />
          </Form.Item>
          <Form.Item style={{ marginBottom: '0' }}>
            <VersionInfoText value={`GPU：${value?.gpu === undefined ? value?.gpu : `${value?.gpu}卡`}`} />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default ResourceConfig;

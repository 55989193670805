import React, { useEffect, useRef, useState } from 'react';
import { Icon, Modal } from '@fuxi/eevee-ui';
import { Form, Table, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ColumnsType } from 'antd/es/table';
import { AuditStatus, AuditTypeToTextMap } from '@/constants/aiCap';
import { auditCapability, getApplyList } from '@/service/aiCap';
import { statusIconMap } from '@/components/IconFont';
import { toResourceStr } from '../../utils';
import cx from './ProjectAiCapApproval.module.less';

export const ProjectAiCapApproval: React.FC = props => {
  const [approvalList, setApprovalList] = useState<AiCapApprovalItem[]>([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // const projectIdRaw = searchParams.get('projectId');
  // const projectId = projectIdRaw ? parseInt(projectIdRaw) : -1;
  // if (projectId === -1) {
  //   notification.error({
  //     message: '未指定具体项目',
  //     description: '请经由正确的路径参数跳转',
  //   });
  // }

  const dispatch = useDispatch();

  const columns: ColumnsType<AiCapApprovalItem> = [
    {
      title: '申请时间',
      dataIndex: 'applyTime',
      key: 'applyTime',
      sorter: (a, b) => (a.applyTime > b.applyTime ? 1 : -1),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '申请项目',
      dataIndex: 'projectName',
      key: 'projectName',
    },
    // {
    //   title: 'AI能力',
    //   dataIndex: 'capabilityName',
    //   key: 'capabilityName',
    // },
    {
      title: '申请资源',
      dataIndex: 'resource',
      key: 'resource',
      render: (_, item) => {
        return toResourceStr(item.resource);
      },
    },
    {
      title: '申请人',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: '申请原因',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: '审批状态',
      dataIndex: 'auditInfo',
      key: 'auditInfo',
      filters: Object.keys(AuditTypeToTextMap).map(key => {
        return {
          text: AuditTypeToTextMap[key],
          value: key,
        };
      }),
      render: (_, item) => AuditTypeToTextMap[item.auditInfo],
    },
    {
      title: '审批意见',
      dataIndex: 'detail',
      key: 'detail',
    },
    {
      title: '操作',
      dataIndex: 'op',
      render: (_, item: AiCapApprovalItem) =>
        item.auditInfo === AuditStatus.WAITING ? (
          <>
            <a className={cx('operation')} onClick={() => approve(item)}>
              审批通过
            </a>
            <a className={cx('operation', { reject: true })} onClick={() => reject(item)}>
              审批不通过
            </a>
          </>
        ) : (
          <></>
        ),
    },
  ];

  const formRef = useRef<any>();

  useEffect(() => {
    getApplyList().then(result => {
      if (result?.aiCapabilityApplyList?.success) {
        setApprovalList(result?.aiCapabilityApplyList?.auditRecord);
      } else {
        notification.error({
          message: '获取审批列表失败',
          description: result?.aiCapabilityApplyList?.message,
        });
      }
    });
  }, []);

  const audit = (applyId: number, state: AuditStatus, detail: string) => {
    auditCapability(0, applyId, state, detail).then(result => {
      console.log(result);
      if (result.auditAiCapability.success) {
        const index = approvalList.findIndex(a => a.id === result.auditAiCapability.auditRecord.id);
        if (index > -1) {
          const newApprovalList = [...approvalList];
          newApprovalList[index] = result.auditAiCapability.auditRecord;
          setApprovalList(newApprovalList);
        }
      }
    });
  };

  const approve = (item: AiCapApprovalItem) => {
    Modal.confirm({
      title: `是否确认同意[${item.projectName}]项目中对AI能力的申请？`,
      onOk: () => {
        audit(item.id, AuditStatus.PASS, 'OK');
      },
    });
  };

  const reject = (item: AiCapApprovalItem) => {
    let detail = '';

    const validate = e => {
      formRef.current
        .validateFields()
        .then(values => {
          audit(item.id, AuditStatus.REJECT, values['detail']);
          e();
        })
        .catch(error => {});
    };

    Modal.confirm({
      title: (
        <>
          <Icon size={24} name="warn-日志"></Icon>填写审批意见
        </>
      ),
      icon: statusIconMap('warning'),
      className: cx('audit-reject-modal'),
      closable: true,
      content: (
        <>
          <Form ref={formRef}>
            <Form.Item label="审批意见" name="detail" rules={[{ required: true, message: '请输入审批意见' }]}>
              <TextArea
                className={cx('audit-detail')}
                placeholder="请输入"
                onChange={e => (detail = e.target.value)}></TextArea>
            </Form.Item>
          </Form>
        </>
      ),
      onOk: validate,
    });
  };
  return (
    <div className={cx('approval-page')}>
      <div className={cx('title')}>审批列表</div>
      {approvalList && <Table columns={columns} dataSource={approvalList}></Table>}
    </div>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@fuxi/eevee-ui';

import Img404 from '../../assets/image/404.png';
import cx from './index.module.less';

const NotFoundPage: React.FC = () => {
  return (
    <div>
      <div className={cx('imgBlock')}>
        <img src={Img404} alt="404" />
      </div>
      <div className={cx('exception_notice')}>
        <span className={cx('exception_title')}>404</span>
        <span className={cx('exception_description')}>抱歉，你访问的页面不存在...</span>
        <Link to="/home">
          <Button type="primary" className={cx('exception_return_btn')}>
            返回首页
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;

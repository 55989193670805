import { useEffect, useState } from 'react';
import { Flex, message, Modal, Space, Table, TablePaginationConfig, Tooltip, Typography } from '@fuxi/eevee-ui';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined, DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Image, ImageSource, VirtualMachineImage, VirtualMachineImageStatus } from '@/store/image';
import { virtualTerminalApi } from '@/service/virtualTerminal';
import { useAppSelector } from '@/hooks';
import { StatusTag } from '../../components/StatusTag';
import ProjectDetailContainer from '../../ProjectDetail/ProjectDetailContainer';
import { defaultCurrentPage, defaultPageSize } from '../../const';
import cx from './index.module.less';

interface Props {}

export const CustomImage: React.FC<Props> = props => {
  const { imagesData, currentProject, fetchImageInfo } = ProjectDetailContainer.useContainer();
  const projectId = useAppSelector(state => state.project.currentProject.id);
  const terminalImages: Image[] = imagesData?.images.map(item => ({ ...item, source: ImageSource.Terminal })) || [];
  const [virtualImages, setVirtualImages] = useState<VirtualMachineImage[]>([]);

  const fetchVirtualImages = () => {
    virtualTerminalApi.getImages(currentProject.id + '').then(res => {
      setVirtualImages(res.data.data);
    });
  };

  useEffect(() => {
    if (projectId) {
      fetchVirtualImages();
    }
  }, [projectId]);

  // 虚拟机转的镜像适配自定义镜像的数据结构
  const displayVirtualImages: Image[] = virtualImages.map(item => {
    return {
      name: item.displayName,
      version: item.imageTag,
      uploadTime: item.updatedAt,
      size: '-',
      stubUrl: '-',
      status: item.currentStatus,
      msg: item.currentStatus,
      source: item?.public ? ImageSource.Public : ImageSource.UserSave,
      id: item.id,
    };
  });

  const dataSource: Image[] = [...terminalImages, ...displayVirtualImages];

  const removeVmImage = (img: Image) => {
    if (img.status === VirtualMachineImageStatus.exporting.value) {
      message.error('正在保存中，无法删除');
      return;
    }

    Modal.confirm({
      style: { wordBreak: 'break-all', color: 'rgba(255, 255, 255, 0.85)' },
      title: `确定删除镜像${img.name}吗？`,
      onOk() {
        virtualTerminalApi.deleteVmImage(projectId + '', img.id!).then(res => {
          if (res.status === 200) {
            message.success('删除成功');
            fetchVirtualImages();
            fetchImageInfo({ variables: { projectId: projectId } });
          } else {
            message.error(res.data.message);
          }
        });
      },
      className: 'confirm-modal',
    });
  };

  const columns = [
    {
      title: '镜像名称',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => (
        <Tooltip title={name} placement="topLeft">
          <div className={cx('image-name-in-cell')}>{name}</div>
        </Tooltip>
      ),
      width: 260,
    },
    {
      title: '版本号',
      dataIndex: 'version',
      key: 'version',
      render: (version: string) => (
        <Tooltip title={version} placement="topLeft">
          <div className={cx('version-in-cell')}>{version}</div>
        </Tooltip>
      ),
      width: 90,
    },
    {
      title: '来源',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: '上传时间',
      dataIndex: 'uploadTime',
      key: 'uploadTime',
      sorter: (a: any, b: any) => Date.parse(b?.uploadTime) - Date.parse(a?.uploadTime),
      render: (text: string) => {
        return <div>{text ? text : '-'}</div>;
      },
    },
    {
      title: '镜像大小',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, img: Image) => (
        <div>
          {img.status ? (
            <StatusTag
              color={VirtualMachineImageStatus[img.status]?.color}
              label={VirtualMachineImageStatus[img.status]?.label}></StatusTag>
          ) : !!img?.msg ? (
            <Flex alignCenter>
              <StatusTag
                color={VirtualMachineImageStatus?.parseError?.color}
                label={VirtualMachineImageStatus?.parseError?.label}></StatusTag>
              <Tooltip title={img?.msg}>
                <QuestionCircleOutlined style={{ cursor: 'pointer' }} />
              </Tooltip>
            </Flex>
          ) : (
            <div>
              {!img?.stubUrl && (
                <StatusTag
                  color={VirtualMachineImageStatus?.parsing?.color}
                  label={VirtualMachineImageStatus?.parsing?.label}></StatusTag>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      title: '操作',
      dataIndex: 'stubUrl',
      key: 'stubUrl',
      render: (text: string, img: Image) => (
        <div>
          {!!img?.status ? (
            img?.source !== ImageSource.Public && (
              <Flex alignCenter>
                <span className={cx('delete-button')} onClick={() => removeVmImage(img)}>
                  删除
                </span>
              </Flex>
            )
          ) : (
            <div>
              {img?.stubUrl && (
                <div onClick={() => downloadFile(text)}>
                  <Flex alignCenter style={{ cursor: 'pointer' }}>
                    <DownloadOutlined />
                    <span title={text}>stub.pyi</span>
                  </Flex>
                </div>
              )}
            </div>
          )}
        </div>
      ),
      width: 130,
    },
  ];

  const downloadFile = (downloadUrl: string) => {
    !!downloadUrl && (window.location.href = downloadUrl);
  };

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: defaultPageSize,
    current: defaultCurrentPage,
  });

  return (
    <div className={cx('wrapper')}>
      <div className={cx('manage-title')}>镜像管理</div>

      <Flex justifyBetween className={cx('repo-url-container')}>
        <Flex className={cx('addr-item-wrapper')} flex={1} alignCenter>
          {/* <div className={cx('addr')}> */}
          <span className={cx('title')}>x86仓库地址： </span>
          <Tooltip placement="topLeft" title={currentProject.imageAddr + '/x86/'}>
            <Typography.Text copyable={{ tooltips: false }} ellipsis className={cx('address-value-x86')}>
              {currentProject.imageAddr + '/x86/'}
            </Typography.Text>
          </Tooltip>
          {/* </div> */}
        </Flex>

        <Flex className={cx('addr-item-wrapper')} flex={1} alignCenter>
          <span className={cx('title')}>ARM仓库地址： </span>
          <Tooltip placement="topLeft" title={currentProject.imageAddr + '/arm/'}>
            <Typography.Text copyable={{ tooltips: false }} ellipsis className={cx('address-value-arm')}>
              {currentProject.imageAddr + '/arm/'}
            </Typography.Text>
          </Tooltip>
        </Flex>
      </Flex>
      {!!dataSource?.length ? (
        <Table
          rowKey={img => img.name + img.version}
          pagination={{
            pageSize: pagination?.pageSize,
            current: pagination?.current,
            showTotal: () => `共 ${dataSource?.length} 条`,
            showSizeChanger: true,
            defaultPageSize: defaultPageSize,
            pageSizeOptions: [8, 10, 20, 50, 100],
            onChange: (page, pageSize) => {
              setPagination({ current: page, pageSize: pageSize });
            },
            showQuickJumper: true,
          }}
          dataSource={dataSource}
          columns={columns}
        />
      ) : (
        <div>
          <div className={cx('up')}></div>
          <div className={cx('empty-tip')}>将自定义镜像上传至上述镜像仓库后，可在此处管理自定义镜像</div>
        </div>
      )}
    </div>
  );
};

import { gql } from '@apollo/client';

export const IMAGE_FRAGMENT = gql`
  fragment ImageFragment on Image {
    name
    version
    uploadTime
    size
    stubUrl
    msg
  }
`;

export const GET_IMAGES = gql`
  query GetImages($projectId: ID!) {
    images(projectId: $projectId) {
      ...ImageFragment
    }
  }
  ${IMAGE_FRAGMENT}
`;

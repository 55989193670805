import Axios from '@/service/common';

export const upload = async (iconFile: string) => {
  const formData = new FormData();
  formData.append('file', iconFile);
  try {
    return await Axios.post<any, { data: { download_url: string } }>('/upload', formData);
  } catch (e: any) {
    return e;
  }
};

import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useRequest } from 'ahooks';
import {
  Button,
  Descriptions,
  DescriptionsProps,
  Divider,
  Empty,
  Image,
  Tabs,
  TabsProps,
  Tooltip,
  Flex,
  Icon,
  Spin,
} from '@fuxi/eevee-ui';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ProjectMarkDown from '@ai-training/components/MarkDown/Markdown';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import ml from '@/service/ml';
import { AbilityVersion, PublicAbilityDetail } from '@/@types/ml';
import { useAppSelector } from '@/hooks';
import { getAbilityTagValue } from '@/constants/abilityTags';
import ProjectImg from '../../../assets/image/mlProjectImg.png';
import EmptyImg from '../../../assets/image/empty2.png';
import { AbilityTag } from '../components/AbilityTag';

import { onMlImgError } from '../utils/onMlImgError';
import cx from './index.module.less';

export const PublicAbilitDetail = () => {
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const mlCategory = useAppSelector(state => state.mlCategory.categories);
  const navigate = useNavigate();
  const { publicAbilityId } = useParams();
  const { getCapabilityDetails, getCapabilityVersions } = ml;
  const {
    data,
    loading,
    run: fetchAbilityDetail,
  } = useRequest(
    () =>
      getCapabilityDetails({
        id: publicAbilityId!,
      }),
    {
      manual: true,
    }
  );

  const {
    data: versions,
    loading: loadingVersions,
    run: fetchVersions,
  } = useRequest(
    () =>
      getCapabilityVersions({
        id: publicAbilityId!,
      }),
    {
      manual: true,
    }
  );

  const [publicAbilityDetail, setPublicAbilityDetail] = useState<PublicAbilityDetail>({} as PublicAbilityDetail);
  const [abilityVersionList, setAbilityVersionList] = useState<AbilityVersion[]>([]);
  const res = data?.data;

  useEffect(() => {
    if (!aiProjectId) {
      return;
    }
    fetchAbilityDetail();
    fetchVersions();
  }, [aiProjectId]);

  useEffect(() => {
    if (isEmpty(res)) {
      return;
    }
    const publicAbilityDetail: PublicAbilityDetail = {
      imgUrl: res.image_url,
      categoryUuid: res.category_uuid,
      uuid: res.uuid,
      name: res.name,
      desc: res.description || '-',
      versions: res.version_num,
      createAt: res.created_at,
      updateAt: res.updated_at,
      creator: res.creator,
      projectId: res.project_id,
      baseCapability: res.base_capability,
      domainSuffix: res.domain_suffix,
      share: res.share,
    };
    setPublicAbilityDetail(publicAbilityDetail);
  }, [res]);

  useEffect(() => {
    setAbilityVersionList(versions?.data?.list || []);
  }, [versions]);

  const items: DescriptionsProps['items'] = [
    {
      key: 'uuid',
      label: '能力id',
      children: publicAbilityDetail.uuid || '-',
      span: 1,
    },
    {
      key: 'versions',
      label: '版本数',
      children: publicAbilityDetail.versions || '-',
      span: 2,
    },
    {
      key: 'updateAt',
      label: '最后更新时间',
      children: <Tooltip title={publicAbilityDetail.updateAt}>{publicAbilityDetail.updateAt}</Tooltip>,
      span: 1,
    },
    {
      key: 'createAt',
      label: '创建时间',
      children: publicAbilityDetail.createAt,
      span: 2,
    },
    {
      key: 'desc',
      label: '描述',
      children: (
        <Tooltip placement="topLeft" title={publicAbilityDetail.desc}>
          {publicAbilityDetail.desc}
        </Tooltip>
      ),
      span: 3,
    },
  ];

  const publicVersionColumns: ColumnsType<AbilityVersion> = [
    {
      title: '版本',
      dataIndex: 'name',
      render: (_, record) => record.name,
    },
    {
      title: '描述',
      dataIndex: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: val => (
        <Tooltip placement="topLeft" title={val}>
          {val || '-'}
        </Tooltip>
      ),
    },
    {
      title: '版本ID',
      dataIndex: 'uuid',
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      render: val => new Date(val).toLocaleString(),
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_, record) => (
        <Link type="link" to={`transfer/${record.uuid}`}>
          调用
        </Link>
      ),
    },
  ];

  const AbilityKeys = {
    AbilityMarkdown: 'abilityMarkdown',
    AbilityVersion: 'abilityVersion',
  };

  const tabs: TabsProps['items'] = [
    {
      key: AbilityKeys.AbilityMarkdown,
      label: '能力说明',
      children: (
        <>
          <div className={cx('md-wrapper')}>
            {data?.data?.markdown ? <ProjectMarkDown text={data?.data?.markdown} /> : <Empty image={EmptyImg} />}
          </div>
        </>
      ),
    },
    {
      key: AbilityKeys.AbilityVersion,
      label: '能力版本',
      children: (
        <Table
          loading={loadingVersions}
          columns={publicVersionColumns}
          dataSource={abilityVersionList}
          pagination={{
            showTotal: total => `总共 ${total} 条`,
            showQuickJumper: true,
            pageSizeOptions: ['8', '10', '16', '32', '64'],
            showSizeChanger: true,
          }}
        />
      ),
    },
  ];

  const transfer = () => {
    navigate(`transfer`);
  };


  return (
    <Spin spinning={loading} tip="正在获取公共能力详情...">
      <div className={cx('wrapper')}>
        <Flex alignCenter>
          <Flex className={cx('desc-wrapper')} flexDirection="column">
            <Descriptions
              className={cx('desc')}
              title={
                <Flex alignCenter>
                  <div>{publicAbilityDetail.name || '-'}</div>
                  <div className={cx('tag-wrapper')}>
                  <AbilityTag value={getAbilityTagValue(mlCategory, publicAbilityDetail?.categoryUuid || '')} />
                  </div>
                </Flex>
              }
              items={items}
            />
            <Flex.Item alignSelf="start">
              <Button className={cx('transfer-button')} type="primary" onClick={transfer} icon={<Icon size={14} name="icon-diaoyong"></Icon>}>
                调用
              </Button>
            </Flex.Item>
          </Flex>
          <Flex.Item>
            <Image className={cx('detail-img')} width={378} height={179} src={publicAbilityDetail?.imgUrl ?? ProjectImg} onError={onMlImgError}></Image>
          </Flex.Item>
        </Flex>
        <Divider className={cx('divider')}></Divider>
        <Tabs defaultActiveKey={AbilityKeys.AbilityMarkdown} items={tabs} />
      </div>
    </Spin>
  );
};

import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useAppDispatch } from '@/hooks';
import { GET_USER_INFO } from '@/service/schema/user/user-queries';
import { setUserInfo } from '@/store/user';
import { ETracker } from '@/tracker';

// 初始化埋点信息
const initTracker = rbacUserInfo => {
  ETracker.setConfig({
    uid: rbacUserInfo?.email,
    username: rbacUserInfo?.name,
  });
};

export const RbacInfo = () => {
  const { data: rbacUserInfo } = useQuery(GET_USER_INFO);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (rbacUserInfo) {
      initTracker(rbacUserInfo?.user);
    }
    dispatch(
      setUserInfo({
        fullName: rbacUserInfo?.user?.fullName,
        name: rbacUserInfo?.user?.name,
        email: rbacUserInfo?.user?.email,
        permissions: JSON.parse(rbacUserInfo?.user.permissions || '[]'),
        roles: rbacUserInfo?.user?.roles || [],
      })
    );
  }, [rbacUserInfo]);
  return <></>;
};

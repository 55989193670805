import React, { useState, useEffect } from 'react';
import { Menu } from '@fuxi/eevee-ui';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from '@/hooks';
import { IRouterExtra, routerExtras } from '@/router/config';
import { useIsMiniProject } from '@/hooks/useIsMiniProject';

interface Props {
  mode: 'vertical' | 'horizontal' | 'inline';
}
const SiderMenu: React.FC<Props> = ({ mode }) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]); // 展开/关闭的菜单项，数组
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]); // 选中的菜单项，单项
  const { pathname } = useLocation();
  const urlParams = useParams();
  const aiProjectId = useAppSelector(state => state.project.currentProject.aiProjectId);
  const isMiniProject = useIsMiniProject();
  const isTerminalProject = useAppSelector(state => state.project.currentProject.isTerminal);

  useEffect(() => {
    const currentPath = urlParams.projectId
      ? pathname.split(`${urlParams.projectId}/`).at(-1)
      : pathname.split('detail/').at(-1);
    const pathnameArray = currentPath!.split('/');

    setOpenKeys(pathnameArray);
    setSelectedKeys(pathnameArray);
  }, [pathname]);

  // 被选中时调用
  function onSelect({ selectedKeys }) {
    setSelectedKeys(selectedKeys);
  }

  // SubMenu 展开/关闭的回调
  function onOpenChange(openKeys) {
    setOpenKeys(openKeys);
  }

  const getMenu = (routerExtras: IRouterExtra[] | undefined) => {
    if (!routerExtras) return;
    return routerExtras
      .filter(item => (isMiniProject ? item.isMini : !item.onlyInMini))
      .filter(item => (isTerminalProject ? true : !item.onlyInTerminal))
      .map(menuItem => {
        return {
          label: menuItem.children ? (
            menuItem.name
          ) : (
            <Link to={`${menuItem?.isAiConfig ? aiProjectId + '/' : ''}${menuItem.path}`}>{menuItem.name}</Link>
          ),
          icon: menuItem.icon,
          key: menuItem.path.split('/').at(-1),
          children: getMenu(menuItem.children),
        };
      });
  };

  return (
    <Menu
      theme="dark"
      mode={mode}
      defaultOpenKeys={['project-config', 'ai-config']}
      selectedKeys={selectedKeys}
      onSelect={onSelect}
      onOpenChange={onOpenChange}
      items={getMenu(routerExtras)}
      inlineIndent={12}
    />
  );
};

export default SiderMenu;

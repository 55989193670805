import React from 'react';
import avatorImg from '@/assets/image/judge.png';
import styles from './issueCard.module.less';

type Props = {
  name: string;
  issue: string;
};

export default function issueCard({ name, issue }: Props) {
  return (
    <div className={styles('issue-card-wrapper')}>
      <div className={styles('issue-avator-wrapper')}>
        <div className={styles('issue-card-avatar')}>
          <img src={avatorImg} alt=""></img>
        </div>
        <div className={styles('issue-card-judge-name')}>{name}</div>
      </div>
      <div className={styles('issue-card-issue')}>{issue}</div>
    </div>
  );
}

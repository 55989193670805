import { ServiceStatus } from '@/typings/common';
import { ProjectType } from './const';

export const isTemplateProject = (item?: Project) => {
  return item?.tag === ProjectType.template;
};

export const toResourceStr = (resStr: string) => {
  if (!resStr) {
    return '未知资源';
  }
  try {
    const res = JSON.parse(resStr);
    return `CPU: ${res.cpu?.value + res.cpu?.unit};内存: ${res.ram?.value + res.ram?.unit};GPU: ${res.gpu?.value + res.gpu?.unit
      };使用时长：${res.duration?.value + res.duration?.unit}`;
  } catch (error) {
    return resStr;
  }
};

export const canPublishService = (service?: Service) => {
  if (!service) {
    return false;
  }
  return [ServiceStatus.Running, ServiceStatus.Error, ServiceStatus.AuditFial, ServiceStatus.Pending].includes(
    service?.status as ServiceStatus
  );
};
import React, { useState, useEffect } from 'react';
import { Menu } from '@fuxi/eevee-ui';
import { Link, useLocation, useParams } from 'react-router-dom';
import { IRouterExtra, mlExtras } from '@/router/config';

interface Props {
  mode: 'vertical' | 'horizontal' | 'inline';
}
const MlSiderMenu: React.FC<Props> = ({ mode }) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]); // 选中的菜单项，单项
  const { pathname } = useLocation();
  const urlParams = useParams();
  const projectId = urlParams.projectId;
  const baseUrl = `/ml/${projectId}`;

  useEffect(() => {
    const currentPath = pathname.split(`${urlParams.projectId}/`).at(-1);
    const pathnameArray = currentPath!.split('/');
    setSelectedKeys(pathnameArray);
  }, [pathname]);

  // 被选中时调用
  function onSelect({ selectedKeys }) {
    setSelectedKeys(selectedKeys);
  }

  const getMenu = (routerExtras: IRouterExtra[] | undefined) => {
    if (!routerExtras) return;
    return routerExtras.map(menuItem => {
      return {
        label: menuItem.children ? menuItem.name : <Link to={baseUrl + menuItem.path}>{menuItem.name}</Link>,
        icon: menuItem.icon,
        key: menuItem.path.split('/').at(-1),
        children: getMenu(menuItem.children),
      };
    });
  };

  return (
    <Menu
      theme="dark"
      mode={mode}
      defaultOpenKeys={['ai-platform', 'ml-public-ability']}
      selectedKeys={selectedKeys}
      onSelect={onSelect}
      items={getMenu(mlExtras)}
      inlineIndent={12}
    />
  );
};

export default MlSiderMenu;

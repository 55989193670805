import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getCommonConfigs } from '@/service/commonConfig';
import { AppThunk } from '.';

type CommonConfigState = {
  data: { [key: string]: any };
};

const initialState: CommonConfigState = { data: {} };

const commonConfig = createSlice({
  name: 'commomConfig',
  initialState,
  reducers: {
    updateCommonConfig: (state, { payload }: PayloadAction<CommonConfigState>) => {
      state.data = payload;
    },
  },
});
export const { updateCommonConfig } = commonConfig.actions;
export default commonConfig.reducer;

export const queryCommonConfigs = (): AppThunk => async (dispatch, getState) => {
  getCommonConfigs().then(res => {
    if (res.status === 200) {
      dispatch(updateCommonConfig(res.data));
    }
  });
};

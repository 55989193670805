import { useEffect, useState } from 'react';
import { usePagination } from 'ahooks';
import { Card, Col, Pagination, Row, Tooltip, Image, Flex, Spin, Empty } from '@fuxi/eevee-ui';
import { useNavigate } from 'react-router-dom';
import { PublicAbilityInList } from '@/@types/ml';
import { getMlPublicCapabilitiesByPage } from '@/service/ml';
import ProjectImg from '@/assets/image/mlProjectImg.png';
import EmptyImg from '@/assets/image/empty2.png';

import { useAppSelector } from '@/hooks';
import { AbilityTagKey, getAbilityTagValue } from '@/constants/abilityTags';
import { AbilityTagFilter } from '../components/AbilityTagFilter/AbilityTagFilter';
import { AbilityTag } from '../components/AbilityTag';
import { MlSearchInput } from '../components/MlSearchInput';
import { onMlImgError } from '../utils/onMlImgError';
import cx from './index.module.less';

export const MlPublicAbilityList = () => {
  const navigate = useNavigate();
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const [currentTag, setCurrentTag] = useState<string[]>([AbilityTagKey.All, AbilityTagKey.SubAll]); // [一级标签，二级标签]
  const [keyword, setKeyword] = useState<string>();
  const { data, pagination, run, loading } = usePagination(getMlPublicCapabilitiesByPage, {
    defaultPageSize: 8,
    manual: true,
  });

  const mlCategory = useAppSelector(state => state.mlCategory.categories);

  const publicCapsList: PublicAbilityInList[] = data?.list || [];

  useEffect(() => {
    if (!!aiProjectId) {
      run({
        current: pagination.current,
        pageSize: pagination.pageSize,
      });
    }
  }, [aiProjectId]);

  const colLayout = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 8,
    xl: 6,
  };

  const handleTagChange = (tagsValue: string[]) => {
    setCurrentTag(tagsValue);
    run({
      current: 1,
      pageSize: pagination.pageSize,
      keyword,
      ...{ ...getCurrentTagValue(tagsValue) },
    });
  };

  const turnToDetail = (id: string) => {
    navigate(`${id}`);
  };

  const getCurrentTagValue = (currentTag: string[]) => {
    const [first_category, second_category] = currentTag;
    return {
      first_category: first_category === AbilityTagKey.All ? undefined : first_category,
      second_category: second_category === AbilityTagKey.SubAll ? undefined : second_category,
    };
  };

  const onSearch = value => {
    run({
      current: 1,
      pageSize: pagination.pageSize,
      keyword: value,
      ...getCurrentTagValue(currentTag),
    });
  };

  const onKeywordChange = (keyword: string) => {
    setKeyword(keyword);
  };

  return (
    <Spin spinning={loading} tip="正在获取公共能力列表...">
      <Flex className={cx('wrapper')} flexDirection="column" justifyBetween>
        <div>
          <Flex justifyBetween alignCenter className={cx('title')}>
            公共能力
            <MlSearchInput
              onKeywordChange={onKeywordChange}
              onSearch={value => onSearch(value)}
              placeholder={'请输入 能力名称 进行搜索'}
            />
          </Flex>

          <AbilityTagFilter handleTagChange={handleTagChange}></AbilityTagFilter>
          {!!publicCapsList?.length ? (
            <Row className={cx('cards-wrapper')} gutter={[24, 24]}>
              {publicCapsList.map(item => (
                <Col key={item.uuid} {...colLayout}>
                  <Card onClick={() => turnToDetail(item.uuid)} className={cx('ability-card')}>
                    <div className={cx('img-wrapper')}>
                      <Image
                        src={item.image_url ?? ProjectImg}
                        onError={onMlImgError}
                        preview={false}
                        className={cx('card-img')}
                      />
                    </div>
                    <div className={cx('ability-info')}>
                      <Flex alignCenter justifyBetween>
                        <Tooltip
                          placement="topLeft"
                          title={
                            <span
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}>
                              {item.name}
                            </span>
                          }>
                          <div title={item.name} className={cx('ability-name')}>
                            {item.name}
                          </div>
                        </Tooltip>

                        <div className={cx('tag-wrapper')}>
                          <AbilityTag value={getAbilityTagValue(mlCategory, item.category_uuid || '')} />
                        </div>
                      </Flex>

                      <Tooltip placement="topLeft" overlayClassName={cx('des-tooltip')} title={item.description}>
                        <div className={cx('ability-desc')}>{item.description}</div>
                      </Tooltip>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <Empty className={cx('empty-placeholder')} image={EmptyImg} description="暂无数据" />
          )}
        </div>

        <Flex.Item alignSelf="end">
          <Pagination
            showQuickJumper
            className={cx('pagination')}
            onChange={pagination.onChange}
            current={pagination.current}
            total={pagination.total}
            pageSize={pagination.pageSize}
            showSizeChanger
            pageSizeOptions={[8, 10, 16, 32, 64]}
            showTotal={() => `共 ${pagination.total} 条`}></Pagination>
        </Flex.Item>
      </Flex>
    </Spin>
  );
};

import { useEffect, useState } from 'react';
import { requestConfirm } from '@ai-training/utils';
import numeral from 'numeral';
import { usePagination } from '@fuxi/eevee-hooks';
import { Button, Flex, Table, TableButtons, Tooltip } from '@fuxi/eevee-ui';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { getMlDatasetByPage } from '@/service/ml';
import { useAppSelector } from '@/hooks';
import service from '@/service';
import { MlSearchInput } from '../../components/MlSearchInput';
import { IDatasetItem, ImportStatusMap, annotationTypeMap } from '../const';
import cx from './index.module.less';

const getNumber = (num: any) => numeral(num).value();

export const DatasetList = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState<string>();
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const { data, pagination, run, loading } = usePagination(
    ({ current, pageSize }) =>
      getMlDatasetByPage({
        current,
        pageSize,
        keyword,
      }),
    {
      defaultPageSize: 10,
      manual: true,
    }
  );

  useEffect(() => {
    if (!aiProjectId) {
      return;
    }
    run({ current: 1, pageSize: 10 });
  }, [aiProjectId]);

  const columns: ColumnsType<IDatasetItem> = [
    {
      title: '数据集名称',
      dataIndex: 'zh_name',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <Tooltip placement="topLeft" title={record.zh_name}>
          <Link type="link" to={`${record.first_version_id}/${record.id}`}>
            {record.zh_name}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: '数据集类型',
      dataIndex: 'classification',
    },
    {
      title: '标注类型',
      dataIndex: 'annotation_type',
      render: (_, record) => {
        return <span>{annotationTypeMap[record.annotation_type as string]}</span>;
      },
    },
    {
      title: '数据总量',
      dataIndex: 'first_version_data_count',
    },
    {
      title: '标注进度',
      dataIndex: ['first_version_data_count', 'first_version_annotated_data_count'],
      render: (val, record) => (
        <div>
          <span style={{ paddingRight: 8 }}>
            {numeral(
              (getNumber(record?.first_version_annotated_data_count) * 100) /
                getNumber(record?.first_version_data_count)
            ).format('0.0')}
            %
          </span>
          {record.first_version_data_count ? (
            <span>
              ({record.first_version_annotated_data_count}/{record.first_version_data_count})
            </span>
          ) : null}
        </div>
      ),
    },
    {
      title: '导入状态',
      dataIndex: 'import_status',
      render: (_, record) => {
        return (
          <Flex alignCenter>
            <div className={cx('status-icon', `status-icon-${record.first_version_data_import_status ?? '-'}`)}></div>
            {ImportStatusMap[record.first_version_data_import_status as string] ?? '-'}
          </Flex>
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      width: 200,
    },
    {
      title: '操作',
      render: (_, record) => (
        <TableButtons
          data={[
            {
              text: '导入',
              disabled: record.first_version_data_import_status === 'importing',
              onClick: () =>
                navigate(
                  `import/${record.first_version_id}?annotateType=${record.annotation_type}&datasetType=${record.classification}`
                ),
            },
            {
              text: '标注',
              onClick: () => navigate(`annotate-${record.annotation_type}/${record.first_version_id}/${record.id}`),
            },
            {
              text: '删除',
              type: 'danger',
              onClick: () =>
                requestConfirm({
                  title: `确定删除数据集 ${record.name}?`,
                  content: '数据集及其版本将全部被删除，且不可恢复。',
                  payload: {
                    datasetId: record.id,
                  },
                  submitApi: service.ml.deleteDataset,
                }).then(res => {
                  if ((res as { data: boolean }).data) {
                    run({
                      current: pagination.current,
                      pageSize: pagination.pageSize,
                      keyword,
                    });
                  }
                }),
            },
          ]}
        />
      ),
    },
  ];

  const submit = (keyword: string) => {
    run({
      current: 1,
      pageSize: pagination.pageSize,
      keyword,
    });
  };
  const onKeywordChange = (keyword: string) => {
    setKeyword(keyword);
  };

  return (
    <div>
      <div className={cx('title')}>数据集</div>

      <Flex justifyBetween>
        <Flex alignCenter>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate('create')}>
            创建数据集
          </Button>
        </Flex>
        <div>
          <MlSearchInput
            deps={[aiProjectId]}
            onKeywordChange={onKeywordChange}
            placeholder={'请输入 数据集 名称'}
            onSearch={keyword => submit(keyword)}
          />
        </div>
      </Flex>

      <Table
        loading={loading}
        className={cx('table')}
        pagination={{
          pageSize: pagination.pageSize,
          showQuickJumper: true,
          onChange: pagination.onChange,
          current: pagination.current,
          total: pagination.total,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 30, 50],
          showTotal: () => `共 ${pagination.total} 条`,
        }}
        columns={columns}
        dataSource={data?.list?.map(item => ({ ...item, key: item.id }))}
      />
    </div>
  );
};

import client from '@/service/graphql';
import { HistoryLogOptions, LogItem, PublishLogItem, PublishLogs } from '@/store/log';
import store from '@/store';
import { Env, getEnv } from '@/utils/getEnv';

import { Pod } from '@/store/realtimeLogs';

import axios, { WebInstance } from '@/service/common';
import { GET_HISTORY_LOG, GET_PUBLISH_LOGS, GET_POD_INFO_LIST, GET_REALTIME_LOG_URL } from './schema/log';

export const getHistoryLog = (options: any) => {
  return client.query<{ logSearchInput: HistoryLogOptions }, { logSearch: any }>(GET_HISTORY_LOG, {
    logSearchInput: { ...options, size: 20 },
  });
};

export const getPublishLogs = (input: string) => {
  return client.query<{ input: string }, { publishLog: PublishLogItem[] }>(GET_PUBLISH_LOGS, {
    input,
  });
};

export const getLogsFromShuyuan = async (options: {
  startdate?: string;
  enddate?: string;
  keyword?: string;
  reverse?: boolean;
}) => {
  const env = getEnv();
  const queries: any = {};
  queries.table = env === Env.Dev ? 'lowcodedevdb' : env === Env.Qa ? 'lowcodetestdb' : 'lowcodeproddb';
  queries.startrow = '';
  queries.endrow = '';
  queries.colfamily = 'c';
  queries.colnames = 'msg,log';
  queries.fuzzyfiltercolname = 'c:msg';
  queries.fuzzyfilterkeyword = options.keyword;
  queries.pagesize = '10';
  const url = `/shuyuan/api/v1/hbase/scanrowbypage?${Object.keys(queries)
    .map(k => {
      if (queries[k]) {
        return k + '=' + queries[k];
      }
      return undefined;
    })
    .filter(s => !!s)
    .join('&')}`;
  const rawlogs = await axios.get(url);
  const logs = Object.keys(rawlogs.data).map(k => {
    const [namespace, datetime, uid] = k.split('#');
    const value = JSON.parse(rawlogs.data[k]['c:log']);
    const log: LogItem = {
      uid,
      content: value.msg,
      createdAt: datetime,
      level: value.level,
      role: value.role,
    };
    return log;
  });
  if (options.reverse) {
    return logs.reverse();
  }
  return logs;
};

export const getPodInfoList = (taskId: string) => {
  const projectId = store.getState().project.currentProject?.id;
  return WebInstance.get(`/edge/project/${projectId}/podInfoList`,{
    params: {
      taskId,
    },
  });
};

export const getRealtimeLogUrl = (input: { taskId: string; podNameList: string }) => {
  const projectId = store.getState().project.currentProject?.id;
  const { taskId, podNameList } = input;
  return WebInstance.get(`/edge/project/${projectId}/podListLogUrl`,{
    params: {
      taskId,
      podNameList,
    },
  });
};

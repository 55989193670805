import React, { useState } from 'react';
import { useAntdTable, useRequest } from '@fuxi/eevee-hooks';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Flex, Button, Input } from '@fuxi/eevee-ui';

import service from '../../service';
import { getSorterTableData } from '../../utils/util';
import { AbilityTagInfo, ProjectDetail } from '../../types/ability';
import { getProjectColumns } from './const';

import cx from './index.module.less';

const AbilityProject: React.FC = () => {
  const history = useNavigate();
  const [keyword, setKeyword] = useState<string>();
  const urlParams = useParams();
  const [projectType, setProjectType] = useState<'INNER' | 'OUTER'>('INNER');

  const {
    tableProps,
    search: { submit },
    refresh,
  } = useAntdTable(getSorterTableData(service.ability.getCapability, { extraParams: { name: keyword, share: false } }));

  /* 获取项目类型 */
  useRequest(() => service.projectManage.projectType(urlParams.currentProjectId), {
    onSuccess(res) {
      setProjectType(res.data);
    },
  });

  return (
    <>
      <div className={cx['main-page-wrapper']}>
        <div className={cx['main-page-title']}>项目能力</div>
        <Flex justifyBetween style={{ marginBottom: 20 }}>
          {projectType === 'INNER' ? (
            <Flex alignCenter>
              <Button type="primary" onClick={() => history('create')}>
                <PlusOutlined />
                创建能力
              </Button>
            </Flex>
          ) : null}
          <div>
            <Input
              onChange={e => setKeyword(e.target.value)}
              placeholder="请输入能力名称"
              style={{ width: '300px', marginRight: 12 }}
            />
            <Button className="search-btn" type="primary" onClick={() => submit()}>
              搜索
            </Button>
          </div>
        </Flex>
        <Table
          columns={getProjectColumns({ history, refresh })}
          {...tableProps}
          pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
          rowKey="id"
        />
      </div>
    </>
  );
};

export default AbilityProject;

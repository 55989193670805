/* eslint-disable max-depth */
import { useEffect, useState } from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Checkbox, Divider, Flex, Input, List, message, Modal, Row, Select, Spin, Tooltip } from '@fuxi/eevee-ui';
import { Terminal } from '@/store/terminal';
import { forceToPushServiceVersion } from '@/service/version';
import ProjectDetailContainer from '@/pages/project/ProjectDetail/ProjectDetailContainer';
import cx from './index.module.less';

interface Prop {
  // fetchTerminalInfoLoading: boolean;
  service: Service;
  serviceVersions: PublishVersion[];
  children?: React.ReactNode;
  initialVersionId?: string;
}
export const ForcePushTerminalVersionModal = (props: Prop) => {
  const container = ProjectDetailContainer.useContainer();
  const { fetchTerminalInfoLoading, currentProject } = container;
  const { children, service, serviceVersions, initialVersionId } = props;
  const [terminals, setCurrentTerminals] = useState<Terminal[]>([]);
  // const terminals: Terminal[] = (container?.terminalsData?.edgeNodes as Ter

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(initialVersionId);

  const [choosedDevicesList, setChoosedDevicesList] = useState<Terminal[]>([]);

  const [choicesOnShow, setChoicesOnShow] = useState(terminals);

  const handleOk = async () => {
    setConfirmLoading(true);

    if (choosedDevicesList?.length === 0) {
      message.error('未选择端侧设备');
      setConfirmLoading(false);
      return;
    }

    try {
      if (selectedVersion) {
        const res = await forceToPushServiceVersion(
          currentProject?.id,
          service?.id,
          selectedVersion,
          choosedDevicesList?.map(item => +item.id)
        );
        if (res?.forceToPushServiceVersion?.code === 0) {
          message.success('推送成功');
        } else {
          setConfirmLoading(false);
          message.error(res?.forceToPushServiceVersion?.msg);
          return;
        }
      } else {
        message.error('未选择端侧版本');
        setConfirmLoading(false);
        return;
      }
    } catch (e) {
      setConfirmLoading(false);
    }

    setConfirmLoading(false);
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (!isModalVisible) {
      clearAll();
      setSelectedVersion(initialVersionId);
      setChoicesOnShow(terminals);
    }
  }, [isModalVisible]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (!fetchTerminalInfoLoading) {
      setCurrentTerminals(
        container?.terminalsData?.edgeNodes?.filter((item: Terminal) => item.serverId === service?.id)
      );
    }
  }, [fetchTerminalInfoLoading, service]);

  useEffect(() => {
    setChoicesOnShow(terminals);
  }, [terminals]);

  useEffect(() => {
    if (!terminals?.length) {
      return;
    }

    setIndeterminate(choosedDevicesList?.length > 0 && choosedDevicesList?.length < terminals.length);
    setCheckAll(choosedDevicesList?.length === terminals?.length);
  }, [choosedDevicesList?.length]);

  const onCheckboxChange = (checked: boolean, device: Terminal) => {
    checked
      ? setChoosedDevicesList([...choosedDevicesList, device])
      : setChoosedDevicesList(choosedDevicesList.filter(dev => dev.id !== device.id));
  };

  const clearAll = () => {
    setChoosedDevicesList([]);
  };

  const deviceChoosed = (device: Terminal) => {
    return !!choosedDevicesList?.find(item => item.id === device.id);
  };

  const onSelectVersion = (e: any) => {
    setSelectedVersion(e);
  };

  const filterDevice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChoicesOnShow(terminals.filter((terminal: Terminal) => terminal.name.includes(e.target.value)));
  };

  const getVersionById = (id?: string) => {
    return serviceVersions?.find(s => s.tag === id);
  };

  const onCheckAllChange = () => {
    const isSelectAll = terminals?.length === choosedDevicesList?.length;
    setChoosedDevicesList(isSelectAll ? [] : terminals);
    setIndeterminate(false);
    // setCheckAll(e.target.checked);
  };

  const handlePushButtonClick = () => {
    if (!service?.id) {
      message.error('请先选择服务器');
      return;
    }

    setIsModalVisible(true);
  };

  return (
    <div>
      <div onClick={handlePushButtonClick}>{children}</div>
      <Modal
        confirmLoading={confirmLoading}
        width={740}
        className={cx('wrapper')}
        destroyOnClose
        // confirmLoading={confirmLoading}
        maskClosable={false}
        title={'强制推送'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <Spin tip="正在获取终端设备" spinning={fetchTerminalInfoLoading}>
          <div className={cx('title')}>推送版本</div>
          <Flex alignCenter justifyBetween className={cx('select-wrapper')}>
            <span>端侧版本：</span>
            <Flex.Item>
              <Select
                dropdownClassName={cx('select-dropdown')}
                disabled={!!initialVersionId}
                className={selectedVersion ? cx('version-selector') : cx('version-selector-required')}
                value={selectedVersion}
                onSelect={onSelectVersion}>
                {serviceVersions?.map(version => (
                  <Select.Option value={version.tag} key={version.tag}>
                    {version.alias}
                  </Select.Option>
                ))}
              </Select>
            </Flex.Item>
          </Flex>

          <div className={cx('verion-info-wrapper')}>
            <div className={cx('version-alias')}>版本别名：{getVersionById(selectedVersion)?.alias}</div>
            <div>发布时间：{getVersionById(selectedVersion)?.publishTime}</div>
          </div>
          <div className={cx('title-choose-device')}>设备选择</div>
          <div className={cx('hint')}>
            被选择的设备将被进行强制版本推送，用于：① 开发阶段的设备初始化；② 设备升级功能坏掉后的修复
          </div>

          <Flex className={cx('device-choose-wrapper')}>
            <Flex flexDirection="column">
              <div>
                <Checkbox
                  className={cx('select-all-button')}
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}>
                  全选
                </Checkbox>
                <Input
                  onChange={e => filterDevice(e)}
                  className={cx('search-input')}
                  placeholder="搜索设备"
                  suffix={<SearchOutlined ></SearchOutlined>}></Input>
              </div>

              <div className={cx('service-name')}>来自：{service?.name}</div>

              <Checkbox.Group value={choosedDevicesList.map(item => item.id)}>
                <List
                  pagination={{
                    showQuickJumper: false,
                    // showSizeChanger: false,
                    size: 'small',
                    // pageSize: 7,
                    // hideOnSinglePage: true,
                  }}
                  dataSource={choicesOnShow}
                  renderItem={device => (
                    <List.Item className={deviceChoosed(device) ? cx('choosed') : undefined}>
                      <Row key={device.id}>
                        <Checkbox
                          onChange={e => onCheckboxChange(e.target.checked, device)}
                          className={cx('checkbox')}
                          value={device.id}>
                          <span className={deviceChoosed(device) ? cx('device-name-choosed') : cx('device-name')}>
                            <Tooltip placement="topLeft" title={device.name}>
                              {device.name}
                            </Tooltip>
                          </span>
                        </Checkbox>
                      </Row>
                    </List.Item>
                  )}
                />
              </Checkbox.Group>
            </Flex>

            <Divider type="vertical" style={{ height: '100%' }}></Divider>

            <div className={cx('choosed-tags-wrapper')}>
              <Flex justifyBetween className={cx('tags-action-wrapper')}>
                <span>已选择({choosedDevicesList?.length})</span>
                <span onClick={clearAll} className={cx('clear')}>
                  清空
                </span>
              </Flex>

              <Flex wrap className={cx('tags-list')}>
                {choosedDevicesList.map(device => (
                  <div key={device.id} className={cx('choosed-device-tag')}>
                    <Flex alignCenter>
                      <div className={cx('tag-name')}>
                        <Tooltip placement="topLeft" title={device.name}>
                          {device.name}
                        </Tooltip>
                      </div>
                      <CloseOutlined
                      
                        onClick={() => onCheckboxChange(false, device)}
                        className={cx('close-icon')}></CloseOutlined>
                    </Flex>
                  </div>
                ))}
              </Flex>
            </div>
          </Flex>
        </Spin>
      </Modal>
    </div>
  );
};

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, message, Tooltip } from '@fuxi/eevee-ui';
import { throttle } from 'lodash';

const { confirm } = Modal;

// 防止重复点击打开多个弹窗
const throttledConfirm = throttle(confirm, 1000, {
  leading: true,
  trailing: false,
});

export const requestConfirm = config =>
  new Promise((resolve, reject) => {
    const { submitApi, payload, title = '' } = config;

    throttledConfirm({
      icon: <ExclamationCircleFilled />,
      ...config,
      title: <Tooltip title={title}>{title}</Tooltip>,
      async onOk() {
        const res = await submitApi(payload);
        if (!res) {
          return;
        }
        message.success({ type: 'success', content: '操作成功' });
        resolve(res);
      },
      onCancel() {
        reject('取消');
      },
      className: 'ai-training-confirm-modal',
      closable: true,
      autoFocusButton: null,
    });
  });

import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Form, message } from '@fuxi/eevee-ui';
import { useImgUpload } from '@/hooks/useImgUpload';
import { useAppDispatch } from '@/hooks';
import { getMlProjectList } from '@/store/project';
import ProjectContainer from './ProjectContainer';
import { modalWidth, baseUrlSuffix } from './const';
import ProjectForm from './ProjectForm';
import { CreateProjectType } from '@/constants/ownership';

const CreateProject: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const container = ProjectContainer.useContainer();
  const { showCreateModal, userInfo, setShowCreate, postCreateProject } = container;
  const [form] = Form.useForm();
  const { iconFile, setIconFile, imgChanged, setImgChanged, waitImgUpload } = useImgUpload();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const createProjectIdRef = useRef('');

  const initialValues = {
    name: '',
    baseUrl: baseUrlSuffix,
    identifier: '',
    description: '',
    type: CreateProjectType.PERSONAL,
    users: [{ email: userInfo.email, name: userInfo.name, roles: userInfo.roles || [] }],
  };

  // eslint-disable-next-line react-func/max-lines-per-function
  const handleCreateProject = async () => {
    try {
      setConfirmLoading(true);
      const values = await form.validateFields();
      const payload = {
        name: values.name,
        identifier: values.identifier,
        description: values.description,
        users: values.users,
        iconUrl: '',
        isTerminal: values.isTerminal,
        type: values.type,
      };
      
      await waitImgUpload(imgChanged, iconFile, payload);

      postCreateProject({ variables: { createProjectInput: payload } })
        .then(res => {
          createProjectIdRef.current = res?.data?.createProject?.id;
          setConfirmLoading(false);
          setImgChanged(false);
          handleCloseModal();
          dispatch(getMlProjectList());
        })
        .catch(e => setConfirmLoading(false));
    } catch (e) {
      setConfirmLoading(false);
      console.log(e);
    }
  };

  const handleCloseModal = () => {
    form.resetFields();
    setShowCreate(false);
  };

  return (
    <Modal
      visible={showCreateModal}
      title="新建项目"
      className="project-modal"
      okText="确认"
      cancelText="取消"
      onOk={handleCreateProject}
      onCancel={handleCloseModal}
      afterClose={() => {
        createProjectIdRef.current &&
          navigate({ pathname: `/project/${createProjectIdRef.current}/detail/project-config/project-desc` });
      }}
      okButtonProps={{ loading: confirmLoading }}
      centered
      width={modalWidth}>
      <ProjectForm
        setIconFile={setIconFile}
        setImgChanged={setImgChanged}
        isEdit={false}
        form={form}
        initialValues={initialValues}
        userInfo={userInfo}
      />
    </Modal>
  );
};

export default CreateProject;

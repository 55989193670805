import { useEffect, useState } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';
import { useParams } from 'react-router-dom';
import { Badge, Card, Col, Descriptions, Divider, Flex, Icon, Row, Spin, Tooltip, message } from '@fuxi/eevee-ui';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LineConfig, Line } from '@ant-design/charts';
import { serviceStatusTypeMap } from '@ai-training/constants/onlineService';
import EditText from '@ai-training/components/EditText';
import dayjs from 'dayjs';
import { mlModalService } from '@/service/ml';
import { useAppSelector } from '@/hooks';
import { MonitorCategorys, MonitorType } from '@/@types/ml';
import { MlDatePicker, RangeType } from '../components/MlDatePicker';
import cx from './index.module.less';

export const ModalServiceDetail = () => {
  const [rangeType, setRangeType] = useState<RangeType>('day');
  const [timestampRange, setTimestampRange] = useState<[number, number] | undefined>(undefined);
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId)!;
  const { serviceId } = useParams();
  const { appDetails, editRelease, monitor } = mlModalService;
  const {
    data: serviceDetail,
    loading,
    run,
  } = useRequest(() => appDetails({ id: serviceId! }), {
    manual: true,
  });

  const {
    data: tpsMonitorData,
    run: fetchTpsMonitorData,
    loading: tpsLoading,
  } = useRequest(monitor, {
    manual: true,
  });

  const {
    data: rescodeMonitorData,
    run: fetchResCodeMonitorData,
    loading: codeLoading,
  } = useRequest(monitor, {
    manual: true,
  });

  const {
    data: resLatencyMonitorData,
    run: fetchResLatencyMonitorData,
    loading: latencyLoading,
  } = useRequest(monitor, {
    manual: true,
  });

  useEffect(() => {
    if (!aiProjectId) return;
    run();
  }, [aiProjectId]);

  useEffect(() => {
    if (!timestampRange || !serviceDetail?.data?.service_uuid) return;

    const params = {
      startTimestamp: timestampRange[0],
      endTimestamp: timestampRange[1],
      step: 239,
      uuid: serviceDetail?.data?.service_uuid,
      type: 'service',
    };

    fetchTpsMonitorData({
      ...params,
      target: MonitorType.TPS,
    });

    fetchResCodeMonitorData({
      ...params,
      target: MonitorType.RES_CODE,
    });

    fetchResLatencyMonitorData({
      ...params,
      target: MonitorType.LATENCY,
    });
  }, [timestampRange, rangeType]);

  const handelSave = async val => {
    try {
      await editRelease({ id: serviceId, desc: val });
      message.success('保存成功');
      run();
    } catch (e) {}
  };

  const serviceDetailColumns = [
    {
      label: '服务ID',
      id: 'id',
      ellipsis: true,
      children: <Tooltip title={serviceDetail?.data?.id}>{serviceDetail?.data?.service_uuid}</Tooltip>,
    },
    {
      key: 'capName',
      label: '能力名称',
      children: serviceDetail?.data?.capability_name || '-',
    },
    {
      label: '接口地址',
      id: 'domain',
      ellipsis: true,
      children: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Tooltip title={serviceDetail?.data.url}>
            <div className="ellipsis">{serviceDetail?.data.url}</div>
          </Tooltip>
          <CopyToClipboard text={serviceDetail?.data.url} onCopy={() => message.success('复制成功！')}>
            <Icon name="复制-项目中心" cursor={'pointer'} />
          </CopyToClipboard>
        </div>
      ),
    },
    {
      label: '能力ID',
      id: 'capability_uuid',
      children: serviceDetail?.data?.capability_uuid,
    },

    {
      label: '版本',
      id: 'version',
      children: serviceDetail?.data?.capability_version || '-',
    },
    {
      label: '服务状态',
      id: 'status',
      children: (
        <Badge
          color={serviceStatusTypeMap[serviceDetail?.data.status]?.color}
          text={serviceStatusTypeMap[serviceDetail?.data?.status]?.label}
        />
      ),
    },
    {
      label: '服务描述',
      key: 'desc',
      children: <EditText value={serviceDetail?.data?.description} onSave={handelSave} maxLength={200} />,
    },
  ];

  const config: LineConfig = {
    autoFit: true,
    data: [],
    padding: [40, 24, 74, 24],
    xField: 'date',
    yField: 'count',
    point: {
      size: 0,
    },
    yAxis: {
      label: {
        formatter: val => {
          return val;
        },
      },
    },
    xAxis: {
      label: {
        formatter: val => {
          return dayjs(val).format(rangeType === 'day' ? 'HH:mm' : 'MM-DD');
        },
      },
    },
    theme: {
      defaultColor: '#262a33',
    },
    legend: {
      title: {
        spacing: -18,
        text: ' ',
      },
      itemName: {
        style: {
          fill: 'rgba(255, 255, 255, 0.65)',
        },
      },
    },
  };

  const getLineData = (data, monitorType: MonitorType) => {
    if (!data?.data?.data) {
      return {
        ...config,
        data: [],
      };
    }

    const codeList = MonitorCategorys[monitorType];
    let lineData = [];
    codeList.forEach(code => {
      const oneCodeItems = data?.data?.data?.[monitorType]?.[code]?.map(item => ({
        date: dayjs.unix(item.timestamp).format('MM-DD HH:mm:ss'),
        count: +item.value,
        category: code,
      }));
      lineData = lineData.concat(oneCodeItems);
    });

    return {
      ...config,
      data: lineData,
      seriesField: 'category',
    };
  };

  const handleRangeChange = (e, rangeType: RangeType) => {
    setRangeType(rangeType);
    switch (rangeType) {
      case 'day':
        setTimestampRange([dayjs(e).startOf('day').unix(), dayjs(e).endOf('day').unix()]);
        break;
      case 'week':
        setTimestampRange([dayjs(e).startOf('week').unix(), dayjs(e).endOf('week').unix()]);
        break;
    }
  };

  return (
    <Spin spinning={loading} tip="正在获取服务详情...">
      <div className={cx('title')}>{serviceDetail?.data?.service_name || '-'}</div>
      <Descriptions className={cx('desc')} items={serviceDetailColumns} />
      <Divider className={cx('divider')} />
      <div className={cx('title')}>服务监控</div>

      {serviceDetail?.data && (
        <>
          <Flex justifyBetween className={cx('picker-wrapper')}>
            <Flex alignCenter>
              <label>统计范围：</label>
              <MlDatePicker selectRangeType={['day', 'week']} onChange={handleRangeChange} />
            </Flex>
          </Flex>

          <Row gutter={[12, 12]} className={cx('chart-wrapper')}>
            <Col span={12}>
              <Card loading={tpsLoading} title="TPS（单位：请求/s）" bordered className={cx('chart-card')}>
                <Line {...getLineData(tpsMonitorData, MonitorType.TPS)} />
              </Card>
            </Col>
            <Col span={12}>
              <Card loading={codeLoading} title="响应码统计（单位：个/s）" bordered className={cx('chart-card')}>
                <Line {...getLineData(rescodeMonitorData, MonitorType.RES_CODE)} />
              </Card>
            </Col>
            <Col span={12}>
              <Card loading={latencyLoading} title="响应延迟时间（单位：ms）" bordered className={cx('chart-card')}>
                <Line height={300} {...getLineData(resLatencyMonitorData, MonitorType.LATENCY)} />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Spin>
  );
};

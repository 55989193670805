/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-depth */
import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Tabs, Select, Button, Empty, Spin, Image, message, Input, Flex } from '@fuxi/eevee-ui';
import { UploadOutlined } from '@ant-design/icons';
import { utils, writeFileXLSX } from 'xlsx';

import { getReviewAppraisesAction, setIsSpinTrue, setSeasonStage } from '@/store/miniReview';
import uploadImg from '@/assets/image/upload.png';
import emptyImg from '@/assets/image/empty.png';
import { ProjectReview, uploadReviewAppraise } from '@/service/miniReview';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { stageOrderKey, stageTrans } from '@/constants/miniReviews';
import IconFont from '@/components/IconFont';
import ReviewItemCard from './components/reviewItemCard/reviewItemCard';
import {
  handle,
  ProjectSummary,
  reviewSortByValue,
  getUploadReviewAppraiseInput,
  getAppraiseSum,
  projectSummaryAverageKeys,
} from './utils';
import { useSeasonStageInfo } from './hooks/useSeasonStageInfo';
import styles from './miniReview.module.less';

const { TabPane } = Tabs;
const { Option } = Select;
const { Search } = Input;

const MiniReview: React.FC = () => {
  const { reviewStages, currentSeasonStage, projectReviewAppraises, projectWithAllJudges, isSpin } = useAppSelector(
    state => state.miniReview
  );
  const [currentSortBy, setCurrentSortBy] = useState('order');
  const [currentSortByDescribe, setCurrentSortByDescribe] = useState('抽签顺序');
  const [descending, setDescending] = useState(true);
  const [tabKey, setTabKey] = useState('评审问卷');
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const { seasonStageInfo, dispatchSeasonStage, miniSeasonList, stageLoading } = useSeasonStageInfo();
  const [projectSummary, setProjectSummary] = useState<ProjectSummary[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const appraiseSum = useMemo(() => getAppraiseSum(projectReviewAppraises), [projectReviewAppraises]);
  const currentSearchProject = useMemo(
    () =>
      projectReviewAppraises[seasonStageInfo.currentIndex]?.appraises.filter(item =>
        item.projectName.includes(seasonStageInfo.projectsSearchText.toLocaleLowerCase())
      ),
    [projectReviewAppraises, seasonStageInfo.currentIndex, seasonStageInfo.projectsSearchText]
  );
  const currentSearchProjectSummary = useMemo(
    () =>
      projectSummary.filter(item => item.projectName.includes(seasonStageInfo.summarySearchText.toLocaleLowerCase())),
    [projectSummary, seasonStageInfo.summarySearchText]
  );

  useEffect(() => {
    setProjectSummary(handle(projectReviewAppraises, projectWithAllJudges, currentSeasonStage.stage));
  }, [projectReviewAppraises, projectWithAllJudges, currentSeasonStage]);

  useEffect(() => {
    projectSummary.sort((a, b) =>
      descending
        ? b[currentSortBy + 'Average'] - a[currentSortBy + 'Average']
        : a[currentSortBy + 'Average'] - b[currentSortBy + 'Average']
    );
    setProjectSummary([...projectSummary]);
  }, [descending]);

  useEffect(() => {
    setTabKey((location.state as { tab?: string })?.tab || '评审问卷');
    return () => {
      dispatch(setIsSpinTrue());
    };
  }, []);

  const selectSort = (value: string, option: any) => {
    setCurrentSortBy(option.key);
    setCurrentSortByDescribe(value.substring(1, value.length - 2));
    projectSummary.sort((a, b) =>
      descending
        ? b[option.key + 'Average'] - a[option.key + 'Average']
        : a[option.key + 'Average'] - b[option.key + 'Average']
    );
    setProjectSummary([...projectSummary]);
  };

  const toReview = (historyState: { stageId: string } & Partial<ProjectReview>) => {
    navigate(`${location.pathname}/questionnaire`, { state: historyState });
  };

  const toSummary = (summaryInfo: ProjectSummary) => {
    navigate(location.pathname + '/resultSummary', { state: summaryInfo });
  };

  const changeSortSequence = () => {
    setDescending(!descending);
  };

  const uploadJudgeAllAppraise = async () => {
    setIsUploadComplete(true);
    const uploadReviewAppraiseInput = getUploadReviewAppraiseInput(currentSeasonStage.stageId, projectReviewAppraises);
    const res = await uploadReviewAppraise(uploadReviewAppraiseInput);
    if (res.uploadReviewAppraise) {
      await dispatch(getReviewAppraisesAction(currentSeasonStage.stageId));
      message.success('上传结果成功');
    } else message.error('上传结果失败，请重试');
    setTimeout(() => {
      setIsUploadComplete(false);
    }, 500);
  };

  const stageName = currentSeasonStage.stage ? stageTrans[currentSeasonStage.stage] : '';

  const handleSeasonChange = (value: string) => {
    dispatchSeasonStage({ type: 'updateSelectSeason', payload: miniSeasonList.find(season => season.id === value) });
  };

  const handleStageChange = (value: string) => {
    dispatchSeasonStage({ type: 'updateSelectedStage', payload: reviewStages.find(stage => stage.id === value) });
  };

  const handleSeasonStageChange = () => {
    setCurrentSortBy('order');
    setCurrentSortByDescribe('抽签顺序');
    dispatch(
      setSeasonStage({
        seasonId: seasonStageInfo.selectedSeason?.id,
        stageId: seasonStageInfo.selectedStage?.id,
        stage: seasonStageInfo.selectedStage?.stage,
      })
    );
  };
  const onProjectSearch = (value: string) => {
    dispatchSeasonStage({ type: 'updateProjectSearchText', payload: value.toLowerCase() });
  };

  const onProjectSummarySearch = (value: string) => {
    dispatchSeasonStage({ type: 'updateSummarySearchText', payload: value.toLowerCase() });
  };

  const toExportExcel = () => {
    try {
      if (!projectSummary.length) {
        message.info('暂无数据');
        return;
      }
      const wb = utils.book_new();
      const header = ['order', 'id', 'max', 'min', 'avg'];
      const xlsxName = `${stageName}-结果汇总`;

      const headerDisplay: Record<string, string> = {
        order: '抽签顺序',
        id: '组名',
        max: '最高分',
        min: '最低分',
        avg: '平均得分',
      };

      projectSummaryAverageKeys.forEach(item => {
        const [arrKey, avgKey, sheetName] = item;

        const data = projectSummary.map(project => {
          const isEmpty = !project[arrKey]?.length;
          const emptyContent = '暂无';
          return {
            order: project.order,
            id: project.projectName,
            max: !isEmpty ? Math.max(...project[arrKey]) : emptyContent,
            min: !isEmpty ? Math.min(...project[arrKey]) : emptyContent,
            avg: !isEmpty ? Number(project[avgKey].toFixed(1)) : emptyContent,
          };
        });

        const sheet: { data: Record<string, number | string>[]; name: string } = {
          data: [headerDisplay, ...data],
          name: sheetName,
        };
        const ws = utils.json_to_sheet(sheet.data, { header, skipHeader: true });
        const colWidth = [{ wpx: 60 }, { wpx: 120 }, { wpx: 60 }, { wpx: 60 }, { wpx: 60 }];
        ws['!cols'] = colWidth;
        utils.book_append_sheet(wb, ws, sheet.name);
      });

      writeFileXLSX(wb, `${xlsxName}.xlsx`);
    } catch (e) {
      message.error('导出失败');
    }
  };

  return (
    <>
      <Flex gap={12} alignCenter className={styles('season-stage-filter')}>
        <span>批次:</span>
        <Select
          className={styles('season-filter')}
          onChange={handleSeasonChange}
          value={seasonStageInfo?.selectedSeason?.id}>
          {miniSeasonList.map(season => (
            <Select.Option value={season.id} key={season.id}>
              {season.name}
            </Select.Option>
          ))}
        </Select>
        <span>评审轮次:</span>
        <Select
          loading={stageLoading}
          onChange={handleStageChange}
          className={styles('stage-filter')}
          value={seasonStageInfo?.selectedStage?.id}>
          {reviewStages.map(stage => (
            <Select.Option value={stage.id} key={stage.id}>
              {stageTrans[stage.stage] || ''}
            </Select.Option>
          ))}
        </Select>
        <Button disabled={stageLoading || isSpin} type="primary" onClick={handleSeasonStageChange}>
          查询
        </Button>
      </Flex>
      <Spin size="large" spinning={isSpin} style={{ paddingTop: '500px' }}>
        {!isSpin ? (
          <Card title="" className={styles('review-wrapper')} bodyStyle={{ padding: '2px 24px' }}>
            <Tabs onChange={setTabKey} activeKey={tabKey} className={styles('review-tabs')}>
              <TabPane tab={'评审问卷'} key={'评审问卷'}>
                {projectReviewAppraises.length === 0 ? (
                  <>
                    <div className={styles('review-stage-title')}>{`${stageName} | 所有问卷（0）`}</div>
                    <Empty
                      style={{ paddingBottom: '290px', paddingTop: '240px' }}
                      image={emptyImg}
                      imageStyle={{
                        height: 260,
                      }}></Empty>
                  </>
                ) : (
                  <>
                    <div className={styles('review-stage-title')}>
                      {`${stageName} | 所有问卷（${appraiseSum}）`}
                      <Button
                        className={styles('review-stage-title-button')}
                        type="primary"
                        loading={isUploadComplete}
                        onClick={() => {
                          uploadJudgeAllAppraise();
                        }}>
                        {isUploadComplete ? (
                          '上传中...'
                        ) : (
                          <>
                            <Image src={uploadImg} alt="" width={21} height={20} preview={false} />
                            发布评审结果
                          </>
                        )}
                      </Button>
                    </div>
                    <div className={styles('review-judge')}>
                      <span>评委： </span>
                      {projectReviewAppraises.map((item, index) => {
                        return (
                          <button
                            key={item.judge}
                            className={styles(
                              'review-judge-btn',
                              `${seasonStageInfo.currentIndex === index ? 'review-judge-btn-active' : ''}`
                            )}
                            onClick={() => {
                              dispatchSeasonStage({ type: 'updateCurrentIndex', payload: index });
                            }}>
                            {`${item.judgeName !== '' ? item.judgeName : item.judge} (${item.appraises.length})`}
                          </button>
                        );
                      })}
                    </div>
                    <div className={styles('review-project-search')}>
                      <Search placeholder="请输入项目名" allowClear enterButton="查询" onSearch={onProjectSearch} />
                    </div>
                    <div className={styles('review-item-wrapper')}>
                      {currentSearchProject.length === 0 ? (
                        <Empty
                          style={{ paddingBottom: '290px', paddingTop: '240px', width: '100%' }}
                          image={emptyImg}
                          imageStyle={{
                            height: 260,
                          }}></Empty>
                      ) : (
                        currentSearchProject.map(item => {
                          return (
                            <ReviewItemCard
                              toReview={() =>
                                toReview({
                                  stageId: currentSeasonStage.stageId,
                                  ...item,
                                })
                              }
                              key={item.projectName}
                              project={item}
                              result={'评审问卷'}
                            />
                          );
                        })
                      )}
                    </div>
                  </>
                )}
              </TabPane>
              <TabPane tab={'结果汇总'} key={'结果汇总'}>
                {projectSummary.length === 0 ? (
                  <>
                    <div className={styles('review-stage-title')}>
                      <span>{`${stageName} | 所有项目（0）`}</span>
                    </div>
                    <Empty
                      style={{ paddingBottom: '290px', paddingTop: '240px' }}
                      image={emptyImg}
                      imageStyle={{
                        height: 260,
                      }}></Empty>
                  </>
                ) : (
                  <>
                    <div className={styles('review-stage-title')}>
                      <span>{`${stageName} | 所有项目（${projectSummary.length}）`}</span>
                      <div className={styles('review-stage-select')}>
                        <Search
                          placeholder="请输入项目名"
                          allowClear
                          enterButton="查询"
                          onSearch={onProjectSummarySearch}
                        />
                        <span
                          className={styles(`review-stage-select-icon-${descending ? 'descending' : 'ascending'}`)}
                          onClick={() => {
                            changeSortSequence();
                          }}>
                          <IconFont type={'icon-order-descending'} />
                        </span>
                        <Select
                          defaultValue={'按抽签顺序排序'}
                          className={styles('review-stage-selected')}
                          onChange={selectSort}>
                          {[{ value: '抽签顺序', key: 'order' }].concat(reviewSortByValue).map(item => {
                            return (
                              <Option value={`按${item.value}排序`} key={item.key}>
                                {`按${item.value}排序`}
                              </Option>
                            );
                          })}
                        </Select>
                        <Button onClick={toExportExcel} icon={<UploadOutlined />} type="primary">
                          导出Excel
                        </Button>
                      </div>
                    </div>
                    <div className={styles('review-item-wrapper')}>
                      {currentSearchProjectSummary.length === 0 ? (
                        <Empty
                          style={{ paddingBottom: '290px', paddingTop: '240px', width: '100%' }}
                          image={emptyImg}
                          imageStyle={{
                            height: 260,
                          }}></Empty>
                      ) : (
                        currentSearchProjectSummary.map(item => {
                          return (
                            <ReviewItemCard
                              key={item.projectName}
                              project={item}
                              result={'结果汇总'}
                              sortBy={currentSortBy}
                              sortByDesc={currentSortByDescribe}
                              toSummary={() => {
                                toSummary(item);
                              }}
                            />
                          );
                        })
                      )}
                    </div>
                  </>
                )}
              </TabPane>
            </Tabs>
          </Card>
        ) : (
          <></>
        )}
      </Spin>
    </>
  );
};

export default MiniReview;

import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useAntModal } from '@fuxi/eevee-hooks';
import { v4 as uuid } from 'uuid';
import { Button, Divider, Flex, Form, Input, message, Modal, Select, Tag as AntdTag } from '@fuxi/eevee-ui';
import { PlusOutlined } from '@ant-design/icons';
import { DocumentNode, useMutation } from '@apollo/client';
import { uniq, uniqBy } from 'lodash';
import { queryIndustry, queryTags, Tag } from '@/store/template';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { CONVERT_TO_TPL } from '@/service/schema/project/project-mutations';
import { GET_PROJECT, GET_PROJECTS } from '@/service/schema/project/project-queries';
import { UPDATE_TPL } from '@/service/schema/template/template-mutation';
import { modalWidth } from '../const';
import ProjectDetailContainer from './ProjectDetailContainer';
import cx from './ProjectDetail.module.less';

type Props = {
  isEdit?: boolean;
  tempTags?: Tag[];
  children?: ReactNode;
};

const TplEditModal: React.FC<Props> = ({ children, isEdit, tempTags }) => {
  const [form] = Form.useForm();
  const { currentProject, userInfo } = ProjectDetailContainer.useContainer();
  const { tags, industry } = useAppSelector(state => state.template);
  const dispatch = useAppDispatch();
  const [customTags, setCustomTags] = useState<Tag[]>([]);
  const [inputError, setInputError] = useState(false);
  const [customTagName, setCustomTagName] = useState('');
  const inputRef = useRef<any>(null);

  // 转换成模板
  const [convert2Tpl, { loading: convertLoading }] = useMutation<
    DocumentNode,
    { input: { projectId: number; industryId: string; tags: string[]; contactInfo: string } }
  >(CONVERT_TO_TPL, { refetchQueries: [GET_PROJECTS, GET_PROJECT] });

  // 更新模板信息
  const [updateTplInfo, { loading: updateLoading }] = useMutation<
    DocumentNode,
    { input: { templateId: number; industryId: string; tags: string[]; contactInfo: string } }
  >(UPDATE_TPL, { refetchQueries: [GET_PROJECTS, GET_PROJECT] });

  const handleOkCallback = async () => {
    const values = await form.validateFields();
    if (!isEdit) {
      const data = await convert2Tpl({
        variables: {
          input: {
            projectId: currentProject.id,
            industryId: values.industry,
            tags: values.tag,
            contactInfo: values.contact,
          },
        },
      });
      data && message.success('模板转换成功！');
    } else {
      const data = await updateTplInfo({
        variables: {
          input: {
            templateId: currentProject.id,
            industryId: values.industry,
            tags: values.tag,
            contactInfo: values.contact,
          },
        },
      });
      data && message.success('更新模板信息成功！');
    }
  };

  const addTag = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!customTagName || !/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(customTagName)) {
      setInputError(true);
      customTagName && message.warning({ content: '标签只支持输入中英文和数字', key: 'tagNameValidator' });
      return;
    }
    if (
      tags
        .concat(customTags)
        .map(({ tagName }) => tagName)
        .includes(customTagName)
    ) {
      message.info('该标签已存在，自动为您选择');
    } else {
      setCustomTags(prev => [...prev, { tagName: customTagName, tagId: uuid(), creator: userInfo.email }]);
    }
    form.setFieldsValue({ ...form.getFieldsValue(), tag: uniq([...(form.getFieldValue('tag') || []), customTagName]) });
    setCustomTagName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onTagNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value && setInputError(false);
    setCustomTagName(e.target.value);
  };

  const { showModal, commonAntModalProps, visible } = useAntModal({
    handleOkCallback,
  });

  useEffect(() => {
    dispatch(queryTags());
    dispatch(queryIndustry());
  }, []);

  useEffect(() => {
    form.setFieldsValue(
      isEdit
        ? {
            contact: currentProject.contactInfo,
            tag: currentProject.tags?.map(t => t.tagName),
            industry: currentProject.industry?.industryId,
          }
        : { contact: userInfo.email }
    );
  }, [currentProject, visible]);

  return (
    <>
      <span onClick={showModal}>{children}</span>
      <Modal
        {...commonAntModalProps}
        title={isEdit ? `编辑 ${currentProject.name} 模板` : `将 ${currentProject.name} 转换为模板`}
        destroyOnClose
        bodyStyle={{ paddingBottom: 0 }}
        width={modalWidth}
        okButtonProps={{ loading: convertLoading || updateLoading }}>
        {visible && (
          <Form form={form} preserve={false} labelCol={{ span: 6 }}>
            <Form.Item
              name="industry"
              label="行业"
              rules={[
                {
                  required: true,
                  message: '请选择所在行业',
                },
              ]}>
              <Select
                options={industry.slice(1).map(item => ({
                  label: item.industryName,
                  key: item.industryId,
                  value: item.industryId,
                }))}
                placeholder="请选择所在行业"
              />
            </Form.Item>
            <Form.Item
              name="tag"
              label="标签"
              rules={[
                {
                  required: true,
                  message: '请选择标签',
                },
                {
                  validator: (_, value) => {
                    if (value?.length > 5) {
                      return Promise.reject(new Error('最多选择5个标签'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}>
              <Select
                className={cx('tag-selector')}
                mode="multiple"
                options={uniqBy(
                  tags
                    .slice(1)
                    .concat(tempTags || [])
                    .concat(customTags),
                  t => t.tagId
                ).map(item => ({
                  key: item.tagId,
                  value: item.tagName,
                }))}
                onDropdownVisibleChange={() => setInputError(false)}
                placeholder="选择标签（最多5个）"
                showArrow
                allowClear
                dropdownRender={menu => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Flex style={{ margin: '0 0 4px 8px' }}>
                      <Input
                        // 若antd 升级 到4.19.0 可以使用 status 或使用form表单
                        style={inputError ? { border: '1px solid #ec3646' } : {}}
                        autoComplete="off"
                        maxLength={20}
                        placeholder="自定义标签"
                        ref={inputRef}
                        value={customTagName}
                        onChange={onTagNameChange}
                      />
                      <Button type="text" icon={<PlusOutlined />} onClick={e => addTag(e)}>
                        添加
                      </Button>
                    </Flex>
                  </>
                )}
              />
            </Form.Item>
            <Form.Item
              name="contact"
              label="咨询联系方式"
              required
              rules={[
                {
                  max: 80,
                  message: '输入不得超过 80 字符',
                },
              ]}>
              <Input autoComplete="off" placeholder="请输入联系方式" />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default TplEditModal;

import { Form } from '@fuxi/eevee-ui';
import { FileType } from '../../MlDataset/const';
import UploadDatasetImg from '../UploadDatasetImg';
import { getUploadText } from '../../MlDataset/util';
import { UploadDataFile } from '../UploadFile';
import cx from './index.module.less';

type UploadFormItemProps = {
  fileType: FileType;
  from?: 'create' | 'import';
  payload?: {
    project_id?: string;
    upload_uuid?: string;
  };
};

const UploadLabel = () => {
  return <div className={cx('upload-label')}>数据上传</div>;
};

export const UploadFormItem: React.FC<UploadFormItemProps> = ({ fileType, payload, from = 'create' }) => {
  if (!fileType) {
    return (
      <Form.Item label={<UploadLabel />} className={cx('crete-upload-formitem')}>
        <div className={cx('upload-empty')}>暂无数据</div>
        <div className={cx('upload-tips')}>请先选择数据上传格式</div>
      </Form.Item>
    );
  }
  return fileType === FileType.Image ? (
    <Form.Item
      className={cx('crete-upload-formitem')}
      label={<UploadLabel />}
      name="filenames"
      trigger="validateFileName"
      validateTrigger={'validateFileName'}>
      <UploadDatasetImg btnText={getUploadText(fileType)} payload={payload} />
    </Form.Item>
  ) : (
    <Form.Item
      className={cx('crete-upload-formitem')}
      label={<UploadLabel />}
      name="filenames"
      trigger="validateFileName"
      validateTrigger={'validateFileName'}>
      <UploadDataFile
        btnText={getUploadText(fileType)}
        fileType={fileType}
        payload={payload}
        className={cx(from === 'create' ? 'crete-upload-formitem' : 'upload')}
      />
    </Form.Item>
  );
};

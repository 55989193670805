export interface PublicDetail {
  id: number | string;
  name: string;
  project_id: number;
  desc: string;
  share: boolean;
  updated_at: string;
  versions: number;
  uuid: string;
}

export const AbilityTagInfo = {
  main: {
    key: 'main',
    name: '主干',
    color: '#36BD1F',
    borderColor: 'rgba(54, 189, 31, 0.60)',
  },
  dev: {
    key: 'dev',
    name: '私服',
    color: '#E58616',
    borderColor: 'rgba(229, 134, 22, 0.60)',
  },
};

export enum ProjectAbilityTag {
  Main = 'main',
  Dev = 'dev',
}

export interface ProjectDetail {
  uuid: string;
  name: string;
  desc: string;
  project_id: number;
  share: boolean;
  updated_at: string;
  versions: number;
  base_capability: {
    id: number | string;
    name: string;
    version_id: string;
  };
  tag: ProjectAbilityTag;
}
export interface ProjectInfo {
  capability_id: string;
  capability_name: string;
  capability_version: string;
  domain: string;
  uuid: string;
  name: string;
  status: string;
  description: string;
  base_capability: {
    id?: string;
    name?: string;
    version?: string;
    version_id?: string;
  };
  tag: ProjectAbilityTag;
}
export interface VersionDetail {
  uuid: number | string;
  name: string;
  desc: string;
  train_status: string;
  duration: number;
  app_status: string;
  version_id: string;
  sdk_created: boolean;
  code: string;
}
export interface VersionDetailInfo {
  id: number | string;
  name: string;
  desc: string;
  inputs: string;
  outputs: string;
  version?: string;
  base_capability: {
    id: number | string;
    name: string;
    version_id: string;
  };
  train: {
    duration: number;
    ended_at: string;
    started_at: string;
  };
  capability_total: {
    capabilityVersion: any;
    capabilityVersionBase: any;
    capabilityVersionEvaluate: any;
    capabilityVersionService: any;
    capabilityVersionTrain: any;
  };
  extra?: any;
  create_by?: string;
  created_at?: string;
}

export interface OptionsForSelect {
  value: string;
  label: string;
}

export interface TreeNodeForSelect {
  title: string;
  value: string;
  key: string;
  children?: TreeNodeForSelect[];
}

// 用于和后端进行数据交互
export interface TrainFormDataConfig extends FormDataExtra {
  image_uri?: string | null;
  command?: string | null;
  git_uri?: string | null;
  git_branch?: string | null;
  project_id?: string | null;
  git_commit?: string | null;
  project_storage?: string | null;
  resources?: any;
  full_train?: boolean | null;
  desc?: string | null;
  description?: string | null;
  parameter_bucket_uuid?: string | null;
  parameter_name?: string | null;
  parameter_version?: string | null;
  version_uuid?: string | null;
  tensorboard_bucket_uuid?: string | null;
  envs?: any;
  input?: string | null;
  output?: string | null;
  dataset_type?: string | null;
  dataset_name?: string | null;
  dataset_id?: string | null;
  eval_dataset_name?: string | null;
  train_dataset_name?: string | null;
  version_id?: string | null;
  uuid?: string | null;
  model_version?: string[] | null;
  model_name?: string[] | null | string;
  train_dataset_id?: string | null;
  validate_dataset_id?: string | null;
  model_bucket_uuid?: string | null;
  // code?: string | null; // 在后端返回的数据里，capabilityVersion 代表的是能力版本的版本号
  model?: {
    model_name?: any;
    file_name?: string;
    local_path?: string;
  };
  client_max_batch?: number;
  server_max_batch?: number;
  server_max_delay?: number;
  dynamic_load_balance?: number;
}

export interface FormDataExtra {
  model?: {
    model_name?: [string];
    file_name?: string;
    local_path?: string;
    [key: string]: any;
  };
  dataset?: {
    input?: string | null;
    output?: string | null;
    dataset_type?: string | null;
    dataset_name?: string | null;
  };
}

export type BaseCapability = {
  id?: string | null;
  name?: string | null;
  version_id?: string | null;
};

// 用于表单回填
export interface TrainFormDataOrigin extends Omit<TrainFormDataConfig, 'envs' | 'resources'> {
  envs: {
    name: string;
    value: string;
  }[];
  resources: ResourceValue;
}

export interface ResourceValue {
  cpu?: number;
  cpuUnit?: string;
  memory?: number;
  memoryUnit?: IMemoryUnit;
  gpu?: number;
  gpu_type?: string;
}

export enum IMemoryUnit {
  GB = 'GB',
  MB = 'MB',
}

export interface FormValues {
  [key: string]: any;
}

export type ModelVersionList = {
  version: string; //类型：String  必有字段  备注：版本
  model_id: string; //类型：String  必有字段  备注：ID
  name: string; //类型：String  必有字段  备注：模型名
  attrs: string; //类型：String  必有字段  备注：评估效果
  size: string; //类型：String  必有字段  备注：大小
  published: boolean; //类型：Boolean  必有字段  备注：上架状态
  published_at: string; //类型：String  必有字段  备注：上架时间
  created_at: string; //类型：String  必有字段  备注：创建时间
  yl_tag: string; //类型：String  必有字段  备注：tag
};

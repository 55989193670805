// import Axios from './common';
import { ShanquanInstance } from "./common";
import { getClusterName } from '@ai-training/utils/environment';


const onlineService = {
  // 发布服务
  appStart(params: {
    capability_uuid: string;
    capability_version_uuid: string;
    service_name: string;
    url: string;
    description: string;
  }) {
    const cluster_name = getClusterName();
    return ShanquanInstance.post('/service/start', { ...params, cluster_name });
  },

  // 服务列表
  appList(params: {
    capability_uuid?: string;
    capability_version_uuid?: string;
  }) {
    return ShanquanInstance.get(`/services`, { params });
  },


  // 扩缩容服务
  appScale(params: {
    replicas: 0 | 1;
    service_uuid: string;
  }) {
    return ShanquanInstance.post(`/service/scale`, params);
  },


  // 暂停服务
  // appStop(params) {
  //   return ShanquanInstance.post(`/service/${params.id}/pause`);
  // },
  // 在线服务详情
  appDetails(params: {
    service_uuid: string;
  }) {
    return ShanquanInstance.get(`/service/${params.service_uuid}`, { params });
  },
  // 重启服务
  appRestart(params) {
    return ShanquanInstance.post(`/service/${params.id}/restart`);
  },
  // 删除模型服务
  appDelete(params: { service_uuid: string }) {
    return ShanquanInstance.delete(`/service/${params.service_uuid}`, { params });
  },
  // 修改服务描述
  editRelease(params: {
    service_uuid: string;
    description: string;
  }) {
    return ShanquanInstance.put(`/service/${params.service_uuid}`, params);
  },
  // 公共服务绑定项目
  publicServeBind(params: {
    service_uuid: string;
  }) {
    return ShanquanInstance.post(`/service/${params.service_uuid}/bind`, null, {
      params
    });
  },
  // 公共服务解绑项目
  publicServeUntie(params: {
    service_uuid: string;
  }) {
    return ShanquanInstance.post(`/service/${params.service_uuid}/unbind`);
  },
};

export default onlineService;

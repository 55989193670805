/* eslint-disable max-lines */
/* eslint-disable react-func/max-lines-per-function */
import React, { useState, useRef, PropsWithChildren } from 'react';
import { UploadProps } from 'antd/es/upload';
import { UploadOutlined } from '@ant-design/icons';
import { useUpdateEffect } from '@fuxi/eevee-hooks';
import { Button, message, Upload as AntUpload, UploadFile } from '@fuxi/eevee-ui';
import service from '@/service';
import { FileType, acceptFileTypeMap } from '../../MlDataset/const';
import cx from './index.module.less';

export enum UploadStatus {
  WAITING = 'waiting',
  UPLOADING = 'uploading',
  FAIL = 'error',
  SUCCESS = 'done',
  ASSEMBLE = 'assemble', // 发送分片finish请求，服务器组装文件中
  Removed = 'removed',
}
type Slice = {
  start: number;
  end: number;
  seq: number;
  // 针对上传文件列表的情况，上传任务池面向分片为单位划分的任务集合，故需要每个数据携带fileMd5来维护数据信息
  file: UploadFile & File;
  fileID: string;
  etag?: string;
  totalSlices: number;
};
type UploadTask = {
  slice: Slice;
  controller: AbortController;
  isFetching: boolean;
};

export type MultipartUploadInterface = UploadProps &
  PropsWithChildren & {
    value?: string[];
    onSuccess?: (fileName: string, file: File) => any;
    onError?: (fileName: string, file: File) => any;
    onFileNameListChange?: (fileNameList: UploadFile<any>[]) => any;
    validateFileName?: (fileNameList: UploadFile<any>[]) => any;

    multipartLine_MB?: number;
    concurrencyMax?: number; // 网络并行请求数量限制
    retryMax?: number; // 请求错误重试次数限制
    payload?: {
      project_id?: string;
      upload_uuid?: string;
    };
    fileType?: FileType; // txt, excel, jsonl, image, zip
    btnText?: string;
  };

const MAX_COUNT = 100;

export const UploadDataFile: React.FC<MultipartUploadInterface> = props => {
  const {
    multipartLine_MB = 30,
    concurrencyMax = 5,
    retryMax = 3,
    btnText = '上传',
    fileType = FileType.Txt,
    onChange,
    payload,
    onError,
    onSuccess,
    onRemove,
    onFileNameListChange,
    validateFileName,
  } = props;

  const [messageApi, contextHolder] = message.useMessage();
  const CHUNK_SIZE = 1024 * 1024 * multipartLine_MB; // 默认30M分片
  let uploadCount = 0;
  const [fileList, setFileList] = useState<UploadFile[]>([]); // 文件列表状态更新

  const sliceQueue = useRef({}); // 分片集合
  // TODO: 优化，当前会优先把获取upload_id的请求放到队列里，在多文件的场景下，要等待id请求回来后才会发起真正的上传请求，争取能够优化获取到id后就发起上传请求
  const taskStack = useRef({}); // 网络并行请求进程集合
  const completedCountRef = useRef({}); // 判断分片传输是否完成
  const completedFileEtagsRef = useRef({}); // 判断分片传输是否完成
  const retryMap = useRef({}); // 记录分片的重试次数
  const status = useRef({}); // 分片逻辑判断

  const beforeUpload = file => {
    if (fileType === FileType.Zip && fileList.length > 0) {
      message.warning('压缩包仅支持上传单文件');
      return false;
    }
  };

  // 文件列表状态更新
  const updateFile = (fileMd5, upload) => {
    let changedFile;
    setFileList(preFileList => {
      const changedFileList = preFileList?.map(file => {
        if (file.uid === fileMd5) {
          changedFile = file;
          return { ...file, ...upload };
        }
        return file;
      });
      onChange?.({ file: changedFile, fileList: changedFileList });

      return changedFileList;
    });
  };

  const addFile = file => {
    setFileList(preFileList => {
      const fileList = [file, ...preFileList];
      // 为支持多文件上传时，维护分片级别的线程池，即文件b完成，会触发选取分片队列里的分片（可能为文件a）所以ant默认的onchange事件监听失效，需组件自行维护
      //   onChange?.({ file, fileList });
      return fileList;
    });
  };
  // 上传多个文件时， 组件维护一个线程池来限制分片上传的数量
  const getConcurrencySilceUploadingCount = () => {
    return (Object.values(taskStack?.current) as Array<any>)?.reduce((acc, cur) => {
      return cur?.length ? acc + cur?.length : acc;
    }, 0);
  };
  const getWaitingSilce = () => {
    // 上传多文件时，只要并行线程池没满，就取出并发上传
    const waitingFileIndex = (Object.values(sliceQueue.current) as Array<any>).findIndex(queue => !!queue?.length);
    const waitingFileMd5 = Object.keys(sliceQueue.current)?.[`${waitingFileIndex}`];
    return sliceQueue.current?.[`${waitingFileMd5}`].pop();
  };
  const getWaitingSilceCount = () => {
    // 上传多文件时，只要并行线程池没满，就取出并发上传
    return (Object.values(sliceQueue.current) as Array<any>).reduce((acc, cur) => {
      return cur?.length ? acc + cur?.length : acc;
    }, 0);
  };
  const cancelRequests = fileMd5 => {
    taskStack.current?.[`${fileMd5}`]?.forEach((item: UploadTask) => {
      item.controller.abort();
      item.isFetching = false;
    });
  };
  const wrapOnError = file => {
    const fileMd5 = file.uid;
    onError?.(file?.name, file);
    updateFile(fileMd5, { status: UploadStatus.FAIL });
    Object.assign(status.current, {
      [`${fileMd5}`]: UploadStatus.FAIL,
    });
    cancelRequests(fileMd5);
  };
  const uploadSlice = async (sliceInfo: Slice, retry = false) => {
    // 失败后不继续上传
    const fileMd5 = sliceInfo?.file?.uid;
    if (status.current?.[`${fileMd5}`] === UploadStatus.FAIL) return;
    const onSliceSuccess = slice => {
      // 上传请求成功后,  将当前分片出栈
      const fileMd5 = sliceInfo?.file?.uid;
      Object.assign(taskStack.current, {
        [`${fileMd5}`]: taskStack.current?.[`${fileMd5}`]?.filter(
          (item: UploadTask) => item.slice.seq !== sliceInfo.seq
        ),
      });
      // 更新分片上传进度
      Object.assign(completedCountRef.current, {
        [`${fileMd5}`]: {
          ...completedCountRef.current?.[`${fileMd5}`],
          completed: completedCountRef.current?.[`${fileMd5}`]?.completed + 1,
        },
      });

      // 更新文件上传的etags
      const prevEtags = completedFileEtagsRef.current?.[`${fileMd5}`];
      Object.assign(completedFileEtagsRef.current, {
        [`${fileMd5}`]: [...(prevEtags ? prevEtags : []), { partnum: slice.seq.toString(), etag: slice.etag }],
      });

      updateFile(fileMd5, {
        percent:
          (completedCountRef.current?.[`${fileMd5}`]?.completed /
            (completedCountRef.current?.[`${fileMd5}`]?.total + 1)) *
          100,
      });
      // 检查是否还有分片待上传，或可以结束上传任务
      execNextTask();
    };
    /* 上传分片失败 */
    const onSliceError = (sliceInfo: Slice) => {
      const fileMd5 = sliceInfo?.file?.uid;
      // TODO: 当前在取消上传之后报错，暂时不支持重试
      // 重试分片上传
      // const retry = retryMap.current?.[`${fileMd5}`];
      // Object.assign(retry, { [sliceInfo.seq]: retry?.[sliceInfo.seq] || 0 });
      // console.log('------retry------', retry);
      // if (retry?.[sliceInfo.seq] < retryMax) {
      //   const _task = taskStack.current?.[`${fileMd5}`].find((item: UploadTask) => item.slice.seq === sliceInfo.seq);
      //   _task && Object.assign(_task, { isFetching: false });
      //   uploadSlice(sliceInfo, true);
      //   retry[sliceInfo.seq] = retry[sliceInfo.seq] + 1;
      //   return;
      // }
      wrapOnError(sliceInfo?.file);
    };

    const getCancelToken = sliceInfo => {
      const fileMd5 = sliceInfo?.file?.uid;
      const controller = new AbortController(); // 浏览器fetch支持AbortController https://developer.mozilla.org/zh-CN/docs/Web/API/AbortController
      if (retry) {
        taskStack.current?.[`${fileMd5}`]?.forEach((item: UploadTask) => {
          if (item.slice.seq === sliceInfo.seq) {
            item.controller = controller;
            item.isFetching = true;
          }
        });
      } else {
        taskStack.current?.[`${fileMd5}`]?.push({ slice: sliceInfo, controller: controller, isFetching: true });
      }
      return controller.signal;
    };

    const sendRequest = async (sliceInfo: Slice) => {
      const { start, end, seq, file, fileID } = sliceInfo;
      const data = new FormData();
      data.append('part_num', seq.toString());
      data.append('upload_id', fileID);
      data.append('upload_uuid', payload?.upload_uuid!);
      data.append('filename', file.name);
      data.append('file', file.slice(start, end));
      const uploadRes = await service.ml
        .uploadPartFile(data, {
          signal: getCancelToken(sliceInfo),
        })
        .catch(e => {
          onSliceError(sliceInfo);
        });

      if (uploadRes?.data?.is_success) {
        sliceInfo.etag = uploadRes?.data?.etag;
        onSliceSuccess(sliceInfo);
      } else {
        onSliceError(sliceInfo);
      }
      // fetch('/ml/api/v1/ml/upload/part', {
      //   signal: getCancelToken(sliceInfo),
      //   method: 'POST',
      //   body: data,
      //   headers: {
      //     Project_id: payload?.project_id!,
      //   },
      // })
      //   .then(response => response.json())
      //   .then(res => {
      //     if (res.code === 0) {
      //       sliceInfo.etag = res?.data?.etag;
      //       onSliceSuccess(sliceInfo);
      //     } else {
      //       onSliceError(sliceInfo);
      //     }
      //   })
      //   .catch(e => {
      //     onSliceError(sliceInfo);
      //   });
    };
    sendRequest(sliceInfo);
  };
  const wrapOnSuccess = file => {
    const fileMd5 = file?.uid;
    onSuccess?.(file?.name, file);
    updateFile(fileMd5, { status: UploadStatus.SUCCESS, percent: 100 });
    Object.assign(status?.current, {
      [`${fileMd5}`]: UploadStatus.SUCCESS,
    });
    cancelRequests(fileMd5);
  };

  const checkCompleteTask = async () => {
    const completeFiles = (Object.values(completedCountRef?.current) as Array<any>).filter(
      completeTask => completeTask && completeTask?.completed === completeTask?.total
    );
    completeFiles.forEach(async ({ fileMd5, name, fileID, file }) => {
      if ([UploadStatus.SUCCESS, UploadStatus.ASSEMBLE, UploadStatus.FAIL].includes(status.current?.[`${fileMd5}`])) {
        return;
      }
      Object.assign(status.current, {
        [`${fileMd5}`]: UploadStatus.ASSEMBLE,
      });

      // const body = JSON.stringify({
      //   upload_id: fileID,
      //   upload_uuid: payload?.upload_uuid,
      //   filename: name,
      //   part_etags: completedFileEtagsRef.current?.[`${fileMd5}`],
      // });

      // fetch('/ml/api/v1/ml/upload/part/finish', {
      //   method: 'POST',
      //   body,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Project_id: payload?.project_id!,
      //   },
      // })
      //   .then(response => response.json())
      //   .then(res => {
      //     if (res.code === 0) {
      //       wrapOnSuccess(completedCountRef.current?.[`${fileMd5}`].file);
      //     } else {
      //       wrapOnError(completedCountRef.current?.[`${fileMd5}`].file);
      //     }
      //   })
      //   .catch(e => {
      //     wrapOnError(completedCountRef.current?.[`${fileMd5}`].file);
      //   });

      const res = await service.ml
        .uploadPartFinish({
          upload_id: fileID,
          upload_uuid: payload?.upload_uuid,
          filename: name,
          part_etags: completedFileEtagsRef.current?.[`${fileMd5}`],
        })
        .catch(e => {
          console.log('------upload finish e------', e);
          wrapOnError(completedCountRef.current?.[`${fileMd5}`]?.file);
        });

      if (res?.data?.is_success) {
        wrapOnSuccess(completedCountRef.current?.[`${fileMd5}`]?.file);
        return;
      } else {
        wrapOnError(completedCountRef.current?.[`${fileMd5}`]?.file);
        return;
      }
    });
  };

  // 动态加载新的并行下载进程
  const execNextTask = () => {
    // 限制并行请求数
    if (getWaitingSilceCount() > 0 && getConcurrencySilceUploadingCount() < concurrencyMax) {
      const slice = getWaitingSilce();
      slice && uploadSlice(slice);
    }
    checkCompleteTask();

    // 是否可以继续执行
    return getWaitingSilceCount() > 0 && getConcurrencySilceUploadingCount() < concurrencyMax;
  };

  const slicesUploadInit = (file, totalSlices, fileID, blob) => {
    const fileMd5 = file?.uid;
    let i = 0;
    let queue: Slice[] = [];
    // 发起组装请求使用的信息
    Object.assign(completedCountRef.current, {
      [`${fileMd5}`]: { completed: 0, total: totalSlices, name: file?.name, fileID, fileMd5, file },
    });
    Object.assign(sliceQueue.current, { [`${fileMd5}`]: [] }); // 分片集合
    Object.assign(taskStack.current, { [`${fileMd5}`]: [] }); // 网络并行请求进程集合
    Object.assign(retryMap.current, { [`${fileMd5}`]: {} });
    while (i < totalSlices) {
      // 分片上传线程是分片级别，分片需要标记文件
      queue.unshift({
        file,
        seq: i + 1,
        start: i * CHUNK_SIZE,
        end: Math.min((i + 1) * CHUNK_SIZE, blob.size),
        fileID,
        totalSlices: totalSlices,
      });
      i = i + 1;
    }
    Object.assign(sliceQueue.current, { [`${fileMd5}`]: queue }); // 分片集合
    let ok = true;
    while (ok) {
      ok = execNextTask();
    }
  };
  const customRequest = async ({ file }: any) => {
    uploadCount++;
    if (uploadCount > MAX_COUNT) {
      messageApi.open({
        key: 'overMaxCount',
        type: 'warning',
        content: '仅支持上传100个文件',
        duration: 1,
      });
      return;
    }
    const fileIdData = await service.ml.getUploadFileId({ filename: file?.name, ...payload });
    if (!fileIdData) {
      message.error('上传失败');
      return;
    }

    const normalInit = file => {
      const fileMd5 = file.uid;
      addFile({
        id: fileIdData.upload_id,
        name: file?.name,
        uid: fileMd5,
        status: UploadStatus.UPLOADING,
        percent: 0,
      });

      Object.assign(status.current, {
        [`${fileMd5}`]: UploadStatus.UPLOADING,
      });
    };

    const beforeRequest = async () => {
      const totalSlices = Math.ceil(file.size / CHUNK_SIZE);
      slicesUploadInit(file, totalSlices === 0 ? 1 : totalSlices, fileIdData.upload_id, file);
    };

    normalInit(file);
    beforeRequest();
  };
  const deleteFile = fileMd5 => {
    setFileList(preFileList => {
      const fileList = preFileList?.filter(file => {
        return file.uid !== fileMd5;
      });

      return fileList;
    });
    onChange?.({ file: {} as any, fileList });

    Object.assign(completedCountRef.current, {
      [`${fileMd5}`]: undefined,
    });
    Object.assign(sliceQueue.current, { [`${fileMd5}`]: undefined }); // 分片集合
    Object.assign(taskStack.current, { [`${fileMd5}`]: undefined }); // 网络并行请求进程集合
    Object.assign(retryMap.current, { [`${fileMd5}`]: undefined });
  };

  const [isCancel, setIsCancel] = useState(false);
  const wrapOnRemove = async file => {
    if (isCancel) return;
    setIsCancel(true);

    const deleteIdx = fileList.findIndex(item => item.uid === file?.uid);
    deleteFile(file?.uid);
    cancelRequests(file?.uid);
    onRemove?.(file);

    let cancelRes;
    if (file.status === UploadStatus.UPLOADING) {
      cancelRes = await service.ml
        .cancelUploading({
          upload_uuid: payload?.upload_uuid,
          upload_id: file.id,
          filename: file.name,
        })
        .catch(e => {
          setIsCancel(false);
          message.warning('取消上传失败');
        });
    }
    if (file.status === UploadStatus.SUCCESS) {
      cancelRes = await service.ml
        .cancelUpload({
          upload_uuid: payload?.upload_uuid,
          filename: file.name,
        })
        .catch(e => {
          setIsCancel(false);
          message.warning('取消上传失败');
        });
    }
    // NOTE: 点的太快会报错
    if (file.status === UploadStatus.Removed) {
      cancelRes = {
        data: true,
      };
    }
    setIsCancel(false);

    // 取消上传失败
    if (!cancelRes?.data) {
      setFileList(preFileList => {
        preFileList.splice(deleteIdx, 0, file);
        return [...preFileList];
      });
      message.warning('取消上传失败');
      return;
    }
  };

  const handleChange = info => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} 上传成功`);
    }
  };
  useUpdateEffect(() => {
    const fileNameList = fileList.filter(file => !(file.status === UploadStatus.FAIL));
    onFileNameListChange?.(fileNameList);
    validateFileName?.(fileNameList);
  }, [fileList]);

  return (
    <>
      {contextHolder}
      <AntUpload
        multiple={fileType === FileType.Zip ? false : true}
        maxCount={100}
        name="file"
        listType="text"
        fileList={fileList}
        accept={acceptFileTypeMap[fileType]}
        onRemove={wrapOnRemove}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        className={cx('dataset-upload', fileType === FileType.Image && 'dataset-upload-image')}>
        <Button type="primary" icon={<UploadOutlined />}>
          {btnText}
        </Button>
      </AntUpload>
    </>
  );
};

export default UploadDataFile;

/* eslint-disable react-func/max-lines-per-function */
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Flex, Table, TableButtons, Tooltip } from '@fuxi/eevee-ui';
import { Link, useNavigate } from 'react-router-dom';
import { requestConfirm } from '@ai-training/utils';
import { serviceStatusTypeMap } from '@ai-training/constants/onlineService';
import { usePagination } from 'ahooks';
import { ServiceStatusType } from '@ai-training/types/onlineService';
import { getModalServicesByPage, mlModalService } from '@/service/ml';
import { useAppSelector } from '@/hooks';
import { MlSearchInput } from '../components/MlSearchInput';
import cx from './index.module.less';

const startType = [ServiceStatusType.PAUSE_FINISH];
const pauseType = [ServiceStatusType.RUNNING];

export const ModalService = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState<string>();
  const { appRestart, appStop } = mlModalService;
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const {
    data: modalServices,
    run,
    pagination,
    refresh,
    loading,
  } = usePagination(
    ({ current, pageSize }) =>
      getModalServicesByPage({
        keyword,
        pageSize,
        current,
      }),
    {
      defaultPageSize: 8,
      manual: true,
    }
  );

  const createModalService = () => {
    navigate('create');
  };

  useEffect(() => {
    setKeyword(undefined);
    if (!aiProjectId) {
      return;
    }
    run({
      projectId: aiProjectId,
      current: 1,
      pageSize: pagination.pageSize,
      keyword: undefined,
    });
  }, [aiProjectId]);

  // 不显示disabled的按钮
  const getFilterTableButtons = record => {
    const isRestart = startType.includes(record.status) && !record.is_public;
    const isStop = pauseType.includes(record.status) && !record.is_public;
    const buttons = [
      {
        text: '启动',
        disabled: !isRestart,
        onClick: () => {
          requestConfirm({
            title: `确定启动 ${record.name}?`,
            payload: {
              id: record.id,
            },
            submitApi: appRestart,
          }).then(() => {
            refresh();
          });
        },
      },
      {
        text: '停止',
        disabled: !isStop,
        onClick: () => {
          requestConfirm({
            title: `确定暂停 ${record.name}?`,
            payload: {
              id: record.id,
            },
            submitApi: appStop,
          }).then(() => {
            refresh();
          });
        },
      },
      {
        text: '删除',
        type: 'danger' as any,
        onClick: () =>
          requestConfirm({
            title: `确定删除服务${record.name}?`,
            content: '删除后该服务的API将失效。',
            payload: {
              id: record.id,
              projectId: aiProjectId,
            },
            submitApi: record.is_public ? mlModalService.publicServeUntie : mlModalService.appDelete,
          }).then(() => {
            refresh();
          }),
      },
    ];
    return buttons;
  };

  const columns = [
    {
      title: '服务名称',
      dataIndex: 'name',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <Flex alignCenter className={record.is_public ? cx('public-service-name-wrapper') : cx('service-name-wrapper')}>
          <Link type="link" to={`${record.id}`}>
            <Tooltip placement="topLeft" title={record.name}>
              <div className={cx('name')}>{record.name}</div>
            </Tooltip>
          </Link>
          {record.is_public && <div className={cx('public-service-tag')}>公共</div>}
        </Flex>
      ),
    },
    {
      title: '服务ID',
      dataIndex: 'id',
      ellipsis: {
        showTitle: false,
      },
      render: value => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: '服务状态',
      dataIndex: 'status',
      render(value, record, index) {
        return (
          <Badge color={serviceStatusTypeMap[record.status]?.color} text={serviceStatusTypeMap[record.status]?.label} />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updated_at',
    },
    {
      title: '操作',
      render: (_, record) => {
        return (
          <>
            <TableButtons data={getFilterTableButtons(record)} />
          </>
        );
      },
    },
  ];

  const onSearch = () => {
    run({
      projectId: aiProjectId,
      current: 1,
      pageSize: pagination.pageSize,
      keyword,
    });
  };

  return (
    <div className={cx['main-page-wrapper']}>
      <div className={cx('model-service')}>模型服务</div>
      <Flex className={cx('button-wrapper')} justifyBetween>
        <Flex alignCenter>
          <Button type="primary" onClick={createModalService}>
            <PlusOutlined />
            发布服务
          </Button>
        </Flex>
        <div>
          <MlSearchInput
            deps={[aiProjectId]}
            placeholder="请输入服务名称"
            onKeywordChange={e => setKeyword(e)}
            onSearch={onSearch}
          />
        </div>
      </Flex>
      <Table
        loading={loading}
        dataSource={modalServices?.list}
        columns={columns}
        rowKey="id"
        pagination={{
          onChange: pagination.onChange,
          current: pagination.current,
          total: modalServices?.total,
          pageSize: pagination.pageSize,
          showTotal: total => `总共 ${total} 条`,
          showQuickJumper: true,
          pageSizeOptions: ['8', '10', '16', '32', '64'],
          showSizeChanger: true,
        }}
      />
    </div>
  );
};

import * as React from 'react';
import {
  CodeOutlined,
  FileImageOutlined,
  LinkOutlined,
  RedoOutlined,
  SaveOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { IToolbar, IWords } from '../config/type';
import cx from '../config/css/index.module.less';

interface IP {
  onClick?: (type: string) => void;
  toInsertImg?: (file: any) => void;
  toolbar: IToolbar;
  words: IWords;
}

interface IS {
  imgHidden: boolean;
  imgList: File[];
}

class Toolbars extends React.Component<IP, IS> {
  static defaultProps = {
    onClick: () => {},
    toolbar: {},
    words: {},
  };

  private timer: number = 0;

  constructor(props: IP) {
    super(props);

    this.state = {
      imgHidden: true,
      imgList: [],
    };
  }

  onClick(type: string) {
    this.props.onClick?.(type);
  }

  imgClick() {
    this.setState({
      imgHidden: !this.state.imgHidden,
    });
  }

  imgMouseOver() {
    window.clearTimeout(this.timer);
    this.setState({
      imgHidden: false,
    });
  }

  imgMouseOut() {
    this.timer = window.setTimeout(() => {
      this.setState({
        imgHidden: true,
      });
    }, 150);
  }

  toAddImg() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/gif,image/jpeg,image/jpg,image/png,image/svg';
    input.onchange = (e: any) => {
      const files = e?.target?.files;
      if (!files || !files.length) return;
      this.props.toInsertImg?.(files);
    };
    input.click();
    input.remove();
  }

  // addImgFile(e: any) {
  //   let { imgList } = this.state;
  //   const index = imgList.length;
  //   imgList.push(e.target.files[0]);
  //   this.setState({
  //     imgList,
  //   });
  //   this.props.addImg?.(e.target.files[0], index);
  //   e.target.value = '';
  // }

  render() {
    const { toolbar, words } = this.props;
    // const { imgHidden } = this.state;
    return (
      <ul>
        {toolbar.undo && (
          <li onClick={() => this.onClick('undo')} title={`${words.undo} (ctrl+z)`}>
            <UndoOutlined />
          </li>
        )}
        {toolbar.redo && (
          <li onClick={() => this.onClick('redo')} title={`${words.redo} (ctrl+y)`}>
            <RedoOutlined />
          </li>
        )}
        {toolbar.h1 && (
          <li onClick={() => this.onClick('h1')} title={words.h1}>
            H1
          </li>
        )}
        {toolbar.h2 && (
          <li onClick={() => this.onClick('h2')} title={words.h2}>
            H2
          </li>
        )}
        {toolbar.h3 && (
          <li onClick={() => this.onClick('h3')} title={words.h3}>
            H3
          </li>
        )}
        {toolbar.h4 && (
          <li onClick={() => this.onClick('h4')} title={words.h4}>
            H4
          </li>
        )}
        {toolbar.img && (
          <li
            title={words.addImgLink}
            className={cx('eevee-toolbar-img')}
            onMouseOver={() => this.imgMouseOver()}
            onMouseOut={() => this.imgMouseOut()}
            onClick={() => this.toAddImg()}>
            <FileImageOutlined />
            {/* <ul style={imgHidden ? { display: 'none' } : {}}>
              <li onClick={() => this.addImgUrl()}>{words.addImgLink}</li>
              <li>
                <input
                  disabled
                  type="file"
                  accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                  onChange={e => this.addImgFile(e)}
                />
              </li>
            </ul> */}
          </li>
        )}
        {toolbar.link && (
          <li onClick={() => this.onClick('link')} title={words.link}>
            <LinkOutlined />
          </li>
        )}
        {toolbar.code && (
          <li onClick={() => this.onClick('code')} title={words.code}>
            <CodeOutlined />
          </li>
        )}
        {toolbar.save && (
          <li onClick={() => this.onClick('save')} title={`${words.save} (ctrl+s)`}>
            <SaveOutlined />
          </li>
        )}
      </ul>
    );
  }
}

export default Toolbars;

import { Flex, Avatar } from '@fuxi/eevee-ui';
import judgeAvator from '@/assets/image/judgeAvator.png';
import style from './ProjectCommentsCard.module.less';
// import cx from './ProjectConfig.module.less';

interface CommitsCardProps {
  judgeName: string;
  info: string;
  index: number;
}

interface HeaderProps {
  judgeName: string;
}

export const CommentCardHeader = (props: HeaderProps) => {
  return (
    <div className={style('header')}>
      <Flex alignCenter>
        <Avatar size={37.5} style={{ marginRight: 9 }} src={judgeAvator} />
        <span>{props.judgeName}</span>
      </Flex>
    </div>
  );
};

export const CommitsCard = (props: CommitsCardProps) => {
  const isNotEmpty = () => {
    console.log();
    return props?.info !== '';
  };

  return (
    <>
      <div className={style('card-container')}>
        <div className={style('card-header')}></div>
        <div className={style('card-body')}>
          <CommentCardHeader judgeName={'评委' + (props.index + 1)}></CommentCardHeader>
          <div className={style('info-container')}>{isNotEmpty() ? props.info : '该评委未发表评论'}</div>
        </div>
      </div>
    </>
  );
};

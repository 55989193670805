import React, { useEffect, useRef, useState } from 'react';
import { Flex, Input } from '@fuxi/eevee-ui';
import find from 'lodash/find';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { Industry, NoIndustryId, NoTagId, queryIndustry, queryTags, queryTemplateList, Tag } from '@/store/template';
import cx from './index.module.less';

const { Search } = Input;
const TAG_CONTAINER_HEIGHT = 40;

const FilterArea: React.FC = () => {
  const industry = useAppSelector(state => state.template.industry);
  const tags = useAppSelector(state => state.template.tags);
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const industryId = urlParams.get('industry');

  const [selectIndustry, updateSelectIndustry] = useState(industryId ? industryId : NoIndustryId);
  const [selectTags, updateSelectTags] = useState([NoTagId]);
  const [loading, setLoading] = useState(false);
  const [fold, setFold] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const tagsContainerDom = useRef<any>(null);

  const searchTags = (selected: Tag) => {
    let temp;
    if (selected.tagId === NoTagId) {
      temp = [NoTagId];
    } else {
      temp = selectTags.includes(selected.tagId)
        ? selectTags.filter(item => item !== selected.tagId)
        : [...selectTags, selected.tagId];
      temp = checkTagsData(temp);
    }
    updateSelectTags(temp);
    dispatch(
      queryTemplateList({
        name: searchValue,
        industryId: selectIndustry === NoIndustryId ? undefined : selectIndustry,
        tags: prepareTagNameFromTagId(temp),
      })
    );
  };

  const checkTagsData = (needCheckTags: string[]) => {
    if (needCheckTags.length > 0) {
      if (needCheckTags.length > 1 && needCheckTags.includes(NoTagId)) {
        return needCheckTags.filter(item => item !== NoTagId);
      } else {
        return needCheckTags;
      }
    } else {
      return [NoTagId];
    }
  };

  const prepareTagNameFromTagId = (temp: string[]) => {
    if (temp.includes(NoTagId)) {
      return undefined;
    } else {
      return temp.map(item => find(tags, { tagId: item })!.tagName);
    }
  };

  const searchIndustry = (selected: Industry) => {
    const temp =
      selected.industryId === selectIndustry || selected.industryId === NoIndustryId
        ? NoIndustryId
        : selected.industryId;
    updateSelectIndustry(temp);
    dispatch(
      queryTemplateList({
        name: searchValue,
        industryId: temp === NoIndustryId ? undefined : temp,
        tags: prepareTagNameFromTagId(selectTags),
      })
    );
  };

  const searchContent = async (val: string) => {
    setLoading(true);

    updateSelectIndustry(NoIndustryId);
    updateSelectTags([NoTagId]);
    setSearchValue(val);

    await dispatch(
      queryTemplateList({
        name: val,
      })
    ).unwrap();
    setLoading(false);
  };

  useEffect(() => {
    dispatch(queryTags());
    dispatch(queryIndustry());
    dispatch(
      queryTemplateList({
        industryId: selectIndustry === NoIndustryId ? undefined : selectIndustry,
      })
    );
  }, []);

  return (
    <div className={cx('container')}>
      <Flex justifyBetween alignCenter>
        <div className={cx('title')}>全部模板</div>
        <Search
          value={searchValue}
          loading={loading}
          placeholder="请输入 模板名称 进行搜索"
          allowClear
          style={{ width: 262 }}
          onChange={event => setSearchValue(event.target.value)}
          onSearch={val => searchContent(val)}
        />
      </Flex>
      <Flex justifyStart className={cx('industry')}>
        <div className={cx('filter-title')}>行业</div>
        <Flex justifyStart alignCenter flexFlow={'wrap'}>
          {industry.map(item => {
            return (
              <div
                key={item.industryId}
                className={cx(['filter-item', item.industryId === selectIndustry ? 'active' : undefined])}
                onClick={() => searchIndustry(item)}>
                {item.industryName}
              </div>
            );
          })}
        </Flex>
      </Flex>
      <Flex justifyStart>
        <div className={cx('filter-title')}>标签</div>
        <div className={cx(['tags', fold && 'fold'])} ref={tagsContainerDom}>
          {tags.map(item => {
            return (
              <div
                key={item.tagId}
                className={cx(['filter-item', selectTags.includes(item.tagId) ? 'active' : undefined])}
                onClick={() => searchTags(item)}>
                {item.tagName}
              </div>
            );
          })}
          {tagsContainerDom.current && tagsContainerDom.current.scrollHeight > TAG_CONTAINER_HEIGHT && (
            <div className={cx('tags-icon')} onClick={() => setFold(!fold)}>
              {fold ? '展开' : '收起'}
            </div>
          )}
        </div>
      </Flex>
    </div>
  );
};

export default FilterArea;

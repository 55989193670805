/* eslint-disable max-lines */
import React, { useState, useEffect, ReactNode } from 'react';
import debounce from 'debounce-promise';
import { isEmpty } from 'lodash';
import { useMutation } from '@apollo/client';
import { Modal, Form, Select, message, Input, InputNumber, Flex, Checkbox, Spin, Tooltip, Icon } from '@fuxi/eevee-ui';
import { LoadingOutlined, QuestionCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import { CheckboxChangeEvent } from 'antd/es/checkbox';
import client from '@/service/graphql';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  ServerType,
  LogType,
  ReleaseStatus,
  LogLevel,
  ServiceModuleType,
  getPublishModules,
  getZhongbaoPlatformUrl,
} from '@/constants';
import PublishImg from '@/assets/image/publish.png';
import { ServiceStatus } from '@/typings/common';
import { GET_LATEST_RELEASE_BY_SERVICE_ID } from '@/service/schema/service/service-queries';
import { PUBLISH_MAIN_SERVICE, CANCEL_PUBLISH } from '@/service/schema/service/service-mutation';
import { updateLog } from '@/store/log';
import { StatusDisplay } from '@/components/StatusDisplay';
import { getServiceVersions } from '@/service/version';
import { services } from '@/service/service';
import VersionFormItem from '../VersionFormItem';
import ProjectDetailContainer from '../ProjectDetailContainer';
import { serverStatusMap } from '../constants';

import { RunningTaskHandleType } from '../TaskRunningTipsModal';
import style from './PublishModal.module.less';

let timeoutId = 0;

export const PublishModal: React.FC<{
  disabled?: boolean;
  server?: { id: string; name: string };
  onPublished?: () => void;
  children?: ReactNode;
}> = ({ server, disabled, children, onPublished }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const container = ProjectDetailContainer.useContainer();
  const currentMlProjectList = useAppSelector(state => state.project.mlProjectList);
  const { serviceList, currentProject, getServicesData } = container;
  const [visible, setVisible] = useState(false);
  const [serviceId, setServiceId] = useState(server?.id || '');
  const service = serviceList.find(s => s.id === serviceId);
  const [publishing, setPublishing] = useState(false);
  const { id: projectId } = currentProject;
  const [commitTags, setCommitTags] = useState<CommitTag[]>([]);
  const [choosedModules, setChoosedModules] = useState<ServiceModuleType[]>([]);
  const [hasTaskRunning, setHasTaskRunning] = useState<boolean>(false);
  const [runningTaskHandleType, setRunningTaskHandleType] = useState<RunningTaskHandleType>();
  const [publishMainService, { data: publishResult, error: publishError, loading: submiting }] = useMutation<{
    publishMainService: { serviceId?: string; msg?: string };
  }>(PUBLISH_MAIN_SERVICE);
  const [cancelPublish, { data: cancelResult, error: cancelError, loading: canceling }] = useMutation<{
    serviceId: string;
  }>(CANCEL_PUBLISH);

  const [serviceVersions, setServiceVersions] = useState<ServiceVersion>();
  const [queryServiceVersionsLoading, setQueryServiceVersionsStatus] = useState(false);
  const isTerminal = currentProject?.isTerminal;

  const canPublishService = () => {
    if (!service) {
      return false;
    }
    return [ServiceStatus.Running, ServiceStatus.Error, ServiceStatus.AuditFial, ServiceStatus.Pending].includes(
      service?.status as ServiceStatus
    );
  };

  const fetchVersions = async () => {
    setQueryServiceVersionsStatus(true);
    try {
      const res = await getServiceVersions(serviceId);
      setServiceVersions(res?.serviceVersions);
      setQueryServiceVersionsStatus(false);
    } catch (e) {
      setQueryServiceVersionsStatus(false);
    }
  };

  useEffect(() => {
    if (visible && serviceId) {
      fetchVersions();
    }
    if (!visible) {
      setChoosedModules(
        isTerminal
          ? [ServiceModuleType.Edge, ServiceModuleType.Cloud, ServiceModuleType.Page]
          : [ServiceModuleType.Cloud]
      );
    }
  }, [serviceId, visible]);

  const getServiceModule = (type: string): ServiceModule | undefined => {
    if (isEmpty(serviceList)) {
      return;
    }
    const service = serviceList?.find(s => s.id === serviceId);
    const module: ServiceModule = service?.modules?.find(module => module.type === type)!;
    return module;
  };

  const getServerStatusInfo = (status: ServiceStatus) => {
    return serverStatusMap[status];
  };

  const handlePublishModuleChange = (e: CheckboxChangeEvent, type: ServiceModuleType) => {
    const checked = e.target.checked;
    let choosed = [...choosedModules];
    checked ? choosed.push(type) : (choosed = choosed.filter(item => item !== type));
    setChoosedModules(choosed);
  };

  const publishModules = Object.keys(getPublishModules(isTerminal)).map(type => (
    <div className={style('module-item')} key={type}>
      <Flex alignStart>
        <Checkbox
          checked={choosedModules.includes(type as ServiceModuleType)}
          onChange={e => handlePublishModuleChange(e, type as ServiceModuleType)}></Checkbox>
        <Flex alignCenter className={style('module-name')}>
          {getPublishModules(isTerminal)?.[type]?.name}
          {getPublishModules(isTerminal)?.[type]?.nameTitle && (
            <Tooltip title={getPublishModules(isTerminal)?.[type]?.nameTitle}>
              <QuestionCircleOutlined className={style('name-title')}></QuestionCircleOutlined>
            </Tooltip>
          )}
        </Flex>

        <Flex flexDirection="column">
          <div className={style('current-version')}>
            {getPublishModules(isTerminal)?.[type]?.versionLabel}：
            {getServiceModule(type)?.currentTag ? (
              <Tooltip placement="topLeft" title={getServiceModule(type)?.alias}>
                {getServiceModule(type)?.alias}
              </Tooltip>
            ) : (
              '暂无'
            )}
          </div>

          {getPublishModules(isTerminal)?.[type]?.showStatus && getServiceModule(type)?.currentTag && (
            <Flex>
              {/* <div className={style('placeholder')}></div> */}
              <Flex alignCenter>
                当前运行状态：
                <StatusDisplay statusInfo={getServerStatusInfo(getServiceModule(type)?.status!)}></StatusDisplay>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
    </div>
  ));

  const addLog = ({ content, pending, level }: { content: string; pending?: boolean; level?: LogLevel }) => {
    dispatch(updateLog({ serviceId, type: LogType.PublishLog, data: { content, pending, level } }));
  };

  const getAllVersions = () => {
    const allServices = [
      ...(serviceVersions?.cloudVersions || []),
      ...(serviceVersions?.pageVersions || []),
      ...(serviceVersions?.terminalVersions || []),
    ];
    return allServices;
  };

  const getVersionByCommmitId = (commitId: string) => {
    if (isEmpty(serviceVersions)) {
      return;
    }
    const allServices = getAllVersions();
    const version = allServices.find(item => item.tag === commitId);
    return version;
  };
  const toPublishProject = async () => {
    const values: { serviceId: string; tag: string; alias: string } = await form.validateFields();
    if (choosedModules?.length === 0) {
      message.error('请至少选择一个发布模块');
      return;
    }

    if (!canPublishService()) {
      message.error(`${service?.name}在${service?.status}状态下不可发布`);
      return;
    }

    const openZhongbaoPlatform = () => {
      const zhongbaoPlatformUrl = getZhongbaoPlatformUrl();
      const currentProjectCreateType = currentMlProjectList?.find(item => item.id === +currentProject.id)?.type;
      const param = currentProjectCreateType !== undefined ? `?editionType=${currentProjectCreateType}` : '';
      window.open(zhongbaoPlatformUrl + param);
    };

    // 开始发布
    // addLog({ content: '正在发起发布流程...' });
    const hasCrowdSourcing = commitTags?.find(tag => tag.commitId === values.tag)?.hasCrowdSourcing;
    hasCrowdSourcing &&
      Modal.confirm({
        content: '由于您使用了众包能力，本次发布需要进行审核，审核通过后会执行发布流程。',
        className: 'ai-training-confirm-modal',
        icon: <ExclamationCircleFilled style={{ color: '#4071f9' }} />,
        onCancel: async () => {
          await openZhongbaoPlatform();
          await getServicesData({ variables: { projectId: currentProject.id } });
        },
        cancelText: '前往众包平台',
        okText: '知道了',
      });
    publishMainService({ variables: { projectId, ...values, category: choosedModules } });
  };

  const toCancelPublish = () => {
    // 取消发布
    // addLog({ content: '正在取消发布', pending: true });
    window.clearTimeout(timeoutId);
    cancelPublish({ variables: { serviceId } });
  };

  const checkAliasRepeat = (newAlias: string) => {
    const tag = form.getFieldValue('tag');
    const currentVersoin = getVersionByCommmitId(tag);
    const existedVersions = getAllVersions().find(version => version.alias === newAlias);
    if (!!existedVersions && existedVersions?.tag !== currentVersoin?.tag) {
      return true;
    }
    return false;
  };

  const toGetLatestReleaseStatus = () => {
    const getLatestReleaseStatus = () => {
      client
        .query<{ serviceId: string }, { latestReleaseByServiceId: { status: ReleaseStatus } }>(
          GET_LATEST_RELEASE_BY_SERVICE_ID,
          { serviceId }
        )
        .then(data => {
          if (!data) return;
          window.clearTimeout(timeoutId);

          if ([ReleaseStatus.Preparing, ReleaseStatus.Running].includes(data.latestReleaseByServiceId.status)) {
            timeoutId = window.setTimeout(async () => {
              const currentServicesList = await (await services.getServicesByProject(projectId)).services.items;
              if (currentServicesList?.find(server => server?.id === serviceId)?.status === ServiceStatus.Auditing) {
                // 审核中退出轮询
                setVisible(false);
                getServicesData({ variables: { projectId } });
                return;
              }
              getLatestReleaseStatus();
            }, 3000);
          } else {
            // 发布结束
            const log = { content: '发布成功', level: LogLevel.Info };
            if (data.latestReleaseByServiceId.status === ReleaseStatus.Failure) {
              log.content = '发布失败';
              log.level = LogLevel.Error;
              message.error(log.content);
            } else {
              message.success(log.content);
              onPublished?.();
            }

            // addLog(log);
            // 刷新服务列表
            getServicesData({ variables: { projectId } });
            setVisible(false);
          }
        })
        .catch(() => {
          // addLog({ content: '发布失败', level: LogLevel.Error });
          // 刷新服务列表
          getServicesData({ variables: { projectId } });
          setVisible(false);
        });
    };

    getLatestReleaseStatus();
  };

  const toGetCommitTags = (prop: CommitTag[]) => {
    setCommitTags(prop);
  };

  const onCommitTagChange = (tag: string) => {
    form.setFieldsValue({
      alias: getVersionByCommmitId(tag)?.alias,
    });
  };

  useEffect(() => {
    if (cancelError) {
      // addLog({ content: '取消发布失败' });
    }
  }, [cancelError]);

  useEffect(() => {
    if (cancelResult) {
      // addLog({ content: '取消发布成功' });
      setVisible(false);
    }
  }, [cancelResult]);

  useEffect(() => {
    if (publishError) {
      // addLog({ content: '发布失败' });
      setVisible(false);
    }
  }, [publishError]);

  useEffect(() => {
    if (!publishResult) return;
    const { msg: errorMsg } = publishResult.publishMainService;
    if (errorMsg) {
      const msg = `发布失败，失败原因：${errorMsg}`;
      message.error(msg);
      // addLog({ content: msg, level: LogLevel.Error });
      setVisible(false);
    } else {
      // 发布中
      setPublishing(true);
      // addLog({ content: '正在发布，预计耗时一分钟', pending: true });
      // 开始轮询发布状态
      toGetLatestReleaseStatus();
    }
  }, [publishResult]);

  useEffect(() => {
    if (visible) {
      setPublishing(false);
      setServiceId(server?.id || '');
    }
  }, [visible]);

  useEffect(() => {
    const currentTag = form.getFieldValue('tag');
    currentTag && onCommitTagChange(currentTag);
  }, [serviceVersions]);

  const checkServerCrowdSourceTaskStatus = (serverId: number) => {
    services.getCrowdSourceTaskStatus(serverId).then(result => {
      if (result.crowdSourceTaskStatus) {
        if (Object.values(result.crowdSourceTaskStatus).some(v => v === true)) {
          // setVisible(false);
          setHasTaskRunning(true);
        } else {
          setVisible(true);
          setServiceId(serverId + '');
        }
        return;
      } else {
        setVisible(true);
      }
    });
  };

  const handleOpen = () => {
    if (disabled) return;
    // 查询是否有任务运行
    if (server?.id) {
      checkServerCrowdSourceTaskStatus(+server?.id);
    } else {
      setVisible(true);
    }
  };

  return (
    <>
      <span onClick={handleOpen}>{children}</span>
      <Modal
        title="发布项目"
        destroyOnClose
        keyboard={!publishing}
        getContainer={false}
        visible={visible}
        bodyStyle={{ paddingBottom: 0, minHeight: 100 }}
        onOk={toPublishProject}
        okButtonProps={{
          loading: submiting,
          disabled: submiting,
          style: publishing ? { display: 'none' } : undefined,
        }}
        cancelButtonProps={{
          disabled: submiting,
          style: publishing ? { display: 'none' } : undefined,
        }}
        closable={!publishing && !submiting}
        onCancel={() => setVisible(false)}
        width={600}>
        {publishing ? (
          <div className={style('publish-container')}>
            <img src={PublishImg} alt="publish" />
            <div className={style('body')}>
              <LoadingOutlined />
              正在发布...
            </div>
            {/* <Button type="primary" onClick={toCancelPublish} disabled={canceling} loading={canceling}>
              取消发布
            </Button> */}
          </div>
        ) : (
          <Spin spinning={queryServiceVersionsLoading}>
            <Form
              form={form}
              preserve={false}
              labelCol={{ span: 6 }}
              initialValues={{ serviceId: server?.id, replicas: { objserv: 1, namserv: 1, gateway: 1 } }}>
              <Form.Item
                name="serviceId"
                label="服务器名称"
                required
                rules={[
                  {
                    required: true,
                    message: '请选择服务器',
                  },
                ]}>
                <Select
                  disabled={!!server}
                  showSearch
                  placeholder="请选择服务器"
                  onChange={(id: string) => {
                    setServiceId(id);
                    checkServerCrowdSourceTaskStatus(+id);
                  }}
                  options={serviceList
                    .filter(({ type, status }) => type === ServerType.Main && status !== ServiceStatus.InitError)
                    .map(({ id, name }) => {
                      return {
                        value: id,
                        label: name,
                      };
                    })}
                  // @ts-ignore
                  filterOption={(input, option) => {
                    return String(option?.label).toLowerCase().includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <VersionFormItem
                serviceId={serviceId}
                onCommitTagChange={tag => onCommitTagChange(tag!)}
                serviceVersions={serviceVersions}
                getCommitTags={toGetCommitTags}
              />
              <Form.Item
                name={['replicas', 'namserv']}
                required
                hidden
                rules={[
                  {
                    required: true,
                    message: 'nameserver 副本数不能为空',
                  },
                ]}
                label="nameserver 副本数">
                <InputNumber
                  min={1}
                  precision={0}
                  style={{ width: '100%' }}
                  placeholder={'请输入 nameserver 副本数'}></InputNumber>
              </Form.Item>
              <Form.Item
                name={['replicas', 'objserv']}
                required
                rules={[
                  {
                    required: true,
                    message: 'objserver 副本数不能为空',
                  },
                ]}
                label="objserver 副本数">
                <InputNumber
                  min={1}
                  precision={0}
                  style={{ width: '100%' }}
                  placeholder={'请输入 objserver 副本数'}></InputNumber>
              </Form.Item>
              <Form.Item
                name={['replicas', 'gateway']}
                required
                rules={[
                  {
                    required: true,
                    message: 'gateway 副本数不能为空',
                  },
                ]}
                label="gateway 副本数">
                <InputNumber
                  min={1}
                  precision={0}
                  style={{ width: '100%' }}
                  placeholder={'请输入 gateway 副本数'}></InputNumber>
              </Form.Item>
              <Form.Item
                name="alias"
                required
                rules={[
                  {
                    required: true,
                    message: '版本别名不能为空',
                  },
                  {
                    // lodash的debounce不生效 会延迟校验 因为返回结果不是promise
                    validator: debounce(async (_, value) => {
                      const isRepeat = checkAliasRepeat(value);
                      return !isRepeat ? Promise.resolve() : Promise.reject('别名已存在');
                    }, 50),
                  },
                ]}
                label="版本别名">
                <Input
                  showCount
                  maxLength={100}
                  disabled={!serviceId}
                  autoComplete="off"
                  placeholder={serviceId ? '请为选择的发布版本输入版本别名' : '请先选择服务器'}></Input>
              </Form.Item>

              <Form.Item className={style('publish-module-item')} required label="发布模块">
                {publishModules}
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Modal>
      <Modal
        visible={hasTaskRunning}
        className={style('running-task-tip-modal')}
        getContainer={false}
        title={
          <Flex flexDirection="row">
            <Icon name="warn-日志" size={24} className={style('warn-icon')} />
            【众包】当前服务器有仍在进行中的任务
          </Flex>
        }
        onCancel={() => {
          setVisible(false);
          setHasTaskRunning(false);
        }}
        onOk={result => {
          setVisible(true);
          setHasTaskRunning(false);
        }}
        okText="继续">
        <>
          <div>继续发布服务器将会使得题目失效，是否继续？</div>
        </>
      </Modal>
    </>
  );
};

import React from 'react';
import { Empty } from 'antd';
import slice from 'lodash/slice';
import { Flex, Row, Col, Pagination } from '@fuxi/eevee-ui';
import { TemplateStatus, updatePagination } from '@/store/template';
import { useAppDispatch, useAppSelector } from '@/hooks';
import TemplateItem from '@/pages/templateCenter/TemplateItem';
import cx from './index.module.less';

const TemplateList: React.FC = () => {
  const dispatch = useAppDispatch();
  const templates = useAppSelector(state => state.template.templates);
  const pagination = useAppSelector(state => state.template.pagination);

  const data = slice(templates, (pagination.page - 1) * pagination.size, pagination.page * pagination.size);

  return (
    <>
      {data.length > 0 && (
        <>
          <Row gutter={[24, 24]}>
            {data.map(item => {
              if (item.status === TemplateStatus.ONLINE) {
                return (
                  <Col span={6} key={item.id}>
                    <TemplateItem data={item} />
                  </Col>
                );
              } else {
                return null;
              }
            })}
          </Row>
          <Flex justifyEnd className={cx('pagination')}>
            <Pagination
              current={pagination.page}
              pageSize={pagination.size}
              total={templates.length}
              showQuickJumper
              showSizeChanger
              onChange={(page, size) => {
                dispatch(
                  updatePagination({
                    page,
                    size: size || pagination.size,
                  })
                );
              }}
            />
          </Flex>
        </>
      )}
      {data.length <= 0 && <Empty style={{ marginTop: 150 }} description={<p>暂无模板</p>} />}
    </>
  );
};

export default TemplateList;

import { useEffect, useRef, useState } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';
import { Button, Cascader, Col, Divider, Form, Input, Row, Space, Spin, message } from '@fuxi/eevee-ui';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useAppSelector } from '@/hooks';
import ml from '@/service/ml';
import Editor from '@/components/MarkdownEditor';
import { useImgUpload } from '@/hooks/useImgUpload';
import { getAbilityTags, getCategoryUuid } from '../utils/getAbilityTags';
import { UploadImg } from '../components/UploadImg';

import cx from './index.module.less';

export const MlProjectAbilityEdit = () => {
  const ref = useRef<{
    getImgEvent: () => {
      iconFile: any;
      imgChanged: boolean;
    };
  }>();

  const { waitImgUpload } = useImgUpload();
  const mlCategory = useAppSelector(state => state.mlCategory.categories);
  const { editCapability } = ml;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [editingText, setEditingText] = useState('');
  const { projectAbilityId } = useParams();

  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId)!;


  const { getCapabilityDetails } = ml;

  const {
    data: capDetail,
    loading,
    run,
  } = useRequest(getCapabilityDetails, {
    manual: true,
  });

  useEffect(() => {
    if (!!aiProjectId) {
      run({ id: projectAbilityId || '' });
    }
  }, [aiProjectId]);

  useEffect(() => {
    if (isEmpty(capDetail)) {
      return;
    }

    setEditingText(capDetail?.data?.markdown);

    form.setFieldsValue({
      abilityTag: [capDetail?.data?.first_category, capDetail?.data?.second_category],
      desc: capDetail?.data?.description,
      coverImg: capDetail?.data?.image_url,
    });
  }, [capDetail]);

  const getAbilityCascadeOptions = () => {
    return getAbilityTags(mlCategory);
  };

  const cancel = () => {
    navigate(-1);
  };

  const handelSave = async () => {
    try {
      await form.validateFields();
      setSubmitLoading(true);
      const imgEvent = ref.current?.getImgEvent();
      if (!imgEvent) {
        message.error('未获取到图片信息');
        return;
      }
      const { iconFile, imgChanged } = imgEvent;
      const payload = {
        iconUrl: '',
      };
      await waitImgUpload(imgChanged, iconFile, payload);
      const iconUrl = payload.iconUrl;
      const { abilityTag, desc } = form.getFieldsValue();
      const categoryUuid = getCategoryUuid(mlCategory, abilityTag);
      if (!categoryUuid) {
        message.error('请选择能力标签');
        return;
      }
      editCapability({
        id: projectAbilityId!,
        category_uuid: categoryUuid,
        description: desc,
        image_url: imgChanged ? iconUrl : capDetail?.data?.image_url,
        markdown: editingText,
      })
        .then(() => {
          message.success('修改成功');
          navigate('../');
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
      // TODO: 保存接口
    } catch (e) {
      console.log(e);
    }
  };

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const onSaveMd = () => {};

  const handleEditingTextChange = e => {
    setEditingText(e);
    // setIsMdChanged(true);
  };

  return (
    <Spin spinning={loading} tip="加载中...">
      <div className={cx('edit-wrapper')}>
        <Form form={form} className={cx('form')} labelAlign="right" labelCol={{ span: 5 }}>
          <Row gutter={[120, 0]}>
            <Col span={13}>
              <Form.Item
                name="abilityTag"
                label="能力标签"
                required
                rules={[
                  {
                    required: true,
                    message: '请选择能力标签',
                  },
                ]}>
                <Cascader options={getAbilityCascadeOptions()}></Cascader>
              </Form.Item>

              <Form.Item label="描述" name="desc">
                <Input.TextArea className={cx('desc-textarea')} maxLength={200} showCount rows={5}></Input.TextArea>
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item
                extra={<div className={cx('img-tip')}>建议尺寸2:1，图片不超过10M，分辨率不小于653*327</div>}
                className={cx('img-item')}
                label="封面图片"
                name="coverImg">
                {capDetail?.data && <UploadImg ref={ref} iconUrl={capDetail?.data?.image_url}></UploadImg>}
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Divider className={cx('divider')}></Divider>

        <Editor
          preview={true}
          subfield={true}
          onSave={onSaveMd}
          height={580}
          value={editingText}
          onChange={handleEditingTextChange}
        />

        <Space className={cx('button-wrapper')}>
          <Button onClick={cancel}>取消</Button>
          <Button type="primary" onClick={handelSave} loading={submitLoading}>
            确定
          </Button>
        </Space>
      </div>
    </Spin>
  );
};

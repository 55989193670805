import React, { useState, useEffect } from 'react';
import { Select, DatePicker } from '@fuxi/eevee-ui';
import dayjs from 'dayjs';

type RangeType = 'month' | 'year';

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  onRangeTypeChange?: (value: string) => void;
}

const ChartsDatePicker: React.FC<Props> = ({ value, onChange, onRangeTypeChange }) => {
  const [rangeType, setRangeType] = useState<RangeType>('month');
  const [range, setRange] = useState<string>(value || dayjs().format('YYYY-MM'));
  const handelRangeType = val => {
    onRangeTypeChange?.(val);
    setRangeType(val);
    val === 'month' && setRange(dayjs().format('YYYY-MM'));
    val === 'year' && setRange(dayjs().format('YYYY'));
  };
  const handelRange = val => {
    setRange(val);
  };
  useEffect(() => {
    onChange?.(range);
  }, [range]);
  return (
    <>
      <Select
        listHeight={128}
        onChange={val => handelRangeType(val)}
        defaultValue="month"
        style={{ marginRight: 'var(--margin-xs)', width: '100px' }}
        options={[
          { value: 'month', label: '按月统计' },
          { value: 'year', label: '按年统计' },
        ]}
      />
      <DatePicker
        allowClear={false}
        disabledDate={current => current && current > dayjs().endOf('day')}
        defaultValue={dayjs(range)}
        value={dayjs(range)}
        onChange={(_, val) => handelRange(val)}
        picker={rangeType}
      />
    </>
  );
};

export default ChartsDatePicker;

import React, { useState, useEffect } from 'react';
import { reduce, join } from 'lodash';
import { Menu } from '@fuxi/eevee-ui';
import { Link, useLocation } from 'react-router-dom';

import { useAppSelector } from '@/hooks';
import getMenuData from './utils/getMenuData';
import filterLicensedMenuData from './utils/filterLicensedMenuData';

import styles from './SiderMenu.module.less';

const { SubMenu } = Menu;

interface Props {
  mode: 'vertical' | 'horizontal' | 'inline';
  permissionList?: string[];
}

const SiderMenu: React.FC<Props> = ({ mode, permissionList }) => {
  const [openKeys, setOpenKeys] = useState<any>([]); // 展开/关闭的菜单项，数组
  const [selectedKeys, setSelectedKeys] = useState<any>([]); // 选中的菜单项，单项
  const isMdChanged = useAppSelector(state => state.project.isMdChanged);
  const { pathname } = useLocation();
  const allMenuData = getMenuData();
  const menuData = filterLicensedMenuData(allMenuData, permissionList);

  useEffect(() => {
    const pathnameArray = pathname.split('/').map(name => `/${name}`);
    pathnameArray.splice(0, 1);
    const selectedKeys = reduce(
      pathnameArray,
      (acc: string[], cur) => {
        acc.push(join([...acc, cur], ''));
        return acc;
      },
      []
    );
    // setOpenKeys(selectedKeys);
    setSelectedKeys(selectedKeys);
  }, [pathname]);

  // event----
  // SubMenu 展开/关闭的回调
  function onOpenChange(openKeys: any) {
    setOpenKeys(openKeys);
  }

  // 被选中时调用
  function onSelect(value: any) {
    !isMdChanged && setSelectedKeys(value.selectedKeys);
  }

  // 生成出菜单项
  function getMenu() {
    return menuData.map((menuInfo: any) => {
      if (menuInfo.children && menuInfo.children.some((child: { hidden: boolean }) => !child.hidden)) {
        return (
          <SubMenu
            className={styles('eevee-sub-menu')}
            key={`${menuInfo.path}`}
            popupOffset={[-20, 1]}
            popupClassName={styles('header-popup')}
            // style={{width: '10px'}}
            title={
              <Link to={`${menuInfo.path}`}>
                {menuInfo.icon}
                {menuInfo.name}
              </Link>
              // <span>
              //   {menuInfo.icon}
              //   <span>{menuInfo.name}</span>
              // </span>
            }>
            {menuInfo?.children?.map((item: any) => {
              return (
                <Menu.Item key={item.path}>
                  <Link to={`${item.path}`}>{item.name}</Link>
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      } else {
        return (
          <Menu.Item key={menuInfo.path} className={styles('header-menu-item')}>
            <Link to={`${menuInfo.path}`}>
              {menuInfo.icon}
              {menuInfo.name}
            </Link>
          </Menu.Item>
        );
      }
    });
  }
  return (
    <Menu
      className={styles('menu')}
      theme="dark"
      // triggerSubMenuAction={'click'}
      // mode="inline"
      mode={mode}
      openKeys={openKeys}
      selectedKeys={selectedKeys}
      onSelect={onSelect}
      onOpenChange={onOpenChange}>
      {getMenu()}
    </Menu>
  );
};

export default SiderMenu;

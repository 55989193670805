import React, { useEffect, useImperativeHandle, useState, forwardRef } from 'react';
import { Empty, Button, Space, Spin, Modal } from '@fuxi/eevee-ui';
import { useLazyQuery, useMutation } from '@apollo/client';
import Editor from '@/components/MarkdownEditor';
import ProjectMarkDown from '@/components/Markdown/Markdown';
import { GET_PROJECT_README } from '@/service/schema/project/project-queries';
import { UPDATE_README } from '@/service/schema/project/project-mutations';
import { ProjectStatus } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setMdChanged } from '@/store/project';
import EmptyImg from '@/assets/image/no-data.png';
import ProjectDetailContainer from '../ProjectDetailContainer';
import cx from './ProjectMarkDown.module.less';

const ProjectMarkDownTab = forwardRef((props, ref) => {
  const dispatch = useAppDispatch();
  const [mdText, setMdText] = useState<string | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [editingText, setEditingText] = useState('');
  const isMdChanged = useAppSelector(state => state.project.isMdChanged);
  const { currentProject, isTpl } = ProjectDetailContainer.useContainer();

  const [fetchReadme, { data: readmeData, loading: readmeLoading }] = useLazyQuery<{
    templateReadme: string;
  }>(GET_PROJECT_README, { variables: { templateId: currentProject.id } });

  const [saveReadme, { loading: saveLoading }] = useMutation<any, { id: number; content: string }>(UPDATE_README);

  const showSaveModal = (callback?: Function) => {
    Modal.confirm({
      title: isTpl ? '是否保存模板描述' : '是否保存项目描述',
      onCancel: () => {
        handleQuitEditMd();
        callback?.();
      },
      onOk: async () => {
        await onSave();
        callback?.();
      },
      className: 'confirm-modal',
    });
  };

  useImperativeHandle(ref, () => ({
    showSaveModal,
  }));

  const onSave = async () => {
    await saveReadme({ variables: { id: currentProject.id, content: editingText } }).then(() => {
      setMdText(editingText);
      fetchReadme();
    });
    handleQuitEditMd();
  };

  const handleEditMd = () => {
    setEditingText(mdText || '');
    setIsEditing(true);
  };

  const handleQuitEditMd = () => {
    dispatch(setMdChanged(false));
    setEditingText('');
    setIsEditing(false);
  };

  const handleEditingTextChange = (value: string) => {
    dispatch(setMdChanged(true));
    setEditingText(value);
  };

  useEffect(() => {
    if (!currentProject.id) return;
    if (![ProjectStatus.Creating, ProjectStatus.Error].includes(currentProject?.status!)) {
      fetchReadme();
    }
  }, [currentProject]);

  useEffect(() => {
    if (!readmeData) return;
    readmeData?.templateReadme !== undefined && setMdText(readmeData.templateReadme);
  }, [readmeData]);

  return (
    <Spin spinning={(readmeLoading || saveLoading) && currentProject.status !== ProjectStatus.Creating}>
      <div className={cx('desc-wrapper')}>
        <div className={cx('desc-title')}>项目说明</div>
        {isEditing ? (
          <>
            <div className={cx('md-btn')}>
              {isMdChanged ? (
                <Space>
                  <Button onClick={onSave}>保存</Button>
                  <Button onClick={handleQuitEditMd}>放弃</Button>
                </Space>
              ) : (
                <Button onClick={handleQuitEditMd}>退出编辑</Button>
              )}
            </div>
            <div className={cx('markdown-wrapper')}>
              <Editor onSave={onSave} height={800} value={editingText} onChange={handleEditingTextChange} />
            </div>
          </>
        ) : (
          <>
            <div className={cx('md-btn')}>
              <Button onClick={handleEditMd}>编辑</Button>
            </div>
            <div className={cx('markdown-wrapper')}>
              <ProjectMarkDown text={mdText} />
            </div>
          </>
        )}

        {mdText === '' && !isEditing && (
          <Empty
            image={EmptyImg}
            style={{ height: 250, marginTop: 100 }}
            description={isTpl ? '添加模板描述以更好地说明你的模板' : '添加项目说明以更好地说明你的项目'}
          />
        )}
      </div>
    </Spin>
  );
});

export default ProjectMarkDownTab;

import { useState } from 'react';
import { useAntdTable } from '@fuxi/eevee-hooks';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Flex, Button, Input } from '@fuxi/eevee-ui';

import service from '../../service';
import { getSorterTableData } from '../../utils/util';
import { getTrainingTaskColumns, createTrainingPath } from './const';
import cx from './index.module.less';

const TrainingTask: React.FC = () => {
  const history = useNavigate();
  const [keyword, setKeyword] = useState<string>();
  const urlParams = useParams();

  const {
    tableProps,
    search: { submit },
    refresh,
  } = useAntdTable(
    getSorterTableData(service.trainingTask.taskList, { extraParams: { project_id: urlParams.projectId, keyword } })
  );
  return (
    <div className={cx['main-page-wrapper']}>
      <div className={cx['main-page-title']}>训练任务</div>
      <Flex justifyBetween style={{ marginBottom: 20 }}>
        <Flex alignCenter>
          <Button type="primary" onClick={() => history(createTrainingPath)}>
            <PlusOutlined />
            创建训练
          </Button>
        </Flex>
        <div>
          <Input
            placeholder="请输入训练任务id"
            onChange={e => setKeyword(e.target.value)}
            style={{ width: '300px', marginRight: 12 }}
          />
          <Button type="primary" onClick={() => submit()}>
            搜索
          </Button>
        </div>
      </Flex>
      <Table
        columns={getTrainingTaskColumns({ refresh })}
        {...tableProps}
        rowKey="id"
        pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
      />
    </div>
  );
};

export default TrainingTask;

/* eslint-disable max-lines */
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  TableColumnsType,
  Table,
  Divider,
  Modal,
  Empty,
  Flex,
  Tooltip,
  message,
  TablePaginationConfig,
} from '@fuxi/eevee-ui';
import _ from 'lodash';

import { QuestionCircleOutlined, SyncOutlined } from '@ant-design/icons';
import {
  VirtualMachine,
  VirtualMachineGroup,
  getVirtualMachineGroupThunk,
  deleteVirtualMachineGroupThunk,
  VIRTUAL_MACHINE_STATUS,
  VIRTUAL_MACHINE_STATUS_VALUE,
} from '@/store/virtualTerminal';
import { useAppSelector, useAppDispatch } from '@/hooks';
import emptyImg from '@/assets/image/empty2.png';
import { isClusterResourceOverflow } from '@/utils/isResourceOverflow';
import { Image } from '@/store/image';
import { virtualTerminalApi } from '@/service/virtualTerminal';
import { defaultCurrentPage, defaultPageSize } from '@/pages/project/const';
import { VMUpdateModal } from '../VMCtrlModals';
import { RemoteConnectionModal } from '../RemoteConnectionModal';
import { VmCopyModal } from '../VmCopyModal';
import { ImageSaveModal } from '../ImageSaveModal';

import cx from './VirtualTerminalTable.module.less';

type VirtualTerminalTableProps = {
  existedImages: Image[];
  setSelectedVirtualMachineGroup: React.Dispatch<React.SetStateAction<(VirtualMachineGroup & VirtualMachine)[]>>;
};

const formatEmptyText = val => val || '-';

const EmptyCard = (
  <div className={cx('empty')}>
    <Empty
      image={emptyImg}
      description={'请选择虚拟主机镜像或自定义镜像为虚拟终端'}
      imageStyle={{ width: '253px', height: '143px' }}
    />
  </div>
);

const VirtualTerminalTable: FC<VirtualTerminalTableProps> = ({ existedImages, setSelectedVirtualMachineGroup }) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(state => state.project.currentProject.id);
  const { virtualMachineGroup, groupCount, virtualMachineTableLoading, resource } = useAppSelector(
    state => state.virtualTerminal
  );
  const isResoureOverflow = isClusterResourceOverflow(resource);
  const [typeFilterTypeList, setTypeFilterTypeList] = useState<string[]>([]);
  const [statusFilterTypeList, setStatusFilterTypeList] = useState<string[]>([]);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: defaultPageSize,
    current: defaultCurrentPage,
  });

  const vmGroupTypeFilters = useMemo(() => {
    return _.uniqBy(
      virtualMachineGroup.map(group => {
        const type = group.vmList[0].type || '未定义';
        return { text: type, value: type };
      }),
      'value'
    );
  }, [virtualMachineGroup]);

  useEffect(() => {
    if (!projectId) return;
    dispatch(getVirtualMachineGroupThunk(1, 10000));
  }, [projectId]);

  const parallelDeployCallback = (vmId: number, { vmNum, password, preserved }) => {
    virtualTerminalApi
      .copyVm(projectId, vmId, {
        number: vmNum,
        password,
        rmOldVm: !preserved,
      })
      .then(res => {
        if (res.status === 200) {
          message.success('部署中');
          dispatch(getVirtualMachineGroupThunk(1, 10000));
        } else {
          message.error('无法进入部署');
        }
      });
  };

  const saveImage = (vmId: number, prop: { imageName: string; imageTag: string }) => {
    virtualTerminalApi.exportImage(projectId, vmId, prop).then(res => {
      if (res.status === 200) {
        message.success('发起保存镜像成功');
        dispatch(getVirtualMachineGroupThunk(1, 10000));
      } else {
        message.error('保存失败');
      }
    });
  };

  const deleteVM = (record: VirtualMachineGroup & VirtualMachine) => {
    Modal.confirm({
      okText: '确认',
      type: 'warning',
      cancelText: '取消',
      title: `确定要删除${record.name}吗？`,
      className: 'confirm-modal',
      onOk: () =>
        dispatch(deleteVirtualMachineGroupThunk(record?.vmList ? record.vmList.map(vm => vm.id) : [record.id])),
    });
  };

  const resetVirtualMachine = (vmId: number, vmName: string) => {
    Modal.confirm({
      okText: '确认',
      type: 'warning',
      cancelText: '取消',
      title: `确定要重置${vmName}吗？`,
      onOk: () => {
        virtualTerminalApi.resetVM(projectId, vmId).then(res => {
          if (res.status === 200) {
            message.success('重置成功');
            dispatch(getVirtualMachineGroupThunk(1, 10000));
          }
        });
      },
      className: 'confirm-modal',
    });
  };

  const columns: TableColumnsType<VirtualMachineGroup & VirtualMachine> = [
    {
      width: 80,
      title: '并行',
      fixed: 'left',
      ellipsis: true,
      key: 'parallelCount',
      render: val => val || '',
      dataIndex: 'parallelCount',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      render: (val, record) => <span style={{ marginLeft: record.vmList ? 0 : 14 }}>{val}</span>,
      width: 100,
    },
    {
      title: '终端名称',
      dataIndex: 'name',
      key: 'parallelName',
      render: formatEmptyText,
      width: 200,
      ellipsis: true,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      render: (status, record) => {
        const statusLabel = status ? VIRTUAL_MACHINE_STATUS[status].label : '-';
        return (
          <div>
            <Flex flexDirection="column">
              <Flex alignCenter justifyBetween>
                <Flex alignCenter>
                  <div
                    className={cx('status-icon')}
                    style={{ backgroundColor: VIRTUAL_MACHINE_STATUS?.[status]?.color }}></div>
                  <span>{statusLabel}</span>
                </Flex>

                {[VIRTUAL_MACHINE_STATUS_VALUE.error].includes(status) && (
                  <div onClick={() => resetVirtualMachine(record.id, record.name)}>
                    <Flex className={cx('reset-button')} alignCenter>
                      <SyncOutlined />
                      <span>重置</span>
                    </Flex>
                  </div>
                )}

                {[VIRTUAL_MACHINE_STATUS_VALUE.creating].includes(status) && isResoureOverflow && (
                  <Flex alignCenter>
                    <span>资源等待调度</span>
                    <Tooltip className={cx('resource-limit-tip')} title="请联系dengli@corp.netease.com进行处理">
                      <QuestionCircleOutlined></QuestionCircleOutlined>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>

              {status === VIRTUAL_MACHINE_STATUS_VALUE.error ? (
                <Tooltip placement="bottomLeft" className={cx('status-tip')} title={record.msg}>
                  <div className={cx('status-tip')}>{record.msg}</div>
                </Tooltip>
              ) : (
                <div className={cx('status-tip')}>
                  {status === VIRTUAL_MACHINE_STATUS_VALUE.error
                    ? record.msg
                    : record.progress
                    ? '进度' + record.progress
                    : ''}
                </div>
              )}
            </Flex>
          </div>
        );
      },
      width: 240,
      filterMode: 'tree',
      filters: [
        { text: VIRTUAL_MACHINE_STATUS.running.label, value: VIRTUAL_MACHINE_STATUS.running.value },
        { text: VIRTUAL_MACHINE_STATUS.poweroff.label, value: VIRTUAL_MACHINE_STATUS.poweroff.value },
        { text: VIRTUAL_MACHINE_STATUS.shutting_down.label, value: VIRTUAL_MACHINE_STATUS.shutting_down.value },
        { text: VIRTUAL_MACHINE_STATUS.booting_up.label, value: VIRTUAL_MACHINE_STATUS.booting_up.value },
        { text: VIRTUAL_MACHINE_STATUS.deleted.label, value: VIRTUAL_MACHINE_STATUS.deleted.value },
        { text: VIRTUAL_MACHINE_STATUS.creating.label, value: VIRTUAL_MACHINE_STATUS.creating.value },
        { text: VIRTUAL_MACHINE_STATUS.error.label, value: VIRTUAL_MACHINE_STATUS.error.value },
        { text: VIRTUAL_MACHINE_STATUS.exporting.label, value: VIRTUAL_MACHINE_STATUS.exporting.value },
        { text: VIRTUAL_MACHINE_STATUS.copying.label, value: VIRTUAL_MACHINE_STATUS.copying.value },
        { text: VIRTUAL_MACHINE_STATUS.deleting.label, value: VIRTUAL_MACHINE_STATUS.deleting.value },
      ],
    },
    {
      title: '运行时间',
      dataIndex: 'runningTime',
      key: 'runningTime',
      ellipsis: true,
      render: formatEmptyText,
      width: 100,
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      render: (val, record) => (record.vmList ? '-' : val || '未定义'),
      width: 140,
      filterMode: 'tree',
      filters: vmGroupTypeFilters,
    },
    {
      title: '镜像名称',
      dataIndex: 'imageName',
      key: 'imageName',
      render: val => <Tooltip title={val || ''}>{val?.split('/')?.pop() || '-'}</Tooltip>,
      ellipsis: true,
      width: 200,
    },
    {
      title: '镜像版本号',
      dataIndex: 'imageTag',
      width: 140,
      key: 'imageTag',
      ellipsis: true,
      render: formatEmptyText,
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      width: '280px',
      dataIndex: 'action',
      render: (_, record) => {
        return record.vmList ? (
          <div className={cx('action-btns', 'group-btns')}>
            <VMUpdateModal initialValue={record}>
              <span className={cx('ctrl-btn')}>编辑</span>
            </VMUpdateModal>
            <Divider type="vertical"></Divider>
            <span
              className={cx('del-btn')}
              onClick={() => {
                deleteVM(record);
              }}>
              删除
            </span>
          </div>
        ) : (
          <div className={cx('action-btns', 'item-btns')}>
            <RemoteConnectionModal connectionInfo={record.remoteConnectInfo}>
              <div className={cx('ctrl-btn')}>远程连接</div>
            </RemoteConnectionModal>
            <Divider type="vertical"></Divider>
            {record.status === VIRTUAL_MACHINE_STATUS.running.value ||
            record.status === VIRTUAL_MACHINE_STATUS.poweroff.value ? (
              <>
                <VmCopyModal
                  itemResource={{ cpu: record.requestCpu, memory: record.requestMemory, gpu: record.gpu }}
                  currentGroupVmNum={0}
                  saveVmCallback={vm => parallelDeployCallback(record.id, vm)}>
                  <div className={cx('ctrl-btn')}>并行部署</div>
                </VmCopyModal>
                <Divider type="vertical"></Divider>
                <ImageSaveModal existedImages={existedImages} newImageCallback={image => saveImage(record.id, image)}>
                  <div className={cx('ctrl-btn')}>保存镜像</div>
                </ImageSaveModal>
                <Divider type="vertical"></Divider>
              </>
            ) : (
              <></>
            )}
            <span
              className={cx('del-btn')}
              onClick={() => {
                deleteVM(record);
              }}>
              删除
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {groupCount === 0 ? (
        EmptyCard
      ) : (
        <Table
          columns={columns}
          className={cx('vm-table')}
          pagination={{
            pageSize: pagination?.pageSize,
            current: pagination?.current,
            showTotal: () => `共 ${virtualMachineGroup?.length} 条`,
            showSizeChanger: true,
            pageSizeOptions: [5, 9, 10, 20, 50, 100],
            onChange: (page, pageSize) => {
              setPagination({ current: page, pageSize: pageSize });
            },
            showQuickJumper: true,
            total: virtualMachineGroup?.length,
          }}
          dataSource={virtualMachineGroup.filter(record => {
            const typeSelected =
              typeFilterTypeList.length > 0
                ? record.vmList.find(vm => typeFilterTypeList.includes(vm.type || '未定义'))
                : true;
            const statusSelected =
              statusFilterTypeList.length > 0
                ? record.vmList.find(vm => statusFilterTypeList.includes(vm.status))
                : true;
            if (typeSelected && statusSelected) return true;
            else return false;
          })}
          loading={virtualMachineTableLoading}
          rowSelection={{
            onChange: (_selectedRowKeys, selectedRows) => {
              const selectedGroupRows = selectedRows.filter(record => record.vmList);
              setSelectedVirtualMachineGroup(selectedGroupRows);
            },
            fixed: 'left',
            renderCell: (_value, record, _index, originNode) => (record.vmList ? originNode : <></>),
          }}
          expandable={{ childrenColumnName: 'vmList' }}
          rowKey={record => record.parallelId}
          scroll={{ x: 2000 }}
          onChange={(_pagination, filters) => {
            setTypeFilterTypeList((filters?.type as string[]) || []);
            setStatusFilterTypeList((filters?.status as string[]) || []);
          }}
          onRow={record => {
            if (!!record.vmList) {
              return {};
            } else {
              const recordType = record.type || '未定义';
              const typeSelected =
                typeFilterTypeList.length > 0 ? typeFilterTypeList.find(type => type === recordType) : true;
              const statusSelected =
                statusFilterTypeList.length > 0 ? statusFilterTypeList.find(status => status === record.status) : true;
              if (typeSelected && statusSelected) return {};
              else return { style: { display: 'none' } };
            }
          }}
          rowClassName={record => {
            if (record.vmList) {
              if (virtualMachineGroup.findIndex(group => group.parallelId === record.parallelId) % 2 === 0) {
                return cx('vm-table-row-even');
              } else {
                return cx('vm-table-row-odd');
              }
            } else {
              if (virtualMachineGroup.findIndex(group => group.vmList.find(vm => vm.id === record.id)) % 2 === 0) {
                return cx('vm-table-row-even');
              } else {
                return cx('vm-table-row-odd');
              }
            }
          }}
        />
      )}
    </div>
  );
};

export default VirtualTerminalTable;

import React, { useRef } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { DatePicker, Flex } from '@fuxi/eevee-ui';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import cx from './index.module.less';

interface Prop {
  disabled?: boolean;
  recentMinChoicesList?: number[];
  onChooseCallback: (range: any) => void;
  onClearCallback: () => void;
  range?: [dayjs.Dayjs, dayjs.Dayjs];
  disabledDate?: RangePickerProps['disabledDate'];
  disabledTime?: RangePickerProps['disabledTime'];
}

const { RangePicker } = DatePicker;

export const DatePickerWithRecentTime: React.FC<Prop> = props => {
  const { onChooseCallback, onClearCallback, range, disabledDate, disabledTime, disabled } = props;
  const recentMinChoicesList = props?.recentMinChoicesList || [5, 30];
  const ref = useRef<any>(null);

  const chooseRecentTime = (recent: number) => {
    const now = new Date();
    onChooseCallback([dayjs(now).add(-recent, 'm'), dayjs(now)]);
    ref?.current?.blur();
  };

  const handleChoose = (momentRange: any) => {
    onChooseCallback(momentRange);
  };

  const onOpenChange = (e: any) => {
    //!e && isRangeValid && onChooseCallback(range);
  };

  const handleClearDate = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    onClearCallback();
  };

  return (
    <>
      <RangePicker
        disabledDate={disabledDate}
        disabledTime={disabledTime}
        disabled={disabled}
        clearIcon={<CloseCircleOutlined onClick={e => handleClearDate(e)} />}
        value={range}
        onOpenChange={onOpenChange}
        onChange={handleChoose}
        ref={ref}
        renderExtraFooter={e => (
          <Flex className={cx('recent-button-container')} alignCenter>
            {recentMinChoicesList?.map(item => (
              <div className={cx('recent-button')} onClick={() => chooseRecentTime(item)} key={item}>
                最近{item}分钟
              </div>
            ))}
          </Flex>
        )}
        showTime
      />
    </>
  );
};

import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Card, Flex, Table, Divider } from '@fuxi/eevee-ui';
import { useAntdTable, useRequest } from '@fuxi/eevee-hooks';

import service from '../../service';
import { getSorterTableData } from '../../utils/util';
import Descriptions from '../../components/Descriptions';
import { getPublicVersionDetailColumns, versionDetailInfoColumns } from './const';
import cx from './index.module.less';

const VersionDetail: React.FC = () => {
  const urlParams = useParams();
  const [urlState] = useSearchParams();
  const datasetName = urlState.get('datasetName');
  const desc = urlState.get('desc');

  const { data: detail } = useRequest(() =>
    service.dataSet.getVersionsDetails({
      dataset_version: urlParams.versionId || '',
      dataset_id: urlParams.dataId || '',
    })
  );
  const { tableProps, refresh } = useAntdTable(
    getSorterTableData(service.dataSet.getVersionsFileList, {
      extraParams: {
        id: urlParams.versionId,
        datasetsId: urlParams.dataId,
        project_id: urlParams.projectId,
        dataset_name: datasetName,
      },
    })
  );

  return (
    <Flex column className={cx['detail-page-wrapper']}>
      <Card title="版本详情" className={cx['detail-head']}>
        <Descriptions
          column={3}
          items={versionDetailInfoColumns}
          data={Object.assign({}, detail?.data, { desc }) || {}}
        />
      </Card>
      <Divider className={cx['detail-divider']} />
      <Card title="数据文件" className={cx['detail-body']}>
        <Table
          columns={getPublicVersionDetailColumns({
            refresh,
            dataSetId: urlParams.dataId || "",
            versionId: urlParams.versionId || "",
          })}
          {...tableProps}
          rowKey="id"
          pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
        />
      </Card>
    </Flex>
  );
};

export default VersionDetail;

import client from '@/service/graphql';

// import { ServerInfo } from '@/store/server';
// import { ServiceType, ServiceEnvironment } from '@/constants';
import { GET_LATEST_RELEASE_BY_SERVICE_ID, GET_SERVICES } from './schema/service/service-queries';
import { CREATE_SERVICE, CROWD_SOURCE_TASK_STATUS, RESTORE_SERVICE } from './schema/service/service-mutation';
import { ServiceEnvironment, ServiceType } from '@/typings/common';

export const services = {
  //   getServicesByProject(projectId: string) {
  //     return client.query<{ projectId: string }, { services: { items: [ServerInfo] } }>(GET_SERVICES_BY_PROJECT, {
  //       projectId,
  //     });
  //   },
  //   getServiceById(serviceId: string) {
  //     return client.query<{ serviceId: string }, { service: ServerInfo }>(GET_SERVICE_BY_ID, {
  //       serviceId,
  //     });
  //   },
  getLatestReleaseByServiceId(serviceId: string) {
    return client.query<{ serviceId: string }, { latestReleaseByServiceId: Service }>(
      GET_LATEST_RELEASE_BY_SERVICE_ID,
      {
        serviceId,
      }
    );
  },
  createService(input: CreateServiceInput) {
    return client.mutate<{ input: CreateServiceInput }, { createService: Service }>(CREATE_SERVICE, {
      input,
    });
  },
  getServicesByProject(projectId: ID) {
    return client.query<{ projectId: ID }, { services: { items: Service[] } }>(GET_SERVICES, {
      projectId,
    });
  },
  //   updateService(input: UpdateServiceInput) {
  //     return client.mutate<{ input: UpdateServiceInput }, { service: ServerInfo }>(UPDATE_SERVICE, {
  //       input,
  //     });
  //   },
  //   publishProgress(input: PublishProcessInput) {
  //     return client.mutate<{ input: PublishProcessInput }, { publishProgress: Process }>(PUBLISH_PROGRESS, {
  //       input,
  //     });
  //   },
  restoreService(projectId: string, serviceId: string) {
    return client.mutate<{ projectId: string; serviceId: string }, { service: Service }>(RESTORE_SERVICE, {
      projectId,
      serviceId,
    });
  },
  getCrowdSourceTaskStatus(serviceId: number) {
    return client.mutate<{ serviceId: number }, any>(CROWD_SOURCE_TASK_STATUS, { serviceId });
  },
};

export type CreateServiceInput = {
  name: string;
  type: ServiceType;
  description: string;
  environment: ServiceEnvironment;
  projectId: string;
};

export type UpdateServiceInput = {
  serviceId: number;
  dsl: Process;
};

export type PublishProcessInput = {
  serviceId: number;
  dsl: Process;
};
export interface Process {
  uiData: any;
  graphData: any;
}

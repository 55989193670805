export enum ServiceEnvironment {
  DEV = 'DEV',
  PRO = 'PRO',
  FAT = 'FAT',
}

export enum ServiceStatus {
  // 创建中
  Creating = 'CREATING',
  // 异常
  Error = 'ERROR',
  // 运行中
  Running = 'RUNNING',
  // 启动中
  Starting = 'STARTING',
  // 已暂停
  Paused = 'PAUSED',
  // 未开启
  Pending = 'PENDING',
  // 创建失败
  InitError = 'INIT_ERROR',
  // 未启用
  UnStart = 'UNSTART',
  // 关闭中
  Closing = 'CLOSING',
  // 重启中
  Restarting = 'RESTARTING',
  // 发布中
  Releasing = 'RELEASING',
  // 审核中
  Auditing = 'AUDITING',
  // 审核失败
  AuditFial = 'AUDIT_FAIL',
  // 模板-上架
  Online = 'ONLINE',
  // 模板-下架
  Offline = 'OFFLINE',
}

export enum ServiceType {
  Main = 'MAIN',
  Personal = 'PERSONAL',
  PAGE = 'PAGE',
  GENERATE_CODE = 'GENERATE_CODE',
  TABLE = 'TABLE',
}

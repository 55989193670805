import React, { forwardRef, useEffect, useState, useImperativeHandle, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { isEmpty, cloneDeep, sampleSize } from 'lodash-es';
import { useRequest } from '@fuxi/eevee-hooks';
import { v4 as uuid } from 'uuid';
import { Form, Input, Radio, Select, Cascader, Typography } from '@fuxi/eevee-ui';
import { TrainFormDataConfig, FormValues } from '@ai-training/types/ability';
import service from '@ai-training/service';

import { CurrentVersionStatus, VersionStatus } from '@ai-training/constants';

import model from '@ai-training/service/model';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import {
  selectDdlInfo,
  selectModelList,
  selectModelBucketId,
  selectFormDataExtra,
  selectFormDataForBaseOfTrain,
  selectFormDataForVersionOfTrain,
  selectCurrentVersionStatus,
} from '@/store/aiTraining';

import { convertOriginFormDataToValues, isFormDataEmpty } from '../NewAIBodyVersion';
import UploadModel from './UploadModel';
import UploadDDL from './UploadDDL';
import cx from './VersionBaseConfig.module.less';

const { getModelUploadUuid, getModelFrameworkInfo, getModelFrameworkVersionInfo, getModelFrameworkVersionFormatInfo } =
  model;

type VersionBaseConfigProps = {
  models: any[];
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  isReadyToFillForm?: boolean;
  children: React.ReactNode;
};

export enum VersionBaseFieldNames {
  VersionCode = 'version_code',
  Description = 'description',
  ModelSource = 'modelSource',
  CustomModel = 'model',
  LibraryModel = 'library_model',
  ModelBucketUuid = 'model_bucket_uuid',
  ModelVersion = 'model_version',
  ModelName = 'model_name',
  DatasetType = 'dataset_type',
  DatasetInput = 'input',
  DatasetOutput = 'output',
  DdlId = 'ddl_id',
  DdlFile = 'ddl_file',
  ModelFile = 'model_file',
}

export enum ModelVersionFieldNames {
  Version = 'version',
  Framework = 'framework',
  FrameworkName = 'framework_name',
  FrameworkVersion = 'framework_version',
  FormatId = 'format_id',
  FrameworkFormat = 'framework_format',
  ModelName = 'model_name',
  ModelFile = 'model_file',
  LocalPath = 'local_path',
  FileName = 'file_name',
  UploadedFileName = 'uploaded_file_name',
  ModelFileInfo = 'model_file_info',
  YlTag = 'yl_tag',
  Description = 'description',
}

const modelSourceOptions = [
  { label: '自定义创建', value: 'custom' },
  { label: '从模型库选择', value: 'existing' },
];

export const datasetTypeOptions = [
  { label: 'CSV', value: 'csv' },
  { label: 'WebDataset', value: 'webdataset' },
];

const transformModelListToOptions = data => {
  const result = [] as any[];

  data.forEach(item => {
    const existingItem = result.find(i => i.value === item.name);

    if (existingItem) {
      existingItem.children.push({ label: `v${item.version}`, value: item.version });
    } else {
      result.push({
        label: item.name,
        value: item.name,
        children: [{ label: `v${item.version}`, value: item.version }],
      });
    }
  });

  return result;
};

export const VersionBaseConfig = forwardRef(({ setIsLoading, children, models }: VersionBaseConfigProps, ref) => {
  // const { data: uploadModelUuid, run: fetchUploadModelUuid } = useRequest(getModelUploadUuid, {
  //   manual: true,
  // });
  const [uploadModelUuid, setUploadModelUuid] = useState<any>(null);
  // const uploadUuid = useRef(uploadModelUuid?.data?.upload_uuid);

  // useEffect(() => {
  //   // if (!uploadUuid?.current) {
  //   uploadUuid.current = uploadModelUuid?.data?.upload_uuid;
  //   // }

  //   // console.log("latetst", uploadUuid.current);
  // }, [uploadModelUuid]);

  const { data: frameworkInfoRes, loading: modelFrameworkInfoLoading } = useRequest(
    () => getModelFrameworkInfo()
    // { ready: !isUpdate }
  );
  const {
    data: frameworkVersionInfoRes,
    run: toGetModelFrameworkVersionInfo,
    loading: modelFrameworkVersionInfoLoading,
  } = useRequest(framework => getModelFrameworkVersionInfo({ framework }), { manual: true });
  const {
    data: frameworkVersionFormatInfoRes,
    run: toGetModelFrameworkVersionFormatInfo,
    loading: modelFrameworkVersionFormatInfoLoading,
  } = useRequest(
    ({ framework, frameworkVersion }) => getModelFrameworkVersionFormatInfo({ framework, frameworkVersion }),
    { manual: true }
  );

  const [frameworkFormat, setFrameworkFormat] = useState('');

  const [currentModel, setCurrentModel] = useState<any>({});

  const [form] = Form.useForm();
  const urlParams = useParams();
  const dispatch = useAppDispatch();
  const [urlState] = useSearchParams();
  const versionStatus = urlState.get('version_status');

  const [isCSV, setIsCSV] = useState(true);
  const [isCustomModel, setIsCustomModel] = useState(true);
  const [ddlInputOptions, setDdlInputOptions] = useState([]);
  const [ddlOutputOptions, setDdlOutputOptions] = useState([]);

  const ddlInfo = useAppSelector(selectDdlInfo);
  const modelList = useAppSelector(selectModelList);
  const formDataExtra = useAppSelector(selectFormDataExtra);
  const modelBucketId = useAppSelector(selectModelBucketId);
  const currentVersionStatus = useAppSelector(selectCurrentVersionStatus);
  const formDataForBaseOfTrain = useAppSelector(selectFormDataForBaseOfTrain);
  const formDataForVersionOfTrain = useAppSelector(selectFormDataForVersionOfTrain);

  const [capNameInCreate, setCapNameInCreate] = useState('');
  //设置ddl的输入输出选项
  const setDdlOptions = ({ ddlInputOptions, ddlOutputOptions }) => {
    setDdlInputOptions(ddlInputOptions);
    setDdlOutputOptions(ddlOutputOptions);
  };

  //新建时计算版本号用于展示
  const { loading: versionLoading } = useRequest(
    () => service.ability.getCapabilityVersions({ capability_uuid: urlParams.abilityId || '' }),
    {
      onSuccess: data => {
        setCapNameInCreate(data?.data?.list?.[0]?.capability_name);
        const latestVersion =
          data?.data?.list?.length === 0 ? 0 : Math.max(...data?.data?.list?.map(item => item.code));
        const currentVersion =
          data?.data?.list?.length === 0
            ? 0
            : latestVersion || Math.max(...data?.data?.list?.map((item, idx) => idx + 1));
        form.setFieldValue(VersionBaseFieldNames.VersionCode, currentVersion + 1);
      },
      ready: !!urlParams.abilityId && (versionStatus === VersionStatus.Create || versionStatus === VersionStatus.Copy),
    }
  );

  const getFieldValuesFromOriginData = () => {
    if (isFormDataEmpty(formDataForBaseOfTrain)) {
      return {};
    }

    // 获取上次上传的ddl文件名
    if (formDataForBaseOfTrain?.[VersionBaseFieldNames.DdlId]) {
      const ddlOptions = Object.entries(ddlInfo?.ddl ?? {}).map(([key, value]) => ({ label: key, value }));
      setDdlOptions({ ddlInputOptions: ddlOptions, ddlOutputOptions: ddlOptions });
      form.setFieldValue(VersionBaseFieldNames.DdlId, formDataForBaseOfTrain?.[VersionBaseFieldNames.DdlId]);
      !!ddlInfo?.file_name &&
        form.setFieldValue(VersionBaseFieldNames.DdlFile, [
          {
            name: ddlInfo?.file_name,
            uid: formDataForBaseOfTrain?.[VersionBaseFieldNames.DdlId],
            percent: 100,
            status: 'success',
          },
        ]);
    } else if (formDataExtra?.dataset?.dataset_type === 'webdataset') {
      form.setFieldValue(VersionBaseFieldNames.DdlFile, [
        {
          name: 'default_ddl.py',
          uid: `${new Date().getTime()}default_ddl.py`,
          percent: 100,
          status: 'success',
        },
      ]);
    }

    const fieldsPart1 = convertOriginFormDataToValues(formDataForBaseOfTrain);
    const fieldsPart2 = convertOriginFormDataToValues(formDataForVersionOfTrain);
    const { code: version, description = '' } = formDataForVersionOfTrain as { code: string; description: string };
    const finalFields = { ...fieldsPart1, ...fieldsPart2 };

    if (versionStatus !== VersionStatus.Copy) {
      finalFields[VersionBaseFieldNames.VersionCode] = version;
    }
    finalFields[VersionBaseFieldNames.Description] = description;

    // 判断模型是自定义还是模型库里的
    const inModelPackage = modelList?.find(
      item => item.label === formDataForBaseOfTrain?.[VersionBaseFieldNames.ModelName]?.[0]
    );

    if (!inModelPackage) {
      setIsCustomModel(true);
      finalFields[VersionBaseFieldNames.ModelSource] = 'custom';
      formDataExtra.model?.file_name &&
        (finalFields[VersionBaseFieldNames.ModelFile] = [
          {
            name: formDataExtra.model?.file_name,
            uid: `${uuid()}`,
            percent: 100,
            status: 'success',
          },
        ]);
      finalFields[VersionBaseFieldNames.ModelName] =
        formDataExtra[VersionBaseFieldNames.CustomModel]![VersionBaseFieldNames.ModelName]?.[0];
      finalFields[VersionBaseFieldNames.CustomModel] = cloneDeep(formDataExtra[VersionBaseFieldNames.CustomModel]);
      finalFields[VersionBaseFieldNames.CustomModel]![VersionBaseFieldNames.ModelName] =
        formDataExtra[VersionBaseFieldNames.CustomModel]![VersionBaseFieldNames.ModelName]?.[0];
    } else {
      setIsCustomModel(false);

      // 待测试
      const modelVersion = inModelPackage?.children?.find(
        item => item.value === formDataForBaseOfTrain?.[VersionBaseFieldNames.ModelVersion]?.[0]
      );

      finalFields[VersionBaseFieldNames.ModelName] =
        formDataExtra[VersionBaseFieldNames.CustomModel]![VersionBaseFieldNames.ModelName]?.[0];
      finalFields[VersionBaseFieldNames.CustomModel] = cloneDeep(formDataExtra[VersionBaseFieldNames.CustomModel]);
      finalFields[VersionBaseFieldNames.CustomModel]![VersionBaseFieldNames.ModelName] =
        formDataExtra[VersionBaseFieldNames.CustomModel]![VersionBaseFieldNames.ModelName]?.[0];

      finalFields[VersionBaseFieldNames.ModelSource] = 'existing';
      finalFields[VersionBaseFieldNames.LibraryModel] = [
        formDataForBaseOfTrain?.[VersionBaseFieldNames.ModelName]?.[0],
        formDataForBaseOfTrain?.[VersionBaseFieldNames.ModelVersion]?.[0],
      ];

      const currentModel = models.find(
        item => item.name === finalFields[VersionBaseFieldNames.ModelName] && item.version === modelVersion?.value
      );

      // @ts-ignore
      const fileName = currentModel?.fileNames;

      if (!finalFields[VersionBaseFieldNames.ModelFile]) {
        finalFields[VersionBaseFieldNames.ModelFile] = [];
      }
      currentModel?.fileNames?.forEach(item => {
        finalFields[VersionBaseFieldNames.ModelFile].push({
          name: item,
          uid: `${uuid()}`,
          percent: 100,
          status: 'success',
        });
      });

      setCurrentModel(currentModel);

      // @ts-ignore
      // fileName &&
      //   (finalFields[VersionBaseFieldNames.ModelFile] = [
      //     {
      //       // @ts-ignore
      //       name: fileName,
      //       uid: `${uuid()}`,
      //       percent: 100,
      //       status: 'success',
      //     },
      //   ]);

      // finalFormData[VersionBaseFieldNames.CustomModel]

      if (currentModel?.framework_name && currentModel?.framework_name !== '-') {
        finalFields[ModelVersionFieldNames.Framework] =
          currentModel?.framework_name === '-' ? undefined : currentModel?.framework_name;
        toGetModelFrameworkVersionInfo(currentModel?.framework_name);
      }

      if (currentModel?.framework_version && currentModel?.framework_version !== '-') {
        finalFields[ModelVersionFieldNames.FrameworkVersion] =
          currentModel?.framework_version === '-' ? undefined : currentModel?.framework_version;
        toGetModelFrameworkVersionFormatInfo({
          framework: currentModel?.framework_name,
          frameworkVersion: currentModel?.framework_version,
        });
      }

      // if (currentModel?.framework_format && currentModel?.framework_format !== '-') {
      //   console.log(frameworkVersionFormatInfoRes, frameworkVersionFormatInfoRes?.data);
      //   setFrameworkFormat(
      //     frameworkVersionFormatInfoRes?.data?.find(item => item.id === currentModel?.framework_format)?.[0]
      //       ?.framework_format
      //   );
      //   finalFields[ModelVersionFieldNames.FormatId] =
      //     currentModel?.framework_format === '-' ? undefined : currentModel?.framework_format;
      // }
    }

    finalFields[VersionBaseFieldNames.DatasetType] = formDataExtra?.dataset?.dataset_type || 'csv';
    finalFields[VersionBaseFieldNames.DatasetInput] = formDataExtra?.dataset?.input || undefined;
    finalFields[VersionBaseFieldNames.DatasetOutput] = formDataExtra?.dataset?.output || undefined;

    setIsCSV(finalFields[VersionBaseFieldNames.DatasetType] === 'csv' ? true : false);

    return finalFields;
  };

  useEffect(() => {
    form.setFieldValue(VersionBaseFieldNames.ModelSource, 'custom');
    form.setFieldValue(VersionBaseFieldNames.DatasetType, 'csv');
  }, []);

  useImperativeHandle(ref, () => ({
    getFieldsError() {
      return form.getFieldsError();
    },
    getFieldValuesFromOriginData() {
      return getFieldValuesFromOriginData();
    },
    getFieldValues() {
      return form.getFieldsValue(true);
    },
    submit(cb: (finalFormData: TrainFormDataConfig) => Promise<void>) {
      form.validateFields().then(async (values: FormValues) => {
        const finalFormData: TrainFormDataConfig = {} as TrainFormDataConfig;
        finalFormData[VersionBaseFieldNames.Description] = values[VersionBaseFieldNames.Description];

        if (values[VersionBaseFieldNames.ModelSource] === 'custom') {
          finalFormData[VersionBaseFieldNames.CustomModel] = values[VersionBaseFieldNames.CustomModel];

          if (values[ModelVersionFieldNames.FormatId]) {
            finalFormData[VersionBaseFieldNames.CustomModel]![ModelVersionFieldNames.FormatId] =
              values[ModelVersionFieldNames.FormatId];
          }

          finalFormData[VersionBaseFieldNames.CustomModel]![VersionBaseFieldNames.ModelName] = [
            values[VersionBaseFieldNames.CustomModel][VersionBaseFieldNames.ModelName],
          ]; //model_name传给后端字符串数组

          // 检查是否有上传的模型文件
          if (values?.[VersionBaseFieldNames.ModelFile]) {
            finalFormData[VersionBaseFieldNames.CustomModel]!['upload_uuid'] = uploadModelUuid;
          }
        } else {
          finalFormData[VersionBaseFieldNames.ModelName] = [values[VersionBaseFieldNames.LibraryModel]?.[0]]; //model_name传给后端字符串数组
          finalFormData[VersionBaseFieldNames.ModelVersion] = [values[VersionBaseFieldNames.LibraryModel]?.[1]]; //model_version传给后端字符串数组
          finalFormData[VersionBaseFieldNames.ModelBucketUuid] = modelBucketId;
        }

        finalFormData[VersionBaseFieldNames.DatasetType] = values[VersionBaseFieldNames.DatasetType];
        if (values[VersionBaseFieldNames.DatasetType] === 'webdataset') {
          finalFormData[VersionBaseFieldNames.DatasetInput] = values[VersionBaseFieldNames.DatasetInput];
          finalFormData[VersionBaseFieldNames.DatasetOutput] = values[VersionBaseFieldNames.DatasetOutput];
          finalFormData[VersionBaseFieldNames.DdlId] = values[VersionBaseFieldNames.DdlId];
        }
        typeof cb === 'function' && cb(finalFormData);
      });
    },
  }));

  useEffect(() => {
    if (
      currentVersionStatus === CurrentVersionStatus.Update ||
      (versionStatus === VersionStatus.Copy && currentVersionStatus === CurrentVersionStatus.Copy)
    ) {
      const finalFields = getFieldValuesFromOriginData();

      if (currentModel?.framework_format && currentModel?.framework_format !== '-') {
        // console.log(frameworkVersionFormatInfoRes, frameworkVersionFormatInfoRes?.data);

        getModelFrameworkVersionFormatInfo({
          framework: currentModel?.framework_name,
          frameworkVersion: currentModel?.framework_version,
        }).then(res => {
          const target = res?.data?.find(item => item.framework_format === currentModel?.framework_format);
          setFrameworkFormat(
            frameworkVersionFormatInfoRes?.data?.find(item => item.id === currentModel?.framework_format)?.[0]
              ?.framework_format
          );

          // form.setFieldValue(
          //   ModelVersionFieldNames.FormatId,
          //   currentModel?.framework_format === '-' ? undefined : target?.id
          // );

          finalFields[ModelVersionFieldNames.FormatId] =
            currentModel?.framework_format === '-' ? undefined : target?.id;

          form.setFieldsValue(finalFields);
        });

        // setFrameworkFormat(
        //   frameworkVersionFormatInfoRes?.data?.find(item => item.id === currentModel?.framework_format)?.[0]
        //     ?.framework_format
        // );
        // finalFields[ModelVersionFieldNames.FormatId] = currentModel?.framework_format === '-' ? undefined : target?.id;
      }

      form.setFieldsValue(finalFields);
    }
  }, [versionStatus, currentVersionStatus, currentModel]);

  const validateFormatId = (name: string) => {
    const values = form.getFieldsValue([
      ModelVersionFieldNames.Framework,
      ModelVersionFieldNames.FrameworkVersion,
      ModelVersionFieldNames.FormatId,
    ]);
    const isAnyFieldFilled = Object.values(values).some(value => !!value);

    if (isAnyFieldFilled && !values[name]) {
      return Promise.reject('请完整填写训练框架信息');
    }

    return Promise.resolve();
  };

  return (
    <div className={cx['version-base-config-wrapper']}>
      <Form form={form} labelAlign="right" labelCol={{ span: 5 }} className={cx['form-body']}>
        <div className={cx['config-item']}>
          <div className={cx['config-item-title']}>版本信息</div>
          <div className={cx['config-item-body']}>
            <Form.Item required label={'版本'} valuePropName="children" name={VersionBaseFieldNames.VersionCode}>
              <Typography.Text />
            </Form.Item>

            <Form.Item label={'描述'} name={VersionBaseFieldNames.Description} rules={[]}>
              <Input.TextArea showCount maxLength={200} placeholder="请输入" className={cx['form-item-widget']} />
            </Form.Item>
          </div>
        </div>

        <div className={cx['config-item']}>
          <div className={cx['config-item-title']}>模型配置</div>
          <div className={cx['config-item-body']}>
            <Form.Item label={'模型来源'} name={VersionBaseFieldNames.ModelSource} required>
              <Radio.Group
                options={modelSourceOptions}
                style={{ backgroundColor: 'transparent' }}
                onChange={e => setIsCustomModel(e.target.value === 'custom' ? true : false)}
              />
            </Form.Item>
            <Form.Item
              hidden
              name={VersionBaseFieldNames.CustomModel}
              rules={isCustomModel ? [{ required: true, message: '请上传模型文件' }] : []}
            />
            {isCustomModel ? (
              <>
                <Form.Item
                  label="训练框架"
                  name={ModelVersionFieldNames.Framework}
                  rules={[
                    {
                      validator: () => validateFormatId(ModelVersionFieldNames.Framework),
                    },
                  ]}
                  // rules={isUpdate ? undefined : [{ required: true, message: '请选择训练框架' }]}
                >
                  <Select
                    allowClear
                    disabled={currentVersionStatus === CurrentVersionStatus.Update}
                    placeholder="请选择"
                    onChange={framework => {
                      toGetModelFrameworkVersionInfo(framework);
                      form.setFieldsValue({ [ModelVersionFieldNames.FrameworkVersion]: undefined });
                      form.setFieldsValue({ [ModelVersionFieldNames.FormatId]: undefined });

                      if (framework === undefined) {
                        form.validateFields([ModelVersionFieldNames.FrameworkVersion]);
                        form.validateFields([ModelVersionFieldNames.FormatId]);
                        form.validateFields([ModelVersionFieldNames.ModelFile]);
                      }
                    }}
                    loading={modelFrameworkInfoLoading}
                    options={frameworkInfoRes?.data?.map(item => ({ label: item, value: item }))}
                  />
                </Form.Item>
                <Form.Item
                  label="框架版本"
                  name={ModelVersionFieldNames.FrameworkVersion}
                  rules={[
                    {
                      validator: () => validateFormatId(ModelVersionFieldNames.FrameworkVersion),
                    },
                  ]}
                  // rules={isUpdate ? undefined : [{ required: true, message: '请选择框架版本' }]}
                >
                  <Select
                    allowClear
                    disabled={currentVersionStatus === CurrentVersionStatus.Update}
                    placeholder="请选择"
                    onChange={frameworkVersion => {
                      toGetModelFrameworkVersionFormatInfo({
                        framework: form.getFieldValue(ModelVersionFieldNames.Framework),
                        frameworkVersion,
                      });
                      form.setFieldsValue({ [ModelVersionFieldNames.FormatId]: undefined });
                    }}
                    loading={modelFrameworkVersionInfoLoading}
                    options={frameworkVersionInfoRes?.data?.map(item => ({ label: item, value: item }))}
                  />
                </Form.Item>
                <Form.Item
                  label="存储格式"
                  name={ModelVersionFieldNames.FormatId}
                  rules={[
                    {
                      validator: () => validateFormatId(ModelVersionFieldNames.FormatId),
                    },
                  ]}
                  // rules={isUpdate ? undefined : [{ required: true, message: '请选择存储格式' }]}
                >
                  <Select
                    onChange={(e, option) => {
                      // @ts-ignore
                      setFrameworkFormat(option?.label);
                    }}
                    value={form.getFieldValue(ModelVersionFieldNames.FormatId) ?? undefined}
                    allowClear
                    disabled={currentVersionStatus === CurrentVersionStatus.Update}
                    placeholder="请选择"
                    // fieldNames={{ label: 'framework_format', value: 'id' }}
                    options={
                      frameworkVersionFormatInfoRes?.data?.map(item => ({
                        label: item?.framework_format,
                        value: item?.id,
                      })) ?? []
                    }
                    loading={modelFrameworkVersionFormatInfoLoading}
                  />
                </Form.Item>

                <Form.Item
                  label={'基础模型'}
                  trigger="validateFileName"
                  validateTrigger={'validateFileName'}
                  name={VersionBaseFieldNames.ModelFile}>
                  <UploadModel
                    formatId={form.getFieldValue(ModelVersionFieldNames.FormatId)}
                    beforeUploadCallback={uuid => setUploadModelUuid(uuid)}
                    // onFileNameListChange={
                    //   // 清空已上传文件，初始化upload_uuid
                    //   () => {
                    //     form.setFieldsValue({ [VersionBaseFieldNames.ModelFile]: [] });
                    //     fetchUploadModelUuid();
                    //   }
                    // }
                    isSavedModel={frameworkFormat === 'SavedModel'}
                    // sliceUploadUrl="/api/v1/upload/model"
                    // uploadSuccessUrl="/api/v1/upload/fin"
                    onSuccess={() => {
                      const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
                      const randomString = sampleSize(characters, 8).join('');

                      if (!form.getFieldValue([VersionBaseFieldNames.CustomModel, VersionBaseFieldNames.ModelName])) {
                        form.setFieldsValue({
                          [VersionBaseFieldNames.CustomModel]: {
                            [VersionBaseFieldNames.ModelName]: `${capNameInCreate}_${randomString}`,
                          },
                        });
                      }
                    }}
                    payload={{
                      uploadModelUuid: uploadModelUuid,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label={'模型名称'}
                  name={[VersionBaseFieldNames.CustomModel, VersionBaseFieldNames.ModelName]}
                  rules={
                    isCustomModel
                      ? [
                          { required: true, message: '模型名称不得为空' },
                          {
                            pattern: /^[a-zA-Z][a-zA-Z0-9_-]*$/,
                            message: '模型名称只能包含字母、数字、下划线、中划线；且只能由大小写字母开头',
                          },
                          () => ({
                            validator: (_: any, value: string) => {
                              if (versionStatus !== VersionStatus.Create) {
                                return Promise.resolve();
                              } else if (!value) {
                                return Promise.resolve();
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]
                      : []
                  }>
                  <Input
                    autoComplete="off"
                    maxLength={64}
                    placeholder="请输入"
                    className={cx['form-item-widget']}
                    disabled={!isEmpty(formDataExtra?.[VersionBaseFieldNames.CustomModel])}
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item
                required
                label={'选择模型'}
                name={VersionBaseFieldNames.LibraryModel}
                rules={isCustomModel ? [] : [{ required: true, message: '请选择模型' }]}>
                <Cascader placeholder="请选择" className={cx['form-item-widget']} options={modelList} />
              </Form.Item>
            )}
          </div>
        </div>

        <div className={cx['config-item']}>
          <div className={cx['config-item-title']}>数据集配置</div>
          <div className={cx['config-item-body']}>
            <Form.Item required label={'数据集格式'} name={VersionBaseFieldNames.DatasetType}>
              <Radio.Group
                options={datasetTypeOptions}
                style={{ backgroundColor: 'transparent' }}
                onChange={e => setIsCSV(e.target.value === 'csv' ? true : false)}
              />
            </Form.Item>
            {!isCSV && (
              <>
                <Form.Item
                  required
                  label={'DDL文件'}
                  tooltip={'请上传.py文件'}
                  name={VersionBaseFieldNames.DdlFile}
                  trigger="validateFileName"
                  validateTrigger={'validateFileName'}
                  rules={[{ required: true, message: '请上传DDL文件' }]}>
                  <UploadDDL setDdlOptions={setDdlOptions} />
                </Form.Item>
                <Form.Item hidden name={VersionBaseFieldNames.DdlId} />
                <Form.Item
                  required
                  label={'Input字段'}
                  name={VersionBaseFieldNames.DatasetInput}
                  rules={isCSV ? [] : [{ required: true, message: '请选择Input字段' }]}>
                  <Select
                    allowClear
                    options={ddlInputOptions}
                    placeholder="请上传DDL文件后选择"
                    className={cx['form-item-widget']}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label={'Output字段'}
                  name={VersionBaseFieldNames.DatasetOutput}
                  rules={isCSV ? [] : [{ required: true, message: '请选择Output字段' }]}>
                  <Select
                    allowClear
                    options={ddlOutputOptions}
                    placeholder="请上传DDL文件后选择"
                    className={cx['form-item-widget']}
                  />
                </Form.Item>
              </>
            )}
          </div>
        </div>
      </Form>
      {children}
    </div>
  );
});

import { AxiosResponse } from 'axios';
import { isNil } from 'lodash';

import { Result } from './const';
import { TrainFormDataConfig } from '@ai-training/types/ability';
import { FieldNamesForTrain } from '@ai-training/constants';

function createAntdTableList(res: AxiosResponse<any>) {
  return {
    total: res.data?.pagination?.total,
    current: res.data?.pagination?.page,
    pageSize: res.data?.pagination?.page_size,
    list: res.data?.list,
  };
}

export const getSorterTableData =
  (
    service: (params: any) => Promise<AxiosResponse<any>>,
    options?: {
      extraParams?: any;
      beforeSubmit?: (params: any) => any;
      setSource?: (value: AxiosResponse<any> | undefined) => void;
    }
  ) =>
  async ({ current, pageSize, sorter }: any, formData: any): Promise<Result<any>> => {
    const { extraParams, beforeSubmit, setSource } = options || {};

    let desc: boolean | undefined = undefined;
    let orderBy = undefined;
    if (!isNil(sorter?.order)) {
      desc = sorter.order === 'ascend' ? false : true;
      orderBy = sorter.field;
    }

    const params = beforeSubmit?.({
      page: current,
      page_size: pageSize,
      ...formData,
      ...extraParams,
      desc,
      orderBy,
    }) || {
      page: current,
      page_size: pageSize,
      ...formData,
      ...extraParams,
      desc,
      orderBy,
    };

    return await service(params)
      .then(res => {
        if (!res.data?.list?.length && res.data?.pagination?.total) {
          params.page = params.page - 1;
          return service(params);
        }
        return res;
      })
      .then(res => {
        setSource?.(res);
        return createAntdTableList(res);
      });
  };

export const filterVal = (options, target, value = 'value', label = 'label', children = 'children') => {
  if (!options.length) {
    return;
  }
  let result: any = null;
  options.forEach(item => {
    if (item[value] === target) {
      result = item;
    }
    if (item[children]) {
      const targetItem = filterVal(item[children], target, value, label, children);
      if (targetItem) {
        result = targetItem;
      }
    }
  });
  return result;
};
/* 倒计时 */
export const countDown = ({ countdown, onEnd, onProcess, interval = 1000 }) => {
  const timer = setInterval(() => {
    countdown = countdown - interval;
    onProcess && onProcess(countdown);
    if (countdown < interval) {
      onEnd && onEnd(countdown);
      clearInterval(timer);
    }
  }, interval);
  return timer;
};

/* 秒转小时分钟,不满一分钟按一分钟计算 */
export const formatDuration = seconds => {
  if (!seconds) {
    return 0;
  }
  let hour = Math.floor(seconds / 3600);
  let minute = Math.ceil((seconds % 3600) / 60);
  if (minute === 60) {
    hour++;
    minute = 0;
  }
  if (hour > 0) {
    return `${hour}小时${minute}分钟`;
  }
  return `${minute}分钟`;
};

/* 字节转kb/MB/GB */
export const formatFileSize = (bytes: number) => {
  if (bytes < 1024) {
    return bytes + 'B';
  } else if (bytes < 1048576) {
    return (bytes / 1024).toFixed(2) + 'KB';
  } else if (bytes < 1073741824) {
    return (bytes / 1048576).toFixed(2) + 'MB';
  } else {
    return (bytes / 1073741824).toFixed(2) + 'GB';
  }
};

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const isValueInEnumFieldTrain = (value: string): boolean => {
  return Object.values(FieldNamesForTrain).includes(value as FieldNamesForTrain);
};

export const getEmptyDataFromObj = (formData: TrainFormDataConfig) => {
  const emptyData = {};
  Object.keys(formData).forEach(key => {
    if (isValueInEnumFieldTrain(key)) {
      emptyData[key] = null;
    }
  });

  return emptyData;
};

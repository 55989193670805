import React from 'react';
import { useAppSelector } from '@/hooks';
import { useIsDataManager } from './ProjectDetailMini/utils/useIsDataManager';
import ProjectContainer from './ProjectContainer';
import ProjectList from './ProjectList';
import EditProject from './EditProject';
import DeleteProject from './DeleteProject';
import ProjectCreator from './ProjectCreator';
import CreateProjectEntry from './CreateProjectEntry';
import cx from './index.module.less';

const ProjectManage = () => {
  const userInfo = useAppSelector(state => state.user);
  const isSuperAdmin = useIsDataManager(userInfo);
  const showCreateProjectButtonInProd = () => {
    return isSuperAdmin;
  };

  return (
    <div className={cx('container')}>
      <ProjectContainer.Provider>
        {showCreateProjectButtonInProd() ? <ProjectCreator /> : <div className={cx('placeholder')}></div>}
        <ProjectList />
        <CreateProjectEntry />
        <EditProject useContainer={ProjectContainer.useContainer} />
        <DeleteProject useContainer={ProjectContainer.useContainer} />
      </ProjectContainer.Provider>
    </div>
  );
};

export default ProjectManage;

import Axios from 'axios';
import cookie from 'react-cookies';

import { createBrowserRouter } from 'react-router-dom';

import { DefaultAiProjectId } from '@ai-training/utils/environment';

import routerConfig from '@/router/config';

import responseHandler, { webInstanceResponseHandler } from './utils/responseHandler';
import errorHandler, { webInstanceErrorHandler } from './utils/errorHandler';

import { shanquanResponseHandler } from './utils/shanquanResponseHandler';

// import { routerConfig } from '@ai-training/router/config';

export * from './utils/URLGroup';

Axios.defaults.baseURL = '/ai-training/';

const instance = Axios.create({
  timeout: 6000000,
});

instance.interceptors.request.use(config => {
  return config;
});

instance.interceptors.response.use(responseHandler, errorHandler);

export const MarkdownInstance = Axios.create({
  baseURL: '/markdown',
  timeout: 60000,
});

MarkdownInstance.interceptors.response.use(responseHandler, errorHandler);

// 后端新的请求地址
export const WebInstance = Axios.create({
  baseURL: '/web/api/v1',
  timeout: 60000,
});

WebInstance.interceptors.response.use(webInstanceResponseHandler, webInstanceErrorHandler);

WebInstance.interceptors.request.use(config => {
  const token = cookie.load('RBAC_TOKEN');
  // @ts-ignore:next-line
  // TODO TS 类型完善
  config.headers['X-Rbac-Token'] = token || '';
  return config;
});

// 丹炉sdk升级联调
export const ShanquanInstance = Axios.create({
  baseURL: '/shanquan/api/v1',
  timeout: 6000000,
});

ShanquanInstance.interceptors.response.use(shanquanResponseHandler, errorHandler);

ShanquanInstance.interceptors.request.use(config => {
  // if (!config?.headers) {
  //   config.headers = {};
  // }
  const routerPath = createBrowserRouter(routerConfig);
  const pathname = window.location.pathname;
  const params = routerPath.state.matches.find(match => match.pathname === pathname)?.params;
  const token = cookie.load('RBAC_TOKEN');
  const danluProjectId = params?.projectId;
  config.headers!['X-Rbac-Token'] = token || '';
  config.headers!['platform'] = 'yl';
  if (!danluProjectId) {
    config.headers!['Project_id'] = window[DefaultAiProjectId];
  } else {
    config.headers!['Project_id'] = danluProjectId;
  }
  return config;

});

export default instance;

import React, { useEffect, useRef, useState } from 'react';
import { Flex, Image, Tooltip, Row } from '@fuxi/eevee-ui';
import { findIndex } from 'lodash-es';
import Tag from '@/pages/templateCenter/Tag';
import { Template, updateTemplateDetail } from '@/store/template';
import IconFont from '@/components/IconFont';
import { useAppDispatch } from '@/hooks';
import placeholderImg from '@/assets/image/project-in-list.png';
import { nullImage } from '@/pages/project/const';
import cx from './index.module.less';

type Props = {
  data: Template;
};
const TagHoverTipElementWidth = 36;

const TemplateItem: React.FC<Props> = ({ data }) => {
  const { iconUrl, name, description, source, tags, id } = data;
  const dispatch = useAppDispatch();
  const [divisionIndex, setDivisionIndex] = useState<number | null>(null);
  const tagsContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let width = tagsContainer.current?.clientWidth!;
    if (width) {
      // +x tag 的宽度
      width -= TagHoverTipElementWidth;
      // 计算一行最多能放几个tag
      const index = findIndex(tags, item => {
        // 一个tag不带内容最少22px
        width -= 22;
        // 每个字14px
        const contentWidth = item.tagName.split('').reduce((prev, current) => {
          // 如果是中文认为是14px宽 如果是英文认为是7px宽
          return prev + (new RegExp('[\u4E00-\u9FA5]+').test(current) ? 14 : 10);
        }, 0);
        width -= contentWidth;
        return width <= 0;
      });
      setDivisionIndex(index < 0 ? tags.length : index === 0 ? 1 : index);
    }
  }, [tagsContainer]);

  return (
    <div
      className={cx('container')}
      onClick={() =>
        dispatch(
          updateTemplateDetail({
            id,
            visible: true,
          })
        )
      }>
      <Image
        style={{ background: '#2c3038' }}
        preview={false}
        alt={'模板图片'}
        className={cx('card-img')}
        src={(iconUrl !== nullImage && iconUrl) || placeholderImg}
      />
      <div className={cx('content')}>
        <div className={cx('title')}>{name}</div>
        <div className={cx('tags')} ref={tagsContainer}>
          {typeof divisionIndex === 'number' && (
            <>
              {tags &&
                tags.slice(0, divisionIndex).map(item => {
                  return <Tag {...item} key={item.tagId} />;
                })}
              {divisionIndex < tags.length && (
                <Tooltip
                  title={
                    <Row gutter={[4, 4]}>
                      {tags &&
                        tags.slice(divisionIndex).map(item => {
                          return <Tag {...item} key={item.tagId} />;
                        })}
                    </Row>
                  }>
                  <Tag tagName={`+${tags.length - divisionIndex}`} style={{ width: TagHoverTipElementWidth }} />
                </Tooltip>
              )}
            </>
          )}
        </div>
        <Tooltip title={description}>
          <div className={cx(['brief', 'gap', 'height'])}>{description}</div>
        </Tooltip>
        <Flex justifyBetween alignCenter>
          <div className={cx('brief')}>{source ? `来自 ${source}` : ''}</div>
          <IconFont type="icon-qianwang" className={cx('goto')} />
        </Flex>
      </div>
    </div>
  );
};

export default TemplateItem;

import React, { useEffect, useState } from 'react';
import find from 'lodash/find';
import { Flex, Row, Col, Image, Tooltip, Drawer, Card, Button, message, Space, Spin } from '@fuxi/eevee-ui';
import { useLazyQuery } from '@apollo/client';
import { CopyOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Empty } from 'antd';
import { useAppDispatch, useAppSelector } from '@/hooks';
import Tag from '@/pages/templateCenter/Tag';
import CreateProjectModal from '@/pages/templateCenter/CreateProjectModal';
import { updateTemplateDetail } from '@/store/template';
import { GET_PROJECT_README } from '@/service/schema/project/project-queries';
import ProjectMarkDown from '@/components/Markdown/Markdown';
import emptyPng from '@/assets/image/empty2.png';
import placeholderImg from '@/assets/image/template-detail.png';
import { nullImage } from '@/pages/project/const';
import { useIsDataManager } from '@/pages/project/ProjectDetailMini/utils/useIsDataManager';
import cx from './index.module.less';

const TemplateDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const { id, visible } = useAppSelector(state => state.template.detail);
  const list = useAppSelector(state => state.template.templates);
  const userInfo = useAppSelector(state => state.user);
  const data = find(list, { id });

  const [fetchReadme, { data: readmeData, loading: readmeLoading }] = useLazyQuery<{
    templateReadme: string;
  }>(GET_PROJECT_README);

  useEffect(() => {
    if (visible && id) {
      fetchReadme({ variables: { templateId: id } });
    }
  }, [id, visible]);

  return (
    <>
      <Drawer
        title="模板详情"
        placement="bottom"
        visible={visible}
        height={document.body.clientHeight - 104}
        className={cx('drawer')}
        onClose={() => dispatch(updateTemplateDetail({ visible: false }))}>
        <div className={cx('container')}>
          <Row gutter={[24, 0]}>
            <Col span={7}>
              <div className={cx('information')}>
                <Image
                  width={'100%'}
                  height={166}
                  placeholder={true}
                  alt={'模板图片'}
                  className={cx('image')}
                  src={(data?.iconUrl !== nullImage && data?.iconUrl) || placeholderImg}
                />
                <div className={cx('title')}>{data?.name}</div>
                <Flex flexFlow={'wrap'}>
                  {data?.tags.map(item => (
                    <Tag {...item} key={item.tagId} />
                  ))}
                </Flex>
                <Tooltip title={data?.description}>
                  <div className={cx('description')}>{data?.description}</div>
                </Tooltip>
                {typeof data?.useNum === 'number' && data?.useNum > 0 && (
                  <Flex justifyCenter alignCenter className={cx('used')}>
                    已被使用<span className={cx('used-num')}>{data?.useNum}</span>次
                  </Flex>
                )}
                <Button
                  disabled={!useIsDataManager(userInfo)}
                  type={'primary'}
                  block
                  onClick={() => setModalVisible(true)}>
                  {useIsDataManager(userInfo) ? '使用模板' : '当前环境仅管理员可以新建项目'}
                </Button>
                <Flex justifyBetween alignCenter className={cx(['line', 'line-top'])}>
                  <div>发布时间：</div>
                  <div>{data?.versionInfo.updateTime}</div>
                </Flex>
                <Flex justifyBetween alignCenter className={cx('line')}>
                  <div>版本：</div>
                  <div>{data?.versionInfo.version}</div>
                </Flex>
                <Flex justifyBetween alignCenter className={cx('line')}>
                  <div>来源：</div>
                  <div>{data?.source}</div>
                </Flex>
                {data?.contactInfo && (
                  <Flex justifyBetween alignCenter className={cx('line')}>
                    <div>联系方式：</div>
                    <div>
                      {data?.contactInfo}
                      <CopyToClipboard text={data?.contactInfo || ''} onCopy={() => message.success('复制成功！')}>
                        <Space className={cx('copy')}>
                          <CopyOutlined className="copy-icon" />
                          {/* <span style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>复制</span> */}
                        </Space>
                      </CopyToClipboard>
                    </div>
                  </Flex>
                )}
                <Flex flexDirection={'column'} className={cx('line')}>
                  <div>版本说明：</div>
                  <Tooltip title={data?.versionInfo.description}>
                    <div className={cx('version')}>{data?.versionInfo.description}</div>
                  </Tooltip>
                </Flex>
              </div>
            </Col>
            <Col span={17}>
              <Card
                title={'模板描述'}
                bordered={false}
                rootClassName={cx('card')}
                className={cx('description')}
                bodyStyle={{ flex: 1, marginTop: 2 }}>
                {readmeData && readmeData.templateReadme && <ProjectMarkDown text={readmeData.templateReadme} />}
                {!readmeLoading && readmeData && !readmeData.templateReadme && (
                  <Empty
                    image={emptyPng}
                    imageStyle={{ marginTop: 150, height: 143 }}
                    description={<p className={cx('emptyText')}>当前模板没有详情描述</p>}
                  />
                )}
                {readmeLoading && (
                  <Flex alignCenter justifyCenter className={cx('loading')}>
                    <Spin size={'large'} tip={'加载中'} />
                  </Flex>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </Drawer>
      <CreateProjectModal visible={modalVisible} onClose={() => setModalVisible(false)} />
    </>
  );
};

export default TemplateDetail;

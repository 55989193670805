import bindClass from 'classnames/bind';
import { Switch, Form, Row, Typography, Col, Layout, Radio, Input, Upload, Button, message } from '@fuxi/eevee-ui';
import { PlusOutlined } from '@ant-design/icons';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useRequest } from 'ahooks';
import { useSearchParams } from 'react-router-dom';
import type { UploadChangeParam, UploadProps, UploadFile } from 'antd/es/upload';
import { useParams } from 'react-router';
import { v4 as uuid } from 'uuid';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  selectFormDataForBaseOfTrain,
  selectIsEnableConfigOfTrain,
  setFormDataForBaseOfTrain,
  setIsEnableConfigOfTrain,
  selectFormDataForConfigOfTrain,
  selectCurrentVersionStatus,
  setFieldsForConfigOfTrain,
} from '@/store/aiTraining';
import { useAppSelector } from '@/hooks/useAppSelector';
import service from '@ai-training/service';
import { ruleMap } from '@ai-training/utils/validator';
import { TrainFormDataConfig, FormValues, TrainFormDataOrigin } from '@ai-training/types/ability';
import {
  AISYSTEM_TENSORBOARD_PATH,
  CurrentVersionStatus,
  DEFAULT_PROJECT_STORAGE,
  FieldNamesForTrain,
  GPU_LIST,
  VersionStatus,
  IDL_CONFIG_ENV_VAS,
  NoneCustomConfigParameter,
} from '@ai-training/constants/ability';
import { normFile } from '@ai-training/utils/util';

import CodeConfigOfForm from '../CodeConfigOfForm';
import RuntimeConfigOfForm from '../RuntimeConfigOfForm';
import { convertOriginFormDataToValues, isFormDataEmpty } from '../NewAIBodyVersion';
import DataSetOfForm from '../DataSetOfForm';
import styles from './index.module.less';
import ability from '@ai-training/service/ability';

const { Text } = Typography;
const cx = bindClass.bind(styles);
const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

const { TextArea } = Input;

const FormItemLayoutForOtherConfig = {
  labelCol: { span: 3 }, // Adjust offset as necessary to align to the right
  wrapperCol: { span: 13 },
};

type ConfigOfTrainProps = {
  children: React.ReactNode;
};

export const isCustomConfigFileUpload = (formDataForBaseOfTrain: TrainFormDataConfig) => {
  return (
    formDataForBaseOfTrain[FieldNamesForTrain.ParameterBuketId] && //parameterBucketId不为空
    !!formDataForBaseOfTrain?.[FieldNamesForTrain.ParameterName]?.[0] && //parameterName不为空
    !NoneCustomConfigParameter.includes(formDataForBaseOfTrain?.[FieldNamesForTrain.ParameterName]?.[0]!) && //自定义配置文件已上传
    !!formDataForBaseOfTrain?.[FieldNamesForTrain.ParameterVersion]?.[0] //parameterVersion不为空
  );
};

export const getFieldsForConfigOfTrain = async (formDataForBaseOfTrain, formDataForConfigOfTrain, urlParams) => {
  return new Promise<FormValues>((resolve, reject) => {
    if (isFormDataEmpty(formDataForConfigOfTrain)) {
      resolve({});
    }
    // format the data to display
    const fields = convertOriginFormDataToValues(formDataForConfigOfTrain);
    const trainDataSetName = formDataForBaseOfTrain[FieldNamesForTrain.TrainDatasetName];
    if (trainDataSetName) {
      fields[FieldNamesForTrain.TrainDatasetName] = trainDataSetName;
      fields[FieldNamesForTrain.IsMountDataset] = true;
    } else {
      // 这里必须要将 TrainDatasetName 设置为 null，否则会导致 dataset 没有被清空
      fields[FieldNamesForTrain.TrainDatasetName] = null;
      fields[FieldNamesForTrain.IsMountDataset] = false;
    }

    if (isCustomConfigFileUpload(formDataForBaseOfTrain)) {
      service.ability
        .getParameterDetail({
          projectId: urlParams?.projectId ?? '',
          parameterName: formDataForBaseOfTrain[FieldNamesForTrain.ParameterName][0],
          parameterVersion: formDataForBaseOfTrain[FieldNamesForTrain.ParameterVersion][0],
        })
        .then(res => {
          if (res.status === 200) {
            const { data: { file_name, parameter_bucket_id } = {} } = res;
            fields[FieldNamesForTrain.CustomConfigFile] = [
              {
                name: file_name,
                uid: parameter_bucket_id,
                percent: 100,
                status: 'success',
              },
            ];
          }
        })
        .catch(err => {
          console.error('error when getParameterDetail, ', err);
        })
        .finally(() => {
          resolve(fields);
        });
    } else {
      resolve(fields);
    }
  });
};

const ConfigOfTrain = forwardRef((props: ConfigOfTrainProps, ref) => {
  const dispatch = useAppDispatch();
  const urlParams = useParams();
  const [isInputTensorboardPath, setIsInputTensorboardPath] = useState<boolean>(true); // 是否输入tensorboard路径
  const isEnableConfigOfTrain = useAppSelector(selectIsEnableConfigOfTrain);
  const formDataForBaseOfTrain = useAppSelector(selectFormDataForBaseOfTrain);
  const currentVersinStatus = useAppSelector(selectCurrentVersionStatus);
  const { data: uuidData } = useRequest(service.dataSet.uuidInit);
  const formDataForConfigOfTrain = useAppSelector(selectFormDataForConfigOfTrain);
  const codeConfigRef = useRef<any>({});
  const datasetConfigRef = useRef<any>({});
  const runtimeConfigRef = useRef<any>({});
  const [searchParams] = useSearchParams();
  const versionStatus = searchParams.get('version_status');

  const { uploadCustomConfig } = ability;

  const [form] = Form.useForm();

  const handleEnableConfigOfTrain = (checked: boolean) => {
    dispatch(setIsEnableConfigOfTrain(checked));
  };

  useImperativeHandle(ref, () => ({
    getFieldValues() {
      return form.getFieldsValue(true);
    },
    submit(cb: (finalFormData: TrainFormDataConfig) => Promise<void>) {
      form
        .validateFields()
        .then(async (values: FormValues) => {
          const { full_train } = values;
          const runtimeConfig = runtimeConfigRef.current?.extractDataFromFormValues?.(values) ?? {};
          const codeConfig = codeConfigRef.current?.extractDataFromFormValues?.(values) ?? {};
          const datasetConfig = datasetConfigRef.current?.extractDataFromFormValues?.(values) ?? {};
          const finalFormData: TrainFormDataConfig = {
            ...runtimeConfig,
            ...codeConfig,
            ...datasetConfig,
          } as TrainFormDataConfig;
          if (values[FieldNamesForTrain.Tensorboard]) {
            const originEnvs = [...(values[FieldNamesForTrain.Env] ?? [])].filter(item => item?.name);
            originEnvs.push({
              name: AISYSTEM_TENSORBOARD_PATH,
              value: values[FieldNamesForTrain.PathForTensorbard],
            });
            finalFormData[FieldNamesForTrain.Env] = originEnvs;
          } else {
            finalFormData[FieldNamesForTrain.Env] = values[FieldNamesForTrain.Env];
          }
          finalFormData[FieldNamesForTrain.Env].push(...IDL_CONFIG_ENV_VAS);

          finalFormData[FieldNamesForTrain.ProjectStorage] = values[FieldNamesForTrain.ProjectStorage];

          finalFormData[FieldNamesForTrain.IsFullTrain] = full_train;

          // 调用回调函数，让其进行下一步
          typeof cb === 'function' && cb(finalFormData);
        })
        .catch(err => {
          message.error('请完成所有必填项!');
        });
    },
  }));

  const onDropFile = () => {
    dispatch(
      setFormDataForBaseOfTrain({
        ...formDataForBaseOfTrain,
        parameter_bucket_uuid: '',
        parameter_name: [] as any,
        parameter_version: [] as any,
      })
    );
  };

  const onUploadSuccess = res => {
    try {
      const { parameter_bucket_uuid = '', parameter_name = '', parameter_version = '' } = res;
      // 取出数据并更新到 BaseOfTrain 里
      dispatch(
        setFormDataForBaseOfTrain({
          ...formDataForBaseOfTrain,
          parameter_bucket_uuid,
          parameter_name,
          parameter_version,
        })
      );
    } catch (error) {
      console.error(`error in onCustomConfigChange is `, error);
    }
  };
  const propsForCustomConfigUpload: UploadProps = {
    withCredentials: true,
  };

  const handleTensorBoardChange = (checked: boolean) => {
    setIsInputTensorboardPath(checked);
  };

  const setformDataFromOriginData = async () => {
    const fields = await getFieldsForConfigOfTrain(formDataForBaseOfTrain, formDataForConfigOfTrain, urlParams);
    fields[FieldNamesForTrain.Args] = (fields[FieldNamesForTrain.Args] ?? [])
      .filter(item => item?.name)
      .map(item => item?.name)
      .join('');
    if (Object.keys(fields).length === 0) {
      // 这里需要先判断下 URL 里的 version_status 是否为 update，如果是的话，需要将 isEnableConfigOfTrain 设置为 false
      // 因为此时代表用户之前没有配置过，所以不需要展示
      if (versionStatus === VersionStatus.Update) {
        dispatch(setIsEnableConfigOfTrain(false));
      }
      return;
    }

    if (fields[FieldNamesForTrain.Tensorboard]) {
      setIsInputTensorboardPath(true);
    } else {
      setIsInputTensorboardPath(false);
      fields[FieldNamesForTrain.Tensorboard] = false;
    }

    form.setFieldsValue(fields);
  };

  useEffect(() => {
    if (currentVersinStatus === CurrentVersionStatus.Update) {
      setformDataFromOriginData();
    }
  }, [currentVersinStatus]);

  useEffect(() => {
    // 在组件卸载的时候，将表单数据保存到 store 中
    return () => {
      const fields = form.getFieldsValue(true);
      // 处理一下上传了的自定义配置文件
      delete fields[FieldNamesForTrain.CustomConfigFile];
      dispatch(setFieldsForConfigOfTrain(fields));
    };
  }, []);

  const handleUpload = async e => {
    const formData = new FormData();

    formData.append('file', e.file);
    formData.append('capability_code', 'test');
    // 生成唯一uuid

    formData.append('id', uuid());
    formData.append('type', 'custom-config');

    try {
      const res = await uploadCustomConfig(formData as any);

      onUploadSuccess(res?.data);

      form.setFieldValue(FieldNamesForTrain.CustomConfigFile, [
        {
          name: e.file.name,
          uid: e.file.uid,
          percent: 100,
          status: 'success',
        },
      ]);

      // propsForCustomConfigUpload.onChange?.(e);
    } catch (e) {
      console.error('error when upload custom config file)', e);
    }
  };

  return (
    <Layout className={styles.container}>
      <Row className={styles.row}>
        <Text className={styles['train-config-text']}>训练配置</Text>
        <Switch checked={isEnableConfigOfTrain} onChange={handleEnableConfigOfTrain} />
        <Text className={styles['train-config-desc']}>强化能力和监督能力请进行训练配置，若无配置需要请关闭</Text>
      </Row>
      {isEnableConfigOfTrain ? (
        <>
          <Form
            form={form}
            name="validate_other"
            className={styles['form-submit-resource']}
            labelAlign="right"
            scrollToFirstError
            colon
            initialValues={{
              [FieldNamesForTrain.Code]: false,
              [FieldNamesForTrain.IsFullTrain]: true,
              [FieldNamesForTrain.Resource]: {
                cpu: 1,
                cpuUnit: '核',
                memory: 1,
                memoryUnit: 'GB',
                gpu: 0,
              },
              [FieldNamesForTrain.Tensorboard]: true,
              [FieldNamesForTrain.IsMountDataset]: true,
              [FieldNamesForTrain.GPU]: GPU_LIST[0].value,
              [FieldNamesForTrain.PathForTensorbard]: '/app/asset/tensorboard',
              [FieldNamesForTrain.Ports]: [{ port: '', protocol: 'tcp', external: true, public: false }],
              [FieldNamesForTrain.Env]: [{ name: '', value: '' }],
              [FieldNamesForTrain.Args]: '',
            }}
            {...formItemLayout}>
            <CodeConfigOfForm ref={codeConfigRef} />

            <RuntimeConfigOfForm isTrainConfig={true} ref={runtimeConfigRef} />

            <div className={styles['part-group-container']}>
              <Row className={styles['train-runtime']}>
                <Col span={2}>
                  <Text style={{ display: 'block' }}>其他配置</Text>
                </Col>
              </Row>

              <Form.Item
                label="训练数据"
                name={FieldNamesForTrain.IsFullTrain}
                rules={[{ required: true, message: '训练数据不能为空' }]}
                {...FormItemLayoutForOtherConfig}>
                <Radio.Group style={{ backgroundColor: 'transparent' }}>
                  <Radio value={true}>全量</Radio>
                  <Radio value={false}>增量</Radio>
                </Radio.Group>
              </Form.Item>

              <DataSetOfForm ref={datasetConfigRef} datasetName={FieldNamesForTrain.TrainDatasetName} />

              <Form.Item
                label="TensorBoard"
                name={FieldNamesForTrain.Tensorboard}
                rules={[{ required: true }]}
                valuePropName="checked"
                {...FormItemLayoutForOtherConfig}>
                <Switch onChange={handleTensorBoardChange} />
              </Form.Item>

              {isInputTensorboardPath && (
                <Form.Item label="存储路径" {...FormItemLayoutForOtherConfig}>
                  <div>
                    <Form.Item
                      className={cx('storage-path')}
                      name={FieldNamesForTrain.PathForTensorbard}
                      rules={[{ required: true }, ruleMap.isValidMountPath]}>
                      <Input placeholder="/app/asset/tensorboard" type="text" className={styles['custom-input']} />
                    </Form.Item>
                    <Row>
                      <Text className={styles['tip-text-of-tensorboard']}>说明：TensorBoard Checkpoint 保存的目录</Text>
                    </Row>
                  </div>
                </Form.Item>
              )}

              <Form.Item
                label="自定义配置文件:"
                getValueFromEvent={normFile}
                className={cx('upload-custom-config')}
                {...FormItemLayoutForOtherConfig}>
                <div>
                  <Form.Item
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    name={FieldNamesForTrain.CustomConfigFile}
                    className={cx('upload-custom-item')}>
                    <Upload
                      multiple={false}
                      maxCount={1}
                      onRemove={onDropFile}
                      customRequest={handleUpload}
                      listType="text"
                      {...propsForCustomConfigUpload}>
                      <Button className={styles['upload-btn']} icon={<PlusOutlined />} type="dashed">
                        <Text>点击上传</Text>
                      </Button>
                    </Upload>
                  </Form.Item>
                  <Row>
                    <Text className={styles['tip-of-custome-config']}>
                      说明：挂载后，启动命令需要从 {'$'}
                      {'{AISYSTEM_PARAMETER_PATH}'}/custom-config 目录下读取配置文件
                    </Text>
                  </Row>
                </div>
              </Form.Item>

              <Form.Item
                label="数据卷路径"
                name={FieldNamesForTrain.ProjectStorage}
                rules={[ruleMap.isValidMountPath]}
                {...FormItemLayoutForOtherConfig}>
                <TextArea rows={4} placeholder="请以/开头" className={styles['custom-input']} />
              </Form.Item>
            </div>
          </Form>
          {props.children}
        </>
      ) : (
        <div className={styles['empty-body']}>{props.children}</div>
      )}
    </Layout>
  );
});

export default ConfigOfTrain;

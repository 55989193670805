/* eslint-disable max-depth */
/* eslint-disable react-func/max-lines-per-function */
import { EChartsOption } from 'echarts';
import { ProjectSummary } from '../miniReview/utils';

const keys = [
  { key: 'experience', value: '核心体验' },
  { key: 'innovation', value: '创新性' },
  { key: 'artisticExpression', value: '艺术表现力' },
  { key: 'effect', value: '技术实现效果' },
  { key: 'difficulty', value: '上手难易度' },
  { key: 'prospect', value: '商业化前景' },
  { key: 'ai', value: 'AI工具使用及效果' },
  { key: 'score', value: '整体评分' },
];

enum ScoreLevel {
  Low = 4,
  Mid = 8,
}

enum ColorLevel {
  Low = '#f2684f',
  Mid = '#386bf3',
  High = '#954af8',
}

export const setOptionsBySummary = (summary: ProjectSummary) => {
  const options: EChartsOption[] = [];
  const judges = summary.judgeArray;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i].key;
    const option: EChartsOption = {
      name: keys[i].value,
      dataZoom: {
        type: 'slider',
      },
      xAxis: { data: judges },
      yAxis: { min: 0, max: 10 },
      average: summary[key + 'Average'],
      tooltip: {},
    };

    const series: any = {};
    series.data = [];
    series.type = 'bar';
    series.barWidth = 28;
    series.label = {
      show: true,
      position: 'top',
    };
    for (let j = 0; j < summary[key + 'Array'].length; j++) {
      const dataItem: any = {};
      dataItem.value = summary[key + 'Array'][j];
      if (dataItem.value >= ScoreLevel.Mid) {
        dataItem.itemStyle = { color: ColorLevel.High };
      } else if (dataItem.value >= ScoreLevel.Low && dataItem.value < ScoreLevel.Mid) {
        dataItem.itemStyle = { color: ColorLevel.Mid };
      } else {
        dataItem.itemStyle = { color: ColorLevel.High };
      }
      series.data.push(dataItem);
    }
    option.series = [];
    option.series.push(series);
    options.push(option);
  }
  return options;
};

export const initPieOption = () => {
  const option = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    series: [
      {
        name: '是否推荐上线',
        type: 'pie',
        radius: '65%',
        center: ['50%', '50%'],
        data: [
          { value: 0, name: '推荐', itemStyle: { color: '#f2684f' } },
          { value: 0, name: '不推荐', itemStyle: { color: '#ffc66e' } },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
  return option;
};

export const setPieOptionBySummary = (summary: ProjectSummary) => {
  const pieOption = initPieOption();
  pieOption.series[0].data[0].value = summary.recommendTotal;
  pieOption.series[0].data[1].value = summary.unRecommendTotal;
  return pieOption;
};

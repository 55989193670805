import { useEffect, useState } from 'react';
import { Button, Icon, Select } from '@fuxi/eevee-ui';

import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  VirtualMachine,
  VirtualMachineGroup,
  getVirtualMachineGroupThunk,
  getVirtualMachineImagesThunk,
  getVirtualMachineResrouceThunk,
} from '@/store/virtualTerminal';
import ProjectDetailContainer from '../../ProjectDetail/ProjectDetailContainer';

import { VMAddModal, VMBatchOperationModal } from './VMCtrlModals';
import VirtualTerminalTable from './VirtualTerminalTable';

import cx from './VirtualTerminal.module.less';

type VirtualTerminalProps = {};

const backOperationOptions = [{ label: '批量修改终端类型', value: 'type' }];

const VirtualTerminal: React.FC<VirtualTerminalProps> = () => {
  const dispatch = useAppDispatch();
  const { imagesData } = ProjectDetailContainer.useContainer();
  const projectId = useAppSelector(state => state.project.currentProject.id);

  // 每5分钟轮询一次，查询镜像和虚拟机
  useEffect(() => {
    if (!projectId) return;
    const gap = 5 * 60 * 1000;
    const timer = setInterval(() => {
      dispatch(getVirtualMachineImagesThunk());
      dispatch(getVirtualMachineGroupThunk(1, 10000));
      dispatch(getVirtualMachineResrouceThunk());
    }, gap);
    return () => clearInterval(timer);
  }, [projectId]);

  useEffect(() => {
    if (!projectId) return;
    dispatch(getVirtualMachineImagesThunk());
    dispatch(getVirtualMachineResrouceThunk());
  }, [projectId]);

  const [selectedVirtualMachineGroup, setSelectedVirtualMachineGroup] = useState<
    (VirtualMachineGroup & VirtualMachine)[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<{ label: string; value: string }>({ label: '', value: '' });

  return (
    <div className={cx('virtual-terminal')}>
      <div className={cx('title')}>虚拟终端</div>
      <div className={cx('virtual-terminal-header')}>
        <VMAddModal>
          <Button type="primary" icon={<Icon name="添加放大" size={18} />} className={cx('add-button')}>
            添加虚拟终端
          </Button>
        </VMAddModal>
        <Select
          value={'批量操作'}
          options={backOperationOptions}
          defaultActiveFirstOption={false}
          className={cx('ctrl-type-select')}
          disabled={selectedVirtualMachineGroup?.length === 0}
          onSelect={(_value, option) => setSelectedOption(option as { label: string; value: string })}
        />
        <VMBatchOperationModal initialValue={selectedVirtualMachineGroup} option={selectedOption} />
        <span className={cx('select-count')}>{`已选${selectedVirtualMachineGroup?.length}项`}</span>
      </div>
      <div className={cx('virtual-terminal-body')}>
        <VirtualTerminalTable
          existedImages={imagesData?.images}
          setSelectedVirtualMachineGroup={setSelectedVirtualMachineGroup}
        />
      </div>
    </div>
  );
};

export default VirtualTerminal;

import { useEffect, useState } from 'react';
import { Form, Select, Radio, Row, Col, Flex, Button, Space, message } from '@fuxi/eevee-ui';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/hooks';
import service from '@/service';
import { getFileTypeOptions, getUploadTips } from '../util';
import { FileType, annotationTypeMap } from '../const';
import { UploadFormItem } from '../../components';
import { useUploadUuid } from '../useUploadUuid';
import cx from './index.module.less';

export const DatasetImport = () => {
  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);

  const { data: uploadUUidRes, refetch: getUUid } = useUploadUuid(aiProjectId);

  const { datasetVid } = useParams();
  const history = useNavigate();
  const [searchParams] = useSearchParams();
  const annotateType = searchParams.get('annotateType')!;
  const datasetType = searchParams.get('datasetType')!;

  const fileType = Form.useWatch('file_type', form);
  const isAnnotated = Form.useWatch('is_annotated', form);

  useEffect(() => {
    if ([FileType.Image, FileType.Zip].includes(fileType)) {
      getUUid();
    }
  }, [fileType]);

  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const handleSave = async () => {
    try {
      setSubmitLoading(true);
      const values = await form.validateFields();
      if (values?.filenames?.find(item => item.status !== 'done')) {
        message.warning('有文件在上传中，请稍后');
        setSubmitLoading(false);
        return;
      }

      await service.ml.importData(datasetVid!, {
        classification: datasetType,
        annotation_type: annotateType,
        is_annotated: values.is_annotated,
        file_type: values.file_type,
        upload_uuid: uploadUUidRes?.data?.upload_uuid,
      });
      message.success('操作成功');
      history(-1);
    } catch (e) {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ is_annotated: false });
  }, [form]);

  const handleValueChange = () => {};
  const uploadTips = getUploadTips(fileType);

  return (
    <div className={cx('import-dataset')}>
      <Form form={form} {...formLayout} labelCol={{ span: 6 }} onValuesChange={handleValueChange}>
        <Flex justifyCenter>
          <Row>
            <Col span={12} style={{ maxWidth: 600 }}>
              <Form.Item label="数据集类型">
                <span>{datasetType}</span>
              </Form.Item>

              <Form.Item label="标注类型">
                <span>{annotationTypeMap[annotateType]}</span>
              </Form.Item>

              <Form.Item label="数据标注状态" name="is_annotated">
                <Radio.Group>
                  <Radio value={false}>无标注</Radio>
                  <Radio value={true}>有标注</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="数据上传格式"
                name="file_type"
                rules={[{ required: true, message: '数据上传格式不能为空' }]}>
                <Select disabled={!datasetType} options={getFileTypeOptions(datasetType, isAnnotated)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <UploadFormItem
                payload={{
                  project_id: String(aiProjectId),
                  upload_uuid: uploadUUidRes?.data?.upload_uuid,
                }}
                fileType={fileType}
              />

              {uploadTips.length > 0 && (
                <Row style={{ width: '100%', marginTop: -20 }}>
                  <Col span={6}></Col>
                  <Col span={18}>
                    <div className={cx('upload-tips')}>
                      {uploadTips.map(item => (
                        <div key={item}>{item}</div>
                      ))}
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Flex>
      </Form>
      <Flex justifyCenter style={{ marginTop: 32 }}>
        <Space>
          <Button type="default" onClick={() => history(-1)}>
            取消
          </Button>
          <Button type="primary" loading={submitLoading} onClick={() => handleSave()}>
            确定
          </Button>
        </Space>
      </Flex>
    </div>
  );
};

/* eslint-disable react-func/max-lines-per-function */
import { noop } from 'lodash';

interface Events {
  onOpen?: (options: any) => void;
  onMessage?: (options: any) => void;
  onClose?: (e: any, errorAfterReconnect: boolean) => void;
  onError?: (options: any) => void;
}

let timer: any;
const isBrowser = typeof window !== undefined;
const isDev = process.env.NODE_ENV === 'development';

const reconnect = (api: string, events: Events, retryNum = 0) => {
  console.log('链接断开，重连中...');

  setTimeout(() => {
    createInstance(api, events, retryNum);
  }, 10000);
};

const createInstance = (api: string, events: Events, retryNum = 0) => {
  if (!isBrowser) return;
  const { onOpen = noop, onError = noop, onClose = noop, onMessage = noop } = events;
  try {
    const o = isDev
      ? new WebSocket(`ws://${window.location.host}${api}`)
      : new WebSocket(`wss://${window.location.host}${api}`);

    o.addEventListener('open', () => {
      console.log('socket实例已创建，每隔30s发送心跳');
      onOpen(o);

      timer = setInterval(() => {
        o.send(JSON.stringify({ type: 'ping' }));
      }, 30000);
    });

    o.addEventListener('error', e => {
      onError(e);
    });

    o.addEventListener('close', e => {
      clearInterval(timer);
      onClose(e, retryNum > 0);
      if (retryNum < 1 && e.code !== 1000) {
        retryNum++;
        reconnect(api, events, retryNum);
      }
    });

    o.addEventListener('message', event => {
      onMessage(event);
    });

    return o;
  } catch (error) {
    if (retryNum < 1) {
      retryNum++;
      reconnect(api, events, retryNum);
    }
  }
};

export const createWebsocketInstance = (api: string, events: Events) => {
  return createInstance(api, events);
};

import React, { useEffect, useRef, useState } from 'react';
import { Drawer, Empty, Spin } from '@fuxi/eevee-ui';
import { useAntDrawer } from '@fuxi/eevee-hooks';
import { isNumber } from 'lodash';
import { LogsWithVirtualScroll } from '@/pages/project/ProjectDetail/LogsWithVirtualScroll';
import { ServiceStatus } from '@/typings/common';
import { getPodInfoList } from '@/service/log';
import { PodLabel } from '@/store/realtimeLogs';
import { PodNameSuffix } from '@/constants/global';
import style from './index.module.less';

interface Prop {
  children: React.ReactNode;
  id: string;
  logUrl: string;
  taskId: string;
  nodeName: string;
  status: ServiceStatus;
}

const TerminalServiceDrawer: React.FC<Prop> = props => {
  const { children, taskId, nodeName } = props;
  const [logLoading, setLogLoading] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { showDrawer, commonAntDrawerProps } = useAntDrawer();
  const { open } = commonAntDrawerProps;
  const [podName, setPodName] = useState('');

  useEffect(() => {
    if (open) {
      fetchPodsList();
    }
  }, [open]);

  const handleFetch = (tabKey: string, loading?: boolean) => {
    setLogLoading(loading === true);
  };

  const transformNodeNameToPodName = (nodeName: string) => {
    return `${nodeName}${PodNameSuffix}`;
  };

  const fetchPodsList = async () => {
    setLogLoading(true);
    try {
      const res = await getPodInfoList(taskId);
      const podNameOfNodeName = transformNodeNameToPodName(nodeName);
      const pod = res?.data?.data
        ?.filter(item => item?.label === PodLabel.edge)
        ?.find(pod => pod?.podName === podNameOfNodeName);
      pod?.podName && setPodName(pod.podName);
    } finally {
      setLogLoading(false);
    }
  };

  return (
    <div className={style('wrapper')}>
      <span onClick={showDrawer}>{children}</span>
      <Drawer
        destroyOnClose
        width={970}
        title={'终端日志'}
        {...commonAntDrawerProps}
        closable
        className={style('drawer-container')}
        onClose={commonAntDrawerProps.onClose as any}>
        <div ref={containerRef} className={style('terminal-log-wrapper')}>
          <Spin tip={'正在拉取日志'} spinning={logLoading}>
            {!!podName ? (
              <LogsWithVirtualScroll
                showNodesSelect={false}
                tabKey={'log'}
                height={isNumber(containerRef?.current?.clientHeight!) ? containerRef?.current?.clientHeight! - 20 : 0}
                serverInfo={{
                  taskId,
                  status: ServiceStatus.Running,
                }}
                selectPod={true}
                selectPodList={[podName]}
                toFetch={handleFetch}
              />
            ) : (
              <Empty description={`未找到${nodeName}对应的pod`}></Empty>
            )}
          </Spin>
        </div>
      </Drawer>
    </div>
  );
};

export default TerminalServiceDrawer;

import { Cascader } from '@fuxi/eevee-ui';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import {
  BaseCapability,
  FormDataExtra,
  FormValues,
  OptionsForSelect,
  TrainFormDataConfig,
} from '@ai-training/types/ability';
import { CurrentVersionStatus } from '@ai-training/constants';

interface Option {
  value: string | number;
  label?: React.ReactNode;
  children?: Option[];
}

interface InitialState {
  fieldDataForBaseOfTrain?: TrainFormDataConfig;
  fieldDataForConfigOfTrain?: TrainFormDataConfig;
  fieldDataForEvaluateOfTrain?: TrainFormDataConfig;
  fieldDataForInferenceOfTrain?: TrainFormDataConfig;
  fieldDataForModelServiceOfTrain?: TrainFormDataConfig;
}

export type AiTrainingState = {
  modelBucketId?: string;
  modelList?: Option[];
  ddlInfo?: {
    ddl?: Record<string, string>;
    file_name?: string;
  };
  dockerList: string[];
  currentVersionStatus: CurrentVersionStatus; // represent whether the version is updated or not
  isEnableConfigOfTrain: boolean;
  isEnableEvaluteOfTrain: boolean;
  isEnableInferenceOfTrain: boolean;
  imageUriOptions: OptionsForSelect[];
  formDataForVersionOfTrain: TrainFormDataConfig;
  formDataForConfigOfTrain: TrainFormDataConfig;
  formDataForBaseOfTrain: TrainFormDataConfig;
  formDataForEvaluateOfTrain: TrainFormDataConfig;
  formDataForInferenceOfTrain: TrainFormDataConfig;
  formDataForModelServiceOfTrain: TrainFormDataConfig;
  formDataExtra: FormDataExtra;
  baseCapability: BaseCapability;
  inititalState: InitialState;
  // 下面的字段是存在表单中的字段，用于在“取消”时，校验表单是否被修改
  formFieldsForConfigOfTrain: FormValues;
  formFieldsForBaseOfTrain: FormValues;
  formFieldsForEvaluateOfTrain: FormValues;
  formFieldsForInferenceOfTrain: FormValues;
  formFieldsForModelServiceOfTrain: FormValues;
};

export const INITITAL_STATE: AiTrainingState = {
  modelBucketId: '',
  dockerList: [] as string[],
  modelList: [] as Option[],
  ddlInfo: { ddl: {}, file_name: '' },
  currentVersionStatus: CurrentVersionStatus.Pending,
  isEnableConfigOfTrain: true,
  isEnableEvaluteOfTrain: true,
  isEnableInferenceOfTrain: true,
  imageUriOptions: [] as OptionsForSelect[],
  formDataForVersionOfTrain: {} as TrainFormDataConfig,
  formDataForBaseOfTrain: {} as TrainFormDataConfig,
  formDataForConfigOfTrain: {
    full_train: null,
    train_mode: null,
  } as TrainFormDataConfig,
  formDataForEvaluateOfTrain: {} as TrainFormDataConfig,
  formDataForInferenceOfTrain: {} as TrainFormDataConfig,
  formDataForModelServiceOfTrain: {} as TrainFormDataConfig,
  formDataExtra: {} as FormDataExtra,
  baseCapability: {} as BaseCapability,
  inititalState: {} as InitialState,
  formFieldsForBaseOfTrain: {} as FormValues,
  formFieldsForConfigOfTrain: {} as FormValues,
  formFieldsForEvaluateOfTrain: {} as FormValues,
  formFieldsForInferenceOfTrain: {} as FormValues,
  formFieldsForModelServiceOfTrain: {} as FormValues,
} as const;

const aiTraining = createSlice({
  name: 'aiTraining',
  initialState: INITITAL_STATE,
  reducers: {
    setModelBucketId(state, { payload }: PayloadAction<string>) {
      state.modelBucketId = payload;
    },
    setModelList(state, { payload }: PayloadAction<Option[]>) {
      state.modelList = payload;
    },
    setDdlInfo(
      state,
      {
        payload,
      }: PayloadAction<{
        ddl?: Record<string, string>;
        file_name?: string;
      }>
    ) {
      state.ddlInfo = payload;
    },
    setDockerList(state, { payload }: PayloadAction<string[]>) {
      state.dockerList = payload;
    },
    setCurrentVersionStatus(state, { payload }: PayloadAction<CurrentVersionStatus>) {
      state.currentVersionStatus = payload;
    },
    setIsEnableConfigOfTrain(state, { payload }: PayloadAction<boolean>) {
      state.isEnableConfigOfTrain = payload;
    },
    setIsEnableEvaluateOfTrain(state, { payload }: PayloadAction<boolean>) {
      state.isEnableEvaluteOfTrain = payload;
    },
    setIsEnableInferenceOfTrain(state, { payload }: PayloadAction<boolean>) {
      state.isEnableInferenceOfTrain = payload;
    },
    setFormDataForVersionOfTrain(state, { payload }: PayloadAction<TrainFormDataConfig>) {
      state.formDataForVersionOfTrain = payload;
    },
    setFormDataForBaseOfTrain(state, { payload }: PayloadAction<TrainFormDataConfig>) {
      state.formDataForBaseOfTrain = payload;
    },
    setFormDataForConfigOfTrain(state, { payload }: PayloadAction<TrainFormDataConfig>) {
      state.formDataForConfigOfTrain = payload;
    },
    setFormDataForEvaluateOfTrain(state, { payload }: PayloadAction<TrainFormDataConfig>) {
      state.formDataForEvaluateOfTrain = payload;
    },
    setFormDataForInferenceOfTrain(state, { payload }: PayloadAction<TrainFormDataConfig>) {
      state.formDataForInferenceOfTrain = payload;
    },
    setFormDataForModelServiceOfTrain(state, { payload }: PayloadAction<TrainFormDataConfig>) {
      state.formDataForModelServiceOfTrain = payload;
    },
    setImageUriOptions(state, { payload }: PayloadAction<OptionsForSelect[]>) {
      state.imageUriOptions = payload;
    },
    setFormDataExtra(state, { payload }: PayloadAction<FormDataExtra>) {
      state.formDataExtra = payload;
    },
    setBaseCapability(state, { payload }: PayloadAction<BaseCapability>) {
      state.baseCapability = payload;
    },
    resetStateToInitial(state, { payload }: PayloadAction<AiTrainingState>) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key];
      });
    },
    clearFormData(state) {
      state.formDataForBaseOfTrain = {};
      state.formDataForConfigOfTrain = {};
      state.formDataForEvaluateOfTrain = {};
      state.formDataForInferenceOfTrain = {};
      state.formDataForVersionOfTrain = {};
      state.formDataExtra = {};
      state.inititalState = {} as InitialState;
      state.formFieldsForBaseOfTrain = {} as FormValues;
      state.formFieldsForConfigOfTrain = {} as FormValues;
      state.formFieldsForEvaluateOfTrain = {} as FormValues;
      state.formFieldsForInferenceOfTrain = {} as FormValues;
    },
    setInitialState(state, { payload }: PayloadAction<InitialState>) {
      state.inititalState = payload;
    },
    setFieldsForBaseOfTrain(state, { payload }: PayloadAction<FormValues>) {
      state.formFieldsForBaseOfTrain = payload;
    },
    setFieldsForConfigOfTrain(state, { payload }: PayloadAction<FormValues>) {
      state.formFieldsForConfigOfTrain = payload;
    },
    setFieldsForEvaluateOfTrain(state, { payload }: PayloadAction<FormValues>) {
      state.formFieldsForEvaluateOfTrain = payload;
    },
    setFieldsForInferenceOfTrain(state, { payload }: PayloadAction<FormValues>) {
      state.formFieldsForInferenceOfTrain = payload;
    },
    setFieldsForModelServiceOfTrain(state, { payload }: PayloadAction<FormValues>) {
      state.formFieldsForModelServiceOfTrain = payload;
    },
  },
});

export const {
  setModelBucketId,
  setDockerList,
  setModelList,
  setDdlInfo,
  setCurrentVersionStatus,
  setIsEnableConfigOfTrain,
  setIsEnableEvaluateOfTrain,
  setIsEnableInferenceOfTrain,
  setFormDataForVersionOfTrain,
  setFormDataForConfigOfTrain,
  setFormDataForBaseOfTrain,
  setFormDataForEvaluateOfTrain,
  setFormDataForInferenceOfTrain,
  setFormDataForModelServiceOfTrain,
  setImageUriOptions,
  setFormDataExtra,
  setBaseCapability,
  resetStateToInitial,
  setInitialState,
  setFieldsForBaseOfTrain,
  setFieldsForConfigOfTrain,
  setFieldsForEvaluateOfTrain,
  setFieldsForInferenceOfTrain,
  setFieldsForModelServiceOfTrain,
} = aiTraining.actions;

export const selectModelBucketId = (state: RootState) => state.aiTraining.modelBucketId;
export const selectModelList = (state: RootState) => state.aiTraining.modelList;
export const selectDdlInfo = (state: RootState) => state.aiTraining.ddlInfo;
export const selectDockerList = (state: RootState) => state.aiTraining.dockerList;
export const selectCurrentVersionStatus = (state: RootState) => state.aiTraining.currentVersionStatus;
export const selectIsEnableConfigOfTrain = (state: RootState) => state.aiTraining.isEnableConfigOfTrain;
export const selectIsEnableEvaluteOfTrain = (state: RootState) => state.aiTraining.isEnableEvaluteOfTrain;
export const selectIsEnableInferenceOfTrain = (state: RootState) => state.aiTraining.isEnableInferenceOfTrain;
export const selectFormDataForVersionOfTrain = (state: RootState) => state.aiTraining.formDataForVersionOfTrain;
export const selectFormDataForBaseOfTrain = (state: RootState) => state.aiTraining.formDataForBaseOfTrain;
export const selectFormDataForConfigOfTrain = (state: RootState) => state.aiTraining.formDataForConfigOfTrain;
export const selectFormDataForEvaluateOfTrain = (state: RootState) => state.aiTraining.formDataForEvaluateOfTrain;
export const selectFormDataForInferenceOfTrain = (state: RootState) => state.aiTraining.formDataForInferenceOfTrain;
export const selectFormDataForModelServiceOfTrain = (state: RootState) =>
  state.aiTraining.formDataForModelServiceOfTrain;
export const selectImageUriOptions = (state: RootState) => state.aiTraining.imageUriOptions;
export const selectFormDataExtra = (state: RootState) => state.aiTraining.formDataExtra;
export const selectBaseCapability = (state: RootState) => state.aiTraining.baseCapability;
export const selectInitialState = (state: RootState) => state.aiTraining.inititalState;
export const selectFieldsForBaseOfTrain = (state: RootState) => state.aiTraining.formFieldsForBaseOfTrain;
export const selectFieldsForConfigOfTrain = (state: RootState) => state.aiTraining.formFieldsForConfigOfTrain;
export const selectFieldsForEvaluateOfTrain = (state: RootState) => state.aiTraining.formFieldsForEvaluateOfTrain;
export const selectFieldsForInferenceOfTrain = (state: RootState) => state.aiTraining.formFieldsForInferenceOfTrain;
export const selectFieldsForModelServiceOfTrain = (state: RootState) =>
  state.aiTraining.formFieldsForModelServiceOfTrain;

export default aiTraining.reducer;

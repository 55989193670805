export enum TaskStatusType {
  CREATE = 'CREATE',
  INIT = 'INIT',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  STOP_FINISH = 'STOP_FINISH',
}

export interface TrainingTaskDetail {
  task_id: string;
  train_id: string;
  capability_version: number;
  capability_name: string;
  capability_id: string;
  capability_version_id: string;
  duration: number;
  started_at: string;
  finished_at: string;
  train_status: string;
  status: string;
}

export interface TrainingDataDetail {
  id: number;
  name: string;
  version: string;
  version_id: number;
  sdk_created: boolean;
  dataset_id: string;
}

import { TaskStatusType } from '@ai-training/types/trainingTask';
import { formatDuration } from '@ai-training/utils/util';
import dayjs from 'dayjs';

// start和end都是时间戳, 返回x小时x分钟
export const getFormattedTimeGap = (startTime: number, endTime: number) => {
  const start = new Date(startTime).getTime();
  const end = new Date(endTime).getTime();
  const gap = end - start;
  const hours = Math.floor(gap / 60 / 60);
  const minutes = Math.floor((gap / 60) % 60);
  if (hours === 0 && minutes !== 0) {
    return `${minutes}分钟`;
  }
  if (minutes === 0) {
    return '1分钟';
  }
  return `${hours}小时${minutes}分钟`;
}

export const getTrainingFormattedTimeGap = (detail: {
  duration: number;
  status: string;
  start_at: string | number;
}) => {
  if (detail?.status === TaskStatusType.STOP_FINISH) {
    return '--'
  }

  if (!!detail?.duration) {
    return formatDuration(detail.duration);
  }

  if (detail?.status === TaskStatusType.RUNNING) {
    return getFormattedTimeGap(dayjs(detail?.start_at).unix(), dayjs(new Date()).unix());
  }

  return '-'
}

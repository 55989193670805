import { createFromIconfontCN } from '@ant-design/icons';
import { IconFontProps } from '@ant-design/icons/lib/components/IconFont';
import { Icon } from '@fuxi/eevee-ui';
import less from 'less';

const IconFont: React.FC<IconFontProps & { style?: React.CSSProperties }> = props => {
  const { style = {}, ...restProps } = props;

  const Icon = createFromIconfontCN({
    extraCommonProps: { style },
    scriptUrl: '//at.alicdn.com/t/c/font_3730943_d50sbpb30gg.js',
  });

  return <Icon {...restProps} />;
};

export const statusIconMap = (type: 'info' | 'warning' | 'error', size?: number) => {
  switch (type) {
    case 'info':
      return <Icon name="warn-日志" style={{ fill: '#36bd1f' }} size={size || 20}></Icon>;
    case 'warning':
      return <Icon name="warn-日志" style={{ fill: '#e58616' }} size={size || 20}></Icon>;
    case 'error':
      return <Icon name="warn-日志" style={{ fill: '#de5550' }} size={size || 20}></Icon>;
  }
};

export default IconFont;

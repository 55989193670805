/* eslint-disable max-lines */
/* eslint-disable react-func/max-lines-per-function */
import bindClass from 'classnames/bind';
import { useRequest } from '@fuxi/eevee-hooks';
import { Divider, Typography, Steps, Layout, Spin, Button, Icon, message, Modal } from '@fuxi/eevee-ui';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import service from '@ai-training/service';
import ConfigOfTrain, { getFieldsForConfigOfTrain } from '@ai-training/pages/ability/components/ConfigOfTrain';
import EvaluteOfTrain, { getFieldsForEvaluateOfTrain } from '@ai-training/pages/ability/components/EvaluteOfTrain';
import { VersionBaseConfig, VersionBaseFieldNames } from '@ai-training/pages/ability/components/VersionBaseConfig';
import InferenceOfTrain, { getFieldsForInferenceOfTrain } from '@ai-training/pages/ability/components/InferenceOfTrain';
import { FormDataExtra, TrainFormDataConfig, TrainFormDataOrigin } from '@ai-training/types/ability';
import {
  ABILITY_VERSION_KEY,
  AISYSTEM_TENSORBOARD_PATH,
  CurrentVersionStatus,
  DEFAULT_VARIABLE_KEY_LIST,
  FieldNamesForTrain,
  VersionStatus,
} from '@ai-training/constants/ability';

import { getEmptyDataFromObj } from '@ai-training/utils/util';

import { isEqual, isEmpty, pick } from 'lodash-es';

import model from '@ai-training/service/model';

import {
  setModelBucketId,
  setModelList,
  setDdlInfo,
  setCurrentVersionStatus,
  selectCurrentVersionStatus,
  selectIsEnableConfigOfTrain,
  selectIsEnableEvaluteOfTrain,
  selectIsEnableInferenceOfTrain,
  setFormDataForVersionOfTrain,
  setFormDataForBaseOfTrain,
  setFormDataForConfigOfTrain,
  setFormDataForEvaluateOfTrain,
  setFormDataForInferenceOfTrain,
  setFormDataForModelServiceOfTrain,
  selectFormDataForVersionOfTrain,
  selectFormDataForConfigOfTrain,
  selectFormDataForEvaluateOfTrain,
  selectFormDataForInferenceOfTrain,
  selectFormDataForBaseOfTrain,
  selectFormDataForModelServiceOfTrain,
  setFormDataExtra,
  selectFormDataExtra,
  setBaseCapability,
  INITITAL_STATE,
  resetStateToInitial,
  setInitialState,
  selectInitialState,
  selectFieldsForBaseOfTrain,
  selectFieldsForConfigOfTrain,
  selectFieldsForEvaluateOfTrain,
  selectFieldsForInferenceOfTrain,
  selectFieldsForModelServiceOfTrain,
  setFieldsForBaseOfTrain,
  setFieldsForConfigOfTrain,
  setFieldsForEvaluateOfTrain,
  setFieldsForInferenceOfTrain,
} from '@/store/aiTraining';
import { setIsInner } from '@/store/user';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import styles from './index.module.less';

const { Text } = Typography;
const { Content } = Layout;

const cx = bindClass.bind(styles);

enum Step {
  ONE = 0,
  TWO = 1,
  Three = 2,
  Four = 3,
}

enum UserRole {
  Inner = 'INNER',
  Outer = 'OUTER',
}

interface Props {}

interface CustomFooterProps {
  children?: React.ReactNode;
  backToPrev: () => void;
  onComplete: () => void;
  isFirstTime: boolean;
  isFirstStep?: boolean;
}

interface FormDataForConfig {
  newFormDataForBaseOfTrain?: TrainFormDataConfig;
  newFormDataForConfigOfTrain?: TrainFormDataConfig;
  newFormDataForEvaluateOfTrain?: TrainFormDataConfig;
  newFormDataForInferenceOfTrain?: TrainFormDataConfig;
  newFormDataForModelServiceOfTrain?: TrainFormDataConfig;
  newFormDataExtra?: FormDataExtra;
  modelInfo?: any;
}

interface TreeSingleData {
  title: string;
  value: string;
}

const scrollToPageTop = () => {
  const scrollableElement = document.getElementById('project-detail-content');
  // 平滑滚动到元素的最上方
  scrollableElement?.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const judgeIfFormHasError = (formRef: any) => {
  const errors = formRef?.current?.getFieldsError?.() ?? [];
  if (errors?.length === 0) {
    return false;
  }
  let isFormHasError = false;
  errors.some(item => {
    if (item?.errors?.length > 0) {
      isFormHasError = true;
      return true;
    }
  });

  return isFormHasError;
};

export const convertOriginFormDataToValues = (formData: TrainFormDataConfig) => {
  const fields: TrainFormDataOrigin = {} as TrainFormDataOrigin;
  fields[FieldNamesForTrain.Code] = false;
  fields[FieldNamesForTrain.IsMountDataset] = false;
  let isContainTensorboard = false;
  Object.keys(formData).forEach((key: string) => {
    try {
      switch (key) {
        case FieldNamesForTrain.Env:
          fields[FieldNamesForTrain.Env] = (formData[key] ?? [{ name: '', value: '' }]).filter(singleEnv => {
            if (DEFAULT_VARIABLE_KEY_LIST.includes(singleEnv.name)) {
              return false;
            } else if (singleEnv.name === AISYSTEM_TENSORBOARD_PATH) {
              fields[FieldNamesForTrain.PathForTensorbard] = singleEnv.value;
              fields[FieldNamesForTrain.Tensorboard] = true;
              isContainTensorboard = true;
              return false;
            } else {
              return true;
            }
          });
          // 如果 env 为空，则默认添加一条空的 env，用于展示
          if (fields[FieldNamesForTrain.Env].length === 0) {
            fields[FieldNamesForTrain.Env].push({
              name: '',
              value: '',
            });
          }

          if (!isContainTensorboard) {
            delete fields[FieldNamesForTrain.PathForTensorbard];
            fields[FieldNamesForTrain.Tensorboard] = false;
          }
          break;
        case FieldNamesForTrain.Ports:
          fields[FieldNamesForTrain.Ports] =
            formData?.[key] && formData?.[key]?.length > 0
              ? formData?.[key]
              : [{ port: '', protocol: 'tcp', external: true, public: false }];
          break;
        case FieldNamesForTrain.Resource:
          const resources = formData[key] ?? {};
          fields[FieldNamesForTrain.Resource] = {
            cpu: resources?.cpu?.max?.value,
            cpuUnit: resources?.cpu?.max?.unit,
            memory: resources?.memory?.max?.value,
            memoryUnit: resources?.memory?.max?.unit,
            gpu: resources?.gpu?.max?.value,
            gpu_type: formData?.[FieldNamesForTrain.GPU],
          };
          if (resources?.gpu?.max?.value) {
            fields[FieldNamesForTrain.GPU] = formData[FieldNamesForTrain.GPU];
          } else {
            fields[FieldNamesForTrain.GPU] = null;
          }
          break;
        case FieldNamesForTrain.CommitId:
        case FieldNamesForTrain.Repo:
        case FieldNamesForTrain.GitBranch:
          if (formData[key]) {
            fields[key] = formData[key];
            fields[FieldNamesForTrain.Code] = true;
          }
          break;
        case FieldNamesForTrain.Args:
          if (Array.isArray(formData[key]) && formData[key].length > 0) {
            fields[key] = formData[key]
              .filter(item => item)
              .map(item => {
                return {
                  name: item,
                };
              });
          } else {
            // 给个默认值
            fields[key] = [{ name: '', value: '' }];
          }
          break;
        case FieldNamesForTrain.TrainDatasetName:
        case FieldNamesForTrain.EvalDatasetName:
          if (formData[key]) {
            fields[key] = formData[key];
            fields[FieldNamesForTrain.IsMountDataset] = true;
          }
          break;
        case FieldNamesForTrain.ImageFile:
          if (formData[key]) {
            fields[key] = formData[key];
            fields[FieldNamesForTrain.DeployMethod] = 'imageDeploy';
          } else {
            fields[FieldNamesForTrain.DeployMethod] = 'modelDeploy';
          }
          break;
        case FieldNamesForTrain.Command:
        case FieldNamesForTrain.ModelName:
        case FieldNamesForTrain.DatasetName:
        case FieldNamesForTrain.ProjectStorage:
          if (formData[key]) {
            fields[key] = formData[key] as keyof TrainFormDataConfig;
          }
          break;
        case FieldNamesForTrain.IsFullTrain:
          if (typeof formData[key] === 'boolean') {
            fields[key] = formData[key];
          }
          break;
        case FieldNamesForTrain.ServerBactching:
          fields[FieldNamesForTrain.ClientMaxBatch] =
            formData?.[FieldNamesForTrain.ServerBactching]?.[FieldNamesForTrain.ClientMaxBatch];
          fields[FieldNamesForTrain.ServerMaxBatch] =
            formData?.[FieldNamesForTrain.ServerBactching]?.[FieldNamesForTrain.ServerMaxBatch];
          fields[FieldNamesForTrain.ServerMaxDelay] =
            formData?.[FieldNamesForTrain.ServerBactching]?.[FieldNamesForTrain.ServerMaxDelay];
          fields[FieldNamesForTrain.ServerBactching] = !!(
            formData?.[FieldNamesForTrain.ServerBactching]?.[FieldNamesForTrain.ServerMaxDelay] &&
            formData?.[FieldNamesForTrain.ServerBactching]?.[FieldNamesForTrain.ServerMaxBatch]
          )
            ? true
            : false;
          break;
        case FieldNamesForTrain.DynamicLoadBalance:
          fields[FieldNamesForTrain.LimitPerInstance] =
            formData?.[FieldNamesForTrain.DynamicLoadBalance]?.[FieldNamesForTrain.LimitPerInstance];
          fields[FieldNamesForTrain.DynamicLoadBalance] = !!formData?.[FieldNamesForTrain.DynamicLoadBalance]?.[
            FieldNamesForTrain.LimitPerInstance
          ]
            ? true
            : false;
          break;
      }
    } catch (error) {
      console.error('error when transform value to fields', error);
    }
  });

  return fields;
};

export const isFormDataEmpty = (formData: TrainFormDataConfig = {}) => {
  if (Object.keys(formData).length === 0) {
    return true;
  }

  let isContainEmptyInfo = true;
  const keysArray = Object.keys(formData);
  for (const key of keysArray) {
    switch (key) {
      case FieldNamesForTrain.Env:
        if (formData[FieldNamesForTrain.Env]?.length > 0) {
          formData[FieldNamesForTrain.Env].some(item => {
            if (!DEFAULT_VARIABLE_KEY_LIST.includes(item.name)) {
              isContainEmptyInfo = false;
              return true;
            }
          });
        }
        break;
      case FieldNamesForTrain.ModelName:
      case FieldNamesForTrain.ModelVersion:
      case FieldNamesForTrain.Resource:
      case FieldNamesForTrain.Ports:
      case FieldNamesForTrain.ImageFile:
        if (!isEmpty(formData[key])) {
          isContainEmptyInfo = false;
        }
        break;
      default:
        break;
    }
  }

  return isContainEmptyInfo;
};

const transformModelListToOptions = data => {
  const result = [] as any[];

  data.forEach(item => {
    const existingItem = result.find(i => i.value === item.name);

    if (existingItem) {
      existingItem.children.push({ label: `v${item.version}`, value: item.version, ...item });
    } else {
      result.push({
        label: item.name,
        value: item.name,
        children: [{ label: `v${item.version}`, value: item.version }],
        ...item,
      });
    }
  });

  return result;
};

const CustomFooter = ({ backToPrev, onComplete, children, isFirstTime, isFirstStep }: CustomFooterProps) => {
  return (
    <div className={styles['footer']}>
      <Divider style={{ marginTop: 12 }} />
      <div className={styles['footer-container']}>
        <Button className={styles['footer-prev-step']} onClick={backToPrev}>
          {isFirstStep ? '取消' : '上一步'}
        </Button>
        {children}
        <Button className={styles['footer-complete']} onClick={onComplete}>
          完成
        </Button>

        {isFirstTime && (
          <>
            <Icon name="消息" cursor={'pointer'} />
            <Text className={styles['tip-user']}>首次填写可跳过训练/评估/推理配置直接保存，请在开始训练前完成配置</Text>
          </>
        )}
      </div>
    </div>
  );
};

interface IGetVersionDetail {
  capabilityId: string | null;
  versionId: string | null;
  isFirstTime?: boolean;
}

const NewAIBodyVersion: React.FC<Props> = () => {
  const [current, setCurrent] = useState<Step>(Step.ONE);
  const urlParams = useParams();
  const dispatch = useAppDispatch();
  const configOfTrainRef = useRef<any>({});
  const versionBaseOfTrainRef = useRef<any>({});
  const evaluteOfTrainRef = useRef<any>({});
  const inferenceOfTrainRef = useRef<any>({});
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false);
  const currentVersionStatus = useAppSelector(selectCurrentVersionStatus);
  const isEnableConfigOfTrain = useAppSelector(selectIsEnableConfigOfTrain);
  const isEnableEvaluteOfTrain = useAppSelector(selectIsEnableEvaluteOfTrain);
  const isEnableInferenceOfTrain = useAppSelector(selectIsEnableInferenceOfTrain);
  const formDataForVersionOfTrain = useAppSelector(selectFormDataForVersionOfTrain);
  const formDataForBaseOfTrain = useAppSelector(selectFormDataForBaseOfTrain);
  const formDataForConfigOfTrain = useAppSelector(selectFormDataForConfigOfTrain);
  const formDataForEvaluateOfTrain = useAppSelector(selectFormDataForEvaluateOfTrain);
  const formDataForInferenceOfTrain = useAppSelector(selectFormDataForInferenceOfTrain);
  const formDataForModelServiceOfTrain = useAppSelector(selectFormDataForModelServiceOfTrain);
  const fieldDataForBaseOfTrain = useAppSelector(selectFieldsForBaseOfTrain);
  const fieldDataForConfigOfTrain = useAppSelector(selectFieldsForConfigOfTrain);
  const fieldDataForEvaluateOfTrain = useAppSelector(selectFieldsForEvaluateOfTrain);
  const fieldDataForInferenceOfTrain = useAppSelector(selectFieldsForInferenceOfTrain);
  const fieldDataForModelServiceOfTrain = useAppSelector(selectFieldsForModelServiceOfTrain);
  const formDataExtra = useAppSelector(selectFormDataExtra);
  const history = useNavigate();
  const location = useLocation();
  const initialAllFormValues = useAppSelector(selectInitialState);
  const versionStatus = searchParams.get('version_status');
  const { generateModelVersion } = model;
  const [modelInfo, setModelInfo] = useState<any>({});

  useEffect(() => {
    getPageData();

    return () => {
      // clear all the data
      dispatch(resetStateToInitial(INITITAL_STATE));
    };
  }, []);

  // 获取模型bucketid;
  const { loading: bucketIdLoading } = useRequest(
    () => service.ability.getModelBucketId({ project_id: urlParams.projectId }),
    {
      onSuccess: data => {
        dispatch(setModelBucketId(data.data?.model_bucket_id));
      },
    }
  );

  // 获取模型库
  const { loading: modelListLoading, data: models } = useRequest(
    () => service.ability.getModels({ project_id: urlParams.projectId }),
    {
      onSuccess: data => {
        const modelOptions = transformModelListToOptions(data.data);
        dispatch(setModelList(modelOptions));
      },
    }
  );

  const getVersionDetailById = async ({ capabilityId, versionId, isFirstTime }: IGetVersionDetail) => {
    setIsLoading(true);
    const { data: { capability_total = {}, extra = {}, base_capability = {} } = {} } =
      (await service.ability
        .getVersionsDetails({
          capability_uuid: capabilityId,
          capability_version_uuid: versionId,
          project_id: urlParams.projectId,
        })
        .catch(() => {})) ?? {};

    const {
      capabilityVersion = {},
      capabilityVersionBase = {},
      capabilityVersionEvaluate = {},
      capabilityVersionService = {},
      capabilityVersionTrain = {},
      capabilityVersionModelService = {},
    } = capability_total;

    // 更新下表单的数据
    dispatch(setFormDataForVersionOfTrain(capabilityVersion ?? {}));
    dispatch(setFormDataForBaseOfTrain(capabilityVersionBase ?? {}));
    dispatch(setFormDataForConfigOfTrain(capabilityVersionTrain ?? {}));
    dispatch(setFormDataForEvaluateOfTrain(capabilityVersionEvaluate ?? {}));
    dispatch(setFormDataForInferenceOfTrain(capabilityVersionService ?? {}));
    dispatch(setFormDataForModelServiceOfTrain(capabilityVersionModelService ?? {}));
    dispatch(setFormDataExtra(extra ?? {}));
    dispatch(setBaseCapability(base_capability));

    if (isFirstTime) {
      // 保存下页面的初始信息，用于退出流程时的判断
      const fieldDataForBaseOfTrain = versionBaseOfTrainRef.current?.getFieldValuesFromOriginData();
      const fieldDataForConfigOfTrain = await getFieldsForConfigOfTrain(
        capabilityVersionBase,
        capabilityVersionTrain,
        urlParams
      );
      const fieldDataForEvaluateOfTrain = getFieldsForEvaluateOfTrain(
        formDataForEvaluateOfTrain,
        formDataForBaseOfTrain
      );
      const fieldDataForInferenceOfTrain = getFieldsForInferenceOfTrain(formDataForInferenceOfTrain);

      // 存储下页面初始化时各个表单的 fields 字段数据，用于判断是否有修改
      dispatch(setFieldsForConfigOfTrain(fieldDataForConfigOfTrain));
      dispatch(setFieldsForEvaluateOfTrain(fieldDataForEvaluateOfTrain));
      dispatch(setFieldsForInferenceOfTrain(fieldDataForInferenceOfTrain));
      dispatch(setFieldsForBaseOfTrain(fieldDataForBaseOfTrain));
      dispatch(
        setInitialState({
          fieldDataForBaseOfTrain,
          fieldDataForConfigOfTrain,
          fieldDataForEvaluateOfTrain,
          fieldDataForInferenceOfTrain,
        })
      );
    }

    if (capabilityVersionBase?.[VersionBaseFieldNames.DdlId] && extra?.dataset?.dataset_type === 'webdataset') {
      await service.ability.getDdlParserData(capabilityVersionBase?.[VersionBaseFieldNames.DdlId]).then(ddlRes => {
        if (ddlRes.status === 200) {
          dispatch(setDdlInfo(ddlRes.data));
        }
      });
    } else {
    }
    setIsLoading(false);
  };

  const getPageData = async () => {
    setIsLoading(true);
    const userInfo = (await service.users.getUserRoles({ projectId: urlParams?.currentProjectId ?? '' }).catch(() => {
      console.error('error when get user info');
    })) ?? { data: '' };
    if (userInfo.data === UserRole.Inner) {
      dispatch(setIsInner(true));
    } else {
      dispatch(setIsInner(false));
    }
    // 获取镜像数据（保留代码，留待后续再做改造）
    // const dockerList = await service.ability.getDockers({ projectId: urlParams?.projectId ?? '' });
    // const { data = [] } = dockerList ?? {};
    // const dockerListMap = data.reduce((acc, cur) => {
    //   const { name, tag } = cur;
    //   acc[name] = acc[name] || [];
    //   acc[name].push({
    //     label: name,
    //     value: tag,
    //   });
    //   return acc;
    // }, {});
    // const dockerListArray: any = [];
    // Object.keys(dockerListMap).forEach(key => {
    //   dockerListArray.push({
    //     value: key,
    //     label: key,
    //     children: dockerListMap[key].map(item => {
    //       return {
    //         value: `${key}:${item.value}`,
    //         label: item.value,
    //       };
    //     }),
    //   });
    // });
    // dispatch(setImageUriOptions(dockerListArray));

    const capabilityId = urlParams?.abilityId ?? '';
    const versionId = searchParams.get('version_id');
    switch (versionStatus) {
      case VersionStatus.Update:
        await getVersionDetailById({ capabilityId, versionId, isFirstTime: true });
        dispatch(setCurrentVersionStatus(CurrentVersionStatus.Update));
        break;
      case VersionStatus.Copy:
        await getVersionDetailById({ capabilityId, versionId, isFirstTime: true });
        dispatch(setCurrentVersionStatus(CurrentVersionStatus.Copy));
        break;
      case VersionStatus.Create:
        dispatch(setCurrentVersionStatus(CurrentVersionStatus.Create));
        break;
      default:
        dispatch(setCurrentVersionStatus(CurrentVersionStatus.Pending));
        break;
    }
    setIsLoading(false);
  };

  const directBackToPrev = () => {
    if (current === Step.ONE) {
      completeAndLeavePage();
    } else {
      setCurrent(current - 1);
    }
  };

  const onStepChange = (nextStep: number) => {
    if (currentVersionStatus !== CurrentVersionStatus.Update) {
      message.warning('请先完成基础信息填写并提交');
      return;
    }
    setCurrent(nextStep);
  };

  const backToPrevWithValidate = async () => {
    switch (current) {
      case Step.ONE:
        // 判断下当前表单页上是否存在错误
        const hasError = judgeIfFormHasError(versionBaseOfTrainRef);
        if (hasError) {
          setIsConfirmModalVisible(true);
          return;
        }
        const editData = {
          fieldDataForBaseOfTrain: versionBaseOfTrainRef.current?.getFieldValues?.(),
          fieldDataForConfigOfTrain,
          fieldDataForEvaluateOfTrain,
          fieldDataForInferenceOfTrain,
        };

        // 判断下当前页面是否有修改
        const isDataEqual = isEqual(editData, initialAllFormValues);
        if (isDataEqual) {
          completeAndLeavePage();
        } else {
          setIsConfirmModalVisible(true);
        }
        break;
      case Step.TWO:
      case Step.Three:
      case Step.Four:
        directBackToPrev();
        break;
      default:
        break;
    }
  };

  const formatAbilityVersionData = (formData: FormDataForConfig) => {
    const {
      newFormDataForBaseOfTrain = {},
      newFormDataForConfigOfTrain = {},
      newFormDataForEvaluateOfTrain = {},
      newFormDataForInferenceOfTrain = {},
      newFormDataForModelServiceOfTrain = {},
      newFormDataExtra = {},
      modelInfo = {},
    } = formData;
    const { projectId = '' } = urlParams ?? {};
    const data = {
      project_id: projectId,
    };

    // 应后端同学的要求，将 model 和 dataset 字段放到 extra 里
    data[ABILITY_VERSION_KEY.Extra] = {
      ...formDataExtra,
      ...newFormDataExtra,
    };

    data[ABILITY_VERSION_KEY.CapabilityVersion] = {
      ...formDataForVersionOfTrain,
      capability_uuid: urlParams?.abilityId ?? '',
      description: newFormDataForBaseOfTrain?.description || formDataForVersionOfTrain?.description,
    };
    if (newFormDataForConfigOfTrain[FieldNamesForTrain.TrainDatasetName]) {
      newFormDataForBaseOfTrain[FieldNamesForTrain.TrainDatasetName] =
        newFormDataForConfigOfTrain[FieldNamesForTrain.TrainDatasetName];
    } else {
      // 此时代表用户没有修改训练配置，则删除训练配置的数据
      // 这里 Object.keys 是用来判断是否 newFormDataForConfigOfTrain 为空对象（此时代表是在修改其他配置，而不是修改训练配置）
      if (Object.keys(newFormDataForConfigOfTrain).length > 0) {
        delete newFormDataForBaseOfTrain[FieldNamesForTrain.TrainDatasetName];
      }
    }

    if (newFormDataForEvaluateOfTrain[FieldNamesForTrain.EvalDatasetName]) {
      newFormDataForBaseOfTrain[FieldNamesForTrain.EvalDatasetName] =
        newFormDataForEvaluateOfTrain[FieldNamesForTrain.EvalDatasetName];
    } else {
      if (Object.keys(newFormDataForConfigOfTrain).length > 0) {
        delete newFormDataForBaseOfTrain[FieldNamesForTrain.EvalDatasetName];
      }
    }

    data[ABILITY_VERSION_KEY.CapabilityVersionBase] = {
      ...formDataForBaseOfTrain,
      ...newFormDataForBaseOfTrain,
      model_bucket_uuid:
        (modelInfo?.model_bucket_id ? modelInfo?.model_bucket_id : undefined) ||
        formDataForBaseOfTrain?.model_bucket_uuid,
      model_name:
        newFormDataExtra?.model?.model_name ||
        newFormDataForBaseOfTrain?.model_name ||
        formDataForBaseOfTrain?.model_name ||
        [],
      model_version: (modelInfo?.version ? [modelInfo?.version] : undefined) || formDataForBaseOfTrain?.model_version,
    };
    data[ABILITY_VERSION_KEY.CapabilityVersionTrain] = {
      ...formDataForConfigOfTrain,
      ...newFormDataForConfigOfTrain,
    };
    data[ABILITY_VERSION_KEY.CapabilityVersionEvaluate] = {
      ...formDataForEvaluateOfTrain,
      ...newFormDataForEvaluateOfTrain,
    };
    data[ABILITY_VERSION_KEY.CapabilityVersionService] = {
      ...formDataForInferenceOfTrain,
      ...newFormDataForInferenceOfTrain,
    };
    data[ABILITY_VERSION_KEY.CapabilityVersionModelService] = {
      ...formDataForModelServiceOfTrain,
      ...newFormDataForModelServiceOfTrain,
    };

    if (!!data[ABILITY_VERSION_KEY.CapabilityVersionService][FieldNamesForTrain.ImageFile]) {
      data[ABILITY_VERSION_KEY.CapabilityVersionModelService] = {
        uuid: data[ABILITY_VERSION_KEY.CapabilityVersionModelService]?.uuid,
      };
    } else {
      data[ABILITY_VERSION_KEY.CapabilityVersionService] = {
        uuid: data[ABILITY_VERSION_KEY.CapabilityVersionService]?.uuid,
      };
    }

    return data;
  };

  const updatDataAndPageLayout = async (params: TrainFormDataConfig, newStep: Step) => {
    const versionId = searchParams.get('version_id') || formDataForBaseOfTrain.version_id;

    if (!versionId) {
      console.error('versionId is empty');
      return;
    }

    scrollToPageTop();
    setIsLoading(true);
    await service.ability
      .updateAbilityByVersionId(
        {
          capabilityId: urlParams?.abilityId,
          versionId,
        },
        params
      )
      .catch(() => {
        setIsLoading(false);
      });
    // 更新版本的配置信息
    setCurrent(newStep);
    setIsLoading(false);
  };

  const goToNewStep = async (newStep: number, callback?: () => void) => {
    if (current === Step.TWO || current === Step.Three || current === Step.Four) {
      if (currentVersionStatus === CurrentVersionStatus.Pending) {
        message.error('当前版本未创建成功，无法进行当前步骤，请退出后重试！');
        return;
      }
    }

    // 验证下当前步骤里表单内容（若打开了表单的开关）
    switch (current) {
      case Step.ONE:
        versionBaseOfTrainRef.current?.submit(async (versionVersionOfTrainData: TrainFormDataConfig) => {
          // 创建版本
          const datasetInput = versionVersionOfTrainData[FieldNamesForTrain.DatasetInput] ?? '';
          const datasetOutput = versionVersionOfTrainData[FieldNamesForTrain.DatasetOutput] ?? '';
          const datasetType = versionVersionOfTrainData[FieldNamesForTrain.DatasetType] ?? '';
          const datasetName = versionVersionOfTrainData[FieldNamesForTrain.DatasetName] ?? '';
          delete versionVersionOfTrainData[FieldNamesForTrain.DatasetInput];
          delete versionVersionOfTrainData[FieldNamesForTrain.DatasetOutput];
          delete versionVersionOfTrainData[FieldNamesForTrain.DatasetType];
          delete versionVersionOfTrainData[FieldNamesForTrain.DatasetName];
          const dataset = {
            input: datasetInput,
            output: datasetOutput,
            dataset_type: datasetType,
            dataset_name: datasetName,
            projectId: urlParams?.projectId ?? '',
          };

          const uploadModelUuid = versionVersionOfTrainData?.model?.['upload_uuid'];
          const formatId = versionVersionOfTrainData?.model?.['format_id'];

          // 区分自定义创建还是从模型库中选择
          let modelInfo;
          let model;
          if (uploadModelUuid) {
            modelInfo = await generateModelVersion({
              upload_uuid: uploadModelUuid,
              name: versionVersionOfTrainData?.model?.['model_name']?.[0],
              format_id: formatId ?? undefined,
            });
            model = model = Object.assign({}, versionVersionOfTrainData?.model ?? {});
          } else {
            modelInfo = {
              data: {
                model_bucket_id: versionVersionOfTrainData?.model_bucket_uuid,
                version: versionVersionOfTrainData?.model_version?.[0],
                model_name:
                  versionVersionOfTrainData?.model_name || versionVersionOfTrainData?.model?.model_name
                    ? versionVersionOfTrainData?.model?.model_name
                    : [],
              },
            };
            model = Object.assign(
              {},
              {
                model_name:
                  versionVersionOfTrainData?.model_name || versionVersionOfTrainData?.model?.model_name
                    ? versionVersionOfTrainData?.model?.model_name
                    : [],
              } ?? {}
            );
          }

          delete versionVersionOfTrainData[FieldNamesForTrain.Model];

          const newFormDataExtra = {
            model,
            dataset,
          };
          const params = formatAbilityVersionData({
            newFormDataForBaseOfTrain: versionVersionOfTrainData,
            newFormDataExtra,
            modelInfo: modelInfo?.data,
          }) as TrainFormDataConfig;

          dispatch(setFormDataExtra(newFormDataExtra));

          scrollToPageTop();
          setIsLoading(true);
          // 仅在 url 参数为 create 或者 copy 且 currentVersionStatus 为对应的状态时，才创建版本，因为只有这个状态代表是第一次进来
          // 而不是通过 “上一步” 返回到第一步
          if (
            (versionStatus === VersionStatus.Create && currentVersionStatus === CurrentVersionStatus.Create) ||
            (versionStatus === VersionStatus.Copy && currentVersionStatus === CurrentVersionStatus.Copy)
          ) {
            // 创建能力版本
            const { data: { version_id = '' } = {} } = (await service.ability.createAbilityVersion(params)) ?? {};
            if (!version_id) {
              console.error('version_id is empty after createAbilityVersion');
            }
            await getVersionDetailById({ capabilityId: urlParams?.abilityId ?? '', versionId: version_id }).catch(
              () => {
                setIsLoading(false);
              }
            );
            // Update the version_id parameter
            searchParams.set('version_id', version_id);
            // Navigate to the new URL
            history(
              {
                pathname: location.pathname,
                search: `?${searchParams.toString()}`,
              },
              { replace: true }
            );
            // 在创建版本后，需要更新下当前版本的状态为 update
            dispatch(setCurrentVersionStatus(CurrentVersionStatus.Update));
            setCurrent(newStep);
          } else {
            // 更新能力版本
            const versionId = (searchParams.get('version_id') || formDataForBaseOfTrain.version_id) ?? '';
            await service.ability
              .updateAbilityByVersionId(
                {
                  capabilityId: urlParams?.abilityId,
                  versionId,
                },
                params
              )
              .catch(() => {
                setIsLoading(false);
              });
            await getVersionDetailById({ capabilityId: urlParams?.abilityId ?? '', versionId });
            setCurrent(newStep);
            dispatch(setCurrentVersionStatus(CurrentVersionStatus.Update));
          }
          setIsLoading(false);
          typeof callback === 'function' && callback();
        });
        break;
      case Step.TWO:
        if (isEnableConfigOfTrain) {
          configOfTrainRef.current?.submit(async (configOfTrainData: TrainFormDataConfig) => {
            const params = formatAbilityVersionData({ newFormDataForConfigOfTrain: configOfTrainData });
            await updatDataAndPageLayout(params, newStep);
            // 在更新完数据后，需要更新下当前的表单数据
            dispatch(setFormDataForConfigOfTrain({ ...formDataForConfigOfTrain, ...configOfTrainData }));
            const newFormDataForBaseOfTrain = {
              ...formDataForBaseOfTrain,
            };
            // 更新下基础信息里的训练数据集名称
            if (configOfTrainData[FieldNamesForTrain.TrainDatasetName]) {
              newFormDataForBaseOfTrain[FieldNamesForTrain.TrainDatasetName] =
                configOfTrainData[FieldNamesForTrain.TrainDatasetName];
            } else {
              newFormDataForBaseOfTrain[FieldNamesForTrain.TrainDatasetName] = null;
            }
            dispatch(setFormDataForBaseOfTrain(newFormDataForBaseOfTrain));
            typeof callback === 'function' && callback();
          });
        } else {
          // 若没有开启训练配置，则需要将训练配置的数据置为空
          const emptyData = getEmptyDataFromObj(formDataForConfigOfTrain);
          const params = formatAbilityVersionData({ newFormDataForConfigOfTrain: emptyData });
          await updatDataAndPageLayout(params, newStep);
          dispatch(setFormDataForConfigOfTrain({ ...formDataForConfigOfTrain, ...emptyData }));
          typeof callback === 'function' && callback();
        }
        break;
      case Step.Three:
        if (isEnableEvaluteOfTrain) {
          evaluteOfTrainRef.current?.submit(async (evaluteOfTrainData: TrainFormDataConfig) => {
            const params = formatAbilityVersionData({ newFormDataForEvaluateOfTrain: evaluteOfTrainData });
            await updatDataAndPageLayout(params, newStep);
            const newFormDataForBaseOfTrain = {
              ...formDataForBaseOfTrain,
            };
            if (evaluteOfTrainData[FieldNamesForTrain.EvalDatasetName]) {
              newFormDataForBaseOfTrain[FieldNamesForTrain.EvalDatasetName] =
                evaluteOfTrainData[FieldNamesForTrain.EvalDatasetName];
            } else {
              newFormDataForBaseOfTrain[FieldNamesForTrain.EvalDatasetName] = null;
            }
            dispatch(setFormDataForBaseOfTrain(newFormDataForBaseOfTrain));
            dispatch(setFormDataForEvaluateOfTrain({ ...formDataForEvaluateOfTrain, ...evaluteOfTrainData }));
            typeof callback === 'function' && callback();
          });
        } else {
          const emptyData = getEmptyDataFromObj(formDataForEvaluateOfTrain);
          const params = formatAbilityVersionData({ newFormDataForEvaluateOfTrain: emptyData });
          await updatDataAndPageLayout(params, newStep);
          dispatch(setFormDataForEvaluateOfTrain({ ...formDataForEvaluateOfTrain, ...emptyData }));
          typeof callback === 'function' && callback();
        }
        break;
      case Step.Four:
        if (isEnableInferenceOfTrain) {
          inferenceOfTrainRef.current?.submit(
            async (inferenceOfTrain: TrainFormDataConfig, modelServiceOfTrain: TrainFormDataConfig) => {
              const params = formatAbilityVersionData({
                newFormDataForInferenceOfTrain: inferenceOfTrain,
                newFormDataForModelServiceOfTrain: modelServiceOfTrain,
              });
              await updatDataAndPageLayout(params, newStep);
              dispatch(setFormDataForInferenceOfTrain({ ...formDataForInferenceOfTrain, ...inferenceOfTrain }));
              dispatch(setFormDataForInferenceOfTrain({ ...formDataForModelServiceOfTrain, ...modelServiceOfTrain }));
              typeof callback === 'function' && callback();
            }
          );
        } else {
          const emptyData = getEmptyDataFromObj(formDataForInferenceOfTrain);
          const params = formatAbilityVersionData({ newFormDataForInferenceOfTrain: emptyData });
          await updatDataAndPageLayout(params, newStep);
          dispatch(setFormDataForInferenceOfTrain({ ...formDataForInferenceOfTrain, ...emptyData }));
          typeof callback === 'function' && callback();
        }
        break;
      default:
        break;
    }
  };

  const completeAndLeavePage = () => {
    // clear all the data
    dispatch(resetStateToInitial(INITITAL_STATE));
    history(`./../../${urlParams?.abilityId}`);
  };

  const onComplete = () => {
    if (current === Step.Four) {
      goToNewStep(current, () => {
        message.success('执行完成，请在详情页继续查看！');
        completeAndLeavePage();
      });
    } else {
      goToNewStep(current + 1, () => {
        message.success('执行完成，请在详情页继续查看！');
        completeAndLeavePage();
      });
    }
  };

  const commitAndGoNext = (
    <Button type="primary" className={styles['footer-next-step']} onClick={() => goToNewStep(current + 1)}>
      提交，下一步
    </Button>
  );

  const isFirstTime =
    (versionStatus === VersionStatus.Create && currentVersionStatus === CurrentVersionStatus.Create) ||
    (versionStatus === VersionStatus.Copy && currentVersionStatus === CurrentVersionStatus.Copy);

  const closeConfirmModal = () => {
    setIsConfirmModalVisible(false);
  };

  return (
    <Spin spinning={isLoading || bucketIdLoading || modelListLoading}>
      <Layout className={styles.container}>
        <Content className={styles['body-container']}>
          <div className={styles['step-container']}>
            <Steps
              current={current}
              direction="vertical"
              items={[
                {
                  title: '基础信息',
                  description: '必填',
                },
                {
                  title: '训练配置',
                  description: '选填',
                },
                {
                  title: '评估配置',
                  description: '选填',
                },
                {
                  title: '推理配置',
                  description: '选填',
                },
              ]}
              onChange={onStepChange}
            />
          </div>
          <div className={styles['right-part']}>
            {current === Step.ONE && (
              <VersionBaseConfig models={models?.data} ref={versionBaseOfTrainRef} setIsLoading={setIsLoading}>
                <CustomFooter
                  isFirstStep={true}
                  backToPrev={backToPrevWithValidate}
                  onComplete={onComplete}
                  isFirstTime={isFirstTime}>
                  {commitAndGoNext}
                </CustomFooter>
              </VersionBaseConfig>
            )}
            {current === Step.TWO && (
              <ConfigOfTrain ref={configOfTrainRef}>
                <CustomFooter backToPrev={backToPrevWithValidate} onComplete={onComplete} isFirstTime={isFirstTime}>
                  {commitAndGoNext}
                </CustomFooter>
              </ConfigOfTrain>
            )}
            {current === Step.Three && (
              <EvaluteOfTrain ref={evaluteOfTrainRef}>
                <CustomFooter backToPrev={backToPrevWithValidate} onComplete={onComplete} isFirstTime={isFirstTime}>
                  {commitAndGoNext}
                </CustomFooter>
              </EvaluteOfTrain>
            )}
            {current === Step.Four && (
              <InferenceOfTrain ref={inferenceOfTrainRef}>
                <CustomFooter
                  backToPrev={backToPrevWithValidate}
                  onComplete={onComplete}
                  isFirstTime={isFirstTime}></CustomFooter>
              </InferenceOfTrain>
            )}
          </div>
        </Content>
      </Layout>
      <Modal
        open={isConfirmModalVisible}
        centered
        onOk={() => {
          closeConfirmModal();
          directBackToPrev();
        }}
        onCancel={closeConfirmModal}
        modalRender={modal => <div>{modal}</div>}
        closeIcon={null}
        className={cx('modal-confirm')}
        maskClosable={false}>
        <div className={cx('modal-confirm-body')}>
          <header className={cx('header')}>
            <div className={cx('left-part')}>
              <Icon name="info-日志" color="#E58616" size={24} cursor={'pointer'} className={styles['tip-user-info']} />
              <div className={cx('title')}>确定要放弃编辑的内容吗</div>
            </div>
            <Icon
              name="关闭-全局"
              size={16}
              cursor={'pointer'}
              onClick={closeConfirmModal}
              className={cx('tip-user-close')}
            />
          </header>
          <div className={cx('tip-body')}>当前修改将不会被保存，且不可恢复</div>
        </div>
      </Modal>
    </Spin>
  );
};

export default NewAIBodyVersion;

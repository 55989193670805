import { gql } from '@apollo/client';

export const GET_USER_INFO = gql`
  query GetUserInfo {
    user {
      name
      email
      fullName
      permissions
      roles
    }
  }
`;

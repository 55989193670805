import { useState } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Flex, Form, Button, message, Space } from '@fuxi/eevee-ui';

import service from '../../service';
import Upload, { UploadStatus } from '../../components/Upload';

const ProjectImportVersion: React.FC = () => {
  const history = useNavigate();
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const [urlState] = useSearchParams();
  const datasetId = urlState.get('datasetId');
  const datasetName = urlState.get('datasetName');
  const projectId = urlState.get('projectId');
  const versionId = urlState.get('versionId');
  const [form] = Form.useForm();
  const { data: uuidData } = useRequest(service.dataSet.uuidInit);

  const handelSave = async () => {
    setSubmitLoading(true);
    try {
      const values = await form.validateFields();
      if (values?.filenames?.find(item => item.status !== 'done')) {
        message.warning('有文件在上传中，请稍后');
        setSubmitLoading(false);
        return;
      }
      const filenames = values?.filenames?.filter(file => file.status === UploadStatus.SUCCESS).map(file => file.name);
      const res = await service.dataSet.importVersionsFile({
        // ...values,
        // filenames,
        dataset_id: datasetId || '',
        dataset_version_id: versionId || '',
        upload_uuid: uuidData?.upload_uuid,
        // project_id: projectId,
        // dataset_name: datasetName,
      });
      message.success('导入成功');
      setSubmitLoading(false);
      history(`../${versionId}?datasetName=${datasetName}`);
    } catch (e) {
      setSubmitLoading(false);
    }
  };
  const cancel = () => {
    history(-1);
  };

  return (
    <Flex justifyCenter style={{ marginTop: 40 }}>
      <Form
        form={form}
        labelAlign="right"
        autoComplete="off"
        initialValues={{}}
        style={{ width: 490 }}
        labelCol={{ span: 5 }}>
        <Form.Item label="数据集名称">
          <>{datasetName}</>
        </Form.Item>
        <Form.Item label="版本">
          <>{versionId}</>
        </Form.Item>
        <Form.Item
          label="数据"
          name="filenames"
          rules={[{ required: true, message: '请上传数据文件' }]}
          trigger="validateFileName"
          validateTrigger={'validateFileName'}
          tooltip="说明：单文件大小不超过20G，仅支持.csv .tar文件">
          <Upload
            // sliceUploadUrl="/upload/part"
            // uploadSuccessUrl="/upload/part/finish"
            payload={{
              upload_uuid: uuidData?.upload_uuid,
            }}
          />
        </Form.Item>
        <Space direction="horizontal" size={16}>
          <div />
        </Space>
        <Form.Item label=" " colon={false}>
          <Space>
            <Button onClick={cancel}>取消</Button>
            <Button type="primary" onClick={handelSave} loading={submitLoading}>
              确定
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default ProjectImportVersion;

import { useParams, useNavigate } from 'react-router-dom';
import { Card, Flex, Table, TableButtons, Image, Empty } from '@fuxi/eevee-ui';
import React, { useEffect, useState } from 'react';
import { useAntdTable, useRequest } from '@fuxi/eevee-hooks';

import { DefaultAiProjectId } from '@ai-training/utils/environment';

import { useAppSelector } from '@/hooks';

import Descriptions from '../../components/Descriptions';
import service from '../../service';
import { getSorterTableData } from '../../utils/util';
import ProjectImg from '../../assets/image/projectImgDetail.png';
import { publicDetailColumns, publicVersionColumns } from './const';

import ProjectMarkDown from '../../components/MarkDown/Markdown';
import { MarkdownInstance } from '../../service/common';

import EmptyImg from '../../assets/empty2.png';

import cx from './Public.module.less';

const AbilityPublicDetail: React.FC<{ readOnly?: boolean }> = ({ readOnly = false }) => {
  const aiProjectId = useAppSelector(
    state => state.project.currentMlProject?.aiProjectId || state.project.currentProject?.aiProjectId
  );

  const urlParams = useParams();
  const history = useNavigate();
  const [mdText, setMdText] = useState<string | undefined>(undefined);
  let columns = publicVersionColumns;
  const operation = {
    title: '操作',
    render: (_, record) => (
      <TableButtons
        data={[
          {
            text: '调用',
            onClick: () => {
              history(`transfer/${record.uuid}`);
            },
          },
        ]}
      />
    ),
  };
  if (urlParams.projectId && !readOnly) {
    columns = [...columns, operation];
  }
  const { data: detail, run: search } = useRequest(
    () =>
      service.ability.getPublicCapabilityDetails({
        capability_uuid: urlParams.abilityId,
      }),
    {
      manual: true,
    }
  );
  const { tableProps, run: searchTable } = useAntdTable(
    getSorterTableData(service.ability.getCapabilityVersions, {
      extraParams: { capability_uuid: urlParams.abilityId },
    }),
    {
      manual: true,
    }
  );

  useEffect(() => {
    const gitUrl = detail?.data?.git_url;
    if (!gitUrl) {
      setMdText(undefined);
      return;
    }
    const path = gitUrl?.split('https://lc.fuxi.netease.com')?.[1];
    MarkdownInstance.get(path)
      .then(res => {
        setMdText(res?.data);
      })
      .catch(err => {
        console.log(err);
        setMdText(undefined);
      });
  }, [detail]);

  useEffect(() => {
    if (aiProjectId) {
      window[DefaultAiProjectId] = aiProjectId;
      search();
      searchTable(tableProps.pagination, 'ascend');
    }
  }, [urlParams.abilityId, aiProjectId]);

  return (
    <div>
      {readOnly ? (
        <Flex gapLG column>
          <Card bordered={false} title="能力详情" headStyle={{ borderBottom: 'none', marginBottom: '-20px' }}>
            <Flex alignCenter>
              <Flex.Item alignSelf="2">
                <Descriptions items={publicDetailColumns} data={detail?.data || {}} column={2} />
              </Flex.Item>
              <Flex.Item alignSelf="1">
                <Image src={ProjectImg} width={348} height={174} />
              </Flex.Item>
            </Flex>
          </Card>

          <Card title="能力说明" headStyle={{ borderBottom: 'none', marginBottom: '-20px' }}>
            {/* <div className={cx.agent_desc_title}></div> */}
            <div className={cx.md_wrapper}>{mdText ? <ProjectMarkDown text={mdText} /> : <Empty />}</div>
          </Card>

          <Card bordered={false} headStyle={{ borderBottom: 'none', marginBottom: '-20px' }} title="能力版本">
            <Table
              columns={columns}
              {...tableProps}
              rowKey="id"
              pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
            />
          </Card>
        </Flex>
      ) : (
        <Flex gapLG column className={cx.public_agent_detail_under_project}>
          <Card bordered={false} title="能力详情" headStyle={{ borderBottom: 'none', marginBottom: '-20px' }}>
            <Flex alignCenter>
              <Flex.Item alignSelf="2">
                <Descriptions items={publicDetailColumns} data={detail?.data || {}} column={2} />
              </Flex.Item>
              <Flex.Item className={cx.img_under_project} alignSelf="1">
                <Image src={ProjectImg} width={348} height={174} />
              </Flex.Item>
            </Flex>
          </Card>

          <Card className={cx.md_card_under_project} title="能力说明" headStyle={{ borderBottom: 'none' }}>
            {/* <div className={cx.agent_desc_title}></div> */}
            <div className={cx.md_wrapper_under_project}>
              {mdText ? <ProjectMarkDown text={mdText} /> : <Empty image={EmptyImg} />}
            </div>
          </Card>

          <Card bordered={false} headStyle={{ borderBottom: 'none' }} title="能力版本">
            <Table
              columns={columns}
              {...tableProps}
              rowKey="id"
              pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
            />
          </Card>
        </Flex>
      )}
    </div>
  );
};

export default AbilityPublicDetail;

import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Tag,
  Typography,
} from '@fuxi/eevee-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { dropRight } from 'lodash-es';
import { DocumentNode, useMutation } from '@apollo/client';
import { LeftOutlined } from '@ant-design/icons';
import { ProjectReview } from '@/store/miniReview';
import { UPDATE_PROJECT_REVIEW } from '@/service/schema/miniReview/miniReview-mutations';
import { ReviewStatus, ReviewStatusLabels, Stage } from '@/constants/miniReviews';
import { questionsLabelMap } from '../utils';
import cx from './QuestionReview.module.less';

const { Title, Text } = Typography;

const QuestionReview: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [reviewData, setReviewData] = useState<ProjectReview>();
  const isFinalStage = reviewData?.stage === Stage.FINAL;
  const [postUpdateReview] = useMutation<
    DocumentNode,
    {
      input: {
        projectId: string;
        stageId: string;
        status: ReviewStatus;
        review: string;
        judge: string;
      };
    }
  >(UPDATE_PROJECT_REVIEW);

  const handleSubmit = async () => {
    try {
      const payload = await form.validateFields();
      Modal.confirm({
        content: (
          <div className={cx('submit-modal')}>
            确认提交 {reviewData?.judgeName || '评委'} 对 {reviewData?.projectName || '项目'} 的修改？
          </div>
        ),
        className: 'confirm-modal',
        // eslint-disable-next-line react-func/max-lines-per-function
        onOk: async () => {
          await postUpdateReview({
            variables: {
              input: {
                projectId: reviewData?.projectId!,
                stageId: reviewData?.stageId,
                status: reviewData?.status as ReviewStatus,
                review: JSON.stringify({
                  ...payload,
                  startTime: reviewData?.startTime,
                  endTime: reviewData?.endTime,
                }),
                judge: reviewData?.judge!,
              },
            },
          }).then((data: any) => {
            if (data?.data?.updateProjectReviewByManager) {
              message.success('保存成功！');
              goBackToStageProjectPage();
            } else {
              message.error({ content: '保存失败！', key: 'save-error' });
            }
          });
        },
      });
    } catch (e) {}
  };

  useEffect(() => {
    const initialReviewValues = location.state;
    if (!initialReviewValues) {
      // 回退上一级
      goBackToStageProjectPage();
      return;
    }
    setReviewData(initialReviewValues as ProjectReview);
    form.setFieldsValue(initialReviewValues);
  }, []);

  const goBackToStageProjectPage = () => {
    navigate(`${dropRight(location.pathname.split('/')).join('/')}`);
  };

  return (
    <div className={cx('container')}>
      <Flex style={{ marginBottom: 12 }} gap={8} alignCenter>
        <LeftOutlined onClick={goBackToStageProjectPage} />
        <Title style={{ marginBottom: 0 }} level={4}>
          {reviewData?.projectName}
        </Title>
        <Tag style={{ color: 'rgba(255, 255, 255, 0.65)' }}>
          {reviewData?.status && <>{ReviewStatusLabels[reviewData?.status]}</>}
        </Tag>
      </Flex>

      <Card bordered>
        <Row className={cx('review-info')}>
          <Col span={24}>评委：{reviewData?.judgeName}</Col>
          <Col span={24}>开始时间：{reviewData?.startTime}</Col>
          <Col span={24}>结束时间：{reviewData?.endTime}</Col>
        </Row>
        <div className={cx('subtitle')}>评分分值为1-10的整数：1-3较差，4-6一般，7-10优秀</div>
        <Form
          form={form}
          validateMessages={{
            required: '这道题必须回答哦',
          }}>
          {questionsLabelMap.map(item => (
            <Form.Item
              key={item.name}
              label={item.label + (item.name === 'difficulty' ? '（1-3困难，4-6一般，7-10容易）' : '')}
              name={item.name}
              rules={[{ required: true }]}>
              <InputNumber style={{ width: 60 }} precision={0} step={1} min={1} max={10} />
            </Form.Item>
          ))}
          {isFinalStage && (
            <Form.Item label="9.是否推荐上线" name="isRecommend" rules={[{ required: true }]}>
              <Radio.Group>
                <Radio value={true}>推荐</Radio>
                <Radio value={false}>不推荐</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item
            labelCol={{ span: 24 }}
            label={`${isFinalStage ? '10' : '9'}.项目整体评价或改进意见（不限字数）`}
            name="issue"
            rules={[{ required: true }]}>
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSubmit}>
              {reviewData?.status === ReviewStatus.APPRAISED ? '保存并发布' : '保存并返回'}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default QuestionReview;

import { LevelColorMap } from '@/pages/project/ProjectDetail/constants';
import cx from './index.module.less';

interface StatusDisplayProp {
  statusInfo: {
    label: string;
    level: string;
  };
}

export const StatusDisplay = (props: StatusDisplayProp) => {
  const { statusInfo } = props;
  return (
    <div>
      <div className={cx('status-tag')} style={{ background: LevelColorMap?.[statusInfo?.level] }}></div>
      <span>{statusInfo?.label}</span>
    </div>
  );
};

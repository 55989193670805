import { get } from 'lodash';
// import { message } from '@fuxi/eevee-ui';
import { AxiosError } from 'axios';
import { youlingAuthenticator } from '../handleYoulingLogin';
// import { openNetworkErrorNotification } from './openNotification';

// 不需要全局提示的错误
// const noGlobalPromptCodes: number[] = [];

export default function errorHandler(error: any) {
  // Ignore error when request is canceled by user:
  const isCanceled = !(error instanceof Error) && error && typeof error.message === 'string';

  if (isCanceled) return;

  // const status: number = get(error, 'response.status', 0);
  // const code: number = get(error, 'response.data.code', 0);

  // 处理401
  // if (status === 401) {
  //   return (window.location.href = `/rbac/login?next=${window.location.href}`);
  // } else if (!noGlobalPromptCodes.includes(code)) {
  //   const msg = get(error, 'response.data.message', '服务端异常');
  //   message.error(msg);
  // }

  return Promise.reject(error);
}

export const webInstanceErrorHandler = (error: AxiosError) => {
  const status: number = get(error, 'response.status', 0);
  const data = get(error, 'response.data', {});
  // @ts-ignore
  youlingAuthenticator({ httpStatus: status, code: data?.code, message: data?.message });
  return Promise.reject(error);
};

import React from 'react';
import { Button, Card, Descriptions, Flex, Space, Tooltip, Image, message, Typography } from '@fuxi/eevee-ui';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import ProjectImg from '@/assets/image/projectImgDetail.png';
import { handleDownloadClient } from '@/pages/templateCenter/utils';
import { nullImage } from '../const';
import ProjectDetailContainer from './ProjectDetailContainer';
import TplEditModal from './TplEditModal';
import { ProjectRole } from './constants';
import cx from './ProjectDetail.module.less';

const { Text } = Typography;

const ProjectInfo: React.FC = () => {
  const container = ProjectDetailContainer.useContainer();
  const { currentProject, userInfo, setShowEdit, isTpl, projectHasClientSample } = container;
  // const codeUrl = currentProject?.customRepoGitUrl?.substring(0, currentProject?.customRepoGitUrl?.lastIndexOf('/'));

  return (
    <Card className={cx('project-info-card')}>
      <Flex style={{ height: '100%' }}>
        <Flex flexDirection="column">
          <Descriptions
            title={
              <Space>
                <div className={cx('project-name')}>项目信息</div>
              </Space>
            }
            className={cx('description')}
            column={2}>
            <Descriptions.Item label="项目标识">
              <div className={cx('info-in-desc')}>
                <Tooltip placement="topLeft" overlayClassName={cx('des-tooltip')} title={currentProject?.identifier}>
                  <div className={cx('margin-info-in-desc')}>{currentProject?.identifier}</div>
                </Tooltip>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="更新时间">{currentProject?.updatedTime}</Descriptions.Item>
            <Descriptions.Item label="更新者">
              <Tooltip
                placement="topLeft"
                overlayClassName={cx('des-tooltip')}
                title={currentProject?.updater || currentProject?.creator}>
                <div className={cx('margin-info-in-desc')}>{currentProject?.updater || currentProject?.creator}</div>
              </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label="创建者">
              <div className={cx('info-in-desc')}>
                <Tooltip placement="topLeft" overlayClassName={cx('des-tooltip')} title={currentProject?.creator}>
                  <div className={cx('info-in-desc')}>{currentProject?.creator}</div>
                </Tooltip>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="项目ID">{currentProject?.id}</Descriptions.Item>
            <Descriptions.Item label="Key" className={cx('key-desc-item-wrapper')}>
              <div className={cx('margin-copyable-wrapper')}>
                <Tooltip
                  placement="topLeft"
                  overlayClassName={cx('des-tooltip')}
                  title={currentProject?.danluAccessKey}>
                  <Text
                    style={{ width: '100%' }}
                    ellipsis={{ tooltip: false }}
                    copyable={{ tooltips: false, text: currentProject?.danluAccessKey }}>
                    {currentProject?.danluAccessKey}
                  </Text>
                </Tooltip>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Secret" className={cx('secret-desc-item-wrapper')}>
              <div className={cx('copyable-wrapper')}>
                <Tooltip
                  placement="topLeft"
                  overlayClassName={cx('des-tooltip')}
                  title={currentProject?.danluSecretKey}>
                  <Text
                    style={{ width: '100%' }}
                    ellipsis={{ tooltip: false }}
                    copyable={{ text: currentProject?.danluSecretKey, tooltips: false }}>
                    {currentProject?.danluSecretKey}
                  </Text>
                </Tooltip>
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="项目描述" span={2}>
              <Tooltip overlayClassName={cx('des-tooltip')} title={currentProject?.description || ''}>
                <div className={cx('project-desc')}>{currentProject?.description || '-'}</div>
              </Tooltip>
            </Descriptions.Item>
          </Descriptions>
          <Space className={cx('project-info-action-buttons')}>
            <Button type="default" onClick={() => setShowEdit(true)}>
              编辑
            </Button>
            {userInfo.roles.includes(ProjectRole.TplCreator) && !isTpl && (
              <TplEditModal>
                <Button type="default">转换为模板</Button>
              </TplEditModal>
            )}
            {/* 只有存在Version才可能存在客户端 */}
            {projectHasClientSample && (
              <Button
                onClick={() => handleDownloadClient(currentProject.id, currentProject.versionInfo?.version)}
                type="default">
                下载示例客户端
              </Button>
            )}
          </Space>
        </Flex>

        <Flex.Item style={{ height: isTpl ? 112 : 224, marginLeft: 32 }}>
          <Image
            src={(currentProject.iconUrl !== nullImage && currentProject.iconUrl) || ProjectImg}
            width={isTpl ? 224 : 448}
            height={isTpl ? 112 : 224}
          />
        </Flex.Item>
      </Flex>
    </Card>
  );
};

export default ProjectInfo;

// import { ThemeConfig, theme } from '@fuxi/eevee-ui';

import { MappingAlgorithm, ThemeConfig, theme } from "antd";

// const baseDarkTheme = 

export const baseDarkAlgorithm: MappingAlgorithm = (seedToken, mapToken) => {
  // 使用 antd 默认的暗色算法生成基础token，这样其他不需要定制的部分则保持原样
  const baseToken = theme.darkAlgorithm(seedToken, mapToken);
  return {
    ...baseToken,
    colorError: '#DE5550',
    colorPrimary: '#4071f9',
  };
};


export const commonTheme: ThemeConfig = {
  algorithm: [baseDarkAlgorithm],
  token: {
    borderRadius: 4,
    colorBgBase: '#1e2127',
    colorPrimary: '#4071f9',
    colorText: 'white',
    colorLinkHover: undefined,
    colorLinkActive: undefined,
    controlOutline: 'rgba(64, 113, 249, 0.2)',
    controlTmpOutline: 'rgba(64, 113, 249, 0.2)',
    colorErrorOutline: 'rgba(64, 113, 249, 0.2)',
    colorBgLayout: '#262a33',
    controlItemBgHover: '#3b3f47',
    controlItemBgActive: '#40424b',
    colorBgElevated: '#262a33',
    colorFillAlter: '#2b2e37',
    colorTextPlaceholder: 'rgba(255, 255, 255, 0.65)',
    colorIcon: 'rgba(255, 255, 255, 0.65)',
    colorTextQuaternary: 'rgba(255, 255, 255, 0.65)',

  },
  components: {
    Table: {
      fontWeightStrong: 500,
      colorTextHeading: 'rgba(255, 255, 255, 0.65)',
      headerSplitColor: '#353A44',
      rowHoverBg: '#353A44',
      headerBg: '#353A44',
      borderColor: 'rgba(255, 255, 255, 0.10)',
    },
    Descriptions: {
      colorText: 'rgba(255, 255, 255, 0.85)',
    },
    Tooltip: {
      colorBgSpotlight: '#000',
    },
    Tabs: {
      cardBg: '#262a33',
      horizontalMargin: '0 0 24px 0'
    },
    Menu: {
      itemSelectedColor: '#4071f9',
      darkItemSelectedColor: '#4071f9',
      darkItemHoverBg: 'rgba(255, 255, 255, 0.10)',
    },
    Pagination: {
      itemInputBg: '#262a33',
    },
    DatePicker: {
      colorPrimary: '#4071f9',
      cellHoverWithRangeBg: '#042682',
    },
    Select: {
      optionSelectedBg: 'rgba(64, 113, 249, 0.20)',
      optionSelectedColor: '#4071f9',
    },
    Cascader: {
      optionSelectedBg: 'rgba(64, 113, 249, 0.20)',
      dropdownHeight: 140,
    },
    Form: {
      labelColonMarginInlineEnd: 12,
    },
    InputNumber: {
      hoverBg: 'transparent',
      activeBg: 'transparent',
    },
    Button: {
      paddingInline	: 12
    },
  },
};

import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Progress, Tabs, Flex, Checkbox, Divider, Icon, Spin } from '@fuxi/eevee-ui';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useNavigate, useParams } from 'react-router-dom';
import numeral from 'numeral';
import { requestConfirm } from '@ai-training/utils';
import service from '@/service';
import { AnnotationType, ImportStatusMap, annotationTypeMap, getAnnotateTabList } from '../const';
import { DatasetContainer, useDatasetState } from '../index.container';
import { EditItem } from '../../components';
import ImageText from './ImageText';
import Text from './Text';
import cx from './index.module.less';

export const DatasetDetail = () => {
  const navigate = useNavigate();
  const { datasetVid } = useParams();

  const { detailData, infoList, pagination, getInfoLoading, tab, setTab, getData } = useDatasetState();

  const { refetch: getDetail, isLoading, data: detail } = detailData;

  useEffect(() => {
    if (detail) {
      getData({ current: 1, pageSize: 18 });
    }
  }, [detail, tab]);

  const items = [
    {
      key: 'id',
      label: 'id',
      children: detail?.data?.id,
    },
    {
      key: 'createTime',
      label: '创建时间',
      children: detail?.data?.create_time,
    },
    {
      key: 'datasetType',
      label: '数据集类型',
      children: detail?.data?.classification,
    },
    {
      key: 'annotateType',
      label: '标注类型',
      children: <span>{annotationTypeMap[detail?.data?.annotation_type as string]}</span>,
    },
    {
      key: 'annotateType',
      label: '导入状态',
      children: (
        <Flex alignCenter>
          <div
            className={cx('status-icon', `status-icon-${detail?.data?.first_version_data_import_status ?? '-'}`)}></div>
          {ImportStatusMap[detail?.data?.first_version_data_import_status as string] ?? '-'}
        </Flex>
      ),
    },
    {
      key: 'description',
      label: '描述',
      children: (
        <EditItem
          type="updateDescription"
          title={detail?.data?.description || '-'}
          datasetId={detail?.data?.id}
          refresh={getDetail}
        />
      ),
    },
  ];

  const [inDelete, setInDelete] = useState(false);

  const [checkedList, setCheckedList] = useState<number[]>([]);
  const checkAll = infoList?.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < infoList?.length;
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? (infoList?.map(item => item.id) as number[]) : []);
  };

  const onCheckChange = checkedItem => {
    if (checkedItem.checked) {
      setCheckedList([...checkedList, checkedItem.id]);
    } else {
      setCheckedList(checkedList.filter(item => item !== checkedItem.id));
    }
  };

  const handleClick = (e, type) => {
    if (type === 'delete') {
      setInDelete(true);
    } else if (type === 'exitDelete') {
      setInDelete(false);
    }
  };

  return (
    <div className={cx('dataset-detail')}>
      <Spin spinning={isLoading}>
        <Flex alignCenter className={cx('detail')}>
          <Flex.Item flex={1} style={{ marginRight: 80 }}>
            <Descriptions
              title={
                <EditItem
                  type="updateName"
                  title={detail?.data?.zh_name!}
                  datasetId={detail?.data?.id}
                  refresh={getDetail}
                />
              }
              items={items}
              column={2}
            />
          </Flex.Item>
          <Flex.Item flex={'0 0 390px'}>
            <Flex alignCenter justifyBetween style={{ position: 'relative' }}>
              <Progress
                type="circle"
                percent={
                  detail?.data?.first_version_annotated_data_count
                    ? numeral(
                        (detail?.data?.first_version_annotated_data_count! * 100) /
                          detail?.data?.first_version_data_count!
                      ).format('00')
                    : 0
                }
                strokeWidth={10}
                size={130}
                style={{ width: 130 }}
                format={percent => (
                  <>
                    <div className={cx('gray-text')}>标注完成度</div>
                    <div style={{ marginTop: 8 }}>{percent}%</div>
                  </>
                )}
              />
              <div>
                <div className={cx('gray-text')}>已标注</div>
                <div className={cx('large-text')}>{detail?.data?.first_version_annotated_data_count}</div>
              </div>
              <div style={{ marginRight: 24 }}>
                <div className={cx('gray-text')}>标注总量</div>
                <div className={cx('large-text')}>{detail?.data?.first_version_data_count}</div>
              </div>
              <div className={cx('update-time')}>
                *数据更新于{detail?.data?.first_version_last_annotation_time || '-'}
              </div>
            </Flex>
          </Flex.Item>
        </Flex>
      </Spin>
      <Divider className={cx('c-divider')} />
      <div className={cx('info')}>
        <div className={cx('info-title')}>数据信息</div>
        <Flex justifyBetween alignCenter className={cx('info-toolbar')}>
          <Flex.Item>
            <Tabs
              activeKey={tab}
              items={getAnnotateTabList(
                +detail?.data?.first_version_data_count!,
                +detail?.data?.first_version_annotated_data_count!
              )}
              onChange={setTab}
            />
          </Flex.Item>
          <Flex.Item>
            <div className={cx('info-toolbar-right')}>
              {inDelete ? (
                <React.Fragment>
                  <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                    全选本页
                  </Checkbox>
                  <Button
                    type="primary"
                    className={cx('mr-12', 'ml-12')}
                    disabled={checkedList.length === 0}
                    onClick={() => {
                      requestConfirm({
                        title: `确定删除${checkedList.length}项${
                          detail?.data?.annotation_type === AnnotationType.Text ? '问答对' : '图片'
                        }吗?`,
                        payload: {
                          datasetVid,
                          ids: checkedList,
                        },
                        submitApi: service.ml.deleteDatasets,
                      }).then(res => {
                        if ((res as { data: boolean }).data) {
                          getDetail();
                          setCheckedList([]);
                          setInDelete(false);
                        }
                      });
                    }}>
                    删除选择{checkedList.length}项目
                  </Button>
                  <Button type="default" onClick={e => handleClick(e, 'exitDelete')}>
                    退出删除
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    type="default"
                    onClick={e => handleClick(e, 'delete')}
                    className={cx('mr-12')}
                    icon={<Icon name="删除" />}>
                    删除
                  </Button>
                  {detail?.data?.first_version_data_import_status !== 'importing' ? (
                    <Button
                      type="primary"
                      className={cx('mr-12')}
                      icon={<Icon name="icon-daoru" />}
                      disabled={isLoading}
                      onClick={() =>
                        navigate(
                          `import?annotateType=${detail?.data?.annotation_type}&datasetType=${detail?.data?.classification}`
                        )
                      }>
                      导入数据
                    </Button>
                  ) : null}

                  <Button
                    type="primary"
                    icon={<Icon name="icon-biaozhu" />}
                    disabled={isLoading}
                    onClick={() => navigate(`annotate-${detail?.data?.annotation_type}`)}>
                    标注数据
                  </Button>
                </React.Fragment>
              )}
            </div>
          </Flex.Item>
        </Flex>
        <Spin spinning={getInfoLoading}>
          {detail?.data?.annotation_type === AnnotationType.Image ? (
            <ImageText
              list={infoList}
              fetchDetail={getDetail}
              pagination={pagination}
              inDelete={inDelete}
              checkedList={checkedList}
              datasetVid={datasetVid!}
              onCheckChange={onCheckChange}
            />
          ) : (
            <Text
              tab={tab}
              pagination={pagination}
              datasetVid={datasetVid!}
              fetch={getDetail}
              list={infoList}
              inDelete={inDelete}
              checkedList={checkedList}
              onCheckChange={onCheckChange}
            />
          )}
        </Spin>
      </div>
    </div>
  );
};

const DatasetDetailPage = () => {
  return (
    <DatasetContainer.Provider>
      <DatasetDetail />
    </DatasetContainer.Provider>
  );
};

export default DatasetDetailPage;

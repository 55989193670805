import { useEffect, useState } from 'react';
import { Card, Flex, Icon, Layout, Spin, Tooltip, message } from '@fuxi/eevee-ui';
import { Outlet, useMatches, useNavigate, useParams } from 'react-router-dom';
import { Empty, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '@/hooks';

import { setMlCurrentProject, setMlProjectList } from '@/store/project';
import { setMlCategory } from '@/store/mlCategory';
import ml from '@/service/ml';
import EmptyImg from '@/assets/image/empty2.png';
import { ProjectStatus } from '@/constants';
import MlSiderMenu from '../MlSiderMenu';
import MlBreadcrumb, { MlMatches } from '../components/MlBreadcrumb';
import cx from './index.module.less';

export const MlBasicLayout = () => {
  const loadingProjectList = useAppSelector(state => state.project.projectListFetching);
  let currentProject = useAppSelector(state => state.project.currentMlProject);
  const projectList = useAppSelector(state => state.project.mlProjectList);
  const dispatch = useAppDispatch();
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const { getTagList, getMlProjectList } = ml;
  const urlParams = useParams();
  const projectIdInUrl = urlParams?.projectId;
  // 从路由中获取projectId
  const projectIdInStore = currentProject.id;
  const emptyProjectList = !projectList?.length;
  const navigate = useNavigate();
  const matches = useMatches() as MlMatches[];
  const bread: MlMatches[] = matches.filter(item => item?.data?.title);
  const [timeoutId, setTimeoutId] = useState(0);

  // 如果当前项目状态为创建中，每隔2s请求一次项目列表
  useEffect(() => {
    window.clearTimeout(timeoutId);
    if (currentProject.status === ProjectStatus.Creating) {
      const currentTimeoutId = window.setTimeout(() => {
        getMlProjectList({
          pageNum: 1,
          pageSize: 999999,
        }).then(res => {
          const projectList = res.data?.data || [];
          dispatch(setMlProjectList(projectList || []));
          const currentProject = projectList.find(p => p.id === projectIdInStore) || projectList[0];
          dispatch(setMlCurrentProject(currentProject));
        });
      }, 2000);
      setTimeoutId(currentTimeoutId);
    }
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [currentProject]);

  useEffect(() => {
    // 路由中不含projectId，且项目列表不为空时，跳转到在store中的当前项目，否则跳转到项目列表第一个项目
    if (projectIdInStore && !projectIdInUrl) {
      currentProject = projectList.find(p => p.id === projectIdInStore) || projectList[0];
      navigate(baseUrl(currentProject.id!));
    } else if (!!projectIdInUrl && !!projectList?.length) {
      const currentProject = projectList.find(p => p.id === +projectIdInUrl);
      if (!currentProject) {
        message.error('项目不存在');
        return;
      }
      dispatch(setMlCurrentProject(currentProject));
    }
  }, [projectIdInStore, projectIdInUrl]);

  useEffect(() => {
    if (!!aiProjectId) {
      // 请求ml模块category
      getTagList().then(res => {
        dispatch(
          setMlCategory(
            res.data?.map(item => ({
              firstCategory: item.first_category,
              secondCategory: item.second_category,
              categoryUuid: item.category_uuid,
            }))
          )
        );
      });
    }
  }, [aiProjectId]);

  const { Content, Sider } = Layout;

  const getProjectListOptions = () => {
    return projectList.map(project => ({
      label: project.name,
      value: project.id,
      desc: project.description,
    }));
  };

  const baseUrl = (projectId: number) => {
    return `/ml/${projectId}/ai-platform/ml-public-ability`;
  };

  const getMlUrl = (projectId: number) => {
    const url = window.location.href;
    const { pathname } = new URL(url);
    const newPathname = pathname.replace(`/ml/${projectIdInUrl}`, `/ml/${projectId}`);
    return newPathname;
  };

  const changeMlProject = (id: number) => {
    const targetProject = projectList.find(p => p.id === id);
    if (!targetProject) {
      message.error('项目不存在');
      return;
    }
    navigate(getMlUrl(id));

    // 若当前路由处于项目能力详情(编辑)/服务详情/任务详情时，切换项目时，跳转到对应的模块首页
    if (bread.length > 1 && bread[0].pathname.startsWith(`/ml/`)) {
      const currentPage = bread[bread.length - 1].data.title;
      if (['项目能力详情', '任务详情', '服务详情', '编辑'].includes(currentPage)) {
        navigate(bread[0].pathname.replace(`/ml/${projectIdInUrl}`, `/ml/${id}`));
      }
      // 数据集相关
      if (['数据集详情', '导入数据', '标注数据'].includes(currentPage)) {
        navigate(bread[0].pathname.replace(`/ml/${projectIdInUrl}`, `/ml/${id}`));
      }
    }
  };

  const preventClickDefault = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Spin
      wrapperClassName={cx('spin-wrapper')}
      style={{ height: '100%' }}
      spinning={loadingProjectList}
      tip="正在查询项目信息，请稍后...">
      <Card className={cx('wrapper')}>
        <Layout className={cx('layout')}>
          <Sider width={220} className={cx('menu-sider')}>
            <Flex justifyCenter>
              <Select
                listHeight={160}
                dropdownMatchSelectWidth={false}
                dropdownClassName={cx('project-select-dropdown')}
                suffixIcon={<Icon className={cx('select-project-icon')} size={16} color="#fff" name="icon-qiehuan" />}
                className={cx('project-select')}
                onChange={e => changeMlProject(e)}
                value={currentProject.id}
                options={getProjectListOptions()}
                optionRender={option => (
                  <Flex alignCenter justifyBetween>
                    <div className={cx('option-label')}>
                      <Tooltip
                        title={<span onClick={preventClickDefault}>{option.data.label}</span>}
                        placement="topLeft">
                        {option.data.label}
                      </Tooltip>
                    </div>
                    <div className={cx('option-desc')}>
                      <Tooltip
                        title={<span onClick={preventClickDefault}>{option.data.desc}</span>}
                        placement="topLeft">
                        {option.data.desc}
                      </Tooltip>
                    </div>
                  </Flex>
                )}></Select>
            </Flex>

            <MlSiderMenu mode="inline" />
          </Sider>
          <Content className={cx('content-wrapper')}>
            {!emptyProjectList ? (
              <Spin spinning={currentProject?.status === ProjectStatus.Creating} tip="项目正在创建中, 请稍后...">
                <MlBreadcrumb></MlBreadcrumb>
                <Outlet />
              </Spin>
            ) : (
              <div className={cx('empty-wrapper')}>
                <Empty image={EmptyImg} description="暂无项目"></Empty>
              </div>
            )}
          </Content>
        </Layout>
      </Card>
    </Spin>
  );
};

import { WebInstance } from "./common";

// 获取项目能力的版本信息列表
export const getActiveCapVersions = (params: {
  projectId: ID;
}) => {
  return WebInstance.get<{
    capId: string;
    capName: string;
    capVersion: string;
    createTime: string;
    projectId: number;
    updateTime: string;
  }[]>('/ai/versions', { params });
}

export const updateCapActiveVersion = (param: {
  projectId: ID;
  updateProjectCapVersionDTO: {
    capId: string;
    capName: string;
    capVersion: string;
  }
}) => {
  return WebInstance.post('/ai/version', param.updateProjectCapVersionDTO, {
    params: {
      projectId: param.projectId,
    }
  });
}

/**
 * 判断 permissionList 是否包含 permissions 中的权限点
 * @param permissionList 所有权限点
 * @param permissions 需要校验的权限
 * @returns {boolean}
 */
export const checkPermission = (permissionList: string[], permissions: string[] | undefined) => {
  if (!permissions || !permissions.length) return true;

  return permissions.some((code: string) => {
    return permissionList.includes(code);
  });
};

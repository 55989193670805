// 合法内容的组合  [\w!@#$%^&*~()\-+=]+

// 不限制组合种类的数量（任意几种都能成功）
export const PASSWORD_REG1 = /^[\w!@#$%^&*~()\-+=]+$/;

// 待排除的组合（所有只有1项的组合排除掉，即至少2项才能符合要求）   (?!([a-z]+|[A-Z]+|\d+|[!@#$%^&*~()\-_+=]+)$)
export const PASSWORD_REG2 = /^(?!([a-z]+|[A-Z]+|\d+|[!@#$%^&*~()\-_+=]+)$)[\w!@#$%^&*~()\-+=]+$/;

// 待排除的组合（所有只有1项、2项的组合排除掉，即至少3项才能符合要求）   (?!([a-z\d]+|[A-Z\d]+|[a-zA-Z]+|[0-9!@#$%^&*~()\-_+=]+|[a-z!@#$%^&*~()\-_+=]+|[A-Z!@#$%^&*~()\-_+=]+)$)
export const PASSWORD_REG3 =
  /^(?!([a-z\d]+|[A-Z\d]+|[a-zA-Z]+|[0-9!@#$%^&*~()\-_+=]+|[a-z!@#$%^&*~()\-_+=]+|[A-Z!@#$%^&*~()\-_+=]+)$)[\w!@#$%^&*~()\-+=]+$/;

/**
 * 生成验证密码的校验方法
 * @param min 密码最小位数
 * @param max 密码最大位数
 * @param ruleCount 密码规则中需要满足的子规则数量（取值范围：1，2，3）
 */
export const validatePassword = (min: number = 6, max: number = 20, ruleCount: number = 3) => {
  const validator = (rule, value) => {
    const index = Math.max(Math.min(ruleCount - 1, 2), 0);
    const ruleREG = [PASSWORD_REG1, PASSWORD_REG2, PASSWORD_REG3][index];

    if (!value || !value.length) {
      return Promise.reject('密码必须填写！');
    } else if (value.length < min) {
      return Promise.reject(`密码不能少于 ${min} 个字符！`);
    } else if (value.length > max) {
      return Promise.reject(`密码不能超过 ${max} 个字符！`);
    } else if (!ruleREG.test(value)) {
      return Promise.reject(validator.message);
    } else {
      return Promise.resolve();
    }
  };

  validator.message = `请输入大小写字母、数字、特殊字符的组合的密码，${
    ruleCount > 1 ? `至少包含 ${ruleCount} 项，` : ''
  }且长度在 ${min}~${max} 位，特殊字符包括 !@#$%^&*()_+-=`;

  return validator;
};

// 只能由小写字母、数字、_、- 组成, 且不能以_或-开头和结尾
export const IMAGE_NAME_REG = /^[a-z0-9][a-z0-9_-]*[a-z0-9]$/;

// 只能由字母、数字、下划线、短横线组成，且不能以下划线或短横线开头和结尾
export const IMAGE_TAG_REG = /^[a-zA-Z0-9]([a-zA-Z0-9_-]*[a-zA-Z0-9])?$/;

export const validateImageName = (rule, value) => {
  if (value.length < 2) {
    return Promise.reject(`镜像名称不能少于2个字符`);
  } else if (!IMAGE_NAME_REG.test(value)) {
    return Promise.reject('镜像名只能由小写字母、数字、下划线、短横线组成，且不能以下划线或短横线开头和结尾');
  } else {
    return Promise.resolve();
  }
};

export const validateImageTag = (rule, value) => {
  if (!IMAGE_TAG_REG.test(value)) {
    return Promise.reject('标签只能由字母、数字、下划线、短横线组成，且不能以下划线或短横线开头和结尾');
  } else {
    return Promise.resolve();
  }
};

import { WebInstance } from './common';

const apiSuffix = '/user/sshKey';

export const getSSHKey = () => {
  return WebInstance.get(apiSuffix);
};

export const addSSHKey = (data: { sshPubKey: string; sshPubKeyTitle: string }) => {
  return WebInstance.post(apiSuffix, data);
};

export const updateSSHKey = (data: { sshPubKey: string; sshPubKeyTitle: string; id: number }) => {
  return WebInstance.put(apiSuffix, data);
};

export const removeSSHKey = (sshPubKeyId: number) => {
  return WebInstance.delete(apiSuffix, { params: { sshPubKeyId } });
};

export const syncSSHKey = (projectId: number, serverId: string) => {
  return WebInstance.post(`/server/ssh/sync/project/${projectId}/server/${serverId}`);
};

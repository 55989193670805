import React, { useState } from 'react';
import bindClass from 'classnames/bind';
import { useParams } from 'react-router-dom';
import { useRequest } from '@fuxi/eevee-hooks';
import { LineConfig, Line } from '@ant-design/charts';
import { Card, Flex, message, Divider } from '@fuxi/eevee-ui';

import service from '../../service';
import { serviceDetailColumns } from './const';
import EditText from '../../components/EditText';
import { OnlineServiceInfo } from '../../types/onlineService';
import ChartsDatePicker from '../../components/ChartsDatePicker';
import Descriptions, { DescriptionsItem } from '../../components/Descriptions';

import pStyles from './index.module.css';
import cx from './index.module.less';

const styles = bindClass.bind(pStyles);

const ServiceDetail: React.FC = () => {
  const urlParams = useParams();
  const [detail, setDetail] = useState<OnlineServiceInfo | null>(null);
  useRequest(
    () =>
      service.onlineService.appDetails({
        service_uuid: urlParams.serviceId || '',
      }),
    {
      onSuccess: data => {
        setDetail(data.data);
      },
    }
  );

  const { data: lineData, run } = useRequest(
    params =>
      service.projectManage.projectCalls({
        ...params,
        project_id: Number(urlParams.projectId),
        app_id: urlParams.serviceId,
      }),
    {
      manual: true,
    }
  );
  const config: LineConfig = {
    data: lineData?.data?.list || [],
    padding: 'auto',
    xField: 'date',
    yField: 'count',
    point: {
      size: 5,
      shape: 'diamond',
    },
    tooltip: {
      customContent: (title, items) => {
        return (
          <div>
            <div style={{ color: 'rgba(255, 255, 255, 0.65)', marginBottom: '12px' }}>{title}</div>
            {items.map(item => (
              <div key={item.title} className="g2-tooltip-list-item-value">
                <span className="g2-tooltip-marker" style={{ backgroundColor: item.color }} />
                <span style={{ color: '#fff' }}> 调用次数 {item.value}</span>
              </div>
            ))}
          </div>
        );
      },
      domStyles: {
        'g2-tooltip': {
          backgroundColor: '#30333C',
          borderRadius: '3px',
          border: '0.5px solid rgba(255, 255, 255, 0.20)',
          boxShadow: 'unset',
          padding: '12px',
        },
        // 'g2-tooltip-list-item-value': {
        //   marginBottom: '0px',
        // },
        'g2-tooltip-marker': { color: '#fff' },
      },
      enterable: true,
    },
  };
  const handelSave = async val => {
    try {
      await service.onlineService.editRelease({ service_uuid: urlParams.serviceId || '', description: val });
      message.success('保存成功');
      detail && setDetail({ ...detail, description: val });
    } catch (e) {}
  };
  const handleRangeChange = val => {
    run({ range: val });
  };
  return (
    <Flex column className={cx['detail-page-wrapper']}>
      <Card title="服务详情" className={cx['detail-head']}>
        <Descriptions
          column={3}
          items={[
            ...serviceDetailColumns,
            {
              label: '服务描述',
              dataIndex: 'description',
              render: data => {
                return <EditText value={detail?.description} onSave={handelSave} maxLength={100} />;
              },
            },
          ]}
          data={detail || {}}>
          {/* <DescriptionsItem span={1} label="服务描述" className={cx['desc-edit-item']}>
            <EditText value={detail?.description} onSave={handelSave} maxLength={50} />
          </DescriptionsItem> */}
        </Descriptions>
      </Card>
      <Divider className={cx['detail-divider']} />
      <Card title="用量统计" className={cx['detail-body']}>
        <Flex justifyBetween className="page-search">
          <Flex alignCenter>
            <label>统计范围：</label>
            <ChartsDatePicker onChange={handleRangeChange} />
          </Flex>
          <div className={styles('transfer-data')}>
            <span>今日调用次数：{lineData?.data?.daily}</span>
            <span>总调用次数：{lineData?.data?.total}</span>
          </div>
        </Flex>
        <Line {...config} />
      </Card>
    </Flex>
  );
};

export default ServiceDetail;

/* eslint-disable max-lines */
import { useEffect, useRef, useState } from 'react';
import { Button, Flex, Tabs, Input, Divider, Tooltip, Spin, Popconfirm, message, Icon, Empty } from '@fuxi/eevee-ui';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import { FreeMode, Thumbs, Navigation } from 'swiper';

import { EyeOutlined, LeftOutlined, QuestionCircleOutlined, RedoOutlined, RightOutlined } from '@ant-design/icons';
import { useSize } from '@fuxi/eevee-hooks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import useWindowSize from '@/hooks/useWindowSize';
import { useAppSelector } from '@/hooks';
import service from '@/service';
import { IInfoItem, getAnnotateTabList } from '../const';
import { AnnotateTemp, EditStatus, UploadDatasetImg } from '../../components/';

import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import { DatasetContainer, useDatasetState } from '../index.container';
import { useUploadUuid } from '../useUploadUuid';
import { getOrder } from '../util';
import cx from './index.module.less';

const largePage = { gap: 12, pageSize: 7, width: 982, prevWidth: 800 };
const smallPage = { gap: 8, pageSize: 5, width: 682, prevWidth: 650 };
const PAGE_WIDTH = 1440;

export const AnnotateImage = () => {
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);

  const navigate = useNavigate();
  const { datasetVid } = useParams();
  const [searchParams] = useSearchParams();
  const currentOrder = +(searchParams.get('order') || 0);
  const positionRef = useRef<number>(0);

  const { detailData, infoList, setInfoList, pagination, getInfoLoading, tab, setTab, getData } = useDatasetState();

  const { data: detail, refetch: getDetail } = detailData;
  const { data: uploadUUidRes } = useUploadUuid(aiProjectId);

  const pageSize = useWindowSize();
  const [pageConfig, setPageConfig] = useState(pageSize.width < PAGE_WIDTH ? smallPage : largePage);

  useEffect(() => {
    if (!aiProjectId) return;
    if (!pageSize.width) return;
    // 页面宽度变化，不刷新页面
    if (pageSize.width && infoList?.length) return;
    // 初始进入页面
    const config = pageSize.width < PAGE_WIDTH ? smallPage : largePage;

    setPageConfig(config);
    const currentPage = Math.floor(currentOrder / config.pageSize) + 1;
    positionRef.current = currentOrder % config.pageSize;
    getData({
      current: currentOrder ? currentPage : 1,
      pageSize: config.pageSize,
    });
  }, [aiProjectId, pageSize.width]);

  useEffect(() => {
    if (!aiProjectId) return;
    // 保证初次进入页面走上面的逻辑
    if (!pageSize.width) return;
    getData({ current: 1, pageSize: pageConfig.pageSize });
  }, [tab]);

  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);
  const [currentItem, setCurrentItem] = useState<IInfoItem>();

  useEffect(() => {
    if (!currentItem) {
      setCurrentItem(infoList[positionRef.current]);
      swiperInstance?.slideTo(positionRef.current, 0);
    }
  }, [infoList]);

  const handleChangePage = (type: 'prev' | 'next') => {
    const { current, pageSize, total, onChange } = pagination;
    if (type === 'prev') {
      if (current === 1) {
        message.error('没有更多了');
        return;
      }
      setCurrentItem(undefined);
      onChange(current - 1, pageSize);
    } else {
      if (current * pageSize >= total) {
        message.error('没有更多了');
        return;
      }
      setCurrentItem(undefined);
      onChange(current + 1, pageSize);
    }
    positionRef.current = 0;
  };

  const handleSwitch = (type: 'prev' | 'next') => {
    const index = infoList.findIndex(item => item.id === currentItem?.id);

    if (index === (infoList.length || 0) - 1 && type === 'next') {
      handleChangePage('next');
      return;
    }
    if (index === 0 && type === 'prev') {
      handleChangePage('prev');
      return;
    }

    if (type === 'next') {
      swiperInstance?.slideNext();
      setCurrentItem(infoList[index + 1]);
    }
    if (type === 'prev') {
      setCurrentItem(infoList[index - 1]);
      swiperInstance?.slidePrev();
    }
  };

  const { mutate: saveAnnotation } = useMutation({
    mutationFn: (currentItem?: IInfoItem) =>
      service.ml.annotateData(datasetVid!, {
        data: currentItem?.data?.trim(),
        annotation: currentItem?.annotation?.trim(),
        id: currentItem?.id as number,
      }),
    onSuccess: () => {
      getDetail();
    },
  });
  const [isSaving, setIsSaving] = useState(false);
  const handleSave = () => {
    setIsSaving(false);
    saveAnnotation(currentItem);
  };

  const swiperRef = useRef(null);
  const size = useSize(swiperRef);
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

  const handleReset = () => {
    setCurrentItem(undefined);
    getData({ current: 1, pageSize: pageConfig.pageSize });
    getDetail();
  };
  const { mutate: deleteItem, isLoading: deleteLoading } = useMutation<any, any, any>({
    mutationFn: ({ datasetVid, ids }) => service.ml.deleteDatasets({ datasetVid, ids }),
    onSuccess: res => {
      message.success('删除成功');
      handleReset();
    },
  });

  const [isTemplateVisible, setIsTemplateVisible] = useState(false);

  const onUploadChange = file => {
    const list = cloneDeep(infoList);
    if (list.length === pageConfig.pageSize) {
      list.pop();
    }
    list.unshift({
      data: '',
      annotation: '',
      empty: true,
    });
    setInfoList(list);
  };
  const handleUploadSuccess = async file => {
    const res = await service.ml.addDatasetData(datasetVid!, {
      data: file.name,
      annotation: null,
      uuid: uploadUUidRes?.data?.upload_uuid,
    });
    if (res.data) {
      handleReset();
    }
  };

  const editRecord = useRef<IInfoItem>({});
  const handleTextChange = e => {
    setIsSaving(true);
    setCurrentItem({ ...currentItem, annotation: e.target.value } as IInfoItem);
    editRecord.current = { ...currentItem, annotation: e.target.value } as IInfoItem;
    const newData = [...infoList];
    const index = newData.findIndex(item => item.id === currentItem?.id);

    newData.splice(index, 1, {
      ...(currentItem as IInfoItem),
      annotation: e.target.value,
    });

    setInfoList(newData);
  };

  // 离开页面前保存当前编辑的数据
  function preventUnload(e: BeforeUnloadEvent) {
    fetch(`/ml/api/v1/ml/dataset/${datasetVid}/annotate`, {
      method: 'POST',
      body: JSON.stringify({ ...editRecord.current }),
      headers: {
        'Content-Type': 'application/json',
        Project_id: String(aiProjectId),
      },
      keepalive: true,
    });
  }

  useEffect(() => {
    window.addEventListener('beforeunload', preventUnload);
    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, []);

  return (
    <Flex className={cx('annotate-image')}>
      <Flex.Item flex={1} className={cx('left')}>
        <Flex justifyBetween alignCenter className={cx('header')}>
          <Flex.Item>
            <EditStatus isSaving={isSaving} navigate={navigate}></EditStatus>
          </Flex.Item>
          <Flex.Item>
            <Tabs
              activeKey={tab}
              items={getAnnotateTabList(
                +detail?.data?.first_version_data_count!,
                +detail?.data?.first_version_annotated_data_count!
              )}
              onChange={tab => {
                setCurrentItem(undefined);
                setTab(tab);
              }}
            />
          </Flex.Item>
          <Flex.Item>
            <UploadDatasetImg
              btnText={'上传图片'}
              btnType={'text'}
              multiple={false}
              showUploadList={false}
              payload={{
                project_id: String(aiProjectId),
                upload_uuid: uploadUUidRes?.data?.upload_uuid,
              }}
              onChange={onUploadChange}
              onSuccess={handleUploadSuccess}
              onError={handleReset}
            />
            <Tooltip
              title={
                <ul style={{ margin: 0, paddingLeft: 20 }}>
                  <li>格式为jpg/png/bmp/jpeg</li>
                  <li>图片大小限制为14M</li>
                  <li>长宽比3: 1以内，其中最长边需要小于4096px，最短边需要大于30px</li>
                </ul>
              }>
              <QuestionCircleOutlined style={{ color: '#4071F9', paddingLeft: 8 }} />
            </Tooltip>
          </Flex.Item>
        </Flex>

        <Divider className={cx('c-divider')} />

        <Spin spinning={getInfoLoading}>
          <div className={cx('content', !infoList.length && 'content-hidden')}>
            <div ref={swiperRef} className={cx('image-list')}>
              <Tooltip title="上一页">
                <div className={cx('to-prev')} onClick={() => handleChangePage('prev')}>
                  <LeftOutlined />
                </div>
              </Tooltip>

              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={pageConfig.gap}
                slidesPerView={pageConfig.pageSize}
                style={{ width: pageConfig.width }}
                freeMode={false}
                watchSlidesProgress={true}
                modules={[FreeMode, Thumbs, Navigation]}
                className={cx('thumb-swiper')}>
                {infoList.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    className={cx('thumb-div', item.id === currentItem?.id && 'thumb-div-selected')}
                    onClick={() => {
                      setCurrentItem(item);
                    }}>
                    {!item?.empty ? (
                      <img className={cx('thumb-img')} src={item.data} alt="" />
                    ) : (
                      <Spin spinning={true}></Spin>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
              <Tooltip title="下一页">
                <div className={cx('to-next')} onClick={() => handleChangePage('next')}>
                  <RightOutlined />
                </div>
              </Tooltip>
            </div>

            <div className={cx('image-preview')} style={{ width: (size?.width || 32) - 32 }}>
              <div className={cx('switch')} onClick={() => handleSwitch('prev')}>
                <LeftOutlined />
              </div>
              <Swiper
                onSwiper={setSwiperInstance}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Thumbs, Navigation]}
                style={{ width: pageConfig.prevWidth }}
                className={`${cx('preview-swiper')} swiper-no-swiping`}>
                {infoList.map((item, index) => (
                  <SwiperSlide key={index} className={cx('c-swiper-item')}>
                    <img src={item.data} className={cx('preview-item')} alt="" />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className={cx('switch')} onClick={() => handleSwitch('next')}>
                <RightOutlined />
              </div>
            </div>
            <Empty className={cx('empty-hidden', !infoList.length && 'empty-preview')} />
          </div>
        </Spin>

        {infoList.length ? (
          <div className={cx('toolbar')}>
            <Popconfirm
              title="确定删除此图片吗？"
              onConfirm={() => {
                deleteItem({ datasetVid, ids: [currentItem?.id] });
              }}
              okText="确定"
              cancelText="取消">
              <Button type="text" danger icon={<Icon name="删除" />} disabled={deleteLoading}>
                删除
              </Button>
            </Popconfirm>
          </div>
        ) : null}
      </Flex.Item>

      <Flex.Item flexBasis={'486px'} className={cx('right')}>
        <div className={cx('title')}>图片标注信息</div>
        <div className={cx('sub-title')}>请描述左侧图片特征（标注语言支持中英文）</div>
        <div>
          <Input.TextArea
            value={currentItem?.annotation}
            onChange={handleTextChange}
            onPressEnter={handleSave}
            onBlur={handleSave}
            maxLength={200}
            disabled={getInfoLoading}
            showCount
            placeholder="请输入标注"
            style={{ height: 240 }}
          />
          <Flex justifyBetween style={{ marginTop: 8, paddingBottom: 16 }}>
            <Button
              type="text"
              className={cx('btn-primary')}
              icon={<EyeOutlined />}
              onClick={() => setIsTemplateVisible(true)}>
              查看示例
            </Button>
            <Button
              type="text"
              className={cx('btn-primary')}
              icon={<RedoOutlined />}
              disabled={!currentItem?.annotation}
              onClick={() => {
                setCurrentItem({ ...currentItem, annotation: '' } as IInfoItem);

                editRecord.current = { ...currentItem, annotation: '' } as IInfoItem;
                const newData = [...infoList];
                const index = newData.findIndex(item => item.id === currentItem?.id);
                newData.splice(index, 1, {
                  ...(currentItem as IInfoItem),
                  annotation: '',
                });
                setInfoList(newData);

                saveAnnotation({ ...currentItem, annotation: '' } as IInfoItem);
              }}>
              清空标注
            </Button>
          </Flex>
        </div>
        <Divider></Divider>
        <div className={cx('count')}>
          第<span className={cx('highlight')}> {getOrder(currentItem?.order!, pagination)} </span>条，共
          <span className={cx('highlight')}> {pagination.total} </span>条
        </div>

        <Flex justifyCenter gap={24}>
          <Button type="default" style={{ width: '45%' }} onClick={() => handleSwitch('prev')}>
            上一条
          </Button>
          <Button type="primary" style={{ width: '45%' }} onClick={() => handleSwitch('next')}>
            下一条
          </Button>
        </Flex>
      </Flex.Item>
      <AnnotateTemp visible={isTemplateVisible} setVisible={setIsTemplateVisible} />
    </Flex>
  );
};

const AnnotateImagePage = () => {
  return (
    <DatasetContainer.Provider>
      <AnnotateImage />
    </DatasetContainer.Provider>
  );
};

export default AnnotateImagePage;

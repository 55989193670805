import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAntdTable, useRequest } from '@fuxi/eevee-hooks';
import { PlusOutlined } from '@ant-design/icons';
import { Card, Flex, Table, message, Divider, Tabs, Input, Button, Tooltip, Select, Space } from '@fuxi/eevee-ui';

import { useAppSelector, useAppDispatch } from '@/hooks';
import { getActiveCapVersions, updateCapActiveVersion } from '@/service/capVersion';
import { resetStateToInitial, INITITAL_STATE } from '@/store/aiTraining';

import service from '../../service';
import EditText from '../../components/EditText';
import { AbilityTagInfo, ProjectInfo } from '../../types/ability';
import { getSorterTableData } from '../../utils/util';
import { projectDetailColumns, getProjectVersionColumns, getModelFileColumns } from './const';
import Descriptions, { DescriptionsItem } from '../../components/Descriptions';
import cx from './index.module.less';
import { AbilityTag } from './components/AbilityTag';

const ProjectDetail: React.FC = () => {
  const urlParams = useParams();
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const [detail, setDetail] = useState<ProjectInfo | null>(null);
  const isExtend = !!detail?.base_capability?.id;
  const [modelNameList, setModelNameList] = useState<string[]>([]);
  const [currentModel, setCurrentModel] = useState<string>('');
  const [searchTag, setSearchTag] = useState('');
  const [currentSearchTag, setCurrentSearchTag] = useState('');
  const [isVersionSelectOpen, setIsVersionSelectOpen] = useState<boolean>(false);
  const [selectedVersionId, setSelectedVersionId] = useState<string>('');
  const [updateActiveVersionLoading, setUpdateActiveVersionLoading] = useState<boolean>(false);
  const projectId = useAppSelector(state => state.project.currentProject.id);
  const [editLoading, setEditLoading] = useState<boolean>(false);

  // 获取当前项目下所有项目能力的应用版本
  const {
    data: activeVersions,
    run: fetchActiveVersions,
    loading: fetchVersionsLoading,
  } = useRequest(getActiveCapVersions, {
    manual: true,
  });

  //获取能力版本列表
  const {
    tableProps,
    refresh,
    data: capVersions,
  } = useAntdTable(
    getSorterTableData(service.ability.getCapabilityVersions, { extraParams: { capability_uuid: urlParams.abilityId } })
  );

  const { data: allCapVersions } = useRequest(() =>
    service.ability.getCapabilityVersions({ capability_uuid: urlParams.abilityId! })
  );

  useEffect(() => {
    if (!!projectId && !!detail) {
      fetchActiveVersions({ projectId });
    }
  }, [projectId, detail]);

  useEffect(() => {
    if (!!activeVersions && !!detail && !!allCapVersions) {
      setSelectedVersionId(getCurrentActiveCapVersion()?.uuid);
    }
  }, [activeVersions, detail, allCapVersions]);

  //获取能力详情
  const { loading: detailLoading, run: fetchDetail } = useRequest(
    () => service.ability.getCapabilityDetails({ capability_uuid: urlParams.abilityId || '' }),
    {
      onSuccess: data => {
        setDetail(data.data);
      },
    }
  );

  //获取模型名称列表
  useRequest(() => service.ability.getModelNameList({ capability_uuid: urlParams.abilityId }), {
    onSuccess: data => {
      setModelNameList(data?.data);
      setCurrentModel(data?.data?.[0]);
    },
  });

  //根据模型名称获取模型版本列表
  const {
    tableProps: modelVersionTableProps,
    refresh: modelVersionRefresh,
    search: { submit: modelVersionSubmit },
  } = useAntdTable(
    getSorterTableData(service.ability.getModelList, {
      extraParams: {
        name: currentModel === '' ? undefined : currentModel,
        yl_tag: searchTag === '' ? undefined : searchTag,
      },
    })
  );

  useEffect(() => {
    modelVersionRefresh();
  }, [currentModel]);

  const handelSave = async (name, val) => {
    try {
      setEditLoading(true);
      await service.ability.editCapability({ capability_uuid: urlParams.abilityId || '', desc: val });
      message.success('保存成功');
      detail && setDetail({ ...detail, description: val });
    } catch (e) {
    } finally {
      setEditLoading(false);
    }
  };

  const onActiveVersionChange = async e => {
    e.stopPropagation();
    e.preventDefault();
    if (!projectId) {
      message.error('projectId 不存在');
      return;
    }
    if (!detail?.uuid || !detail?.name) {
      message.error('能力id或能力名称不存在');
      return;
    }
    // 如果当前生效版本没有变化，则不需要更新
    if (selectedVersionId === getCurrentActiveCapVersion()?.id) {
      setIsVersionSelectOpen(false);
      return;
    }
    const newCapVersionDTO = {
      capId: detail?.uuid,
      capName: detail?.name,
      capVersion: selectedVersionId,
    };
    setUpdateActiveVersionLoading(true);
    await updateCapActiveVersion({ projectId: +projectId, updateProjectCapVersionDTO: newCapVersionDTO });
    setUpdateActiveVersionLoading(false);

    message.success('更新当前生效版本成功');
    fetchActiveVersions({ projectId });
    setIsVersionSelectOpen(false);
  };

  const getCurrentActiveCapVersion = () => {
    const activeVersion = activeVersions?.data?.find(version => version.capId === detail?.uuid);
    const activeVersionInList = allCapVersions?.data?.list?.find(version => version.uuid === activeVersion?.capVersion);
    return activeVersionInList;
  };

  const getCurrentAbilityTag = (data: ProjectInfo) => {
    if (data?.tag === AbilityTagInfo.main.key) {
      return AbilityTagInfo.main;
    }
    if (data?.tag === AbilityTagInfo.dev.key) {
      return AbilityTagInfo.dev;
    }
  };

  const cancelSelect = () => {
    setSelectedVersionId(getCurrentActiveCapVersion()?.uuid);
    setIsVersionSelectOpen(false);
  };

  const onSelectBlur = () => {
    cancelSelect();
  };

  const onMousedownDroprender = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Flex column className={cx['detail-page-wrapper']}>
      <Card bordered={false} title="能力详情" className={cx['detail-head']}>
        <Descriptions
          column={3}
          items={[
            {
              label: '能力名称',
              dataIndex: 'name',
              ellipsis: true,
              render(data) {
                return (
                  <Flex alignCenter className={cx['detail-title']}>
                    <div className={cx['detail-title-name']}>{detail?.name}</div>
                    {data?.tag && (
                      <AbilityTag
                        value={getCurrentAbilityTag(data)?.name || ''}
                        color={getCurrentAbilityTag(data)?.color || '#fff'}
                        borderColor={getCurrentAbilityTag(data)?.borderColor || '#fff'}></AbilityTag>
                    )}
                  </Flex>
                );
              },
            },
            ...projectDetailColumns,
            {
              label: '能力描述',
              dataIndex: 'description',
              render: data => {
                return (
                  <EditText
                    loading={editLoading}
                    // onChange={fetchDetail}
                    value={data?.description}
                    onSave={val => handelSave('desc', val)}
                    maxLength={50}
                  />
                );
              },
            },
          ]}
          data={detail || {}}>
          <DescriptionsItem span={1} label="当前生效版本" className={cx['desc-active-version']}>
            <Select
              onBlur={onSelectBlur}
              onMouseDown={e => e.stopPropagation()}
              onChange={e => {
                setSelectedVersionId(e);
              }}
              value={selectedVersionId}
              open={isVersionSelectOpen}
              onDropdownVisibleChange={open => {
                open && setIsVersionSelectOpen(true);
              }}
              loading={fetchVersionsLoading}
              rootClassName={cx['desc-active-version-select']}
              dropdownRender={menu => (
                <div onMouseDown={onMousedownDroprender}>
                  {menu}
                  <div
                    className={cx['selection-buttons-wrapper']}
                    onBlur={() => {
                      setIsVersionSelectOpen(false);
                    }}>
                    <Space className={cx['active-select-dropdown-wrapper']}>
                      <Button
                        onClick={() => {
                          setSelectedVersionId(getCurrentActiveCapVersion()?.uuid);
                          setIsVersionSelectOpen(false);
                        }}>
                        取消
                      </Button>
                      <Button
                        disabled={!capVersions?.list?.length}
                        id="updateVerionButton"
                        type="primary"
                        onClick={onActiveVersionChange}
                        loading={updateActiveVersionLoading}>
                        确定
                      </Button>
                      {/* </Flex> */}
                    </Space>
                  </div>
                </div>
              )}
              options={allCapVersions?.data?.list?.map(item => ({
                value: item.uuid,
                label: item.code,
              }))}></Select>
          </DescriptionsItem>
        </Descriptions>
      </Card>
      <Divider className={cx['detail-divider']} />
      <div className={cx['detail-body-tab']}>
        <Tabs
          tabBarStyle={{ marginBottom: 24 }}
          items={[
            {
              key: 'AgentVersion',
              label: '能力版本',
              children: (
                <>
                  <Button
                    type="primary"
                    loading={detailLoading}
                    className={cx['create-version-btn']}
                    onClick={async () => {
                      await dispatch(resetStateToInitial(INITITAL_STATE));
                      history(`../version/${urlParams.abilityId}?ability_name=${detail?.name}&version_status=create`);
                    }}>
                    <PlusOutlined />
                    新建版本
                  </Button>
                  <Table
                    columns={getProjectVersionColumns({
                      isExtend,
                      history,
                      refresh,
                      urlParams,
                      abilityName: detail?.name,
                      abilityId: urlParams.abilityId,
                      dataSource: tableProps.dataSource,
                    })}
                    {...tableProps}
                    rowKey="id"
                    pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
                  />
                </>
              ),
            },
            {
              key: 'Model',
              label: '模型',
              children: (
                <>
                  <div className={cx['model-header']}>
                    <div className={cx['model-name-group']}>
                      {modelNameList.map(name => (
                        <Tooltip key={name} title={name}>
                          <div
                            onClick={() => {
                              setCurrentModel(name);
                            }}
                            className={`${cx['model-name-btn']} ${
                              name === currentModel ? cx['model-name-btn-active'] : ''
                            }`}>
                            {name}
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                    <div className={cx['model-header-search']}>
                      <Input
                        onChange={e => setSearchTag(e.target.value)}
                        placeholder="请输入tag"
                        style={{ width: '200px', marginRight: 12 }}
                      />
                      <Button
                        className="search-btn"
                        type="primary"
                        onClick={() => {
                          setCurrentSearchTag(searchTag);
                          modelVersionSubmit();
                        }}>
                        搜索
                      </Button>
                    </div>
                  </div>

                  <Table
                    columns={getModelFileColumns({
                      refresh: modelVersionRefresh,
                      projectId: urlParams.projectId,
                      capName: detail?.name,
                      dataSource: modelVersionTableProps.dataSource,
                    })}
                    {...modelVersionTableProps}
                    pagination={{ ...modelVersionTableProps.pagination }}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </Flex>
  );
};

export default ProjectDetail;

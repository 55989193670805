/* eslint-disable max-lines */
import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import dayjs from 'dayjs';
import {
  ClearOutlined,
  SearchOutlined,
  DownOutlined,
  CheckOutlined,
  CaretRightOutlined,
  InfoCircleFilled,
  ExclamationCircleFilled,
  CloseCircleFilled,
  LoadingOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Input, Dropdown, Menu, Select, Divider, Tooltip, Collapse, DatePicker, Empty } from '@fuxi/eevee-ui';
import { useScroll, useDebounce } from 'ahooks';
import InfiniteScroll from 'react-infinite-scroll-component';

// import { useUpdateEffect } from '@fuxi/eevee-hooks';

import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import { useAppSelector, useAppDispatch } from '@/hooks';
import { initLog, setLog, LogItem, toGetPersonalServiceLogs, toGetHistoryLogs, toGetPublishLogs } from '@/store/log';
import { ServiceStatus } from '@/typings/common';
import { LogType, LogLevel } from '@/constants';
import emptyPng from '@/assets/image/empty2.png';
import HighlightedField from '../../components/HighlightedField.tsx';

import style from './ProjectDetailLogs.module.less';

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const allLogLevels = Object.values(LogLevel);

const LogIconMap: { [key in LogLevel]?: React.ReactElement } = {
  [LogLevel.Info]: <InfoCircleFilled className="info-icon" style={{color: '#36bd1f'}} />,
  [LogLevel.Debug]: <InfoCircleFilled className="debug-icon" style={{color: '#0aaed2'}} />,
  [LogLevel.Error]: <ExclamationCircleFilled className="error-icon" style={{color: '#de5550'}} />,
  [LogLevel.Warning]: <ExclamationCircleFilled className="warn-icon" style={{color: '#d7a72e'}} />,
  [LogLevel.Crit]: <CloseCircleFilled className="critical-icon" style={{color: '#a369ff'}} />,
};

const getSelectedLevelsName = (seletedLevels: LogLevel[]) => {
  if (seletedLevels.length === 1) return seletedLevels[0];
  if (seletedLevels.length === allLogLevels.length) return 'All levels';

  // allLogLevels 是以级别从重到轻的顺序排列，如果所选的 levels 是以 Crit 开头的连续 level，则显示 "XX +"，否则显示 "Mixed"
  const sortedSelectedLevels: LogLevel[] = [];
  allLogLevels.forEach(level => {
    if (seletedLevels.includes(level)) {
      sortedSelectedLevels.push(level);
    }
  });

  const sortedLevelString = sortedSelectedLevels.join();
  if (sortedLevelString.startsWith(`${LogLevel.Crit}`) && allLogLevels.join().includes(sortedLevelString)) {
    return `${sortedSelectedLevels.pop()} +`;
  } else {
    return 'Mixed';
  }
};

const getFilteredLogs = (logs: LogItem[], logLevels: LogLevel[], filterKey?: string, clearLogs = false) => {
  return logs
    .filter(({ level, createdAt, content, traceback = '', permanent }) => {
      const value = `${level} ${createdAt} ${content} ${traceback}`;
      const filteredLog = value.includes(filterKey || '') && logLevels.includes(level);

      if (clearLogs) {
        // 只清除筛选后的日志列表
        return !filteredLog || (filteredLog && !!permanent);
      } else {
        return filteredLog;
      }
    })
    .sort((prev, next) => dayjs(prev.createdAt).valueOf() - dayjs(next.createdAt).valueOf());
};

const Log: React.FC<LogItem & { searchValue?: string }> = ({
  uid,
  content,
  pending,
  createdAt,
  level,
  traceback,
  searchValue = '',
}) => {
  const Content = (
    <>
      [<span className={style(`log-${level.toLowerCase()}`)}>{level}</span>][
      <HighlightedField text={createdAt} field={searchValue} />
      ]：
      <HighlightedField text={content} field={searchValue} />
    </>
  );

  return (
    <div className={style('log-item')} key={uid}>
      {pending ? (
        <>
          <span style={{ marginRight: 6 }}>{LogIconMap[level]}</span>
          <span className={style('log-content')}>{Content}</span>
          <span className={style('dot')}></span>
        </>
      ) : traceback ? (
        <>
          <Collapse bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
            <Panel
              header={
                <>
                  <span className={style('icon-container')}>{LogIconMap[level]}</span>
                  <span className={style('log-content')}>{Content}</span>
                </>
              }
              key={uid}>
              <div className={style('traceback')}>
                <HighlightedField text={traceback} field={searchValue} />
              </div>
            </Panel>
          </Collapse>
        </>
      ) : (
        <>
          <span style={{ marginRight: 6 }}>{LogIconMap[level]}</span>
          <span className={style('log-content')}>{Content}</span>
        </>
      )}
    </div>
  );
};

const FilteredLogs: React.FC<{ logs: LogItem[]; searchValue?: string }> = ({ logs, searchValue }) => {
  return logs.length ? (
    <>
      {logs.map(log => (
        <Log {...log} searchValue={searchValue} key={log.uid} />
      ))}
    </>
  ) : (
    <Empty style={{ marginTop: 150 }} image={emptyPng} />
  );
};

export const Logs: React.FC<{
  type: LogType;
  activeKey: string;
  currentKey: string;
  visible: boolean;
  taskId: string;
  serviceId: string;
  status: string;
  onRef?: any;
}> = ({ type, activeKey, currentKey, visible, taskId, serviceId, status, onRef }) => {
  const dispatch = useAppDispatch();
  const log = useAppSelector(state => state.log);
  const [logLevels, setLogLevels] = useState<LogLevel[]>(allLogLevels);
  const [logLevelDropdownVisible, setLogLevelDropdownVisible] = useState(false);
  const [logType, setLogType] = useState<LogType>(type || LogType.Log);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [filteredLogs, setFilteredLogs] = useState<LogItem[]>([]);
  const [timeBucket, setTimeBucket] = useState<{ start: string; end: string } | undefined>();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const logs = log[serviceId]?.[logType];
  const isCurrentTabActive = currentKey === activeKey;
  const isUserScroll = useRef(false); // 是否被用户滚动到非底部位置
  const wrapperScroll = useScroll(wrapperRef);
  const debouncedSearchValue = useDebounce(searchValue, { wait: 300 });

  const handleVisibleChange = (visible: boolean) => setLogLevelDropdownVisible(visible);

  const handleClickLogLevel = (type: LogLevel) => {
    // 至少选择一种日志类型
    if (logLevels.includes(type) && logLevels.length === 1) return;

    if (!logLevels.includes(type)) {
      return setLogLevels(types => types.concat(type));
    }
    const types = logLevels.filter(i => i !== type);
    setLogLevels(types);
  };

  const filterPermanentLogs = () => {
    const filteredLogs = getFilteredLogs(logs as LogItem[], logLevels, searchValue, true);
    dispatch(setLog({ serviceId, type: logType, data: filteredLogs }));
  };

  const fetchHistoryLog = async (page?: number) => {
    if (timeBucket) {
      dispatch(
        toGetHistoryLogs(serviceId, { namespace: taskId, level: logLevels, page, search: searchValue, ...timeBucket })
      );
    }
  };

  const fetchPublishLogs = async (page?: number) => {
    if (timeBucket) {
      dispatch(toGetPublishLogs(+serviceId, timeBucket?.start, timeBucket?.end));
    } else {
      const gapDays = 1;
      const secondsPerDay = 86400000;
      const currentDate = new Date();
      const daysAgo = currentDate.getTime() - gapDays * secondsPerDay;
      const currentFormat = dayjs().format('YYYY-MM-DD HH:mm:ss');
      const daysAgoFormat = dayjs(daysAgo).format('YYYY-MM-DD HH:mm:ss');
      dispatch(toGetPublishLogs(+serviceId, daysAgoFormat, currentFormat));
    }
  };
  const onTimeBucketChange = (_: any, values: string[]) => {
    const [start, end] = values;

    setTimeBucket({
      start,
      end,
    });
  };

  useImperativeHandle(onRef, () => {
    return { getPublishLogs: fetchPublishLogs };
  });

  useEffect(() => {
    if (
      !wrapperRef.current ||
      !containerRef.current ||
      !visible ||
      !isCurrentTabActive ||
      logType === LogType.HistoryLog
    ) {
      return;
    }
    if (!isUserScroll.current) {
      const containerHeight = containerRef.current.offsetHeight;
      wrapperRef.current.scrollTop = containerHeight;
    }
  }, [filteredLogs, isCurrentTabActive, visible]);

  useEffect(() => {
    if (!wrapperScroll || !wrapperRef.current || !containerRef.current || !visible) return;

    const wrapperScrollTop = wrapperScroll.top;
    const wrapperHeight = wrapperRef.current.clientHeight;
    const containerHeight = containerRef.current.offsetHeight;

    isUserScroll.current = wrapperScrollTop < containerHeight - wrapperHeight;
  }, [wrapperScroll, visible]);

  useEffect(() => {
    if (!logs) {
      dispatch(initLog(serviceId));
    } else {
      const filteredLogs = getFilteredLogs(logs as LogItem[], logLevels, debouncedSearchValue);
      setFilteredLogs(filteredLogs);
    }
  }, [logs, debouncedSearchValue, logLevels]);

  useEffect(() => {
    if (logType === LogType.Log && [ServiceStatus.Running, ServiceStatus.Error].includes(status as ServiceStatus)) {
      dispatch(toGetPersonalServiceLogs(serviceId, taskId));
    }
  }, []);

  useEffect(() => {
    LogType.Log === currentKey && fetchHistoryLog(1);
    LogType.PublishLog === currentKey && fetchPublishLogs(1);
  }, [timeBucket, logLevels, debouncedSearchValue]);

  // 收起抽屉时，不更新日志，避免大量更新实时日志时导致其他操作有卡顿现象
  if (!visible) return null;

  const refreshPublishLog = () => {
    fetchPublishLogs(1);
  };
  return (
    <>
      <div className={style('operation-bar')}>
        <div className={style('clear')}>
          <Tooltip title="清除日志">
            <ClearOutlined onClick={filterPermanentLogs} />
          </Tooltip>
        </div>
        <Divider type="vertical" />
        <Input
          className={style('search-input')}
          allowClear
          placeholder="请输入"
          prefix={<SearchOutlined className={style('search-icon')} />}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />
        <>
          <Dropdown
            overlayClassName={style('log-level-dropdown')}
            visible={logLevelDropdownVisible}
            onVisibleChange={handleVisibleChange}
            overlay={
              <Menu>
                <Menu.Item key="Default" onClick={() => setLogLevels(allLogLevels)}>
                  <div>Default</div>
                </Menu.Item>
                <Menu.Divider />
                {allLogLevels.map(level => (
                  <Menu.Item
                    disabled={logLevels.includes(level) && logLevels.length === 1}
                    key={`${level}`}
                    onClick={() => handleClickLogLevel(level)}>
                    <div>
                      {logLevels.includes(level) && <CheckOutlined className={style('check-icon')} />}
                      <span>{level}</span>
                    </div>
                  </Menu.Item>
                ))}
              </Menu>
            }>
            <div onClick={e => e.preventDefault()}>
              <span className={style('selected-levels')}>{getSelectedLevelsName(logLevels)}</span>
              <DownOutlined />
            </div>
          </Dropdown>
          <Divider type="vertical" />
          {logType === LogType.PublishLog ? (
            <div>
              <RangePicker
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                style={{ width: 420 }}
                allowClear={false}
                onChange={onTimeBucketChange}
                value={timeBucket ? [dayjs(timeBucket.start), dayjs(timeBucket.end)] : undefined}
              />
              <Divider type="vertical" />
              <Tooltip title="同步日志">
                <SyncOutlined onClick={refreshPublishLog} />
              </Tooltip>
            </div>
          ) : (
            <div>
              {logType === LogType.HistoryLog ? (
                <>
                  {/* <RangePicker
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    style={{ width: 320 }}
                    allowClear={false}
                    onChange={onTimeBucketChange}
                    value={timeBucket ? [moment(timeBucket.start), moment(timeBucket.end)] : undefined}
                  /> */}
                  <Divider type="vertical" />
                </>
              ) : null}
              <Select
                className={style('log-type')}
                bordered={false}
                defaultValue={LogType.Log}
                onChange={(value: LogType) => setLogType(value as LogType)}>
                <Option value={LogType.Log}>当前日志</Option>
                <Option value={LogType.HistoryLog}>历史日志</Option>
              </Select>
            </div>
          )}
        </>
      </div>
      <div id="scrollableDiv" className={style('project-detail-logs-wrapper')} ref={wrapperRef}>
        <div className={style('logs-container')} ref={containerRef}>
          {logType === LogType.HistoryLog ? (
            <InfiniteScroll
              dataLength={log[serviceId].historyLog.length}
              next={fetchHistoryLog}
              loader={
                filteredLogs.length ? (
                  <div style={{ textAlign: 'center' }}>
                    <LoadingOutlined />
                  </div>
                ) : null
              }
              hasMore={log[serviceId].historyLog.length < log[serviceId].historyLogCount}
              scrollableTarget="scrollableDiv"
              endMessage={
                <div style={{ textAlign: 'center' }}>
                  {timeBucket ? '全部历史日志已加载' : '选择时间段后可查看对应历史日志'}
                </div>
              }>
              <FilteredLogs logs={filteredLogs} searchValue={debouncedSearchValue} />
            </InfiniteScroll>
          ) : (
            <FilteredLogs logs={filteredLogs} searchValue={debouncedSearchValue} />
          )}
        </div>
      </div>
    </>
  );
};

/* eslint-disable react-func/max-lines-per-function */

import { v4 as uuid } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import { LogLevel } from '@/constants';
import { AppThunk } from '@/store/index';
import { getPublishLogs } from '@/service/log';
import { services } from '@/service/service';

export type PublishLogItem = {
  uid: string;
  // 日志内容
  content: string;
  // 日志时间
  createdAt: string;
  // 日志类型
  level: LogLevel;
  // 日志堆栈
  traceback?: string;
  // key
  key?: string;
};

const getFormattedLogs = (data: Partial<PublishLogItem>[] | Partial<PublishLogItem>): PublishLogItem[] => {
  const logs = ([] as Partial<PublishLogItem>[]).concat(data);

  return logs.map(log => {
    const data = { ...log };
    const { uid, level, createdAt } = data;
    data.uid = uid || uuid();
    data.level = level || LogLevel.Info;
    data.createdAt = createdAt || moment().format('YYYY-MM-DD HH:mm:ss');

    return data as PublishLogItem;
  });
};

export interface PublishLogs {
  serviceId: number;
  logId: number;
  startTime: string;
  endTime: string;
  page?: number;
  size?: number;
}

const initialState: {
  logs: PublishLogItem[];
} = {
  logs: [],
};

const terminal = createSlice({
  name: 'publishLog',
  initialState,
  reducers: {
    setPublishLog(state, { payload }: PayloadAction<{ page: number; data: Partial<PublishLogItem>[] }>) {
      const { data, page } = payload;
      const logs = getFormattedLogs(data as Partial<PublishLogItem>[]);
      page === 1 ? (state.logs = logs) : (state.logs = state.logs.concat(logs));
    },
  },
});

export const { setPublishLog } = terminal.actions;

export const toGetPublishLogs =
  (serviceId: string): AppThunk =>
  async (dispatch, getState) => {
    const releaseInfo = await services.getLatestReleaseByServiceId(serviceId);
    const lastPublishTime = releaseInfo?.latestReleaseByServiceId?.createdTime;
    const startTime = moment(lastPublishTime).format('YYYY-MM-DD HH:mm:ss');
    const endTime = moment().format('YYYY-MM-DD HH:mm:ss');

    const maxSize = 1000;
    let page = 1;

    const res = await getPublishLogs(
      JSON.stringify({
        serviceId,
        startTime,
        endTime,
        logId: 0,
        page,
        size: maxSize,
      })
    );

    dispatch(
      setPublishLog({
        page,
        data: res.publishLog.map(
          log =>
            ({
              uid: log.id + '',
              content: log.msg,
              createdAt: log.dt,
              level: log.level,
              serviceId: log.serviceId,
            } as PublishLogItem)
        ),
      })
    );
  };

export default terminal.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UserModelState = {
  name: string;
  fullName?: string;
  email: string;
  permissions?: string[];
  roles: string[];
  feedbackModalNonce: number;
  isInner: boolean; // 是否为内部用户
};

const initialState: UserModelState = {
  name: '',
  fullName: '',
  email: '',
  permissions: [],
  roles: [],
  feedbackModalNonce: 0,
  isInner: true,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, { payload }: PayloadAction<User>) {
      state.email = payload.email;
      state.name = payload.name;
      state.fullName = payload.fullName;
      state.permissions = payload.permissions;
      state.roles = payload.roles;
    },
    setFeedbackModalNonce(state, { payload }: PayloadAction<number>) {
      state.feedbackModalNonce = payload;
    },
    setIsInner(state, { payload }: PayloadAction<boolean>) {
      state.isInner = payload;
    },
  },
});

export const { setUserInfo, setFeedbackModalNonce, setIsInner } = user.actions;

export default user.reducer;

export const env = window['EEVEE_ENV'];
export const REALTIME_LOGS_DISCONNECT_TIME = 'EEVEE_ENV_REALTIME_LOGS_DISCONNECT_TIME';

export const getRealtimeLogsDisconnectTime = () => {
  return +window[REALTIME_LOGS_DISCONNECT_TIME];
};

// 获取环境变量
export enum Env {
  Dev = 'dev',
  Qa = 'qa',
  DDlQa = 'ddl-qa',
  Pre = 'pre',
  Prod = 'prod',
  DdlQa = 'ddl-qa',
  Qa2 = 'qa-2',
}

export const getEnv = () => {
  switch (env) {
    case Env.Dev:
      return Env.Dev;
    case Env.DDlQa:
      return Env.DDlQa;
    case Env.Qa:
      return Env.Qa;
    case Env.Pre:
      return Env.Pre;
    case Env.Prod:
      return Env.Prod;
    default:
      return Env.Dev;
  }
};

export const getShuyuanEnv = () => {
  const env = window['EEVEE_ENV'];
  switch (env) {
    case Env.Dev:
      return 'dev';
    case Env.Qa2:
    case Env.DdlQa:
    case Env.Qa:
      return 'test';
    case Env.Prod:
      return 'prod';
    default:
      return 'dev';
  }
}


export const isDev = () => {
  return getEnv() === Env.Dev;
};

export const isQa = () => {
  return getEnv() === Env.Qa;
};

export const isProd = () => {
  return getEnv() === Env.Prod;
};

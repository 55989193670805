import { useEffect, useState } from 'react';
import { OperationVariables, QueryLazyOptions, useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { ProjectStatus } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import createContainer from '@/hooks/createContainer';
import { setCurrentProject, setShowDeleteModal } from '@/store/project';
import {
  GET_PROJECTS,
  GET_PROJECT_USERS,
  GET_PROJECT,
  PROJECT_HAS_CLIENT_SAMPLE,
} from '@/service/schema/project/project-queries';
import { DELETE_PROJECT, UPDATE_PROJECT, UPDATE_PROJECT_USERS } from '@/service/schema/project/project-mutations';
import { GET_SERVICES } from '@/service/schema/service/service-queries';
import { PAUSE_SERVICE, START_SERVICE } from '@/service/schema/service/service-mutation';
import { GET_TERMINALS } from '@/service/schema/terminal/terminal-queries';
import { GET_IMAGES } from '@/service/schema/image/image-queries';
import { virtualTerminalApi } from '@/service/virtualTerminal';
import { getProjectType } from '@/service/project';
import { VirtualMachineImage } from '@/store/image';
import { isTemplateProject } from '../utils';
import { ProjectRole } from './constants';

const useProjectDetailState = () => {
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector(state => state.project.currentProject);
  const showEditModal = useAppSelector(state => state.project.showEditModal);
  const showDeleteModal = useAppSelector(state => state.project.showDeleteModal);
  const userInfo = useAppSelector(state => state.user);
  const isCurrentProjectMaintainer = currentProject.id
    ? userInfo.roles.includes(`${currentProject.id}-${ProjectRole.Maintainer}`)
    : false;

  const isTpl = isTemplateProject(currentProject);

  const [virtualImages, setVirtualImages] = useState<VirtualMachineImage[]>([]);

  const [projectType, setProjectType] = useState<'INNER' | 'OUTER'>('INNER');

  const [getServicesData, { loading: serviceListLoading, data: serviceData }] = useLazyQuery(GET_SERVICES);

  const { items: serviceList = [] } = serviceData?.services || {};

  // 占位 并且告诉postDelete有GET_PROJECTS方法 不然删除后不会刷新缓存
  useQuery(GET_PROJECTS, { fetchPolicy: 'cache-first' });

  // 编辑项目
  const [postEditProject, { data: postEditData, loading: editLoading }] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [GET_PROJECTS],
  });

  // 获取项目信息
  const [fetchProjectInfoGql, { data: projectInfo, loading: fetchProjectInfoLoading }] = useLazyQuery(GET_PROJECT);

  // 项目是否存在示例客户端
  const [fetchProjectHasClientSample, { data: projectHasClientSample }] = useLazyQuery<{
    projectHasClientSample: boolean;
  }>(PROJECT_HAS_CLIENT_SAMPLE);

  const fetchProjectInfo = (options?: QueryLazyOptions<OperationVariables>) => {
    fetchProjectInfoGql(options);
    fetchProjectHasClientSample(options);
  };

  // 获取终端信息
  const [fetchTerminalInfo, { data: terminalsData, loading: fetchTerminalInfoLoading }] = useLazyQuery(GET_TERMINALS);

  // 获取边端自定义镜像信息
  const [fetchImageInfo, { data: imagesData }] = useLazyQuery(GET_IMAGES);

  // 修改项目成员
  const [postUpdateUsers, { loading: addMemeberLoading }] = useMutation(UPDATE_PROJECT_USERS, {
    refetchQueries: [GET_PROJECT_USERS],
  });

  // 获取项目成员
  const [getProjectUsers, { data: projectUsersData }] = useLazyQuery(GET_PROJECT_USERS);

  const projectUsers: User[] = projectUsersData?.project?.users || [];

  // 删除项目
  const [postDelete, { loading: deleteLoading }] = useMutation(DELETE_PROJECT, { refetchQueries: [GET_PROJECTS] });

  // 启动项目
  const [startService] = useMutation(START_SERVICE, { refetchQueries: [GET_SERVICES] });

  // 暂停项目
  const [pauseService] = useMutation(PAUSE_SERVICE, { refetchQueries: [GET_SERVICES] });

  const fetchVirtualImages = () => {
    virtualTerminalApi.getImages(currentProject.id + '').then(res => {
      setVirtualImages(res.data.data);
    });
  };

  const getProjectTypeInfo = () => {
    getProjectType(currentProject.id + '').then(res => {
      setProjectType(res.data);
    });
  };

  useEffect(() => {
    if (currentProject.id && currentProject.status !== ProjectStatus.Creating) {
      getServicesData({ variables: { projectId: +currentProject.id } });
      fetchVirtualImages();
      getProjectTypeInfo();
    }
  }, [currentProject]);

  useEffect(() => {
    if (postEditData?.updateProject) {
      dispatchSetProject(postEditData?.updateProject);
    }
  }, [postEditData?.updateProject]);

  const setShowEdit = (visible: boolean) => {
    dispatch({
      type: 'project/setShowEditModal',
      payload: visible,
    });
  };

  const setShowDelete = (visible: boolean) => {
    dispatch(setShowDeleteModal(visible));
  };

  const dispatchSetProject = (payload: Project) => {
    dispatch(setCurrentProject(payload));
  };

  return {
    projectType,
    showEditModal,
    showDeleteModal,
    currentProject,
    serviceList: serviceList as Service[],
    serviceListLoading,
    editLoading,
    deleteLoading,
    userInfo,
    projectUsers,
    projectUsersData,
    addMemeberLoading,
    projectInfo,
    fetchProjectInfoLoading,
    isTpl,
    isCurrentProjectMaintainer,
    fetchTerminalInfoLoading,
    terminalsData,
    imagesData,
    virtualImages,
    projectHasClientSample: projectHasClientSample?.projectHasClientSample,
    fetchProjectHasClientSample,
    fetchProjectInfo,
    getProjectUsers,
    postEditProject,
    postUpdateUsers,
    setShowEdit,
    setShowDelete,
    dispatchSetProject,
    postDelete,
    startService,
    pauseService,
    getServicesData,
    fetchImageInfo,
    fetchTerminalInfo,
    fetchVirtualImages,
    getProjectTypeInfo,
  };
};

const ProjectDetailContainer = createContainer(useProjectDetailState);

export default ProjectDetailContainer;

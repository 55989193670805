/* eslint-disable max-depth */
import { useEffect, useState } from 'react';
import { Button, Flex, Form, Spin, Tooltip } from '@fuxi/eevee-ui';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Select, message } from 'antd';
import { useRequest } from '@fuxi/eevee-hooks';
import { OnlineServiceDetail } from '@ai-training/types/onlineService';
import ml, { mlModalService } from '@/service/ml';
import { useAppSelector } from '@/hooks';
import { getCurrentUrl } from '../utils/getCurrentUrl';
import cx from './index.module.less';

export const MlTransfter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [initialLocationUrl, setInitialLocationUrl] = useState<string>(`${location.pathname}${location.search}`);
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId)!;
  const [bindLoading, setBindLoading] = useState<boolean>(false);
  const { getMlPublicCapabilities, getCapabilityVersions, appList } = ml;
  const { publicServeBind, appList: allModalServices } = mlModalService;
  const { versionId, publicAbilityId } = useParams();

  const {
    data: publicCaps,
    loading: loadingCaps,
    run: fetchPublicAbilities,
  } = useRequest(
    () =>
      getMlPublicCapabilities({
        share: true,
      }),
    {
      manual: true,
    }
  );

  const {
    data: allServices,
    run: fetchAllServices,
    loading: fetchAllServicesLoading,
  } = useRequest(() => allModalServices({ ai_project_id: aiProjectId }), {
    manual: true,
  });

  useEffect(() => {
    if (!aiProjectId) return;
    fetchPublicAbilities();
    fetchVersions();
    fetchAllServices();
    setInitialLocationUrl(`${location.pathname}${location.search}`);
  }, [aiProjectId, publicAbilityId]);

  const {
    data: versions,
    run: fetchVersions,
    loading: loadingVersions,
  } = useRequest((id: string = publicAbilityId!) => getCapabilityVersions({ id }), {
    manual: true,
  });

  const [serviceDetail, setServiceDetail] = useState<OnlineServiceDetail>();

  const { run: fetchVersionDetail, loading: loadingDetail } = useRequest(
    ({ abilityId = publicAbilityId, id = versionId }) =>
      appList({ capability_id: abilityId, capability_version_id: id }),
    {
      onSuccess: ({ data }) => {
        setServiceDetail(data?.list?.[0] || {});
      },
    }
  );

  useEffect(() => {
    if (!aiProjectId) {
      return;
    }
    const defaultCapId = publicAbilityId;
    const defaultVersionId = versionId;

    if (publicAbilityId && versionId) {
      fetchVersionDetail({
        abilityId: defaultCapId,
        id: defaultVersionId,
      });
    }

    form.setFieldsValue({
      baseCap: defaultCapId,
      version: defaultVersionId,
    });
  }, [aiProjectId]);

  const [form] = Form.useForm();

  const onBaseCapChange = (baseCapId: string) => {
    form.setFieldsValue({
      version: undefined,
    });
    setServiceDetail(undefined);
    fetchVersions(baseCapId);
  };

  const onVersionChange = (versionId: string) => {
    const { baseCap, version } = form.getFieldsValue();
    fetchVersionDetail({
      abilityId: baseCap,
      id: version,
    });
  };

  const handelSave = async () => {
    setBindLoading(true);

    // 查看是该公共服务是否已经绑定
    const isBinded = allServices?.data?.list?.find(item => item?.id === serviceDetail?.id);
    if (isBinded) {
      message.error('该公共能力已绑定服务');
      setBindLoading(false);
      return;
    }
    try {
      const data = await publicServeBind({
        id: serviceDetail?.id,
      });
      if (data?.data?.data === true) {
        message.success('调用成功');
        if (initialLocationUrl !== getCurrentUrl() || !initialLocationUrl.includes('/transfer')) {
          return;
        }
        if (!!publicAbilityId && !!versionId) {
          navigate(`../../../../../model-service/${serviceDetail?.id}`);
        } else {
          navigate(`../../../../model-service/${serviceDetail?.id}`);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBindLoading(false);
    }
  };

  const cancel = () => {
    if (!!publicAbilityId && !!versionId) {
      navigate('../../');
    } else {
      navigate('../');
    }
  };

  return (
    <Spin spinning={loadingCaps || loadingVersions || loadingDetail || fetchAllServicesLoading} tip="加载中...">
      <Flex alignCenter justifyCenter>
        <Form labelAlign="right" labelCol={{ span: 5 }} form={form} className={cx('transfer-form')}>
          <Form.Item
            label="基础能力"
            name="baseCap"
            required
            rules={[
              {
                required: true,
                message: '请选择基础能力',
              },
            ]}>
            <Select
              listHeight={160}
              onChange={value => onBaseCapChange(value)}
              options={publicCaps?.data?.list?.map(item => ({ label: item.name, value: item.uuid }))}></Select>
          </Form.Item>

          <Form.Item
            label="版本"
            name="version"
            required
            rules={[
              {
                required: true,
                message: '请选择版本',
              },
            ]}>
            <Select
              placeholder="请输入"
              listHeight={160}
              onChange={versionId => onVersionChange(versionId)}
              options={versions?.data?.list?.map(item => ({
                label: item.code,
                value: item.uuid,
                desc: item.description,
              }))}
              optionRender={option => (
                <Flex justifyBetween>
                  <div>{option.data.label}</div>
                  <Tooltip className={cx('desc-in-selector')} placement="topLeft" title={option.data.desc}>
                    {option.data.desc}
                  </Tooltip>
                </Flex>
              )}></Select>
          </Form.Item>

          <Form.Item label="服务名称" name="name">
            <div>{serviceDetail?.name || '-'}</div>
          </Form.Item>
          <Form.Item label="接口地址" name="desc">
            <div>{serviceDetail?.domain || '-'}</div>
          </Form.Item>

          <Flex justifyStart gapSM className={cx('buttons-wrapper')}>
            <Button onClick={cancel}>取消</Button>
            <Button
              disabled={!(serviceDetail?.domain && serviceDetail?.name)}
              type="primary"
              onClick={handelSave}
              loading={bindLoading}>
              确定
            </Button>
          </Flex>
        </Form>
      </Flex>
    </Spin>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getSSHKey } from '@/service/ssh';
import { AppThunk } from '.';

const initialState: {
  sshKeys: SSHKey[];
} = {
  sshKeys: [],
};

const ssh = createSlice({
  name: 'ssh',
  initialState: initialState,
  reducers: {
    setSSHInfo(state, { payload }: PayloadAction<SSHKey[]>) {
      state.sshKeys = payload;
    },
    updateSSHInfo(state, { payload }: PayloadAction<Partial<SSHKey>>) {
      state.sshKeys.some(sshKey => {
        if (sshKey.id === payload.id) {
          Object.assign(sshKey, payload);
          return true;
        }
      });
    },
    removeSSHInfo(state, { payload }: PayloadAction<number>) {
      state.sshKeys.some((sshKey, index) => {
        if (sshKey.id === payload) {
          state.sshKeys.splice(index, 1);
        }
      });
    },
  },
});

export const { setSSHInfo, updateSSHInfo, removeSSHInfo } = ssh.actions;

export const toGetSSHInfo = (): AppThunk => dispatch => {
  getSSHKey().then(res => {
    if (res.status === 200) {
      dispatch(dispatch(setSSHInfo(res.data || [])));
    }
  });
};

export default ssh.reducer;

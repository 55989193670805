import { FC, ReactNode, useState } from 'react';
import { Modal, Button, Typography, Popconfirm, Form, Input, message } from '@fuxi/eevee-ui';
import { PlusOutlined, KeyOutlined, DeleteOutlined, CopyOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useAntModal } from '@fuxi/eevee-hooks';
import dayjs from 'dayjs';
import CopyToClipboard from 'react-copy-to-clipboard';
import sshpk from 'sshpk';

import { addSSHKey, updateSSHKey, removeSSHKey } from '@/service/ssh';
import { useAppSelector, useAppDispatch } from '@/hooks';
import { updateSSHInfo, removeSSHInfo, toGetSSHInfo } from '@/store/ssh';

import AddSSHModal from './AddSSHModal';

import style from './index.module.less';

const { TextArea } = Input;
const { Paragraph } = Typography;

const SSHKey: FC<{ children: ReactNode }> = ({ children }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const sshKeys = useAppSelector(state => state.ssh.sshKeys);
  const { showModal, closeModal, commonAntModalProps } = useAntModal();
  // const [addSSHModalVisible, setAddSSHModalVisible] = useState(false);
  const hasSSHKey = !!sshKeys.length;

  // function showAddSSHModal() {
  //   closeModal();
  //   setAddSSHModalVisible(true);
  // }

  // function closeAddSSHModal() {
  //   setAddSSHModalVisible(false);
  // }

  async function toAddSSHKey() {
    const data = await form.validateFields();

    addSSHKey(data).then(() => {
      message.success('公钥添加成功');
      closeModal();
      dispatch(toGetSSHInfo());
    });
  }

  function onValuesChange(changedValues, allValues) {
    if (changedValues.sshPubKey && !allValues.sshPubKeyTitle) {
      try {
        const data = sshpk.parseKey(changedValues.sshPubKey, 'ssh');
        form.setFieldValue('sshPubKeyTitle', data.comment);
        form.validateFields(['sshPubKeyTitle']);
      } catch {}
    }
  }

  function onSSHTitleChange(sshPubKeyTitle: string, sshKey: SSHKey) {
    if (sshPubKeyTitle === sshKey.sshPubKeyTitle) return;
    if (!sshPubKeyTitle) {
      return message.warning('公钥标题不能为空');
    }
    if (sshPubKeyTitle.length > 64) {
      return message.warning('公钥标题字符数不能超过64');
    }

    updateSSHKey({
      id: sshKey.id,
      sshPubKeyTitle,
      sshPubKey: sshKey.sshPubKey,
    }).then(() => {
      message.success('公钥标题修改成功');
      dispatch(
        updateSSHInfo({
          ...sshKey,
          sshPubKeyTitle,
        })
      );
    });
  }

  function onRemoveSSHKey(id: number) {
    removeSSHKey(id).then(() => {
      message.success('公钥删除成功');
      dispatch(removeSSHInfo(id));
    });
  }

  return (
    <>
      <div onClick={showModal}>{children}</div>
      <Modal
        title="SSH公钥"
        destroyOnClose
        {...commonAntModalProps}
        onOk={toAddSSHKey}
        footer={hasSSHKey ? null : undefined}
        wrapClassName={style('ssh-modal')}
        bodyStyle={{ paddingBottom: 0 }}>
        <div className={style('ssh-modal-content')}>
          <div className={style('step-container')}>
            <div className={style('title')}>怎么生成公钥？以ssh-rsa为例：</div>
            <div className={style('step')}>step1： ssh-keygen -t rsa</div>
            <div className={style('step')}>step2： cat ~/.ssh/id_rsa.pub</div>
          </div>
          {hasSSHKey ? (
            <>
              {sshKeys.map(sshKey => {
                const { sshPubKeyTitle, sshPubKey, createTime, id } = sshKey;
                return (
                  <div className={style('ssh-key')} key={id}>
                    <Paragraph
                      className={style('title')}
                      editable={{
                        maxLength: 64,
                        onChange: title => onSSHTitleChange(title, sshKey),
                      }}>
                      {sshPubKeyTitle}
                    </Paragraph>
                    <Paragraph className={style('key')} ellipsis>
                      <KeyOutlined className={style('key-icon')} /> {sshPubKey}
                    </Paragraph>
                    <Paragraph className={style('time')}>
                      {dayjs(createTime).format('YYYY-MM-DD HH:mm:ss')} 创建
                    </Paragraph>
                    <div className={style('operation')}>
                      <CopyToClipboard text={sshPubKey} onCopy={() => message.success('复制成功')}>
                        <CopyOutlined />
                      </CopyToClipboard>
                      <Popconfirm
                        title="确定删除该公钥吗？"
                        onConfirm={() => onRemoveSSHKey(id)}
                        icon={<ExclamationCircleFilled style={{ color: 'rgba(229, 134, 22, 1)' }} />}>
                        <DeleteOutlined />
                      </Popconfirm>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            // <Button className={style('add-ssh-btn')} type="dashed" block onClick={showAddSSHModal}>
            //   <PlusOutlined />
            //   添加公钥
            // </Button>
            <Form form={form} preserve={false} labelCol={{ span: 3 }} onValuesChange={onValuesChange}>
              <Form.Item
                label="公钥"
                name="sshPubKey"
                validateFirst
                rules={[
                  {
                    required: true,
                    message: '必填，请输入公钥',
                  },
                  {
                    validator(_, value) {
                      try {
                        const data = sshpk.parseKey(value, 'ssh');
                        if (data.comment.length > 64) {
                          return Promise.reject(new Error('公钥 comment 长度不能超过 64 个字符'));
                        } else {
                          return Promise.resolve();
                        }
                      } catch (err) {
                        return Promise.reject(new Error('公钥格式不正确'));
                      }
                    },
                  },
                ]}>
                <TextArea rows={8} placeholder="请输入以 ssh-rsa 开头的公钥" />
              </Form.Item>
              <Form.Item
                label="标题"
                name="sshPubKeyTitle"
                rules={[
                  {
                    required: true,
                    message: '必填，请输入标题',
                  },
                  {
                    max: 64,
                    message: '标题字符数不能超过64',
                  },
                ]}>
                <Input placeholder="示例：MacBook key" maxLength={64} showCount />
              </Form.Item>
            </Form>
          )}
        </div>
      </Modal>
      {/* <AddSSHModal visible={addSSHModalVisible} closeAddSSHModal={closeAddSSHModal} /> */}
    </>
  );
};

export default SSHKey;

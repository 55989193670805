import { gql } from '@apollo/client';
import { PROJECT_FRAGMENT } from '@/service/schema/project/project-queries';

export const USE_TEMPLATE = gql`
  mutation UseTemplate($input: UseTemplateInput) {
    useTemplate(input: $input) {
      ...ProjectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

// 更新模板描述
export const UPDATE_TPL = gql`
  mutation UpdateTemplate($input: UpdateTemplateInput) {
    updateTemplate(input: $input) {
      id
      name
    }
  }
`;

// 上架模板
export const ONLINE_TPL = gql`
  mutation OnlineTemplate($templateId: ID!) {
    onlineTemplate(templateId: $templateId)
  }
`;

// 下架模板
export const OFFLINE_TPL = gql`
  mutation OfflineTemplate($templateId: ID!) {
    offlineTemplate(templateId: $templateId)
  }
`;

// 发布模板
export const PUBLISH_TPL = gql`
  mutation PublishTemplate($input: PublishTemplateInput) {
    publishTemplate(input: $input)
  }
`;

import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@fuxi/eevee-ui';

import Img403 from '../../assets/image/403.png';
import cx from './index.module.less';

const ForbiddenPage: React.FC = () => {
  return (
    <div>
      <div className={cx('imgBlock')}>
        <img src={Img403} alt="403" />
      </div>
      <div className={cx('exception_notice')}>
        <span className={cx('exception_title')}>403</span>
        <span className={cx('exception_description')}>抱歉，你无权访问该页面...</span>
        <Link to="/home">
          <Button type="primary" className={cx('exception_return_btn')}>
            返回首页
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ForbiddenPage;

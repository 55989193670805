export enum ReviewStatus {
  REVIEW_STATUS_UNKNOWN = 'REVIEW_STATUS_UNKNOWN', //未保存
  STAGING = 'STAGING', //暂存
  SUBMIT = 'SUBMIT', //提交
  APPRAISED = 'APPRAISED', //确定
}

export const ReviewStatusLabels: {
  [key in ReviewStatus]: string;
} = {
  [ReviewStatus.REVIEW_STATUS_UNKNOWN]: '未保存',
  [ReviewStatus.STAGING]: '暂存',
  [ReviewStatus.SUBMIT]: '已提交',
  [ReviewStatus.APPRAISED]: '已发布',
};

export const stageTrans: {
  [name in Stage]: string;
} = {
  DEMO: 'DEMO评审',
  MID: '中期评审',
  FINAL: '项目终审',
};

export const stageOrderKey = {
  DEMO: 'demoStageOrder',
  MID: 'midStageOrder',
  FINAL: 'finalStageOrder',
};

export enum Stage {
  DEMO = 'DEMO',
  MID = 'MID',
  FINAL = 'FINAL',
}

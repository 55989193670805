import { Select, Input, Row, Typography } from '@fuxi/eevee-ui';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.less';

const cx = classNames.bind(styles);
const { Text } = Typography;

export interface EnvValue {
  name?: string;
  value?: string;
}

interface ResourceConfigProps {
  value?: EnvValue;
  isFirst?: boolean;
  isLast?: boolean;
  onChange?: (value: EnvValue) => void;
  children?: React.ReactNode;
}

const EnvSetup: React.FC<ResourceConfigProps> = ({
  value: groupValue = {},
  onChange,
  children = null,
  isFirst = false,
  isLast = false,
}) => {
  const [value, setValue] = useState<string>('');
  const [name, setName] = useState<string>('');

  const triggerChange = (changedValue: { name?: string; value?: string }) => {
    onChange?.({ name, value, ...groupValue, ...changedValue });
  };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newKey = e.target.value;
    setName(newKey);
    triggerChange({ name: newKey });
  };

  const onNameBlur = () => {
    triggerChange({ name: name || groupValue.name });
  };

  const onValueBlur = () => {
    triggerChange({ value: value || groupValue.value });
  };

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    triggerChange({ value: newValue });
  };

  return (
    <div className={cx('single-env-config', { 'first-single-env': isFirst, 'last-single-env': isLast })}>
      <Input
        type="text"
        placeholder="请输入key"
        value={groupValue.name || name}
        onChange={onNameChange}
        onBlur={onNameBlur} // 新增 onBlur 事件
        style={{ width: 200, height: 32 }}
      />
      <Text className={cx('equal')}>&nbsp;=&nbsp;</Text>
      <Input
        type="text"
        placeholder="请输入value"
        value={groupValue.value || value}
        onChange={onValueChange}
        onBlur={onValueBlur} // 新增 onBlur 事件
        style={{ width: 200, height: 32 }}
      />
      {children}
    </div>
  );
};

export default EnvSetup;

import { Icon, Spin } from '@fuxi/eevee-ui';
import { CheckCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { NavigateFunction } from 'react-router-dom';
import cx from './index.module.less';

export const EditStatus = ({ isSaving, navigate }: { isSaving: boolean; navigate: NavigateFunction }) => {
  return (
    <span className={cx('autosave')}>
      <LeftOutlined style={{ fontSize: 16, marginRight: 6 }} onClick={() => navigate(-1)} />
      <span className={cx('title')}>标注数据</span>
      {isSaving ? (
        <>
          <Spin indicator={<Icon name="loading1" style={{ fontSize: 18 }} />} />
          <span className={cx('indicator')}>保存中</span>
        </>
      ) : (
        <>
          <CheckCircleOutlined className={cx('icon-success')} />
          <span className={cx('indicator')}>已实时保存</span>
        </>
      )}
    </span>
  );
};

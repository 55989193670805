import { TaskStatusType } from '../types/trainingTask';

export const taskStatusMap = {
  [TaskStatusType.CREATE]: {
    label: '创建中',
    color: '#4071F9',
  },
  [TaskStatusType.INIT]: {
    label: '创建中',
    color: '#4071F9',
  },
  [TaskStatusType.RUNNING]: {
    label: '进行中',
    color: '#36BD1F',
  },
  [TaskStatusType.COMPLETED]: {
    label: '完成',
    color: 'rgba(255, 255, 255, 0.30)',
  },
  [TaskStatusType.ERROR]: {
    label: '异常',
    color: '#DE5550',
  },
  [TaskStatusType.STOP_FINISH]: {
    label: '已停止',
    color: '#FF9500',
  },
};

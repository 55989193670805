import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, message } from '@fuxi/eevee-ui';
import { LeftOutlined, UploadOutlined } from '@ant-design/icons';

import ReactEcharts from 'echarts-for-react';
import { utils, writeFileXLSX } from 'xlsx';
import moment from 'moment';
import { dropRight, remove } from 'lodash';
import { Stage, stageTrans } from '@/constants/miniReviews';
import { ProjectSummary, projectSummaryArrayKeys, projectSummaryAverageKeys } from '../miniReview/utils';
import IssueCard from './components/issueCard/issueCard';

import { setOptionsBySummary, setPieOptionBySummary } from './utils';

import styles from './resultSummary.module.less';

type Props = {};

export default function ResultSummary(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const summaryInfo = location.state as ProjectSummary;
  const stage = summaryInfo?.stage;
  const isFinalStage = stage === Stage.FINAL;
  if (!summaryInfo) {
    navigate('');
    return <></>;
  }

  const options = setOptionsBySummary(summaryInfo);
  const pieOption = setPieOptionBySummary(summaryInfo);
  const goBack = () => {
    navigate(`${dropRight(location.pathname.split('/')).join('/')}`, { state: { tab: '结果汇总' } });
  };

  // eslint-disable-next-line react-func/max-lines-per-function
  const toExportExcel = () => {
    try {
      const wb = utils.book_new();
      const header = [
        'id',
        'judgeName',
        'startTime',
        'endTime',
        'consuming',
        'experience',
        'innovation',
        'artisticExpression',
        'effect',
        'difficulty',
        'prospect',
        'ai',
        'score',
        'isRecommend',
        'issue',
      ];

      const headerDisplay: Record<string, string> = {
        id: '序号',
        judgeName: '评委',
        startTime: '开始评审时间',
        endTime: '结束评审时间',
        consuming: '评审时长（秒）',
        experience: '核心体验',
        innovation: '创新性',
        artisticExpression: '艺术表现力',
        effect: '技术实现效果',
        difficulty: '上手难易度',
        prospect: '商业化前景',
        ai: 'AI工具使用及效果',
        score: '整体评分',
        isRecommend: '是否推荐上线',
        issue: '评价',
      };

      if (!isFinalStage) {
        remove(header, key => key === 'isRecommend');
        delete headerDisplay.isRecommend;
      }

      const xlsxName = `${stageTrans[stage as Stage]}-${summaryInfo.projectName}-评审记录`;

      const sheet: { data: Record<string, number | string>[]; name: string } = {
        data: [
          headerDisplay,
          // 由于要控制顺序 就不用数组遍历生成了
          ...new Array(summaryInfo.commitTotal).fill('').map((_, index) => {
            const sheetData: Record<string, string | number> = {
              id: index,
              consuming: moment(summaryInfo.endTimeArray[index]).diff(
                moment(summaryInfo.startTimeArray[index]),
                'seconds'
              ),
            };
            projectSummaryArrayKeys.forEach(key => {
              if (!isFinalStage && key[1] === 'isRecommend') {
                return;
              }
              const value = summaryInfo[key[0]]?.[index] ?? '';
              sheetData[key[1]] = typeof value === 'boolean' ? (value ? '是' : '否') : value;
            });
            return sheetData;
          }),
          {
            // 空行
          },
        ],
        name: xlsxName,
      };

      const avgSheetLine: Record<string, number | string> = {
        id: '平均值',
      };

      const minSheetLine: Record<string, number | string> = {
        id: '最小值',
      };

      const maxSheetLine: Record<string, number | string> = {
        id: '最大值',
      };

      projectSummaryArrayKeys.forEach(key => {
        const avgKeyInSheet = key[1];
        const arrKeyInSheet = key[0];
        const avgKeyInSummary = projectSummaryAverageKeys.find(avgKey => avgKey[0] === key[0])?.[1];
        if (avgKeyInSummary) {
          minSheetLine[avgKeyInSheet] = Math.min(...summaryInfo[arrKeyInSheet]);
          maxSheetLine[avgKeyInSheet] = Math.max(...summaryInfo[arrKeyInSheet]);
          avgSheetLine[avgKeyInSheet] = Number(summaryInfo[avgKeyInSummary]?.toFixed(1));
        }
      });

      sheet.data.push(avgSheetLine, minSheetLine, maxSheetLine);

      const ws = utils.json_to_sheet(sheet.data, { header, skipHeader: true });
      const colWidth = [
        { wpx: 50 },
        { wpx: 60 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 100 },
        { wpx: 80 },
        { wpx: 60 },
        { wpx: 80 },
        { wpx: 120 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 120 },
        { wpx: 80 },
        { wpx: 100 },
        { wpx: 150 },
      ];

      !isFinalStage && remove(colWidth, (_, index) => index === colWidth.length - 2);
      ws['!cols'] = colWidth;

      utils.book_append_sheet(wb, ws, sheet.name);
      writeFileXLSX(wb, `${xlsxName}.xlsx`);
    } catch (e) {
      message.error('导出失败');
    }
  };

  return (
    <div className={styles('result-summary-wrapper')}>
      <div className={styles('result-summary-title')}>
        <span
          className={styles('result-summary-title-left')}
          onClick={() => {
            goBack();
          }}>
          <LeftOutlined />
          <span className={styles('result-summary-project-title')}>{summaryInfo.projectName}</span>
        </span>
        <Button
          onClick={toExportExcel}
          className={styles('result-summary-title-right')}
          icon={<UploadOutlined />}
          type="primary">
          导出Excel
        </Button>
      </div>
      <div className={styles('result-summary-card-wrapper')}>
        {options.map((item: any, index: number) => {
          return (
            <div className={styles('result-summary-card')} key={item.name}>
              <div className={styles('result-summary-card-title')}>
                <span className={styles('card-title-left')}>{`${index + 1}. ${item.name}`}</span>
                <span className={styles('card-title-right')}>
                  平均分：
                  <span className={styles('card-title-score')}>{item.average ? item.average.toFixed(1) : '-'}</span>
                </span>
              </div>
              <ReactEcharts
                option={item}
                style={{ height: 320 }}
                opts={{ renderer: 'svg' }}
                className={styles('result-summary-card-echarts')}
              />
            </div>
          );
        })}
        {isFinalStage && (
          <div className={styles('result-summary-card')}>
            <div className={styles('result-summary-card-title')}>
              <span className={styles('card-title-left')}>{`9. 是否推荐上线`}</span>
              <span className={styles('card-title-right')}>
                <span className={styles('card-title-score')}>
                  {summaryInfo.commitTotal === 0
                    ? '-%'
                    : ((summaryInfo.recommendTotal / summaryInfo.commitTotal) * 100).toFixed(0) + '%'}
                </span>
                的人推荐上线
              </span>
            </div>
            <ReactEcharts
              option={pieOption}
              style={{ height: 320 }}
              className={styles('result-summary-card-echarts')}
            />
          </div>
        )}
      </div>
      <div className={styles('result-summary-issue-wrapper')}>
        <div className={styles('result-summary-issue-title')}>{`${
          stage === Stage.FINAL ? '10' : '9'
        }.项目整体评价或改进意见`}</div>
        <div className={styles('result-summary-issue')}>
          {summaryInfo.judgeArray.map((item: string, index: number) => {
            return <IssueCard name={item} key={item} issue={summaryInfo.issueArray[index]} />;
          })}
        </div>
      </div>
    </div>
  );
}

import { gql } from '@apollo/client';
import { SERVICE_FRAGMENT } from './service-queries';

export const START_SERVICE = gql`
  mutation StartService($serviceId: ID!) {
    startService(serviceId: $serviceId) {
      msg
      code
    }
  }
`;

export const PAUSE_SERVICE = gql`
  mutation PauseService($serviceId: ID!) {
    pauseService(serviceId: $serviceId) {
      msg
      code
    }
  }
`;

export const CREATE_SERVICE = gql`
  mutation CreateService($projectId: ID!, $name: String!, $description: String!) {
    createService(projectId: $projectId, name: $name, description: $description) {
      ...ServiceFragment
    }
  }
  ${SERVICE_FRAGMENT}
`;

export const RESTORE_SERVICE = gql`
  mutation RestoreService($projectId: ID!, $serviceId: ID!) {
    fixService(projectId: $projectId, serviceId: $serviceId) {
      ...ServiceFragment
    }
  }
  ${SERVICE_FRAGMENT}
`;

export const CROWD_SOURCE_TASK_STATUS = gql`
  query getCrowdSourceTaskStatus($serviceId: ID!) {
    crowdSourceTaskStatus(serviceId: $serviceId)
  }
`;

export const PUBLISH_MAIN_SERVICE = gql`
  mutation PublishMainService(
    $projectId: ID!
    $serviceId: ID!
    $tag: String!
    $alias: String
    $category: [ServiceModuleType]
    $replicas: Replicas
  ) {
    publishMainService(
      projectId: $projectId
      serviceId: $serviceId
      tag: $tag
      alias: $alias
      category: $category
      replicas: $replicas
    ) {
      serviceId
      releaseId
      msg
    }
  }
`;

export const CANCEL_PUBLISH = gql`
  mutation CancelPublish($serviceId: ID!) {
    cancelRelease(serviceId: $serviceId) {
      ...ServiceFragment
    }
  }
  ${SERVICE_FRAGMENT}
`;

export const TURN_OFF_SERVICE = gql`
  mutation TurnOffService($serviceId: ID!) {
    turnOffService(serviceId: $serviceId) {
      ...ServiceFragment
    }
  }
  ${SERVICE_FRAGMENT}
`;

export const TURN_ON_SERVICE = gql`
  mutation TurnOnService($serviceId: ID!) {
    turnOnService(serviceId: $serviceId) {
      ...ServiceFragment
    }
  }
  ${SERVICE_FRAGMENT}
`;

export const RESTART_SERVICE = gql`
  mutation RestartService($serviceId: ID!) {
    restartService(serviceId: $serviceId) {
      ...ServiceFragment
    }
  }
  ${SERVICE_FRAGMENT}
`;

export const ENABLE_PERSONAL_SERVICE = gql`
  mutation EnablePersonalService($serviceId: ID!) {
    enablePersonalService(serviceId: $serviceId) {
      ...ServiceFragment
    }
  }
  ${SERVICE_FRAGMENT}
`;

export const DSIABLE_PERSONAL_SERVICE = gql`
  mutation DisablePersonalService($serviceId: ID!) {
    disablePersonalService(serviceId: $serviceId) {
      ...ServiceFragment
    }
  }
  ${SERVICE_FRAGMENT}
`;

import { PaginationConfig } from 'antd/es/pagination';
import { IInfoItem, ISelectOption, uploadTypeMap } from './const';

export const getAnnotateTypeOptions = (datasetType: string, list?: ISelectOption[]) => {
  return (
    list
      ?.filter(item => item.first_category === datasetType)
      ?.map(item => ({
        value: item.second_category_value,
        label: item.second_category,
      })) || []
  );
};
export const getFileTypeOptions = (datasetType: string, isAnnotated?: boolean) => {
  if (datasetType === '自然语言处理') {
    return isAnnotated
      ? [
          { value: 'excel', label: 'Excel' },
          { value: 'jsonl', label: 'JsonL' },
        ]
      : [
          { value: 'txt', label: 'TXT' },
          { value: 'excel', label: 'Excel' },
          { value: 'jsonl', label: 'JsonL' },
        ];
  } else {
    return isAnnotated
      ? [{ value: 'zip', label: '压缩包' }]
      : [
          { value: 'image', label: '图片' },
          { value: 'zip', label: '压缩包' },
        ];
  }
};

export const getUploadText = (uploadType: string) => {
  const text = uploadTypeMap[uploadType as keyof typeof uploadTypeMap];
  return `上传${text ?? ''}`;
};

export const getUploadTips = (uploadType: string) => {
  if (uploadType === 'excel') {
    return ['文件类型支持xlsx格式，单次上传限制100个文件'];
  }
  if (uploadType === 'txt') {
    return [
      '1. 文本将会按照您定义的分割符拆分成多组数据，每组数据的字符建议不超过512个字符（包括中英文、数字、符号等）',
      '2. 文本文件类型支持txt，编码仅支持UTF-8，单次上传限制100个文本文件',
    ];
  }
  if (uploadType === 'image') {
    return [
      '1. 图片类型为jpg/png/bmp/jpeg，单次上传限制10个文件',
      '2. 图片大小限制在14M，长宽比在3:1 以内，其中最长边需要小于4096px。最短边需要大于30px',
    ];
  }
  if (uploadType === 'zip') {
    return [];
  }
  return [];
};

export const calcCurrent = (pageNumber: number, pageSize: number, total: number) => {
  const tmp = pageNumber * pageSize;
  if (tmp >= total) return total;
  return tmp;
};

export const transformList = (pagination: PaginationConfig, list?: IInfoItem[]) => {
  return (
    list?.map((item, index) => ({
      ...item,
      order: index + 1 + (pagination.current! - 1) * pagination.pageSize!,
    })) || []
  );
};

export const getOrder = (idx: number, pagination: PaginationConfig) => {
  if (!idx || !pagination.pageSize) return 0;
  return (pagination.current! - 1) * pagination.pageSize! + idx;
};

import React, { useEffect, useState } from 'react';
import { Form } from '@fuxi/eevee-ui';
import { FieldNamesForTrain } from '@ai-training/constants/ability';

import EmptyCard from '@ai-training/components/EmptyCard';

import { useAppSelector } from '@/hooks';
import { selectFormDataForInferenceOfTrain, selectFormDataForModelServiceOfTrain } from '@/store/aiTraining';

import { isFormDataEmpty } from '../../NewAIBodyVersion';
import { ConfigTitle, VersionInfoText, VersionInfoSwitchText } from './ConfigConstant';
import EnvSetup from './EnvSetup';
import ResourceConfig from './ResourceConfig';
import { convertOriginFormDataToValues } from '../ProjectVersionConfigInfo';
import cx from './Configs.module.less';

type Props = {};

export const Inference = (props: Props) => {
  const [form] = Form.useForm();
  const [isConfigEmpty, setIsConfigEmpty] = useState(true);
  const [isUseGpu, setIsUseGpu] = useState(false);
  const [isImageDeploy, setIsImageDeploy] = useState(true);
  const isInner = useAppSelector(state => state.user.isInner);
  const formDataForInferenceOfTrain = useAppSelector(selectFormDataForInferenceOfTrain);
  const formDataForModelServiceOfTrain = useAppSelector(selectFormDataForModelServiceOfTrain);

  useEffect(() => {
    if (isFormDataEmpty(formDataForInferenceOfTrain) && isFormDataEmpty(formDataForModelServiceOfTrain)) {
      setIsConfigEmpty(true);
    } else {
      const inferenceFields = convertOriginFormDataToValues(formDataForInferenceOfTrain);
      const modelServiceFields = convertOriginFormDataToValues(formDataForModelServiceOfTrain);

      setIsUseGpu(!!inferenceFields?.resources?.gpu && !!(inferenceFields?.resources?.gpu > 0));

      if (!!inferenceFields?.[FieldNamesForTrain.ImageFile]) {
        setIsImageDeploy(true);
        inferenceFields[FieldNamesForTrain.DeployMethod] = '镜像部署';
        form.setFieldsValue(inferenceFields ?? {});
      } else {
        setIsImageDeploy(false);
        inferenceFields[FieldNamesForTrain.DeployMethod] = '模型部署';
        form.setFieldsValue({ ...inferenceFields, ...modelServiceFields } ?? {});
      }

      setIsConfigEmpty(false);
    }
  }, [formDataForInferenceOfTrain, formDataForModelServiceOfTrain]);

  return (
    <div>
      {isConfigEmpty ? (
        <EmptyCard height={300} imageStyle={{ height: 143, width: 253 }} />
      ) : (
        <Form form={form} labelAlign="right" labelCol={{ style: { width: 130 } }}>
          <div className={cx['config-item']}>
            <div className={cx['config-item-title']}>{ConfigTitle.DeployMethod}</div>
            <div className={cx['config-item-body']}>
              <Form.Item label="部署方式" required name={FieldNamesForTrain.DeployMethod}>
                <VersionInfoText />
              </Form.Item>
              {!isImageDeploy && (
                <>
                  <Form.Item label="样本最大批次" required name={FieldNamesForTrain.ClientMaxBatch}>
                    <VersionInfoText />
                  </Form.Item>
                  <Form.Item label="积攒请求数" required name={FieldNamesForTrain.ServerMaxBatch}>
                    <VersionInfoText />
                  </Form.Item>
                  <Form.Item label="延迟时间" required name={FieldNamesForTrain.ServerMaxDelay}>
                    <VersionInfoText />
                  </Form.Item>
                  <Form.Item label="单实例tps限流" required name={FieldNamesForTrain.LimitPerInstance}>
                    <VersionInfoText />
                  </Form.Item>
                </>
              )}
            </div>
          </div>
          <div className={cx['config-item']}>
            <div className={cx['config-item-title']}>{ConfigTitle.RuntimeEnv}</div>
            <div className={cx['config-item-body']}>
              {isImageDeploy && (
                <Form.Item label="镜像" required name={FieldNamesForTrain.ImageFile}>
                  <VersionInfoText />
                </Form.Item>
              )}
              <Form.Item label="资源配额" required name={FieldNamesForTrain.Resource}>
                <ResourceConfig />
              </Form.Item>
              {isInner && isUseGpu && (
                <Form.Item label="GPU型号" required name={FieldNamesForTrain.GPU}>
                  <VersionInfoText />
                </Form.Item>
              )}
              <Form.Item label="启动命令" name={FieldNamesForTrain.Command}>
                <VersionInfoText />
              </Form.Item>
              <Form.Item label="命令行参数" name={FieldNamesForTrain.Args}>
                <Form.List name={FieldNamesForTrain.Args}>
                  {(fields, { add, remove }, { errors }) =>
                    fields.length === 0 ? (
                      <VersionInfoText />
                    ) : (
                      <>
                        {fields.map(({ key, ...field }, index) => (
                          <Form.Item key={key} style={{ marginBottom: '0' }} {...field}>
                            <VersionInfoText />
                          </Form.Item>
                        ))}
                      </>
                    )
                  }
                </Form.List>
              </Form.Item>
              <Form.Item label="环境变量" name={FieldNamesForTrain.Env}>
                <Form.List name={FieldNamesForTrain.Env}>
                  {(fields, { add, remove }, { errors }) =>
                    fields.length === 0 ? (
                      <VersionInfoText />
                    ) : (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item noStyle key={field.key}>
                            <Form.Item {...field} style={{ marginBottom: '0' }}>
                              <EnvSetup />
                            </Form.Item>
                          </Form.Item>
                        ))}
                        <Form.Item noStyle key={'last-env-var-config'}>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                        {/* <Tooltip title={SYSTEM_ENV_VARIABLE} className={styles['view-env-variables']}>
                      <Text>查看系统环境变量</Text>
                    </Tooltip> */}
                      </>
                    )
                  }
                </Form.List>
              </Form.Item>
              <Form.Item label="开放端口" name={FieldNamesForTrain.Ports}>
                <Form.List name={FieldNamesForTrain.Ports}>
                  {fields =>
                    fields.length === 0 ? (
                      <VersionInfoText />
                    ) : (
                      <>
                        {fields.map(({ name, key, ...field }, index) => {
                          return (
                            <div key={key}>
                              <Form.Item
                                label={'开放外网'}
                                name={[name, 'public']}
                                style={{ marginBottom: 0 }}
                                {...field}>
                                <VersionInfoSwitchText />
                              </Form.Item>
                              <Form.Item
                                label={'端口号'}
                                name={[name, 'port']}
                                style={{ marginBottom: 0, marginLeft: 14 }}
                                {...field}>
                                <VersionInfoText />
                              </Form.Item>
                            </div>
                          );
                        })}
                      </>
                    )
                  }
                </Form.List>
              </Form.Item>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

import { gql } from '@apollo/client';

// 按serviceId查询版本信息
export const GET_SERVICE_VERSIONS = gql`
  query serviceVersions($serviceId: ID!, $current: Int, $pageSize: Int, $orderDesc: Boolean) {
    serviceVersions(serviceId: $serviceId, current: $current, pageSize: $pageSize, orderDesc: $orderDesc) {
      cloudVersions {
        tag
        alias
        publishTime
      }
      edgeVersions {
        tag
        alias
        publishTime
      }
      pageVersions {
        tag
        alias
        publishTime
      }
    }
  }
`;

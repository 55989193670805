/* eslint-disable max-depth */
import { useEffect, useState } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';
import { Select, message } from 'antd';
import { Button, Col, Flex, Form, Input, InputNumber, Row, Space, Spin, Tooltip } from '@fuxi/eevee-ui';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/hooks';
import ml, { mlTrainingTask } from '@/service/ml';

import { MlProjectDetail } from '@/@types/ml';
import { getCurrentUrl } from '../utils/getCurrentUrl';
import { MlDataSelect } from '../MlDatasetSelect';
import cx from './index.module.less';

export const MlCreateTrainingTask = () => {
  const location = useLocation();
  const [initialLocationUrl, setInitialLocationUrl] = useState<string>(`${location.pathname}${location.search}`);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const abilityIdInUrl = searchParams.get('abilityId');
  const versionIdInUrl = searchParams.get('versionId');
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const { getMlPublicCapabilities, getCapabilityVersions } = ml;
  const { taskCreate } = mlTrainingTask;

  const {
    data: abilities,
    run,
    loading: loadingCaps,
  } = useRequest(getMlPublicCapabilities, {
    manual: true,
  });

  const {
    data: versions,
    run: toFetchVersions,
    loading: loadingVersions,
  } = useRequest(getCapabilityVersions, {
    manual: true,
  });

  useEffect(() => {
    if (!!aiProjectId) {
      run({
        share: false,
      });

      form.setFieldsValue({
        capability: {
          id: undefined,
          version_id: undefined,
        },
      });

      if (!!abilityIdInUrl) {
        toFetchVersions({ id: abilityIdInUrl });
        form.setFieldValue(['capability', 'id'], abilityIdInUrl);
      }

      if (!!versionIdInUrl) {
        form.setFieldValue(['capability', 'version_id'], versionIdInUrl);
      }
    }
  }, [aiProjectId, abilityIdInUrl, versionIdInUrl]);

  useEffect(() => {
    if (!!aiProjectId) {
      setInitialLocationUrl(`${location.pathname}${location.search}`);
    }
  }, [aiProjectId]);

  // useEffect(() => {
  //   if (!!abilityIdInUrl) {
  //     toFetchVersions({ abilityIdInUrl });
  //   }
  // }, [abilityIdInUrl]);

  const onSelecteAbility = (id: string) => {
    toFetchVersions({ id });
    form.setFieldsValue({
      capability: {
        id,
        version_id: undefined,
        datasets: undefined,
      },
    });
  };

  const cancel = () => {
    if (!!abilityIdInUrl && !!versionIdInUrl) {
      navigate(`../../ai-platform/ml-project-ability/${abilityIdInUrl}`);
    } else if (!!abilityIdInUrl) {
      navigate(`../../ai-platform/ml-project-ability`);
    } else {
      navigate('../');
    }
  };

  const onSubmit = async () => {
    await form.validateFields();
    form.validateFields().then(async values => {
      setSubmitLoading(true);
      try {
        const data = await taskCreate(values);
        if (!!data?.data?.id) {
          // 如果页面url有变化，则不跳转
          const currentUrl = getCurrentUrl();
          if (initialLocationUrl === currentUrl && currentUrl.includes('/ml-training-task/create')) {
            navigate(`../../ml-training-task/${data?.data?.id}`);
          }
          message.success('创建成功');
        }
      } catch (e) {
      } finally {
        setSubmitLoading(false);
      }
    });
  };

  const onDatasetChange = e => {
    form.setFieldValue('datasets', [{ version_id: e?.[0]?.first_version_id }]);
  };

  const canOpenDatasetModal = () => {
    const versionId = form.getFieldValue(['capability', 'id']);
    if (!versionId) {
      message.warning('请先选择能力');
      return false;
    }
    return true;
  };

  const getProjectCategory = () => {
    const targetCap = abilities?.data?.list?.find(
      (item: MlProjectDetail) => item.uuid === form.getFieldValue(['capability', 'id'])
    );
    return [targetCap?.first_category, targetCap?.second_category];
  };

  return (
    <Spin spinning={loadingCaps || loadingVersions} tip="加载中...">
      <Flex justifyCenter flexDirection="column" alignCenter>
        <Form autoComplete="off" className={cx('form')} labelAlign="right" labelCol={{ span: 6 }} form={form}>
          <Row gutter={[80, 0]}>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: '请选择能力',
                  },
                ]}
                required
                label="能力"
                name={['capability', 'id']}>
                <Select
                  listHeight={160}
                  onSelect={e => onSelecteAbility(e)}
                  options={abilities?.data?.list?.map(item => ({
                    label: item.name,
                    value: item.uuid,
                  }))}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: '请选择版本',
                  },
                ]}
                required
                label="版本"
                name={['capability', 'version_id']}>
                <Select
                  disabled={!form.getFieldValue(['capability', 'id'])}
                  options={versions?.data?.list?.map(item => ({
                    label: item.code,
                    value: item.uuid,
                    desc: item.description,
                  }))}
                  listHeight={160}
                  optionRender={option => (
                    <Flex justifyBetween>
                      <div>{option.data.label}</div>
                      <Tooltip className={cx('desc-in-selector')} placement="topLeft" title={option.data.desc}>
                        {option.data.desc}
                      </Tooltip>
                    </Flex>
                  )}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: '请输入迭代轮数',
                  },
                ]}
                required
                label="迭代轮数"
                name="epoch">
                <InputNumber
                  placeholder="请输入1-50之间的整数"
                  precision={0}
                  addonAfter={<span>轮</span>}
                  min={1}
                  max={50}></InputNumber>
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: '请输入批处理大小',
                  },
                ]}
                required
                label="批处理大小"
                name="batch_size">
                <InputNumber placeholder="请输入1-8之间的整数" precision={0} min={1} max={8}></InputNumber>
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: '请输入学习率',
                  },
                ]}
                required
                label="学习率"
                name="learning_rate">
                <InputNumber placeholder="请输入数字" addonAfter="%" min={1} max={100}></InputNumber>
              </Form.Item>

              <Form.Item label="描述" name="desc">
                <Input.TextArea maxLength={200} showCount rows={4}></Input.TextArea>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: '请选择数据集',
                  },
                ]}
                required
                label="数据集"
                name="datasets">
                <MlDataSelect
                  category={getProjectCategory()}
                  getProjectCategory={getProjectCategory}
                  canOpenDatasetModal={canOpenDatasetModal}
                  onChange={e => onDatasetChange(e)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Space className={cx('buttons-wrapper')}>
          <Button onClick={cancel}>取消</Button>
          <Button type="primary" onClick={onSubmit} loading={submitLoading}>
            提交
          </Button>
        </Space>
      </Flex>
    </Spin>
  );
};

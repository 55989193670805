import { Col, notification, Row, Modal } from '@fuxi/eevee-ui';

const colSpan = 24;

export type ErrorList = { message: string; code?: number }[];

export function openGraphqlErrorNotification(errorList: { message: string; code?: number }[]) {
  if (errorList.find(error => error.code === 40005)) {
    Modal.warning({
      title: '项目数量已达到上限，如有需求请联系平台管理员',
      className: 'create-project-confirm-modal',
      okText: '我知道了',
      closable: true,
    });
  } else {
    notification.error({
      message: '接口报错(Graphql)',
      description: (
        <>
          {errorList.map(error => (
            <Row>
              {error.code && <Col span={colSpan}>Code：{error.code}</Col>}
              <Col span={colSpan}>Message：{error.message}</Col>
            </Row>
          ))}
        </>
      ),
    });
  }
}

export function openNetworkErrorNotification(message: string, code: number) {
  notification.error({
    message: '接口报错(Network)',
    description: (
      <Row>
        {code && <Col span={colSpan}>Code：{code}</Col>}
        <Col span={colSpan}>Message：{message}</Col>
      </Row>
    ),
  });
}

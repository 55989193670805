import { Link, createSearchParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { Tooltip, Badge, TableButtons } from '@fuxi/eevee-ui';

import service from '../../service';
import { requestConfirm } from '../../utils';
import { formatDuration } from '../../utils/util';
import { taskStatusMap } from '../../constants/trainingTask';
import { projectVersionDetailPath } from '../../pages/dataSet/const';
import { abilityProjectDetailPath } from '../../pages/ability/const';
import { TrainingTaskDetail, TrainingDataDetail, TaskStatusType } from '../../types/trainingTask';
import { MonocaEditorModal } from './components/MonacoEditorModal';

import cx from './index.module.less';

export const trainingTaskPath = '../training-task';
export const createTrainingPath = '../training-task/create';
export const trainingTaskDetailPath = '../training-task/';

export const getTrainingTaskColumns = ({ refresh }): ColumnsType<TrainingTaskDetail> => {
  return [
    {
      title: '任务ID',
      dataIndex: 'id',
      width: 300,
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <Tooltip placement="topLeft" title={record.task_id}>
          <div className="list-link-ellipsis">
            <Link type="link" to={trainingTaskDetailPath + record.task_id}>
              {record.task_id}
            </Link>
          </div>
        </Tooltip>
      ),
    },
    {
      title: '能力名称',
      dataIndex: 'capability_name',
      ellipsis: {
        showTitle: false,
      },
      render: value => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: '版本',
      width: 60,
      dataIndex: 'capability_version',
      render: (_, record) => (
        <Link
          type="link"
          className={cx['table-version-col-link']}
          to={`${abilityProjectDetailPath}${record.capability_id}/${record.capability_version_id}`}>
          {record.capability_version}
        </Link>
      ),
    },
    {
      title: '训练状态',
      width: 100,
      dataIndex: 'status',
      render(value) {
        const status = taskStatusMap[value];
        return <Badge color={status?.color} text={status?.label} />;
      },
    },
    {
      title: '已训练时长',
      dataIndex: 'duration',
      width: 130,
      render: (value, record) => (record.status === TaskStatusType.STOP_FINISH ? '-' : formatDuration(value)),
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      ellipsis: {
        showTitle: false,
      },
      render: val => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: '操作',
      fixed: 'right',
      render: (_, record) => (
        <>
          <TableButtons
            data={[
              {
                text: '停止',
                onClick: () => {
                  requestConfirm({
                    title: `确定暂停 ${record.task_id}?`,
                    payload: {
                      train_id: record.train_id,
                    },
                    submitApi: service.trainingTask.taskStop,
                  }).then(() => {
                    refresh();
                  });
                },
                disabled: !(record.status === TaskStatusType.RUNNING),
              },
              {
                text: '删除',
                type: 'danger' as any,
                onClick: () =>
                  requestConfirm({
                    title: `确定删除训练${record.task_id}?`,
                    content:
                      record.status === TaskStatusType.RUNNING
                        ? '删除后不可恢复，且对应的能力版本也将被删除。 '
                        : '删除后不可恢复，已完成的训练任务对于的能力版本不受影响',
                    payload: {
                      task_id: record.task_id,
                    },
                    submitApi: service.trainingTask.taskDelete,
                  }).then(() => {
                    refresh();
                  }),
              },
            ]}
          />
        </>
      ),
    },
  ];
};

export const taskDetailColumns = [
  {
    label: '能力名称',
    dataIndex: 'capability_name',
    ellipsis: true,
  },
  {
    label: '版本',
    dataIndex: 'capability_version',
    ellipsis: true,
  },
  {
    label: '任务ID',
    dataIndex: 'task_id',
    ellipsis: true,
  },
  {
    label: '开始训练时间',
    dataIndex: 'start_at',
    render: data => data.start_at || '-',
  },
  {
    label: '结束训练时间',
    dataIndex: 'finish_at',
    render: data => data.finish_at || '-',
  },
  {
    label: '已训练时长',
    dataIndex: 'duration',
    render: data => formatDuration(data.duration),
  },
  {
    label: '服务状态',
    dataIndex: 'train_status',
    render: data => {
      const status = taskStatusMap[data.status];
      return <Badge color={status?.color} text={status?.label} />;
    },
  },
  {
    label: '训练监控',
    dataIndex: 'tensorboard_url',
    render: data => {
      return data.tensorboard_url ? (
        <a href={'http://' + data.tensorboard_url} target="_blank" rel="noreferrer">
          {data.tensorboard_url}
        </a>
      ) : (
        '-'
      );
    },
  },
];

export const getTrainingDataColumns = ({ history }): ColumnsType<TrainingDataDetail> => {
  return [
    {
      title: '序号',
      dataIndex: 'id',
    },
    {
      title: '数据集名称 ',
      dataIndex: 'name',
    },
    {
      title: '版本',
      dataIndex: 'version',
    },
    {
      title: '操作',
      width: 200,
      render: (_, record) => (
        <TableButtons
          data={[
            {
              text: '查看详情',
              disabled: record.sdk_created,
              onClick: () => {
                console.log(record);
                history(`${projectVersionDetailPath}${record.dataset_id}/${record.id}`);
              },
            },
          ]}
        />
      ),
    },
  ];
};

export enum LogType {
  Log = 'log',
  HistoryLog = 'historyLog',
  PublishLog = 'publishLog',
}

export enum LogRole {
  Platform = 'platform',
  Engine = 'ACE',
}

export enum LogLevel {
  Crit = 'CRIT',
  Error = 'ERROR',
  Warning = 'WARNING',
  Info = 'INFO',
  Debug = 'DEBUG',
}

// import Axios from './common';
import { ShanquanInstance } from "./common";

const ability = {
  // 查询数据集列表
  getDatasets<T = any>(params) {
    return ShanquanInstance.get<T>(`/datasets`, {
      params: {
        ...params,
        page_size: (params?.page_size || 999999),
      }
    });
  },
  // 创建数据集
  createDatasets<T = any>(params) {
    return ShanquanInstance.post<T>(`/datasets`, {
      name: params.name,
      description: params.description,
      upload_uuid: params.upload_uuid,
    });
  },
  // 数据集基本信息
  getDatasetsDetails<T = any>(params: {
    dataset_id: string;
    is_common: boolean;
  }) {
    return ShanquanInstance.get<T>(`/datasets/${params.dataset_id}`, { params });
  },
  // 编辑
  editDatasets<T = any>(params) {
    return ShanquanInstance.patch<T>(`/dataset/${params.id}/desc`, params);
  },
  editDatasetVersion<T = any>(params) {
    return ShanquanInstance.patch<T>(`/datasets/${params.id}/versions/${params.versionId}`, params);
  },
  // 删除数据集
  deleteDatasets<T = any>(params: {
    dataset_id: string;
  }) {
    return ShanquanInstance.delete<T>(`/datasets/${params.dataset_id}`, { params });
  },
  // 查询数据集版本
  getVersions<T = any>(params: {
    dataset_id: string;
  }) {
    return ShanquanInstance.get<T>(`/datasets/${params.dataset_id}/versions`, { params });
  },
  // 新增数据集版本
  addVersions<T = any>(params: {
    dataset_id: string;
    description: string;
    upload_uuid: string;
  }) {
    return ShanquanInstance.post<T>(`/datasets/${params.dataset_id}/versions`, {
      description: params.description,
      uuid_path: params.upload_uuid,
      dataset_id: params.dataset_id,
    });
  },
  // 单一数据集版本详情基础信息
  getVersionsDetails<T = any>(params: {
    dataset_id: string;
    dataset_version: string;
  }) {
    return ShanquanInstance.get<T>(`/datasets/${params.dataset_id}/versions/${params.dataset_version}`, { params });
  },
  // 单一数据集版本文件列表
  getVersionsFileList<T = any>(params) {
    return ShanquanInstance.get<T>(`/datasets/${params.datasetsId}/versions/${params.id}/files`, { params });
  },
  // 删除数据集版本
  deleteVersions<T = any>(params: {
    dataset_id: string;
    dataset_version: string;
  }) {
    return ShanquanInstance.delete<T>(`/datasets/${params.dataset_id}/versions/${params.dataset_version}`, { params });
  },
  // 删除数据集中文件
  deleteVersionsFile<T = any>(params) {
    return ShanquanInstance.delete<T>(`/datasets/${params.datasetsId}/versions/${params.id}/files`, {
      data: params
    });
  },
  // 初始化uuid
  uuidInit<T = any>() {
    return ShanquanInstance.get<T>(`/dataset/upload/uuid`).then(res => {
      return res.data;
    });
  },

  // 新增数据集版本
  createDatasetVersion<T = any>(params: {
    dataset_id: string;
    version: string;
    description: string;
    upload_uuid: string;
  }) {
    return ShanquanInstance.post<T>(`/datasets/${params.dataset_id}/versions`, params);
  },






  // 初始化上传
  uploadInit<T = any>(params) {
    return ShanquanInstance.post<T>(`/upload/init`, params);
  },
  // 文件上传
  upload<T = any>(params, config) {
    return ShanquanInstance.post<T>(`/upload`, params, config);
  },
  // 版本导入文件
  importVersionsFile<T = any>(params: {
    dataset_id: string;
    dataset_version_id: string;
    upload_uuid: string;
  }) {
    return ShanquanInstance.post<T>(`/datasets/${params.dataset_id}/versions/${params.dataset_version_id}/import`, {
      upload_uuid: params.upload_uuid,


    }, {
      params: {
        dataset_id: params.dataset_id,
        dataset_version_id: params.dataset_version_id,
      }
    });
  },
  // 完成上传
  uploadFin<T = any>(params) {
    return ShanquanInstance.post<T>(`/upload/fin`, params);
  },
  // 取消上传
  uploadAbort<T = any>(params) {
    return ShanquanInstance.post<T>(`/upload/abort`, params);
  },
  // 数据集版本文件下载
  downloadfile<T = any>(params: {
    dataset_id: string;
    dataset_version_id: string;
    key: string;
  }) {
    return ShanquanInstance.get<T>(`/datasets/${params.dataset_id}/versions/${params.dataset_version_id}/files/download`, { params });
  },

};

export default ability;

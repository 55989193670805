import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Image = {
  name: string;
  version: string;
  uploadTime: string;
  size: string;
  stubUrl: string;
  msg: string;
  source?: string;
  id?: string;
  status?: string;
};


export const ImageSource = {
  Public: '平台镜像',
  UserUpload: '用户上传',
  UserSave: '用户保存',
  Terminal: '终端镜像',
};

export const VirtualMachineImageStatus = {
  exported: {
    value: 'exported',
    label: '导出成功',
    color: '#36BD1F',
  },
  exporting: {
    value: 'exporting',
    label: '保存镜像中',
    color: '#E58616',
  },
  error: {
    value: 'error',
    label: '异常',
    color: '#DE5550',
  },
  parseError: {
    value: 'ParseError',
    label: '解析异常',
    color: '#DE5550',
  },
  parseSuccess: {
    value: 'ParseSuccess',
    label: '解析成功',
    color: '#36BD1F',
  },
  parsing: {
    value: 'Parsing',
    label: '解析中',
    color: '#E58616',
  },
};


export type VirtualMachineImage = {
  id: string;
  displayName: string;
  imageName: string;
  imageRepo: string;
  imageTag: string;
  port: number;
  defaultProtocol: string;
  systemType: string;
  projectId: string;
  currentStatus: string;
  public: boolean;
  updatedAt: string;
};


export type ImageState = {
  currentImages: Image[];
};

const initialState: ImageState = {
  currentImages: [],
};

const project = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setCurrentImages(state, { payload }: PayloadAction<Image[]>) {
      state.currentImages = payload;
    },
  },
});

export const { setCurrentImages } = project.actions;

export default project.reducer;

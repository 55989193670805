import React from 'react';
import { Carousel } from '@fuxi/eevee-ui';
import HomeBanner1 from '@/assets/image/banner1.png';
import HomeBanner2 from '@/assets/image/banner2.png';
import HomeBanner3 from '@/assets/image/banner3.png';
import HomeBanner4 from '@/assets/image/banner4.png';
import styles from './Home.module.less';

const Home: React.FC = () => {
  return (
    <div className={styles.home_wrapper}>
      <Carousel autoplay>
        <img src={HomeBanner1} alt="" />
        <img src={HomeBanner2} alt="" />
        <img src={HomeBanner3} alt="" />
        <img src={HomeBanner4} alt="" />
      </Carousel>
    </div>
  );
};

export default Home;

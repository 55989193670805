/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-lines */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { reverse, isEmpty } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { Collapse, Divider, Dropdown, Flex, Input, Menu, Tooltip, Button, message, notification } from '@fuxi/eevee-ui';
import {
  CaretRightOutlined,
  CheckOutlined,
  ClearOutlined,
  CloseCircleFilled,
  DownOutlined,
  ExclamationCircleFilled,
  InfoCircleFilled,
  SearchOutlined,
} from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import { CellMeasurerCache } from 'react-virtualized';
import find from 'lodash/find';
import filterUtil from 'lodash/filter';
import { useDebounceFn, useThrottleFn, useUnmount, useUpdateEffect } from '@fuxi/eevee-hooks';
import { RangePickerProps } from 'antd/es/date-picker';
import takeRight from 'lodash/takeRight';
import { difference } from 'lodash-es';
import { LogLevel, LogRole } from '@/constants';
import { ServiceStatus } from '@/typings/common';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  Filter,
  LogItem,
  PodLabel,
  resetRealtimeServer,
  handleSocketWhenClose,
  toGetPersonalRealtimeLogs,
  getRealtimeServerContainer,
  clearRealtimeLogs,
  toCloseRealtimeLogSocket,
  asyncGetPodList,
} from '@/store/realtimeLogs';
import { DatePickerWithRecentTime } from '@/components/DatePickerWithRecentTime';
import { VirtualList } from '@/components/VirtualList';
import { ShuyuanHistoryLogInstance } from '@/service/common';
import { getRealtimeLogsDisconnectTime, getShuyuanEnv } from '@/utils/getEnv';
import MultiCascader from '@/components/MultiCascader';
import HighlightedField from '../../components/HighlightedField.tsx';
import { NodesSelect, parseNodeList, ifSelectGatewayOrNamePod, getNodesList } from '../NodesSelect';

import { ReconnectMsg } from './ReconnectMsg';
import cx from './LogsWithVirtualScroll.module.less';

interface Prop {
  selectPod?: boolean;
  logUrl?: string;
  selectPodList?: string[];
  tabKey: string;
  height: number;
  serverInfo: {
    taskId: string;
    status: ServiceStatus;
  };
  showNodesSelect?: boolean;
  toFetch?: (key: string, loading: boolean) => void;
  toSetFilterRequirements?: (tabKey: string, req: string[]) => void;
}

const LogRoleData = [
  {
    value: LogRole.Platform,
    title: '平台日志',
  },
  {
    value: LogRole.Engine,
    title: '引擎日志',
  },
];

type TimeRange = [Dayjs, Dayjs] | undefined;

// 对象，key 为 podName，value 为时间范围
type PodTimeRange = {
  [key: string]: TimeRange;
};

const TIME_FORMAT_RULE = 'YYYY-MM-DD HH:mm:ss.SSS';

const noMoreLogId = 'noMoreLog';

export const LogsWithVirtualScroll: React.FC<Prop> = props => {
  // 实时日志连接时间
  const [connectTime, setConnectTime] = useState<Date | undefined>(undefined);
  let connectInterval: NodeJS.Timeout | null = null;
  const dispatch = useAppDispatch();
  const { tabKey, height, toFetch = () => {}, serverInfo, selectPod = true, showNodesSelect = true } = props;
  const taskId = serverInfo.taskId;
  const logCache = useAppSelector(state => state.realtimeLogs.cache[tabKey]);
  const podList = useAppSelector(state => state.realtimeLogs.podList);
  const realtimeLogFromStore = useAppSelector(state => state.realtimeLogs.realtimeLogs[tabKey]) || [];
  // const serviceId = serverInfo?.id;
  const updatedRealtimeLogs = useAppSelector(state => state.realtimeLogs.updateLogs[tabKey]);
  const realtimeMaxCount = useAppSelector(state => state.realtimeLogs.realtimeMaxCount);
  const allLogLevels = Object.values(LogLevel);
  const [realtimeLogsData, setRealtimeLogsData] = useState<LogItem[]>(realtimeLogFromStore);
  const [filter, setFilter] = useState<Filter | undefined>(logCache ? logCache.filter : undefined);
  const [selectPodList, setSelectPodList] = useState<string[]>(
    props.selectPodList ? props.selectPodList : logCache ? logCache.selectPodList : [PodLabel.cloud]
  );
  const [filterDate, setFilterDate] = useState<[Dayjs, Dayjs] | undefined>(logCache ? logCache.filterDate : undefined);
  const [logLevels, setLogLevels] = useState<LogLevel[]>(logCache ? logCache.logLevels : allLogLevels);
  const [searchValue, setSearchValue] = useState<string | undefined>(logCache ? logCache.searchValue : undefined);
  const [confirmSearchValue, setConfirmSearchValue] = useState<string | undefined>(
    logCache ? logCache.confirmSearchValue : undefined
  );
  const [role, setRole] = useState<LogRole[]>(logCache ? logCache.role : [LogRole.Platform]);
  const [historyLogs, setHistoryLogs] = useState<LogItem[]>(logCache ? logCache.historyLogs : []);
  const [updateHistoryLogs, setUpdateHistoryLogs] = useState<LogItem[]>([]);
  // 上一次最早的日志，用于拉到顶部请求完数据后，滚动条重新定位至刚刚的最早日志
  const [lastEarliestHistoryLog, setLastEarliestHistoryLog] = useState<{ log: LogItem }>();
  const [forceUpdateCommand, setForceUpdateCommand] = useState<string | null>(null);
  const [displayedLogsData, setDisplayedLogsData] = useState<LogItem[]>([]);
  const [showReconnectButton, setShowReconnectButton] = useState(false);
  const [reconnectMsgValue, setReconnectMsgValue] = useState<string>('长时间未活跃，日志连接已断开，');

  const operationBarRef = useRef<HTMLDivElement>(null);
  const [operationBarHeight, setOperaBarHeight] = useState(0);
  const virtualListRef = useRef<{
    reRenderLogItem: (index: number) => void;
    scrollToRow: (index: number) => void;
    getIsOverflow: () => boolean;
  }>();
  const [activeKeys, setActiveKeys] = useState<string[]>(logCache ? logCache.activeKeys : []);

  const [podTimeRange, setPodTimeRange] = useState<PodTimeRange>();

  const [logLevelDropdownVisible, setLogLevelDropdownVisible] = useState(false);
  const handleVisibleChange = (visible: boolean) => setLogLevelDropdownVisible(visible);
  const [noMoreLogs, setNoMoreLogs] = useState(false);
  const noMoreLogsRef = useRef(noMoreLogs);
  const retryFailedCount = useAppSelector(state => state.realtimeLogs.retryFailedCount);

  useEffect(() => {
    if (selectPod) {
      toFetch(tabKey, true);
      dispatch(asyncGetPodList(taskId)).catch(() => {
        toFetch(tabKey, false);
      });
    }
  }, []);

  useEffect(() => {
    // 30分钟内开始时间未改变时，主动断开ws连接；mousemove、重新连接会重置开始时间戳、并重新计时
    const disconnectTime = getRealtimeLogsDisconnectTime();
    const thirtyMins = 30 * 60 * 1000;
    if (connectInterval) {
      clearInterval(connectInterval);
    }
    connectInterval = setInterval(() => {
      unmountRealtimeLog(true);
    }, disconnectTime || thirtyMins);

    return () => {
      connectInterval && clearInterval(connectInterval);
    };
  }, [connectTime]);

  useUpdateEffect(() => {
    resetLogData();
  }, [selectPodList]);

  useUpdateEffect(() => {
    // 如果选择了 gateway 或 name 节点
    if (ifSelectGatewayOrNamePod(selectPodList)) {
      resetLogData();
    } else {
      resetVirtualList();
    }
  }, [role]);

  useUpdateEffect(() => {
    if (operationBarRef?.current?.clientHeight !== operationBarHeight) {
      setOperaBarHeight(operationBarRef?.current?.clientHeight || 0);
      setForceUpdateCommand(Date.now().toString());
    }
  }, [operationBarRef?.current?.clientHeight]);

  useUpdateEffect(() => {
    const currentLength = realtimeLogsData.length;
    const cutNum = currentLength + updatedRealtimeLogs.length - realtimeMaxCount;
    // 实时日志最多 realtimeMaxCount 条，如果超了从头开始截取
    setRealtimeLogsData(takeRight(realtimeLogsData.concat(updatedRealtimeLogs), realtimeMaxCount));
    if (!filterDate) {
      let removed: any = [];
      if (cutNum > 0) {
        removed = displayedLogsData.splice(-currentLength, cutNum);
        cache?.clearAll();
      }
      let temp = [...updatedRealtimeLogs];
      if (removed.length < cutNum) {
        temp.splice(0, cutNum - removed.length);
      }
      setDisplayedLogsData(displayedLogsData.concat(filterLogsData(temp)));
    }
  }, [updatedRealtimeLogs]);

  useUpdateEffect(() => {
    setHistoryLogs(updateHistoryLogs.concat(historyLogs));
    setDisplayedLogsData(filterLogsData(updateHistoryLogs).concat(displayedLogsData));
  }, [updateHistoryLogs]);

  useUpdateEffect(() => {
    // 筛选日期
    (async () => {
      if (filterDate) {
        await getLogsByDateAndKeyword();
      } else {
        setHistoryLogs([]);
        setDisplayedLogsData(filterLogsData(realtimeLogsData));
        resetVirtualList(false);
      }
    })();
    setPodTimeRange(undefined);
    setNoMoreLogs(false);
  }, [filterDate]);

  // useEffect(() => {
  //   handleSetFilterRequirements(filter);
  // }, [showPublishLog]);

  const getLogsByDateAndKeyword = async () => {
    toFetch(tabKey, true);
    const data = await fetchHistoryLogs(false);
    setHistoryLogs(data);
    setDisplayedLogsData(filterLogsData(data));
    resetVirtualList(false);
  };

  const mergedLogsData = useMemo(() => {
    return [...historyLogs, ...realtimeLogsData];
  }, [realtimeLogsData, historyLogs]);

  const [itemsInRender, setItemsInRender] = useState<{
    overscanStartIndex: number;
    overscanStopIndex: number;
    startIndex: number;
    stopIndex: number;
  }>();

  // 获取最早的pod日志的时间, 以便在某个pod没有数据时，按照这个时间去请求
  const getEarliestLogTime = () => {
    if (!mergedLogsData) {
      return;
    }
    let earliest = dayjs(mergedLogsData[0]?.createdAt);
    if (!podTimeRange) {
      return earliest;
    }
    Object.keys(podTimeRange).forEach(key => {
      if (podTimeRange[key]) {
        const [start] = podTimeRange[key]!;
        if (dayjs(start)?.isBefore(dayjs(earliest))) {
          earliest = dayjs(start);
        }
      }
    });
    return earliest;
  };

  const getStartTimeParam = (scrollLoad: boolean, podKey: string) => {
    if (!scrollLoad && filterDate?.[1]) {
      return dayjs(filterDate[1]);
    } else {
      if (!mergedLogsData) {
        return dayjs();
      }

      const currentPodStartTime = podTimeRange?.[podKey]?.[0];

      return currentPodStartTime ? dayjs(currentPodStartTime) : getEarliestLogTime() || dayjs();
    }
  };

  const getEndTimeParam = () => {
    if (filterDate?.[0]) {
      return dayjs(filterDate[0]);
    } else {
      return dayjs(mergedLogsData[0]?.createdAt).subtract(1, 'y');
    }
  };

  const resetLogData = () => {
    toFetch(tabKey, true);
    // 有时间筛选或关键字时刷新历史日志；否则刷新实时日志
    if (filter?.date || filter?.keyword) {
      getLogsByDateAndKeyword();
    } else {
      getRealtimeServerContainer(tabKey)
        ? dispatch(
            resetRealtimeServer({
              tabId: tabKey,
              input: { taskId: taskId!, podNameList: parseNodeList(selectPodList, role) },
            })
          )
        : fetchRealtimeLogs();

      setHistoryLogs([]);
      setRealtimeLogsData([]);
      setDisplayedLogsData([]);
      setForceUpdateCommand(Date.now().toString());
      setPodTimeRange(undefined);
      setNoMoreLogs(false);
      setTimeout(() => {
        toFetch(tabKey, false);
      }, 1000);
    }
  };

  const parseHistoryLog = (data: string, key: string) => {
    const { dt, level, msg, traceback, role, filename } = JSON.parse(data);
    // if (!dt) return;
    const logData = {
      uid: uuid(),
      level,
      content: msg,
      createdAt: dt,
      traceback,
      role,
      filename,
      key: key.split('#')[2],
      isHistory: true,
    };
    return logData;
  };

  const fetchHistoryLogs = async (scrollLoad: boolean = true) => {
    const pods = getNodesList(selectPodList, role);
    const podsParams = pods.map(item => ({
      pod_name: item,
      starttime: getStartTimeParam(scrollLoad, item).format(TIME_FORMAT_RULE),
      endtime: getEndTimeParam().format(TIME_FORMAT_RULE),
      task_id: taskId,
    }));
    const params = {
      env: getShuyuanEnv(),
      query: podsParams,
      pod_name: parseNodeList(selectPodList, role),
      colfamily: 'c',
      colnames: 'dt,msg,log,role,level,pod_name,task_Id',
      fuzzyfiltercolname: 'c:msg',
      fuzzyfilterkeyword: confirmSearchValue,
      pagesize: 500,
      reverse: true,
    };

    const res = await ShuyuanHistoryLogInstance.post<
      any,
      {
        timeout: boolean;
        timeRanges: { [key: string]: TimeRange };
        data: any;
      }
    >('/hbase/lowcode/scanrow_v2', params).catch(e => {
      console.error(e);
      return {
        data: {},
        timeRanges: {},
        timeout: false,
      };
    });

    const { timeRanges, data } = res;

    if (!!timeRanges) {
      Object.keys(timeRanges).forEach(key => {
        setPodTimeRange(prev => ({
          ...prev,
          [key]: timeRanges[key],
        }));
      });
    }

    if (isEmpty(data)) {
      setNoMoreLogs(true);
    }

    const hasPreviousLogs = !isEmpty(historyLogs);

    // 用startrow去请求历史日志，历史日志的endrow会和这次请求的startrow重复，需要pop一下
    const logs = reverse(Object.keys(res?.data)).map(item => parseHistoryLog(res?.data[item]['c:log'], item)) || [];
    hasPreviousLogs && logs.pop();
    return logs;
  };

  const [cache] = useState(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 40,
      minHeight: 40,
    })
  );

  const fetchRealtimeLogs = () => {
    const { status } = serverInfo;
    const canFetchRealtimeLogs = [ServiceStatus.Running, ServiceStatus.Error].includes(status);
    if (!canFetchRealtimeLogs) {
      toFetch(tabKey, false);
      return;
    }
    if (!serverInfo) return;
    // 实时日志获取
    if (taskId && [ServiceStatus.Running, ServiceStatus.Error].includes(status)) {
      toFetch(tabKey, true);
      dispatch(
        toGetPersonalRealtimeLogs({ tabId: tabKey, input: { taskId, podNameList: parseNodeList(selectPodList, role) } })
      ).finally(() => {
        toFetch(tabKey, false);
      });
    }
  };

  useUpdateEffect(() => {
    if (!podList) {
      return;
    }
    fetchRealtimeLogs();
  }, [podList]);

  const resetScrollBar = () => {
    if (logCache.scrollToItemUid) {
      const visibleLog = find<LogItem>(mergedLogsData, { uid: logCache.scrollToItemUid });
      if (visibleLog) {
        toFetch(tabKey, true);
        setLastEarliestHistoryLog({ log: visibleLog });
        // 关闭loading
        setTimeout(() => {
          toFetch(tabKey, false);
        }, 300);
      }
    }
  };

  useEffect(() => {
    // 如果有缓存，使用缓存数据
    if (logCache) {
      resetVirtualList();
      resetScrollBar();
    }

    const socket = getRealtimeServerContainer(tabKey)?.server;

    if (getRealtimeServerContainer(tabKey) && !!socket) {
      socket.onopen = () => {
        setConnectTime(new Date());
      };
    }

    // 组件卸载时，主动关闭ws连接
    return () => {
      unmountRealtimeLog();
    };
  }, []);

  // 断开ws连接等卸载操作, initiative为true时，表明因前端不活跃而主动断开连接
  const unmountRealtimeLog = (initiative?: boolean) => {
    const wsInstance = getRealtimeServerContainer(tabKey)?.server;
    dispatch(toCloseRealtimeLogSocket(tabKey));
    if (initiative && wsInstance && !showReconnectButton) {
      setReconnectMsgValue('长时间未活跃，日志连接已断开，');
      setShowReconnectButton(true);
    }
  };

  useEffect(() => {
    if (getRealtimeServerContainer(tabKey)?.errorAfterReconnect && !showReconnectButton) {
      setReconnectMsgValue('日志连接已断开，');
      setShowReconnectButton(true);
    }
  }, [retryFailedCount]);

  useUnmount(() => {
    dispatch(
      handleSocketWhenClose({
        tabId: tabKey,
        data: {
          filterDate,
          confirmSearchValue,
          logLevels,
          role,
          historyLogs,
          // 还没来得及等到日志过来就缓存所有实时日志
          // realtimeLogStartUid: isEmpty(realtimeLogsData) ? '' : realtimeLogsData[0]?.uid,
          searchValue,
          scrollToItemUid:
            displayedLogsData && itemsInRender
              ? displayedLogsData[
                  itemsInRender.stopIndex - 1 >= 0 ? itemsInRender.stopIndex - 1 : itemsInRender.stopIndex
                ]?.uid
              : undefined,
          filter,
          activeKeys,
          selectPodList,
        },
      })
    );
  });

  useUpdateEffect(() => {
    resetVirtualList();
  }, [logLevels, role]);

  useUpdateEffect(() => {
    if (filterDate) {
      (async () => {
        await getLogsByDateAndKeyword();
      })();
    } else {
      resetVirtualList();
    }
    setNoMoreLogs(false);
  }, [confirmSearchValue]);

  const handleChoose = (range: [Dayjs, Dayjs]) => {
    const newFilters = {
      ...filter,
      date: range?.[0]?.format(TIME_FORMAT_RULE) + ' - ' + range?.[1]?.format(TIME_FORMAT_RULE),
    };
    setFilterDate(range);
    handleSetFilterRequirements(newFilters);
  };

  const handleDateCleared = () => {
    const newFilters = { ...filter, date: '' };
    setFilterDate(undefined);
    handleSetFilterRequirements(newFilters);
  };

  const getSelectedLevelsName = (seletedLevels: LogLevel[]) => {
    if (seletedLevels.length === 1) return seletedLevels[0];
    if (seletedLevels.length === allLogLevels.length) return 'All levels';

    // allLogLevels 是以级别从重到轻的顺序排列，如果所选的 levels 是以 Crit 开头的连续 level，则显示 "XX +"，否则显示 "Mixed"
    const sortedSelectedLevels: LogLevel[] = [];
    allLogLevels.forEach(level => {
      if (seletedLevels.includes(level)) {
        sortedSelectedLevels.push(level);
      }
    });

    const sortedLevelString = sortedSelectedLevels.join();
    if (sortedLevelString.startsWith(`${LogLevel.Crit}`) && allLogLevels.join().includes(sortedLevelString)) {
      return `${sortedSelectedLevels.pop()} +`;
    } else {
      return 'Mixed';
    }
  };

  const clearLog = () => {
    // if (showPublishLog) {
    //   return;
    // }
    setRealtimeLogsData([]);
    dispatch(clearRealtimeLogs({ tabId: tabKey }));
    setHistoryLogs([]);
    setDisplayedLogsData([]);
    setConfirmSearchValue('');
    setSearchValue('');
    setFilterDate(undefined);
    clearFilterAfterShowPublishLogs();
  };

  const clearFilterAfterShowPublishLogs = () => {
    handleSetFilterRequirements({
      keyword: undefined,
      date: undefined,
    });
    setLogLevels(allLogLevels);
  };

  const filterLogsData = (data: LogItem[]) => {
    return filterUtil(data, item => {
      let result;
      if (confirmSearchValue) {
        result = item.content.includes(confirmSearchValue);
        if (!result) {
          return false;
        }
      }
      if (!role.includes(item.role!)) {
        return false;
      }
      return logLevels.includes(item.level!);
    });
  };

  const handleClickLogLevel = (type: LogLevel) => {
    // 至少选择一种日志类型
    if (logLevels.includes(type) && logLevels.length === 1) return;

    if (!logLevels.includes(type)) {
      return setLogLevels(types => types.concat(type));
    }
    const types = logLevels.filter(i => i !== type);
    setLogLevels(types);
  };

  const resetVirtualList = (updateData = true) => {
    toFetch(tabKey, true);
    updateData && setDisplayedLogsData(filterLogsData(getCurrentData()));
    setForceUpdateCommand(Date.now().toString());
    setPodTimeRange(undefined);
    setNoMoreLogs(false);
    setTimeout(() => {
      toFetch(tabKey, false);
    }, 150);
  };

  const getCurrentData = () => {
    if (filterDate) {
      return historyLogs;
    } else {
      return mergedLogsData;
    }
  };

  const handleSetFilterRequirements = (filter?: Filter) => {
    setFilter(filter);
    // showPublishLog
    //   ? toSetFilterRequirements(tabKey, ['发布日志'])
    //   : toSetFilterRequirements(
    //     tabKey,
    //     [filter?.keyword || '', filter?.date || ''].filter(item => !!item)
    //   );
  };

  const onRowsRendered = (renderProps: {
    overscanStartIndex: number;
    overscanStopIndex: number;
    startIndex: number;
    stopIndex: number;
  }) => {
    setItemsInRender(renderProps);
  };

  // const clearHistoryLog = (startIndex: number, stopIndex: number) => {
  //   if (historyLogs.length > 0 && !displayedLogsData[startIndex].isHistory && !displayedLogsData[stopIndex].isHistory) {
  //     console.log('清除历史日志');
  //   }
  // };

  // const fetchPublishLogs = async () => {
  //   dispatch(toGetPublishLogs(serviceId!));
  // };

  const onMousewheel = async ({ scrollTop, scrollHeight }: { scrollTop: number; scrollHeight: number }) => {
    throttleOnMouseEvent();
    if (noMoreLogsRef.current) return;
    // 向上请求历史日志
    if (scrollHeight > 0 && scrollTop <= 0) {
      toFetch(tabKey, true);
      const data = await fetchHistoryLogs();
      if (isEmpty(data)) {
        setTimeout(() => {
          toFetch(tabKey, false);
        }, 300);
        return;
      }
      setUpdateHistoryLogs(data);
      // 等待滚动条定位到刚刚触发刷新的位置
      setTimeout(() => {
        setLastEarliestHistoryLog({ log: displayedLogsData[0] });
      }, 500);
      setTimeout(() => {
        toFetch(tabKey, false);
      }, 600);
      setForceUpdateCommand(Date.now().toString());
    }
  };

  const onSearchInputBlur = () => {
    const newFilters = { ...filter, keyword: searchValue || '' };
    handleSetFilterRequirements(newFilters);
    setConfirmSearchValue(searchValue);
  };

  const onSearchInputClear = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setSearchValue('');
    setNoMoreLogs(false);
    setPodTimeRange(undefined);
    const newFilters = { ...filter, keyword: '' };
    handleSetFilterRequirements(newFilters);
    setConfirmSearchValue(undefined);
  };

  const rowRender = (rowData: any) => {
    return (
      <Log
        {...rowData}
        searchValue={confirmSearchValue}
        isActive={activeKeys?.includes(rowData.uid)}
        changeCollapse={key => onActiveKeyChange(key)}
        reRenderAfterHeightChange={key => reRenderRow(key)}
      />
    );
  };

  const onActiveKeyChange = (key: string) => {
    if (key === noMoreLogId) return;
    const isActive = activeKeys?.includes(key);
    const newKeys = isActive ? activeKeys.filter(k => k !== key) : [...activeKeys, key];
    setActiveKeys(newKeys);
  };

  const reRenderRow = (key: string) => {
    const index = getLogsOnShow()?.findIndex(item => item.uid === key);
    virtualListRef?.current?.reRenderLogItem(index);
  };

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && dayjs(current.format('YYYY-MM-DD')) > dayjs(dayjs().format('YYYY-MM-DD'));
  };

  // const range = (start: number, end: number) => {
  //   const result: number[] = [];
  //   for (let i = start; i < end; i++) {
  //     result.push(i);
  //   }
  //   return result;
  // };

  const disabledRangeTime: RangePickerProps['disabledTime'] = (current, type) => {
    return {};
  };

  const loadMoreLogs = () => {
    onMousewheel({ scrollTop: 0, scrollHeight: 100 });
  };

  const onChooseAllLevels = () => {
    setLogLevels(allLogLevels);
  };

  const showMore = () => {
    return !virtualListRef?.current?.getIsOverflow();
  };

  function onPodChange(newValue: string[]) {
    if (newValue.length > 0) {
      if (difference(newValue, selectPodList).length || difference(selectPodList, newValue).length) {
        setSelectPodList(newValue);
      }
    } else {
      message.error('必须选择一个服务节点');
    }
  }

  useEffect(() => {
    noMoreLogsRef.current = noMoreLogs;
    setForceUpdateCommand(Date.now().toString());
  }, [noMoreLogs]);

  // 没有更多日志的情况，首条显示没有更多日志
  const getLogsOnShow = () => {
    return noMoreLogs
      ? [
          {
            uid: noMoreLogId,
            content: '没有更多日志',
            createdAt: '',
          },
        ].concat(displayedLogsData)
      : displayedLogsData;
  };

  const { run: throttleOnMouseEvent } = useThrottleFn(
    () => {
      setConnectTime(new Date());
    },
    {
      wait: 1000,
    }
  );

  const reconnectMsg = () => {
    clearLog();
    resetLogData();
    setShowReconnectButton(false);
  };

  return (
    <div onMouseMove={throttleOnMouseEvent} onMouseDown={throttleOnMouseEvent}>
      <div ref={operationBarRef}>
        <Flex alignCenter className={cx('operation-bar')}>
          <div className={cx('clear')}>
            <Tooltip title="清除日志">
              <ClearOutlined style={{ cursor: 'pointer' }} onClick={() => clearLog()} />
            </Tooltip>
          </div>
          <Divider type="vertical" />
          <Input
            suffix={
              searchValue && (
                <CloseCircleFilled style={{ cursor: 'pointer' }} onMouseDown={event => onSearchInputClear(event)} />
              )
            }
            onPressEnter={onSearchInputBlur}
            onBlur={onSearchInputBlur}
            width={200}
            className={cx('search-input')}
            placeholder="请输入"
            prefix={<SearchOutlined className={cx('search-icon')} />}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
          <>
            <Dropdown
              overlayClassName={cx('log-level-dropdown')}
              visible={logLevelDropdownVisible}
              onVisibleChange={handleVisibleChange}
              overlay={
                <Menu>
                  <Menu.Item key="Default" onClick={onChooseAllLevels}>
                    <div>Default</div>
                  </Menu.Item>
                  <Menu.Divider />
                  {allLogLevels.map(level => (
                    <Menu.Item
                      disabled={logLevels.includes(level) && logLevels.length === 1}
                      key={`${level}`}
                      onClick={() => handleClickLogLevel(level)}>
                      <div>
                        {logLevels.includes(level) && <CheckOutlined className={cx('check-icon')} />}
                        <span>{level}</span>
                      </div>
                    </Menu.Item>
                  ))}
                </Menu>
              }>
              <div className={cx('level-selector')} onClick={e => e.preventDefault()}>
                <span className={cx('selected-levels')}>{getSelectedLevelsName(logLevels)}</span>
                <DownOutlined />
              </div>
            </Dropdown>
            <Divider type="vertical" />
            <DatePickerWithRecentTime
              disabledTime={disabledRangeTime}
              disabledDate={disabledDate}
              range={filterDate}
              onChooseCallback={handleChoose}
              onClearCallback={handleDateCleared}
            />
            <Divider type="vertical" />
            <MultiCascader
              okText={'确定'}
              cancelText={'取消'}
              allowClear={false}
              value={role}
              onChange={newValue => {
                if (newValue.length > 0) {
                  setRole(newValue as LogRole[]);
                } else {
                  notification.error({
                    message: '至少选择一种类型的日志',
                    description: '',
                  });
                }
              }}
              data={LogRoleData}
              placeholder=""
            />
            {selectPod && showNodesSelect && (
              <>
                <Flex className={cx('separator')} alignCenter justifyCenter>
                  -
                </Flex>
                <NodesSelect onPodChange={onPodChange} selectPodList={selectPodList} />
              </>
            )}
          </>
        </Flex>
        {showMore() && !noMoreLogs && (
          <Button type="link" block className={cx('more-log-btn')} onClick={loadMoreLogs}>
            加载更多日志
          </Button>
        )}
      </div>

      <VirtualList
        onListScroll={throttleOnMouseEvent}
        scrollToAlignment="start"
        ref={virtualListRef}
        forceUpdate={forceUpdateCommand}
        rowRender={rowRender}
        cache={cache}
        onMousewheel={onMousewheel}
        onRowsRendered={onRowsRendered}
        containerHeight={height - (operationBarRef?.current?.clientHeight || 0)}
        list={getLogsOnShow()}
        lastEarliestHistoryLog={lastEarliestHistoryLog}
      />

      {showReconnectButton && (
        <div>
          <ReconnectMsg reconnect={reconnectMsg} info={reconnectMsgValue} />
        </div>
      )}
    </div>
  );
};

export const Log: React.FC<
  LogItem & {
    searchValue?: string;
    changeCollapse: (key: string) => void;
    isActive: boolean;
    reRenderAfterHeightChange: (key: string) => void;
  }
> = props => {
  const {
    uid,
    content,
    createdAt,
    level,
    traceback,
    searchValue = '',
    isActive = false,
    changeCollapse,
    reRenderAfterHeightChange,
  } = props;
  const { Panel } = Collapse;

  const LogIconMap: { [key in LogLevel]?: React.ReactElement } = {
    [LogLevel.Info]: <InfoCircleFilled className={cx(level?.toLowerCase())} />,
    [LogLevel.Debug]: <InfoCircleFilled className={cx(level?.toLowerCase())} />,
    [LogLevel.Error]: <ExclamationCircleFilled className={cx(level?.toLowerCase())} />,
    [LogLevel.Warning]: <ExclamationCircleFilled className={cx(level?.toLowerCase())} />,
    [LogLevel.Crit]: <CloseCircleFilled className={cx(level?.toLowerCase())} />,
  };

  const Content = (
    <>
      {!!level && [
        <span key={uid} className={cx(`${level.toLowerCase()}`)}>
          {level}
        </span>,
      ]}
      <>{!!createdAt && '[' + createdAt + ']：'}</>
      <HighlightedField text={content} field={searchValue} />
    </>
  );

  const { run } = useDebounceFn(
    () => {
      reRenderAfterHeightChange(uid);
    },
    {
      wait: 33,
    }
  );

  return (
    <div className={noMoreLogId === uid ? cx('no-more') : cx('log-item')} key={uid}>
      {traceback ? (
        <div onTransitionEnd={run}>
          <Collapse
            onChange={() => changeCollapse(uid)}
            activeKey={isActive ? uid : ''}
            bordered={false}
            expandIcon={() => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
            <Panel
              header={
                <>
                  {level && <span className={cx('icon-container')}>{LogIconMap[level]}</span>}
                  <span className={cx('log-content')}>{Content}</span>
                </>
              }
              key={uid}>
              <div className={cx('traceback')}>
                <HighlightedField text={traceback} field={searchValue} />
              </div>
            </Panel>
          </Collapse>
        </div>
      ) : (
        <>
          {!!level && <span style={{ marginRight: 6 }}>{LogIconMap[level]}</span>}
          <span className={cx('log-content')}>{Content}</span>
        </>
      )}
    </div>
  );
};

import React, { useState } from 'react';
import { Radio, Space } from 'antd';
import { TrainFormDataConfig, TrainFormDataOrigin, ResourceValue } from '@ai-training/types/ability';
import {
  AISYSTEM_TENSORBOARD_PATH,
  DEFAULT_VARIABLE_KEY_LIST,
  FieldNamesForTrain,
} from '@ai-training/constants/ability';

import { Train, Assess, Inference } from './Configs';
import cx from './ProjectVersionConfigInfo.module.less';

type Props = {};

enum ConfigType {
  Train = '训练配置',
  Assess = '评估配置',
  Inference = '推理配置',
}

export const convertOriginFormDataToValues = (formData: TrainFormDataConfig) => {
  const fields: TrainFormDataOrigin = {} as TrainFormDataOrigin;
  let isContainTensorboard = false;

  fields[FieldNamesForTrain.Tensorboard] = '未开启';
  fields[FieldNamesForTrain.Code] = '未开启';
  fields[FieldNamesForTrain.IsMountDataset] = '未开启';
  Object.keys(formData).forEach((key: string) => {
    try {
      switch (key) {
        case FieldNamesForTrain.Env:
          fields[FieldNamesForTrain.Env] = (formData[key] ?? [{ name: '', value: '' }]).filter(singleEnv => {
            if (DEFAULT_VARIABLE_KEY_LIST.includes(singleEnv.name)) {
              return false;
            } else if (singleEnv.name === AISYSTEM_TENSORBOARD_PATH) {
              fields[FieldNamesForTrain.PathForTensorbard] = singleEnv.value;
              fields[FieldNamesForTrain.Tensorboard] = '已开启';
              isContainTensorboard = true;
              return false;
            } else {
              return true;
            }
          });

          if (!isContainTensorboard) {
            delete fields[FieldNamesForTrain.PathForTensorbard];
          }
          break;
        case FieldNamesForTrain.Ports:
          fields[FieldNamesForTrain.Ports] = formData[key] ?? [];
          break;
        case FieldNamesForTrain.Resource:
          const resources = formData[key] ?? {};
          fields[FieldNamesForTrain.Resource] =
            Object.keys(resources).length === 0
              ? ({} as ResourceValue)
              : {
                  cpu: resources?.cpu?.max?.value,
                  cpuUnit: resources?.cpu?.max?.unit,
                  memory: resources?.memory?.max?.value,
                  memoryUnit: resources?.memory?.max?.unit,
                  gpu: resources?.gpu?.max?.value,
                };
          break;
        case FieldNamesForTrain.CommitId:
        case FieldNamesForTrain.Repo:
        case FieldNamesForTrain.GitBranch:
          if (formData[key]) {
            fields[key] = formData[key];
            fields[FieldNamesForTrain.Code] = '已开启';
          } else {
          }
          break;
        case FieldNamesForTrain.Args:
          if (Array.isArray(formData[key]) && formData[key].length > 0) {
            fields[key] = formData[key].filter(item => item);
          }
          break;
        case FieldNamesForTrain.ImageFile:
          if (formData[key]) {
            fields[key] = formData[key];
            fields[FieldNamesForTrain.DeployMethod] = 'imageDeploy';
          } else {
            fields[FieldNamesForTrain.DeployMethod] = 'modelDeploy';
          }
          break;
        case FieldNamesForTrain.Command:
        case FieldNamesForTrain.ModelName:
        case FieldNamesForTrain.ProjectStorage:
        case FieldNamesForTrain.GPU:
          if (formData[key]) {
            fields[key] = formData[key];
          }
          break;
        case FieldNamesForTrain.TrainDatasetName:
        case FieldNamesForTrain.EvalDatasetName:
          if (formData[key]) {
            fields[key] = formData[key];
            fields[FieldNamesForTrain.IsMountDataset] = '已开启';
          }
          break;
        case FieldNamesForTrain.IsFullTrain:
          fields[FieldNamesForTrain.IsFullTrain] = (formData[key] ? '全量' : '增量') as any;
          break;
        case FieldNamesForTrain.ServerBactching:
          fields[FieldNamesForTrain.ClientMaxBatch] =
            formData?.[FieldNamesForTrain.ServerBactching]?.[FieldNamesForTrain.ClientMaxBatch];
          fields[FieldNamesForTrain.ServerMaxBatch] =
            formData?.[FieldNamesForTrain.ServerBactching]?.[FieldNamesForTrain.ServerMaxBatch];
          fields[FieldNamesForTrain.ServerMaxDelay] =
            formData?.[FieldNamesForTrain.ServerBactching]?.[FieldNamesForTrain.ServerMaxDelay];
          break;
        case FieldNamesForTrain.DynamicLoadBalance:
          fields[FieldNamesForTrain.LimitPerInstance] =
            formData?.[FieldNamesForTrain.DynamicLoadBalance]?.[FieldNamesForTrain.LimitPerInstance];
          break;
      }
    } catch (error) {
      console.error('error when transform value to fields', error);
    }
  });

  return fields;
};

export const ProjectVersionConfigInfo = (props: Props) => {
  const [currentConfigType, setCurrentConfigType] = useState<ConfigType>(ConfigType.Train);

  return (
    <div className={cx['project-version-config-info-wrapper']}>
      <Radio.Group
        optionType="button"
        value={currentConfigType}
        options={[
          { label: <Space children={ConfigType.Train} />, value: ConfigType.Train },
          { label: <Space children={ConfigType.Assess} />, value: ConfigType.Assess },
          { label: <Space children={ConfigType.Inference} />, value: ConfigType.Inference },
        ]}
        onChange={e => {
          setCurrentConfigType(e.target.value);
        }}
      />
      <div className={cx['config-info-body']}>
        {currentConfigType === ConfigType.Train && <Train />}
        {currentConfigType === ConfigType.Assess && <Assess />}
        {currentConfigType === ConfigType.Inference && <Inference />}
      </div>
    </div>
  );
};

import { Card, Col, Row, Empty } from '@fuxi/eevee-ui';
import MonacoEditor from 'react-monaco-editor';

import EmptyCard from '../EmptyCard';
import cx from './InputOutput.module.less';

interface Props {
  input?: string;
  output?: string;
}
export const InputOutput: React.FC<Props> = ({ input, output }) => {
  return (
    <Row gutter={32} className={cx['input-output-wrapper']}>
      <Col span={12}>
        <Card
          title={<span className={cx['input-output-title']}>输入</span>}
          bordered
          style={{ height: '100%', overflow: 'hidden', backgroundColor: '#2b2e37' }}>
          {input && (
            <MonacoEditor
              language="python"
              theme="vs-dark"
              value={input}
              height="372px"
              options={{ readOnly: true }}
              className={cx['io-monaco-editor']}
            />
          )}
          {!input && <EmptyCard height={372} />}
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title={<span className={cx['input-output-title']}>输出</span>}
          bordered
          style={{ height: '100%', overflow: 'hidden', backgroundColor: '#2b2e37' }}>
          {output && (
            <MonacoEditor
              language="python"
              theme="vs-dark"
              value={output}
              height="372px"
              options={{ readOnly: true }}
              className={cx['io-monaco-editor']}
            />
          )}
          {!output && <EmptyCard height={372} />}
        </Card>
      </Col>
    </Row>
  );
};

export enum TerminalStatus {
  Ready = 'READY',
  Running = 'RUNNING',
  Error = 'ERROR',
  Offline = 'OFFLINE',
  Initializing = 'INITIALIZING',
}

export const TerminalStatusMap = {
  [TerminalStatus.Ready]: {
    label: '就绪',
    color: 'rgba(56, 107, 243, 1)',
  },
  [TerminalStatus.Running]: {
    label: '运行',
    color: 'rgba(56, 201, 33, 1)',
  },
  [TerminalStatus.Offline]: {
    label: '离线',
    color: 'rgba(243, 56, 56, 1)',
  },
  [TerminalStatus.Error]: {
    label: '异常',
    color: 'rgba(243, 56, 56, 1)',
  },
  [TerminalStatus.Initializing]: {
    label: '初始化中',
    color: 'rgba(243, 56, 56, 1)',
  },
};

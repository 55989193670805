import Axios, { WebInstance } from './common';
import { ShanquanInstance } from "./common";

const trainingTask = {
  // 停止训练任务
  taskStop(data: {
    train_id: string;
  }) {
    return ShanquanInstance.post(`/trains/${data.train_id}/stop`);
  },
  // 重启训练任务
  taskRestart(data) {
    return ShanquanInstance.post(`/trains/${data.id}/stop`);
  },
  // 创建训练任务
  taskCreate(data) {
    return ShanquanInstance.post(`/trains`, data);
  },
  // 删除训练任务
  taskDelete(params: {
    task_id: string;
  }) {
    return ShanquanInstance.delete(`/trains/${params.task_id}`);
  },
  // 查询训练任务列表
  taskList(params) {
    return ShanquanInstance.get(`/trains`, { params });
  },
  // 训练任务数据集
  taskDatasets(params) {
    return ShanquanInstance.get(`/trains/${params.id}/datasets`, { params });
  },
  // 训练任务详情
  taskDetails(params: {
    task_id: string;
  }) {
    return ShanquanInstance.get(`/trains/${params.task_id}`, { params });
  },
  // 训练任务pod信息
  taskPodsInfo(params: {
    projectId: string;
    type: string;
    uuid: string;
  }) {
    return WebInstance.get(`/ai/pods`, { params });
  },
};

export default trainingTask;

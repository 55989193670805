export const ENV_VAR_KEY = 'EEVEE_ENV';

const DANLU_ENV = 'EEVEE_DANLU_ENV';

export const DefaultAiProjectId = 'EEVEE_AI_PROJECT_ID';

export enum Env {
  Dev = 'dev',
  Qa = 'qa',
  DdlQa = 'ddl-qa',
  Qa2 = 'qa-2',
  Pre = 'pre',
  Prod = 'prod',
  FuxiQa = 'fuxi-qa',
}

export const getEnv = () => {
  const env = window[DANLU_ENV];
  switch (env) {
    case Env.Dev:
      return Env.Dev;
    case Env.Qa:
      return Env.Qa;
    case Env.DdlQa:
      return Env.DdlQa;
    case Env.Qa2:
      return Env.Qa2;
    case Env.FuxiQa:
      return Env.FuxiQa;
    case Env.Prod:
      return Env.Prod;
    default:
      return Env.Dev;
  }
};

export const isDev = () => {
  return getEnv() === Env.Dev;
};

export const isQa = () => {
  return getEnv() === Env.Qa;
};

export const isDdlQa = () => {
  return getEnv() === Env.DdlQa;
};

export const isProd = () => {
  return getEnv() === Env.Prod;
};

export const VITE_SERVICE_URL_SUFFIX_DEV = '.apps-new-danlu-vm-dev.danlu.netease.com';
export const VITE_SERVICE_URL_SUFFIX_DDL_QA = '.apps-sl.danlu.netease.com';
export const VITE_SERVICE_URL_SUFFIX_QA = '.qa.fuxi.netease.com';
export const VITE_SERVICE_URL_SUFFIX_PROD = '.apps-edge.danlu.netease.com';
export const VITE_SERVICE_URL_SUFFIX_QA_2 = '.danlu-qa.apps-fuxi-qa.danlu.netease.com';
export const VITE_SERVICE_URL_SUFFIX_FUXI_QA = '.apps-fuxi-qa.danlu.netease.com';

export enum ClusterName {
  Dev = 'dev',
  DdlQa = 'serverless',
  Edge = 'edge',
  Qa = 'danlu-online',
  Qa2 = 'qa',
  FuxiQa = 'fuxi-qa'
}

export const getServiceUrlSuffix = () => {
  const env = getEnv();
  switch (env) {
    case Env.Dev:
      return VITE_SERVICE_URL_SUFFIX_DEV;
    case Env.Qa:
      return VITE_SERVICE_URL_SUFFIX_QA;
    case Env.DdlQa:
      return VITE_SERVICE_URL_SUFFIX_DDL_QA;
    case Env.Qa2:
      return VITE_SERVICE_URL_SUFFIX_QA_2;
    case Env.FuxiQa:
      return VITE_SERVICE_URL_SUFFIX_FUXI_QA;
    case Env.Prod:
      return VITE_SERVICE_URL_SUFFIX_PROD;
    default:
      return VITE_SERVICE_URL_SUFFIX_DEV;
  }
}

export const getClusterName = () => {
  const env = getEnv();
  switch (env) {
    case Env.Dev:
      return ClusterName.Dev;
    case Env.Qa:
      return ClusterName.Qa;
    case Env.DdlQa:
      return ClusterName.DdlQa;
    case Env.Qa2:
      return ClusterName.Qa2;
    case Env.Prod:
      return ClusterName.Edge;
    case Env.FuxiQa:
      return ClusterName.FuxiQa;
    default:
      return ClusterName.Dev;
  }
}
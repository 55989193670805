import { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, Space, Tooltip, message } from '@fuxi/eevee-ui';
import service from '@/service';
import { nameRules } from '../../MlDataset/const';
import cx from './index.module.less';

const typeMap = {
  updateName: 'name',
  updateDescription: 'description',
};
export const EditItem = ({
  type,
  title: defaultText,
  datasetId,
  refresh,
}: {
  title: string;
  type: 'updateName' | 'updateDescription';
  datasetId?: number;
  refresh?: () => void;
}) => {
  const [form] = Form.useForm();
  const propName = typeMap[type];
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    form.setFieldValue(propName, defaultText);
  }, [form, defaultText]);

  const title = Form.useWatch(propName, form);

  const handleOnSave = async () => {
    const values = await form.validateFields();

    const res = await service.ml[type]({ dataset_id: datasetId, ...values }).catch(err => {
      setEdit(false);
      message.error(err.message || '修改失败');
    });
    if (res) {
      message.success('修改成功');
      refresh && refresh();
      setEdit(false);
    }
  };
  return (
    <div className={cx('info-text')}>
      {edit ? (
        <Flex>
          <Form form={form}>
            <Form.Item
              name={propName}
              rules={
                type === 'updateName'
                  ? [
                      {
                        required: true,
                        message: '数据集名字不能为空',
                      },
                      ...nameRules,
                    ]
                  : []
              }>
              <Input
                style={{ width: '80%', marginRight: 16 }}
                maxLength={type === 'updateDescription' ? 200 : 20}
                placeholder="请输入"
                showCount
              />
            </Form.Item>
          </Form>
          <Space>
            <Button type="link" disabled={!title} size="small" onClick={() => handleOnSave()}>
              保存
            </Button>
            <Button
              type="text"
              size="small"
              onClick={() => {
                form.setFieldValue(propName, defaultText);
                setEdit(false);
              }}>
              取消
            </Button>
          </Space>
        </Flex>
      ) : (
        <Flex alignEnd>
          <Flex.Item style={{ paddingRight: 4 }}>
            {type === 'updateDescription' ? (
              <Tooltip title={defaultText} placement="topLeft">
                <div className={cx(type === 'updateDescription' && 'desc')}>{defaultText}</div>
              </Tooltip>
            ) : (
              <span>{defaultText}</span>
            )}
          </Flex.Item>
          <Flex.Item>
            <EditOutlined
              style={{ color: '#386BF3', cursor: 'pointer', marginLeft: 6 }}
              onClick={() => setEdit(true)}
            />
          </Flex.Item>
        </Flex>
      )}
    </div>
  );
};

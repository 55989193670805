// import Axios from './common';
import { ShanquanInstance } from './common';

const ability = {
  // 查询镜像服务
  getDockers<T = any>(params) {
    return ShanquanInstance.get<T>(`/images?project_id=${params.projectId}`, params);
  },
  // 查询能力列表
  getCapability<T = any>(params) {
    return ShanquanInstance.get<T>(`/capabilities`, {
      params: {
        ...params,
        page_size: (params?.page_size || 999999),
      },
    });
  },
  // 能力基本信息
  getPublicCapabilityDetails<T = any>(params) {
    return ShanquanInstance.get<T>(`/capabilities/share/${params.capability_uuid}`, { params });
  },
  // 能力基本信息
  getCapabilityDetails<T = any>(params: {
    capability_uuid: string;
  }) {
    return ShanquanInstance.get<T>(`/capabilities/${params.capability_uuid}`, { params });
  },
  // 创建能力
  createCapability<T = any>(params) {
    return ShanquanInstance.post<T>(`/capabilities`, params);
  },
  // 编辑
  editCapability<T = any>(params: {
    capability_uuid: string;
    desc: string;
  }) {
    return ShanquanInstance.patch<T>(`/capabilities/${params.capability_uuid}`, params);
  },
  // 删除
  deleteCapability<T = any>(params) {
    return ShanquanInstance.delete<T>(`/capabilities/${params.id}`);
  },
  // 能力版本列表
  getCapabilityVersions<T = any>(params: { capability_uuid: string, page_size?: number }) {
    return ShanquanInstance.get<T>(`/capabilities/${params.capability_uuid}/versions`, {
      params: {
        ...params,
        page_size: (params?.page_size || 999999),

      },
    });
  },
  // 能力版本详情
  getVersionsDetails<T = any>(params) {
    return ShanquanInstance.get<T>(
      `/capabilities/${params.capability_uuid}/versions/${params.capability_version_uuid}`,
      {
        params: {
          capability_uuid: params.capability_uuid,
          capability_version_uuid: params.capability_version_uuid,
        },
      }
    );
  },
  // 查询能力版本训练使用数据集
  getVersionsDatasets<T = any>(params) {
    return ShanquanInstance.get<T>(`/capabilities/${params.abilityId}/versions/${params.id}/datasets`);
  },
  // 发布能力
  // releaseCapability<T = any>(params) {
  //   return ShanquanInstance.post<T>(`/model_service/start`, {
  //     capability_uuid: params.abilityId,
  //     capability_version_uuid: params.capability_version_id,
  //     url: params.domain,
  //     model_name: params.name,
  //     description: params.desc,
  //   });
  // },
  // 创建能力版本
  createAbilityVersion<T = any>(params) {
    return ShanquanInstance.post<T>(`/capabilities/${params?.capabilityVersion?.capability_uuid}/version`, params);
  },
  // 通过 Id 查询能力版本
  queryAbilityByVersionId<T = any>(params) {
    return ShanquanInstance.get<T>(`/capabilities/${params.capabilityId}/versions/${params.versionId}`, params);
  },
  // 更新能力版本
  updateAbilityByVersionId<T = any>(params, body) {
    return ShanquanInstance.put<T>(`/capabilities/${params.capabilityId}/versions/${params.versionId}`, body);
  },
  // 获取上传 ID
  getUploadId<T = any>() {
    return ShanquanInstance.get<T>(`/get/modelUuid`, {});
  },
  // 删除能力版本
  deleteCapabilityVersions<T = any>(params: {
    capability_uuid: string;
    capability_version_uuid: string;
  }) {
    return ShanquanInstance.delete<T>(`/capabilities/${params.capability_uuid}/versions/${params.capability_version_uuid}`);
  },
  // 查询智能体超级参数默认值
  getCapabilityDefaultHyperParams<T = any>(params: { capability_version_uuid: string }) {
    return ShanquanInstance.get<T>('/capability/parameter', { params });
  },
  //修改tag
  updateModalYlTag<T = any>(params: {
    name: string;
    version: string;
    description?: string;
    yl_tag: string;
  }) {
    return ShanquanInstance.post<T>('/model/saveModelYlTag', params);
  },

  //上架模型文件
  publishModel<T = any>(params) {
    return ShanquanInstance.post<T>('/model/publishModel', params);
  },

  //下架模型文件
  concealModel<T = any>(params) {
    return ShanquanInstance.post<T>('/model/concealModel', params);
  },

  // 查询模型文件的详细信息
  getDetailOfModelById<T = any>(params) {
    return ShanquanInstance.get<T>('/model/detail', { params });
  },

  //查询模型名称列表
  getModelNameList<T = any>(params) {
    return ShanquanInstance.get<T>('/capability/getCapModelInfoList', { params });
  },

  //根据模型名称分页查询模型文件列表
  getModelListByName<T = any>(params) {
    return ShanquanInstance.get<T>(`/model/server/listModel`, { params });
  },

  getModelList<T = any>(params) {
    if (!params?.name) {
      return new Promise((resolve) => {
        resolve({ data: { data: [] } });
      }
      );
    }

    return ShanquanInstance.get<T>(`/model/${params.name}/versions`, {
      params: {
        ...params,
        model_name: params.name,
        yl_tag: params.yl_tag,
      },
    });
  },

  //删除模型文件
  deleteModelByName<T = any>(params) {
    return ShanquanInstance.delete<T>('/model/deleteModelByName', { params });
  },

  //获取模型下载URL
  getModalDownloadUrl<T = any>(params) {
    return ShanquanInstance.get<T>('/model/getDownloadUrl', { params });
  },

  // ddl文件解析
  getDdlParserData<T = any>(ddlUploadId) {
    return ShanquanInstance.get<T>(`/ddl_parser/${ddlUploadId}`);
  },

  //获取模型库bucket_id
  getModelBucketId<T = any>(params) {
    return ShanquanInstance.get<T>(`/model/bucket`, { params });
  },

  //获取模型库列表
  getModels<T = any>(params) {
    return ShanquanInstance.get<T>(`/models?project_id=${params.project_id}`);
  },

  //获取上传的模型文件名
  getModelfileName<T = any>(params) {
    return ShanquanInstance.get<T>(`/get/model`, { params });
  },

  //获取上传的模型文件名
  getParameterDetail<T = any>({ projectId, parameterName, parameterVersion }) {
    return ShanquanInstance.get<T>(
      `/parameter/detail?project_id=${projectId}&parameter_name=${parameterName}&parameter_version=${parameterVersion}`
    );
  },

  //获取资源规格
  getModelServicResource<T = any>() {
    return ShanquanInstance.get<T>(`model_service/config/resource`);
  },

  //获取gpu类型
  getModelServicGpuType<T = any>() {
    return ShanquanInstance.get<T>(`model_service/config/gpu_type`);
  },
  // 下载
  downloadAbilityVersion<T = any>(params: {
    capability_version_uuid: string;
  }) {
    return ShanquanInstance.get<T>(`/capability/download`, { params });
  },

  // 自定义配置文件上传
  uploadCustomConfig<T = any>(params: {
    file: any;
    capability_code: string;
    id: string;
    type: string
  }) {

    return ShanquanInstance.post<T>(`/capability/file`, params);


  }
};
export default ability;

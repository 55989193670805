import { useState, useEffect } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Flex, Form, Input, Select, Button, Space, Card, Spin, message, Tooltip } from '@fuxi/eevee-ui';
import { monaco } from 'react-monaco-editor';
import { QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';

import { isEmpty } from 'lodash';

import service from '../../service';
import DataSelect from './components/DataSelect';
import { trainingTaskPath, trainingTaskDetailPath } from './const';

import cx from './Create.module.less';

const CreateTraining: React.FC = () => {
  const history = useNavigate();
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const [urlState] = useSearchParams();
  const urlParams = useParams();
  const abilityId = urlState.get('abilityId');
  const versionId = urlState.get('versionId');
  const [form] = Form.useForm();
  const [fetchHyperParamsLoading, setFetchHyperParamsLoading] = useState<boolean>(false);
  const [defaultHyperParams, setDefaultHyperParams] = useState<string | undefined>(undefined);

  const [showHyperParamsEditor, setShowHyperParamsEditor] = useState<boolean>(false);

  const loadAbility = async () => {
    return service.ability.getCapability({ share: false }).then(res => res.data.list);
  };
  const fetchHyperParams = async (capVersionId: string) => {
    return service.ability
      .getCapabilityDefaultHyperParams({
        capability_version_uuid: capVersionId,
      })
      .then(res => res.data);
  };
  const { data: abilityList } = useRequest(loadAbility);

  const { data: versions, run: versionsRun } = useRequest(service.ability.getCapabilityVersions, {
    manual: true,
  });
  const handelAbilityChange = val => {
    form.setFieldsValue({
      capability: {
        version_id: null,
      },
    });
    if (!!val) {
      versionsRun({ capability_uuid: val });
    }

    if (defaultHyperParams || !val) {
      setDefaultHyperParams(undefined);
      setShowHyperParamsEditor(false);
    }
  };

  useEffect(() => {
    return () => {
      monaco.editor.getModels().forEach(model => model.dispose());
    };
  }, []);

  const handelSave = () => {
    form
      .validateFields()
      .then(async values => {
        setSubmitLoading(true);
        const editorValue = monaco.editor.getModels()?.[0]?.getValue();
        const hyperParams = isEmpty(editorValue) ? undefined : editorValue;
        const defaultParams = defaultHyperParams;
        if (hyperParams !== defaultParams) {
          values.parameters = hyperParams;
        }
        values.datasets = values.datasets.map(item => ({
          version_id: item.version_id + '',
        }));
        const { data } = await service.trainingTask.taskCreate(values);
        history(`../${trainingTaskDetailPath}${data.id}`);
      })
      .catch(e => {
        setSubmitLoading(false);
      });
  };
  const cancel = () => {
    history(-1);
  };
  useEffect(() => {
    abilityId && versionsRun({ capability_uuid: abilityId });
  }, []);

  useEffect(() => {
    monaco.editor.getModels()?.[0]?.setValue(defaultHyperParams || '');
  }, [defaultHyperParams]);

  monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    validate: false,
  });

  monaco.editor.defineTheme('myTheme', {
    base: 'vs-dark',
    inherit: true,
    rules: [
      {
        token: 'identifier',
        foreground: '9CDCFE',
      },
      {
        token: 'identifier.function',
        foreground: 'DCDCAA',
      },
      {
        token: 'type',
        foreground: '1AAFB0',
      },
    ],
    colors: {
      'editor.background': '#1e2127',
      'editorGutter.background': '#30333c',
    },
  });

  useEffect(() => {
    if (document.getElementById('container-in-train')) {
      monaco.editor.create(document.getElementById('container-in-train')!, {
        value: '',
        language: 'json',
        automaticLayout: true,
        minimap: {
          enabled: false,
        },
        theme: 'myTheme',
      });
    }
  }, []);

  const onVersionChange = versionId => {
    if (!versionId) {
      setDefaultHyperParams(undefined);
      setShowHyperParamsEditor(false);
      // monaco.editor.getModels()?.[0]?.dispose();
      return;
    }
    setFetchHyperParamsLoading(true);
    fetchHyperParams(versionId)
      .then(res => {
        setFetchHyperParamsLoading(false);
        if (isEmpty(res)) {
          setShowHyperParamsEditor(false);
          setDefaultHyperParams(undefined);
          // monaco.editor.getModels()?.[0]?.dispose();

          // message.info('该版本没有默认超级参数');
          return;
        }
        setShowHyperParamsEditor(true);
        setDefaultHyperParams(res);
        monaco.editor.getModels()?.[0]?.setValue(res);
      })
      .catch(e => {
        setFetchHyperParamsLoading(false);
      });
  };

  const resetHyperParams = () => {
    const versionId = form.getFieldValue(['capability', 'version_id']);
    if (!versionId) {
      message.warning('请选择能力版本');
      return;
    }
    setFetchHyperParamsLoading(true);
    fetchHyperParams(versionId)
      .then(res => {
        if (isEmpty(res)) {
          message.info('该版本没有默认超级参数');
          return;
        }
        setFetchHyperParamsLoading(false);
        setDefaultHyperParams(res);
        monaco.editor.getModels()?.[0]?.setValue(res);
      })
      .catch(e => {
        setFetchHyperParamsLoading(false);
      });
  };

  useEffect(() => {
    if (abilityId && versionId) {
      onVersionChange(versionId);
    }
  }, [abilityId, versionId]);

  return (
    <Spin spinning={fetchHyperParamsLoading} tip="正在获取超级参数">
      <Flex className={cx.wrapper} justifyCenter>
        <Flex justifyCenter>
          <Form labelAlign="right" form={form} style={{ width: 500 }} labelCol={{ span: 4 }} autoComplete="off">
            <Form.Item
              label="能力"
              name={['capability', 'id']}
              rules={[{ required: true, message: '请选择能力' }]}
              initialValue={abilityId}>
              {!!abilityId ? (
                <>{abilityList?.find(item => item.uuid === abilityId).name}</>
              ) : (
                <Select
                  allowClear
                  showSearch
                  listHeight={128}
                  placeholder="请选择"
                  optionFilterProp="label"
                  defaultValue={abilityId}
                  onChange={handelAbilityChange}
                  options={abilityList?.map(item => ({ value: item.uuid, label: item.name }))}
                />
              )}
            </Form.Item>
            <Form.Item
              label="版本"
              name={['capability', 'version_id']}
              rules={[{ required: true, message: '请选择版本' }]}
              initialValue={versionId}>
              {!!versionId ? (
                <>{versions?.data.list?.find(item => item.uuid === versionId)?.code}</>
              ) : (
                <Select
                  onChange={onVersionChange}
                  allowClear
                  showSearch
                  listHeight={128}
                  placeholder="请选择"
                  defaultValue={versionId}
                  optionFilterProp="label"
                  options={versions?.data.list.map(item => ({ value: item.uuid, label: item.code }))}
                />
              )}
            </Form.Item>
            <Form.Item label="描述" name="desc" initialValue={''}>
              <Input.TextArea placeholder="请输入" rows={4} maxLength={200} showCount />
            </Form.Item>
            <Form.Item label="数据集" name="datasets" rules={[{ required: true, message: '请选择数据集' }]}>
              <DataSelect />
            </Form.Item>
            <Space direction="horizontal" size={16}>
              <div />
            </Space>
            <Form.Item label=" " colon={false}>
              <Space>
                <Button onClick={cancel}>取消</Button>
                <Button type="primary" onClick={handelSave} loading={submitLoading}>
                  确定
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Flex>

        <div
          className={cx.hyper_param_wrapper}
          style={{ marginLeft: 80, display: showHyperParamsEditor ? 'block' : 'none' }}>
          <Card
            bodyStyle={{ padding: 0, marginTop: 1 }}
            bordered
            title={
              <Flex alignCenter justifyBetween>
                <div>
                  <span className={cx.hyper_param_title}>超级参数配置</span>
                  <Tooltip title={`支持使用超级参数进行精细控制, 有灵AI已为您选择的算法参数设置默认值`}>
                    <QuestionCircleOutlined className={cx.hyper_param_tip} />
                  </Tooltip>
                </div>
                <Tooltip title={!!form.getFieldValue(['capability', 'version_id']) ? '重置超参' : '未选择能力版本'}>
                  <ReloadOutlined onClick={resetHyperParams} />
                </Tooltip>
              </Flex>
            }>
            <div id="container-in-train" style={{ height: 500, background: 'transparent' }}></div>
          </Card>
        </div>
      </Flex>
    </Spin>
  );
};

export default CreateTraining;

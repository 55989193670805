import React, { useEffect, useState } from 'react';
import { Modal, Form, message, Button, Flex } from '@fuxi/eevee-ui';
import { PlusOutlined } from '@ant-design/icons';
import emptyBoxImg from '@/assets/image/empty-box.png';
import { useAppDispatch } from '@/hooks';
import { queryIndustry } from '@/store/template';
import ProjectContainer from './ProjectContainer';
import CreateProject from './CreateProject';
import TemplateCreateCard from './TemplateCreateCard';
import cx from './index.module.less';

const CreateProjectEntry: React.FC = () => {
  const container = ProjectContainer.useContainer();
  const dispatch = useAppDispatch();

  const { showCreateEntryModal, industry, setShowCreate, setShowCreateEntry, postCreateProject } = container;
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    dispatch(queryIndustry());
  }, []);

  // eslint-disable-next-line react-func/max-lines-per-function
  const handleCreateProject = async () => {
    try {
      setConfirmLoading(true);
      const values = await form.validateFields();
      const payload = {
        name: values.name,
        identifier: values.identifier,
        description: values.description,
        users: values.users,
      };
      postCreateProject({ variables: { createProjectInput: payload } })
        .then(res => {
          message.success('项目创建成功！');
          setConfirmLoading(false);
          handleCloseModal();
        })
        .catch(e => setConfirmLoading(false));
    } catch (e) {
      setConfirmLoading(false);
      console.log(e);
    }
  };

  const handleCloseModal = () => {
    form.resetFields();
    setShowCreateEntry(false);
  };

  return (
    <Modal
      visible={showCreateEntryModal}
      title="新建项目"
      onOk={handleCreateProject}
      onCancel={handleCloseModal}
      okButtonProps={{ loading: confirmLoading }}
      centered
      footer={<></>}
      width={576}
      className={cx('entry-create-modal')}
      >
      <Flex className={cx('create-wrapper')} flexDirection="column">
        <Button
          onClick={() => {
            setShowCreateEntry(false);
            setShowCreate(true);
          }}
          className={cx('create-button')}>
          <Flex alignCenter justifyBetween>
            <Flex alignCenter>
              <img className={cx('empty-img')} src={emptyBoxImg} alt=""></img>
              <span className={cx('create-empty-text')}>创建空白项目</span>
            </Flex>
            <PlusOutlined></PlusOutlined>
          </Flex>
        </Button>

        <div className={cx('create-from-template-text')}>从模板创建项目</div>

        <Flex flexWrap="wrap" justifyCenter gap={24}>
          {[
            // 删除不限的行业分类
            ...industry.slice(1),
            {
              isEmpty: true,
            },
          ].map((t: any) => (
            <TemplateCreateCard key={t?.industryId || 'tpl'} {...t} />
          ))}
        </Flex>
      </Flex>

      <CreateProject />
    </Modal>
  );
};

export default CreateProjectEntry;

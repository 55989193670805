import users from './users';
import projectManage from './projectManage';
import trainingTask from './trainingTask';
import ability from './ability';
import dataSet from './dataSet';
import onlineService from './onlineService';
import upload from './upload';

const service = {
  users,
  projectManage,
  trainingTask,
  ability,
  dataSet,
  onlineService,
  upload,
};

export default service;

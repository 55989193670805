import MultiCascader from './components/MultiCascader';
import './index.less';

export * from './components/MultiCascader';
export default MultiCascader;
export * from './constants';
export { matchAllLeafValue, flattenTree, transformValue } from './libs/utils';

export type ValueType = string;

export type TreeNode = {
  parent?: TreeNode | null;
  children?: TreeNode[];
  value: ValueType;
  title: React.ReactNode;
  selectTitle?: string;
  isLeaf?: boolean;
};

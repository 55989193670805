import { IWords } from './type';

// eslint-disable-next-line react-func/max-lines-per-function
export const getInsertTextObj = (words: IWords): Record<string, any> => ({
  h1: {
    prefix: '# ',
    suffix: '',
    str: words.h1,
  },
  h2: {
    prefix: '## ',
    suffix: '',
    str: words.h2,
  },
  h3: {
    prefix: '### ',
    suffix: '',
    str: words.h3,
  },
  h4: {
    prefix: '#### ',
    suffix: '',
    str: words.h4,
  },
  img: {
    prefix: '![alt](',
    suffix: ')',
    str: 'url',
  },
  link: {
    prefix: '[title](',
    suffix: ')',
    str: 'url',
  },
  code: {
    prefix: '```',
    suffix: '\n\n```',
    str: 'language',
  },
  tab: {
    prefix: '  ',
    suffix: '',
    str: '',
  },
});

import { gql } from '@apollo/client';
import { PROJECT_FRAGMENT } from './project-queries';

export const CREATE_PROJECT = gql`
  mutation CreateProject($createProjectInput: CreateProjectInput) {
    createProject(createProjectInput: $createProjectInput) {
      identifier
      id
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($updateProjectInput: UpdateProjectInput) {
    updateProject(updateProjectInput: $updateProjectInput) {
      includeClientSample
      ...ProjectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId) {
      code
      msg
    }
  }
`;

export const UPDATE_PROJECT_USERS = gql`
  mutation UpdateProjectUsers($input: UpdateProjectUserInput) {
    updateProjectUsers(updateProjectUserInput: $input) {
      email
      name
      fullName
    }
  }
`;

// 发布
export const PUBLISH_PROJECT = gql`
  mutation PublishProject($projectId: ID!) {
    publishProject(projectId: $projectId) {
      serviceId
      releaseId
    }
  }
`;

// 取消发布
export const CANCEL_PUBLISH_PROJECT = gql`
  mutation CancelPublishProject($serviceId: ID!) {
    cancelRelease(serviceId: $serviceId) {
      id
      status
    }
  }
`;

// 更新readme
export const UPDATE_README = gql`
  mutation UpdateReadme($id: ID!, $content: String) {
    updateReadme(id: $id, content: $content)
  }
`;

// 项目转换模板
export const CONVERT_TO_TPL = gql`
  mutation ConvertToTemplate($input: ConvertTemplateInput) {
    convertToTemplate(input: $input) {
      id
      name
    }
  }
`;

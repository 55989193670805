import { useState } from 'react';
import { createContainer, usePagination } from '@fuxi/eevee-hooks';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getMlDatasetDataByPage } from '@/service/ml';
import service from '@/service';
import { useAppSelector } from '@/hooks';
import { IDatasetDetail, IInfoItem, annotateStatusMap } from './const';

const _useDatasetState = () => {
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const { datasetVid, datasetId } = useParams();
  const [infoList, setInfoList] = useState<IInfoItem[]>([]);
  const [tab, setTab] = useState('all');
  const {
    pagination,
    run: getData,
    loading: getInfoLoading,
  } = usePagination(
    ({ current, pageSize }) =>
      getMlDatasetDataByPage(datasetVid!, {
        current,
        pageSize,
        isAnnotated: tab === 'all' ? '' : annotateStatusMap[tab],
      }),
    {
      onSuccess: data => {
        if (data?.list?.length === 0 && tab !== 'all') {
          setTab('all');
        }
        setInfoList(prevList => {
          return data.list?.map((item, idx) => ({ ...item, order: idx + 1, key: String(item.id) })) || prevList;
        });
      },
      refreshDeps: [tab],
      manual: true,
    }
  );

  const detailData = useQuery<any, any, { data: IDatasetDetail }>({
    queryKey: ['ml', 'getDatasetDetail', datasetId!],
    queryFn: () => service.ml.getDatasetDetail(datasetId!),
    enabled: !!aiProjectId,
    refetchOnWindowFocus: false,
  });

  return {
    infoList,
    setInfoList,
    pagination,
    getInfoLoading,
    tab,
    setTab,
    getData,
    detailData,
  };
};
export const DatasetContainer = createContainer(_useDatasetState);
export const useDatasetState = DatasetContainer.useContainer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getConsumeRecords, QueryProjectBillInput } from '@/service/cosumeRecord';
import { AppThunk } from '.';

export enum BillType {
  CROWD_SOURCING = '众包任务',
  PROJECT_AI = '项目AI能力',
  PUBLIC_AI = '公共AI能力',
  TRAIN_RESOURCE = '训练资源',
  PARALLEL_DEPLOY = '并行部署',
}

export type ConsumeRecordType = {
  consumeDate: string; //消费日期
  consumeItem: string; //消费项
  billType: BillType; //分类
  operator: string; //操作者
  orderId: string; //账单号
  startTime: string; //开始使用时间
  endTime: string; //结束使用时间
  cost: string; //使用量
};

export type ConsumeRecordState = {
  consumeRecordList: Array<ConsumeRecordType>;
  loading: boolean;
};

const initialState: ConsumeRecordState = {
  consumeRecordList: [],
  loading: true,
};

const consumeRecordSlice = createSlice({
  name: 'consumeRecord',
  initialState,
  reducers: {
    setConsumeRecord(state, { payload }: PayloadAction<Array<ConsumeRecordType>>) {
      state.consumeRecordList = payload;
    },
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
  },
});

export const { setConsumeRecord, setLoading } = consumeRecordSlice.actions;

export const getConsumeRecordThunk =
  (QueryProjectBillInput: QueryProjectBillInput): AppThunk =>
  async dispatch => {
    getConsumeRecords(QueryProjectBillInput).then(async (res: any) => {
      try {
        if (res) {
          const projectBills = res?.projectBills ? [...res.projectBills] : [];
          projectBills.sort((a, b) => new Date(b.consumeDate).getTime() - new Date(a.consumeDate).getTime());
          await dispatch(setConsumeRecord(projectBills));
          await dispatch(setLoading(false));
        } else {
          throw new Error('获取消费记录失败');
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

export default consumeRecordSlice.reducer;

import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, message } from '@fuxi/eevee-ui';
import { UpdateProjectInput } from '@/service/schema/project/types';
import { useImgUpload } from '@/hooks/useImgUpload';
import { baseUrlSuffix, modalWidth } from './const';
import ProjectForm from './ProjectForm';

type Container = {
  showEditModal: boolean;
  setShowEdit: (visible: boolean) => void;
  currentProject: Project;
  editLoading?: boolean;
  postEditProject: ({ variables }: { variables: { updateProjectInput: UpdateProjectInput } }) => PromiseGQL;
  userInfo: User;
};

const EditProject: React.FC<{ useContainer: () => Container }> = ({ useContainer }) => {
  const [form] = Form.useForm();
  const { iconFile, setIconFile, imgChanged, setImgChanged, waitImgUpload } = useImgUpload();
  const [formLoading, setFormLoading] = useState(false);
  const [uploadedIconUrl, setDownloadUrl] = useState('');
  const container = useContainer();
  const { showEditModal, currentProject, editLoading, userInfo, postEditProject, setShowEdit } = container;
  const formRef = useRef<any>();

  const initialValues = {
    name: currentProject?.name,
    identifier: currentProject?.identifier,
    baseUrl: currentProject?.baseUrl || currentProject?.identifier + baseUrlSuffix,
    description: currentProject?.description,
    users: [{ email: userInfo.email, name: userInfo.name, roles: userInfo.roles || [] }],
    iconUrl: currentProject?.iconUrl,
    tag: currentProject?.tag,
    isTerminal: currentProject?.isTerminal,
  };

  useEffect(() => {
    // 用currentProject初始化表单
    form.setFieldsValue({
      ...currentProject,
      baseUrl: currentProject?.baseUrl || currentProject?.identifier + baseUrlSuffix,
      users: [{ email: userInfo.email, name: userInfo.name, roles: userInfo.roles || [] }],
    });
  }, [currentProject, form, showEditModal]);

  // eslint-disable-next-line react-func/max-lines-per-function
  const handleSubmitEdit = async () => {
    try {
      setFormLoading(true);
      const values = await form.validateFields();
      const payload = {
        projectId: currentProject?.id,
        description: values.description,
        name: values.name,
        iconUrl: currentProject.iconUrl,
        isTerminal: currentProject?.isTerminal,
      };

      await waitImgUpload(imgChanged, iconFile, payload);

      postEditProject({ variables: { updateProjectInput: payload } }).then(res => {
        message.success('保存成功！');
        setShowEdit(false);
        setImgChanged(false);
        setFormLoading(false);
      });
    } catch (e) {
      setFormLoading(false);
      message.error({ content: '保存失败！', key: 'save-error' });
    }
  };

  const handleModalCancel = () => {
    setShowEdit(false);
    (formRef.current! as any).resetPreviewImage();
  };

  return (
    <Modal
      visible={showEditModal}
      className="project-modal"
      title="编辑项目"
      okText="保存"
      cancelText="取消"
      onCancel={handleModalCancel}
      onOk={handleSubmitEdit}
      okButtonProps={{ loading: formLoading || editLoading }}
      centered
      width={modalWidth}>
      {/* <Tag style={{ margin: '0 0 24px 60px' }} color="green">
        创建者：{currentProject?.creator}
      </Tag> */}
      <ProjectForm
        isEdit={true}
        ref={formRef}
        setIconFile={setIconFile}
        setImgChanged={setImgChanged}
        projectIdDisabled={true}
        form={form}
        initialValues={initialValues}
        userInfo={userInfo}
      />
    </Modal>
  );
};

export default EditProject;

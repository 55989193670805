import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunk } from '@/store/index';
import { getIndustry, getTags, getTemplates, useTemplate } from '@/service/template';
import { QueryTemplateInput, UseTemplateInput } from '@/service/schema/template/types';

export const NoIndustryId = '-1';
export const NoTagId = '-1';

export type Industry = {
  industryId: string;
  industryName: string;
  description: string;
  want: string;
  icon: string;
  color: string;
};

export type Tag = {
  tagId: string;
  tagName: string;
  creator: string;
};

export type Version = {
  version: string;
  description: string;
  updateTime: string;
};

export enum TemplateStatus {
  ERROR = 'ERROR',
  RUNNING = 'RUNNING',
  STARTING = 'STARTING',
  PAUSED = 'PAUSED',
  PENDING = 'PENDING',
  STOPPED = 'STOPPED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  CANCELLED = 'CANCELLED',
  PREPARING = 'PREPARING',
  INIT_ERROR = 'INIT_ERROR',
  CREATING = 'CREATING',
  RELEASING = 'RELEASING',
  RESTARTING = 'RESTARTING',
  CLOSING = 'CLOSING',
  UNSTART = 'UNSTART',
  AUDITING = 'AUDITING',
  AUDIT_FAIL = 'AUDIT_FAIL',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

enum ProjectType {
  UNKNOWN = 'UNKNOWN',
  NORMAL = 'NORMAL',
  MINI = 'MINI',
  HISTORY_MINI = 'HISTORY_MINI',
  TEMPLATE = 'TEMPLATE',
}

export type Template = {
  id: string;
  name: string;
  // 项目标识
  identifier: string;
  // 项目描述
  description?: string;
  // 项目配置
  config: any;
  // 项目状态
  status: TemplateStatus;
  // 项目创建者
  creator: string;
  // 项目更新者
  updater?: string;
  // 创建时间
  createdTime: string;
  // 更新时间
  updatedTime: string;
  // icon url
  iconUrl: string;
  tag: ProjectType;
  // 模板标签
  tags: [Tag];
  // 模板行业
  industry: Industry;
  // 模板版本
  versionInfo: Version;
  // 使用数
  useNum?: number;
  // 来源
  source?: string;
  // 联系方式
  contactInfo?: string;
  // 是否有示例客户端
  clientSample: boolean;
};

export type TemplateModelState = {
  industry: Industry[];
  tags: Tag[];
  currentPage: number;
  templates: Template[];
  pagination: {
    page: number;
    size: number;
  };
  detail: Detail;
};

type Detail = {
  id?: string;
  visible: boolean;
};

const initialState: TemplateModelState = {
  industry: [],
  tags: [],
  currentPage: 0,
  templates: [],
  pagination: {
    page: 1,
    size: 8,
  },
  detail: {
    visible: false,
  },
};

const template = createSlice({
  name: 'project',
  initialState,
  reducers: {
    updateTags(state, { payload }: PayloadAction<Tag[]>) {
      state.tags = payload;
    },
    updateIndustry(state, { payload }: PayloadAction<Industry[]>) {
      state.industry = payload;
    },
    updateTemplateList(state, { payload }: PayloadAction<Template[]>) {
      state.templates = payload;
    },
    updateTemplateDetail(state, { payload }: PayloadAction<Detail>) {
      state.detail = payload;
    },
    updatePagination(
      state,
      {
        payload,
      }: PayloadAction<{
        page?: number;
        size?: number;
      }>
    ) {
      state.pagination = {
        ...state.pagination,
        ...payload,
      };
    },
  },
});

export const { updateTemplateList, updatePagination, updateTags, updateIndustry, updateTemplateDetail } =
  template.actions;

export const queryTemplateList = createAsyncThunk<{ templates: Template[] }, QueryTemplateInput>(
  'queryTemplate',
  async (input, { dispatch, getState }) => {
    const res = await getTemplates(input);
    dispatch(updateTemplateList(res.templates));
    dispatch(
      updatePagination({
        page: 1,
      })
    );
    return res;
  }
);

export const queryTags = (): AppThunk => async (dispatch, getState) => {
  const res = await getTags();
  dispatch(
    updateTags([
      {
        tagId: NoTagId,
        tagName: '不限',
        creator: '',
      },
      ...res.tags,
    ])
  );
};

export const queryIndustry = (): AppThunk => async (dispatch, getState) => {
  const res = await getIndustry();

  dispatch(
    updateIndustry([
      {
        industryId: NoIndustryId,
        industryName: '不限',
        description: '',
        color: '',
        want: '',
        icon: '',
      },
      ...res.industry,
    ])
  );
};

export const mutationUseTemplate = createAsyncThunk<{ useTemplate: Project }, UseTemplateInput>(
  'useTemplate',
  async (input, { dispatch, getState }) => {
    const res = await useTemplate(input);
    return res;
  }
);

export default template.reducer;

import { render as reactRender, unmount as reactUnmount } from 'rc-util/lib/React/render';
import React from 'react';

export interface ComponentProps {
  onOk?: (any?) => void | boolean;
  onCancel?: (any?) => void;
  [key: string]: any;
}
/**
 * 函数式渲染弹框
 */
interface PopupConfig {
  Component: React.FC<ComponentProps>;
  payload?: { [key: string]: any };
  onOk?: (any?) => void | boolean;
  onCancel?: (any?) => void;
}
export const popup = ({ Component, onOk, onCancel, payload }: PopupConfig) => {
  const container = document.createDocumentFragment();

  const handleOk = value => {
    onOk && onOk(value);
    reactUnmount(container);
  };
  const handleCancel = value => {
    onCancel && onCancel(value);
    reactUnmount(container);
  };
  reactRender(<Component onOk={handleOk} onCancel={handleCancel} {...payload} />, container);
};

import React, { useEffect, useState } from 'react';
import { Button, Card, Tabs, Tooltip, Spin, Row, Col, Radio, Space, TablePaginationConfig } from '@fuxi/eevee-ui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlusOutlined, SyncOutlined, AppstoreOutlined, MenuOutlined } from '@ant-design/icons';

import { ProjectStatus } from '@/constants';
import IconFont from '@/components/IconFont';
import { isProjectCreator } from '@/utils/getUserName';
import { useAppDispatch } from '@/hooks';
import { queryCommonConfigs } from '@/store/commonConfig';
import EditProject from '../EditProject';
import DeleteProject from '../DeleteProject';
import ProjectDetailContainer from '../ProjectDetail/ProjectDetailContainer';
import ServiceList from '../ProjectDetail/ServiceList';
import UserManage from '../ProjectDetail/UserManage';
import SourceCodeManage from '../ProjectDetail/SourceCodeManage';
import UpsertServerModal from '../ProjectDetail/UpsertServerModal';
import { ProjectInformation } from '../ProjectDetail/ProjectInformation';
import ProjectInfo from '../ProjectDetail/ProjectInfo';
import TplProjectCard from '../ProjectDetail/TplProjectCard';
import ServiceCardList from '../ProjectDetail/ServiceCardList';
import cx from './ProjectDetailMini.module.less';

let timeoutId = 0;

const ProjectDetail: React.FC = () => {
  const container = ProjectDetailContainer.useContainer();
  const {
    currentProject,
    dispatchSetProject,
    getProjectUsers,
    userInfo,
    projectUsersData,
    projectInfo,
    fetchProjectInfoLoading,
    fetchProjectInfo,
    isTpl,
  } = container;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [isTable, setIsTable] = useState(false);
  const isCreator = isProjectCreator(currentProject.creator, userInfo.name);
  const projectId = +(searchParams.get('projectId') || '0');
  const [editPermission, setEditPermission] = useState(false);
  const codeUrl = currentProject?.customRepoGitUrl?.substring(0, currentProject?.customRepoGitUrl?.lastIndexOf('/'));
  let currentProjectResourceObj: Resource = {
    SVNUrl: '',
    cloudSpaceUrl: '',
    downloadUrl: {
      iOS: '',
      Android: '',
      other: '',
    },
    assetsOnShow: [],
  };

  const handleQRCodeClick = (link?: string) => {
    if (link) {
      window.location.href = link;
    }
    return;
  };

  const toFetchProjectInfo = () => {
    fetchProjectInfo({ variables: { projectId: projectId } });
    dispatch(queryCommonConfigs());
  };

  const refreshIframe = () => {
    // @ts-ignore
    document.getElementById('biDataView')?.contentDocument?.location.reload();
    iframeOnload();
  };

  const iframeOnload = () => {
    const biDataView = document.getElementById('biDataView');
    if (!biDataView) {
      return;
    }
    // @ts-ignore
    const embedFrameFooter = biDataView.contentDocument.body.getElementsByClassName('EmbedFrame-footer');
    !!embedFrameFooter?.length && (embedFrameFooter[0].style = 'display: none');
  };

  useEffect(() => {
    projectInfo?.project && dispatchSetProject(projectInfo?.project);
  }, [projectInfo]);

  useEffect(() => {
    if (!projectId) {
      navigate('/project');
      return;
    }
    toFetchProjectInfo();
  }, []);

  useEffect(() => {
    const users = projectUsersData?.project?.users;
    const canEdit = !!users?.find((u: User) => u.email === userInfo.email);
    setEditPermission(canEdit);
  }, [projectUsersData]);

  useEffect(() => {
    if (currentProject.id) {
      getProjectUsers({ variables: { projectId: currentProject.id } });
    }
  }, [currentProject.id]);

  useEffect(() => {
    window.clearTimeout(timeoutId);

    if (currentProject.status === ProjectStatus.Creating) {
      timeoutId = window.setTimeout(() => {
        toFetchProjectInfo();
      }, 2000);
    }
  }, [currentProject]);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 9,
    current: 1,
  });

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setPagination({ pageSize: pageSize || 9, current: page });
  };

  return (
    <Spin spinning={currentProject?.status === ProjectStatus.Creating} tip="项目创建中，请稍后...">
      <div style={{ minWidth: 1050 }} className={cx('description-card')}>
        <Spin spinning={fetchProjectInfoLoading}>
          <Row gutter={[16, 16]}>
            <Col span={isTpl ? 16 : 24}>
              <ProjectInfo />
            </Col>
            {isTpl && (
              <Col span={8}>
                <TplProjectCard />
              </Col>
            )}
          </Row>
        </Spin>
      </div>
      <br />
      <Card style={{ minWidth: 1050 }} className={cx('service-tabs')}>
        <div className={cx('white-line')} />
        <Tabs defaultActiveKey="project-information">
          <Tabs.TabPane
            tab={
              <>
                <IconFont style={{ fontSize: 16 }} type="icon-mobanmiaoshu" />
                {'项目信息'}
              </>
            }
            key="project-information">
            <ProjectInformation editPermission={editPermission}></ProjectInformation>
          </Tabs.TabPane>

          {editPermission && (
            <Tabs.TabPane
              tab={
                <>
                  <IconFont style={{ fontSize: 16 }} type="icon-fuwuliebiao" />
                  服务列表
                </>
              }
              key="list">
              <div className={cx('add-server')}>
                {isCreator ? (
                  <UpsertServerModal>
                    <Button type="primary">
                      <PlusOutlined />
                      添加服务器
                    </Button>
                  </UpsertServerModal>
                ) : (
                  <Tooltip title="仅项目创建者有此权限">
                    <Button type="primary" disabled>
                      <PlusOutlined />
                      添加服务器
                    </Button>
                  </Tooltip>
                )}
                <Radio.Group defaultValue="card">
                  <Radio.Button onClick={() => setIsTable(false)} value="card">
                    <Space>
                      <AppstoreOutlined />
                      卡片
                    </Space>
                  </Radio.Button>
                  <Radio.Button onClick={() => setIsTable(true)} value="table">
                    <Space>
                      <MenuOutlined />
                      列表
                    </Space>
                  </Radio.Button>
                </Radio.Group>
              </div>
              {isTable ? <ServiceList editPermission={true} /> : <ServiceCardList editPermission={true} />}
            </Tabs.TabPane>
          )}

          {editPermission && (
            <Tabs.TabPane
              tab={
                <>
                  <IconFont style={{ fontSize: 16 }} type="icon-renyuanguanli" />
                  人员管理
                </>
              }
              key="user">
              <UserManage editPermission={editPermission} />
            </Tabs.TabPane>
          )}

          {/* {editPermission && (
            <Tabs.TabPane tab="项目配置" key="config">
              <ProjectConfig />
            </Tabs.TabPane>
          )} */}

          {editPermission && (
            <Tabs.TabPane tab="源码管理" key="sourceCode">
              <SourceCodeManage />
            </Tabs.TabPane>
          )}

          {editPermission && (
            <Tabs.TabPane
              tab={
                <>
                  <IconFont style={{ fontSize: 16 }} type="icon-a-bidashboard" />
                  <span>BI数据看板</span>
                  <Tooltip title="每60s数据自动刷新">
                    <SyncOutlined onClick={refreshIframe} />
                  </Tooltip>
                </>
              }
              key="BIDataView">
              <iframe
                onLoad={iframeOnload}
                id="biDataView"
                src={`/bi/public/dashboard/c75b2adb-4b12-4cd1-b047-0f141789782b?code_type=${currentProject.identifier}#hide_parameters=code_type&refresh=60`}
                frameBorder="0"
                width="100%"
                height="600px"
                allowFullScreen></iframe>
            </Tabs.TabPane>
          )}
        </Tabs>
      </Card>
    </Spin>
  );
};

const ProjectDetailWrapper = () => {
  return (
    <ProjectDetailContainer.Provider>
      <ProjectDetail />
      <EditProject useContainer={ProjectDetailContainer.useContainer} />
      <DeleteProject useContainer={ProjectDetailContainer.useContainer} />
    </ProjectDetailContainer.Provider>
  );
};

export default ProjectDetailWrapper;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MlCategory = {
  firstCategory: string;
  secondCategory: string;
  categoryUuid: string;
}

export type CategoryState = {
  categories: MlCategory[];
};

const initialState: CategoryState = {
  categories: [],
};

const mlCategory = createSlice({
  name: 'mlCategory',
  initialState,
  reducers: {
    setMlCategory(state, { payload }: PayloadAction<MlCategory[]>) {
      state.categories = payload;
    },
  },
});

export const { setMlCategory } = mlCategory.actions;

export default mlCategory.reducer;

export const acceptImageType = ['image/jpg', 'image/png', 'image/jpeg'];

export const nullImage = 'null icon';

export const idRegexp = /^[a-z][0-9a-z\\-]+[0-9a-z]$/;
export const onlySmallCharacterOrNumberReg = /^[0-9a-z_]{1,}$/;
export const startsWithSmallCharacterReg = /^[a-z].*$/;
export const endsWithSmallCharacterOrNumberReg = /^.*[a-z0-9]$/;
export const blackSpaceReg = /^\s*$/;

export const blackSpaceRule = {
  validator: (rule, value) => {
    if (value && blackSpaceReg.test(value)) {
      return Promise.reject('不能只输入空格');
    }
    return Promise.resolve();
  },
};

export const nameRules = [
  {
    pattern: new RegExp(onlySmallCharacterOrNumberReg, 'g'),
    message: '项目标识中只能包含小写字母、数字、下划线',
  },
  {
    pattern: new RegExp(startsWithSmallCharacterReg, 'g'),
    message: '项目标识须以小写字母开头',
  },
  {
    pattern: new RegExp(endsWithSmallCharacterOrNumberReg, 'g'),
    message: '项目标识须以小写字母/数字结尾',
  },
];

export interface ISelectOption {
  category_uuid: string;
  first_category: string;
  second_category: string;
  second_category_value: string;
}
export interface IDatasetItem {
  id?: number;
  name?: string;
  zh_name?: string;
  annotation_type?: string;
  first_version_data_count?: string;
  first_version_annotated_data_count?: string;
  first_version_data_import_status?: string;
  first_version_id?: string;
  classification?: string;
  update_time?: string;
  is_annotated?: string;
}

export interface IDatasetDetail {
  create_time: string;
  annotation_type: string;
  first_version_data_count?: number;
  first_version_annotated_data_count?: number;
  first_version_data_import_status?: string;
  first_version_id?: string;
  classification: string;
  name: string;
  zh_name: string;
  description: string;
  id: number;
  first_version_last_annotation_time: string | null;
  update_time: string;
}

export enum DatasetType {
  NatualLanguage = 'natualLanguage',
  MultiModal = 'multiModal',
}

export enum MlDatasetImportStatus {
  Importing = 'importing',
  Finished = 'finished',
  Failed = 'failed',
}

export const ImportStatusMap = {
  importing: '导入中',
  finished: '导入成功',
  failed: '导入失败',
};

export const datasetTypeList = [
  { value: '自然语言处理', label: '自然语言处理' },
  { value: '多模态', label: '多模态' },
];

export enum AnnotationType {
  Text = 'text',
  Image = 'image',
}
export const annotationTypeMap = {
  [AnnotationType.Text]: '文本生成',
  [AnnotationType.Image]: '图文生成',
};
export const annotationTypeList = [
  { value: AnnotationType.Text, label: '文本生成' },
  { value: AnnotationType.Image, label: '图文生成' },
];

export enum FileType {
  Txt = 'txt',
  Excel = 'excel',
  Jsonl = 'jsonl',
  Image = 'image',
  Zip = 'zip',
}
export const uploadTypeMap = { txt: 'TXT文本', excel: 'Excel文件', jsonl: 'Jsonl', image: '图片', zip: '压缩包' };
export const uploadTypeList = [
  { value: 'txt', label: 'TXT' },
  { value: 'excel', label: 'Excel' },
  { value: 'jsonl', label: 'Jsonl' },
  { value: 'image', label: '图片' },
  { value: 'zip', label: '压缩包' },
];
export const acceptFileTypeMap = {
  txt: '.txt',
  excel: '.xlsx, .xls, .csv, .xlsm',
  jsonl: '.jsonl',
  image: 'image/*',
  zip: '.zip',
};

export const templateUrlMap = {
  [FileType.Txt]:
    'https://cdn.fuxi.netease.com/youling_ai/index_module/annotation_template/no_annotation_dataset.txt?auth_key=2147483647-0-0-0220c10f4ad8aeed71ed292f048b6791',
  [FileType.Excel]:
    'https://cdn.fuxi.netease.com/youling_ai/index_module/annotation_template/annotation_dataset1.xlsx?auth_key=2147483647-0-0-d4a445c932776199946788089a7c3327',
  [FileType.Jsonl]:
    'https://cdn.fuxi.netease.com/youling_ai/index_module/annotation_template/annotation_dataset.jsonl?auth_key=2147483647-0-0-d596aedb0d06d39ffdfec1660a2fb04c',
  [FileType.Zip]:
    'https://cdn.fuxi.netease.com/youling_ai/index_module/annotation_template/annotation_image1.zip?auth_key=2147483647-0-0-4626fff99a080eb138cab1d1fc336a4a',
};

export const annotationStatusList = [
  { value: '1', label: '无标注' },
  { value: '2', label: '有标注' },
];

export const getAnnotateTabList = (all, annotated) => {
  let tmpAll = all;
  let tmpAnnotated = annotated;
  if (isNaN(all) || isNaN(annotated)) {
    tmpAll = 0;
    tmpAnnotated = 0;
  }

  return [
    {
      key: 'all',
      label: `全部（${tmpAll}）`,
    },
    {
      key: 'unannotated',
      label: `未标注（${tmpAll - tmpAnnotated}）`,
      disabled: tmpAll - tmpAnnotated === 0,
    },
    {
      key: 'annotated',
      label: `已标注（${tmpAnnotated}）`,
      disabled: tmpAnnotated === 0,
    },
  ];
};

export const annotateStatusMap = {
  unannotated: false,
  annotated: true,
  all: '',
};

export interface IInfoItem {
  id?: number | string;
  key?: string;
  order?: number;
  data?: string;
  annotation?: string;
  [key: string]: any;
}

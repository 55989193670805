import React, { CSSProperties, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import MarkdownIt from 'markdown-it';
import 'highlight.js/styles/github-dark.css';
import 'github-markdown-css/github-markdown-dark.css';
import './dark.css';
import './Markdown.css';

type Props = {
  theme?: string;
  text?: string;
  styles?: CSSProperties;
};

const ProjectMarkDown: React.FC<Props> = ({ theme = 'dark', text = '', styles }) => {
  const isDark = theme === 'dark';
  const [mdHtml, setMdHtml] = useState('');

  // useEffect(() => {
  //   loadCss();
  // }, []);

  function parseMarkdown(markdown) {
    const markdownIt = new MarkdownIt({
      html: true,
    });
    // 移除 HTML 注释
    return markdownIt.render(markdown);
  }

  useEffect(() => {
    try {
      const mdHtml = DOMPurify.sanitize(parseMarkdown(text));
      setMdHtml(mdHtml);
    } catch (e) {
      console.log(e);
    }
  }, [text]);

  return (
    <div
      className={classNames('markdown-body', 'show-html')}
      style={styles}
      dangerouslySetInnerHTML={{
        __html: mdHtml,
      }}></div>
  );
};

export default ProjectMarkDown;

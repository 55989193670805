import { useQuery } from '@tanstack/react-query';
import service from '@/service';

export const useUploadUuid = aiProjectId => {
  return useQuery({
    queryKey: ['uploadUUid', aiProjectId],
    queryFn: () => service.ml.getUploadUuid(),
    enabled: !!aiProjectId,
    refetchOnWindowFocus: false,
  });
};

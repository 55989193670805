import { CloseOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Flex, Image } from '@fuxi/eevee-ui';
import { Button, Modal } from 'antd';
import cx from './index.module.less';

export const AnnotateTemp = ({ visible, setVisible }: { visible: boolean; setVisible: (val: boolean) => void }) => {
  return (
    <Modal
      className={cx('template-modal')}
      open={visible}
      // open={true}
      width={892}
      title={
        <Flex alignCenter justifyBetween style={{}}>
          <Flex.Item>图片标注示例</Flex.Item>
          <Flex.Item>
            <CloseOutlined onClick={() => setVisible(false)} />
          </Flex.Item>
        </Flex>
      }
      footer={
        <Button type="primary" onClick={() => setVisible(false)}>
          我知道了
        </Button>
      }
      closable={false}>
      <div className={cx('content')}>
        <Flex alignStart className={cx('tips')}>
          <InfoCircleFilled style={{ color: '#3855A7', paddingRight: 8, paddingTop: 4 }} />
          <span>
            请根据左侧图片特征（如颜色、大小、形状、种类、环境、数量等），在右侧文本框输入相应的描述文字，请尽可能使用简短的词汇描述显著的特征
          </span>
        </Flex>
        <Flex style={{ position: 'relative' }}>
          <Image
            style={{ height: 360, width: 550, marginRight: 16, borderRadius: 4 }}
            preview={false}
            src={'https://static.fuxi.netease.com/yaotai/portal/index/lr5zexkx_383232.png'}
          />
          <div className={cx('example')}>示例</div>
          <div className={cx('right')}>
            In the picture, there is a Ragdoll cat lying down and looking straight ahead. It has blue eyes, a pink nose,
            and white and brown fur.
          </div>
        </Flex>
      </div>
    </Modal>
  );
};

import React, { ReactNode } from 'react';
import { Modal, Form, Input, message } from '@fuxi/eevee-ui';
import { useAntModal } from '@fuxi/eevee-hooks';

import client from '@/service/graphql';
import { VALIDATE_SERVICE_NAME } from '@/service/schema/service/service-queries';
import { CREATE_SERVICE } from '@/service/schema/service/service-mutation';

import ProjectDetailContainer from './ProjectDetailContainer';

const { TextArea } = Input;

interface Prop {
  children: ReactNode;
}

const UpsertServerModal: React.FC<Prop> = ({ children }) => {
  const [form] = Form.useForm();
  const { currentProject, getServicesData } = ProjectDetailContainer.useContainer();

  const handleOkCallback = async () => {
    const values = await form.validateFields();
    const data = await client.mutate(CREATE_SERVICE, {
      projectId: currentProject.id,
      ...values,
    });

    if (data) {
      message.success('添加服务器成功');
      // 刷新服务列表
      getServicesData({ variables: { projectId: +currentProject.id } });
    }
  };

  const validateServiceName = async (name: string) => {
    const { validateServiceName } = await client.query(VALIDATE_SERVICE_NAME, {
      projectId: currentProject.id,
      name: name,
    });

    if (validateServiceName) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('名称不能重复'));
    }
  };

  const { showModal, commonAntModalProps } = useAntModal({
    handleOkCallback,
  });

  return (
    <>
      <span onClick={showModal}>{children}</span>
      <Modal title="添加服务器" destroyOnClose bodyStyle={{ paddingBottom: 0 }} {...commonAntModalProps}>
        <Form
          form={form}
          preserve={false}
          labelCol={{ span: 5 }}
          initialValues={{ description: `${currentProject.name} 中的主干服务器` }}>
          <Form.Item
            name="name"
            label="服务器名称"
            validateFirst
            rules={[
              {
                required: true,
                message: '请输入服务器名称',
              },
              {
                max: 128,
                message: '输入不得超过 128 字符',
              },
              {
                validator: (_, value) => {
                  return validateServiceName(value);
                },
              },
            ]}>
            <Input autoComplete="off" placeholder="请输入服务器名称" />
          </Form.Item>
          <Form.Item
            name="description"
            label="服务器描述"
            rules={[
              {
                max: 256,
                message: '输入不得超过 256 字符',
              },
            ]}>
            <TextArea rows={4} placeholder="请输入服务器描述" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpsertServerModal;

import { Tooltip } from '@fuxi/eevee-ui';
import { AbilityTags } from '@/constants/abilityTags';
import cx from './index.module.less';

interface Prop {
  value: string;
}

export const AbilityTag = (props: Prop) => {
  const { value } = props;
  const [tag, subTag] = value.split('/');
  const currentTag = AbilityTags.find(item => item.label === tag);
  const notFound = !subTag;
  const notFoundTag = '未设置';
  const errorColor = '#de5550';


  return (
    <div
      style={{
        color: notFound ? errorColor : currentTag?.color?.color,
        borderColor: notFound ? errorColor : currentTag?.color?.borderColor,
      }}
      className={cx('tag')}>
      <Tooltip placement="topLeft" title={subTag || notFoundTag}>
        {subTag || notFoundTag}
      </Tooltip>
    </div>
  );
};

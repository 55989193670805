export const openVisualWebsite = (id: number) => {
  const url = `/editor?projectId=${id}`;
  window.open(url);
};

export const defaultPageSize = 8;

export const defaultCurrentPage = 1;

export const modalWidth = 538;

export const deleteModalWidth = 400;

export const descriptionAutoSize = { minRows: 2, maxRows: 5 };

export const descriptionMaxWidth = 500;

export const baseUrlSuffix = '.agent.163.com';

export const formLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

export const defaultFileList = [];

export const ProjectType = {
  unknow: 'UNKNOWN',
  normal: 'NORMAL',
  mini: 'MINI',
  historyMini: 'HISTORY_MINI',
  template: 'TEMPLATE',
};
export const acceptImageType = ['image/jpg', 'image/png', 'image/jpeg', 'image/bmp', 'image/svg', 'image/gif'];

export const nullImage = 'null icon';

export const reviewStage = {
  DEMO: 'DEMO',
  MID: 'MID',
  FINAL: 'FINAL',
};

export const reviewDimension: any = {
  experience: {
    en: 'experience',
    cn: '核心体验',
  },
  innovation: {
    en: 'innovation',
    cn: '创新性',
  },
  artisticExpression: {
    en: 'artisticExpression',
    cn: '艺术表现力',
  },
  ai: {
    en: 'ai',
    cn: 'AI工具使用及效果',
  },
  effect: {
    en: 'effect',
    cn: '技术实现效果',
  },
  difficulty: {
    en: 'difficulty',
    cn: '上手难易度',
  },
  prospect: {
    en: 'prospect',
    cn: '商业化前景',
  },
};

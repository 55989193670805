import { ClusterResource } from '@/utils/isResourceOverflow';
import { WebInstance } from './common';

const descriptionRoute = (projectId: string) => `/terminal/vms/project/${projectId}/vm`;
const descriptionImageRoute = (projectId: string) => `/terminal/images/project/${projectId}/image`;
const descriptionResource = (projectId: string) => `/terminal/vms/project/${projectId}/resource`;

const createVM = (projectId: string, createVMInfo) => {
  return WebInstance.post(descriptionRoute(projectId), { ...createVMInfo });
};

const deleteVM = (projectId: string, vmIdList) => {
  return WebInstance.delete(descriptionRoute(projectId), { data: { vmIdList } });
};

const updateVM = (projectId: string, updateVMInfo) => {
  return WebInstance.put(descriptionRoute(projectId), { ...updateVMInfo });
};

const resetVM = (projectId: ID, vmId: number) => {
  return WebInstance.post(`${descriptionRoute(projectId + '')}/${vmId}/reset`);
};

const getVMs = (projectId: string, page?: number, size?: number) => {
  return WebInstance.get(descriptionRoute(projectId), { params: { page, size } });
};

const getVMByID = (projectId: string, vmId: string) => {
  return WebInstance.get(`${descriptionRoute(projectId)}/${vmId}`);
};

const getResource = (projectId: string) => {
  return WebInstance.get<{
    usage: ClusterResource;
    limitation: ClusterResource;
  }>(`${descriptionResource(projectId)}`);
};

const copyVm = (
  projectId: ID,
  vmId: number,
  copyVmReqVO: {
    number: number;
    rmOldVm: boolean;
    password: string;
  }
) => {
  return WebInstance.post(`${descriptionRoute(projectId + '')}/${vmId}/copy`, { ...copyVmReqVO });
};

const exportImage = (
  projectId: ID,
  vmId: number,
  exportVmReqVO: {
    imageName: string;
    imageTag: string;
  }
) => {
  return WebInstance.post(`${descriptionRoute(projectId + '')}/${vmId}/export`, { ...exportVmReqVO });
};

const getImages = (projectId: string) => {
  return WebInstance.get(`${descriptionImageRoute(projectId)}`);
};

const deleteVmImage = (projectId: string, imageId: string) => {
  return WebInstance.delete(`${descriptionImageRoute(projectId)}/${imageId}`);
};

export const virtualTerminalApi = {
  createVM,
  deleteVM,
  updateVM,
  resetVM,
  copyVm,
  getVMs,
  getVMByID,
  exportImage,
  getImages,
  getResource,
  deleteVmImage,
};

import React from 'react';
import { Image, Tooltip } from '@fuxi/eevee-ui';
import { EyeOutlined } from '@ant-design/icons';

import { ProjectReview } from '@/store/miniReview';
import placeholderImg from '@/assets/image/project-in-list.png';
import { ProjectSummary } from '../../utils';

import styles from './reviewItemCard.module.less';

interface Props {
  project: ProjectReview | ProjectSummary;
  sortBy?: string;
  sortByDesc?: string;
  result: '结果汇总' | '评审问卷';
  toReview?: () => void;
  toSummary?: () => void;
}

export default function ReviewItemCard({
  project,
  sortBy = 'score',
  sortByDesc = '整体',
  result,
  toReview,
  toSummary,
}: Props) {
  const {
    projectName,
    description,
    image,
    commitTotal,
    unCommitTotal,
    unCommitJudgeArray,
    judgeArray: commitJudgeArray,
  } = project;

  return (
    <div
      className={styles('review-item-card-wrapper')}
      onClick={() => {
        toSummary?.();
        toReview?.();
      }}>
      <div className={styles('card-left')}>
        <div className={styles('card-left-title')}>{projectName}</div>
        <div className={styles('card-left-describe')}>{description}</div>
        <div className={styles('card-left-grade')}>
          <span className={styles('review-grade')}>
            {project[sortBy] || (project[sortBy + 'Average'] ? project[sortBy + 'Average'].toFixed(1) : '-')}
          </span>
          <span>{sortByDesc === '抽签顺序' ? ` ${sortByDesc}` : ` ${sortByDesc}评分`}</span>
        </div>
        {result === '评审问卷' && (
          <div className={styles('card-left-look')}>
            <EyeOutlined />
            <span>查看问卷</span>
          </div>
        )}
        {result === '结果汇总' && (
          <div className={styles('card-left-commit')} onClick={e => e.stopPropagation()}>
            <Tooltip title={`已提交：${commitJudgeArray.join('、')}`} placement="bottomLeft">
              <span>{`${commitTotal}人已提交`} </span>
            </Tooltip>
            ，
            <Tooltip title={`未提交：${unCommitJudgeArray.join('、')}`} placement="bottomLeft">
              <span>{`${unCommitTotal}人未提交`}</span>
            </Tooltip>
          </div>
        )}
      </div>
      <div className={styles('card-right')}>
        <Image
          src={image}
          preview={false}
          width={240}
          height={120}
          fallback={placeholderImg}
          placeholder={<Image src={placeholderImg} preview={false}></Image>}
        />
      </div>
    </div>
  );
}

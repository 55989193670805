import { menuConfig } from '@/router/config';

function formatter(data = menuConfig, parentPath = ''): RouteConfig[] {
  const list: RouteConfig[] = [];
  data.forEach((item: RouteConfig) => {
    if (item.hidden) return;
    if (item.children) {
      list.push({
        ...item,
        path: `${parentPath}${item.path}`,
        children: formatter(item.children, `${parentPath}${item.path}`),
      });
    } else {
      list.push({
        ...item,
        path: `${parentPath}${item.path}`,
      });
    }
  });
  return list;
}

const getMenuData = () => formatter(menuConfig);

export default getMenuData;

import isFQDN from 'validator/lib/isFQDN';
import { getServiceUrlSuffix } from './environment';
import { EnvValue } from '@ai-training/pages/ability/components/EnvSetup';
import { ResourceValue } from '@ai-training/types/ability';

const VITE_SERVICE_URL_SUFFIX = getServiceUrlSuffix();

function _validatePorts(input) {
  const validFormat = /^\d+$/;

  if (!validFormat.test(input)) {
    return [false, '端口号仅支持数字'];
  }

  // 检查每个端口号是否在1到65535的范围内
  if (Number(input) < 1 || Number(input) > 65535) {
    return [false, '端口号的范围是 [1, 65535]'];
  }

  return [true, ''];
}

export const firstLastNotStr =
  str =>
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (value?.[0] === str || value?.[value.length - 1] === str) {
          return Promise.reject(new Error(`名称首和尾字符不能为连字符 “${str}”`));
        }
        return Promise.resolve();
      },
    });

export const ruleMap = {
  isDomain: () => ({
    validator: (_: any, value: string) => {
      if (!value) {
        return Promise.resolve();
      }
      if (value?.length === 1 && !/[-.]/.test(value)) {
        return Promise.resolve();
      }
      if (value.length > 63) {
        return Promise.reject('域名总长度不可超过63个字符!');
      }
      if (!/^[a-z0-9]+([a-z0-9-.])*[a-z0-9]$/.test(value)) {
        return Promise.reject(
          '域名必须由小写字母、数字、“-”和“.”组成，首字符必须为字母或数字，尾字符必须为字母或数字!'
        );
      }
      const validaResult = isFQDN(value + VITE_SERVICE_URL_SUFFIX, {
        require_tld: false,
      });
      if (!validaResult) {
        return Promise.reject(new Error(`请填写有效地址`));
      }
      return Promise.resolve();
    },
  }),
  isFQDN: () => ({
    validateTrigger: 'onBlur',
    validator: (_: any, value: string[]) => {
      const validaResult = isFQDN(value + VITE_SERVICE_URL_SUFFIX, {
        require_tld: false,
      });
      if (!validaResult) {
        return Promise.reject(new Error(`请填写有效地址`));
      }
      return Promise.resolve();
    },
  }),
  isValidPort: () => ({
    validator: (_: any, value: string) => {
      if (!value) {
        return Promise.resolve();
      } else {
        const [validaResult, errorMessage] = _validatePorts(value);
        if (!validaResult) {
          return Promise.reject(new Error(errorMessage as string));
        }
        return Promise.resolve();
      }
    },
  }),
  isValidsafeTeamWorkNumber: () => ({
    validator: (_: any, value: string) => {
      if (!value) {
        return Promise.resolve();
      } else if (/^(https|http|ftp|rtsp|mms):\/\/soc\.netease\.com\/.+/.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('请输入正确的安全扫描工单地址'));
    },
  }),
  isValidCommand: () => ({
    validator: (_: any, value: string) => {
      if (!value) {
        return Promise.resolve();
      } else {
        const newlineRegex = /(\r\n|\n|\r)/gm;
        const validaResult = !newlineRegex.test(value);
        if (!validaResult) {
          return Promise.reject(new Error('命令中不能包含换行符'));
        }
        return Promise.resolve();
      }
    },
  }),
  isValidMountPath: () => ({
    validator: (_: any, value: string) => {
      if (!value) {
        return Promise.resolve();
      } else {
        const validaResult = _isValidPath(value);
        if (!validaResult) {
          return Promise.reject(new Error('这不是1个合法的路径，请以 / 开头，且不包含 "/." 或 "/.." '));
        }
        return Promise.resolve();
      }
    },
  }),
  isValidEnvVariable: (envVariableKeyList: Map<string, string>, setIsTipUserEnv: (isCorrect: boolean) => void) => ({
    validator: (_: any, value: EnvValue) => {
      if (!value || !value.name) {
        setIsTipUserEnv(false);
        return Promise.resolve();
      } else {
        const { name, isBlur } = value;
        const regex = /^(?![0-9])[A-Za-z0-9_]+$/;
        const validaResult = regex.test(name!);
        if (!validaResult) {
          setIsTipUserEnv(true);
          return Promise.reject(new Error(''));
        }
        if (name.length > 256) {
          return Promise.reject(new Error('key 长度不能超过256个字符！'));
        }
        if (isBlur && name) {
          // 此时需要判断是否重复
          for (const [envKey] of envVariableKeyList.entries()) {
            if (envKey === name) {
              return Promise.reject(new Error('环境变量名不允许与系统变量名重复！'));
            }
          }
        }

        setIsTipUserEnv(false);
        return Promise.resolve();
      }
    },
  }),
  isValidGitCommitId: () => ({
    validator: (_: any, value: string) => {
      if (!value) {
        return Promise.resolve();
      } else {
        const regex = /^[0-9a-fA-F]{40}$/gm;
        const validaResult = regex.test(value);
        if (!validaResult) {
          return Promise.reject(new Error('这不是1个合法的 Commit ID!'));
        }
        return Promise.resolve();
      }
    },
  }),
  isValidGitRepo: () => ({
    validator: (_: any, value: string) => {
      if (!value) {
        return Promise.resolve();
      } else {
        const regex = /^(https?|git|ssh)\:\/\/[\w\.@\:\/\-~]+(\.git)(\/?|\#[\d\w\.\_\-]+)?$/;
        const validaResult = regex.test(value);
        if (!validaResult) {
          return Promise.reject(new Error('这不是1个合法的 Git 仓库地址格式!'));
        }
        return Promise.resolve();
      }
    },
  }),
  isValidCpuConfig: () => ({
    validator: (_: any, value: ResourceValue) => {
      if (!value) {
        return Promise.resolve();
      } else {
        const { cpu = 0, cpuUnit, memory = 0 } = value;
        if ((cpuUnit === '核' && cpu > 16) || (cpuUnit === 'm' && cpu > 16000)) {
          return Promise.reject(new Error('CPU 核数不能超过 16'));
        } else if (cpu === 0) {
          return Promise.reject(new Error('CPU 不能为0'));
        } else if (memory === 0) {
          return Promise.reject(new Error('内存 不能为0'));
        } else if (cpu < 0) {
          return Promise.reject(new Error('CPU 不能为负数'));
        } else if (memory < 0) {
          return Promise.reject(new Error('内存 不能为负数'));
        }
        return Promise.resolve();
      }
    },
  }),
  isValidDatasetName: () => ({
    validator: (_: any, value: string) => {
      if (!value) {
        return Promise.resolve();
      } else {
        const regex = /^[a-z][a-z0-9_]{0,47}$/;
        const validaResult = regex.test(value);
        if (!validaResult) {
          return Promise.reject(
            new Error('数据集的名称必须以字母开头，并只包含小写字母、数字和下划线，且长度不超过48个字符')
          );
        }
        return Promise.resolve();
      }
    },
  }),
  isValidImageFile: () => ({
    validator: (_: any, value: string) => {
      if (!value) {
        return Promise.resolve();
      } else {
        const regex = /^\s+|\s+$/;
        const validaResult = regex.test(value);
        if (validaResult) {
          return Promise.reject(new Error('镜像仓库名称的首尾不能包含空格'));
        }
        return Promise.resolve();
      }
    },
  }),
  isValidArgs: () => ({
    validator: (_: any, value: string) => {
      if (!value) {
        return Promise.resolve();
      } else {
        const newlineRegex = /(\r\n|\n|\r)/gm;
        const validaResult = !newlineRegex.test(value);
        if (!validaResult) {
          return Promise.reject(new Error('不能包含换行符'));
        }
        return Promise.resolve();
      }
    },
  }),
};

function _isValidPath(inputPath) {
  // Check if the input starts with '/'
  if (!inputPath.startsWith('/')) {
    return false;
  }

  // Check if the input contains '..'
  if (inputPath.includes('..')) {
    return false;
  }

  // Check if the input contains '/.' as a segment
  if (inputPath.includes('/.')) {
    return false;
  }

  return true;
}

import React, { useRef } from 'react';
import { Flex, Spin, Row, Col } from '@fuxi/eevee-ui';
import FlexItem from '@fuxi/eevee-ui/dist/components/Flex/FlexItem';
import { useIsMiniProject } from '@/hooks/useIsMiniProject';
import { ProjectStatus } from '@/constants';
import ProjectDetailContainer from '../ProjectDetailContainer';
import ProjectInfo from '../ProjectInfo';
import TplProjectCard from '../TplProjectCard';
import ProjectMarkDownTab from '../ProjectMarkDownTab';

import { ProjectInformation } from '../ProjectInformation';
import cx from './ProjectDesc.module.less';

type Props = {};

export const ProjectDesc = (props: Props) => {
  const isMiniProject = useIsMiniProject();
  const mdRef = useRef<{ showSaveModal: Function }>(null);
  const container = ProjectDetailContainer.useContainer();
  const { fetchProjectInfoLoading, isTpl, currentProject } = container;

  return (
    <Flex flexDirection="column" className={cx('desc-wrapper')}>
      <FlexItem flexGrow={0} className={cx('description-card')}>
        <Spin spinning={fetchProjectInfoLoading && currentProject.status !== ProjectStatus.Creating}>
          <Row gutter={[16, 16]}>
            <Col span={isTpl ? 16 : 24}>
              <ProjectInfo />
            </Col>
            {isTpl && (
              <Col span={8}>
                <TplProjectCard />
              </Col>
            )}
          </Row>
        </Spin>
      </FlexItem>
      <FlexItem className={cx('markdown-wrapper')} flexGrow={1}>
        {isMiniProject ? (
          <ProjectInformation editPermission={true}></ProjectInformation>
        ) : (
          <ProjectMarkDownTab ref={mdRef} />
        )}
      </FlexItem>
    </Flex>
  );
};

import { Env, getEnv } from '@/utils/getEnv';

export enum AuditStatus {
  // 未知异常
  UNKNOW = 'AUDIT_STATUS_UNSPECIFIED',
  // 未审批
  WAITING = 'AUDIT_STATUS_WAITING',
  // 通过
  PASS = 'AUDIT_STATUS_PASS',
  // 不通过
  REJECT = 'AUDIT_STATUS_REJECT',
}

export const AuditTypeToTextMap = {
  [AuditStatus.PASS]: '审核通过',
  [AuditStatus.REJECT]: '审核不通过',
  [AuditStatus.WAITING]: '未审核',
  [AuditStatus.UNKNOW]: '未知异常',
};

export const ZhongbaoPlatformUrl = {
  [Env.Dev]: 'https://zhongbao-manage.apps-sl.danlu.netease.com',
  [Env.Qa]: 'https://zhongbao-manage.apps-qa.danlu.netease.com',
  [Env.Prod]: 'https://zhongbao-manage.apps-fp.danlu.netease.com',
};

export const getZhongbaoPlatformUrl = () => {
  const env = getEnv();
  let localZhongbaoPlatformUrl;
  switch (env) {
    case Env.Dev:
      localZhongbaoPlatformUrl = ZhongbaoPlatformUrl[Env.Dev];
      break;
    case Env.DDlQa:
    case Env.Qa:
      localZhongbaoPlatformUrl = ZhongbaoPlatformUrl[Env.Qa];
      break;
    case Env.Prod:
      localZhongbaoPlatformUrl = ZhongbaoPlatformUrl[Env.Prod];
      break;
    default:
      localZhongbaoPlatformUrl = ZhongbaoPlatformUrl[Env.Dev];
      break;
  }

  const zhongbaoPlatformUrl = process.env.ZHONGBAO_PLATFORM_URL || localZhongbaoPlatformUrl;
  return zhongbaoPlatformUrl;
};

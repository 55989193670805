import React, { ReactNode } from 'react';
import { Modal, Form, message, Select } from '@fuxi/eevee-ui';
import { useAntModal } from '@fuxi/eevee-hooks';
import _ from 'lodash';

import axios from '@/service/common';

import VersionFormItem from './VersionFormItem';
import ProjectDetailContainer from './ProjectDetailContainer';

const DownloadModal: React.FC<{
  serviceId: string;
  serviceName: string;
  isPersonal?: boolean;
  children: ReactNode;
}> = ({ isPersonal, serviceId, serviceName, children }) => {
  const [form] = Form.useForm();
  const { currentProject } = ProjectDetailContainer.useContainer();
  const { id: projectId } = currentProject;

  const sdkLists = ['unity', 'python_x86', 'python_arm'];

  const handleOkCallback = async () => {
    const { tag, sdk } = await form.validateFields();
    const sha = isPersonal ? 'personal' : tag;
    await axios
      .get(`/projects/${projectId}/repository/client?sha=${sha}&serviceId=${serviceId}&type=${sdk}`, {
        responseType: 'blob',
      })
      .then(res => {
        let blob = new Blob([res.data], { type: 'application/octet-stream' });
        let url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'clientSDK.zip';
        link.click();
        URL.revokeObjectURL(url);
        message.success('下载成功');
      })
      .catch(async error => {
        const responseObj = await error.response.data.text();
        const msg = _.get(JSON.parse(responseObj), 'error.response.message', '项目不存在或无权限');
        message.error(msg);
      });
  };

  const { showModal, commonAntModalProps } = useAntModal({
    handleOkCallback,
  });

  return (
    <>
      <div onClick={showModal}>{children}</div>
      <Modal
        title="下载客户端SDK"
        destroyOnClose
        bodyStyle={{ paddingBottom: 0 }}
        {...commonAntModalProps}
        okText="下载">
        <Form form={form} preserve={false} labelCol={{ span: 5 }}>
          <Form.Item label="服务器名称">{serviceName}</Form.Item>
          {!isPersonal && <VersionFormItem serviceId={serviceId} label="下载版本" placeholder="请选择或输入版本" />}
          <Form.Item
            label="SDK类型"
            name="sdk"
            required
            rules={[
              {
                required: true,
                message: '请选择SDK类型',
              },
            ]}>
            <Select placeholder="请选择SDK类型">
              {sdkLists.length &&
                sdkLists.map((item: string) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DownloadModal;

import { ServiceStatusType } from '../types/onlineService';

export const serviceStatusTypeMap = {
  [ServiceStatusType.RUNNING]: {
    label: '运行中',
    color: '#36BD1F',
  },
  [ServiceStatusType.PAUSE_RECEIVE]: {
    label: '暂停中',
    color: '#4C4C4C',
  },
  [ServiceStatusType.PAUSE_CREATE]: {
    label: '暂停中',
    color: '#4C4C4C',
  },
  [ServiceStatusType.PAUSE_FINISH]: {
    label: '已暂停',
    color: '#FADC19',
  },
  [ServiceStatusType.ERROR]: {
    label: '异常',
    color: '#DE5550',
  },
  [ServiceStatusType.STOP_ERROR]: {
    label: '异常',
    color: '#DE5550',
  },
  [ServiceStatusType.UPDATE_ERROR]: {
    label: '异常',
    color: '#DE5550',
  },
  [ServiceStatusType.PAUSE_ERROR]: {
    label: '异常',
    color: '#DE5550',
  },
  [ServiceStatusType.RESTART_ERROR]: {
    label: '异常',
    color: '#DE5550',
  },
  [ServiceStatusType.INIT]: {
    label: '等待调度',
    color: '#FF9500',
  },
  [ServiceStatusType.CREATE]: {
    label: '等待调度',
    color: '#FF9500',
  },
  [ServiceStatusType.UPDATE_RECEIVE]: {
    label: '更新中',
    color: '#4071F9',
  },
  [ServiceStatusType.UPDATE_CREATE]: {
    label: '更新中',
    color: '#4071F9',
  },
  [ServiceStatusType.RESTART_RECEIVE]: {
    label: '更新中',
    color: '#4071F9',
  },
  [ServiceStatusType.RESTART_CREATE]: {
    label: '更新中',
    color: '#4071F9',
  },
};

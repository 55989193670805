import React, { useState, useRef, PropsWithChildren, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { UploadProps } from 'antd/es/upload';
import { UploadOutlined } from '@ant-design/icons';
import { useUpdateEffect } from '@fuxi/eevee-hooks';
import { Button, message, Upload as AntUpload, UploadFile, Form } from '@fuxi/eevee-ui';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash-es';

import { VersionBaseFieldNames } from './VersionBaseConfig';
import service from '../../../../service';
import cx from './UploadModel.module.less';

export enum UploadStatus {
  WAITING = 'waiting',
  UPLOADING = 'uploading',
  FAIL = 'error',
  SUCCESS = 'done',
  ASSEMBLE = 'assemble', // 发送分片finish请求，服务器组装文件中
}
type Slice = {
  start: number;
  end: number;
  seq: number;
  // 针对上传文件列表的情况，上传任务池面向分片为单位划分的任务集合，故需要每个数据携带fileMd5来维护数据信息
  file: UploadFile & File;
  fileID: string;
  totalSlices: number;
};
type UploadTask = {
  slice: Slice;
  controller: AbortController;
  isFetching: boolean;
};

export type MultipartUploadInterface = UploadProps &
  PropsWithChildren & {
    value?: string[];
    onSuccess?: (fileName: string, file: File) => any;
    onError?: (fileName: string, file: File) => any;
    onFileNameListChange?: (fileNameList: UploadFile<any>[]) => any;
    validateFileName?: (fileNameList: UploadFile<any>[]) => any;
    setDdlOptions?: ({ ddlInputOptions, ddlOutputOptions }) => any;
  };

const Upload: React.FC<MultipartUploadInterface> = props => {
  const { onChange, onError, onSuccess, onRemove, onFileNameListChange, validateFileName, setDdlOptions, value } =
    props;
  const urlParams = useParams();
  const [urlState] = useSearchParams();
  const abilityName = urlState.get('ability_name');
  const formInstance = Form.useFormInstance();
  const [fileList, setFileList] = useState<UploadFile[]>([]); // 文件列表状态更新

  const currentUploadId = useRef(''); //uploadId

  const beforeUpload = file => {
    if (file.size <= 1024 * 1024 && /^.*\.py$/.test(file.name)) return true;
    message.warning('单文件大小不超过1MB，且只能上传.py文件');
    return false;
  };

  const wrapOnRemove = (file: UploadFile) => {
    setFileList([]);
    formInstance.setFieldValue(VersionBaseFieldNames.DatasetInput, undefined);
    formInstance.setFieldValue(VersionBaseFieldNames.DatasetOutput, undefined);
    setDdlOptions?.({ ddlInputOptions: [], ddlOutputOptions: [] });
    return true;
  };

  const handleChange = info => {};

  const customRequest = async file => {
    currentUploadId.current = `${uuid()}`;
    const fd = new FormData();
    fd.append('capability_code', abilityName!);
    fd.append('id', currentUploadId.current);
    fd.append('file', file.file);
    fd.append('type', 'ddl');

    setFileList([{ name: file.file.name, uid: file.file.uid, percent: 0, status: UploadStatus.UPLOADING }]);
    await service.upload.uploadDDL(fd);
    await setFileList([{ name: file.file.name, uid: file.file.uid, percent: 100, status: UploadStatus.SUCCESS }]);

    const ddlRes = await service.ability.getDdlParserData(currentUploadId.current);
    if (ddlRes.status === 200) {
      message.success('ddl文件解析成功，请选择Input字段和Output字段');
      const ddlOptions = Object.entries(ddlRes.data.ddl).map(([key, value]) => ({ label: key, value }));
      setDdlOptions?.({ ddlInputOptions: ddlOptions, ddlOutputOptions: ddlOptions });
      formInstance.setFieldValue(VersionBaseFieldNames.DdlId, currentUploadId.current);
      formInstance.setFieldValue(VersionBaseFieldNames.DatasetInput, undefined);
      formInstance.setFieldValue(VersionBaseFieldNames.DatasetOutput, undefined);
    }
  };

  useUpdateEffect(() => {
    setFileList(value);
  }, [value?.length]);

  useUpdateEffect(() => {
    const fileNameList = fileList?.filter(file => !(file.status === UploadStatus.FAIL));
    onFileNameListChange?.(fileNameList);
    validateFileName?.(fileList);
    onChange?.({ file: {} as any, fileList });
  }, [fileList]);

  useEffect(() => {
    !isEmpty(value) && setFileList(value);
  }, []);

  return (
    <AntUpload
      name="file"
      fileList={fileList}
      accept={'.py'}
      onRemove={wrapOnRemove}
      onChange={handleChange}
      beforeUpload={beforeUpload}
      customRequest={customRequest}
      className={cx['ai-train-upload']}>
      <Button type="dashed" icon={<UploadOutlined />}>
        点击上传
      </Button>
    </AntUpload>
  );
};

export default Upload;

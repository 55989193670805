import React from 'react';
import FilterArea from '@/pages/templateCenter/FilterArea';
import TemplateList from '@/pages/templateCenter/TemplateList';
import TemplateDetail from '@/pages/templateCenter/TemplateDetail';

const TemplateCenter: React.FC = () => {
  return (
    <>
      <FilterArea />
      <TemplateList />
      <TemplateDetail />
    </>
  );
};

export default TemplateCenter;

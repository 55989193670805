import { gql } from '@apollo/client';

export const AUDIT_AI_CAPABILITY = gql`
  mutation AuditAiCapability($projectId: ID!, $applyId: ID!, $status: AuditStatus!, $detail: String) {
    auditAiCapability(projectId: $projectId, applyId: $applyId, status: $status, detail: $detail)
  }
`;

export const AI_CAPABILITY_APPLY_LIST = gql`
  query AiCapabilityApplyList($projectId: ID, $size: Int) {
    aiCapabilityApplyList(projectId: $projectId, size: $size)
  }
`;

export const GET_LATEST_APPLY = gql`
  query GetLatestApply($projectId: ID!) {
    getLatestApply(projectId: $projectId)
  }
`;

export const AI_CAPABILITY_APPLY = gql`
  mutation ApplyProjectAiCap($input: AiApplyInput) {
    aiCapabilityApply(input: $input)
  }
`;

export type ClusterResource = {
  cpu: number;
  memory: number;
  gpu: number;
};

export const isClusterResourceOverflow = ({
  usage,
  limitation,
}: {
  usage: ClusterResource;
  limitation: ClusterResource;
}) => {
  if (!usage || !limitation) return false;
  const { cpu: currentCpu, memory: currentMemory, gpu: currentGpu } = usage;
  const { cpu: limitCpu, memory: limitMemory, gpu: limitGpu } = limitation;
  return currentCpu > limitCpu || currentMemory > limitMemory || currentGpu > limitGpu;
};

export const maxParallelNum = ({
  usage,
  limitation,
  itemResource,
}: {
  usage: ClusterResource;
  limitation: ClusterResource;
  itemResource: ClusterResource;
}) => {
  const { cpu: currentCpu, memory: currentMemory, gpu: currentGpu } = usage;
  const { cpu: limitCpu, memory: limitMemory, gpu: limitGpu } = limitation;
  const { cpu: itemCpu, memory: itemMemory, gpu: itemGpu } = itemResource;
  const cpuNum = Math.floor((limitCpu - currentCpu) / itemCpu);
  const memoryNum = Math.floor((limitMemory - currentMemory) / itemMemory);
  const gpuNum = Math.floor((limitGpu - currentGpu) / itemGpu);
  return Math.min(cpuNum, memoryNum, gpuNum);
};

import client from '@/service/graphql';
import { ProjectReviewAppraise, ReviewStage } from '@/store/miniReview';
import { UploadReviewAppraiseInput } from '@/pages/miniReview/utils';

import {
  GET_PROJECT_SEASONS,
  GET_REVIEW_STAGES,
  GET_REVIEW_APPRAISE,
  GET_SEASON_PROJECTS,
  GET_PROJECT_REVIEWS,
  GET_STAGE_PROJECTS,
} from './schema/miniReview/miniReview-queries';
import { UPLOAD_REVIEW_APPRAISE } from './schema/miniReview/miniReview-mutations';

export type ProjectReview = {
  projectId: string; //项目Id
  projectName: string; //项目名称
  description: string; //项目简述
  image: string; //项目图片
  stage: string; //项目评审阶段
  status: string; //状态
  judge: string; //评委邮箱
  judgeName: string; //评委姓名
  reviewDetail: string;
  [props: string]: any;
};

export const getProjectSeasons = () => {
  return client.query(GET_PROJECT_SEASONS);
};

export const getReviewAppraise = (stageId: string) => {
  return client
    .query<{ stageId: string }, any>(GET_REVIEW_APPRAISE, { stageId })
    .then(data => data?.reviewAppraise as { judge: string; judgeName: string; appraises: any[] }[]);
};

export const getSeasonProjects = (seasonId: string) => {
  return client.query<{ seasonId: string }, { seasonProjects: { total: number; projects: any[] } }>(
    GET_SEASON_PROJECTS,
    { seasonId }
  );
};

export const getStageProjects = (stageId: string) => {
  return client.query<{ stageId: string }, { stageProjects: { total: number; projects: any[] } }>(GET_STAGE_PROJECTS, {
    stageId,
  });
};

export const getProjectReviews = (projectId: number, stageId: string) => {
  return client.query(GET_PROJECT_REVIEWS, { projectId, stageId });
};

export const uploadReviewAppraise = (UploadReviewAppraiseInput: UploadReviewAppraiseInput) => {
  return client.mutate<{ UploadReviewAppraiseInput: UploadReviewAppraiseInput }, { uploadReviewAppraise: boolean }>(
    UPLOAD_REVIEW_APPRAISE,
    {
      UploadReviewAppraiseInput,
    }
  );
};

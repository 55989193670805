/* eslint-disable max-depth */
import { message } from 'antd';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { virtualTerminalApi } from '@/service/virtualTerminal';
import { ClusterResource } from '@/utils/isResourceOverflow';

import { AppThunk } from '.';

export const VIRTUAL_MACHINE_STATUS_VALUE = {
  running: 'running',
  poweroff: 'poweroff',
  shutting_down: 'shutting_down',
  booting_up: 'booting_up',
  deleted: 'deleted',
  creating: 'creating',
  error: 'error',
  exporting: 'exporting',
  copying: 'copying',
  deleting: 'deleting',
};

export const VIRTUAL_MACHINE_STATUS = {
  running: {
    label: '运行',
    color: '#61ba3f',
    value: 'running',
  },
  poweroff: {
    label: '离线',
    color: '#ce5e56',
    value: 'poweroff',
  },
  shutting_down: {
    label: '关闭中',
    color: '#f19a37',
    value: 'shutting_down',
  },
  booting_up: {
    label: '启动中',
    color: '#f19a37',
    value: 'booting_up',
  },
  deleted: {
    label: '已删除',
    color: '#ce5e56',
    value: 'deleted',
  },
  creating: {
    label: '创建中',
    color: '#f19a37',
    value: 'creating',
  },
  error: {
    label: '异常',
    color: '#ce5e56',
    value: 'error',
  },
  exporting: {
    label: '保存镜像中',
    color: '#f19a37',
    value: 'exporting',
  },
  copying: {
    label: '并行复制中',
    color: '#f19a37',
    value: 'copying',
  },
  deleting: {
    label: '删除中',
    color: '#f19a37',
    value: 'deleting',
  },
};

export type VirtualMachineImage = {
  id: number;
  displayName: string;
  imageName: string;
  imageRepo: string;
  imageTag: string;
  port: number;
  defaultProtocol: string;
  systemType: string;
  projectId: string;
  currentStatus: string;
  public: boolean;
};

export type RemoteConnectInfo = {
  host: string;
  port: number;
  username: string;
  protocol: string;
};

export type VirtualMachine = {
  id: number;
  name: string;
  status: string;
  type: string;
  runningTime: string;
  imageName: string;
  imageTag: string;
  remoteConnectInfo: RemoteConnectInfo;
  progress: string;
  startAt: string;
  msg: string;
  requestCpu: number;
  requestMemory: number;
  gpu: number;
};

export type VirtualMachineGroup = {
  parallelId: string;
  parallelCount: number;
  name: string;
  type: string;
  imageName: string;
  imageTag: string;
  vmList: VirtualMachine[];
} & VirtualMachine;

export type VirtualTerminalState = {
  virtualMachineTableLoading: boolean;
  virtualMachineGroup: VirtualMachineGroup[];
  groupCount: number;
  virtualMachineImages: VirtualMachineImage[];
  resource: {
    usage: ClusterResource;
    limitation: ClusterResource;
  };
};

const initialState: VirtualTerminalState = {
  virtualMachineTableLoading: false,
  virtualMachineGroup: [],
  groupCount: 0,
  virtualMachineImages: [],
  resource: {
    usage: {
      cpu: 0,
      memory: 0,
      gpu: 0,
    },
    limitation: {
      cpu: 0,
      memory: 0,
      gpu: 0,
    },
  },
};

const virtualTerminal = createSlice({
  name: 'virtualTerminal',
  initialState,
  reducers: {
    setVirtualMachineGroup(
      state,
      { payload }: PayloadAction<{ virtualMachineGroup: VirtualMachineGroup[]; groupCount: number }>
    ) {
      state.virtualMachineGroup = payload.virtualMachineGroup;
      state.groupCount = payload.groupCount;
    },
    setVirtualMachineImages(state, { payload }: PayloadAction<VirtualMachineImage[]>) {
      state.virtualMachineImages = payload;
    },
    setVirtualMachineTableLoading(state, { payload }: PayloadAction<boolean>) {
      state.virtualMachineTableLoading = payload;
    },
    setResource(
      state,
      {
        payload,
      }: PayloadAction<{
        usage: ClusterResource;
        limitation: ClusterResource;
      }>
    ) {
      state.resource = payload;
    },
  },
});

export const getVirtualMachineGroupThunk =
  (page: number, pageSize: number): AppThunk =>
  async (dispatch, getState) => {
    await dispatch(setVirtualMachineTableLoading(true));
    const res = await virtualTerminalApi.getVMs(`${getState().project.currentProject.id}`, page, pageSize);
    try {
      if (res.status === 200) {
        if (res.data) {
          dispatch(setVirtualMachineGroup({ virtualMachineGroup: res.data.group, groupCount: res.data.groupCount }));
        }
      } else throw new Error(res.statusText);
    } catch (error) {
      console.error(error);
    }
    await dispatch(setVirtualMachineTableLoading(false));
  };

export const getVirtualMachineImagesThunk = (): AppThunk => async (dispatch, getState) => {
  if (!getState().project.currentProject.id) {
    return;
  }
  const res = await virtualTerminalApi.getImages(`${getState().project.currentProject.id}`);
  try {
    if (res.status === 200) {
      if (res.data) {
        dispatch(setVirtualMachineImages(res.data.data));
      }
    } else throw new Error(res.statusText);
  } catch (error) {
    console.error(error);
  }
};

export const getVirtualMachineResrouceThunk = (): AppThunk => async (dispatch, getState) => {
  console.log('getVirtualMachineResrouceThunk');
  if (!getState().project.currentProject.id) {
    return;
  }
  const res = await virtualTerminalApi.getResource(`${getState().project.currentProject.id}`);
  try {
    if (res.status === 200) {
      if (res.data) {
        dispatch(setResource(res.data));
      }
    } else throw new Error(res.statusText);
  } catch (error) {
    console.error(error);
  }
};

export const createVirtualMachineGroupThunk =
  (createVMInfo): AppThunk =>
  async (dispatch, getState) => {
    const res = await virtualTerminalApi.createVM(`${getState().project.currentProject.id}`, createVMInfo);
    try {
      if (res.status === 200) {
        dispatch(getVirtualMachineGroupThunk(1, 10000));
      } else throw new Error(res.statusText);
    } catch (error) {
      console.error(error);
    }
  };

export const deleteVirtualMachineGroupThunk =
  (vmIdList: number[]): AppThunk =>
  async (dispatch, getState) => {
    const res = await virtualTerminalApi.deleteVM(`${getState().project.currentProject.id}`, vmIdList);
    try {
      if (res.status === 200) {
        dispatch(getVirtualMachineGroupThunk(1, 10000));
      } else throw new Error(res.statusText);
    } catch (error) {
      console.error(error);
    }
  };

export const updateVirtualMachineGroupThunk =
  (updateVMInfo): AppThunk =>
  async (dispatch, getState) => {
    const res = await virtualTerminalApi.updateVM(`${getState().project.currentProject.id}`, updateVMInfo);
    try {
      if (res.status === 200) {
        message.success('编辑成功');
        dispatch(getVirtualMachineGroupThunk(1, 10000));
      } else throw new Error(res.statusText);
    } catch (error) {
      message.error('编辑失败');
      console.error(error);
    }
  };

export const { setVirtualMachineGroup, setVirtualMachineImages, setVirtualMachineTableLoading, setResource } =
  virtualTerminal.actions;

export default virtualTerminal.reducer;
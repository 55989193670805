import { gql } from '@apollo/client';

export const FORCE_TO_PUSH_SERVICE_VERSION = gql`
  mutation ForceToPushServiceVersion($projectId: ID!, $serviceId: ID!, $tag: String, $terminalsId: [ID]) {
    forceToPushServiceVersion(projectId: $projectId, serviceId: $serviceId, tag: $tag, terminalsId: $terminalsId) {
      msg
      code
      serviceId
      releaseId
    }
  }
`;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TerminalStatus } from '@/constants/terminal';

export type Terminal = {
  id: string;
  name: string;
  status: TerminalStatus;
  type: string | undefined;
  serverId: string | undefined;
  serverName?: string;
  mac?: string;
  nodeName?: string;
  createTime?: string;
  updateTime?: string;
  offlineTime: string;
  sshUrl: string;
  projectId?: string;
  logUrl?: string;
  taskId: string;
  currentVersionAlias: string;
  webTerminalUrl: string;
};

export type EdgeNodeInput = {
  edgeNodeId?: string;
  name: string;
  type?: string;
  serviceId: string;
};

export type UpdateEdgeNodeInput = {
  projectId?: ID;
  input: EdgeNodeInput[];
};

export type TerminalState = {
  currentTerminals: Terminal[];
};

const initialState: TerminalState = {
  currentTerminals: [],
};

const project = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setCurrentTerminals(state, { payload }: PayloadAction<Terminal[]>) {
      state.currentTerminals = payload;
    },
  },
});

export const { setCurrentTerminals } = project.actions;
export default project.reducer;

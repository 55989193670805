import { useState } from 'react';
import _, { isEmpty } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  Button,
  Divider,
  Flex,
  message,
  Spin,
  Modal,
  Tooltip,
  Space,
  Dropdown,
  Menu,
  Table,
  TableColumnsType,
} from '@fuxi/eevee-ui';
import { CopyOutlined, DownloadOutlined, DownOutlined, SyncOutlined } from '@ant-design/icons';
import { TerminalStatus, TerminalStatusMap } from '@/constants/terminal';
import { EdgeNodeInput, Terminal } from '@/store/terminal';
import { resetTerminal, updateTerminal } from '@/service/terminal';
import { ServiceStatus } from '@/typings/common';
import ProjectDetailContainer from '../../ProjectDetail/ProjectDetailContainer';
import { downloadInitTool } from '../../../../service/common';
import { DownloadInitTool } from '../../components/DownloadInitTool';
import { EditTerminalModal } from './EditTerminalModal';
import TerminalServiceDrawer from './TerminalServiceDrawer';
import WebTerminalDrawer from './WebTerminalDrawer';
import { BatchToUpdateModal } from './BatchToOperateModal';
import cx from './index.module.less';

interface Props {}

export const TerminalManage: React.FC<Props> = props => {
  const container = ProjectDetailContainer.useContainer();
  const { serviceList, terminalsData, currentProject, fetchTerminalInfo, fetchTerminalInfoLoading } = container;
  const dataSource: Terminal[] = terminalsData?.edgeNodes?.map((node: Terminal) => ({
    ...node,
    serviceId: node?.serverId === '0' ? '' : node?.serverId,
  }));

  // 都处于运行或异常状态才能重置
  const disableToBatchReset = () => {
    return selectedTerminals?.some(item => ![TerminalStatus.Error, TerminalStatus.Running].includes(item.status));
  };

  // 都处于就绪状态时才能分配服务器
  const disableToBatchToConfigService = () => {
    return selectedTerminals?.some(item => item.status !== TerminalStatus.Ready);
  };

  const batchToActions = [
    {
      key: 'changeType',
      name: '批量修改类型',
      openModal: true,
      field: ['type'],
      disabled: () => false,
      disabledMsg: '',
    },
    {
      key: 'changeService',
      name: '批量配置归属服务器',
      openModal: true,
      field: ['service'],
      disabled: disableToBatchToConfigService,
      disabledMsg: '所有设备均处于 就绪 状态时才能分配服务器',
    },
    {
      key: 'batchToReset',
      name: '批量重置',
      openModal: false,
      field: ['reset'],
      disabled: disableToBatchReset,
      disabledMsg: '所有设备均处于 异常 或 运行 状态时才能重置',
    },
  ];

  const [selectedTerminals, setSelectedTerminals] = useState<Terminal[]>([]);

  const toResetTerminal = async (terminalId: string, termianlName: string) => {
    Modal.confirm({
      content: <span>确定要重置{termianlName}吗?</span>,
      onOk: async () => {
        const res: any = await resetTerminal(currentProject.id, [terminalId]);
        res?.resetEdgeNode && message.success('重置成功，请稍后刷新查看状态');
      },
      className: 'confirm-modal'
    });
  };

  const callback = async (formData: EdgeNodeInput, id: string) => {
    await updateTerminal({
      projectId: currentProject?.id,
      input: [{ ...formData, edgeNodeId: id }],
    });
    fetchTerminalInfo({ variables: { projectId: currentProject?.id } });
  };

  const onBatchOperationsConfirm = async (formData: { type?: string; serviceId?: string }) => {
    const res: any = await updateTerminal({
      projectId: currentProject?.id,
      input: selectedTerminals?.map(item => ({
        name: item.name,
        edgeNodeId: item.id,
        serviceId: formData?.serviceId || item.serverId!,
        type: formData?.type || item.type,
      })),
    });
    !isEmpty(res?.updateEdgeNode) ? message.success('操作成功') : message.error('操作失败');
    setSelectedTerminals([]);
    fetchTerminalInfo({ variables: { projectId: currentProject?.id } });
  };

  const columns: TableColumnsType<Terminal> = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      render: (name, termianl: Terminal) => (
        <div className={cx('name-in-cell')}>
          <Tooltip title={termianl?.id}>{termianl?.id}</Tooltip>
        </div>
      ),
    },
    {
      title: '终端名称',
      dataIndex: 'name',
      key: 'name',
      render: (name, termianl: Terminal) => (
        <div className={cx('name-in-cell')}>
          <Tooltip placement="topLeft" title={name}>
            {name}
          </Tooltip>
        </div>
      ),
      // width: 300,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      // width: 230,
      filters: Object.keys(TerminalStatusMap)?.map(key => ({ text: TerminalStatusMap?.[key]?.label, value: key })),
      onFilter: (value, terminal: Terminal) => terminal?.status === value,
      render: (status: TerminalStatus, termianl: Terminal) => (
        <div className={cx('status-in-cell')}>
          <Flex alignCenter justifyBetween>
            <Flex alignCenter>
              <div className={cx('status-icon')} style={{ backgroundColor: TerminalStatusMap?.[status]?.color }}></div>
              <div>{TerminalStatusMap?.[status]?.label}</div>
            </Flex>
            {[TerminalStatus.Running, TerminalStatus.Error].includes(status) && (
              <div onClick={() => toResetTerminal(termianl.id, termianl.name)}>
                <Flex className={cx('reset-button')} alignCenter>
                  <SyncOutlined />
                  <span>重置</span>
                </Flex>
              </div>
            )}
          </Flex>
          {status === TerminalStatus.Offline && (
            <span className={cx('offline-time')}>
              <Tooltip
                placement="topLeft"
                title={`离线时间：${termianl.offlineTime}`}>{`离线时间：${termianl.offlineTime}`}</Tooltip>
            </span>
          )}
        </div>
      ),
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      filters: _.uniqWith(
        dataSource?.map(data => data?.type ?? ''),
        _.isEqual
      )
        ?.map(type => (type ? { text: type, value: type } : { text: '未定义', value: '' }))
        ?.sort((a, b) => b?.value?.length - a?.value?.length),
      onFilter: (value, terminal: Terminal) => terminal?.type === value,
      render: (_: any, term: Terminal) => (term?.type ? term?.type : <span className={cx('error')}>未定义</span>),
    },
    {
      title: '归属服务器',
      dataIndex: 'serverId',
      key: 'serverId',
      filters: _.uniqWith(
        dataSource?.map(data => data?.serverId ?? ''),
        _.isEqual
      )
        ?.map(serviceId =>
          !!serviceList?.find(service => service?.id === serviceId)
            ? {
                text: serviceList?.find(service => service?.id === serviceId)?.name,
                value: serviceId,
              }
            : { text: '未分配', value: '' }
        )
        ?.sort((a, b) => +b.value - +a.value),
      onFilter: (value, terminal: Terminal) =>
        (serviceList?.find(service => service?.id === terminal?.serverId)?.id ?? '') === value,
      render: (_: any, terminal: Terminal) =>
        serviceList?.find(s => s.id === terminal?.serverId)?.name || <span className={cx('error')}>未分配</span>,
    },
    {
      title: 'SSH访问地址',
      dataIndex: 'sshUrl',
      key: 'sshUrl',
      width: 200,
      render: (_: any, terminal: Terminal) => (
        <CopyToClipboard text={terminal?.sshUrl || ''} onCopy={() => message.success('复制成功！')}>
          <Space>
            <div className={cx('name-in-cell-ssh')}>
              <Tooltip placement="topLeft" title={terminal?.sshUrl}>
                {terminal?.sshUrl}
              </Tooltip>
            </div>
            <CopyOutlined className={cx('copy-icon')} />
          </Space>
        </CopyToClipboard>
      ),
    },
    {
      title: '所属版本',
      dataIndex: 'currentVersionAlias',
      key: 'currentVersionAlias',
      render: (_: any, terminal: Terminal) => terminal.currentVersionAlias,
    },
    {
      title: '操作',
      dataIndex: 'stubUrl',
      key: 'stubUrl',
      render: (_: any, terminal: Terminal, index: number) => (
        <Flex alignCenter>
          <span>
            {
              <EditTerminalModal
                initialValues={{
                  name: terminal?.name,
                  type: terminal?.type!,
                  serviceId: terminal?.serverId!,
                }}
                serviceList={serviceList}
                callback={data => callback(data, terminal.id)}
                terminal={terminal}></EditTerminalModal>
            }
          </span>
          <Divider type="vertical"></Divider>
          <WebTerminalDrawer id={terminal?.id} ssh={terminal?.webTerminalUrl}>
            <span className={cx('operation')}>终端</span>
          </WebTerminalDrawer>
          <Divider type="vertical"></Divider>
          <TerminalServiceDrawer
            taskId={terminal?.taskId}
            status={terminal.status as unknown as ServiceStatus}
            nodeName={terminal.nodeName!}
            logUrl={terminal.logUrl!}
            id={terminal?.id}>
            <span className={cx('operation')}>查看日志</span>
          </TerminalServiceDrawer>
        </Flex>
      ),
    },
  ];

  const toDownloadInitTool = (serviceId: string) => {
    downloadInitTool({ projectId: currentProject?.id, serviceId });
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Terminal[]) => {
      setSelectedTerminals(selectedRows);
    },
    getCheckboxProps: (record: Terminal) => ({
      name: record.name,
    }),
  };

  const batchResetTerminals = () => {
    if (disableToBatchReset()) {
      message.error(`所有设备均处于 异常 或 运行 状态时才能重置`);
      return;
    }

    Modal.confirm({
      content: `确定要重置选中的${selectedTerminals?.length}项终端设备吗`,
      onOk: async () => {
        const res: any = await resetTerminal(
          currentProject.id,
          selectedTerminals?.map(item => item.id)
        );
        res?.resetEdgeNode ? message.success('重置成功') : message.success('重置失败');
      },
      className: 'confirm-modal'
    });
  };

  return (
    <div className={cx('terminal-manage-wrapper')}>
      <div className={cx('terminal-title')}>终端设备</div>
      <Spin spinning={fetchTerminalInfoLoading}>
        <Flex alignCenter className={cx('action-area')}>
          <DownloadInitTool
            onConfirmBack={toDownloadInitTool}
            serviceList={serviceList}
            children={
              <Button className={cx('download-button')} type="primary" icon={<DownloadOutlined />}>
                下载初始化工具
              </Button>
            }></DownloadInitTool>

          <Dropdown
            disabled={selectedTerminals?.length === 0}
            placement="bottomLeft"
            trigger={['click']}
            overlay={
              <Menu>
                {batchToActions?.map(item => (
                  <Menu.Item key={item.key} disabled={item?.disabled()}>
                    {item.openModal ? (
                      <BatchToUpdateModal
                        batchUpdateCallback={onBatchOperationsConfirm}
                        disabled={item.disabled()}
                        disabledMsg={item.disabledMsg}
                        serviceList={serviceList}
                        fieldsList={item.field}
                        title={item.name}
                        children={<div>{item.name}</div>}></BatchToUpdateModal>
                    ) : (
                      <span onClick={() => batchResetTerminals()}>{item.name}</span>
                    )}
                  </Menu.Item>
                ))}
              </Menu>
            }>
            <Button>
              批量操作 <DownOutlined />
            </Button>
          </Dropdown>

          <div className={cx('choosed-length-tip')}>已选{selectedTerminals?.length}项</div>
        </Flex>

        {!!dataSource?.length ? (
          <Table
            rowKey="name"
            rowSelection={rowSelection}
            className={cx('table')}
            pagination={{
              defaultPageSize: 10,
            }}
            dataSource={dataSource}
            columns={columns}
          />
        ) : (
          <div>
            <div className={cx('up')}></div>
            <div className={cx('empty-tip')}>下载初始化工具并在终端上运行，可在此处查看相应终端并进行管理</div>
          </div>
        )}
      </Spin>
    </div>
  );
};

import React from 'react';
import { useMatches, Params, Link } from 'react-router-dom';
import { Breadcrumb as AntBreadcrumb } from '@fuxi/eevee-ui';

interface Matches {
  id: string;
  pathname: string;
  params: Params;
  data: {
    title: string;
  };
  handle: unknown;
}

type BreadcrumbProps = {
  length?: number;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ length }) => {
  const matches = useMatches() as Matches[];
  const bread: Matches[] =
    length === undefined
      ? matches.filter(item => item?.data?.title)
      : matches.filter(item => item?.data?.title).slice(0, length);

  return (
    <div>
      <AntBreadcrumb
        items={(bread.length === 1 ? [] : bread).map((item, index) => ({
          title:
            item.id.length > 1 && index < bread.length - 1 ? (
              <Link to={item.pathname}>{item?.data?.title}</Link>
            ) : (
              item?.data?.title
            ),
        }))}
      />
    </div>
  );
};

export default Breadcrumb;

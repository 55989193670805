import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from '@fuxi/eevee-ui';
import { FieldNamesForTrain, NoneCustomConfigParameter } from '@ai-training/constants/ability';

import { useAppSelector } from '@/hooks';
import { selectFormDataForConfigOfTrain, selectFormDataForBaseOfTrain } from '@/store/aiTraining';
import service from '@ai-training/service';
import EmptyCard from '@ai-training/components/EmptyCard';

import { isCustomConfigFileUpload } from '../../ConfigOfTrain';
import { isFormDataEmpty } from '../../NewAIBodyVersion';
import ResourceConfig from './ResourceConfig';
import EnvSetup from './EnvSetup';
import { ConfigTitle, InfoInput, VersionInfoText, VersionInfoSwitchText, VersionInfoImage } from './ConfigConstant';
import { convertOriginFormDataToValues } from '../ProjectVersionConfigInfo';
import cx from './Configs.module.less';

type Props = {};

export const Train = (props: Props) => {
  const urlParams = useParams();
  const [form] = Form.useForm();
  const [isConfigEmpty, setIsConfigEmpty] = useState(true);
  const [isCodeOpen, setIsCodeOpen] = useState(false);
  const [isDatasetOpen, setIsDatasetOpen] = useState(false);
  const [isTensorbardOpen, setIsTensorbardOpen] = useState(false);
  const [isUseGpu, setIsUseGpu] = useState(false);
  const isInner = useAppSelector(state => state.user.isInner);
  const formDataForConfigOfTrain = useAppSelector(selectFormDataForConfigOfTrain);
  const formDataForBaseOfTrain = useAppSelector(selectFormDataForBaseOfTrain);

  useEffect(() => {
    if (isFormDataEmpty(formDataForConfigOfTrain)) {
      setIsConfigEmpty(true);
    } else {
      const fields = convertOriginFormDataToValues({
        train_dataset_name: formDataForBaseOfTrain?.train_dataset_name,
        ...formDataForConfigOfTrain,
      });

      setIsUseGpu(!!fields?.resources?.gpu && !!(fields?.resources?.gpu > 0));
      setIsCodeOpen(fields[FieldNamesForTrain.Code] === '已开启');
      setIsDatasetOpen(fields[FieldNamesForTrain.IsMountDataset] === '已开启');
      setIsTensorbardOpen(fields[FieldNamesForTrain.Tensorboard] === '已开启');
      form.setFieldsValue(fields ?? {});
      form.setFieldValue('code_mount_path', '/output/workspace');

      isCustomConfigFileUpload(formDataForBaseOfTrain) &&
        service.ability
          .getParameterDetail({
            projectId: urlParams?.projectId ?? '',
            parameterName: formDataForBaseOfTrain![FieldNamesForTrain.ParameterName]![0],
            parameterVersion: formDataForBaseOfTrain![FieldNamesForTrain.ParameterVersion]![0],
          })
          .then(res => {
            if (res.status === 200) {
              form.setFieldValue(FieldNamesForTrain.CustomConfigFile, res?.data?.file_name || '');
            }
          });

      setIsConfigEmpty(false);
    }
  }, [formDataForConfigOfTrain]);

  return (
    <div>
      {isConfigEmpty ? (
        <EmptyCard height={300} imageStyle={{ height: 143, width: 253 }} />
      ) : (
        <Form form={form} labelAlign="right" labelCol={{ style: { width: 130 } }}>
          <div className={cx['config-item']}>
            <div className={cx['config-item-title']}>{ConfigTitle.CodeInfo}</div>
            <div className={cx['config-item-body']}>
              <Form.Item required label="挂载代码：" name={FieldNamesForTrain.Code}>
                <VersionInfoText />
              </Form.Item>
              {isCodeOpen ? (
                <>
                  <Form.Item label="代码库" required name={FieldNamesForTrain.Repo}>
                    <VersionInfoText />
                  </Form.Item>
                  <Form.Item label="分支" required name={FieldNamesForTrain.GitBranch}>
                    <VersionInfoText />
                  </Form.Item>
                  <Form.Item label="Commit ID" required name={FieldNamesForTrain.CommitId}>
                    <VersionInfoText />
                  </Form.Item>
                  <Form.Item label="挂载路径" required name={'code_mount_path'}>
                    <VersionInfoText />
                  </Form.Item>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className={cx['config-item']}>
            <div className={cx['config-item-title']}>{ConfigTitle.RuntimeEnv}</div>
            <div className={cx['config-item-body']}>
              <Form.Item label="镜像" required name={FieldNamesForTrain.ImageFile}>
                <VersionInfoText />
              </Form.Item>
              <Form.Item label="资源配额" required name={FieldNamesForTrain.Resource}>
                <ResourceConfig />
              </Form.Item>
              {isInner && isUseGpu && (
                <Form.Item label="GPU型号" required name={FieldNamesForTrain.GPU}>
                  <VersionInfoText />
                </Form.Item>
              )}
              <Form.Item label="启动命令" name={FieldNamesForTrain.Command}>
                <VersionInfoText />
              </Form.Item>
              <Form.Item label="开放端口" name={FieldNamesForTrain.Ports}>
                <Form.List name={FieldNamesForTrain.Ports}>
                  {fields =>
                    fields.length === 0 ? (
                      <VersionInfoText />
                    ) : (
                      <>
                        {fields.map(({ name, key, ...field }, index) => {
                          return (
                            <div key={key}>
                              <Form.Item
                                label={'开放外网'}
                                name={[name, 'public']}
                                style={{ marginBottom: 0, marginLeft: 28 }}
                                {...field}>
                                <VersionInfoSwitchText />
                              </Form.Item>
                              <Form.Item
                                label={'端口号'}
                                name={[name, 'port']}
                                style={{ marginBottom: 0, marginLeft: 42 }}
                                {...field}>
                                <VersionInfoText />
                              </Form.Item>
                              <Form.Item
                                label={'安全扫描工单'}
                                name={[name, 'safe_team_work_number']}
                                style={{ marginBottom: 0 }}
                                {...field}>
                                <VersionInfoText />
                              </Form.Item>
                              <Form.Item
                                label={'审批通过截图'}
                                name={[name, 'safe_team_pass_screenshot']}
                                style={{ marginBottom: 0 }}
                                {...field}>
                                <VersionInfoImage />
                              </Form.Item>
                            </div>
                          );
                        })}
                      </>
                    )
                  }
                </Form.List>
              </Form.Item>
              <Form.Item label="命令行参数" name={FieldNamesForTrain.Args}>
                <Form.List name={FieldNamesForTrain.Args}>
                  {(fields, { add, remove }, { errors }) =>
                    fields.length === 0 ? (
                      <VersionInfoText />
                    ) : (
                      <>
                        {fields.map((field, index) => {
                          return (
                            <Form.Item style={{ marginBottom: '0' }} {...field}>
                              <VersionInfoText />
                            </Form.Item>
                          );
                        })}
                      </>
                    )
                  }
                </Form.List>
              </Form.Item>
              <Form.Item label="环境变量" name={FieldNamesForTrain.Env}>
                <Form.List name={FieldNamesForTrain.Env}>
                  {(fields, { add, remove }, { errors }) =>
                    fields.length === 0 ? (
                      <VersionInfoText />
                    ) : (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item noStyle key={field.key}>
                            <Form.Item {...field} style={{ marginBottom: '0' }}>
                              <EnvSetup />
                            </Form.Item>
                          </Form.Item>
                        ))}
                        <Form.Item noStyle key={'last-env-var-config'}>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    )
                  }
                </Form.List>
              </Form.Item>
            </div>
          </div>
          <div className={cx['config-item']}>
            <div className={cx['config-item-title']}>{ConfigTitle.OtherConfig}</div>
            <div className={cx['config-item-body']}>
              <Form.Item required label="训练数据" name={FieldNamesForTrain.IsFullTrain}>
                <VersionInfoText />
              </Form.Item>
              <Form.Item required label="数据集挂载" name={FieldNamesForTrain.IsMountDataset}>
                <VersionInfoText />
              </Form.Item>
              {isDatasetOpen ? (
                <Form.Item
                  rules={[
                    { required: true, message: '请输入数据集名称' },

                    {
                      pattern: /^[a-z][a-z0-9_]{0,63}$/,
                      message: '仅允许小写字母、数字、下划线，且只能以小写字母开头， 最多64个字符',
                    },
                  ]}
                  required
                  label="数据集名称"
                  name={FieldNamesForTrain.TrainDatasetName}>
                  <VersionInfoText />
                </Form.Item>
              ) : (
                <></>
              )}
              <Form.Item required label="TensorBoard" name={FieldNamesForTrain.Tensorboard}>
                <VersionInfoText />
              </Form.Item>
              {isTensorbardOpen ? (
                <Form.Item label="存储路径" name={FieldNamesForTrain.PathForTensorbard}>
                  <VersionInfoText />
                </Form.Item>
              ) : (
                <></>
              )}
              <Form.Item label="自定义配置文件" name={FieldNamesForTrain.CustomConfigFile}>
                <VersionInfoText />
              </Form.Item>
              <Form.Item label="数据卷路径" name={FieldNamesForTrain.ProjectStorage}>
                <VersionInfoText />
              </Form.Item>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

import { Table, Flex, Button, Input, TableButtons, message } from '@fuxi/eevee-ui';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAntdTable } from '@fuxi/eevee-hooks';

import { DefaultAiProjectId } from '@ai-training/utils';

import { useAppSelector } from '@/hooks';

import service from '../../service';
import { getSorterTableData } from '../../utils/util';
import { publicColumns } from './const';

import cx from './Public.module.less';

const AbilityPublic: React.FC<{ readOnly?: boolean }> = ({ readOnly = false }) => {
  const aiProjectId = useAppSelector(
    state => state.project.currentMlProject?.aiProjectId || state.project.currentProject?.aiProjectId
  );


  const [keyword, setKeyword] = useState<string>();
  const history = useNavigate();
  const urlParams = useParams();

  let columns = publicColumns;
  const operation = {
    title: '操作',
    render: (_, record) => (
      <TableButtons
        data={[
          {
            text: '调用',
            onClick: () => {
              history(`transfer?abilityId=${record.uuid}`);
            },
          },
        ]}
      />
    ),
  };
  if (urlParams.projectId && !readOnly) {
    columns = [...columns, operation];
  }

  const handleSearch = () => {
    // 不能为纯空格或者空
    if (!keyword) {
      submit();
      return;
    }
    if (!keyword.trim()) {
      message.warning('搜索内容不能为纯空格');
      return;
    }

    submit();
  };

  const {
    tableProps,
    search: { submit },
  } = useAntdTable(getSorterTableData(service.ability.getCapability, { extraParams: { name: keyword, share: true } }), {
    manual: true,
  });

  useEffect(() => {
    if (aiProjectId) {
      window[DefaultAiProjectId] = aiProjectId;
      submit();
    }
  }, [aiProjectId]);

  return (
    <div>
      {readOnly ? (
        <div>
          <Flex alignCenter justifyBetween className={cx.title_wrapper}>
            <div className={cx.title}>公共能力</div>
            <Flex justifyBetween className="page-search">
              <div>
                <Input
                  onChange={e => setKeyword(e.target.value)}
                  placeholder="请输入能力名称进行搜索"
                  style={{ width: '300px' }}
                />
                <Button className={cx.search_btn} type="primary" onClick={handleSearch}>
                  搜索
                </Button>
              </div>
            </Flex>
          </Flex>
          <Table
            columns={columns}
            {...tableProps}
            rowKey="id"
            pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
          />
        </div>
      ) : (
        <div className={cx.public_cap_wrapper_under_project}>
          <Flex alignCenter justifyBetween className={cx.title_wrapper}>
            <div className={cx.title}>公共能力</div>
            <Flex justifyBetween className="page-search">
              <div>
                <Input
                  onChange={e => setKeyword(e.target.value)}
                  placeholder="请输入能力名称进行搜索"
                  style={{ width: '300px' }}
                />
                <Button className={cx.search_btn} type="primary" onClick={handleSearch}>
                  搜索
                </Button>
              </div>
            </Flex>
          </Flex>
          <Table
            columns={columns}
            {...tableProps}
            rowKey="id"
            pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
          />
        </div>
      )}
    </div>
  );
};

export default AbilityPublic;

import { AuditStatus } from '@/constants/aiCap';
import client from './graphql';
import {
  AI_CAPABILITY_APPLY_LIST,
  AI_CAPABILITY_APPLY,
  AUDIT_AI_CAPABILITY,
  GET_LATEST_APPLY,
} from './schema/project/ai-cap';

export const getApplyList = (projectId?: number, size?: number) => {
  return client.query<
    { projectId?: number; size?: number },
    { aiCapabilityApplyList: { auditRecord: any[]; success: boolean; message: string } }
  >(AI_CAPABILITY_APPLY_LIST, {
    projectId,
    size,
  });
};

export const auditCapability = (projectId: number, applyId: number, status: AuditStatus, detail: string) => {
  return client.query<
    { projectId: number; applyId: number; status: AuditStatus; detail: string },
    { auditAiCapability: { auditRecord: any; success: boolean; message: string } }
  >(AUDIT_AI_CAPABILITY, { projectId, applyId, status, detail });
};

export const GetLatestApply = (projectId: number) => {
  return client.query<{ projectId: number }, { getLatestApply: any }>(GET_LATEST_APPLY, { projectId });
};

export const ApplyProjectAiCap = (projectId: number, user: string, resourceSpec: ResourceSpec, reason: string) => {
  return client.mutate<{ input: { projectId: number; user: string; resource: string; reason: string } }, any>(
    AI_CAPABILITY_APPLY,
    {
      input: { projectId, user, resource: JSON.stringify(resourceSpec), reason },
    }
  );
};

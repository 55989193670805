import { useState } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Form, Input, Button, message, Space } from '@fuxi/eevee-ui';

import service from '../../service';
import { projectDetailPath } from './const';
import Upload, { UploadStatus } from '../../components/Upload';
import { firstLastNotStr } from '../../utils/validator';

const CreateAbility: React.FC = () => {
  const history = useNavigate();
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const urlParams = useParams();
  const [form] = Form.useForm();
  const { data: uuidData } = useRequest(service.dataSet.uuidInit);
  console.log('uuidData', uuidData);
  const handelSave = async () => {
    try {
      setSubmitLoading(true);
      const values = await form.validateFields();
      if (values?.filenames?.find(item => item.status !== 'done')) {
        message.warning('有文件在上传中，请稍后');
        setSubmitLoading(false);
        return;
      }
      const filenames = values?.filenames?.filter(file => file.status === UploadStatus.SUCCESS).map(file => file.name);
      await form.validateFields({ recursive: true });
      const res = await service.dataSet.createDatasets({
        upload_uuid: uuidData.upload_uuid,
        name: values.name,
        description: values.desc,
      });
      message.success('创建成功');
      history(`../${projectDetailPath}${res?.data?.dataset_id}`);
    } catch (e) {
      setSubmitLoading(false);
    }
  };
  const cancel = () => {
    history(-1);
  };
  return (
    <Flex justifyCenter style={{ marginTop: 40 }}>
      <Form
        form={form}
        labelAlign="right"
        autoComplete="off"
        initialValues={{}}
        style={{ width: 502 }}
        labelCol={{ span: 6 }}>
        <Form.Item
          label="数据集名称"
          name="name"
          tooltip="只能输入数字、大小写英文字母以及连字符，连字符不可用作开头和结尾"
          rules={[
            { required: true, message: '请输入数据集名称' },
            {
              pattern: /^[a-z][a-z0-9_]{0,63}$/,
              message: '仅允许小写字母、数字、下划线，且只能以小写字母开头， 最多64个字符',
            },
            // { pattern: /^[A-Za-z0-9_\u4e00-\u9fa5]+$/, message: '支持汉字、数字、大小写英文以及下划线' },
            firstLastNotStr('_'),
          ]}>
          <Input placeholder="请输入" maxLength={64} showCount />
        </Form.Item>
        <Form.Item label="版本">
          <div>v1</div>
        </Form.Item>
        <Form.Item label="描述" name="desc" initialValue={''}>
          <Input.TextArea placeholder="请输入" rows={4} maxLength={200} showCount />
        </Form.Item>
        <Form.Item
          label="数据"
          name="filenames"
          tooltip="单文件大小不超过20G，仅支持.csv .tar文件"
          rules={[{ required: true, message: '请上传数据文件' }]}
          trigger="validateFileName"
          validateTrigger={'validateFileName'}>
          <Upload
            // sliceUploadUrl="/upload/part"
            // uploadSuccessUrl="/upload/part/finish"
            payload={{
              // project_id: urlParams.projectId,
              upload_uuid: uuidData?.upload_uuid,
            }}
          />
        </Form.Item>
        <Space direction="horizontal" size={16}>
          <div />
        </Space>
        <Form.Item label=" " colon={false}>
          <Space>
            <Button onClick={cancel}>取消</Button>
            <Button type="primary" onClick={handelSave} loading={submitLoading}>
              确定
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default CreateAbility;

import * as React from 'react';
import classNames from 'classnames';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  OneToOneOutlined,
} from '@ant-design/icons';
import { IToolbar, IWords } from '../config/type';

interface IP {
  onClick: (type: string) => void;
  toolbar?: IToolbar;
  preview?: boolean;
  expand?: boolean;
  subfield?: boolean;
  words: IWords;
}

class Toolbars extends React.Component<IP, {}> {
  static defaultProps = {
    onClick: () => {},
    toolbars: {},
    words: {},
  };

  onClick(type: string) {
    this.props.onClick(type);
  }

  render() {
    const { preview, expand, subfield, toolbar = {}, words } = this.props;

    const previewActive = classNames({
      'eevee-active': preview,
    });
    const expandActive = classNames({
      'eevee-active': expand,
    });
    const subfieldActive = classNames({
      'eevee-active': subfield,
    });
    return (
      <ul>
        {toolbar.expand && (
          <li
            className={expandActive}
            onClick={() => this.onClick('expand')}
            title={expandActive ? words.fullscreenOff : words.fullscreenOn}>
            {expandActive ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
          </li>
        )}
        {toolbar.preview && (
          <li className={previewActive} onClick={() => this.onClick('preview')} title={words.preview}>
            {previewActive ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          </li>
        )}
        {toolbar.subfield && (
          <li
            className={subfieldActive}
            onClick={() => this.onClick('subfield')}
            title={subfieldActive ? words.singleColumn : words.doubleColumn}>
            <OneToOneOutlined />
          </li>
        )}
      </ul>
    );
  }
}

export default Toolbars;

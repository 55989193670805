import React from 'react';
import { useMatches, Params, Link } from 'react-router-dom';
import { Breadcrumb as AntBreadcrumb } from '@fuxi/eevee-ui';

interface Matches {
  id: string;
  pathname: string;
  params: Params;
  data: {
    title: string;
  };
  handle: unknown;
}

type BreadcrumbProps = {};

const ProjectBreadcrumb: React.FC<BreadcrumbProps> = () => {
  const matches = useMatches() as Matches[];
  const bread: Matches[] = matches.filter(item => item?.data?.title).slice(2);

  return bread.length <= 1 ? (
    <div></div>
  ) : (
    <div style={{ margin: '32px auto 0px 32px' }}>
      <AntBreadcrumb
        items={bread.map((item, index) => ({
          title:
            item.id.length > 1 && index < bread.length - 1 ? (
              <Link to={item.pathname}>{item?.data?.title}</Link>
            ) : (
              item?.data?.title
            ),
        }))}
      />
    </div>
  );
};

export default ProjectBreadcrumb;

import { Card, Col, Row, Empty } from '@fuxi/eevee-ui';
import { monaco } from 'react-monaco-editor';
import { useEffect } from 'react';

import EmptyImg from '@/assets/image/no-data.png';

import cx from './index.module.less';

interface Props {
  input?: string;
  output?: string;
  readOnly?: boolean;
  height?: number;
  loading: boolean;
}
const PublicInputOutput: React.FC<Props> = ({ loading, input, output, readOnly, height }) => {
  useEffect(() => {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: false,
    });

    if (document.getElementById('input-container') && loading === false) {
      monaco.editor.create(document.getElementById('input-container')!, {
        value: input,
        language: 'json',
        readOnly: true,
        automaticLayout: true,
        theme: 'vs-dark',
      });

      monaco.editor.create(document.getElementById('output-container')!, {
        value: output,
        language: 'json',
        readOnly: true,
        automaticLayout: true,
        theme: 'vs-dark',
      });
    }
  }, [loading]);

  return (
    <Row gutter={8}>
      <Col span={12}>
        <Card bordered headStyle={{ height: '48px' }} bodyStyle={{ height, padding: 0 }} title="输入">
          {input && <div id="input-container" style={{ height: 336 }}></div>}
          {!input && <Empty className={cx.empty} image={EmptyImg} />}
        </Card>
      </Col>
      <Col span={12}>
        <Card bordered headStyle={{ height: '48px' }} bodyStyle={{ height, padding: 0 }} title="输出">
          {output && <div id="output-container" style={{ height: 336 }}></div>}
          {!output && <Empty className={cx.empty} image={EmptyImg} />}
        </Card>
      </Col>
    </Row>
  );
};

export default PublicInputOutput;

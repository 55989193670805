import { useState } from 'react';
import { useAntdTable } from '@fuxi/eevee-hooks';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Flex, Button, Input } from '@fuxi/eevee-ui';

import service from '../../service';
import { getSorterTableData } from '../../utils/util';
import { getOnlineServiceColumns, releaseServicePath } from './const';
import cx from './index.module.less';

const OnlineService: React.FC = () => {
  const history = useNavigate();
  const [keyword, setKeyword] = useState<string>();
  const urlParams = useParams();

  const {
    tableProps,
    search: { submit },
    refresh,
  } = useAntdTable(
    getSorterTableData(service.onlineService.appList, { extraParams: { project_id: urlParams.projectId, service_name: keyword } })
  );
  return (
    <div className={cx['main-page-wrapper']}>
      <div className={cx['main-page-title']}>在线服务</div>
      <Flex justifyBetween style={{ marginBottom: 20 }}>
        <Flex alignCenter>
          <Button type="primary" onClick={() => history(releaseServicePath)}>
            <PlusOutlined />
            发布服务
          </Button>
        </Flex>
        <div>
          <Input
            placeholder="请输入服务名称"
            onChange={e => setKeyword(e.target.value)}
            style={{ width: '300px', marginRight: 12 }}
          />
          <Button type="primary" onClick={() => submit()}>
            搜索
          </Button>
        </div>
      </Flex>
      <Table
        columns={getOnlineServiceColumns({ refresh, history, projectId: urlParams.projectId })}
        {...tableProps}
        rowKey="id"
        pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
      />
    </div>
  );
};

export default OnlineService;

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { useRequest } from '@fuxi/eevee-hooks';
import { Badge, Descriptions, DescriptionsProps, Divider, Spin, Table, TableButtons, Tooltip } from '@fuxi/eevee-ui';
// import { serviceStatusTypeMap } from '@ai-training/constants/onlineService';
import { taskStatusMap } from '@ai-training/constants/trainingTask';
import { mlTrainingTask } from '@/service/ml';
import { useAppSelector } from '@/hooks';
import { getTrainingFormattedTimeGap } from '../utils/getFormattedTimeGap';
import cx from './index.module.less';

export const MlTrainingTaskDetail = () => {
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId)!;
  const { taskId } = useParams();
  const { taskDetails } = mlTrainingTask;
  const navigate = useNavigate();
  const {
    data: taskDetail,
    loading,
    run,
  } = useRequest((taskId: string) => taskDetails({ task_id: taskId! }), {
    manual: true,
  });
  const detail = taskDetail?.data;

  useEffect(() => {
    if (!!aiProjectId && !!taskId) {
      run(taskId);
    }
  }, [aiProjectId, taskId]);

  const items: DescriptionsProps['items'] = [
    {
      key: 'capability_name',
      label: '能力名称',
      children: detail?.capability_name || '-',
      span: 1,
    },
    {
      key: 'id',
      label: '任务ID',
      children: <Tooltip title={detail?.id}>{detail?.task_id}</Tooltip>,
    },
    {
      key: 'version',
      label: '版本',
      children: detail?.capability_version || '-',
      span: 1,
    },
    {
      key: 'status',
      label: '服务状态',
      children: <Badge color={taskStatusMap[detail?.status]?.color} text={taskStatusMap[detail?.status]?.label} />,
    },

    {
      label: '开始训练时间',
      key: 'start_at',
      children: detail?.start_at || '-',
    },

    {
      label: '结束训练时间',
      key: 'finish_at',
      children: detail?.finish_at || '-',
    },
    {
      label: '已训练时长',
      key: 'duration',
      children: getTrainingFormattedTimeGap({
        duration: detail?.duration,
        status: detail?.status,
        start_at: detail?.start_at,
      }),
    },
    {
      label: '训练监控',
      key: 'tensorboard_url',
      children: detail?.tensorboard_url ? (
        <a href={'http://' + detail?.tensorboard_url} target="_blank" rel="noreferrer">
          {detail?.tensorboard_url}
        </a>
      ) : (
        '-'
      ),
    },
    {
      label: '描述',
      key: 'description',
      children: !!detail?.description ? (
        <Tooltip title={detail?.description} placement="topLeft">
          {detail?.description}
        </Tooltip>
      ) : (
        '-'
      ),
    },
  ];

  const trainingDataset: ColumnsType<{
    index: string;
    name: string;
  }> = [
    {
      title: '序号',
      dataIndex: 'name',
      render: (_, record) => {
        return record.index;
      },
    },
    {
      title: '数据集名称',
      dataIndex: 'name',
      render: (_, record) => record.name,
    },

    {
      title: '操作',
      fixed: 'right',
      render: record => (
        <TableButtons
          data={[
            {
              text: '查看详情',
              onClick: () => {
                navigate(`../../dataset/${record.id}/${record.datasetId}`);
              },
            },
          ]}
        />
      ),
    },
  ];

  return (
    <Spin spinning={loading} tip="正在获取训练任务详情...">
      <div>
        <div className={cx('title')}>训练任务信息</div>
        <Descriptions className={cx('desc')} items={items} />
        <Divider className={cx('divider')} />
        <div className={cx('dataset-title')}>训练数据集</div>

        <Table
          pagination={{
            showTotal: total => `总共 ${total} 条`,
            showQuickJumper: true,
            pageSizeOptions: ['8', '10', '16', '32', '64'],
            showSizeChanger: true,
          }}
          loading={loading}
          columns={trainingDataset}
          dataSource={taskDetail?.data?.dataset?.map((item, index) => ({
            index: index + 1,
            name: item.zh_name,
            id: item.id,
            datasetId: item.dataset_id,
          }))}></Table>
      </div>
    </Spin>
  );
};

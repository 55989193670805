import { useState } from 'react';
import { Form, Modal, Select } from '@fuxi/eevee-ui';
import cx from './index.module.less';

interface Prop {
  children: React.ReactNode;
  serviceList: Service[];
  onConfirmBack: (id: string) => void;
}

export const DownloadInitTool: React.FC<Prop> = props => {
  const { children, serviceList, onConfirmBack } = props;
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);

  const handleOk = async () => {
    const data = await form.validateFields();
    onConfirmBack(data?.serviceId);
    setIsVisible(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };
  return (
    <>
      <div onClick={() => setIsVisible(true)}>{children}</div>
      <Modal
        className={cx('modal-wrapper')}
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        title="下载初始化工具">
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          form={form}
          validateMessages={{
            required: '必填',
          }}>
          <Form.Item required name="serviceId" label="选择服务器" rules={[{ required: true, message: '必选' }]}>
            <Select listHeight={160} placeholder="请选择服务器">
              {serviceList?.map(l => (
                <Select.Option key={l?.id} value={l?.id}>
                  {l?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

import { Modal } from '@fuxi/eevee-ui';
import { useState } from 'react';
import Editor from 'react-monaco-editor';

interface Prop {
  value: string;
  children: React.ReactNode;
  height?: number;
  readonly?: boolean;
}

export const MonocaEditorModal = (props: Prop) => {
  const { value, children, height = 500, readonly = true } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div onClick={showModal}>{children}</div>
      <Modal
        cancelButtonProps={{ hidden: true }}
        destroyOnClose
        width={688}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        title="超级参数配置">
        <Editor
          options={{
            readOnly: readonly,
            automaticLayout: true,
            renderValidationDecorations: 'off',
          }}
          language="typescript"
          theme={'vs-dark'}
          height={height}
          value={value}></Editor>
      </Modal>
    </div>
  );
};

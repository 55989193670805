// eslint-disable-next-line react-func/max-lines-per-function
function insertText($vm: HTMLTextAreaElement | null, params: any): string {
  const { prefix, str = '', suffix = '' } = params;
  if (!$vm) return '';
  const value = $vm.value;
  if ($vm.selectionStart || $vm.selectionStart === 0) {
    const start = $vm.selectionStart;
    const end = $vm.selectionEnd;

    const restoreTop = $vm.scrollTop;

    if (start === end) {
      $vm.value = value.substring(0, start) + prefix + str + suffix + value.substring(end, value.length);
      $vm.selectionStart = start + prefix.length;
      $vm.selectionEnd = end + prefix.length + str.length;
    } else {
      $vm.value =
        value.substring(0, start) + prefix + value.substring(start, end) + suffix + value.substring(end, value.length);
      $vm.selectionStart = start + prefix.length;
      $vm.selectionEnd = end + prefix.length;
    }

    $vm.focus();
    if (restoreTop >= 0) {
      $vm.scrollTop = restoreTop;
    }
  }
  return $vm.value;
}

export { insertText };

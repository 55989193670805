import { Space, Tooltip } from 'antd';

export const dataSelectColumns = [
  {
    title: '数据集',
    dataIndex: 'dataset',
    render: (text: string) =>
      text ? (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 200,
          }}>
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        </div>
      ) : (
        '-'
      ),
  },
  {
    title: '版本',
    dataIndex: 'version',
  },
  {
    title: '数据量',
    dataIndex: 'size',
    render: (text: string) => (text ? text : '-'),
  },
];

import React, { useState } from 'react';
import { Button, Space, Tooltip, Radio, TablePaginationConfig, Spin } from '@fuxi/eevee-ui';
import { AppstoreOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { isProjectCreator } from '@/utils/getUserName';
import ServiceList from '../ServiceList';
import ServiceCardList from '../ServiceCardList';
import UpsertServerModal from '../UpsertServerModal';
import ProjectDetailContainer from '../ProjectDetailContainer';

import cx from './ProjectServiceList.module.less';

type Props = {};

export const ProjectServiceList = (props: Props) => {
  const container = ProjectDetailContainer.useContainer();
  const { currentProject, userInfo, fetchProjectInfoLoading, serviceListLoading, getServicesData } = container;
  const [isTable, setIsTable] = useState(false);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 9,
    current: 1,
  });

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setPagination({ pageSize: pageSize || 9, current: page });
  };

  return (
    <div className={cx('project-service-list-wrapper')}>
      <Spin spinning={fetchProjectInfoLoading || serviceListLoading}>
        <div className={cx('title')}>服务器管理</div>
        <div className={cx('add-server')}>
          {/* {isProjectCreator(currentProject.creator, userInfo.name) ? (
            <UpsertServerModal>
              <Button type="primary">
                <PlusOutlined />
                添加服务器
              </Button>
            </UpsertServerModal>
          ) : (
            <Tooltip title="仅项目创建者有此权限">
              <Button type="primary" disabled>
                <PlusOutlined />
                添加服务器
              </Button>
            </Tooltip>
          )} */}

          <div>
            <Radio.Group defaultValue="card">
              <Radio.Button onClick={() => setIsTable(false)} value="card">
                <Space>
                  <AppstoreOutlined />
                  卡片
                </Space>
              </Radio.Button>
              <Radio.Button onClick={() => setIsTable(true)} value="table">
                <Space>
                  <MenuOutlined />
                  列表
                </Space>
              </Radio.Button>
            </Radio.Group>
          </div>
          <Button
            type="primary"
            loading={serviceListLoading}
            onClick={() => {
              getServicesData({ variables: { projectId: currentProject.id } });
            }}>
            刷新列表
          </Button>
        </div>
        {isTable ? (
          <ServiceList pagination={pagination} handlePaginationChange={handlePaginationChange} editPermission={true} />
        ) : (
          <ServiceCardList
            handlePaginationChange={handlePaginationChange}
            pagination={pagination}
            editPermission={true}
          />
        )}
      </Spin>
    </div>
  );
};

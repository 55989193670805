import React, { CSSProperties, useEffect, useState } from 'react';
import { marked } from 'marked';
import hljs from 'highlight.js';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import 'highlight.js/styles/github-dark.css';
import 'github-markdown-css/github-markdown-dark.css';
import './dark.css';
import './Markdown.css';

type Props = {
  theme?: string;
  text?: string;
  styles?: CSSProperties;
};

const ProjectMarkDown: React.FC<Props> = ({ theme = 'dark', text = '', styles }) => {
  const isDark = theme === 'dark';
  const [mdHtml, setMdHtml] = useState('');

  // useEffect(() => {
  //   loadCss();
  // }, []);

  useEffect(() => {
    try {
      setMdHtml(DOMPurify.sanitize(marked.parse(text)));
    } catch (e) {
      console.log(e);
    }
  }, [text]);

  // const loadCss = () => {
  //   if (isDark) {
  //     require('highlight.js/styles/github-dark.css');
  //     require('github-markdown-css');
  //     require('./dark.css');
  //   } else {
  //     require('highlight.js/styles/github.css');
  //     require('github-markdown-css/github-markdown-light.css');
  //     require('./light.css');
  //   }
  // };

  marked.setOptions({
    renderer: new marked.Renderer(),
    highlight: function (code, lang) {
      const language = hljs.getLanguage(lang)?.name;
      let res = code;
      try {
        res = language ? hljs.highlight(code, { language, ignoreIllegals: true }).value : code;
      } catch {
        res = code;
      }
      return res;
    },
    pedantic: false,
    gfm: true,
    breaks: false,
    sanitize: false,
    smartypants: false,
    xhtml: false,
  });

  return (
    <div
      className={classNames('markdown-body', 'show-html')}
      style={styles}
      dangerouslySetInnerHTML={{
        __html: mdHtml,
      }}></div>
  );
};

export default ProjectMarkDown;

import { useState } from 'react';
import { Form, Input, Modal, Select } from '@fuxi/eevee-ui';
import { Terminal, EdgeNodeInput } from '@/store/terminal';
import { pythonVariableNameValidator } from '@/utils/pythonVariableNameValidator';
import { TerminalStatus } from '@/constants/terminal';

import cx from './index.module.less';

interface Prop {
  terminal: Terminal;
  serviceList: Service[];
  callback: (data: EdgeNodeInput) => void;
  initialValues: EdgeNodeInput;
}
export const EditTerminalModal: React.FC<Prop> = props => {
  const { terminal, callback, serviceList, initialValues } = props;
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);

  const handleOk = async () => {
    const data: EdgeNodeInput = await form.validateFields();
    form.setFieldsValue(initialValues);

    try {
      setIsVisible(false);
      callback(data);
    } catch {
      console.log(data);
    }
  };

  const handleCancel = () => {
    setIsVisible(false);
    form.setFieldsValue(initialValues);
  };

  return (
    <>
      <span className={cx('operation')} onClick={() => setIsVisible(true)}>
        编辑
      </span>
      <Modal visible={isVisible} onOk={handleOk} onCancel={handleCancel} title="编辑">
        <Form
          initialValues={initialValues}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          form={form}
          validateMessages={{
            required: '必填',
          }}>
          <Form.Item
            initialValue={terminal?.name}
            name="name"
            required
            rules={[{ required: true, message: '终端名称不能为空' }]}
            label="终端名称">
            <Input autoComplete="off" maxLength={126} showCount></Input>
          </Form.Item>

          <Form.Item
            initialValue={terminal?.type}
            required
            name="type"
            rules={[
              { required: true, message: '终端类型不能为空' },
              {
                validator: (_, value) => {
                  const errorInfo = pythonVariableNameValidator(value);
                  if (errorInfo) {
                    return Promise.reject(new Error(errorInfo));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            label="终端类型">
            <Input maxLength={16} placeholder="请设置终端类型" showCount></Input>
          </Form.Item>

          <Form.Item name="serviceId" label="选择服务器">
            <Select disabled={terminal?.status !== TerminalStatus.Ready} listHeight={160} placeholder="请选择服务器">
              {serviceList?.map(l => (
                <Select.Option key={l?.id} value={l?.id}>
                  {l?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

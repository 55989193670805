/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-lines */
import React, { useRef, useState, useEffect } from 'react';
import { Spin } from 'antd';
import { isImgType } from '@/utils/isImgType';
import { uploadFilesToCdn } from '@/utils/uploadFilesToCdn';
import Markdown from '../Markdown/Markdown';
import ToolbarLeft from './components/ToolbarLeft';
import ToolbarRight from './components/ToolbarRight';
import { insertText } from './config/utils';
import cx from './config/css/index.module.less';
import { CONFIG } from './config';
import { IToolbar, IWords } from './config/type';
import { getInsertTextObj } from './config/constants';

type Props = {
  theme?: string;
  value?: string;
  lineNum?: boolean;
  onChange?: (value: string) => void;
  onSave?: (value: string) => void;
  placeholder?: string;
  fontSize?: string;
  disabled?: boolean;
  style?: object;
  height?: number;
  preview?: boolean;
  expand?: boolean;
  subfield?: boolean;
  toolbar?: IToolbar;
  language?: string;
  addImg?: (file: File, index: number) => void;
};

let currentTimeout: number = 0;

const MdEditor: React.FC<Props> = props => {
  const {
    lineNum = true,
    onChange = () => {},
    onSave = () => {},
    addImg = () => {},
    fontSize = '14px',
    height = 600,
    value,
    placeholder,
    disabled = false,
    style = {},
    toolbar = CONFIG.toolbar,
    language = 'zh-CN',
  } = props;
  const theme = props.theme === 'light' ? 'light' : 'dark';

  const $vm = useRef<HTMLTextAreaElement>(null);
  const $scrollEdit = useRef<HTMLDivElement>(null);
  const $scrollPreview = useRef<HTMLDivElement>(null);
  const $blockEdit = useRef<HTMLDivElement>(null);
  const $blockPreview = useRef<HTMLDivElement>(null);

  const [preview, setPreview] = useState(props.preview || false);
  const [expand, setExpand] = useState(props.expand || false);
  const [subfield, setSubfield] = useState(props.subfield || false);
  const [history, setHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [lineIndex, setLineIndex] = useState(0);
  const [words, setWords] = useState<IWords>({});
  const [uploadingImg, setUploadingImg] = useState(false);

  const editorClass = cx({
    'eevee-editor-edit': true,
    'eevee-panel': true,
    'eevee-active': preview && subfield,
    'eevee-edit-preview': preview && !subfield,
  });
  const previewClass = cx({
    'eevee-panel': true,
    'eevee-editor-preview': true,
    'eevee-active': preview && subfield,
  });
  const fullscreen = cx({
    'eevee-container-dark': theme === 'dark',
    'eevee-container': theme === 'light',
    'eevee-fullscreen': expand,
  });
  const lineNumStyles = cx({
    'eevee-line-num': true,
    hidden: !lineNum,
  });

  useEffect(() => {
    initLanguage();
  }, []);

  useEffect(() => {
    reLineNum();
    if (value === history[historyIndex]) return;
    clearTimeout(currentTimeout);
    currentTimeout = window.setTimeout(() => {
      saveHistory(value);
    }, 500);
  }, [value]);

  const initLanguage = (): void => {
    if (!language) return;
    const lang = CONFIG.langList.indexOf(language) >= 0 ? language : 'zh-CN';
    setWords(CONFIG.language[lang]);
  };

  // 输入框改变
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = e.target.value;
    onChange?.(value);
  };

  // 保存记录
  const saveHistory = (value?: string): void => {
    // 撤销后修改，删除当前以后记录
    history.splice(historyIndex + 1, history.length);
    if (history.length >= 20) {
      history.shift();
    }
    // 记录当前位置
    history.push(value || '');
    setHistory(history);
    setHistoryIndex(history.length - 1);
  };

  // 重新计算行号
  const reLineNum = () => {
    const lineIndex = value ? value.split('\n').length : 1;
    setLineIndex(lineIndex);
  };

  // 保存
  const save = (): void => {
    onSave?.($vm.current!.value);
  };

  // 撤销
  const undo = (): void => {
    let index = historyIndex - 1;
    if (index < 0) return;
    onChange?.(history[index]);
    setHistoryIndex(index);
  };

  // 重做
  const redo = (): void => {
    let index = historyIndex + 1;
    if (index >= history.length) return;
    onChange?.(history[index]);
    setHistoryIndex(index);
  };

  // 菜单点击
  const toolBarLeftClick = (type: string): void => {
    const insertTextObj = getInsertTextObj(words);

    if (insertTextObj.hasOwnProperty(type) && $vm.current) {
      const value = insertText($vm.current, insertTextObj[type]);
      onChange?.(value);
    }

    const otherLeftClick: Record<string, Function> = {
      undo,
      redo,
      save,
    };

    otherLeftClick[type]?.();
  };

  // 添加图片
  const addImage = (file: File, index: number) => {
    addImg?.(file, index);
  };

  // const $img2Url = (name: string, url: string) => {
  //   const value = insertText($vm.current, {
  //     prefix: `![${name}](${url})`,
  //     suffix: '',
  //     str: '',
  //   });
  //   onChange?.(value);
  // };

  // 右侧菜单
  const toolBarRightClick = (type: string): void => {
    const toolbarRightPreviewClick = () => {
      setPreview(prev => !prev);
      setSubfield(false);
    };

    const toolbarRightExpandClick = () => setExpand(prev => !prev);

    const toolbarRightSubfieldClick = () => {
      preview && subfield && setPreview(false);
      !preview && !subfield && setPreview(true);
      setSubfield(prev => !prev);
    };

    const rightClick: Record<string, Function> = {
      preview: toolbarRightPreviewClick,
      expand: toolbarRightExpandClick,
      subfield: toolbarRightSubfieldClick,
    };

    rightClick[type]?.();
  };

  const focusText = (): void => $vm.current!.focus();

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const radio = $blockEdit.current!.scrollTop / ($scrollEdit.current!.scrollHeight - e.currentTarget.offsetHeight);
    $blockPreview.current!.scrollTop =
      ($scrollPreview.current!.scrollHeight - $blockPreview.current!.offsetHeight) * radio;
  };

  // 行号
  const getLineNum = () => {
    const list = [];
    for (let i = 0; i < lineIndex; i++) {
      // @ts-ignore
      list.push(<li key={i + 1}>{i + 1}</li>);
    }
    return <ul className={lineNumStyles}>{list}</ul>;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!(e.ctrlKey || e.metaKey) && !e.altKey && !e.shiftKey) {
      switch (e.key) {
        case 'Tab': {
          e.preventDefault();
          toolBarLeftClick('tab');
          break;
        }
      }
    } else if ((e.ctrlKey || e.metaKey) && !e.altKey && !e.shiftKey) {
      // ctrl +
      switch (e.key) {
        case 'z': {
          e.preventDefault();
          toolBarLeftClick('undo');
          break;
        }
        case 'y': {
          e.preventDefault();
          toolBarLeftClick('redo');
          break;
        }
        case 's': {
          e.preventDefault();
          toolBarLeftClick('save');
          break;
        }
      }
    }
  };

  // 上传到cdn
  const insertImg = async files => {
    const imgs = Array.from(files).filter(file => isImgType(file));
    setUploadingImg(true);
    try {
      const cdnUrls = await uploadFilesToCdn(imgs, 'markdownImgs');

      const imgsUrl = cdnUrls.items.map(item => {
        // 截取图片名称
        const splitName = item.split('/');
        const imgName = splitName[splitName.length - 1];
        return `![${imgName}](${item})`;
      });
  
      const value = insertText($vm.current, {
        prefix: '',
        suffix: '',
        str: imgsUrl.join('\n\n'),
      });
      onChange?.(value);
    } catch(e) {
      console.log(e);
    } finally {
      setUploadingImg(false);
    }
  };

  const handleDrop = e => {
    if (!!e.dataTransfer.files.length) {
      e.preventDefault();
      insertImg(e.dataTransfer.files);
    }
  };

  const handlePaste = e => {
    if (!!e.clipboardData.files.length) {
      e.preventDefault();
      insertImg(e.clipboardData.files);
    }
  };

  return (
    <Spin spinning={uploadingImg} tip="正在上传图片...">
      <div onKeyDown={handleKeyDown} className={fullscreen} style={{ height, ...style }}>
        {/* 菜单栏 */}
        {Boolean(Object.keys(toolbar || {}).length) && (
          <div className={cx('eevee-toolbar')}>
            <ToolbarLeft toolbar={toolbar} words={words} onClick={toolBarLeftClick} toInsertImg={insertImg} {...props} />
            <ToolbarRight
              toolbar={toolbar}
              words={words}
              preview={preview}
              expand={expand}
              subfield={subfield}
              onClick={toolBarRightClick}
            />
          </div>
        )}
        {/* 内容区 */}
        <div className={cx('eevee-editor')} style={{ height: '100%', fontSize }}>
          {/* 编辑区 */}
          <div className={editorClass} ref={$blockEdit} onScroll={handleScroll} onClick={focusText}>
            <div className={cx('eevee-editor-block')} ref={$scrollEdit}>
              {getLineNum()}
              <div className={cx('eevee-editor-content')}>
                <pre>{value}</pre>
                <textarea
                  onPaste={handlePaste}
                  onDrop={handleDrop}
                  ref={$vm}
                  value={value}
                  disabled={disabled}
                  onChange={handleChange}
                  placeholder={placeholder ? placeholder : words.placeholder}
                />
              </div>
            </div>
          </div>
          {/* 预览区 */}
          <div className={previewClass} ref={$blockPreview}>
            <div ref={$scrollPreview} className={cx('eevee-preview', 'eevee-markdown-preview')}>
              <Markdown theme={theme} text={value} />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default MdEditor;

import { Flex } from '@fuxi/eevee-ui';
import { ExclamationCircleFilled } from '@ant-design/icons';
import cx from './index.module.less';

export const ReconnectMsg: React.FC<{
  info?: string;
  reconnect: () => void;
}> = ({ info = '长时间未活跃，日志连接已断开，', reconnect }) => {
  return (
    <Flex gap={4} className={cx('lock-message')}>
      <ExclamationCircleFilled className="warn-icon" style={{ color: '#d7a72e' }} />
      <Flex.Item>
        {info}
        <a onClick={() => reconnect()} className={cx('reconnect-button')}>
          点击重连
        </a>
      </Flex.Item>
    </Flex>
  );
};

import { ReactNode, useState } from 'react';
import { Flex, Modal, Typography } from '@fuxi/eevee-ui';
import { RemoteConnectInfo } from '@/store/virtualTerminal';

import cx from './index.module.less';

export const RemoteConnectionModal: React.FC<{ connectionInfo: RemoteConnectInfo; children: ReactNode }> = props => {
  const { Text } = Typography;
  const { host, port, username, protocol } = props.connectionInfo;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={cx('connection-span')}>
      <div onClick={showModal}>{props.children}</div>
      <Modal
        wrapClassName={cx('connection-modal')}
        cancelButtonProps={{ style: { display: 'none' } }}
        className={cx('connection-modal')}
        title="连接远程桌面"
        destroyOnClose
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}>
        <div>请使用RDP客户端或SSH连接至虚拟终端</div>
        <div className={cx('connection-info')}>
          <Flex className={cx('info-wrapper')} flexDirection="column">
            <Flex alignCenter>
              访问地址: <Text copyable>{host}</Text>
              {/* <Icon className={cx('copy-icon')} name="复制-项目中心"></Icon> */}
            </Flex>
            <Flex>端口: {port}</Flex>
            <Flex>用户名: {username}</Flex>
            <Flex>协议: {protocol}</Flex>
          </Flex>
        </div>
      </Modal>
    </div>
  );
};

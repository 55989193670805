import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Radio, Row, Col, Button, Space, Flex, message, notification } from '@fuxi/eevee-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import service from '@/service';
import { useAppSelector } from '@/hooks';
import { downloadFile } from '@/utils/file';
import { FileType, ISelectOption, nameRules, templateUrlMap } from '../const';
import { getFileTypeOptions, getAnnotateTypeOptions, getUploadTips } from '../util';
import { UploadFormItem } from '../../components/UploadFormItem';
import { useUploadUuid } from '../useUploadUuid';
import { getCurrentUrl } from '../../utils/getCurrentUrl';
import cx from './index.module.less';

export const DatasetCreate = () => {
  const location = useLocation();
  const [initialLocationUrl, setInitialLocationUrl] = useState<string>(`${location.pathname}${location.search}`);
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const history = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ is_annotated: false });
  }, [form]);

  const datasetType = Form.useWatch('classification', form);
  const fileType = Form.useWatch('file_type', form);
  const isAnnotated = Form.useWatch('is_annotated', form);
  const { data: uploadUUidRes, refetch: getUploadUUid } = useUploadUuid(aiProjectId);

  useEffect(() => {
    form.setFieldsValue({ annotation_type: undefined });
    form.setFieldsValue({ file_type: undefined });
    // 切换数据集类型，重新获取上传uuid
    if (datasetType) {
      getUploadUUid();
    }
  }, [datasetType]);

  useEffect(() => {
    // 切换文件类型（图片和压缩包），重新获取上传uuid
    if ([FileType.Image, FileType.Zip].includes(fileType)) {
      getUploadUUid();
    }
  }, [fileType]);

  useEffect(() => {
    setInitialLocationUrl(`${location.pathname}${location.search}`);
  }, [aiProjectId]);

  const { data: optionsData, isLoading: loadingConfig } = useQuery<any, any, { data: ISelectOption[] }>({
    queryKey: ['catogory', aiProjectId],
    queryFn: () => service.ml.getCategory(),
    enabled: !!aiProjectId,
    refetchOnWindowFocus: false,
  });

  const uploadTips = getUploadTips(fileType);

  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const handleSave = async () => {
    try {
      setSubmitLoading(true);
      const values = await form.validateFields();
      if (values?.filenames?.find(item => item.status !== 'done')) {
        message.warning('有文件在上传中，请稍后');
        setSubmitLoading(false);
        return;
      }

      const res = await service.ml.createDataset({
        ...values,
        upload_uuid: uploadUUidRes?.data?.upload_uuid,
      });
      message.success('创建成功');

      if (initialLocationUrl === getCurrentUrl() && initialLocationUrl.includes('/dataset/create')) {
        history(`../${res.data.dataset_version_id}/${res.data.dataset_id}`);
      }
    } catch (e) {
      setSubmitLoading(false);
    }
  };
  const [api, contextHolder] = notification.useNotification();

  const handleDownload = () => {
    const tmpUrl = templateUrlMap[fileType];
    try {
      api.info({
        message: `样例文件下载中，请稍后`,
        placement: 'topRight',
        duration: 1,
      });
      // jsonl文件需要加后缀
      tmpUrl && downloadFile(tmpUrl, `${fileType}文件模板${fileType === FileType.Jsonl ? '.jsonl' : ''}`);
    } catch (error) {
      message.error('下载模板文件失败');
    }
  };

  return (
    <div className={cx('create-dataset')}>
      <Form form={form} labelCol={{ span: 6 }}>
        <Flex justifyCenter>
          <Row>
            <Col span={12} style={{ maxWidth: 600 }}>
              <Form.Item
                label="数据集名称"
                name="name"
                extra={'以小写字母开头，支持数字、小写英文字母以及下划线，下划线不可用作结尾；'}
                rules={[{ required: true, message: '项目名称不能为空' }, ...nameRules]}>
                <Input placeholder="请输入" maxLength={20} showCount autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="数据集类型"
                name="classification"
                rules={[{ required: true, message: '数据集类型不能为空' }]}>
                <Select
                  disabled={loadingConfig}
                  options={optionsData?.data?.map(item => ({
                    value: item.first_category,
                    label: item.first_category,
                  }))}
                  allowClear
                />
              </Form.Item>

              <Form.Item
                label="标注类型"
                name="annotation_type"
                rules={[{ required: true, message: '标注类型不能为空' }]}>
                <Select
                  options={getAnnotateTypeOptions(datasetType, optionsData?.data)}
                  disabled={!datasetType}
                  allowClear
                />
              </Form.Item>

              <Form.Item
                label="数据标注状态"
                name="is_annotated"
                rules={[{ required: true, message: '标注状态不能为空' }]}>
                <Radio.Group>
                  <Radio value={false}>无标注</Radio>
                  <Radio value={true}>有标注</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="数据上传格式"
                name="file_type"
                rules={[{ required: true, message: '数据上传格式不能为空' }]}>
                <Select disabled={!datasetType} options={getFileTypeOptions(datasetType, isAnnotated) || []} />
              </Form.Item>

              <Form.Item label="描述" name="description" rules={[{ required: true, message: '描述不能为空' }]}>
                <Input.TextArea showCount maxLength={200} rows={4} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={12} style={{ position: 'relative' }}>
              {contextHolder}
              <UploadFormItem
                payload={{
                  project_id: String(aiProjectId),
                  upload_uuid: uploadUUidRes?.data?.upload_uuid,
                }}
                fileType={fileType}
              />
              {fileType && templateUrlMap[fileType] && (
                <Button
                  style={{ position: 'absolute', top: 0, left: 'calc(25% + 140px)' }}
                  type="text"
                  onClick={handleDownload}>
                  下载数据样例
                </Button>
              )}

              {uploadTips.length > 0 && (
                <Row style={{ width: '100%', marginTop: -20 }}>
                  <Col span={6}></Col>
                  <Col span={18}>
                    <div className={cx('upload-tips')}>
                      {uploadTips.map(item => (
                        <div key={item}>{item}</div>
                      ))}
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Flex>
      </Form>
      <Flex justifyCenter style={{ marginTop: 32 }}>
        <Space>
          <Button type="default" onClick={() => history(-1)}>
            取消
          </Button>
          <Button type="primary" loading={submitLoading} onClick={() => handleSave()}>
            确定
          </Button>
        </Space>
      </Flex>
    </div>
  );
};

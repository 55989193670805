import { checkPermission } from '@/utils';

const filterLicensedMenuData = (menus: RouteConfig[], permissionList?: string[]) => {
  if (!permissionList) return menus;

  let i = menus.length;
  while (i--) {
    const { permissionCodes, children } = menus[i];
    const noPermission = !checkPermission(permissionList, permissionCodes);

    // 无权限则剔除当前菜单项
    if (noPermission) {
      menus.splice(i, 1);
      continue;
    }

    if (children && children.length) {
      const childrenMenus = filterLicensedMenuData(children, permissionList);

      childrenMenus.length ? (menus[i].children = childrenMenus) : menus.splice(i, 1); // 如果所有的子菜单项都无权限，则剔除当前菜单项
    }
  }

  return menus;
};

export default filterLicensedMenuData;

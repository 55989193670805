import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { message, TableButtons, Tooltip } from '@fuxi/eevee-ui';

import service from '../../service';
import { requestConfirm } from '../../utils';
import { formatFileSize } from '../../utils/util';
import { ItemOptions } from '../../components/Descriptions';
import { PublicDetail, ProjectDetail, VersionDetail, FileDetail } from '../../types/dataSet';

import cx from './index.module.less';

// 项目数据路由
export const projectPath = '../../data-set/project';
// 项目数据详情路由
export const projectDetailPath = '../../data-set/project/';
// 项目版本详情
export const projectVersionDetailPath = '../../data-set/project/';
// 创建数据路由
export const createPath = '../../data-set/project/create';
// 公共数据路由
export const publicPath = '../../data-set/public';
// 公共数据详情路由
export const publicDetailPath = '../../data-set/public/';
// 公共版本详情
export const publicVersionDetailPath = '../../../data-set/public/';

export const projectCreateVersionPath = '../../data-set/project/createVersion';

export const ProjectImportVersionPath = 'importVersion';

// 公共数据
export const publicColumns: ColumnsType<PublicDetail> = [
  {
    title: '数据集名称',
    dataIndex: 'name',
    ellipsis: {
      showTitle: false,
    },
    render: (_, record) => (
      <Tooltip placement="topLeft" title={record.zh_name}>
        <div className="list-link-ellipsis">
          <Link type="link" to={publicDetailPath + record.id}>
            {record.zh_name}
          </Link>
        </div>
      </Tooltip>
    ),
  },
  {
    title: '数据集ID',
    dataIndex: 'id',
  },
  {
    title: '版本',
    dataIndex: 'version_count',
  },
  {
    title: '更新时间',
    dataIndex: 'update_time',
  },
];

export const getProjectColumns = ({ history, refresh, projectId }): ColumnsType<ProjectDetail> => {
  return [
    {
      title: '数据集名称',
      dataIndex: 'name',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <Tooltip placement="topLeft" title={record.zh_name}>
          <div className="list-link-ellipsis">
            <Link type="link" to={projectDetailPath + record.id}>
              {record.name}
            </Link>
          </div>
        </Tooltip>
      ),
    },
    {
      title: '数据集ID',
      dataIndex: 'id',
    },
    {
      title: '版本数',
      dataIndex: 'version_count',
    },
    {
      title: '更新时间 ',
      dataIndex: 'update_time',
    },
    {
      title: '操作',
      fixed: 'right',
      render: (_, record) => (
        <TableButtons
          data={[
            {
              text: '新增版本',
              onClick: () =>
                history(
                  `${record.id}/createVersion?projectId=${projectId}&datasetId=${record.id}&datasetName=${record.name}`
                ),
            },
            {
              text: '删除',
              type: 'danger',
              onClick: () =>
                requestConfirm({
                  title: `确定删除数据集 ${record.name}?`,
                  content: '数据集及其版本将全部被删除，且不可恢复。',
                  payload: {
                    dataset_id: record.id,
                  },
                  submitApi: service.dataSet.deleteDatasets,
                }).then(() => {
                  refresh();
                }),
            },
          ]}
        />
      ),
    },
  ];
};
/* 公共数据详情 */
export const publicDetailColumns: ItemOptions[] = [
  {
    label: '数据集名称',
    dataIndex: 'name',
    ellipsis: true,
    render: data =>
      data?.name ? (
        <Tooltip placement="topLeft" title={data?.zh_name}>
          {data?.zh_name}
        </Tooltip>
      ) : (
        '-'
      ),
  },
  {
    label: '数据集ID',
    dataIndex: 'id',
  },
  {
    label: '版本数',
    dataIndex: 'version_count',
  },
  {
    label: '上传用户',
    dataIndex: 'update_by',
    ellipsis: true,
  },
  {
    label: '最后更新时间',
    dataIndex: 'update_time',
  },
  {
    label: '数据集描述',
    dataIndex: 'classification',
    ellipsis: true,
  },
];

/* 公共数据详情-版本 */
export const getPublicVersionColumns = ({ refresh, history, dataId, datasetName }): ColumnsType<VersionDetail> => {
  return [
    {
      title: '版本',
      dataIndex: 'version',
      render: (_, record) =>
        record.sdk_created ? (
          record.version
        ) : (
          <Link
            type="link"
            className={cx['table-version-col-link']}
            to={`${publicVersionDetailPath}${dataId}/${record.id}?datasetName=${datasetName}&desc=${record.desc}`}>
            {record.version}
          </Link>
        ),
    },
    {
      title: '版本ID ',
      dataIndex: 'id',
    },
    {
      title: '描述',
      dataIndex: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: val => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: '更新时间 ',
      dataIndex: 'update_time',
    },
  ];
};

/* 公共数据详情-版本详情-表格 */
export const getPublicVersionDetailColumns = (props: {
  refresh;
  dataSetId: string;
  versionId: string;
}): ColumnsType<VersionDetail> => {
  const { refresh, dataSetId, versionId } = props;
  return [
    {
      title: '数据文件',
      dataIndex: 'key',
      render: (key, record) => {
        if (key.includes('/')) {
          return key.split('/')[key.split('/').length - 1];
        } else {
          return key;
        }
      },
    },
    {
      title: '文件大小 ',
      dataIndex: 'size',
      render: (_, record) => formatFileSize(record.size),
    },
    {
      title: '上传用户 ',
      dataIndex: 'uploader',
      render: (_, record) => record.uploader || '-',
    },
    {
      title: '更新时间 ',
      dataIndex: 'updated_at',
    },
    {
      title: '操作',
      width: 200,
      render: (_, record) => (
        <>
          <TableButtons
            data={[
              {
                text: '下载',
                onClick: () => {
                  service.dataSet
                    .downloadfile({
                      dataset_id: dataSetId,
                      dataset_version_id: versionId + '',
                      key: record.key,
                    })
                    .then(res => {
                      if (res.status === 200) {
                        message.success('开始下载');
                        const a = document.createElement('a');

                        const url = res?.data?.url?.replace('http://', 'https://');
                        a.href = url;

                        console.log(res.data?.url, 'res.data?.url');
                        const name = record.key.split('/')[record.key.split('/').length - 1];
                        a.download = name;
                        a.click();
                      } else {
                        message.error('下载初始化失败');
                      }
                    });
                },
              },
            ]}
          />
        </>
      ),
    },
  ];
};

/* 项目数据详情-版本 */
export const getProjectVersionColumns = ({
  history,
  refresh,
  dataId,
  datasetName,
  projectId,
  datasetId,
}): ColumnsType<VersionDetail> => {
  return [
    {
      title: '版本',
      dataIndex: 'version',
      render: (_, record) => (
        <Link
          type="link"
          className={cx['table-version-col-link']}
          to={`${record.id}?datasetName=${datasetName}&desc=${record.desc}`}>
          {record.version}
        </Link>
      ),
    },
    {
      title: '描述',
      dataIndex: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: val => (
        <Tooltip placement="topLeft" title={val}>
          {val || '-'}
        </Tooltip>
      ),
    },
    {
      title: '版本ID ',
      dataIndex: 'id',
    },
    {
      title: '更新时间 ',
      dataIndex: 'update_time',
    },
    {
      title: '操作',
      width: 200,
      render: (_, record) => (
        <>
          <TableButtons
            data={[
              {
                text: '导入',
                disabled: !!record.sdk_created,
                onClick: () =>
                  history(
                    `${ProjectImportVersionPath}?projectId=${projectId}&datasetId=${datasetId}&datasetName=${datasetName}&version=${record.version}&versionId=${record.id}`
                  ),
              },
              {
                text: '删除',
                type: 'danger' as any,
                onClick: () =>
                  requestConfirm({
                    title: `确认删除数据集${record.version}版本？`,
                    content: '数据集版本将被删除且不可恢复，继承此版本的数据集版本不受影响。',
                    payload: {
                      dataset_version: record.version,
                      dataset_id: dataId,
                    },
                    submitApi: service.dataSet.deleteVersions,
                  }).then(() => {
                    refresh();
                  }),
              },
            ]}
          />
        </>
      ),
    },
  ];
};

/* 项目数据详情 */
export const projectDetailColumns: ItemOptions[] = [
  {
    label: '数据集名称',
    dataIndex: 'name',
    render: data =>
      data?.name ? (
        <Tooltip placement="topLeft" title={data?.zh_name}>
          {data?.name}
        </Tooltip>
      ) : (
        '-'
      ),
  },
  {
    label: '数据集ID',
    dataIndex: 'id',
  },
  {
    label: '版本数',
    dataIndex: 'version_count',
  },
  {
    label: '上传用户',
    dataIndex: 'create_by',
  },
  {
    label: '最后更新时间',
    dataIndex: 'update_time',
  },
  {
    label: '数据集描述',
    dataIndex: 'description',
    ellipsis: true,
    render: data =>
      data?.description ? (
        <Tooltip placement="topLeft" title={data?.description}>
          {data?.description}
        </Tooltip>
      ) : (
        '-'
      ),
  },
];

/* 项目数据详情-版本详情-基本详情 */
export const versionDetailInfoColumns: ItemOptions[] = [
  {
    label: '数据集名称',
    dataIndex: 'dataset_name',
  },
  {
    label: '版本',
    dataIndex: 'version',
  },
  // {
  //   label: '文件数',
  //   dataIndex: 'data_count',
  //   render: data => data?.data_count || '-',
  // },
  {
    label: '版本描述',
    dataIndex: 'description',
    ellipsis: true,
  },
];

/* 项目数据详情-版本详情-表格 */
export const getProjectVersionDetailColumns = ({
  refresh,
  dataId,
  versionId,
  datasetName,
  projectId,
}): ColumnsType<FileDetail> => {
  const isInLowcode = window.location.href.includes('project/detail');
  return [
    {
      title: '数据文件',
      dataIndex: 'key',
    },
    {
      title: '文件大小 ',
      dataIndex: 'size',
      render: (_, record) => formatFileSize(record.size),
    },
    {
      title: '上传用户 ',
      dataIndex: 'uploader',
      render: (_, record) => record.uploader || '-',
    },
    {
      title: '更新时间 ',
      dataIndex: 'updated_at',
      render: (_, record) => record.updated_at || '-',
    },
    {
      title: '操作',
      fixed: 'right',
      render: (_, record) => (
        <TableButtons
          data={[
            {
              text: '下载',
              onClick: () => {
                service.dataSet
                  .downloadfile({
                    key: record.key,
                    dataset_id: dataId,
                    dataset_version_id: versionId,
                  })
                  .then(res => {
                    console.log(res, 'resres');
                    if (res.status === 200) {
                      message.success('开始下载');
                      const a = document.createElement('a');
                      const url = res?.data?.url?.replace('http://', 'https://');
                      a.href = url;
                      a.download = record.name;
                      a.click();
                    } else {
                      message.error('下载初始化失败');
                    }
                  });
              },
            },
            {
              text: '删除',
              type: 'danger',
              onClick: () =>
                requestConfirm({
                  title: `确定删除文件 ${record.key}?`,
                  content: '删除文件后将不可恢复。',
                  submitApi: service.dataSet.deleteVersionsFile,
                  payload: {
                    keys: record.key,
                    project_id: projectId,
                    filename: record.key,
                    datasetsId: dataId,
                    id: versionId,
                    dataset_name: datasetName,
                  },
                }).then(() => {
                  refresh();
                }),
            },
          ]}
        />
      ),
    },
  ];
};

import { useEffect, useState } from 'react';
import { requestConfirm } from '@ai-training/utils';
import { usePagination } from '@fuxi/eevee-hooks';
import { Button, Flex, TableButtons, Tooltip } from '@fuxi/eevee-ui';
import type { ColumnsType } from 'antd/es/table';
import { Link, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import ml, { getMlPublicCapabilitiesByPage } from '@/service/ml';
import { useAppSelector } from '@/hooks';
import { MlProjectDetail } from '@/@types/ml';
import { MlSearchInput } from '../components/MlSearchInput';
import cx from './index.module.less';

export const MlProjectAbility = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState<string>();
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const { deleteCapability } = ml;
  const { data, pagination, run, loading } = usePagination(
    ({ current, pageSize }) => {
      return getMlPublicCapabilitiesByPage({
        current,
        pageSize,
        keyword,
        share: false,
      });
    },
    {
      defaultPageSize: 8,
      manual: true,
    }
  );

  useEffect(() => {
    if (!aiProjectId) {
      return;
    }
    setKeyword(undefined);
    run({
      current: 1,
      pageSize: pagination.pageSize,
      keyword: undefined,
    });
  }, [aiProjectId]);

  const refresh = () => {
    run({
      current: pagination.current,
      pageSize: pagination.pageSize,
      keyword,
    });
  };

  const columns: ColumnsType<MlProjectDetail> = [
    {
      title: '能力名称',
      dataIndex: 'name',
      ellipsis: {
        showTitle: false,
      },

      render: (_, record) => (
        <Tooltip placement="topLeft" title={record.name}>
          <Link type="link" to={`${record.uuid}`}>
            {record.name}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: '描述',
      dataIndex: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: val => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: '基础能力',
      dataIndex: 'source',
      ellipsis: {
        showTitle: false,
      },
      render: val => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: '版本数',
      dataIndex: 'version_num',
    },
    {
      title: '更新时间',
      dataIndex: 'updated_at',
      ellipsis: {
        showTitle: false,
      },
      render: val => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: '操作',
      render: (_, record) => (
        <TableButtons
          data={[
            {
              text: '训练',
              onClick: () => navigate(`../../ml-training-task/create?abilityId=${record.uuid}`),
            },
            {
              text: '发布',
              onClick: () => navigate(`../../model-service/create?abilityId=${record.uuid}`),
            },
            {
              text: '删除',
              type: 'danger',
              onClick: () =>
                requestConfirm({
                  title: `确定删除能力 ${record.name}?`,
                  content: '能力及其版本将全部被删除，且不可恢复。',
                  payload: {
                    id: record.uuid,
                  },
                  submitApi: deleteCapability,
                }).then(() => {
                  refresh();
                }),
            },
          ]}
        />
      ),
    },
  ];

  const submit = (keyword: string) => {
    run({
      current: 1,
      pageSize: pagination.pageSize,
      keyword,
    });
  };

  const onKeywordChange = (keyword: string) => {
    setKeyword(keyword);
  };

  return (
    <div>
      <div className={cx('title')}>项目能力</div>

      <Flex justifyBetween>
        <Flex alignCenter>
          <Button type="primary" onClick={() => navigate('create')}>
            <PlusOutlined />
            创建项目能力
          </Button>
        </Flex>
        <div>
          <MlSearchInput
            deps={[aiProjectId]}
            onKeywordChange={onKeywordChange}
            placeholder={'请输入 能力名称 进行搜索'}
            onSearch={keyword => submit(keyword)}
          />
        </div>
      </Flex>

      <Table
        loading={loading}
        className={cx('table')}
        pagination={{
          pageSize: pagination.pageSize,
          showQuickJumper: true,
          onChange: pagination.onChange,
          current: pagination.current,
          total: pagination.total,
          showSizeChanger: true,
          pageSizeOptions: [8, 10, 16, 32, 64],
          showTotal: () => `共 ${pagination.total} 条`,
        }}
        columns={columns}
        dataSource={data?.list}></Table>
    </div>
  );
};

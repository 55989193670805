/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-lines */
import { useEffect, useRef, useState } from 'react';
import { DatePicker, Divider, Dropdown, Flex, Icon, MenuProps, Space, Spin, message } from '@fuxi/eevee-ui';
import { useThrottleFn } from '@fuxi/eevee-hooks';
import { CellMeasurerCache } from 'react-virtualized';
import dayjs from 'dayjs';
import { useDebounceFn, useSize } from 'ahooks';
import { DownOutlined } from '@ant-design/icons';
import { ServiceStatusType } from '@ai-training/types/onlineService';
import { VirtualList } from '@/components/VirtualList';
import { LogItem } from '@/store/realtimeLogs';
import { ModelServiceLogInstance } from '@/service/common';
import { Log } from '@/pages/project/ProjectDetail/LogsWithVirtualScroll';
import useWindowSize from '@/hooks/useWindowSize';
import { sendTrainingSocketInfo } from '@/tracker';
import { useAppSelector } from '@/hooks';
import { ReconnectMsg } from '@/pages/project/ProjectDetail/LogsWithVirtualScroll/ReconnectMsg';
import { MlSearchInput } from '../../components/MlSearchInput';
import cx from './index.module.less';

interface Prop {
  podNameList: string[];
  podSelectable?: boolean;
  // 历史日志最长保存时间
  historyDuration?: number;
  historyLogUrl?: string;
  realtimeLogUrl?: string;
  trainingName?: string;
  trainingStatus?: ServiceStatusType;
}

// interface Service

export const ModelServiceLog = (props: Prop) => {
  const projectId = useAppSelector(state => state.project.currentProject?.id) || 0;

  const {
    podNameList,
    podSelectable = true,
    historyDuration = 7,
    realtimeLogUrl,
    historyLogUrl,
    trainingStatus,
  } = props;
  const [keyword, setKeyword] = useState<string>('');
  const [logItems, setLogItems] = useState<LogItem[]>([]);
  const [historyLogItems, setHistoryLogItems] = useState<LogItem[]>([]);
  const pagesize = 10000;
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [forceUpdate, setForceUpdate] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const vcWrapperRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const [virtualListHeight, setVirtualListHeight] = useState<number>(0);
  const containerSize = useSize(containerRef);
  const [selectedPod, setSelectedPod] = useState<string>(podNameList[0]);
  const [timeRange, setTimeRange] = useState<number[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTitle, setLoadingTitle] = useState<string>('加载中...');
  const maxLogLength = 100000;
  // 未选时间范围时，显示实时日志
  const displayRealtimeLogs = !timeRange;
  // 上一次最早的日志，用于拉到顶部请求完数据后，滚动条重新定位至刚刚的最早日志
  const [lastEarliestHistoryLog, setLastEarliestHistoryLog] = useState<{ log: LogItem }>();
  const [wsInstance, setWsInstance] = useState<WebSocket | null>(null);
  // 实时日志连接时间
  const [connectTime, setConnectTime] = useState<Date | undefined>(undefined);
  let connectInterval: NodeJS.Timeout | null = null;
  const [showReconnectButton, setShowReconnectButton] = useState(false);
  const [reConnect, setReConnect] = useState<Date | undefined>(undefined);

  // setForceUpdate加入防抖，使用useDebounceFn
  const { run: reRenderList } = useDebounceFn(
    () => {
      setForceUpdate(new Date().getTime().toString());
    },
    {
      wait: 33,
    }
  );

  useEffect(() => {
    // 30分钟内开始时间未改变时，主动断开ws连接；mousemove、重新连接会重置开始时间戳、并重新计时
    const thirtyMins = 30 * 60 * 1000;
    if (connectInterval) {
      clearInterval(connectInterval);
    }
    connectInterval = setInterval(() => {
      unmountRealtimeLog(true);
    }, 120000);

    return () => {
      connectInterval && clearInterval(connectInterval);
    };
  }, [connectTime]);

  // 断开ws连接等卸载操作, initiative为true时，表明因前端不活跃而主动断开连接
  const unmountRealtimeLog = (initiative?: boolean) => {
    if (wsInstance) {
      wsInstance.close();
      setWsInstance(null);
      if (initiative) {
        setShowReconnectButton(true);
      }
    }
  };

  useEffect(() => {
    if (!!containerRef.current?.clientWidth) {
      reRenderList();
    }
  }, [containerSize]);

  useEffect(() => {
    const contentWrapper = document.getElementById('project-detail-content');
    if (!contentWrapper) return;
    // 设置contentWrapper的伪类
    contentWrapper.classList.add('hidden-scrollbar');

    return () => {
      contentWrapper.classList.remove('hidden-scrollbar');
      unmountRealtimeLog();
    };
  }, []);

  useEffect(() => {
    const padding = 50;
    const minHeight = 400 - 24;
    const top = vcWrapperRef.current?.getBoundingClientRect().top || 0;
    const height = windowSize.height - top - padding;
    setVirtualListHeight(height >= minHeight ? height : minHeight);
    // TODO: 调整高度加loading
  }, [windowSize, vcWrapperRef.current?.getBoundingClientRect().top]);

  const filterEmptyContent = (content: string) => {
    return content.replace(/\s/g, '');
  };

  useEffect(() => {
    if (!realtimeLogUrl || trainingStatus !== ServiceStatusType.RUNNING) return;
    setLoading(true);
    setLoadingTitle('正在建立ws链接...');
    // 建立websocket连接
    const ws = new WebSocket(realtimeLogUrl + '&timestamps=true');
    setWsInstance(ws);
    ws.onopen = () => {
      sendTrainingSocketInfo(true, { projectId, realtimeLogUrl });
      setLoading(false);
      setConnectTime(new Date());
    };
    ws.onmessage = evt => {
      const data = evt.data;
      const timestampPattern = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+[\\+\\-]\d{2}:\d{2})\s+/;
      const timestamp = data.match(timestampPattern)?.[0];
      const uid = new Date().getTime().toString();
      if (timestamp) {
        const [timestamp, content] = extractTimestamp(data, timestampPattern) as string[];
        // 过滤空格、空字符串、换行符
        if (!content.replace(/\s/g, '')) return;
        const timestampOnShow = dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss');
        setLogItems(preLogItems => {
          if (preLogItems.length > maxLogLength) {
            preLogItems.shift();
          }
          return [...preLogItems, { uid, content: content, createdAt: timestampOnShow }];
        });
      } else {
        if (!data.replace(/\s/g, '')) return;

        setLogItems(preLogItems => {
          if (preLogItems.length > maxLogLength) {
            preLogItems.shift();
          }
          return [...preLogItems, { uid, content: data, createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss') }];
        });
      }
    };

    ws.onclose = () => {
      setLoading(false);
      sendTrainingSocketInfo(false, { projectId, realtimeLogUrl });
    };

    ws.onerror = e => {
      setLoading(false);
      console.log('ws error', e);
    };
    return () => {
      ws.close();
      setLoading(false);
    };
  }, [realtimeLogUrl, reConnect]);

  const getFilterRealtimeLogsByKeyword = () => {
    // 大小写不敏感
    return logItems.filter(item => item.content.toLowerCase().includes(keyword.toLowerCase()));
  };

  const extractTimestamp = (input: string, pattetn: RegExp) => {
    // 正则表达式匹配 ISO 8601 格式的日期时间
    // 提取时间戳
    const match = input.match(pattetn);
    if (match) {
      // 匹配到的完整时间戳字符串
      const timestamp = match[1];
      // 移除时间戳和空格后的字符串
      const stringWithoutTimestamp = input.replace(pattetn, '');
      return [timestamp, stringWithoutTimestamp];
    }

    // 如果没有匹配到时间戳，返回 null
    return null;
  };

  const virtualListRef = useRef<{
    reRenderLogItem: (index: number) => void;
    scrollToRow: (index: number) => void;
    getIsOverflow: () => boolean;
  }>();

  const onSearch = (keyword: string) => {
    setKeyword(keyword);
    setForceUpdate(new Date().getTime().toString());
  };

  // clear为是否清空历史日志，还是拼接在一起
  const fetchHistoryLogs = async (
    params: {
      selectedPod?: string;
      page?;
      pagesize?;
      timeRange?: number[] | undefined;
      keyword?: string;
    },
    clear: boolean,
    byMousewheel: boolean
  ) => {
    if (!historyLogUrl) return;

    const { pathname, search } = new URL(historyLogUrl);
    const logs = await ModelServiceLogInstance.get(`${pathname + search}`, {
      params: {
        match: keyword ?? undefined,
        starttime: params.timeRange?.[0] ?? undefined,
        endtime: params.timeRange?.[1] ?? undefined,
        pagesize: pagesize,
        page: params.page ?? pageIndex,
      },
    });
    const newLogs: LogItem[] =
      logs.data?.map((item: any) => ({
        uid: item.id,
        content: item.log,
        createdAt: dayjs(item.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      })) || [];

    const newLogsLength = newLogs.length;

    if (!!newLogsLength && newLogsLength === pagesize) {
      message.success(`已加载${pagesize}条历史日志, 向上滚动以加载更多日志`);
    } else if (newLogsLength === 0 && byMousewheel) {
      message.info('已加载全部历史日志');
    }

    if (clear) {
      setHistoryLogItems(newLogs.filter(item => filterEmptyContent(item.content)));
    } else {
      if (historyLogItems.length + newLogsLength > maxLogLength) {
        const overflow = historyLogItems.length + newLogsLength - maxLogLength;
        const displayLogs = newLogs.concat(historyLogItems).slice(overflow);
        setHistoryLogItems(displayLogs.filter(item => filterEmptyContent(item.content)));
      } else {
        setHistoryLogItems(preLogs => [...newLogs, ...preLogs].filter(item => filterEmptyContent(item.content)));
      }
    }
  };

  useEffect(() => {
    setPageIndex(1);
    if (!timeRange) {
      return;
    }
    setLoadingTitle('正在请求历史日志...');
    setLoading(true);
    fetchHistoryLogs(
      {
        keyword,
        selectedPod,
        page: 1,
        pagesize,
        timeRange,
      },
      true,
      false
    ).finally(() => {
      setLoading(false);
    });
  }, [selectedPod, keyword, timeRange]);

  const reRenderRow = (key: string) => {
    const index = logItems?.findIndex(item => item.uid === key);
    virtualListRef?.current?.reRenderLogItem(index);
  };

  const rowRender = (rowData: any) => {
    return <Log {...rowData} searchValue={keyword} reRenderAfterHeightChange={key => reRenderRow(key)} />;
  };

  const [cache] = useState(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 40,
      minHeight: 40,
    })
  );

  const podItems: MenuProps['items'] = podNameList.map(item => ({ key: item, label: item }));
  const { RangePicker } = DatePicker;

  const onRangeChange = (_, dateStrings: [string, string]) => {
    // 清空range的情况
    if (dateStrings.includes('')) {
      setTimeRange(undefined);
      return;
    }

    const range = dateStrings.map(item => dayjs(new Date(item)).valueOf());
    setTimeRange(range);
  };

  const onPodSelect = (item: { key: string }) => {
    setSelectedPod(item.key);
  };

  // 不能选择早于七天前的零点
  const disbaledDate = current => {
    return current < dayjs().subtract(historyDuration, 'day').startOf('day') || current > dayjs().endOf('day');
  };

  const disabledTime = now => {
    const minutesLimit = 15;
    const hoursPerDay = 24;
    const minutesPerHour = 60;
    const secondsPerMinute = 60;
    // 没选日期不能选时分秒
    if (!now) {
      return {
        disabledHours: () => {
          return Array.from({ length: hoursPerDay }, (_, i) => i);
        },
        disabledMinutes: () => {
          return Array.from({ length: minutesPerHour }, (_, i) => i);
        },
        disabledSeconds: () => {
          return Array.from({ length: secondsPerMinute }, (_, i) => i);
        },
      };
    }

    //  所选日期为今天时，不可选择未来时间
    if (dayjs(now).isSame(dayjs(), 'day')) {
      return {
        disabledHours: () => {
          if (dayjs().minute() < minutesLimit) {
            return Array.from({ length: hoursPerDay }, (_, i) => i).slice(dayjs().hour());
          }
          return Array.from({ length: hoursPerDay }, (_, i) => i).slice(dayjs().hour() + 1);
        },
        disabledMinutes: () => {
          if (dayjs(now).hour() === dayjs().hour()) {
            if (dayjs().minute() >= minutesLimit) {
              return Array.from({ length: minutesPerHour }, (_, i) => i).slice(dayjs().minute() - 14);
            } else {
              return Array.from({ length: minutesPerHour }, (_, k) => k);
            }
          }
          if (dayjs(now).hour() === dayjs().hour() - 1) {
            if (dayjs().minute() >= minutesLimit) {
              return [];
            }
            return Array.from({ length: minutesPerHour }, (_, i) => i).slice(dayjs().minute() - 14);
          }

          return [];
        },
      };
    }
    return {};
  };

  const onMousewheel = async ({ scrollTop, scrollHeight }: { scrollTop: number; scrollHeight: number }) => {
    throttleOnMouseEvent();

    // 向上请求历史日志
    if (displayRealtimeLogs) {
      return;
    }
    if (scrollHeight > 0 && scrollTop <= 0) {
      if (!historyLogItems.length) {
        return;
      }

      const earliestLog = historyLogItems[0];
      setLoading(true);
      await fetchHistoryLogs(
        {
          keyword,
          selectedPod,
          page: pageIndex + 1,
          pagesize,
          timeRange,
        },
        false,
        true
      );

      setPageIndex(pre => pre + 1);
      setLastEarliestHistoryLog({ log: earliestLog });
      setLoading(false);

      // setTimeout(() => {
      //   toFetch(tabKey, false);
      // }, 600);
      setForceUpdate(new Date().getTime().toString());
    }
  };

  const { run: throttleOnMouseEvent } = useThrottleFn(
    () => {
      setConnectTime(new Date());
    },
    {
      wait: 1000,
    }
  );

  const reconnectMsg = () => {
    setHistoryLogItems([]);
    setLogItems([]);
    setTimeRange(undefined);
    setKeyword('');
    setForceUpdate(new Date().getTime().toString());
    setShowReconnectButton(false);
    setReConnect(new Date());
  };

  return (
    <Spin spinning={loading} tip={loadingTitle}>
      <div
        ref={containerRef}
        className={cx('log-wrapper')}
        onMouseMove={throttleOnMouseEvent}
        onMouseDown={throttleOnMouseEvent}>
        <Flex className={cx('toolbar')} alignCenter>
          <MlSearchInput width={180} placeholder="请输入" onSearch={onSearch} />

          {podSelectable && (
            <>
              <Divider type="vertical" />

              <Dropdown
                className={cx('dropdown')}
                menu={{ items: podItems, onClick: e => onPodSelect(e) }}
                placement="bottomLeft">
                <Space>
                  <div className={cx('displayed-name')}>{selectedPod}</div>
                  <DownOutlined />
                </Space>
              </Dropdown>
            </>
          )}

          <Divider type="vertical" />
          <RangePicker
            showTime={{
              defaultValue: [dayjs().startOf('day'), dayjs().startOf('day')],
            }}
            disabledDate={disbaledDate}
            disabledTime={disabledTime}
            onChange={onRangeChange}
            value={timeRange ? [dayjs(timeRange[0]), dayjs(timeRange[1])] : undefined}
          />

          <Flex className={cx('tip-wrapper')} alignCenter>
            <Icon size={16} className={cx('tip-icon')} name="info-circle"></Icon>
            <div>已保存{historyDuration}天内日志</div>
          </Flex>
        </Flex>
        <div ref={vcWrapperRef} className={cx('list-wrapper')}>
          <VirtualList
            onListScroll={throttleOnMouseEvent}
            lastEarliestHistoryLog={lastEarliestHistoryLog}
            onMousewheel={onMousewheel}
            forceUpdate={forceUpdate}
            ref={virtualListRef}
            containerHeight={virtualListHeight}
            cache={cache}
            list={displayRealtimeLogs ? getFilterRealtimeLogsByKeyword() : historyLogItems}
            rowRender={rowRender}
            scrollToAlignment="start"
          />
        </div>
      </div>

      {showReconnectButton && (
        <div onClick={reconnectMsg}>
          <ReconnectMsg />
        </div>
      )}
    </Spin>
  );
};

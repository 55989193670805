export const modelPublishStatusMap = {
  published: {
    label: '已上架',
    color: '#36BD1F',
  },
  concealed: {
    label: '已下架',
    color: '#DE5550',
  },
};

export const DOCKER_RESOURCE_ARRAY = [
  { cpu: 1, memory: 1, gpu: 0 },
  { cpu: 1, memory: 2, gpu: 0 },
  { cpu: 1, memory: 4, gpu: 0 },
  { cpu: 2, memory: 8, gpu: 0 },
  { cpu: 4, memory: 16, gpu: 0 },
  { cpu: 8, memory: 16, gpu: 0 },
  { cpu: 8, memory: 32, gpu: 0 },
  { cpu: 8, memory: 64, gpu: 0 },
  { cpu: 12, memory: 64, gpu: 0 },
  { cpu: 24, memory: 128, gpu: 0 },
  { cpu: 36, memory: 192, gpu: 0 },
  { cpu: 40, memory: 128, gpu: 0 },
  { cpu: 64, memory: 64, gpu: 0 },
  { cpu: 2, memory: 8, gpu: 1 },
  { cpu: 2, memory: 16, gpu: 1 },
  { cpu: 4, memory: 16, gpu: 1 },
  { cpu: 4, memory: 128, gpu: 1 },
  { cpu: 6, memory: 30, gpu: 1 },
  { cpu: 6, memory: 60, gpu: 1 },
  { cpu: 8, memory: 30, gpu: 1 },
  { cpu: 10, memory: 30, gpu: 1 },
  { cpu: 10, memory: 80, gpu: 1 },
  { cpu: 12, memory: 60, gpu: 1 },
  { cpu: 16, memory: 60, gpu: 1 },
  { cpu: 20, memory: 80, gpu: 1 },
  { cpu: 20, memory: 128, gpu: 1 },
  { cpu: 32, memory: 64, gpu: 1 },
  { cpu: 40, memory: 128, gpu: 1 },
  { cpu: 40, memory: 200, gpu: 1 },
  { cpu: 6, memory: 30, gpu: 2 },
  { cpu: 12, memory: 60, gpu: 2 },
  { cpu: 12, memory: 128, gpu: 2 },
  { cpu: 10, memory: 30, gpu: 4 },
  { cpu: 12, memory: 60, gpu: 4 },
  { cpu: 12, memory: 128, gpu: 4 },
  { cpu: 20, memory: 128, gpu: 4 },
  { cpu: 20, memory: 400, gpu: 4 },
  { cpu: 60, memory: 128, gpu: 4 },
  { cpu: 24, memory: 256, gpu: 5 },
  { cpu: 20, memory: 100, gpu: 6 },
  { cpu: 24, memory: 256, gpu: 6 },
  { cpu: 40, memory: 160, gpu: 6 },
  { cpu: 40, memory: 200, gpu: 6 },
  { cpu: 60, memory: 200, gpu: 6 },
  { cpu: 1, memory: 1, gpu: 8 },
  { cpu: 40, memory: 128, gpu: 8 },
  { cpu: 40, memory: 200, gpu: 8 },
  { cpu: 40, memory: 256, gpu: 8 },
  { cpu: 58, memory: 748, gpu: 8 },
  { cpu: 122, memory: 748, gpu: 8 },
] as const;

export const GPU_LIST = [
  {
    value: '1080TI',
    label: '1080TI',
  },
  {
    value: '2080TI',
    label: '2080TI',
  },
  {
    value: '3090',
    label: '3090',
  },
  {
    value: 'T4',
    label: 'T4',
  },
  {
    value: 'A30',
    label: 'A30',
  },
  {
    value: 'A100',
    label: 'A100',
  },
  {
    value: 'A10',
    label: 'A10',
  },
  {
    value: '4090',
    label: '4090',
  },
] as const;

export enum FieldNamesForTrain {
  Args = 'args',
  Code = 'code',
  Command = 'command',
  CommitId = 'git_commit',
  ImageFile = 'image_uri',
  Resource = 'resources',
  Script = 'script',
  GPU = 'gpu_type',
  Ports = 'ports',
  Env = 'envs',
  IsFullTrain = 'full_train',
  IsEnableDataset = 'is_enable_dataset',
  Tensorboard = 'tensorboard',
  Repo = 'git_uri',
  GitBranch = 'git_branch',
  PathForTensorbard = 'tensorboard_path',
  CustomConfigFile = 'custom_config_file',
  ProjectStorage = 'project_storage',
  DiskStorage = 'disk_storage',
  IsMountDataset = 'is_mount_dataset',
  DatasetType = 'dataset_type',
  DatasetName = 'dataset_name',
  DatasetId = 'dataset_id',
  DatasetInput = 'input',
  DatasetOutput = 'output',
  Model = 'model',
  ModelVersion = 'model_version',
  ModelName = 'model_name',
  ModelInfo = 'model_info',
  TrainDatasetName = 'train_dataset_name',
  EvalDatasetName = 'eval_dataset_name',
  ParameterBuketId = 'parameter_bucket_uuid',
  ParameterName = 'parameter_name',
  ParameterVersion = 'parameter_version',
  DeployMethod = 'deploy_method',
  ClientMaxBatch = 'client_max_batch_size',
  ServerBactching = 'batch',
  ServerMaxBatch = 'server_batch_size',
  ServerMaxDelay = 'server_max_delay_milliseconds',
  LimitPerInstance = 'limit_per_instance',
  DynamicLoadBalance = 'load_balance',
  DynamicLoadBalanceEnable = 'enable',
}

// 这里的值仅仅用来做表单的校验，不会传输到真正的form对象里
export const FieldNamesForTrainLabel: FieldNamesForTrain[] = [
  FieldNamesForTrain.Code,
  FieldNamesForTrain.IsEnableDataset,
  FieldNamesForTrain.IsMountDataset,
] as const;

export const NoneCustomConfigParameter = ['youling-keep']; //未上传自定义配置文件时返回的默认参数

export const IDL_CONFIG_ENV_VAS = [
  {
    name: 'IDL_PATH',
    value: 'aop_idl',
  },
  { name: 'CONFIG_PATH', value: 'custom-config' },
];

const DEFAULT_ENV_VARS = {
  AISYSTEM_DATASET_PATH: '/app/asset/dataset/',
  AISYSTEM_MODEL_PATH: '/app/asset/model/',
  AISYSTEM_PARAMETER_PATH: '/app/asset/parameter/',
  SECRET_KEY: '',
  ACCESS_KEY: '',
  ADDRESS: '',
  PROJECT_ID: '',
  IDL_PATH: '',
  CONFIG_PATH: '',
  // Add other default environment variables here if needed
} as const;

// Generate the key list from DEFAULT_ENV_VARS
export const DEFAULT_VARIABLE_KEY_LIST = Object.keys(DEFAULT_ENV_VARS) as (keyof typeof DEFAULT_ENV_VARS)[];

export const AISYSTEM_TENSORBOARD_PATH = 'AISYSTEM_TENSORBOARD_PATH';

// Generate the variable list from DEFAULT_ENV_VARS
export const DEFAULT_VARIABLE_LIST = [
  ...Object.entries(DEFAULT_ENV_VARS),
  [AISYSTEM_TENSORBOARD_PATH, '/app/asset/tensorboard/'],
] as [string, string][];

// Generate the string representation for environment variables
export const SYSTEM_ENV_VARIABLE = Object.entries(DEFAULT_ENV_VARS)
  .filter(([key, value]) => value)
  .map(([key, value]) => `${key} = '${value}'`)
  .join('\n');

export enum ABILITY_VERSION_KEY {
  CapabilityVersion = 'capabilityVersion',
  CapabilityVersionBase = 'capabilityVersionBase',
  CapabilityVersionTrain = 'capabilityVersionTrain',
  CapabilityVersionEvaluate = 'capabilityVersionEvaluate',
  CapabilityVersionService = 'capabilityVersionService',
  CapabilityVersionModelService = 'capabilityVersionModelService',
  Extra = 'extra',
}

// 代表 URL 参数里表示的能力版本的状态，为第一优先级，如果存在则优先使用，然后再使用 CurrentVersionStatus
export enum VersionStatus {
  Update = 'update',
  Copy = 'copy',
  Create = 'create',
}

// 代表当前表单页中的能力版本的状态
export enum CurrentVersionStatus {
  Pending = 'pending',
  Update = 'update',
  Copy = 'copy',
  Create = 'create',
}

export const DEFAULT_PROJECT_STORAGE = '/output/workspace';

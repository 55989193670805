/* eslint-disable max-lines */
import React, { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { TableButtons, Tooltip, message, Badge, Input, Flex, Dropdown, Space } from '@fuxi/eevee-ui';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import ability from '@ai-training/service/ability';

import { isEmpty } from 'lodash';

import { useAppDispatch } from '@/hooks';
import { resetStateToInitial, INITITAL_STATE } from '@/store/aiTraining';

import { datasetTypeOptions } from './components/VersionBaseConfig';
import service from '../../service';
import { requestConfirm } from '../../utils';
import { formatDuration } from '../../utils/util';
import { ItemOptions } from '../../components/Descriptions';
import { modelPublishStatusMap } from '../../constants/ability';
import { createTrainingPath } from '../../pages/trainingTask/const';
import { releaseServicePath } from '../../pages/onlineService/const';
import { projectVersionDetailPath } from '../../pages/dataSet/const';
import {
  PublicDetail,
  ProjectDetail,
  VersionDetail,
  VersionDetailInfo,
  ModelVersionList,
  AbilityTagInfo,
} from '../../types/ability';

import { AbilityTag } from './components/AbilityTag';
import cx from './index.module.less';

// 公共能力路由
export const abilityPublicPath = '../ai-capability';
// 公共能力详情路由
export const abilityPublicDetailPath = '../../ability/public-ability/';
// 公共能力调用
export const transferPath = '../../ability/public-ability/transfer';
// 项目能力路由
export const abilityProjectPath = '../../ability/project-ability';
// 项目能力详情路由
export const abilityProjectDetailPath = '../ability/project-ability/';
// 创建能力路由
export const createAbilityPath = '../../ability/project-ability/create';
// 能力发布路由
export const projectAbilityReleasePath = '../../ability/project-ability/release/';
export const publicAbilityReleasePath = '../../ability/public-ability/release/';

// 公共能力
export const publicColumns: ColumnsType<PublicDetail> = [
  {
    title: '能力名称',
    dataIndex: 'name',
    ellipsis: {
      showTitle: false,
    },
    render: (_, record) => (
      <Tooltip placement="topLeft" title={record.name}>
        <div className="list-link-ellipsis">
          <Link type="link" to={`${record.uuid}`}>
            {record.name}
          </Link>
        </div>
      </Tooltip>
    ),
  },
  {
    title: '描述',
    dataIndex: 'desc',
    ellipsis: {
      showTitle: false,
    },
    render: val => (
      <Tooltip placement="topLeft" title={val}>
        {val || '-'}
      </Tooltip>
    ),
  },
  {
    title: '版本数',
    dataIndex: 'version_num',
  },
  {
    title: '更新时间',
    dataIndex: 'updated_at',
    ellipsis: {
      showTitle: false,
    },
    render: val => (
      <Tooltip placement="topLeft" title={val}>
        {val}
      </Tooltip>
    ),
  },
];

const getAbilityTagValue = (record: ProjectDetail) => {
  if (record?.tag === AbilityTagInfo.dev.key) {
    return (
      <AbilityTag
        value={AbilityTagInfo.dev.name}
        color={AbilityTagInfo.dev.color}
        borderColor={AbilityTagInfo.dev.borderColor}></AbilityTag>
    );
  }
  if (record?.tag === AbilityTagInfo.main.key) {
    return (
      <AbilityTag
        value={AbilityTagInfo.main.name}
        color={AbilityTagInfo.main.color}
        borderColor={AbilityTagInfo.main.borderColor}></AbilityTag>
    );
  }
  return null;
};

// 项目能力
export const getProjectColumns = ({ history, refresh }): ColumnsType<ProjectDetail> => {
  return [
    {
      title: '能力名称',
      dataIndex: 'name',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <Flex alignCenter>
          <Link className="list-link-ellipsis" type="link" to={`${record.uuid}`}>
            <Tooltip placement="topLeft" title={record.name}>
              {record.name}
            </Tooltip>
          </Link>
          {getAbilityTagValue(record)}
        </Flex>
      ),
    },
    {
      title: '描述',
      dataIndex: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: val =>
        !!val ? (
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: '基础能力',
      dataIndex: 'source',
      ellipsis: {
        showTitle: false,
      },
      render: val =>
        !!val ? (
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: '版本数',
      dataIndex: 'version_num',
    },
    {
      title: '更新时间',
      dataIndex: 'updated_at',
      ellipsis: {
        showTitle: false,
      },
      render: val => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: '操作',
      width: 232,
      render: (_, record) => {
        const dispatch = useAppDispatch();

        const newVersionButton = {
          text: '新建版本',
          onClick: async () => {
            await dispatch(resetStateToInitial(INITITAL_STATE));
            history(`version/${record.uuid}?ability_name=${record.name}&version_status=create`);
          },
        };

        const deleteVersion = () => {
          requestConfirm({
            title: `确定删除能力 ${record.name}?`,
            content: '能力及其版本将全部被删除，且不可恢复。',
            payload: {
              id: record.uuid,
            },
            submitApi: service.ability.deleteCapability,
          }).then(() => {
            refresh();
          });
        };

        return (
          <Space size={0}>
            <TableButtons data={[newVersionButton, { text: '' }]} />
            <Dropdown
              arrow
              menu={{
                items: [
                  {
                    key: 'train',
                    label: (
                      <a
                        style={{ color: '#1668dc' }}
                        onClick={() => history(`${createTrainingPath}?abilityId=${record.uuid}`)}>
                        训练
                      </a>
                    ),
                  },
                  {
                    key: 'publish',
                    label: (
                      <a
                        style={{ color: '#1668dc' }}
                        onClick={() => history(`${releaseServicePath}?abilityId=${record.uuid}`)}>
                        发布
                      </a>
                    ),
                  },
                  {
                    key: 'delete',
                    label: (
                      <a style={{ color: '#dc4446' }} onClick={deleteVersion}>
                        删除
                      </a>
                    ),
                  },
                ],
              }}>
              <a style={{ color: '#1668dc' }}>更多</a>
            </Dropdown>
          </Space>
        );
      },
    },
  ];
};
// 项目能力详情-基本信息
export const projectDetailColumns: ItemOptions[] = [
  {
    label: '能力ID',
    dataIndex: 'uuid',
  },
  {
    label: '基础能力',
    dataIndex: 'base_capability.name',
    render(data, item) {
      return !!data?.base_capability?.name ? (
        <Link to={`../${abilityPublicDetailPath}${data?.base_capability?.id}`}>{data?.base_capability?.name}</Link>
      ) : (
        '-'
      );
    },
  },
  {
    label: '创建者',
    dataIndex: 'create_by',
    render: data => data?.create_by || '-',
  },
  {
    label: '创建时间',
    dataIndex: 'created_at',
  },
  {
    label: '版本数',
    dataIndex: 'version_num',
  },
  {
    label: '最后更新时间',
    dataIndex: 'updated_at',
  },
];
// 公共能力详情-基本信息
export const publicDetailColumns: ItemOptions[] = [
  {
    label: '能力名称',
    dataIndex: 'name',
    ellipsis: true,
    render: data => data?.name || '-',
  },
  {
    label: '版本数',
    dataIndex: 'version_num',
    render: data => data?.version_num || '-',
  },
  {
    label: '能力ID',
    dataIndex: 'uuid',
    render: data => data?.uuid || '-',
  },
  {
    label: '创建时间',
    dataIndex: 'created_at',
    span: 2,
    render: data => data?.created_at || '-',
  },
  {
    label: '最后更新时间',
    dataIndex: 'updated_at',
    render: data => data?.updated_at || '-',
  },
  {
    label: '能力描述',
    dataIndex: 'description',
    span: 3,
    render: data =>
      data?.description ? (
        <Tooltip placement="topLeft" title={data?.description}>
          {data?.description}
        </Tooltip>
      ) : (
        '-'
      ),
  },
];
// 公共能力详情-版本
export const publicVersionColumns: ColumnsType<VersionDetail> = [
  {
    title: '版本',
    dataIndex: 'uuid',
    render: (_, record) => (
      <Link type="link" to={`${record.uuid}`} className={cx['table-version-col-link']}>
        {record.code}
      </Link>
    ),
  },
  {
    title: '描述',
    dataIndex: 'description',
    ellipsis: {
      showTitle: false,
    },
    render: val => (
      <Tooltip placement="topLeft" title={val}>
        {val || '-'}
      </Tooltip>
    ),
  },
  {
    title: '版本ID',
    dataIndex: 'uuid',
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    render: val => new Date(val).toLocaleString(),
  },
];

// 项目能力详情-版本
export const getProjectVersionColumns = ({
  isExtend,
  history,
  abilityId,
  refresh,
  dataSource,
  abilityName,
  urlParams,
}): ColumnsType<VersionDetail> => {
  return [
    {
      title: '版本',
      dataIndex: 'code',
      render: (_, record) => (
        <Link type="link" to={`${record.uuid}`} className={cx['table-version-col-link']}>
          {record.code}
        </Link>
      ),
    },
    {
      title: '描述',
      dataIndex: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: val =>
        !!val ? (
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: '版本ID',
      dataIndex: 'uuid',
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
    },
    {
      title: '操作',
      fixed: 'right',
      width: 232,
      render: record => {
        const download = () => {
          const { downloadAbilityVersion } = service.ability;
          message.info('请求已发起，请耐心等待');
          downloadAbilityVersion({
            capability_version_uuid: record.uuid,
          }).then(async res => {
            if (res.status !== 200) {
              message.error('下载失败');
              return;
            }
            const urlList = res.data;

            console.log(urlList);

            for (let i = 0; i < urlList.length; i++) {
              const url = urlList[i];
              console.log(url, 'ulr');
              const a = document.createElement('a');
              const downloadUrl = url?.replace('http://', 'https://');
              a.href = downloadUrl;
              a.download = record.uuid;
              a.click();
              // 等待500ms，否则不会触发后续文件下载
              await new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                }, 500);
              });
            }
          });
        };

        const deleteVersion = () => {
          if (dataSource?.length === 1) {
            message.warning('不能删除最后一个版本');
            return;
          }
          requestConfirm({
            title: `确定删除能力${abilityName} 的${record.code}版本?`,
            content: '该版本将被删除，且不可恢复。',
            payload: {
              capability_uuid: abilityId,
              capability_version_uuid: record.uuid,
            },
            submitApi: service.ability.deleteCapabilityVersions,
          }).then(() => refresh());
        };

        const trainButton = {
            text: '训练',
            onClick: () => history(`../../${createTrainingPath}?abilityId=${abilityId}&versionId=${record.uuid}`),
          },
          publishButton = {
            text: '发布',
            onClick: () => history(`../../${releaseServicePath}?abilityId=${abilityId}&versionId=${record.uuid}`),
          },
          editButton = {
            text: '编辑',
            onClick: async () => {
              // 查询该版本的详情
              const data = await ability.getVersionsDetails({
                capability_uuid: abilityId,
                capability_version_uuid: record.uuid,
              });

              const versionBase = data?.data?.capability_total?.capabilityVersionBase;

              const isModelEmpty = isEmpty(versionBase?.model_name) && isEmpty(versionBase?.model_version);
              const version = data?.data?.version;
              if (version == '1' && isModelEmpty) {
                message.info('该初始版本未挂载模型，不能对训练/评估/推理等信息进行编辑');
                return;
              }

              history(
                `../update-version/${abilityId}?ability_name=${abilityName}&version_id=${record.uuid}&version_status=update`
              );
            },
          },
          copyButton = {
            text: '拷贝新建',
            onClick: () =>
              history(
                `../copy-version/${abilityId}?ability_name=${abilityName}&version_id=${record.uuid}&version_status=copy`
              ),
          },
          downloadButton = {
            text: '下载',
            onClick: download,
          },
          deleteButton = {
            text: '删除',
            type: 'danger' as any,
            onClick: deleteVersion,
          };

        return (
          <Space size={0}>
            <TableButtons data={[editButton, copyButton, { text: '' }]} />
            <Dropdown
              arrow
              menu={{
                items: [
                  {
                    key: 'train',
                    label: (
                      <a
                        style={{ color: '#1668dc' }}
                        onClick={() =>
                          history(`../${createTrainingPath}?abilityId=${abilityId}&versionId=${record.uuid}`)
                        }>
                        训练
                      </a>
                    ),
                  },
                  {
                    key: 'publish',
                    label: (
                      <a
                        style={{ color: '#1668dc' }}
                        onClick={() =>
                          history(`../${releaseServicePath}?abilityId=${abilityId}&versionId=${record.uuid}`)
                        }>
                        发布
                      </a>
                    ),
                  },
                  {
                    key: 'download',
                    label: (
                      <a style={{ color: '#1668dc' }} onClick={download}>
                        下载
                      </a>
                    ),
                  },
                  {
                    key: 'delete',
                    label: (
                      <a style={{ color: '#dc4446' }} onClick={deleteVersion}>
                        删除
                      </a>
                    ),
                  },
                ],
              }}>
              <a style={{ color: '#1668dc' }}>更多</a>
            </Dropdown>
          </Space>
        );
      },
    },
  ];
};

export const getVersionDetailColumns = ({ refresh, history }): ColumnsType<VersionDetail> => {
  return [
    {
      title: '数据集名称',
      dataIndex: 'name',
      render: (_, record) => (
        <Link to={`../${projectVersionDetailPath}${record.id}/${record.version_id}`}>{record.name}</Link>
      ),
    },
    {
      title: '数据集ID',
      dataIndex: 'id',
    },
    {
      title: '版本',
      dataIndex: 'version',
    },
    {
      title: '版本ID',
      dataIndex: 'version_id',
    },
  ];
};

export const getModelFileColumns = ({ refresh, projectId, capName, dataSource }): ColumnsType<ModelVersionList> => {
  return [
    { title: '版本', dataIndex: 'version' },
    { title: '模型ID', dataIndex: 'model_id' },
    {
      title: '模型tag',
      width: 192,
      dataIndex: 'yl_tag',
      render: (yl_tag, record) => {
        const TagEdit = () => {
          const [isEdit, setIsEdit] = useState(false);
          const [editValue, setEditValue] = useState(yl_tag);
          const [currentTag, setCurrentTag] = useState(yl_tag);
          const saveTag = async () => {
            try {
              if (!/^[a-z0-9]+(_[a-z0-9]+)*$/.test(editValue)) {
                message.warning('标签格式错误，只能输入小写字母、数字、下划线，且不能以下划线开头和结尾');
                return;
              }

              await service.ability
                .updateModalYlTag({
                  name: record.name,
                  yl_tag: editValue,
                  version: record.version,
                })
                .then(res => {
                  if (res.data && res.status === 200) {
                    refresh();
                    message.success('保存成功');
                    setIsEdit(false);
                    setCurrentTag(editValue);
                  } else {
                    message.error('保存失败');
                  }
                });
            } catch (error) {}
          };

          return isEdit ? (
            <div className={cx['tag-edit-wrapper']}>
              <Input
                maxLength={64}
                value={editValue}
                className={cx['tag-edit-input']}
                onChange={e => setEditValue(e.target.value)}
              />
              <div className={cx['tag-edit-btns']}>
                <div className={`${cx['tag-edit-btn']} ${cx['btn-save']}`} onClick={saveTag}>
                  保存
                </div>
                <div
                  className={cx['tag-edit-btn']}
                  onClick={() => {
                    setIsEdit(false);
                    setEditValue(currentTag);
                  }}>
                  取消
                </div>
              </div>
            </div>
          ) : (
            <div className={cx['tag-edit-wrapper']}>
              <Tooltip title={currentTag}>
                <div className={cx['tag-text']}>{currentTag}</div>
              </Tooltip>
              <EditOutlined className={cx['tag-edit-icon']} onClick={() => setIsEdit(true)} />
            </div>
          );
        };

        return <TagEdit />;
      },
    },
    {
      title: '评估效果',
      dataIndex: 'evaluate_metrics',
      // width: 150,
      render: evaluate_metrics => {
        const attrsList = Object.entries(evaluate_metrics || {});
        console.log(attrsList, 'attrsList');
        return attrsList.length > 0 ? (
          <Tooltip
            title={
              <div>
                {attrsList.map(([key, value]) => {
                  return <div key={key}>{`${key}：${value}`}</div>;
                })}
              </div>
            }>
            <div className={cx['model-attrs-wrapper']}>
              {attrsList.slice(0, 2).map(([key, value]) => {
                return <div key={key}>{`${key}：${value}`}</div>;
              })}
            </div>
          </Tooltip>
        ) : (
          '-'
        );
      },
    },
    {
      title: '模型大小',
      dataIndex: 'size',
      render: size => {
        return (
          <>
            {size !== 0 && !size
              ? '-'
              : size >= 1024
              ? Math.round(size / 1024).toLocaleString() + 'MB'
              : Math.round(size).toLocaleString() + 'KB'}
          </>
        );
      },
    },
    {
      title: '上架状态',
      dataIndex: 'published',
      render: (published, record) => {
        return published ? (
          <>
            <div>
              <Badge color={modelPublishStatusMap.published.color} text={modelPublishStatusMap.published.label} />
            </div>
            <div>{record.published_at ? dayjs(record.published_at).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
          </>
        ) : (
          <div>
            <Badge color={modelPublishStatusMap.concealed.color} text={modelPublishStatusMap.concealed.label} />
          </div>
        );
      },
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      ellipsis: {
        showTitle: false,
      },
      render: created_at => {
        return created_at ? (
          <Tooltip placement="topLeft" title={dayjs(created_at).format('YYYY-MM-DD HH:mm:ss')}>
            {dayjs(created_at).format('YYYY-MM-DD HH:mm:ss')}
          </Tooltip>
        ) : (
          '-'
        );
      },
    },
    {
      title: '操作',
      fixed: 'right',
      width: 160,
      render: record => (
        <TableButtons
          data={[
            {
              text: record.published ? '下架' : '上架',
              onClick: () => {
                if (record.published) {
                  service.ability
                    .concealModel({ name: record.name, version: record.version, project_id: projectId })
                    .then(res => {
                      if (res.status === 200 && res.data) {
                        message.success('模型已下架');
                        refresh();
                      }
                    });
                } else {
                  service.ability
                    .publishModel({
                      name: record.name,
                      version: record.version,
                      project_id: projectId,
                      cap_name: capName,
                    })
                    .then(res => {
                      if (res.status === 200 && res.data) {
                        message.success('模型已上架');
                        refresh();
                      }
                    });
                }
              },
            },
            {
              text: '下载',
              onClick: async () => {
                message.info('请求已发起，请耐心等待');
                const data = await service.ability.getModalDownloadUrl({
                  name: record.name,
                  version: record.version,
                  project_id: projectId,
                });

                if (data.status === 200) {
                  Promise.all(
                    data.data.map((downloadUrl, index) => {
                      return fetch(downloadUrl).then(async res => {
                        const downloadInfo = downloadUrl.split('/');
                        const download = downloadInfo[downloadInfo.length - 1].split('?')[0];
                        if (res.status !== 200) {
                          return { res: false, message: `${download}获取下载数据失败` };
                        }
                        const resBlob = await res.blob();
                        const url = URL.createObjectURL(resBlob);
                        const a = document.createElement('a');
                        a.download = download;
                        a.href = url;
                        a.click();
                        URL.revokeObjectURL(url);
                        return { res: true, message: `${download}获取下载数据成功` };
                      });
                    })
                  ).then(ress => {
                    ress.forEach(res => {
                      if (!res.res) {
                        message.error(res.message);
                      }
                    });
                  });
                } else {
                  message.error('获取下载数据失败');
                }
              },
            },
            {
              text: '删除',
              type: 'danger',
              onClick: () => {
                requestConfirm({
                  title: `确定删除模型${record.name} 的${record.version}版本?`,
                  content: '该版本将被删除，且不可恢复。',
                  payload: {
                    name: record.name,
                    version: record.version,
                    project_id: projectId,
                  },
                  submitApi: service.ability.deleteModelByName,
                }).then(() => refresh());
              },
            },
          ]}
        />
      ),
    },
  ];
};

export const versionDetailInfoColumns: ItemOptions<VersionDetailInfo>[] = [
  {
    label: '能力名称',
    dataIndex: 'name',
    ellipsis: true,
    render: data => data?.name || '-',
  },
  {
    label: '版本',
    dataIndex: 'version',
    render: data => data?.version || '-',
  },
  {
    label: '基础能力',
    dataIndex: 'base_capability.id',
    isShow: data => {
      return data?.base_capability?.name;
    },
    render(data, item) {
      return (
        <Link to={`../../${abilityPublicDetailPath}${data?.base_capability?.id}`}>{data?.base_capability?.name}</Link>
      );
    },
  },
  {
    label: '版本ID',
    dataIndex: 'id',
    render: data => data?.id || '-',
  },
  {
    label: '创建者',
    dataIndex: 'create_by',
    render: data => data?.create_by || '-',
  },
  {
    label: '版本描述',
    dataIndex: 'desc',
    ellipsis: true,
    render: data =>
      data?.desc ? (
        <Tooltip placement="topLeft" title={data?.desc}>
          {data?.desc}
        </Tooltip>
      ) : (
        '-'
      ),
  },
  {
    label: '开始训练时间',
    dataIndex: 'train.started_at',
    render(data, item) {
      return data?.train?.started_at || '-';
    },
  },
  {
    label: '结束训练时间',
    dataIndex: 'train.ended_at',
    render(data, item) {
      return data?.train?.ended_at || '-';
    },
  },
  {
    label: '已训练时长',
    dataIndex: 'train.duration',
    render: data => formatDuration(data?.train?.duration),
  },
  {
    label: '模型名称',
    dataIndex: 'capability_total.capabilityVersionBase.model_name',
    render: data => data?.capability_total?.capabilityVersionBase?.model_name?.[0] || '-',
  },
  {
    label: '模型版本',
    dataIndex: 'capability_total.capabilityVersionBase.model_name',
    render: data => data?.capability_total?.capabilityVersionBase?.model_version?.[0] || '-',
  },
  {
    label: '数据集格式',
    dataIndex: 'extra.dataset.dataset_type',
    render: data =>
      datasetTypeOptions.find(option => option.value === data?.extra?.dataset?.dataset_type)?.label || '-',
  },
  {
    label: 'DDL文件',
    isShow: data => {
      return data?.extra?.dataset?.dataset_type === 'webdataset';
    },
    dataIndex: 'base_dataset.file_name',
    render: data => data?.base_dataset?.file_name || '-',
  },
  {
    label: 'Input字段',
    isShow: data => {
      return data?.extra?.dataset?.dataset_type === 'webdataset';
    },
    dataIndex: 'extra.dataset.input',
    render: data => data?.base_dataset?.input || '-',
  },
  {
    label: 'Output字段',
    isShow: data => {
      return data?.extra?.dataset?.dataset_type === 'webdataset';
    },
    dataIndex: 'extra.dataset.output',
    render: data => data?.base_dataset?.output || '-',
  },
];

export const publicVersionDetailInfoColumns: ItemOptions<VersionDetailInfo>[] = [
  {
    label: '能力名称',
    dataIndex: 'name',
    ellipsis: true,
  },
  {
    label: '版本',
    dataIndex: 'version',
  },
  {
    label: '版本ID',
    dataIndex: 'id',
  },
  {
    label: '创建时间',
    dataIndex: 'created_at',
  },
  {
    label: '创建者',
    dataIndex: 'create_by',
  },
  {
    label: '版本描述',
    dataIndex: 'desc',
    render: data =>
      data?.desc ? (
        <Tooltip placement="topLeft" title={data?.desc}>
          {data?.desc}
        </Tooltip>
      ) : (
        '-'
      ),
  },
];

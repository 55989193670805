export enum ServerType {
  Main = 'MAIN',
  Personal = 'PERSONAL',
}

export enum ReleaseStatus {
  // 运行中
  Running = 'RUNNING',
  // 发布成功
  Success = 'SUCCESS',
  // 发布失败
  Failure = 'FAILURE',
  // 准备发布中
  Preparing = 'PREPARING',
  // 已取消
  Cancelled = 'CANCELLED',
}

// 服务模块的类别，边端、云端、页面
export enum ServiceModuleType {
  Edge = 'EDGE',
  Cloud = 'CLOUD',
  Page = 'PAGE',
}

export const ServiceVersionMap = {
  [ServiceModuleType.Edge]: 'edgeVersions',
  [ServiceModuleType.Cloud]: 'cloudVersions',
  [ServiceModuleType.Page]: 'pageVersions',
};

export const PublishModule = {
  [ServiceModuleType.Edge]: {
    key: 'edge',
    name: '端侧',
    showStatus: false,
    versionLabel: '最新版本',
  },
  [ServiceModuleType.Cloud]: {
    key: 'cloud',
    name: '后端服务器',
    showStatus: true,
    versionLabel: '当前版本',
  },
  [ServiceModuleType.Page]: {
    key: 'page',
    name: '前端页面',
    nameTitle: '平台开发的独立页面, 仅发布众包流程无需勾选',
    showStatus: true,
    versionLabel: '当前版本',
  },
};

export const PublishModuleWidthoutTerminal = {
  [ServiceModuleType.Cloud]: {
    key: 'cloud',
    name: '后端服务器',
    showStatus: true,
    versionLabel: '当前版本',
  },
  [ServiceModuleType.Page]: {
    key: 'page',
    name: '前端页面',
    nameTitle: '平台开发的独立页面, 仅发布众包流程无需勾选',
    showStatus: true,
    versionLabel: '当前版本',
  },
};

export const getPublishModules = (isTerminal?: boolean) => {
  const modules = isTerminal ? PublishModule : PublishModuleWidthoutTerminal;
  return modules;
};

import React from 'react';
import { Spin } from '@fuxi/eevee-ui';
import bindClass from 'classnames/bind';

import pStyles from './index.module.css';

const styles = bindClass.bind(pStyles);

interface LoadingProps {
  loading: boolean;
  size?: 'small' | 'default' | 'large';
  children?: React.ReactNode;
}

const Loading = (props: LoadingProps) => {
  const { loading, children, size = 'default' } = props;

  return (
    <>
      {loading ? (
        <div className={styles('loading-container')}>
          <Spin size={size} />
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default Loading;

import Axios, { WebInstance } from './common';
import { ShanquanInstance } from './common';

const projectManage = {
  // 创建项目
  createProject<T = any>(data) {
    return Axios.post<T>(`/api/v1/projects`, data);
  },
  // 当前用户可见项目列表
  projects<T = any>(params) {
    return Axios.get<T>(`/api/v1/projects`, { params });
  },
  projectType<T = any>(projectId) {
    return WebInstance.get<T>(`/auth/${projectId}/type`);
  },
  // 单一项目统计信息（能力数、服务数、数据集数、今日消费）
  projectStatisticalInfo<T = any>(projectId) {
    return Axios.get<T>(`/api/v1/projects/${projectId}/stats`);
  },
  // 项目基本信息
  projectInfo<T = any>(projectId) {
    return Axios.get<T>(`/api/v1/projects/${projectId}`);
  },
  // 项目活动记录
  projectOperations<T = any>(params) {
    return Axios.get<T>(`/api/v1/projects/${params.projectId}/operations`, { params });
  },
  // 修改项目成员
  projectUsersEdit<T = any>(data) {
    return Axios.put<T>(`/api/v1/projects/${data.projectId}/users`, data);
  },
  // 查询项目成员列表
  projectUsers<T = any>(params) {
    return Axios.get<T>(`/api/v1/projects/${params.projectId}/users`, { params });
  },
  // 移除项目成员
  projectUsersDelete<T = any>(params) {
    return Axios.delete<T>(`/api/v1/projects/${params.projectId}/users/${params.userId}`);
  },
  // 查询项目服务调用详情
  projectCalls<T = any>(params) {
    return ShanquanInstance.get<T>(`/projects/${params.project_id}/calls`, { params });
  },
  // 消费折线
  projectConsumption<T = any>(params) {
    return Axios.get<T>(`/api/v1/projects/${params.projectId}/consumption`, { params });
  },
  // 消费账单列表
  projectBills<T = any>(params) {
    return Axios.get<T>(`/api/v1/projects/${params.projectId}/bills`, { params });
  },
  // 账单明细
  billsDetails<T = any>(params) {
    return Axios.get<T>(`/api/v1/projects/${params.projectId}/bills/${params.billId}`, { params });
  },
  // 费用信息（同比、排名等）
  consumptionStats<T = any>(params) {
    return Axios.get<T>(`/api/v1/projects/${params.projectId}/consumption/stats`, { params });
  },
};

export default projectManage;

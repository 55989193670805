import React, { useEffect, useState } from 'react';
import { Input } from '@fuxi/eevee-ui';
import { SearchOutlined } from '@ant-design/icons';

import cx from './index.module.less';

interface Prop {
  // 依赖变更时，清空搜索框
  deps?: any[];
  placeholder?: string;
  width?: number;
  onSearch: (keyword: string) => void;
  onKeywordChange?: (keyword: string) => void;
}

export const MlSearchInput = (props: Prop) => {
  const [keyword, setKeyword] = useState<string>('');
  const { onSearch, placeholder, width } = props;

  const { onKeywordChange } = props;

  const onChange = value => {
    setKeyword(value);
    onKeywordChange?.(value);
  };

  const onSearchChange = () => {
    onSearch(keyword);
  };

  useEffect(() => {
    setKeyword('');
  }, props.deps ?? []);

  return (
    <Input
      style={{ width }}
      value={keyword}
      onBlur={onSearchChange}
      onPressEnter={e => e.currentTarget.blur()}
      prefix={<SearchOutlined />}
      className={cx('search-input')}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}></Input>
  );
};

import { useEffect, useRef, useState } from 'react';
import { Form, Input, InputRef, Modal, Spin, message } from '@fuxi/eevee-ui';
import { Image, ImageSource, VirtualMachineImage } from '@/store/image';
import { virtualTerminalApi } from '@/service/virtualTerminal';
import { useAppSelector } from '@/hooks';
import { validateImageName, validateImageTag } from '@/utils/password';

import cx from './index.module.less';

interface Prop {
  existedImages: Image[];
  children: React.ReactNode;
  newImageCallback: ({ imageName, imageTag }: { imageName: string; imageTag: string }) => void;
}

export const ImageSaveModal: React.FC<Prop> = props => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const projectId = useAppSelector(state => state.project.currentProject.id);
  const [virtualImages, setVirtualImages] = useState<VirtualMachineImage[]>([]);
  const [loading, setLoading] = useState(false);
  const tagInputRef = useRef<InputRef>(null);

  const getDisplayedImages = () => {
    const displayVirtualImages: Image[] = virtualImages.map(item => {
      return {
        name: item.displayName,
        version: item.imageTag,
        uploadTime: item.updatedAt,
        size: '-',
        stubUrl: '-',
        status: item.currentStatus,
        msg: item.currentStatus,
        source: item?.public ? ImageSource.Public : ImageSource.UserUpload,
        id: item.id,
      };
    });
    return [...props.existedImages, ...displayVirtualImages];
  };

  const fetchVirtualImages = () => {
    setLoading(true);
    virtualTerminalApi
      .getImages(projectId + '')
      .then(res => {
        setVirtualImages(res.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    isModalOpen && fetchVirtualImages();
  }, [isModalOpen]);

  const showModal = () => {
    form.resetFields();
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const isExisted = getDisplayedImages().some(item => item.name === values.name && item.version === values.tag);
      if (isExisted) {
        message.error('当前版本镜像已存在');
        return;
      }
      Modal.confirm({
        title: '确认保存镜像',
        content: '保存过程中，虚拟机会关闭，请确认',
        onOk: async () => {
          props.newImageCallback({
            imageName: values.name,
            imageTag: values.tag,
          });
          setIsModalOpen(false);
        },
        className: 'confirm-modal',
      });
    } catch (e) {}
  };

  const repeatCheck = async (rule?: any, value?: string) => {
    const imageName = form.getFieldValue('name');
    const imageTag = value || form.getFieldValue('tag');
    const isExisted = getDisplayedImages().some(item => item.name === `${imageName}:${imageTag}`);
    if (isExisted) {
      throw new Error('当前版本镜像已存在');
    }
  };

  return (
    <div>
      <div onClick={showModal}>{props.children}</div>
      <Modal
        destroyOnClose
        className={cx('connection-modal')}
        title="保存镜像"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <Spin spinning={loading}>
          <Form form={form}>
            <Form.Item
              rules={[
                {
                  validator: validateImageName,
                },
              ]}
              required
              name="name"
              label="镜像名称">
              <Input
                onInput={() => {
                  form.validateFields(['tag']);
                }}
                maxLength={30}
                minLength={2}
                showCount
                required
                autoComplete="off"
                placeholder="请输入镜像名称"
              />
            </Form.Item>
            <Form.Item
              required
              name="tag"
              label="镜像版本"
              rules={[
                {
                  required: true,
                  message: '必填',
                },
                {
                  validator: repeatCheck,
                },
                {
                  validator: validateImageTag,
                },
              ]}>
              <Input showCount maxLength={30} ref={tagInputRef} autoComplete="off" placeholder="请输入镜像版本" />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

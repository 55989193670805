/* eslint-disable react-func/max-lines-per-function */
import { useEffect, useState } from 'react';
import { usePagination } from '@fuxi/eevee-hooks';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Flex, Button, Tooltip, Badge, TableButtons } from '@fuxi/eevee-ui';

// import service from '../../service';
// import { getSorterTableData } from '../../utils/util';
// import { getTrainingTaskColumns, createTrainingPath } from './const';
import { ColumnsType } from 'antd/es/table';
import { TaskStatusType } from '@ai-training/types/trainingTask';
import { taskStatusMap } from '@ai-training/constants/trainingTask';
import { requestConfirm } from '@ai-training/utils';
import { Table } from 'antd';
import { getMlTrainingTaskByPage, mlTrainingTask } from '@/service/ml';
import { useAppSelector } from '@/hooks';

import { MlTrainingTaskDetail } from '@/@types/ml';
import { MlSearchInput } from '../components/MlSearchInput';
import { getTrainingFormattedTimeGap } from '../utils/getFormattedTimeGap';
import cx from './index.module.less';

export const MlTrainingTask: React.FC = () => {
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId)!;
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState<string>();
  const { taskStop, taskDelete, taskRestart } = mlTrainingTask;
  const { data, pagination, run, loading } = usePagination(getMlTrainingTaskByPage, {
    defaultPageSize: 10,
    manual: true,
  });

  useEffect(() => {
    setKeyword(undefined);
    if (!!aiProjectId) {
      run({
        project_id: aiProjectId,
        current: 1,
        pageSize: pagination.pageSize,
        keyword: undefined,
      });
    }
  }, [aiProjectId]);

  const search = () => {
    run({
      project_id: aiProjectId,
      current: 1,
      pageSize: pagination.pageSize,
      keyword,
    });
  };

  const refresh = () => {
    run({
      project_id: aiProjectId,
      current: pagination.current,
      pageSize: pagination.pageSize,
      keyword,
    });
  };

  const getFilterTableButtons = (record: MlTrainingTaskDetail) => {
    const buttons = [
      {
        text: '停止',
        onClick: () => {
          requestConfirm({
            title: `确定暂停 ${record.train_id}?`,
            payload: {
              id: record.train_id,
            },
            submitApi: taskStop,
          }).then(() => {
            refresh();
          });
        },
        show: true,
        disabled: !(record.status === TaskStatusType.RUNNING),
      },
      {
        text: '删除',
        type: 'danger' as any,
        onClick: () =>
          requestConfirm({
            title: `确定删除训练${record.task_id}?`,
            content:
              record.status === TaskStatusType.RUNNING
                ? '删除后不可恢复，且对应的能力版本也将被删除。 '
                : '删除后不可恢复，已完成的训练任务对于的能力版本不受影响',
            payload: {
              id: record.task_id,
            },
            submitApi: taskDelete,
          }).then(() => {
            refresh();
          }),
      },
      {
        text: '重启',
        show: true,
        disabled: !(record.status === TaskStatusType.STOP_FINISH),
        onClick: () =>
          requestConfirm({
            title: `确定重启训练${record.task_id}?`,
            payload: {
              id: record.task_id,
            },
            submitApi: taskRestart,
          }).then(() => {
            refresh();
          }),
      },
    ];
    return buttons.filter(item => !item.disabled);
  };

  const trainingTaskColumns: ColumnsType<MlTrainingTaskDetail> = [
    {
      title: '任务ID',
      dataIndex: 'id',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <Tooltip placement="topLeft" title={record.task_id}>
          <Link className={cx('id-link')} type="link" to={`${record.task_id}`}>
            {record.task_id}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: '能力名称',
      dataIndex: 'capability_name',
      ellipsis: {
        showTitle: false,
      },
      render: value => (
        !!value ? <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip> : '-'
      ),
    },
    {
      title: '版本',
      dataIndex: 'capability_version',
      render: (_, record) => record.capability_version || '-',
    },
    {
      title: '训练状态',
      dataIndex: 'status',
      render(value) {
        const status = taskStatusMap[value];
        return <Badge color={status?.color} text={status?.label} />;
      },
    },
    {
      title: '已训练时长',
      dataIndex: 'duration',
      render: (_, record) =>
        getTrainingFormattedTimeGap({
          duration: record.duration,
          status: record.status,
          start_at: record.start_at,
        }),
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      render: (_, record) => record.created_at || '-',
    },
    {
      title: '操作',
      fixed: 'right',
      render: (_, record) => (
        <>
          <TableButtons data={getFilterTableButtons(record)} />
        </>
      ),
    },
  ];

  const createTrainingTask = () => {
    navigate('create');
  };

  const onKeywordChange = e => {
    setKeyword(e);
  };

  return (
    <div className={cx['main-page-wrapper']}>
      <div className={cx['main-page-title']}>训练任务</div>
      <Flex justifyBetween className={cx('create-wrapper')}>
        <Flex alignCenter>
          <Button type="primary" onClick={createTrainingTask}>
            <PlusOutlined />
            创建训练
          </Button>
        </Flex>
        <div>
          <MlSearchInput
            deps={[aiProjectId]}
            onKeywordChange={onKeywordChange}
            placeholder={'请输入训练任务id'}
            onSearch={search}
          />
        </div>
      </Flex>
      <Table
        loading={loading}
        dataSource={data?.list}
        columns={trainingTaskColumns}
        rowKey="id"
        pagination={{
          onChange: pagination.onChange,
          current: pagination.current,
          total: data?.total,
          pageSize: pagination.pageSize,
          showTotal: total => `总共 ${total} 条`,
          showQuickJumper: true,
          pageSizeOptions: ['8', '10', '16', '32', '64'],
          showSizeChanger: true,
        }}
      />
    </div>
  );
};

import { gql } from '@apollo/client';

export const REVIEW_FRAGMENT = gql`
  fragment ReviewFragment on ProjectReview {
    projectId
    projectName
    description
    image
    stage
    status
    judge
    reviewDetail
    judgeName
  }
`;

export const SEASON_PROJECT_FRAGMENT = gql`
  fragment SeasonProjectFragment on SeasonProject {
    name
    description
    id
    icon
    judges {
      judge
      judgeName
    }
  }
`;

export const STAGE_PROJECT_FRAGMENT = gql`
  fragment StageProjectFragment on StageProject {
    name
    description
    id
    icon
    judges {
      judge
      judgeName
    }
    demoStageOrder
    midStageOrder
    finalStageOrder
  }
`;

export const GET_PROJECT_SEASONS = gql`
  query GetProjectSeasons {
    projectSeasons {
      id
      name
    }
  }
`;

export const GET_REVIEW_STAGES = gql`
  query GetReviewStages($seasonId: ID!) {
    reviewStages(seasonId: $seasonId) {
      id
      startTime
      endTime
      stage
      seasonId
    }
  }
`;

export const GET_REVIEW_APPRAISE = gql`
  query GetReviewAppraise($stageId: ID!) {
    reviewAppraise(stageId: $stageId) {
      judge
      judgeName
      appraises {
        ...ReviewFragment
      }
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const GET_SEASON_PROJECTS = gql`
  query GetSeasonProjects($seasonId: ID) {
    seasonProjects(seasonId: $seasonId) {
      total
      projects {
        ...SeasonProjectFragment
      }
    }
  }
  ${SEASON_PROJECT_FRAGMENT}
`;

export const GET_PROJECT_REVIEWS = gql`
  query GetReviewAppraiseById($projectId: ID!, $stageId: ID) {
    projectReviews(projectId: $projectId, stageId: $stageId) {
      projectId
      description
      projectName
      judgeName
      reviewDetail
      stage
    }
  }
`;

export const GET_STAGE_PROJECTS = gql`
  query GetStageProjects($stageId: ID) {
    stageProjects(stageId: $stageId) {
      total
      projects {
        ...StageProjectFragment
      }
    }
  }
  ${STAGE_PROJECT_FRAGMENT}
`;

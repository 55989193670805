import { forwardRef, useImperativeHandle, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Icon, message } from '@fuxi/eevee-ui';
import { acceptImageType, nullImage } from '@/pages/project/const';

import cx from './index.module.less';

interface Prop {
  iconUrl: string;
  imgHeight?: number;
  imgWidth?: number;
}

export const UploadImg = forwardRef((props: Prop, ref) => {
  const { iconUrl } = props;
  const tenMB = 10485760;

  const [iconFile, setIconFile] = useState<any>();
  const [imgChanged, setImgChanged] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState(iconUrl);
  const [previewImage, setPreviewImage] = useState('');

  useImperativeHandle(ref, () => ({
    getImgEvent: () => {
      return {
        iconFile,
        imgChanged,
      };
    },
  }));

  const removeIcon = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setPreviewImage('');
    setImgUrl('');
    setIconFile('');
    setImgChanged(true);
  };

  const showInitialImgUrl = !previewImage && imgUrl?.startsWith('http');
  const showImage = (
    <div className={cx('show-image-container')}>
      <img className={cx('show-image')} src={showInitialImgUrl ? imgUrl : previewImage} alt="" />
      <div onClick={e => removeIcon(e)} className={cx('image-icon-container')}>
        <Icon name="错误删除" color="#DE5550" size={16}></Icon>
      </div>
    </div>
  );

  const uploadButton =
    showInitialImgUrl || (previewImage !== '' && previewImage !== nullImage) ? (
      showImage
    ) : (
      <div className={cx('img-container')}>
        <div>{loading ? <LoadingOutlined /> : <PlusOutlined />}</div>
      </div>
    );

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const handleFileChange = async (e: any) => {
    const fileList = e.target.files[0];
    if (!fileList) return;

    if (fileList.size > tenMB) {
      message.error('上传图片大小不能超过10M');
      return;
    }

    if (!acceptImageType.includes(fileList.type)) {
      message.error('上传图片格式错误, 仅支持png, jpg, jpeg, bmp, svg格式');
      return;
    }

    setIsLoading(true);
    if (!fileList?.url && !fileList?.preview) {
      fileList.preview = await getBase64(fileList);
    }
    setIconFile(fileList);
    setImgChanged(true);
    setPreviewImage(fileList.url || fileList.preview);
    setIsLoading(false);
    e.target.value = '';
  };

  return (
    <div className={cx('upload-wrapper')}>
      <input
        accept={acceptImageType.join()}
        id="uploadInput"
        className={cx('upload-input')}
        type="file"
        onChange={e => handleFileChange(e)}></input>
      <label className={cx('upload-icon')} htmlFor="uploadInput">
        {uploadButton}
      </label>
      {/* <div className={cx('tip')}>建议宽高比为2:1, 图片不超过10M(png/jpg/jpeg)</div> */}
    </div>
  );
});

import { Flex } from '@fuxi/eevee-ui';
import cx from './index.module.less';

interface Prop {
  color: string;
  label: string;
}
export const StatusTag = (props: Prop) => {
  const { color, label } = props;
  return (
    <Flex alignCenter>
      <div className={cx('status-icon')} style={{ backgroundColor: color }}></div>
      <span>{label}</span>
    </Flex>
  );
};

import React from 'react';
import { Button, Card, Descriptions, Flex, Form, message, Modal, Space, Tag, Tooltip } from '@fuxi/eevee-ui';
import { CheckCircleFilled, CopyOutlined, InfoCircleFilled } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useAntModal } from '@fuxi/eevee-hooks';
import { Input } from 'antd';
import { DocumentNode, useMutation } from '@apollo/client';
import { ServiceStatus } from '@/typings/common';
import { GET_PROJECT } from '@/service/schema/project/project-queries';
import { OFFLINE_TPL, ONLINE_TPL, PUBLISH_TPL } from '@/service/schema/template/template-mutation';
import { VALIDATE_VERSION } from '@/service/schema/template/template-queries';
import client from '@/service/graphql';
import ProjectDetailContainer from './ProjectDetailContainer';
import TplEditModal from './TplEditModal';
import cx from './ProjectDetail.module.less';

const TplProjectCard: React.FC = () => {
  const [form] = Form.useForm();
  const container = ProjectDetailContainer.useContainer();
  const { currentProject, isCurrentProjectMaintainer } = container;

  // 上架模板
  const [onlineTpl, { loading: onlineLoading }] = useMutation<DocumentNode, { templateId: number }>(ONLINE_TPL, {
    refetchQueries: [GET_PROJECT],
  });

  // 下架模板
  const [offlineTpl, { loading: offlineLoading }] = useMutation<DocumentNode, { templateId: number }>(OFFLINE_TPL, {
    refetchQueries: [GET_PROJECT],
  });

  // 发布模板
  const [publishTpl, { loading: publishLoading }] = useMutation<
    DocumentNode,
    { input: { templateId: number; version: string; description: string } }
  >(PUBLISH_TPL, {
    refetchQueries: [GET_PROJECT],
  });

  const onlineTemplate = async () => {
    const data = await onlineTpl({ variables: { templateId: currentProject.id } });
    (data as any)?.onlineTemplate && message.success('模板上架成功！');
  };

  const handleOkCallback = async () => {
    const values = await form.validateFields();
    const data = await publishTpl({
      variables: {
        input: {
          templateId: currentProject.id,
          version: values.version,
          description: values.desc,
        },
      },
    });
    (data as any)?.publishTemplate && message.success('版本更新并发布成功！');
  };

  const { showModal, commonAntModalProps } = useAntModal({
    handleOkCallback,
  });

  const handleUnPublishedTpl = () => {
    Modal.confirm({
      title: `确定要下架 ${currentProject.name} 吗？`,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const data = await offlineTpl({ variables: { templateId: currentProject.id } });
        (data as any).offlineTemplate && message.success('模板下架成功！');
      },
      className: 'confirm-modal',
    });
  };

  const tplConfig: Partial<Record<ServiceStatus, Record<'title' | 'btns', any>>> = {
    [ServiceStatus.Online]: {
      title: (
        <Space>
          <CheckCircleFilled style={{ color: '#36BD1F' }} /> 模板已上架
        </Space>
      ),
      btns: (
        <Space>
          <Button
            loading={offlineLoading}
            disabled={!isCurrentProjectMaintainer}
            onClick={handleUnPublishedTpl}
            danger
            type="primary">
            下架
          </Button>
          <TplEditModal isEdit={true}>
            <Button disabled={!isCurrentProjectMaintainer} type="default">
              编辑
            </Button>
          </TplEditModal>
          <Button disabled={!isCurrentProjectMaintainer} onClick={showModal} type="default">
            更新版本
          </Button>
        </Space>
      ),
    },
    [ServiceStatus.Offline]: {
      title: (
        <Space>
          <InfoCircleFilled style={{ color: '#DE5550' }} /> 模板已下架
        </Space>
      ),
      btns: (
        <Space>
          <Button
            type="primary"
            disabled={!isCurrentProjectMaintainer}
            loading={onlineLoading}
            onClick={currentProject.versionInfo?.version ? onlineTemplate : showModal}>
            上架
          </Button>
          <TplEditModal isEdit={true}>
            <Button disabled={!isCurrentProjectMaintainer} type="default">
              编辑
            </Button>
          </TplEditModal>
        </Space>
      ),
    },
    [ServiceStatus.Pending]: {
      title: (
        <Space>
          <InfoCircleFilled style={{ color: '#FFFFFF' }} /> 模板未生效
        </Space>
      ),
      btns: (
        <Space>
          {currentProject.versionInfo?.version ? (
            <Button
              disabled={!isCurrentProjectMaintainer}
              type="primary"
              loading={onlineLoading}
              onClick={onlineTemplate}>
              上架
            </Button>
          ) : (
            <Button disabled={!isCurrentProjectMaintainer} type="primary" onClick={showModal}>
              发布
            </Button>
          )}

          <TplEditModal tempTags={currentProject.tags || []} isEdit={true}>
            <Button disabled={!isCurrentProjectMaintainer} type="default">
              编辑
            </Button>
          </TplEditModal>
        </Space>
      ),
    },
  };

  const tplContent = tplConfig[currentProject.status as unknown as ServiceStatus];

  if (!tplContent) return null;

  return (
    <Card style={{ height: '100%' }} className={cx('tpl-wrapper')}>
      <Flex style={{ height: '100%' }} flexDirection="column" justifyBetween>
        <Flex.Item>
          <div className={cx('tpl-title')}>
            {tplContent.title}
            <Tooltip
              getPopupContainer={t => t.parentNode as HTMLElement}
              title={currentProject.versionInfo?.updateTime}>
              {(currentProject.status as any) === ServiceStatus.Online && !!currentProject.versionInfo?.version && (
                <div className={cx('tpl-version')}>{`线上版本：${currentProject.versionInfo.version}`}</div>
              )}
            </Tooltip>
          </div>
          <Descriptions className={cx('description')} column={1} labelStyle={{ display: 'inline-block' }}>
            <Descriptions.Item label="行业">{currentProject?.industry?.industryName}</Descriptions.Item>
            <Descriptions.Item label="标签">
              <div>
                {currentProject.tags?.map(item => (
                  <Tag className={cx('tag-item')} style={{ marginBottom: 8 }} color={'processing'} key={item.tagId}>
                    {item.tagName}
                  </Tag>
                ))}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="示例客户端代码库地址">
              <Flex alignBaseline gap={8}>
                <Tooltip
                  placement="topLeft"
                  overlayClassName={cx('des-tooltip')}
                  title={currentProject?.clientSampleGitUrl || '无'}>
                  <div className={cx('info-in-desc')}>{currentProject?.clientSampleGitUrl || '无'}</div>
                </Tooltip>

                <CopyToClipboard
                  text={currentProject?.clientSampleGitUrl || ''}
                  onCopy={() => message.success('复制成功！')}>
                  <Space>
                    {currentProject?.clientSampleGitUrl && (
                      <CopyOutlined style={{ cursor: 'pointer' }} className="copy-icon" />
                    )}
                  </Space>
                </CopyToClipboard>
              </Flex>
            </Descriptions.Item>
            <Descriptions.Item label="咨询联系方式">
              <Tooltip placement="topLeft" overlayClassName={cx('des-tooltip')} title={currentProject?.contactInfo}>
                <div className={cx('info-in-desc')}>{currentProject?.contactInfo}</div>
              </Tooltip>
            </Descriptions.Item>
          </Descriptions>
        </Flex.Item>
        {tplContent.btns}
      </Flex>
      <Modal
        {...commonAntModalProps}
        okButtonProps={{ loading: publishLoading }}
        title={currentProject.versionInfo?.version ? '更新版本至项目中心' : '发布版本至项目中心'}>
        <Form labelCol={{ span: 4 }} form={form}>
          <Form.Item
            required
            rules={[
              {
                required: true,
                message: '请输入对外版本',
              },
              {
                validator: async (_, value) => {
                  if (!value) return Promise.resolve();
                  const data = await client.query<{ templateId: number; tag: string }, { validateTag: boolean }>(
                    VALIDATE_VERSION,
                    { templateId: currentProject.id, tag: value }
                  );
                  return data?.validateTag ? Promise.resolve() : Promise.reject('版本号已存在！');
                },
              },
            ]}
            label="版本号"
            name="version">
            <Input placeholder="请输入对外版本号，如V0.1.0" autoComplete="off"></Input>
          </Form.Item>
          <Form.Item required rules={[{ required: true, message: '请输入版本说明' }]} label="版本说明" name="desc">
            <Input.TextArea
              placeholder="请输入版本说明，500字符以内"
              autoSize={{ minRows: 4, maxRows: 7 }}
              maxLength={500}
              showCount
            />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default TplProjectCard;

import client from './graphql';
import { FORCE_TO_PUSH_SERVICE_VERSION } from './schema/version/version-mutation';
import { GET_SERVICE_VERSIONS } from './schema/version/version-queries';

export const getServiceVersions = (serviceId: string, orderDesc: boolean = true) => {
  return client.query<
    { serviceId: string; current?: number; orderDesc?: boolean },
    { serviceVersions: ServiceVersion }
  >(GET_SERVICE_VERSIONS, {
    serviceId,
    // pageSize: 0,
    // current: 0,
    orderDesc,
  });
};

export const forceToPushServiceVersion = (projectId: number, serviceId: string, tag: string, terminalsId: number[]) => {
  return client.mutate<
    { projectId: number; serviceId: string; tag: string; terminalsId: number[] },
    { forceToPushServiceVersion: { serviceId?: string; msg?: string; code?: number } }
  >(FORCE_TO_PUSH_SERVICE_VERSION, {
    projectId,
    serviceId,
    tag,
    terminalsId,
  });
};

import { Typography } from '@fuxi/eevee-ui';
import React from 'react';
export interface EnvValue {
  name?: string;
  value?: string;
  isBlur: boolean;
}

interface ResourceConfigProps {
  value?: EnvValue;
}

const EnvSetup: React.FC<ResourceConfigProps> = ({ value = {} }) => {
  return <Typography.Text children={`${value?.name}  =  ${value?.value}`}></Typography.Text>;
};

export default EnvSetup;

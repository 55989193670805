import { ChangeEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Input, message, Modal } from '@fuxi/eevee-ui';
import { useAppDispatch } from '@/hooks';
import { UPDATE_PROJECT } from '@/service/schema/project/project-mutations';
import { GET_PROJECTS } from '@/service/schema/project/project-queries';
import { setCurrentProject } from '@/store/project';

interface prop {
  resource: Resource | undefined;
  nameList: MiniNameList | undefined;
  projectId: number;
  name: string;
  description: string;
}

export const ProjectMiniInformationSetting = (prop: prop) => {
  const { resource, nameList, projectId, name, description } = prop;
  const [postEditProject, { loading: editLoading }] = useMutation(UPDATE_PROJECT, { refetchQueries: [GET_PROJECTS] });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TextArea } = Input;
  const dispatch = useAppDispatch();
  const initialNamesListDataSource = [
    {
      key: 'Designer',
      value: nameList?.Designer,
      name: '策划',
    },
    {
      key: 'Programmer',
      value: nameList?.Programmer,
      name: '程序',
    },
    {
      key: 'Art',
      value: nameList?.Art,
      name: '美术',
    },
    {
      key: 'QA',
      value: nameList?.QA,
      name: 'QA',
    },
  ];
  const initialResource = [
    { name: 'SVN地址', key: 'SVNUrl', value: resource?.SVNUrl || '' },
    { name: '云空间地址', key: 'cloudSpaceUrl', value: resource?.cloudSpaceUrl || '' },
    { name: '展示资源', key: 'assetsOnShow', value: resource?.assetsOnShow },
  ];
  const [namesListDataSource, setNamesListDataSource] = useState(initialNamesListDataSource);
  const [resourceDataSource, setResourceDataSource] = useState(initialResource);

  const showModal = () => {
    setIsModalVisible(true);
    setNamesListDataSource(initialNamesListDataSource);
    setResourceDataSource(initialResource);
  };

  const handleOk = () => {
    update();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const update = () => {
    const namesListObj = new Object() as any;
    const resourceObj = { ...resource } as any;
    namesListDataSource.forEach(item => (namesListObj[item.key] = item.value));
    resourceDataSource.forEach(item => (resourceObj[item.key] = item.value));

    postEditProject({
      variables: {
        updateProjectInput: {
          description,
          name,
          projectId,
          nameList: JSON.stringify(namesListObj),
          resource: JSON.stringify(resourceObj),
          isTerminal: false,
        },
      },
    }).then(res => {
      if (res?.data) {
        dispatch(setCurrentProject(res?.data?.updateProject));
        message.success('保存成功！');
        setIsModalVisible(false);
      } else {
        message.success('保存失败！');
      }
    });
  };

  const setNamesList = (e: ChangeEvent<HTMLTextAreaElement>, key: string) => {
    const namesList = e.target.value;
    const source = namesListDataSource.find(namesList => namesList.key === key)!;
    const newSource = { ...source, value: namesList.split('、') };
    setNamesListDataSource(namesListDataSource.map(item => (item.key === key ? newSource : item)));
  };

  const setResource = (e: ChangeEvent<HTMLTextAreaElement>, key: string) => {
    const resourceItem = e.target.value;
    const source = resourceDataSource.find(item => item.key === key)!;
    const newSource = { ...source, value: resourceItem };
    setResourceDataSource(resourceDataSource.map(item => (item.key === key ? newSource : item)));
  };

  return (
    <>
      <Button type="primary" onClick={showModal} style={{ marginLeft: 8 }}>
        项目配置
      </Button>
      <Modal
        confirmLoading={editLoading}
        title="项目信息配置"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <span>人名请以中文顿号进行分隔</span>
        {namesListDataSource.map(item => (
          <div key={item.key}>
            <span>{item.name}：</span>
            <TextArea onChange={e => setNamesList(e, item.key)} value={item?.value?.join('、')}></TextArea>
          </div>
        ))}
        {resourceDataSource.map(item => (
          <div key={item.key}>
            {typeof item.value === 'string' && (
              <div>
                <span>{item.name}：</span>
                <TextArea onChange={e => setResource(e, item.key)} value={item?.value}></TextArea>
              </div>
            )}
          </div>
        ))}
      </Modal>
    </>
  );
};

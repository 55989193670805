import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAntdTable, useRequest } from '@fuxi/eevee-hooks';
import { Card, Flex, Table, Divider, Tabs } from '@fuxi/eevee-ui';

import service from '../../service';
import { getSorterTableData } from '../../utils/util';
import Descriptions from '../../components/Descriptions';
import EmptyCard from '../../components/EmptyCard';
import { publicDetailColumns, getPublicVersionColumns } from './const';
import cx from './index.module.less';

const PublicDetail: React.FC = () => {
  const urlParams = useParams();
  const history = useNavigate();

  const { data: detail } = useRequest(() =>
    service.dataSet.getDatasetsDetails({
      dataset_id: urlParams.dataId || '',
      is_common: true,
    })
  );
  const { tableProps, refresh } = useAntdTable(
    getSorterTableData(service.dataSet.getVersions, { extraParams: { dataset_id: urlParams.dataId } })
  );

  return (
    <Flex column className={cx['detail-page-wrapper']}>
      <Card title="基本信息" className={cx['detail-head']}>
        <Descriptions column={3} items={publicDetailColumns} data={detail?.data || {}} />
      </Card>
      <Divider className={cx['detail-divider']} />
      <div className={cx['detail-body-tab']}>
        <Tabs
          tabBarStyle={{ marginBottom: 24 }}
          items={[
            // {
            //   key: 'DataSetDesc',
            //   label: '数据集说明',
            //   children: (
            //     <>
            //       <EmptyCard />
            //     </>
            //   ),
            // },
            {
              key: 'DataSetVersion',
              label: '数据集版本',
              children: (
                <Table
                  columns={getPublicVersionColumns({
                    refresh,
                    history,
                    dataId: urlParams.dataId,
                    datasetName: detail?.data?.name,
                  })}
                  {...tableProps}
                  rowKey="id"
                  pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
                />
              ),
            },
          ]}
        />
      </div>
    </Flex>
  );
};

export default PublicDetail;

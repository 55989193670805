/* eslint-disable react-func/max-lines-per-function */
import { stageOrderKey } from '@/constants/miniReviews';
import { ProjectReviewAppraise, Stage } from '@/store/miniReview';

export type ProjectWithAllJudge = {
  projectId: string;
  projectName: string;
  description: string;
  image: string;
  judges: {
    judge: string;
    judgeName: string;
  }[];
  demoStageOrder?: number;
  midStageOrder?: number;
  finalStageOrder?: number;
};

export type ProjectSummary = {
  stage?: Stage;
  projectId: string;
  projectName: string;
  description: string;
  image: string;
  judgeTotal: number;
  unCommitJudgeArray: string[];
  commitJudgeArray: string[];
  judgeArray: string[];

  experienceAverage: number;
  innovationAverage: number;
  artisticExpressionAverage: number;
  effectAverage: number;
  difficultyAverage: number;
  prospectAverage: number;
  aiAverage: number;
  scoreAverage: number;

  startTimeArray: string[];
  endTimeArray: string[];
  experienceArray: number[];
  innovationArray: number[];
  artisticExpressionArray: number[];
  effectArray: number[];
  difficultyArray: number[];
  prospectArray: number[];
  aiArray: number[];
  scoreArray: number[];
  recommendArray: boolean[];

  recommendTotal: number;
  unRecommendTotal: number;
  commitTotal: number;
  unCommitTotal: number;
  issueArray: string[];
  [props: string]: any;
};

export type AppraiseInfo = { judge: string; projectId: string };

export type UploadReviewAppraiseInput = {
  stageId: string;
  appraiseData: AppraiseInfo[];
};

export const projectSummaryArrayKeys: [string, string][] = [
  ['judgeArray', 'judgeName'],
  ['experienceArray', 'experience'],
  ['innovationArray', 'innovation'],
  ['artisticExpressionArray', 'artisticExpression'],
  ['effectArray', 'effect'],
  ['difficultyArray', 'difficulty'],
  ['prospectArray', 'prospect'],
  ['aiArray', 'ai'],
  ['scoreArray', 'score'],
  ['issueArray', 'issue'],
  ['startTimeArray', 'startTime'],
  ['endTimeArray', 'endTime'],
  ['recommendArray', 'isRecommend'],
];

export const projectSummaryAverageKeys: [string, string, string][] = [
  ['experienceArray', 'experienceAverage', '核心体验'],
  ['innovationArray', 'innovationAverage', '创新性'],
  ['artisticExpressionArray', 'artisticExpressionAverage', '艺术表现力'],
  ['effectArray', 'effectAverage', '技术实现效果'],
  ['difficultyArray', 'difficultyAverage', '上手难易度'],
  ['prospectArray', 'prospectAverage', '商业化前景'],
  ['aiArray', 'aiAverage', 'AI工具使用及效果'],
  ['scoreArray', 'scoreAverage', '整体评分'],
];

export const initProjectSummary = (projectWithAllJudge: ProjectWithAllJudge & { stage?: Stage; stageKey: string }) => {
  const projectSummary: ProjectSummary = {
    stage: projectWithAllJudge.stage,
    projectId: projectWithAllJudge.projectId,
    projectName: projectWithAllJudge.projectName,
    description: projectWithAllJudge.description,
    image: projectWithAllJudge.image,
    judgeTotal: projectWithAllJudge.judges.length,
    order: projectWithAllJudge[projectWithAllJudge.stageKey],
    unCommitJudgeArray: projectWithAllJudge.judges.map(item => item.judgeName),
    commitJudgeArray: [],
    judgeArray: [],
    startTimeArray: [],
    endTimeArray: [],

    experienceAverage: 0,
    innovationAverage: 0,
    artisticExpressionAverage: 0,
    effectAverage: 0,
    difficultyAverage: 0,
    prospectAverage: 0,
    aiAverage: 0,
    scoreAverage: 0,

    experienceArray: [],
    innovationArray: [],
    artisticExpressionArray: [],
    effectArray: [],
    difficultyArray: [],
    prospectArray: [],
    aiArray: [],
    scoreArray: [],
    recommendArray: [],

    recommendTotal: 0,
    unRecommendTotal: 0,
    commitTotal: 0,
    unCommitTotal: 0,
    issueArray: [],
  };
  return projectSummary;
};

export const getAverage = (scoreArray: number[], commit: number) => {
  // 当评委数量小于等于4时 直接取平均值
  if (commit === 0) return 0;
  if (commit <= 4) {
    return scoreArray.reduce((prev, curr) => prev + curr, 0) / commit;
  } else {
    const sum = scoreArray.reduce((prev, curr) => prev + curr) - Math.max(...scoreArray) - Math.min(...scoreArray);
    return sum / (commit - 2);
  }
};

export const handle = (
  projectReviewAppraises: ProjectReviewAppraise[],
  projectWithAllJudges: ProjectWithAllJudge[],
  stage?: Stage
) => {
  const projectSummarys: ProjectSummary[] = [];
  projectWithAllJudges.forEach(projectWithAllJudge => {
    const stageKey = stageOrderKey[stage!];
    const projectSummary = initProjectSummary({ ...projectWithAllJudge, stage, stageKey });
    projectReviewAppraises.forEach(projectReviewAppraise => {
      projectReviewAppraise.appraises.forEach(projectReview => {
        if (projectSummary.projectId === projectReview.projectId) {
          projectSummaryArrayKeys.forEach(key => {
            projectSummary[key[0]].push(projectReview[key[1]]);
          });
          if (!projectReview.isRecommend) projectSummary.unRecommendTotal++;
          else projectSummary.recommendTotal++;
          projectSummary.commitTotal++;
          projectSummary.unCommitJudgeArray = projectSummary.unCommitJudgeArray.filter(
            item => item !== projectReview.judgeName
          );
        }
      });
    });
    projectSummaryAverageKeys.forEach(key => {
      projectSummary[key[1]] = getAverage(projectSummary[key[0]], projectSummary.commitTotal);
    });
    projectSummary.orderAverage = projectSummary.order;
    projectSummary.unCommitTotal = projectSummary.judgeTotal - projectSummary.commitTotal;
    projectSummarys.push(projectSummary);
  });
  projectSummarys.sort((a, b) => b.orderAverage - a.orderAverage);
  return projectSummarys;
};

export const getUploadReviewAppraiseInput = (stageId: string, JudgeWithProjects: ProjectReviewAppraise[]) => {
  const uploadReviewAppraiseInput: UploadReviewAppraiseInput = { stageId: '', appraiseData: [] };
  uploadReviewAppraiseInput.stageId = stageId;
  JudgeWithProjects.forEach(current => {
    current.appraises.forEach(item => {
      const appraiseInfo: AppraiseInfo = { judge: item.judge, projectId: item.projectId };
      uploadReviewAppraiseInput.appraiseData.push(appraiseInfo);
    });
  });
  return uploadReviewAppraiseInput;
};

export const getAppraiseSum = (projectReviewAppraises: ProjectReviewAppraise[]) =>
  projectReviewAppraises.reduce((prev, next) => prev + next.appraises.length, 0);

export const reviewSortByValue: { key: string; value: string }[] = [
  { key: 'score', value: '整体' },
  { key: 'experience', value: '核心体验' },
  { key: 'innovation', value: '创新性' },
  { key: 'artisticExpression', value: '艺术表现力' },
  { key: 'effect', value: '技术实现效果' },
  { key: 'difficulty', value: '上手难易度' },
  { key: 'prospect', value: '商业化前景' },
  { key: 'ai', value: 'AI工具使用及效果' },
];
export const questionsLabelMap = [
  {
    label: '1.核心体验',
    name: 'experience',
  },
  {
    label: '2.创新性',
    name: 'innovation',
  },
  {
    label: '3.艺术表现力',
    name: 'artisticExpression',
  },
  {
    label: '4.技术实现效果',
    name: 'effect',
  },
  {
    label: '5.上手难易度',
    name: 'difficulty',
  },
  {
    label: '6.商业化前景',
    name: 'prospect',
  },
  {
    label: '7.AI工具使用及效果',
    name: 'ai',
  },
  {
    label: '8.整体评分',
    name: 'score',
  },
];

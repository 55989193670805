import { useState } from 'react';
import { Form, Input, message, Modal, Select } from '@fuxi/eevee-ui';
import { pythonVariableNameValidator } from '@/utils/pythonVariableNameValidator';

type BatchUpdateFidle = {
  type?: string;
  serviceId?: string;
};

interface Prop {
  fieldsList: string[];
  children: React.ReactNode;
  title: string;
  serviceList?: Service[];
  disabled: boolean;
  disabledMsg: string;
  batchUpdateCallback: (props: BatchUpdateFidle) => void;
}

export const BatchToUpdateModal: React.FC<Prop> = props => {
  const { children, title, serviceList, fieldsList, disabled, disabledMsg, batchUpdateCallback } = props;
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);

  const handleOk = async () => {
    const data: BatchUpdateFidle = await form.validateFields();

    try {
      batchUpdateCallback(data);
      setIsVisible(false);
    } catch {
      console.log(data);
    }

    form.resetFields();
  };

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  const toShowModal = () => {
    if (!disabled) {
      setIsVisible(true);
    } else {
      message.error(disabledMsg);
    }
  };

  return (
    <>
      <span onClick={toShowModal}>{children}</span>
      <Modal visible={isVisible} onOk={handleOk} onCancel={handleCancel} title={title}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          form={form}
          validateMessages={{
            required: '必填',
          }}>
          {fieldsList.includes('type') && (
            <Form.Item
              required
              name="type"
              rules={[
                { required: true, message: '终端类型不能为空' },
                {
                  validator: (_, value) => {
                    const errorInfo = pythonVariableNameValidator(value || '');
                    if (errorInfo) {
                      return Promise.reject(new Error(errorInfo));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              label="终端类型">
              <Input autoComplete="off" maxLength={16} placeholder="请设置终端类型" showCount></Input>
            </Form.Item>
          )}
          {fieldsList.includes('service') && (
            <Form.Item
              name="serviceId"
              label="选择服务器"
              required
              rules={[{ required: true, message: '所选服务器不能为空' }]}>
              <Select listHeight={160} placeholder="请选择服务器">
                {serviceList?.map(l => (
                  <Select.Option key={l?.id} value={l?.id}>
                    {l?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

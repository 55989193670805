import { MlCategory } from '../../../store/mlCategory';

export const getAbilityTags = (mlCategory: MlCategory[]) => {
  const tags: any = [];
  mlCategory?.forEach(item => {
    const firstCategory = tags.find(tag => tag.label === item.firstCategory);
    if (firstCategory) {
      const secondCategory = firstCategory.children.find(tag => tag.label === item.secondCategory);
      if (!secondCategory) {
        firstCategory.children.push({
          label: item.secondCategory,
          value: item.secondCategory,
        });
      }
    } else {
      tags.push({
        label: item.firstCategory,
        value: item.firstCategory,
        children: [
          {
            label: item.secondCategory,
            value: item.secondCategory,
          },
        ],
      });
    }
  });
  return tags;
};


export const getCategoryUuid = (mlCategory: MlCategory[], selectedCategory: string[]) => {
  if (selectedCategory.length !== 2) {
    return;
  }
  const [firstCategory, secondCategory] = selectedCategory;
  const category = mlCategory.find(
    item => item.firstCategory === firstCategory && item.secondCategory === secondCategory
  );
  return category?.categoryUuid;
};
import React, { useState, useRef, PropsWithChildren, useEffect } from 'react';
import axios from 'axios';
import { UploadProps } from 'antd/es/upload';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload as AntUpload, UploadFile } from '@fuxi/eevee-ui';

export enum UploadStatus {
  WAITING = 'waiting',
  UPLOADING = 'uploading',
  FAIL = 'error',
  SUCCESS = 'done',
}

export type MultipartUploadInterface = UploadProps &
  PropsWithChildren & {
    onSuccess?: (fileName: string, file: File) => any;
    onError?: (fileName: string, file: File) => any;
    onFileNameListChange?: (fileNameList: UploadFile<any>[]) => any;
    validateFileName?: (fileNameList: UploadFile<any>[]) => any;
    onChange?: (src?: string) => any;
    value?: string;
  };

const Upload: React.FC<MultipartUploadInterface> = props => {
  const { onChange, onError, onSuccess, onRemove, onFileNameListChange, validateFileName, value } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]); // 文件列表状态更新

  const beforeUpload = file => {
    if (file.type.includes('image')) return true;
    message.warning('请上传图片');
    return false;
  };

  const wrapOnRemove = (file: UploadFile) => {
    setFileList([]);
    onChange?.(undefined);
    return true;
  };

  const handleChange = info => {};

  const customRequest = async file => {
    const fd = new FormData();
    const uid = new Date().getTime();
    fd.append(`${uid}${file.file.name}`, file.file);

    setFileList([{ name: `${uid}${file.file.name}`, uid: file.file.uid, percent: 0, status: UploadStatus.UPLOADING }]);

    const res = await axios({
      url: '/cdnUpload/api/v1/files',
      method: 'post',
      headers: {
        project: 'lowcode',
        module: 'index_module',
        _dir: 'index',
        refresh: 'true',
        'Content-Type': 'multipart/form-data',
      },
      data: fd,
    });
    const sourceUrl = res.data.items[0];
    await setFileList([
      {
        name: `${uid}${file.file.name}`,
        uid: file.file.uid,
        percent: 100,
        status: UploadStatus.SUCCESS,
        url: sourceUrl,
        thumbUrl: sourceUrl,
      },
    ]);
    onChange?.(sourceUrl);
  };

  useEffect(() => {
    if (!!value) {
      const fileName = value.split('/').pop();
      setFileList([
        {
          name: fileName!,
          uid: fileName!,
          percent: 100,
          status: UploadStatus.SUCCESS,
          url: value,
          thumbUrl: value,
        },
      ]);
    }
  }, [value]);

  return (
    <AntUpload
      name="file"
      fileList={fileList}
      listType="picture"
      onRemove={wrapOnRemove}
      onChange={handleChange}
      beforeUpload={beforeUpload}
      customRequest={customRequest}>
      <Button type="dashed" icon={<UploadOutlined />}>
        点击上传
      </Button>
    </AntUpload>
  );
};

export default Upload;

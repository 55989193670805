export const isImgType = (file) => {
  if (!file) {
    return false;
  }
  // 忽略后缀大小写
  const fileName = file.name.toLowerCase();
  if (!/\.png$|\.jpg$|\.jpeg$|\.svg$|\.bmp$|\.gif$/.test(fileName)) {
    return false;
  }
  return true;
}
import { ServerType } from '@/constants';

// 获取代码库拉取命令
export const getRepoPullCommand = (data: {
  serviceType: string;
  clientRepoGitUrl?: string;
  serviceRepoGitUrl?: string;
  customRepoGitUrl?: string;
  version?: string;
  creator?: string;
}) => {
  const { clientRepoGitUrl, serviceRepoGitUrl, customRepoGitUrl, serviceType, version, creator = '' } = data;
  const isMain = serviceType === ServerType.Main;

  return [clientRepoGitUrl, serviceRepoGitUrl, customRepoGitUrl].map((url, index) => {
    if (!url) return '';

    const folderName = index === 0 ? 'AceClient' : '';
    let branch = '';

    // 主干
    if (isMain) {
      if (index === 2) {
        // 自定义代码切至 master 分支
        branch = '-b master';
      } else if (version) {
        // 仅当发布过时指定分支
        branch = `-b ${version}`;
      }
    } else {
      // 私服分支
      branch = `-b ${creator}`;
    }

    // 服务器代码有 submodule，需一起 clone
    let recursive = '';
    if (index === 1) {
      recursive = '--recursive';
    }

    return `git clone ${url} ${folderName} ${branch} ${recursive}`;
  });
};

import { useState } from 'react';
import { Flex } from '@fuxi/eevee-ui';
import { AbilityTagKey } from '@/constants/abilityTags';

import { useAppSelector } from '@/hooks';
import cx from './index.module.less';

interface Prop {
  handleTagChange: (tagValue: string[]) => void;
}

export const AbilityTagFilter = (props: Prop) => {
  const { handleTagChange } = props;
  const mlCategory = useAppSelector(state => state.mlCategory.categories);

  const [selectedTag, setSelectedTag] = useState<string[]>([AbilityTagKey.All, AbilityTagKey.SubAll]);

  const toHandleTagChange = (item: any) => {
    let selectedTags: string[] = [];
    // 一级标签
    if (!!item?.children?.length) {
      if (item.value === selectedTag[0]) {
        return;
      }
      selectedTags = [item.value, AbilityTagKey.SubAll];
      setSelectedTag(selectedTags);
    } else {
      if (item.value === selectedTag[1]) {
        return;
      }
      selectedTags = [selectedTag[0], item.value];
      const tagValues = selectedTags;
      setSelectedTag(tagValues);
    }

    // TODO: 等待服务端支持分页搜索的tag参数
    handleTagChange(selectedTags);
  };

  const getSubTags = () => {
    return getAbilityTags().find(item => item.value === selectedTag[0])?.children || [];
  };

  const isSelected = (value: string) => {
    return selectedTag.includes(value);
  };

  const getAbilityTags = () => {
    const tags: any = [];
    mlCategory?.forEach(item => {
      const firstCategory = tags.find(tag => tag.label === item.firstCategory);
      if (firstCategory) {
        const secondCategory = firstCategory.children.find(tag => tag.label === item.secondCategory);
        if (!secondCategory) {
          firstCategory.children.push({
            label: item.secondCategory,
            value: item.secondCategory,
          });
        }
      } else {
        tags.push({
          label: item.firstCategory,
          value: item.firstCategory,
          children: [
            {
              label: item.secondCategory,
              value: item.secondCategory,
            },
          ],
        });
      }
    });
    tags.forEach(item => {
      item.children.unshift({
        label: AbilityTagKey.All,
        value: AbilityTagKey.SubAll,
      });
    });

    tags.unshift({
      label: AbilityTagKey.All,
      value: AbilityTagKey.All,
      children: [
        {
          label: AbilityTagKey.All,
          value: AbilityTagKey.SubAll,
        },
      ],
    });
    return tags;
  };

  return (
    <div className={cx('wrapper')}>
      <Flex className={cx('category-wrapper')}>
        <span className={cx('title')}>类别：</span>
        {getAbilityTags().map(item => (
          <div
            className={isSelected(item.value) ? cx('selected-tag') : cx('tag')}
            onClick={() => toHandleTagChange(item)}
            key={item.value}>
            {item.label}
          </div>
        ))}
      </Flex>

      {selectedTag[0] !== AbilityTagKey.All && (
        <Flex className={cx('category-wrapper')}>
          <span className={cx('title')}>子类别：</span>

          {getSubTags().map(item => (
            <div
              className={isSelected(item.value) ? cx('selected-tag') : cx('tag')}
              onClick={() => toHandleTagChange(item)}
              key={item.value}>
              {item.label}
            </div>
          ))}
        </Flex>
      )}
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectStatus } from '@/constants';
import { AppThunk } from '@/store/index';
import ml from '@/service/ml';
import { CreateProjectType } from '@/constants/ownership';

export type MlProjectInfo = {
  id: number | undefined;
  name: string;
  description: string;
  aiProjectId: number;
  status: ProjectStatus;
  type?: CreateProjectType;
};

export type ProjectModelState = {
  currentProject: Project;
  projectList: Project[];
  mlProjectList: MlProjectInfo[];
  currentMlProject: MlProjectInfo;
  projectListFetching: boolean;
  showEditModal: boolean;
  showDeleteModal: boolean;
  isMdChanged: boolean;
};

const projectTemplate = {
  id: 0,
  identifier: '0',
  name: '',
  baseUrl: '',
  users: [],
  creator: '',
  description: '',
  updatedTime: '',
};

const initialState: ProjectModelState = {
  currentProject: projectTemplate,
  currentMlProject: {
    id: undefined,
    name: '',
    description: '',
    aiProjectId: 0,
    status: ProjectStatus.Success,
  },
  mlProjectList: [],
  projectList: [],
  showEditModal: false,
  showDeleteModal: false,
  isMdChanged: false,
  projectListFetching: true,
};

const project = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setCurrentProject(state, { payload }: PayloadAction<Project>) {
      state.currentProject = payload;
    },
    setShowEditModal(state, { payload }: PayloadAction<boolean>) {
      state.showEditModal = payload;
    },
    setShowDeleteModal(state, { payload }: PayloadAction<boolean>) {
      state.showDeleteModal = payload;
    },
    setMdChanged(state, { payload }: PayloadAction<boolean>) {
      state.isMdChanged = payload;
    },
    setProjectList(state, { payload }: PayloadAction<Project[]>) {
      state.projectList = payload;
    },
    setProjectListFetching(state, { payload }: PayloadAction<boolean>) {
      state.projectListFetching = payload;
    },
    setMlProjectList(state, { payload }: PayloadAction<MlProjectInfo[]>) {
      state.mlProjectList = payload;
    },
    setMlCurrentProject(state, { payload }: PayloadAction<MlProjectInfo>) {
      state.currentMlProject = payload;
    },
  },
});

export const getMlProjectList = (): AppThunk => async (dispatch, getState) => {
  ml.getMlProjectList({
    pageNum: 1,
    pageSize: 999999,
  }).then(res => {
    if (res.status === 200) {
      const projectList = res?.data?.data || [];
      dispatch(setMlProjectList(projectList));
    }
  });
};

export const {
  setCurrentProject,
  setShowEditModal,
  setShowDeleteModal,
  setMdChanged,
  setProjectList,
  setProjectListFetching,
  setMlProjectList,
  setMlCurrentProject,
} = project.actions;

export default project.reducer;

import {
  CNReg,
  PythonBuiltInFuncNameList,
  PythonKeywordList,
  PythonVariableNameReg,
  SystemNameList,
} from '@/constants/global';

export const pythonVariableNameValidator = (
  name: string,
  options?: {
    ignoreCN?: boolean;
    ignorePyVariable?: boolean;
    ignoreSystem?: boolean;
    ignorePyKeyword?: boolean;
    ignorePyBuiltIn?: boolean;
  }
) => {
  const { ignoreCN, ignorePyVariable, ignoreSystem, ignorePyKeyword, ignorePyBuiltIn } = options || {};
  if (!ignoreCN && CNReg.test(name)) {
    return '不支持中文';
  }
  if (!ignorePyVariable && (!PythonVariableNameReg.test(name) || name.length > 64)) {
    return '不符合python命名规范';
  }

  if (!ignoreSystem && SystemNameList.includes(name)) {
    return '该名称已被系统占用';
  }

  if (!ignorePyKeyword && PythonKeywordList.includes(name)) {
    return '不能使用python关键字';
  }

  if (!ignorePyBuiltIn && PythonBuiltInFuncNameList.includes(name)) {
    return '不能使用python内置函数名';
  }

  return '';
};

/* eslint-disable react-func/max-lines-per-function */
/**
 * 终端日志数据
 * 包含：目前只有实时日志
 */
import { v4 as uuid } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
//  import { throttle } from 'lodash-es';
import { TerminalLogType } from '@/constants/terminalLog';
import { AppThunk } from '@/store/index';
import { createWebsocketInstance } from '@/service/socket';
import { isJson } from '@/utils';
import { LogLevel } from '@/constants/log';
import { LogItem } from './realtimeLogs';

export interface TerminalHistoryLogOptions {
  namespace?: string;
  start: string;
  end: string;
  page?: number;
  search?: string;
  level?: string[];
}

export interface TerminalHistoryLogOptions {
  namespace?: string;
  start: string;
  end: string;
  page?: number;
  search?: string;
  level?: string[];
}

export type TerminalLog = {
  historyLogPage: number;
  historyLogCount: number;
  [TerminalLogType.Log]: LogItem[];
};

const getFormattedLogs = (data: Partial<LogItem>[] | Partial<LogItem>): LogItem[] => {
  const logs = ([] as Partial<LogItem>[]).concat(data);

  return logs.map(log => {
    const data = { ...log };
    const { uid, level, createdAt } = data;
    data.uid = uid || uuid();
    data.level = level || LogLevel.Info;
    data.createdAt = createdAt || moment().format('YYYY-MM-DD HH:mm:ss');
    return data as LogItem;
  });
};

const initialState: Record<string, TerminalLog> = {};
const initialServiceData = () => ({
  historyLogPage: 0,
  historyLogCount: 0,
  [TerminalLogType.Log]: [],
  // [TerminalLogType.HistoryLog]: [],
});

const terminalLog = createSlice({
  name: 'terminalLog',
  initialState,
  reducers: {
    initTerminalLog(state, { payload }: PayloadAction<string>) {
      state[payload] = initialServiceData();
    },
    setTerminalLog(
      state,
      { payload }: PayloadAction<{ terminalId: string; type: TerminalLogType; data: Partial<LogItem>[] }>
    ) {
      const { terminalId, type, data } = payload;
      const logs = getFormattedLogs(data as Partial<LogItem>[]);
      state[terminalId][type] = logs;
    },
    updateTerminalLog(
      state,
      {
        payload,
      }: PayloadAction<{
        terminalId: string;
        type: TerminalLogType;
        data: Partial<LogItem>[] | Partial<LogItem>;
      }>
    ) {
      const { terminalId, type, data } = payload;
      const logs = getFormattedLogs(data as Partial<LogItem>[] | Partial<LogItem>);

      if (!state[terminalId]) {
        state[terminalId] = initialServiceData();
      }

      state[terminalId][type] = state[terminalId][type].concat(logs);
    },
  },
});

export const { initTerminalLog, setTerminalLog, updateTerminalLog } = terminalLog.actions;

export const toGetTerminalServiceLogs =
  (terminalId: string, logUrl: string): AppThunk =>
  (dispatch, getState) => {
    let timeoutId = 0;
    const timeout = 300;
    const maxCount = 10000;
    const logArr: Partial<LogItem>[] = [];

    const toUpdateLog = () => {
      const { terminalLog } = getState();
      const logs = terminalLog?.[terminalId]?.[TerminalLogType.Log] || [];
      if (logArr.length) {
        if (logs?.length < maxCount) {
          dispatch(updateTerminalLog({ type: TerminalLogType.Log, terminalId, data: logArr.splice(0, 10) }));
        } else {
          dispatch(
            setTerminalLog({
              type: TerminalLogType.Log,
              terminalId,
              data: [...(logs || []), ...(logArr || []).splice(0)].splice(-maxCount),
            })
          );
        }
      }
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(toUpdateLog, timeout);
    };

    createWebsocketInstance(`/api-terminal-log-ws${logUrl}`, {
      onMessage: res => {
        const { data } = res;

        if (isJson(data)) {
          const { dt, level, msg, traceback } = JSON.parse(data);
          // 应用输出日志中一定包含 dt 字段，以此判断是否是标准日志输出
          if (!dt) return;
          const logData = {
            uid: uuid(),
            level,
            content: msg,
            createdAt: dt,
            traceback,
          };
          logArr.push(logData);
          if (!timeoutId) {
            timeoutId = window.setTimeout(toUpdateLog, timeout);
          }
        }
      },
    });
  };

export default terminalLog.reducer;

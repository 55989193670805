import { useAppSelector } from '@/hooks';
import cx from './index.module.less';

export const BiBoard = () => {
  const project = useAppSelector(state => state.project.currentProject);

  const iframeOnload = () => {
    const biDataView = document.getElementById('biDataView');
    if (!biDataView) {
      return;
    }
    // @ts-ignore
    const embedFrameFooter = biDataView.contentDocument.body.getElementsByClassName('EmbedFrame-footer');
    !!embedFrameFooter?.length && (embedFrameFooter[0].style = 'display: none');
  };

  return (
    <div className={cx('wrpper')}>
      <iframe
        title="biDataView"
        onLoad={iframeOnload}
        id="biDataView"
        src={`/bi/public/dashboard/c75b2adb-4b12-4cd1-b047-0f141789782b?code_type=${project.identifier}#bordered=false&hide_parameters=code_type&refresh=60`}
        frameBorder="0"
        width="100%"
        height="100%"
        allowFullScreen></iframe>
    </div>
  );
};

/* eslint-disable react-func/max-lines-per-function */
import { useEffect, useState } from 'react';
import { Flex, Form, Input, InputNumber, Modal, Switch, Tooltip, message } from '@fuxi/eevee-ui';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { validatePassword } from '@/utils/password';
import { ClusterResource, isClusterResourceOverflow, maxParallelNum } from '@/utils/isResourceOverflow';
import { useAppSelector } from '@/hooks';

import cx from './index.module.less';

interface VmCopyModalProps {
  itemResource: ClusterResource;
  currentGroupVmNum: number;
  children: React.ReactNode;
  saveVmCallback: ({ vmNum, password, preserved }: { vmNum: number; password: string; preserved: boolean }) => void;
}

export const VmCopyModal: React.FC<VmCopyModalProps> = props => {
  const { itemResource } = props;
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { resource } = useAppSelector(state => state.virtualTerminal);

  useEffect(() => {
    if (!isModalOpen) {
      form.resetFields();
    }
  }, [isModalOpen]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    try {
      const maxNum = 99;
      const values = await form.validateFields();
      const num = Number(values.num);

      const parallelismNum = num || 1;

      const usageResource: ClusterResource = {
        cpu: resource.usage.cpu + itemResource.cpu * parallelismNum,
        memory: resource.usage.memory + itemResource.memory * parallelismNum,
        gpu: resource.usage.gpu + itemResource.gpu * parallelismNum,
      };

      const isResoureOverflow = isClusterResourceOverflow({ usage: usageResource, limitation: resource.limitation });
      console.log('isResoureOverflow', isResoureOverflow, usageResource, resource.limitation, parallelismNum);
      if (isResoureOverflow) {
        message.error(
          `当前资源最大支持并行${maxParallelNum({
            usage: resource.usage,
            limitation: resource.limitation,
            itemResource: {
              cpu: itemResource.cpu,
              memory: itemResource.memory,
              gpu: itemResource.gpu,
            },
          })}份, 请修改份数或联系dengli@corp.netease.com`
        );
        return;
      }
      const password = values.password;
      const preserved = values.preserved;
      const isOverMaxNum = props.currentGroupVmNum + num > maxNum;
      if (isOverMaxNum) {
        message.error('当前Group最多只能有99个虚拟机');
        return;
      }

      Modal.confirm({
        title: '确认并行部署',
        content: '部署过程中，虚拟机会关闭，请确认',
        className: 'confirm-modal',
        onOk: async () => {
          props.saveVmCallback({
            vmNum: num,
            password,
            preserved,
          });
          setIsModalOpen(false);
        },
      });
    } catch (e) {}
  };
  return (
    <div>
      <div onClick={showModal}>{props.children}</div>
      <Modal
        destroyOnClose
        className={cx('copy-modal')}
        title="并行部署"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <Form form={form}>
          <Form.Item
            rules={[
              {
                required: true,
                message: '必填',
              },
            ]}
            required
            name="num"
            label="并行份数">
            <InputNumber min={1} className={cx('input-number')} width={384} required autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="password"
            required
            label="设置密码"
            rules={[
              {
                required: true,
                message: '必填',
              },
              {
                validator: validatePassword(6, 20, 1),
              },
            ]}>
            <Input
              className={cx('input-password')}
              autoSave="off"
              placeholder="请输入大小写字母、数字、特殊字符组合的密码，6～20位"
              min={6}
              max={20}
              name="passward"
              autoComplete="off"></Input>
          </Form.Item>
          <Form.Item
            required
            name="preserved"
            label={
              <Flex alignCenter>
                保留当前虚拟终端和并行项
                {
                  <Tooltip title="并行部署后，默认删除当前虚拟终端和并行项, 您也可以选择保留">
                    <QuestionCircleOutlined className={cx('icon')}></QuestionCircleOutlined>
                  </Tooltip>
                }
              </Flex>
            }>
            <Switch></Switch>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Descriptions,
  DescriptionsProps,
  Divider,
  Tabs,
  Image,
  TabsProps,
  Empty,
  Tooltip,
  TableButtons,
  message,
  Flex,
  Spin,
} from '@fuxi/eevee-ui';
import { ColumnsType } from 'antd/es/table';
import { requestConfirm } from '@ai-training/utils';
import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import ml from '@/service/ml';
import EmptyImg from '@/assets/image/empty2.png';

import ProjectMarkDown from '@/components/Markdown/Markdown';
import { useAppSelector } from '@/hooks';
import { getAbilityTagValue } from '@/constants/abilityTags';
import { MlProjectDetail, MlProjectVersionDetail } from '@/@types/ml';
import ProjectImg from '../../../assets/image/mlProjectImg.png';
import { AbilityTag } from '../components/AbilityTag';
import { onMlImgError } from '../utils/onMlImgError';
import cx from './index.module.less';

export const MlProjectAbilityDetail = () => {
  const navigate = useNavigate();
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const mlCategory = useAppSelector(state => state.mlCategory.categories);
  const { projectAbilityId } = useParams();
  const {
    getCapabilityDetails,
    getCapabilityVersions,
    deleteCapabilityVersions,
    downloadAbilityVersion,
    getDatasetDetail,
  } = ml;
  const [detail, setDetail] = useState<MlProjectDetail>();
  const [fetchDatasetDetailLoading, setFetchDatasetDetailLoading] = useState<boolean>(false);

  const AbilityKeys = {
    AbilityMarkdown: 'abilityMarkdown',
    AbilityVersion: 'abilityVersion',
  };

  const { loading, run: fetchCapDetail } = useRequest(() => getCapabilityDetails({ id: projectAbilityId! }), {
    onSuccess: data => {
      setDetail(data.data);
    },
    manual: true,
  });

  const { data: versions, run } = useRequest(
    () =>
      getCapabilityVersions({
        id: projectAbilityId!,
      }),
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (!!aiProjectId) {
      fetchCapDetail();
      run();
    }
  }, [aiProjectId]);

  const refresh = () => {
    run();
  };

  const toDatasetDetail = async (datasetId: string) => {
    message.info('正在跳转到数据集详情页...');
    const errorLog = '未找相关联的数据集';
    if (!datasetId) {
      message.error(errorLog);
      return;
    }
    try {
      setFetchDatasetDetailLoading(true);
      const data: {
        status: number;
        data: {
          name: string;
          first_version_id: string;
        };
      } = await getDatasetDetail(datasetId);
      setFetchDatasetDetailLoading(false);
      if (data.status !== 200 || !data.data.first_version_id) {
        message.error(errorLog);
        return;
      } else {
        navigate(`../../../dataset/${data.data.first_version_id}/${datasetId}`);
      }
    } catch (error) {
      message.error(errorLog);
      setFetchDatasetDetailLoading(false);
      return;
    }
  };

  const items: DescriptionsProps['items'] = [
    {
      key: 'id',
      label: '能力id',
      children: detail?.uuid,
      span: 1,
    },
    {
      key: 'versions',
      label: '版本数',
      children: detail?.version_num,
      span: 2,
    },
    {
      key: 'createAt',
      label: '创建时间',
      children: <Tooltip title={detail?.created_at}>{detail?.created_at}</Tooltip>,
      span: 1,
    },
    {
      key: 'updateAt',
      label: '最后更新时间',
      children: detail?.updated_at,
      span: 2,
    },
    {
      key: 'desc',
      label: '描述',
      children: (
        <Tooltip title={detail?.description} placement="topLeft">
          {detail?.description}
        </Tooltip>
      ),
      span: 3,
    },
  ];

  const publicVersionColumns: ColumnsType<MlProjectVersionDetail> = [
    {
      title: '版本',
      dataIndex: 'name',
      render: (_, record) => record.code,
    },
    {
      title: '描述',
      dataIndex: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: val => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: '原能力',
      dataIndex: 'source',
      render: val =>
        !!val ? (
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: '训练数据集',
      dataIndex: 'train_dataset_name',
      render: (_, record) =>
        !!record.train_dataset_name ? (
          <div className={cx('dataset-name')} onClick={() => toDatasetDetail(record.train_dataset_id)}>
            {record.train_dataset_name}
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: '操作',
      fixed: 'right',
      render: (record: MlProjectVersionDetail) => (
        <TableButtons
          data={[
            {
              text: '训练',
              onClick: () =>
                navigate(`../../../ml-training-task/create?abilityId=${projectAbilityId}&versionId=${record.uuid}`),
            },
            {
              text: '发布',
              onClick: () =>
                navigate(`../../../model-service/create?abilityId=${projectAbilityId}&versionId=${record.uuid}`),
            },
            {
              text: '下载',
              onClick: () => {
                if (!aiProjectId) {
                  message.warning('未找到当前版本关联的丹炉项目');
                  return;
                }
                message.info('请求已发起，请耐心等待');
                downloadAbilityVersion({
                  id: record.uuid,
                  aiProjectId: aiProjectId,
                }).then(async res => {
                  if (res.status !== 200) {
                    message.error('下载失败');
                    return;
                  }
                  const contentDisposition = res.headers['content-disposition'];
                  if (contentDisposition) {
                    const filename = contentDisposition.split('filename=')[1];
                    const blob = new Blob([res.data], { type: 'application/octet-stream' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                    URL.revokeObjectURL(url);
                    message.success('下载成功');
                  }
                });
              },
            },
            {
              text: '删除',
              type: 'danger',
              onClick: () => {
                if (versions?.data?.list?.length === 1) {
                  message.warning('不能删除最后一个版本');
                  return;
                }
                requestConfirm({
                  title: `确定删除能力${detail?.name} 的${record.name}版本?`,
                  content: '该版本将被删除，且不可恢复。',
                  payload: {
                    abilityId: detail?.uuid,
                    versionId: record.uuid,
                  },
                  submitApi: deleteCapabilityVersions,
                }).then(() => refresh());
              },
            },
          ]}
        />
      ),
    },
  ];

  const tabs: TabsProps['items'] = [
    {
      key: AbilityKeys.AbilityMarkdown,
      label: '能力说明',
      children: (
        <div className={cx('markdown')}>
          {detail?.markdown ? (
            <ProjectMarkDown text={detail?.markdown} />
          ) : (
            <Empty className={cx('empty')} description="添加能力说明以更好地描述你的项目能力" image={EmptyImg} />
          )}
        </div>
      ),
    },
    {
      key: AbilityKeys.AbilityVersion,
      label: '能力版本',
      children: (
        <>
          <Table
            loading={fetchDatasetDetailLoading}
            pagination={{
              showTotal: total => `总共 ${total} 条`,
              showQuickJumper: true,
              pageSizeOptions: ['8', '10', '16', '32', '64'],
              showSizeChanger: true,
            }}
            columns={publicVersionColumns}
            dataSource={versions?.data?.list}></Table>
        </>
      ),
    },
  ];

  const transfer = () => {
    navigate('edit');
  };

  return (
    <Spin spinning={loading} tip="正在获取项目能力详情...">
      <div className={cx('wrapper')}>
        <Flex alignCenter>
          <div className={cx('desc-wrapper')}>
            <Flex flexDirection="column">
              <Descriptions
                title={
                  <Flex alignCenter>
                    <div className={cx('name')}>{detail?.name || '-'}</div>
                    <AbilityTag value={getAbilityTagValue(mlCategory, detail?.category_uuid || '')}></AbilityTag>
                  </Flex>
                }
                items={items}
              />
              <Flex.Item alignSelf="start">
                <Button className={cx('edit-button')} onClick={transfer} type="primary" icon={<EditOutlined />}>
                  编辑
                </Button>
              </Flex.Item>
            </Flex>
          </div>
          <Flex.Item>
            <Image
              width={378}
              height={179}
              className={cx('card-img')}
              src={detail?.image_url || ProjectImg}
              onError={onMlImgError}></Image>
          </Flex.Item>
        </Flex>

        <Divider className={cx('divider')}></Divider>
        <Tabs defaultActiveKey={AbilityKeys.AbilityMarkdown} items={tabs} />
      </div>
    </Spin>
  );
};

/* eslint-disable max-lines */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { FormInstance } from 'antd/es/form';
import { Button, Flex, Form, Input, Popconfirm, Table, Tabs, message } from '@fuxi/eevee-ui';
import { PlusOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { usePagination } from '@fuxi/eevee-hooks';
import { useAppSelector } from '@/hooks';
import service from '@/service';
import { getMlDatasetDataByPage } from '@/service/ml';
import { IInfoItem, annotateStatusMap, blackSpaceRule, getAnnotateTabList } from '../const';
import { DatasetContainer, useDatasetState } from '../index.container';
import { EditStatus } from '../../components';
import cx from './index.module.less';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof IInfoItem;
  record: IInfoItem;
  editRecord: React.MutableRefObject<IInfoItem>;
  handleSave: (record: IInfoItem) => void;
  isSaving?: boolean;
  setIsSaving: (isSaving: boolean) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  editRecord,
  handleSave,
  setIsSaving,
  ...restProps
}) => {
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (record) {
      form.setFieldsValue({ [dataIndex]: record?.[dataIndex] });
    }
  }, [form]);

  const save = async () => {
    const values = await form.validateFields().catch(err => {
      setIsSaving(false);
      form.setFieldsValue({ [dataIndex]: record?.[dataIndex] });
    });
    if (!values) {
      return;
    }

    if (values?.[dataIndex] === record?.[dataIndex]) {
      setIsSaving(false);
      return;
    }
    handleSave({ ...record, ...values });
  };

  const rules =
    dataIndex === 'data'
      ? [
          {
            required: true,
            message: `问题不能为空`,
          },
          blackSpaceRule,
        ]
      : [blackSpaceRule];

  return (
    <td {...restProps}>
      {editable ? (
        <Form.Item style={{ margin: 0 }} name={dataIndex} rules={[...rules]}>
          <Input.TextArea
            onChange={e => {
              setIsSaving(true);
              editRecord.current = { ...record, [dataIndex]: e.target.value?.trim() };
            }}
            disabled={dataIndex === 'annotation' && record.annotationDisabled}
            onPressEnter={save}
            onBlur={save}
            maxLength={200}
          />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }}>
          {children}
        </div>
      )}
    </td>
  );
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const AnnotateText: React.FC = () => {
  const history = useNavigate();
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const { datasetVid } = useParams();

  const [infoList, setInfoList] = useState<IInfoItem[]>([]);
  const [tab, setTab] = useState('all');
  const [isAdd, setIsAdd] = useState(false);
  const {
    pagination,
    run: getData,
    loading: getInfoLoading,
  } = usePagination(
    ({ current, pageSize }) =>
      getMlDatasetDataByPage(datasetVid!, {
        current,
        pageSize,
        isAnnotated: tab === 'all' ? '' : annotateStatusMap[tab],
      }),
    {
      defaultPageSize: 10,
      onSuccess: data => {
        const { list } = data;
        // 删除完最后一条数据，切换到全部tab
        if (data?.list?.length === 0 && tab !== 'all') {
          setTab('all');
        }

        if (isAdd && tab === 'all') {
          setIsAdd(false);
          if (list?.length === pagination?.pageSize) {
            list.pop();
          }
          const newItem = {
            id: '',
            data: '',
            key: uuidv4(),
            annotationDisabled: true,
            annotation: '',
          };
          list.unshift(newItem);
        }
        setInfoList(prevList => {
          return list?.map((item, idx) => ({ ...item, order: idx + 1, key: String(item.id) })) || prevList;
        });
      },
      manual: true,
    }
  );

  useEffect(() => {
    if (!aiProjectId) {
      return;
    }
    getData({ current: 1, pageSize: 10 });
  }, [aiProjectId, tab]);

  const { detailData } = useDatasetState();
  const { data: detail, refetch: getDetail } = detailData;

  const mutation = useMutation<any, any, any>({
    mutationFn: ({ datasetVid, ids }) => service.ml.deleteDatasets({ datasetVid, ids }),
    onSuccess: res => {
      message.success('删除成功');
      getData({ current: 1, pageSize: 10 });
      getDetail();
    },
  });

  const handleDelete = async record => {
    if (!record?.id) {
      const newList = infoList.filter(item => item.key !== record.key);
      setInfoList(newList);
      return;
    }
    mutation.mutate({ datasetVid, ids: [record?.id] });
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: '序号',
      key: 'order',
      dataIndex: 'order',
      editable: false,
    },
    {
      title: '问题',
      dataIndex: 'data',
      editable: true,
    },
    {
      title: '回答',
      dataIndex: 'annotation',
      editable: true,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_, record) => (
        <Popconfirm title="确定删除此问答对吗？" onConfirm={() => handleDelete(record)}>
          <Button type="text" danger disabled={!record.id}>
            删除
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const handleAdd = () => {
    if (tab === 'all') {
      const newList = cloneDeep(infoList);
      const newItem = {
        id: '',
        key: uuidv4(),
        data: '',
        annotation: '',
      };
      // 数据超过一页
      if (newList?.length === pagination.pageSize) {
        newList.pop();
      }
      newList.unshift(newItem);
      setInfoList(
        [...newList]?.map((item, idx) => ({
          ...item,
          order: idx + 1,
          annotationDisabled: true,
          key: item.id ? String(item.id) : item.key,
        }))
      );
    } else {
      setTab('all');
      setIsAdd(true);
    }
  };

  const handleSave = async (row: IInfoItem) => {
    const newData = [...infoList];
    // 有id的是编辑，没有id的是新增
    if (row.id) {
      const res = await service.ml.annotateData(datasetVid!, {
        id: row.id as number,
        data: row.data,
        annotation: row.annotation,
      });
      if (!res.data) return;

      setIsSaving(false);
      const index = newData.findIndex(item => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setInfoList(newData);
    } else {
      const res = await service.ml.addDatasetData(datasetVid!, {
        data: row.data,
        // 新增的必然是未标注的
        annotation: null,
      });
      if (!res.data) return;
      setIsSaving(false);
      getData({
        current: 1,
        pageSize: pagination.pageSize,
      });
    }
    // 更新tab的数据
    getDetail();
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const [isSaving, setIsSaving] = useState(false);
  const editRecord = useRef<IInfoItem>({});
  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IInfoItem) => ({
        record,
        editRecord,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        setIsSaving,
      }),
    };
  });

  // 离开页面前保存当前编辑的数据
  function preventUnload(e: BeforeUnloadEvent) {
    const row = editRecord.current;
    if (!editRecord.current?.data) return;
    const addUrl = `/api/v1/ml/dataset/${datasetVid}/data`;
    const annotationUrl = `/api/v1/ml/dataset/${datasetVid}/annotate`;
    const params = {
      ...row,
      data: row.data || null,
      annotation: row.annotation || null,
    };
    // 有id的是编辑，没有id的是新增
    if (row.id) {
      fetch('/ml' + annotationUrl, {
        method: 'POST',
        body: JSON.stringify({ ...params }),
        headers: {
          'Content-Type': 'application/json',
          Project_id: String(aiProjectId),
        },
        keepalive: true,
      });
    } else {
      fetch('/ml' + addUrl, {
        method: 'POST',
        body: JSON.stringify({ ...params }),
        headers: {
          'Content-Type': 'application/json',
          Project_id: String(aiProjectId),
        },
        keepalive: true,
      });
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', preventUnload);
    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, []);

  return (
    <div className={cx('annotate-text')}>
      <EditStatus isSaving={isSaving} navigate={history}></EditStatus>
      <Flex justifyBetween alignCenter className={cx('toolbar')}>
        <Tabs
          activeKey={tab}
          items={getAnnotateTabList(
            +detail?.data?.first_version_data_count!,
            +detail?.data?.first_version_annotated_data_count!
          )}
          onChange={setTab}
        />
        <Button onClick={handleAdd} type="default" icon={<PlusOutlined />}>
          添加问答对
        </Button>
      </Flex>

      <Table
        loading={getInfoLoading}
        pagination={{
          pageSize: pagination.pageSize,
          showQuickJumper: true,
          onChange: pagination.onChange,
          current: pagination.current,
          total: pagination.total,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 30, 50],
          showTotal: () => `共 ${pagination.total} 条`,
        }}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={infoList}
        columns={columns as ColumnTypes}
      />
    </div>
  );
};

const AnnotateTextPage = () => {
  return (
    <DatasetContainer.Provider>
      <AnnotateText />
    </DatasetContainer.Provider>
  );
};

export default AnnotateTextPage;

import React from 'react';
import { Space, StatusTag, Table, TableColumnsType, TablePaginationConfig, Tooltip } from '@fuxi/eevee-ui';
import { openVisualWebsite } from '../const';
import { isTemplateProject } from '../utils';
import { CreateProjectType } from '@/constants/ownership';

type ProjectTableProps = {
  handlePaginationChange: (pagination: TablePaginationConfig) => void;
  projectList: Project[];
  editProject: (item: Project) => void;
  deleteProject: (item: Project) => void;
  handleTurn2Detail: (item: Project) => void;
  paginationProps: TablePaginationConfig;
};

const Project: React.FC<ProjectTableProps> = props => {
  const { projectList, paginationProps, handlePaginationChange, handleTurn2Detail } = props;

  const columns: TableColumnsType<Project> = [
    {
      title: '项目名称',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Space>
          <a onClick={() => handleTurn2Detail(record)}> {text}</a>
          {isTemplateProject(record) && (
            <StatusTag
              style={{
                display: 'inline',
                padding: '4px 8px',
                background: '#E58616',
                color: 'white',
                borderColor: '#E58616',
              }}
              color="info">
              模板
            </StatusTag>
          )}
        </Space>
      ),
    },
    {
      title: '项目描述',
      dataIndex: 'description',
      key: 'description',
      ellipsis: {
        showTitle: false,
      },
      render: description => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      sorter: (prev, next) => {
        if (prev.updateTime && next.updateTime) {
          return next?.updateTime > prev?.updateTime ? -1 : 1;
        }
        return 0;
      },
    },
    {
      title: '归属空间',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => {
        return text === CreateProjectType.ENTERPRISE ? '企业' : '个人';
      },
    },
    {
      title: '项目创建者',
      dataIndex: 'creator',
      key: 'creator',
    },
    {
      title: '操作',
      width: 180,
      render: (_: unknown, record: Project) => {
        return (
          <>
            <a onClick={() => openVisualWebsite(record.id)}>可视化开发</a>
            {/* <Divider type="vertical" />
            <a onClick={() => editProject(record)}>编辑</a> */}
            {/* <a onClick={() => deleteProject(record)}>删除</a> */}
          </>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={projectList}
      onChange={handlePaginationChange}
      // loading={false}
      rowKey="id"
      pagination={paginationProps}
    />
  );
};

export default Project;

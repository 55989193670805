import { message } from '@fuxi/eevee-ui';
import { get } from 'lodash';
import { AxiosResponse } from 'axios';

export const shanquanResponseHandler = (response: AxiosResponse) => {
  const data = get(response, 'data.data', response.data);

  const status = get(response, 'response.status') || 200;
  const code = get(response, 'data.code');

  // 当httpStatus正确但是业务码出错的情况
  // 特殊处理markdown的请求

  if (status > 200 && status < 300 && code !== 0) {
    const msg = get(response, 'data.msg');
    message.error(msg || '系统异常');
    throw new Error(msg);
  }

  return { ...response, data };
};

import React, { useEffect, useState } from 'react';
import { UserOutlined, LogoutOutlined, DownOutlined, KeyOutlined } from '@ant-design/icons';
import { Layout, Dropdown, Menu, Space, Flex, Typography, Icon } from '@fuxi/eevee-ui';
import { useLocation, Outlet, useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import cookie from 'react-cookies';
import SiderMenu from '@/components/SiderMenu';
import PageContainer from '@/components/PageContainer';

import fuxiAgentLogo from '@/assets/fuxi-agent-logo.png';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { FeedbackModal } from '@/components/FeedbackModal';
// import IconFont from '@/components/IconFont';
import { queryCommonConfigs } from '@/store/commonConfig';
import { toGetSSHInfo } from '@/store/ssh';
import { DanluDomain, NeteaseDomain, RbacTokenName, RbacUserName, RoutePathname, YLNeteaseDomain } from '@/constants/global';
import { setMlCurrentProject, setMlProjectList, setProjectListFetching } from '@/store/project';
import ml from '@/service/ml';
import SSHKey from '@/components/SSHKey';

import style from './index.module.less';

const { Header, Content } = Layout;

const BasicLayout: React.FC = () => {
  const params = useParams();
  const projectId = params.projectId!;
  const location = useLocation();
  const roles = useAppSelector(state => state.user.roles || []);
  const currentMlProject = useAppSelector(state => state.project.currentMlProject);
  const { getMlProjectList } = ml;
  const feedbackModalNonce = useAppSelector(state => state.user.feedbackModalNonce);

  const dispatch = useAppDispatch();
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);

  const user = useAppSelector(state => state.user);

  // 有灵ml模块也需要查询项目列表，故提到最外层,并存储在store中
  const {
    data: projectsData,
    loading: projectListLoading,
    refresh,
  } = useRequest(() =>
    getMlProjectList({
      pageNum: 1,
      pageSize: 999999,
    })
  );

  useEffect(() => {
    location.pathname === '/project' && !projectListLoading && refresh();
  }, [location.pathname]);

  useEffect(() => {
    dispatch({
      type: 'project/fetchProjects',
    });
  }, []);

  useEffect(() => {
    const projectList = projectsData?.data?.data || [];
    dispatch(setMlProjectList(projectList || []));
    dispatch(setProjectListFetching(projectListLoading));
    if (!!currentMlProject?.id) {
      const currentProject = projectList?.find(p => p.id === currentMlProject.id);
      currentProject && dispatch(setMlCurrentProject(currentProject));
    } else if (!!projectId) {
      const targetProject = projectList?.find(p => p.id === +projectId);
      targetProject && dispatch(setMlCurrentProject(targetProject));
    } else {
      const latestProject = projectList?.[0];
      latestProject && dispatch(setMlCurrentProject(latestProject));
    }
  }, [projectsData?.data?.data]);

  const isMainPage = location.pathname === RoutePathname.project;
  const isTemplatePage = location.pathname === RoutePathname.template;
  const aiMainPage = location.pathname.endsWith('/ai-capability');
  const underAiPage = location.pathname.includes('/ai-capability');
  const underReview = location.pathname.includes(RoutePathname.review);

  useEffect(() => {
    dispatch(queryCommonConfigs());
    dispatch(toGetSSHInfo());
  }, []);

  useEffect(() => {
    if (feedbackModalNonce) {
      showFeedbackModal();
    }
  }, [feedbackModalNonce]);

  const logout = () => {
    // 清除cookie
    cookie.remove(RbacTokenName, { domain: NeteaseDomain, path: '/' });
    cookie.remove(RbacUserName, { domain: NeteaseDomain, path: '/' });

    cookie.remove(RbacTokenName, { domain: YLNeteaseDomain, path: '/' });
    cookie.remove(RbacUserName, { domain: YLNeteaseDomain, path: '/' });

    cookie.remove(RbacTokenName, { domain: DanluDomain, path: '/' });
    cookie.remove(RbacUserName, { domain: DanluDomain, path: '/' });
    window.location.href = `/rbac/login?next=${window.location.href}`;
  };

  const menu = (
    <Menu>
      <Menu.Item key="user name" style={{ maxWidth: 235, whiteSpace: 'nowrap' }}>
        <Space>
          <UserOutlined />
          <Typography.Text>{user?.fullName || user?.name}</Typography.Text>
        </Space>
      </Menu.Item>
      <Menu.Item key="ssh public key">
        <SSHKey>
          <Space>
            <KeyOutlined />
            <span>SSH公钥</span>
          </Space>
        </SSHKey>
      </Menu.Item>
      <Menu.Item key="logout">
        <a onClick={logout}>
          <Space>
            <LogoutOutlined />
            <span>退出登录</span>
          </Space>
        </a>
      </Menu.Item>
    </Menu>
  );

  const showFeedbackModal = () => {
    setFeedbackModalVisible(true);
  };

  const linkToDoc = () => {
    let tempwindow = window.open('_blank');
    (tempwindow as any)!.location = 'https://youling-docs.apps-hp.danlu.netease.com/docs';
  };

  const linkToFuxiSdk = () => {
    let tempwindow = window.open('_blank');
    (tempwindow as any)!.location = 'https://youling-docs.apps-hp.danlu.netease.com/docs/guide/catalogue/fuxiaop';
  };

  return (
    <Layout className={style('basic-layout')}>
      <Layout className={style('right-layout')}>
        <Header className={style('header')}>
          <div className={style('logo')}>
            <img alt="伏羲机器人" src={fuxiAgentLogo} />
            <span>&nbsp;· 有灵平台</span>
          </div>
          {/* 加上key解决初始化时menuData被权限过滤 获取到权限后 menu内item增加 而menu过短 选项隐藏问题 */}
          <Flex.Item className={style('header-menu')} flex={1}>
            <SiderMenu key={roles?.length} mode="horizontal" permissionList={roles} />
          </Flex.Item>
          <Flex className={style('right-container')} alignCenter>
            <Flex.Item style={{ width: 92, marginRight: 24, cursor: 'pointer' }}>
              <div onClick={linkToFuxiSdk} style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="guide" style={{ marginRight: 8, width: 20, height: 20 }} />
                开发指南
              </div>
            </Flex.Item>
            <Flex.Item style={{ width: 92, marginRight: 24, cursor: 'pointer' }}>
              <div onClick={linkToDoc} style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="icon-wendangzhongxin" style={{ marginRight: 8, width: 24, height: 24 }} />
                文档中心
              </div>
            </Flex.Item>
            <Flex.Item style={{ width: 92, cursor: 'pointer' }}>
              <div onClick={showFeedbackModal} style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="icon-fankui" style={{ marginRight: 8, width: 24, height: 24 }} />
                我要反馈
              </div>
            </Flex.Item>
            <Flex.Item>
              <Dropdown
                className={style('user-dropdown')}
                overlayClassName={style('user-dropdown')}
                placement="bottomCenter"
                overlay={menu}
                trigger={['hover']}>
                <div className={style('user-name-container')}>
                  <Icon name="icon-touxiang" style={{ width: 32, height: 32, marginRight: 8 }}></Icon>
                  <span className={style('user-name')}>{user?.fullName || user?.name}</span>
                  <DownOutlined />
                </div>
              </Dropdown>
            </Flex.Item>
          </Flex>
        </Header>
        <div className={style('content', { 'tpl-center-bg': location.pathname.includes('template') })}>
          <Content
            className={
              underReview
                ? style('review')
                : aiMainPage
                ? style('ai-page')
                : underAiPage
                ? style('under-ai-page')
                : isMainPage
                ? style('project-main')
                : isTemplatePage
                ? style('template-main')
                : style('main')
            }>
            <PageContainer>
              <Outlet />
            </PageContainer>
          </Content>
        </div>
      </Layout>
      <FeedbackModal visible={feedbackModalVisible} setFeedbackModalVisible={setFeedbackModalVisible} />
      {/* <UnsavedPrompt /> */}
    </Layout>
  );
};

export default BasicLayout;

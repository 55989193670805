import React, { MouseEventHandler, CSSProperties } from 'react';
import { Tag as TagProps } from '@/store/template';
import cx from './index.module.less';

const Tag: React.FC<
  Pick<TagProps, 'tagName'> &
    Partial<{ onMouseEnter: MouseEventHandler; onMouseLeave: MouseEventHandler; style: CSSProperties }>
> = ({ tagName, onMouseEnter, onMouseLeave, style }) => {
  return (
    <div className={cx('container')} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={style}>
      {tagName}
    </div>
  );
};

export default Tag;

import client from '@/service/graphql';
import { GET_CONSUME_RECORDS } from './schema/consumeRecord/consume-record';

export type QueryProjectBillInput = {
  projectId: number;
  startTime?: string;
  endTime?: string;
};

export const getConsumeRecords = (QueryProjectBillInput: QueryProjectBillInput) => {
  return client.query(GET_CONSUME_RECORDS, { QueryProjectBillInput });
};

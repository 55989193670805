import React from 'react';
import { Empty } from 'antd';

import EmptyImg from '../../assets/empty2.png';
import cx from './EmptyCard.module.less';

type Props = { description?: string; height?: number; imageStyle?: React.CSSProperties };

export const EmptyCard: React.FC<Props> = ({ description, height, imageStyle }) => {
  return (
    <div style={{ height }} className={cx['empty-card-wrapper']}>
      <Empty description={description} image={EmptyImg} imageStyle={imageStyle} className={cx['empty-card']} />
    </div>
  );
};

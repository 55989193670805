import React, { useState, PropsWithChildren } from 'react';
import { UploadProps } from 'antd/es/upload';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useUpdateEffect } from '@fuxi/eevee-hooks';
import { Button, message, Upload as AntUpload, UploadFile, Icon } from '@fuxi/eevee-ui';
import service from '@/service';
import cx from './index.module.less';

enum UploadStatus {
  UPLOADING = 'uploading',
  FAIL = 'error',
  SUCCESS = 'done',
}
const MAX_COUNT = 10;

export type UploadInterface = UploadProps &
  PropsWithChildren & {
    value?: string[];
    onSuccess?: (file: File) => any;
    onChange?: (file: File) => any;
    onError?: (fileName: string, file: File) => any;
    onFileNameListChange?: (fileNameList: UploadFile<any>[]) => any;
    validateFileName?: (fileNameList: UploadFile<any>[]) => any;

    payload?: {
      project_id?: string;
      upload_uuid?: string;
    };
    btnText?: string;
    btnType?: 'primary' | 'dashed' | 'link' | 'text' | 'default';
    multiple?: boolean;
  };

export const UploadDatasetImg: React.FC<UploadInterface> = props => {
  const {
    btnText = '上传',
    btnType = 'primary',
    onChange,
    payload,
    onError,
    onSuccess,
    onRemove,
    onFileNameListChange,
    validateFileName,
    multiple = true,
    showUploadList = true,
  } = props;

  let uploadCount = 0;
  const [fileList, setFileList] = useState<UploadFile[]>([]); // 文件列表状态更新

  const beforeUpload = file => {
    if (file.size > 14 * 1024 * 1024) {
      message.warning('单文件大小不超过14M');
      return false;
    }
  };

  // 文件列表状态更新
  const updateFile = (fileMd5, upload) => {
    let changedFile;
    setFileList(preFileList => {
      const changedFileList = preFileList?.map(file => {
        if (file.uid === fileMd5) {
          changedFile = file;
          return { ...file, ...upload };
        }
        return file;
      });
      onChange?.({ file: changedFile, fileList: changedFileList });

      return changedFileList;
    });
  };

  const addFile = file => {
    setFileList(preFileList => {
      const fileList = [file, ...preFileList];
      return fileList;
    });
  };

  const customRequest = async ({ file }: any) => {
    uploadCount++;
    if (uploadCount > MAX_COUNT) {
      message.open({
        type: 'warning',
        key: 'uploadImage',
        content: `单次最多上传${MAX_COUNT}张图片`,
        duration: 1,
      });
      return;
    }
    const fileIdData = await service.ml.getUploadFileId({ filename: file?.name, ...payload });
    if (!fileIdData) {
      message.error('上传失败');
      return;
    }
    addFile({
      name: file?.name,
      uid: fileIdData?.upload_id,
      status: UploadStatus.UPLOADING,
      percent: 0,
    });

    const data = new FormData();
    data.append('file', file);
    data.append('upload_id', fileIdData?.upload_id);
    data.append('filename', file?.name);
    data.append('upload_uuid', payload?.upload_uuid!);
    const uploadRes = await service.ml.uploadTotalFile(data).catch(() => {
      updateFile(fileIdData?.upload_id, {
        name: file?.name,
        uid: fileIdData?.upload_id,
        status: UploadStatus.FAIL,
        percent: 100,
      });
      onError?.(file?.name, file);
    });
    if (uploadRes?.data?.url) {
      updateFile(fileIdData?.upload_id, {
        name: file?.name,
        uid: fileIdData?.upload_id,
        status: UploadStatus.SUCCESS,
        percent: 100,
        url: uploadRes?.data?.url,
      });
      onSuccess?.({
        ...file,
        name: file?.name,
        url: uploadRes?.data?.url,
      });
    } else {
      updateFile(fileIdData?.upload_id, {
        name: file?.name,
        uid: fileIdData?.upload_id,
        status: UploadStatus.FAIL,
        percent: 100,
      });
      onError?.(file?.name, file);
    }
  };

  const deleteFile = fileMd5 => {
    setFileList(preFileList => {
      const fileList = preFileList?.filter(file => {
        return file.uid !== fileMd5;
      });

      return fileList;
    });
    onChange?.({ file: {} as any, fileList });
  };
  const wrapOnRemove = async (file: UploadFile) => {
    if (file.status === UploadStatus.UPLOADING) {
      message.error('正在上传中，无法取消');
      return;
    }
    const cancelRes = await service.ml.cancelUpload({
      upload_uuid: payload?.upload_uuid,
      filename: file.name,
    });
    if (!cancelRes) {
      message.error('取消上传失败');
      return;
    }
    deleteFile(file?.uid);
    onRemove?.(file);
  };

  const handleChange = info => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} 上传成功`);
    }
  };
  useUpdateEffect(() => {
    const fileNameList = fileList.filter(file => !(file.status === UploadStatus.FAIL));
    onFileNameListChange?.(fileNameList);
    validateFileName?.(fileNameList);
  }, [fileList]);

  return (
    <AntUpload
      multiple={multiple}
      maxCount={MAX_COUNT}
      name="file"
      listType="picture"
      showUploadList={showUploadList}
      fileList={fileList}
      accept={'image/*'}
      onRemove={wrapOnRemove}
      onChange={handleChange}
      beforeUpload={beforeUpload}
      customRequest={customRequest}
      itemRender={(originNode, file, currFileList, actions) => {
        return (
          <div className={cx('image-item', `image-item-${file.status}`)}>
            {file.status === UploadStatus.FAIL && (
              <img alt="" src="https://static.fuxi.netease.com/yaotai/portal/index/lrepja6s_985619.png"></img>
            )}
            {file.status === UploadStatus.UPLOADING && (
              <div className={cx('image-item-progress')}>
                <LoadingOutlined />
              </div>
            )}
            {file.status === UploadStatus.SUCCESS && <img src={file.url} alt="" />}
            <div className={cx('image-item-mask')}>
              <Icon
                name="删除"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  actions?.remove();
                }}></Icon>
            </div>
          </div>
        );
      }}
      className={cx('dataset-upload-image', !showUploadList && 'hide-image-list')}>
      <Button type={btnType} icon={<UploadOutlined />}>
        {btnText}
      </Button>
    </AntUpload>
  );
};

export default UploadDatasetImg;

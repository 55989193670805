import client from '@/service/graphql';
import { Industry, Tag, Template } from '@/store/template';
import { QUERY_INDUSTRY, QUERY_TAGS, QUERY_TEMPLATES } from '@/service/schema/template/template-queries';
import { QueryTemplateInput, UseTemplateInput } from '@/service/schema/template/types';
import { USE_TEMPLATE } from '@/service/schema/template/template-mutation';

export const getTags = () => {
  return client.query<{}, { tags: Tag[] }>(QUERY_TAGS);
};

export const getIndustry = () => {
  return client.query<{}, { industry: Industry[] }>(QUERY_INDUSTRY);
};

export const getTemplates = (input: QueryTemplateInput) => {
  return client.query<{ input: QueryTemplateInput }, { templates: Template[] }>(QUERY_TEMPLATES, {
    input,
  });
};

export const useTemplate = (input: UseTemplateInput) => {
  return client.mutate<{ input: UseTemplateInput }, { useTemplate: Project }>(USE_TEMPLATE, {
    input,
  });
};

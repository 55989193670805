import { Card, Flex, Form, Input, Button, Select, message } from '@fuxi/eevee-ui';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';

import service from '../../service';
import { serviceDetailPath } from '../../pages/onlineService/const';

const Release: React.FC = () => {
  const history = useNavigate();
  const [urlState] = useSearchParams();
  const versionId = urlState.get('versionId');

  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const [form] = Form.useForm();
  const urlParams = useParams();

  const { data: detail } = useRequest(() =>
    service.ability.getCapabilityDetails({
      id: urlParams.abilityId,
    })
  );

  const { data: versions } = useRequest(() =>
    service.ability.getCapabilityVersions({
      id: urlParams.abilityId,
    })
  );

  const handelSave = () => {
    form
      .validateFields()
      .then(async values => {
        setSubmitLoading(true);
        const { data } = await service.ability.releaseCapability({ ...values, projectId: urlParams.projectId });
        message.success('发布成功');
        // 跳转服务详情页
        history(`../../${serviceDetailPath}${data.id}`);
      })
      .catch(() => setSubmitLoading(false));
  };
  const cancel = () => {
    history(-1);
  };
  return (
    <Card bordered={false}>
      <Flex justifyCenter>
        <Form form={form} style={{ width: 600 }} initialValues={{}} labelCol={{ span: 6 }} autoComplete="off">
          <Form.Item label="能力名称：" rules={[{ required: true, message: '请选择基础能力' }]} initialValue={''}>
            <div>{detail?.data?.name}</div>
          </Form.Item>
          <Form.Item label="能力ID：" rules={[{ required: true, message: '请选择版本' }]}>
            <div>{detail?.data?.id}</div>
          </Form.Item>
          <Form.Item
            label="版本："
            name="capability_version_id"
            rules={[{ required: true, message: '请输选择版本' }]}
            initialValue={versionId}>
            {!!versionId ? (
              <>{versions?.data.list.find(item => item.id === versionId)?.name}</>
            ) : (
              <Select
                listHeight={128}
                placeholder="请选择"
                defaultValue={versionId}
                options={versions?.data.list.map(item => ({ value: item.id, label: item.name }))}
              />
            )}
          </Form.Item>
          <Form.Item label="服务名称：" name="name" rules={[{ required: true, message: '请输入服务名称' }]}>
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item label="接口地址：" name="domain" rules={[{ required: true, message: '请输入接口地址' }]}>
            <Input placeholder="请输入" addonAfter="snds.kdfjka.com" />
          </Form.Item>
          <Flex justifyCenter gapSM>
            <Button onClick={cancel}>取消</Button>
            <Button type="primary" onClick={handelSave} loading={submitLoading}>
              确定
            </Button>
          </Flex>
        </Form>
      </Flex>
    </Card>
  );
};

export default Release;

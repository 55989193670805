import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Flex, message, Space } from '@fuxi/eevee-ui';
import axios from '@/service/common';
import ProjectDetailContainer from '../ProjectDetailContainer';
import cx from './SourceCodeManage.module.less';

export const SourceCodeManage: React.FC = () => {
  const container = ProjectDetailContainer.useContainer();
  const { currentProject } = container;

  const downloadClientSdk = () => {
    axios
      .get(`/projects/${currentProject.id}/repository/client`, {
        responseType: 'blob',
      })
      .then(res => {
        message.info('正在下载..');
        let blob = new Blob([res.data], { type: 'application/octet-stream' });
        let url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'clientSDK.zip';
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(e => {
        message.error('项目不存在或无权限');
      });
  };

  const UrlItem: React.FC<{ name: keyof Project; label: string; downloadTrigger?: () => void }> = ({
    name,
    label,
    downloadTrigger,
  }) => {
    const url = (currentProject?.[name] as string) || '';
    return (
      <li className={cx('git-url')}>
        <Flex gapSM>
          {label}：{url || '无'}
          <span
            className={cx({
              copy: !!url,
              'copy-hidden': !url,
            })}>
            <Space>
              <CopyToClipboard text={url} onCopy={() => message.success('复制成功！')}>
                <Space>
                  <CopyOutlined className="copy-icon" />
                </Space>
              </CopyToClipboard>
              {/* {!!downloadTrigger && (
                <Space onClick={downloadClientSdk}>
                  <DownloadOutlined />
                  下载
                </Space>
              )} */}
            </Space>
          </span>
        </Flex>
      </li>
    );
  };

  return (
    <ul className={cx('url-list')}>
      <UrlItem name="clientRepoGitUrl" label="客户端代码仓库" downloadTrigger={downloadClientSdk} />
      <UrlItem name="customRepoGitUrl" label="自定义代码仓库" />
    </ul>
  );
};

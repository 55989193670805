export enum ServiceStatusType {
  RUNNING = 'RUNNING',
  PAUSE_RECEIVE = 'PAUSE_RECEIVE',
  PAUSE_CREATE = 'PAUSE_CREATE',
  PAUSE_FINISH = 'PAUSE_FINISH',
  ERROR = 'ERROR',
  STOP_ERROR = 'STOP_ERROR',
  UPDATE_ERROR = 'UPDATE_ERROR',
  PAUSE_ERROR = 'PAUSE_ERROR',
  RESTART_ERROR = 'RESTART_ERROR',
  INIT = 'INIT',
  CREATE = 'CREATE',
  UPDATE_RECEIVE = 'UPDATE_RECEIVE',
  UPDATE_CREATE = 'UPDATE_CREATE',
  RESTART_RECEIVE = 'RESTART_RECEIVE',
  RESTART_CREATE = 'RESTART_CREATE',
}
export interface OnlineServiceDetail {
  service_uuid: number;
  service_name: string;
  status: ServiceStatusType;
  updated_at: string;
  is_public: boolean;
  // domain: string;
  url: string;
}

export interface OnlineServiceInfo {
  capability_id: string;
  capability_name: string;
  capability_version: string;
  url: string;
  id: string;
  name: string;
  status: ServiceStatusType;
  description: string;
}

import { combineReducers } from '@reduxjs/toolkit';
import template from '@/store/template';
import projectReducer from './project';
import userReducer from './user';
import logReducer from './log';
import miniReviewReducer from './miniReview';
import terminalReducer from './terminal';
import imageReducer from './image';
import terminalLogReducer from './terminalLog';
import realtimeLogsReducer from './realtimeLogs';
import publishLogsReducer from './publishLogs';
import commonConfigReducer from './commonConfig';
import virtualTerminalReducer from './virtualTerminal';
import aiTrainingReducer from './aiTraining';
import consumeRecordReducer from './ConsumeRecord';
import mlCategoryReducer from './mlCategory';
import sshReducer from './ssh';

const rootReducer = combineReducers({
  project: projectReducer,
  user: userReducer,
  log: logReducer,
  miniReview: miniReviewReducer,
  template,
  terminal: terminalReducer,
  image: imageReducer,
  terminalLog: terminalLogReducer,
  realtimeLogs: realtimeLogsReducer,
  publishLog: publishLogsReducer,
  commonConfig: commonConfigReducer,
  virtualTerminal: virtualTerminalReducer,
  consumeRecord: consumeRecordReducer,
  aiTraining: aiTrainingReducer,
  mlCategory: mlCategoryReducer,
  ssh: sshReducer,
});

export default rootReducer;

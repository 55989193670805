import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Flex, Table, TableButtons } from '@fuxi/eevee-ui';
import { popup } from '@ai-training/utils/popup';

import { useAppSelector } from '@/hooks';
import { MlAddDatasetModal } from '../components/MlAddDatasetModal';
import cx from './index.module.less';

type DataType = {
  key: string;
  title: string;
};
interface Props {
  value?: DataType[];
  accept?: string;
  category: string[];
  onChange?: (value?: DataType[]) => void;
  canOpenDatasetModal: () => boolean;
  getProjectCategory: () => string[];
}

export const MlDataSelect: React.FC<Props> = ({
  category,
  canOpenDatasetModal,
  getProjectCategory,
  value,
  onChange,
}) => {
  const urlParams = useParams();
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const [dataList, setDataList] = useState<DataType[] | undefined>(value);

  useEffect(() => {
    setDataList([]);
  }, [...category]);
  const operation = {
    title: '操作',
    render: (_, record) => (
      <TableButtons
        data={[
          {
            text: '移除',
            type: 'danger',
            onClick: () => {
              handleRemove(record);
            },
          },
        ]}
      />
    ),
  };
  const handleRemove = record => {
    const index = dataList?.indexOf(record);
    index !== undefined && dataList?.splice(index, 1);
    const arrCopy = dataList?.slice();
    setDataList(arrCopy);
    onChange?.(arrCopy);
  };
  const handelOk = value => {
    onChange?.(value);
    setDataList(value);
  };

  const onDatasetSelected = e => {
    onChange?.(e);
    setDataList(e);
  };

  const dataSelectColumns = [
    {
      title: '数据集',
      dataIndex: 'name',
    },
    {
      title: '版本',
      dataIndex: 'version',
      render: () => 1,
    },
    {
      title: '数据量',
      dataIndex: 'data_count',
    },
  ];

  return (
    <div className="data-select">
      {!!dataList?.length ? (
        <Table
          childrenColumnName="off"
          columns={[...dataSelectColumns, operation]}
          dataSource={dataList}
          pagination={false}
          rowKey="dataset"
        />
      ) : (
        <div
          onClick={() => {
            if (!canOpenDatasetModal()) {
              return;
            }
            const categories = getProjectCategory();
            popup({
              Component: () => (
                <MlAddDatasetModal categories={categories} onOk={onDatasetSelected} aiProjectId={aiProjectId} />
              ),
              onOk: handelOk,
              payload: { defaultTargetKeys: dataList?.map(item => item.key), projectId: urlParams.projectId },
            });
          }}>
          <Flex alignCenter justifyCenter className={cx('dataset-placeholder')}>
            <Flex justifyCenter alignCenter flexDirection="column">
              <PlusOutlined className={cx('add-icon')} />
              <span>暂无数据</span>
            </Flex>
          </Flex>
        </div>
      )}
    </div>
  );
};

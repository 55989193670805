import { message } from '@fuxi/eevee-ui';
import _ from 'lodash';
import axios from '@/service/common';

export const handleDownloadClient = async (id?: number, version?: string) => {
  const params = version ? { version } : {};
  await axios
    .get(`/projects/${id}/repository/client-sample`, {
      responseType: 'blob',
      params,
    })
    .then(res => {
      let blob = new Blob([res.data], { type: 'application/octet-stream' });
      let url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'sampleClient.zip';
      link.click();
      URL.revokeObjectURL(url);
      message.success('下载成功');
    })
    .catch(async error => {
      const responseObj = await error.response.data.text();
      const msg = _.get(JSON.parse(responseObj), 'error.response.message', '项目不存在或无权限');
      message.error(msg);
    });
};

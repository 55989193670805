import { useParams } from 'react-router-dom';
import { Card, Flex, Spin } from '@fuxi/eevee-ui';
import React, { useEffect } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';

import { DefaultAiProjectId } from '@ai-training/utils';

import { useAppSelector } from '@/hooks';

import service from '../../service';
import Descriptions from '../../components/Descriptions';

import { publicVersionDetailInfoColumns } from './const';
import PublicInputOutput from './components/PublicInputOutput/index';

import cx from './PublicVersionDetail.module.less';

const VersionDetail: React.FC = () => {
  const urlParams = useParams();
  const underProject = urlParams?.currentProjectId;
  const aiProjectId = useAppSelector(
    state => state.project.currentMlProject?.aiProjectId || state.project.currentProject?.aiProjectId
  );

  const {
    data: detail,
    loading,
    run,
  } = useRequest(
    () =>
      service.ability.getVersionsDetails({
        // project_id: urlParams.projectId,
        capability_uuid: urlParams.abilityId,
        capability_version_uuid: urlParams.versionId,
      }),
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (aiProjectId && urlParams.abilityId && urlParams.versionId) {
      window[DefaultAiProjectId] = aiProjectId;
      run();
    }
  }, [urlParams, aiProjectId]);

  if (detail?.data) {
    detail.data.id = urlParams.versionId;
  }
  return (
    <Spin spinning={loading}>
      {underProject ? (
        <Flex className={cx.wrapper_under_project} column>
          <Card className={cx.card_wrapper} bordered={false}>
            <Descriptions
              column={4}
              title="版本详情"
              items={publicVersionDetailInfoColumns}
              data={detail?.data || {}}
            />
          </Card>
          <Card className={cx.card_wrapper} bordered={false}>
            <Descriptions column={1} title="输入与输出信息" items={[]} data={detail?.data} />
            <PublicInputOutput
              loading={loading}
              height={336}
              input={detail?.data?.inputs}
              output={detail?.data?.outputs}
            />
          </Card>
        </Flex>
      ) : (
        <Flex gapLG column>
          <Card bordered={false}>
            <Descriptions
              column={4}
              title="版本详情"
              items={publicVersionDetailInfoColumns}
              data={detail?.data || {}}
            />
          </Card>

          <Card bordered={false} className={cx.output_wrapper}>
            <Descriptions column={1} title="输入与输出信息" items={[]} data={detail?.data} />
            <PublicInputOutput
              loading={loading}
              height={336}
              input={detail?.data?.inputs}
              output={detail?.data?.outputs}
            />
          </Card>
        </Flex>
      )}
    </Spin>
  );
};

export default VersionDetail;

import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { useAntdTable, useRequest } from '@fuxi/eevee-hooks';
import { Card, Flex, Table, Divider, Tabs, Dropdown, Space, Button, message } from '@fuxi/eevee-ui';

import service from '../../service';
import { VersionInfo, ProjectInfo } from '../../types/dataSet';
import { getSorterTableData } from '../../utils/util';
import Descriptions from '../../components/Descriptions';
import InputOutput from '../../components/InputOutput';
import { getProjectVersionDetailColumns, versionDetailInfoColumns } from './const';

import cx from './index.module.less';

const VersionDetail: React.FC = () => {
  const urlParams = useParams();
  const [urlState] = useSearchParams();
  const datasetName = urlState.get('datasetName');
  const desc = urlState.get('desc');
  const [detail, setDetail] = useState<VersionInfo | null>(null);
  const [projectDetail, setProjectDetail] = useState<ProjectInfo | null>(null);
  const [selectedRows, setSelectedRows] = useState([]);

  useRequest(
    () =>
      service.dataSet.getDatasetsDetails({
        dataset_id: urlParams.dataId || '',
        is_common: false,
      }),
    {
      onSuccess: data => {
        setProjectDetail(data.data);
      },
    }
  );

  useRequest(
    () =>
      service.dataSet.getVersionsDetails({
        dataset_version: urlParams.versionId || '',
        dataset_id: urlParams.dataId || '',
      }),
    {
      onSuccess: data => {
        setDetail(data.data);
      },
    }
  );

  const { tableProps, refresh, data } = useAntdTable(
    getSorterTableData(service.dataSet.getVersionsFileList, {
      extraParams: {
        id: urlParams.versionId,
        datasetsId: urlParams.dataId,
        project_id: urlParams.projectId,
        dataset_name: datasetName,
      },
    })
  );

  const onMenuClick = () => {
    Promise.all(
      selectedRows.map((row: any) =>
        service.dataSet
          .downloadfile({
            dataset_id: urlParams.dataId || '',
            dataset_version_id: urlParams.versionId || '',
            key: row.key || '',
          })
          .then(async res => {
            if (res.status === 200) {
              const a = document.createElement('a');
              const url = res?.data?.url?.replace('http://', 'https://');
              a.href = url;
              a.download = row.name;
              a.click();
              return Object.assign({}, row, { res: true });
            } else {
              return Object.assign({}, row, { res: false });
            }
          })
      )
    ).then(ress => {
      if (ress.find(res => !res.res)) {
        message.error('下载初始化失败');
      } else {
        message.success('开始下载');
      }
    });
  };

  const onTableSelectChange = (_selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  };

  return (
    <Flex column className={cx['detail-page-wrapper']}>
      <Card title="版本详情" className={cx['detail-head']}>
        <Descriptions
          column={3}
          items={[
            ...versionDetailInfoColumns,
            {
              label: '文件数',
              dataIndex: 'file_count',
              render: () => data?.total,
            },
          ]}
          data={Object.assign({}, detail, { desc }) || {}}></Descriptions>
      </Card>
      <Divider className={cx['detail-divider']} />
      <div className={cx['detail-body-tab']}>
        <Tabs
          tabBarStyle={{ marginBottom: 24 }}
          items={[
            {
              key: 'Datastruct',
              label: '数据结构',
              children: <InputOutput input={projectDetail?.data_fields} output={projectDetail?.label_fields} />,
            },
            {
              key: 'DataSetFile',
              label: '数据文件',
              children: (
                <>
                  <Space className={cx['ctrl-dropdown-wrapper']}>
                    <Dropdown
                      disabled={selectedRows.length === 0}
                      trigger={['click']}
                      menu={{
                        items: [
                          {
                            label: '批量下载',
                            key: 'download',
                          },
                        ],
                        onClick: onMenuClick,
                      }}>
                      <Button>
                        <Space>
                          批量处理
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                    <span>{`已选 ${selectedRows?.length} 项`}</span>
                  </Space>
                  <Table
                    columns={getProjectVersionDetailColumns({
                      refresh,
                      dataId: urlParams.dataId,
                      versionId: urlParams.versionId,
                      datasetName: detail?.dataset_name,
                      projectId: urlParams.projectId,
                    })}
                    {...tableProps}
                    rowKey="key"
                    rowSelection={{ type: 'checkbox', onChange: onTableSelectChange }}
                    pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
                  />
                </>
              ),
            },
          ]}
        />
      </div>
    </Flex>
  );
};

export default VersionDetail;

import React from 'react';
import escapeStringRegexp from 'escape-string-regexp';

interface HighlightedFieldProps {
  text: string;
  field: string | string[];
  flags?: string;
  style?: React.CSSProperties;
}

const includes = (arr: string[], i: string) => arr.some(j => j.toLowerCase() === escapeStringRegexp(i).toLowerCase());

const HighlightedField = (props: HighlightedFieldProps) => {
  const { text, field, style, flags = 'gi' } = props;
  const escapedFields = ([] as string[]).concat(field).map(escapeStringRegexp);

  if (!field || !field.length) return <>{text}</>;
  const reg = new RegExp(`(${escapedFields.join('|')})`, flags);

  return (
    <>
      {text.split(reg).map((item, index) => {
        if (includes(escapedFields, item)) {
          return (
            <span key={index} className="highlighted-field" style={style}>
              {item}
            </span>
          );
        } else {
          return <span key={index}>{item}</span>;
        }
      })}
    </>
  );
};

export default HighlightedField;

import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Card, Flex, Table, Divider, Spin, Tabs, TabsProps } from '@fuxi/eevee-ui';
import { useAntdTable, useRequest } from '@fuxi/eevee-hooks';
import { LogType } from '@ai-training/types/log';

import { log } from '@ai-training/service/log';

import { ModelServiceLog } from '@/pages/ml/ModalServiceDetail/ModelServiceLog';

import service from '../../service';
import { getSorterTableData } from '../../utils/util';
import Descriptions from '../../components/Descriptions';
import { taskDetailColumns, getTrainingDataColumns } from './const';
import cx from './index.module.less';
import { MonocaEditorModal } from './components/MonacoEditorModal';

const TaskDetail: React.FC = () => {
  const urlParams = useParams();
  const history = useNavigate();
  const [searchParams] = useSearchParams();
  const [hyperParams, setHyperParams] = React.useState<string | undefined>(undefined);

  const fetchHyperParams = async (capVersionId: string) => {
    return service.ability
      .getCapabilityDefaultHyperParams({
        capability_version_uuid: capVersionId,
      })
      .then(res => res.data);
  };

  const [fetchHyperParamsLoading, setFetchHyperParamsLoading] = React.useState<boolean>(false);

  const { data: detail, run } = useRequest(() =>
    service.trainingTask.taskDetails({
      task_id: urlParams.taskId || '',
    })
  );

  const { data: podsInfo, run: getPodsInfo } = useRequest(
    (danlu_id: string) =>
      service.trainingTask.taskPodsInfo({
        projectId: urlParams.currentProjectId!,
        type: LogType.Training,
        uuid: danlu_id!,
      }),
    {
      manual: true,
    }
  );

  const { data: realtimeLogUrl, run: getRealtimeTrainingTaskLogUrl } = useRequest(
    (danlu_id: string, podName: string, containerName: string) =>
      log.logUrl({
        projectId: urlParams.currentProjectId!,
        taskId: danlu_id!,
        taskType: LogType.Training,
        podName,
        containerName,
        useWs: true,
      }),
    {
      manual: true,
    }
  );

  const { data: historyLogUrl, run: getHistoryTrainingTaskLogUrl } = useRequest(
    (danlu_id: string, podName: string, containerName: string) =>
      log.logUrl({
        projectId: urlParams.currentProjectId!,
        taskId: danlu_id!,
        taskType: LogType.Training,
        podName,
        containerName,
        useWs: false,
      }),
    {
      manual: true,
    }
  );

  // useEffect(() => {
  // }, [trainingTaskLogUrl]);

  // const { tableProps, refresh } = useAntdTable(
  //   getSorterTableData(service.trainingTask.taskDatasets, { extraParams: { id: urlParams.taskId } })
  // );

  console.log(detail, 'detail');

  const dataSet = useEffect(() => {
    const danluId = detail?.data?.danlu_id;
    if (!!danluId) {
      getPodsInfo(danluId);
    }
  }, [detail]);

  useEffect(() => {
    if (!!podsInfo?.data?.pods?.length) {
      const pod = podsInfo.data.pods[0];
      const podName = pod.name;
      const containerName = pod.containerList?.[0]?.name;
      getRealtimeTrainingTaskLogUrl(detail?.data?.danlu_id, podName, containerName);
      getHistoryTrainingTaskLogUrl(detail?.data?.danlu_id, podName, containerName);
    }
  }, [podsInfo]);

  useEffect(() => {
    const capVersionId = detail?.data?.capability_version_id;
    if (!capVersionId) return;
    setFetchHyperParamsLoading(true);
    fetchHyperParams(capVersionId)
      .then(res => {
        if (res) {
          setHyperParams(res);
          setFetchHyperParamsLoading(false);
        }
      })
      .catch(() => {
        setFetchHyperParamsLoading(false);
      })
      .finally(() => {
        setFetchHyperParamsLoading(false);
      });
  }, [detail?.data?.capability_version_id]);

  const getTaskDetailColumns = () => {
    const baseDesc = taskDetailColumns;
    if (!isEmpty(hyperParams)) {
      return [
        ...taskDetailColumns.slice(0, taskDetailColumns.length - 1),
        {
          label: '超级参数',
          dataIndex: 'hyperParameters',
          render: data => {
            return (
              <MonocaEditorModal
                value={data.hyperParams}
                children={
                  <div
                    style={{
                      cursor: 'pointer',
                      color: '#4071F9',
                    }}>
                    查看详情
                  </div>
                }></MonocaEditorModal>
            );
          },
        },
        taskDetailColumns[taskDetailColumns.length - 1],
      ];
    } else {
      return baseDesc;
    }
  };

  const tabs: TabsProps['items'] = [
    {
      key: 'trainingLog',
      label: '训练日志',
      children: (
        <ModelServiceLog
          trainingStatus={detail?.data?.status}
          realtimeLogUrl={realtimeLogUrl?.data?.url}
          historyLogUrl={historyLogUrl?.data?.url}
          podSelectable={false}
          podNameList={['aaa']}
          historyDuration={7}
        />
      ),
    },
    {
      key: 'trainingData',
      label: '训练数据集',
      children: (
        <Table
          dataSource={detail?.data?.dataset}
          columns={getTrainingDataColumns({ history })}
          // {...tableProps}
          rowKey="id"
          // pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
        />
      ),
    },
  ];

  return (
    <Flex column className={cx['detail-page-wrapper']}>
      <Card title="训练任务信息" className={cx['detail-head']}>
        <Spin spinning={fetchHyperParamsLoading}>
          <Descriptions column={3} items={getTaskDetailColumns()} data={{ ...detail?.data, hyperParams } || {}} />
        </Spin>
      </Card>
      <Divider className={cx['detail-divider']} />
      <Tabs className={cx['tab']} items={tabs}></Tabs>
    </Flex>
  );
};

export default TaskDetail;

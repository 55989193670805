/* eslint-disable react-func/max-lines-per-function */
/**
 * 日志数据
 * 包含：实时日志、历史日志、发布日志
 */
import { v4 as uuid } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
// import { throttle } from 'lodash-es';

import { LogType, LogLevel } from '@/constants';
import { AppThunk } from '@/store/index';
import { createWebsocketInstance } from '@/service/socket';
import { getHistoryLog, getPublishLogs } from '@/service/log';
import { isJson } from '@/utils';

export interface HistoryLogOptions {
  namespace: string;
  start: string;
  end: string;
  page?: number;
  search?: string;
  level?: string[];
}

export interface PublishLogs {
  serviceId: number;
  logId: number;
  startTime: string;
  endTime: string;
  page?: number;
  size?: number;
}

export type PublishLogItem = {
  id: number;
  msg: string;
  serviceId: number;
  level: string;
  dt: string;
  traceback: string;
};

export type LogItem = {
  uid: string;
  // 日志内容
  content: string;
  // 日志时间
  createdAt: string;
  // 日志类型
  level: LogLevel;
  // 日志回溯
  traceback?: string;
  // 是否在日志末尾处显示等待中动画
  pending?: boolean;
  // 日志是否可被清除
  permanent?: boolean;
  // 日志记录角色,分为平台和引擎
  role?: string;
};

export type Log = {
  historyLogPage: number;
  historyLogCount: number;
  [LogType.Log]: LogItem[];
  [LogType.HistoryLog]: LogItem[];
  [LogType.PublishLog]: LogItem[];
};

const getFormattedLogs = (data: Partial<LogItem>[] | Partial<LogItem>): LogItem[] => {
  const logs = ([] as Partial<LogItem>[]).concat(data);

  return logs.map(log => {
    const data = { ...log };
    const { uid, level, createdAt } = data;
    data.uid = uid || uuid();
    data.level = level || LogLevel.Info;
    data.createdAt = createdAt || moment().format('YYYY-MM-DD HH:mm:ss');

    return data as LogItem;
  });
};

// const MAX_LOG_COUNT = 3000;

const initialState: Record<string, Log> = {};
const initialServiceData = () => ({
  historyLogPage: 0,
  historyLogCount: 0,
  [LogType.Log]: [],
  [LogType.HistoryLog]: [],
  [LogType.PublishLog]: [],
});

const log = createSlice({
  name: 'log',
  initialState,
  reducers: {
    initLog(state, { payload }: PayloadAction<string>) {
      state[payload] = initialServiceData();
    },
    setLog(state, { payload }: PayloadAction<{ serviceId: string; type: LogType; data: Partial<LogItem>[] }>) {
      const { serviceId, type, data } = payload;
      const logs = getFormattedLogs(data as Partial<LogItem>[]);

      state[serviceId][type] = logs;
    },
    updateLog(
      state,
      { payload }: PayloadAction<{ serviceId: string; type: LogType; data: Partial<LogItem>[] | Partial<LogItem> }>
    ) {
      const { serviceId, type, data } = payload;
      const logs = getFormattedLogs(data as Partial<LogItem>[] | Partial<LogItem>);

      if (!state[serviceId]) {
        state[serviceId] = initialServiceData();
      }

      state[serviceId][type] = state[serviceId][type].concat(logs);
    },
    setHistotyLogData(
      state,
      { payload }: PayloadAction<{ serviceId: string; page: number; total: number; data: Partial<LogItem>[] }>
    ) {
      const { serviceId, page, total, data = [] } = payload;
      const logs = getFormattedLogs(data as Partial<LogItem>[]);

      state[serviceId].historyLogPage = page;
      state[serviceId].historyLogCount = total;

      if (page === 1) {
        state[serviceId].historyLog = logs;
      } else {
        state[serviceId].historyLog = state[serviceId].historyLog.concat(logs);
      }
    },
    setPublishLog(
      state,
      { payload }: PayloadAction<{ serviceId: string; type: LogType; page: number; data: Partial<LogItem>[] }>
    ) {
      const { serviceId, type, data, page } = payload;
      const logs = getFormattedLogs(data as Partial<LogItem>[]);
      page === 1 ? (state[serviceId][type] = logs) : (state[serviceId][type] = state[serviceId][type].concat(logs));
    },
  },
});

export const { initLog, setLog, updateLog, setHistotyLogData, setPublishLog } = log.actions;

export const toGetPersonalServiceLogs =
  (serviceId: string, taskId: string): AppThunk =>
  (dispatch, getState) => {
    let timeoutId = 0;
    const timeout = 300;
    const maxCount = 400;
    const logArr: Partial<LogItem>[] = [];

    const toUpdateLog = () => {
      const { log } = getState();
      const logs = log[serviceId][LogType.Log];

      if (logArr.length) {
        if (logs.length < maxCount) {
          dispatch(updateLog({ type: LogType.Log, serviceId, data: logArr.splice(0, 10) }));
        } else {
          dispatch(setLog({ type: LogType.Log, serviceId, data: [...logs, ...logArr.splice(0)].splice(-maxCount) }));
        }
      }

      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(toUpdateLog, timeout);
    };

    createWebsocketInstance(`/api-ws/robot-paas/log?projectId=${taskId}&taskId=${taskId}`, {
      onMessage: res => {
        const { data } = res;

        if (isJson(data)) {
          const { dt, level, msg, traceback } = JSON.parse(data);
          // 应用输出日志中一定包含 dt 字段，以此判断是否是标准日志输出
          if (!dt) return;
          const logData = {
            uid: uuid(),
            level,
            content: msg,
            createdAt: dt,
            traceback,
          };
          logArr.push(logData);

          if (!timeoutId) {
            timeoutId = window.setTimeout(toUpdateLog, timeout);
          }
        }
      },
    });
  };

export const toGetHistoryLogs =
  (serviceId: string, options: HistoryLogOptions): AppThunk =>
  async (dispatch, getState) => {
    const { log } = getState();
    const { historyLogPage } = log[serviceId];
    const page = options.page || historyLogPage + 1;
    const res = await getHistoryLog({
      ...options,
      page,
    });

    if (!res) return;

    const {
      logSearch: { total = 0, data = [] },
    } = res;
    const logs = data.map((item: any) => {
      const { dt, level, msg, traceback } = item.log;

      return {
        uid: uuid(),
        level,
        content: msg,
        createdAt: dt,
        traceback,
      };
    });

    dispatch(setHistotyLogData({ serviceId, page, total: +total, data: logs }));
  };

export const toGetPublishLogs =
  (serviceId: number, startTime?: string, endTime?: string): AppThunk =>
  async (dispatch, getState) => {
    const maxSize = 10;
    let page = 1;
    let publishLogLengthOnce = 0;
    do {
      const res = await getPublishLogs(
        JSON.stringify({
          serviceId,
          startTime,
          endTime,
          logId: 0,
          page,
          size: maxSize,
        })
      );
      publishLogLengthOnce = res.publishLog.length;
      dispatch(
        setPublishLog({
          serviceId: serviceId + '',
          type: LogType.PublishLog,
          page,
          data: res.publishLog.map(
            log =>
              ({
                uid: log.id + '',
                content: log.msg,
                createdAt: log.dt,
                level: log.level,
                serviceId: log.serviceId,
                traceback: log.traceback,
              } as LogItem)
          ),
        })
      );
      page++;
    } while (publishLogLengthOnce === maxSize);
  };

export default log.reducer;

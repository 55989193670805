export const getStrLen = (value: string) => {
  if (!value) return 0;
  let len = 0;
  for (let i = 0; i < value.length; i++) {
    if (value.charCodeAt(i) > 127 || value.charCodeAt(i) === 94) {
      len += 1;
    } else {
      len += 0.5;
    }
  }
  return Math.floor(len);
};

export const formatStrWithEllipsis = (str: string, len: number) => {
  if (!str) return '';

  if (str.length > len) {
    return `${str.slice(0, len)}...`;
  }

  return str;
};

export const isDoubleSpacesReg = /\s{2,}/; // 禁止输入两个连续空格

export const countFormat = (count: number) => {
  if (count < 10000) return count;

  return `${parseFloat((count / 10000).toFixed(1))}w`;
};

// 转换文件大小
export const getSize = size => {
  switch (true) {
    case size < 1024:
      return size.toFixed(2) + 'B';
    case size < 1048576:
      return (size / 1024).toFixed(2) + 'K';
    case size < 1073741824:
      return (size / (1024 * 1024)).toFixed(2) + 'M';
    case size < 1024 * 1024 * 1024 * 1024:
      return (size / (1024 * 1024 * 1024)).toFixed(2) + 'G';
    default:
      break;
  }
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { CrumbRoute } from '@/router';
import { RoutePathname } from '@/constants/global';
import Breadcrumb from '../Breadcrumb';
import cx from './index.module.less';

interface ContainerProps {
  title?: string;
  crumbs?: CrumbRoute[];
  notInPageContainer?: boolean;
  children?: React.ReactNode;
}

const PageContainer = (props: ContainerProps) => {
  const { children, notInPageContainer, ...rest } = props;
  const location = useLocation();
  const isMainPage = [RoutePathname.project, RoutePathname.publicAgent].includes(location.pathname);
  const isMlPage = location.pathname.includes(RoutePathname.ml);
  const underProject = !isMainPage && location.pathname.includes(RoutePathname.project);
  const underReview = location.pathname.includes(RoutePathname.review);

  return (
    <>
      {notInPageContainer ? (
        children
      ) : (
        <div
          className={
            underProject ? cx('under-project-page-container') : underReview ? cx('review') : cx('page-container')
          }>
          {!isMlPage && (
            <div className={cx('page-header')}>
              <Breadcrumb length={2} />
            </div>
          )}
          <div
            className={
              isMlPage
                ? cx('ml-page-content')
                : isMainPage
                ? cx('main-page-content')
                : underProject
                ? cx('under-project-page-content')
                : cx('page-content')
            }>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default PageContainer;

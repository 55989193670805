import { LogType } from '@ai-training/types/log';

import { WebInstance } from './common';

export const log = {
  // 查询日志链接
  logUrl(params: {
    projectId: string;
    taskId: string;
    taskType: LogType;
    podName: string;
    containerName: string;
    useWs: boolean;
  }) {
    return WebInstance.get('/ai/log/addr', { params });
  },
};
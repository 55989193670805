import { useEffect } from 'react';
import { message } from '@fuxi/eevee-ui';
import { useMutation } from '@apollo/client';

import { ServerType, LogType } from '@/constants';
import { useAppDispatch } from '@/hooks';
import { updateLog } from '@/store/log';
import { ServiceStatus } from '@/typings/common';
import {
  DSIABLE_PERSONAL_SERVICE,
  ENABLE_PERSONAL_SERVICE,
  RESTORE_SERVICE,
} from '@/service/schema/service/service-mutation';
import { syncSSHKey } from '@/service/ssh';

import ProjectDetailContainer from './ProjectDetailContainer';

const getUsername = (name: string = '') => (name.split('urs.').pop() || '').split('@')[0];

export const useServiceOperation = (service: Service) => {
  const dispatch = useAppDispatch();
  const container = ProjectDetailContainer.useContainer();
  const { currentProject, userInfo, getServicesData } = container;
  const projectId = currentProject.id;
  const { id, creator, type, status } = service;
  const [restoreServer, { data, loading: restoringServer }] = useMutation(RESTORE_SERVICE);
  const [enableServer, { data: enableServerRes, loading: enablingServer }] = useMutation(ENABLE_PERSONAL_SERVICE);
  const [disableServer, { data: disableServerRes, loading: disablingServer }] = useMutation(DSIABLE_PERSONAL_SERVICE);

  const isMainServer = type === ServerType.Main;
  const isInitError = status === ServiceStatus.InitError;

  // 项目创建者
  const isProjectCreator = getUsername(currentProject.creator) === getUsername(userInfo.name);
  // 私服拥有者
  const isServiceCreator = getUsername(creator) === getUsername(userInfo.name);

  // 可以启用私服
  const canStartPersonalService = status === ServiceStatus.UnStart;

  // 可以禁用私服
  const canDisablePersonalService = (
    [ServiceStatus.Running, ServiceStatus.Pending, ServiceStatus.Error] as string[]
  ).includes(status);

  // 可以发布主干
  const canPublishService =
    isProjectCreator &&
    [ServiceStatus.Running, ServiceStatus.Error, ServiceStatus.AuditFial, ServiceStatus.Pending].includes(
      status as ServiceStatus
    );

  // 可以进行修复操作
  let canRestoreService = isInitError;
  if (isMainServer) {
    // 主干时，仅对项目创建者在一定条件下激活
    canRestoreService = isProjectCreator && canRestoreService;
  } else {
    // 私服时，仅对私服所有者在一定条件下激活
    canRestoreService = isServiceCreator && canRestoreService;
  }

  const handleRestoreService = async () => {
    await restoreServer({ variables: { projectId, serviceId: id } });
    await getServicesData({ variables: { projectId: currentProject.id } });
  };

  const handleEnableService = async () => {
    await enableServer({ variables: { serviceId: id } });
    await getServicesData({ variables: { projectId: currentProject.id } });
  };

  const handleDisableService = async () => {
    await disableServer({ variables: { serviceId: id } });
    await getServicesData({ variables: { projectId: currentProject.id } });
  };

  function toSyncSSHKey() {
    syncSSHKey(projectId, id).then(() => {
      message.success('同步公钥成功');
    });
  }

  useEffect(() => {
    if (data) {
      message.success('私服修复成功，正在启动中，请稍后刷新查看状态');
      dispatch(
        updateLog({
          serviceId: id,
          type: LogType.PublishLog,
          data: { content: '私服修复成功, 正在启动中...', pending: true },
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (enableServerRes) {
      message.success('私服启用成功，正在启动中，请稍后刷新查看状态');
      dispatch(
        updateLog({
          serviceId: id,
          type: LogType.PublishLog,
          data: { content: '私服启用成功, 正在启动中...', pending: true },
        })
      );
      getServicesData({ variables: { projectId } });
    }
  }, [enableServerRes]);

  useEffect(() => {
    if (disableServerRes) {
      message.success('私服禁用成功，正在禁用中，请稍后刷新查看状态');
      dispatch(
        updateLog({
          serviceId: id,
          type: LogType.PublishLog,
          data: { content: '私服禁用成功, 正在禁用中...', pending: true },
        })
      );
      getServicesData({ variables: { projectId } });
    }
  }, [disableServerRes]);

  return {
    isMainServer,
    restoringServer,
    enablingServer,
    disablingServer,
    isProjectCreator,
    isServiceCreator,
    canPublishService,
    canRestoreService,
    canStartPersonalService,
    canDisablePersonalService,
    handleRestoreService,
    handleEnableService,
    handleDisableService,
    toSyncSSHKey,
  };
};

import React from 'react';

import cx from './index.module.less';

interface TagProps {
  value: string;
  color: string;
  borderColor: string;
}

export const AbilityTag = (props: TagProps) => {
  const { value, color, borderColor } = props;
  return (
    <div
      className={cx['tag']}
      style={{
        color,
        borderColor,
      }}>
      {value}
    </div>
  );
};

export type PublicAbilityInList = {
  name: string;
  uuid: string;
  upadtedAt: string;
  description: string;
  category_uuid: string;
  image_url: string;
}

export type BaseCapability = {
  id: string;
  name: string;
  version: string;
  versionId: string;
}

export type PublicAbilityDetail = {
  imgUrl: string;
  categoryUuid: string;
  uuid: string;
  name: string;
  versions: number;
  updateAt: string;
  createAt: string;
  projectId: number;
  domainSuffix: string;
  desc: string;
  baseCapability: BaseCapability;
  creator: string;
  share: boolean;
}

export type AbilityVersion = {
  uuid: string;
  capability_name: string;
  capability_uuid: string;
  code: string;
  create_by: string;
  created_at: string;
  delay: number;
  description: string;
  name: string;
  performance: number;
  source_version_uuid: string;
  tps: number;
  train_dataset_name: string;
  updated_at: string;
}

export type MlProjectDetail = {
  uuid: string;
  code: string;
  name: string;
  project_id: number;
  description: string;
  inputs: string;
  outputs: string;
  share: boolean;
  version_num: number;
  category_uuid: string;
  first_category: string;
  second_category: string;
  git_url: string;
  image_url: string;
  markdown: string;
  created_at: string;
  updated_at: string;
}


export type MlProjectVersionDetail = {
  uuid: string;
  name: string;
  train_dataset_name: string;
  source_version_uuid: string;
  train_dataset_id: string;
  description: string;
  inputs: string;
  outputs: string;
  code: string;
  created_at: string;
  updated_at: string;
}

export type MlTrainingTaskDetail = {
  train_id: string;
  task_id: string;
  capabiltiy_id: string;
  capability_name: string;
  capability_version: number;
  capability_version_id: string;

  created_at: string;
  create_by: string;
  start_at: string;
  finish_at: string;


  duration: number;
  status: string;
}


export enum MonitorType {
  TPS = 'kong_tps',
  RES_CODE = 'kong_response_code',
  LATENCY = 'kong_response_latency',
}

export const MonitorCategorys = {
  [MonitorType.TPS]: ['tps'],
  [MonitorType.RES_CODE]: ['2xx', '3xx', '4xx', '5xx'],
  [MonitorType.LATENCY]: ['50百分点', '95百分点', '99百分点'],
}
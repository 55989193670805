import { useState, useEffect } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Form, Input, Select, Button, message, Space, Radio } from '@fuxi/eevee-ui';

import service from '../../service';

const CreateAbility: React.FC = () => {
  const history = useNavigate();
  const urlParams = useParams();

  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const [isExtend, setIsExtend] = useState<boolean>(true);
  const { data: versions, run: versionsRun } = useRequest(service.ability.getCapabilityVersions, {
    manual: true,
  });
  const { data: capability } = useRequest(() => service.ability.getCapability({ share: true }));

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldValue('is_extend', true);
  }, []);

  const handelSave = () => {
    form
      .validateFields()
      .then(async vals => {
        setSubmitLoading(true);
        const { is_extend, ...values } = vals;
        if (isExtend) {
          const currentCapability = capability?.data.list.find(item => item.id === values.base_capability.id);
          // values.base_capability.share = currentCapability.share;
        }
        const { data } = await service.ability.createCapability(values);
        message.success('创建成功');
        history('../');
      })
      .catch((e) => {
        setSubmitLoading(false);
      });
  };
  const cancel = () => {
    history(-1);
  };
  const handelAbilityChange = val => {
    form.setFieldsValue({
      base_capability: {
        version_id: null,
      },
    });
    versionsRun({ capability_uuid: val });
  };
  return (
    <Flex justifyCenter style={{ marginTop: 40 }}>
      <Form
        form={form}
        labelAlign="right"
        autoComplete="off"
        initialValues={{}}
        style={{ width: 490 }}
        labelCol={{ span: 6 }}>
        <Form.Item label="能力来源" required name={['is_extend']}>
          <Radio.Group
            style={{ backgroundColor: 'transparent' }}
            defaultValue={true}
            onChange={event => {
              setIsExtend(event.target.value);
            }}
            options={[
              { label: '继承公共能力', value: true },
              { label: '自定义创建', value: false },
            ]}
          />
        </Form.Item>
        {isExtend ? (
          <>
            <Form.Item
              label="基础能力"
              name={['base_capability', 'id']}
              rules={isExtend ? [{ required: true, message: '请选择基础能力' }] : undefined}>
              <Select
                showSearch
                listHeight={128}
                placeholder="请选择"
                optionFilterProp="label"
                onChange={handelAbilityChange}
                options={capability?.data?.list?.map(item => ({ value: item.uuid, label: item.name }))}
              />
            </Form.Item>
            <Form.Item
              label="版本"
              name={['base_capability', 'version_id']}
              rules={isExtend ? [{ required: true, message: '请选择版本' }] : undefined}>
              <Select
                showSearch
                listHeight={128}
                placeholder="请选择"
                optionFilterProp="label"
                options={versions?.data.list.map(item => ({ value: item.uuid, label: item.code }))}
              />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
        <Form.Item
          label="能力名称"
          name="name"
          tooltip="说明：只能输入小写字母、数字和下划线；"
          rules={[
            { required: true, message: '能力名称不得为空' },
            { pattern: /^[a-z][a-z0-9_]*$/, message: '限制为小写字母、数字和下划线，且必须由小写字母开头' },
          ]}>
          <Input placeholder="请输入" maxLength={16} showCount />
        </Form.Item>
        <Form.Item label="描述" name="desc" initialValue={''}>
          <Input.TextArea placeholder="请输入" rows={4} maxLength={50} showCount />
        </Form.Item>
        <Space direction="horizontal" size={16}>
          <div />
        </Space>
        <Form.Item colon={false} label=" ">
          <Space>
            <Button onClick={cancel}>取消</Button>
            <Button type="primary" onClick={handelSave} loading={submitLoading}>
              确定
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Flex>
  );
};

export default CreateAbility;

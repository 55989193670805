import React from 'react';
import { Card, Col, Pagination, Row, TablePaginationConfig, Image, Empty, Tooltip, Flex, Icon } from '@fuxi/eevee-ui';
import { slice } from 'lodash-es';
import { sliceByPagination } from '@/utils/sliceByPagination';
import ProjectImg from '@/assets/image/project-in-list.png';
import IconFont from '@/components/IconFont';
import { ProjectStatus } from '@/constants';
import { isTemplateProject } from '../utils';
import { openVisualWebsite } from '../const';
import cx from './ProjectList.module.less';
import { BankOutlined } from '@ant-design/icons';
import { CreateProjectType } from '@/constants/ownership';

type ProjectCardProps = {
  pagination: TablePaginationConfig;
  handlePaginationChange: (page: number, pageSize?: number) => void;
  projectList: Project[];
  total: number;
  editProject: (item: Project) => void;
  deleteProject: (item: Project) => void;
  handleTurn2Detail: (item: Project) => void;
  paginationProps: TablePaginationConfig;
};

const colLayout = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 8,
  xl: 6,
};

const ProjectCard: React.FC<ProjectCardProps> = props => {
  const { pagination, projectList, total, paginationProps, handlePaginationChange, handleTurn2Detail } = props;
  return (
    <>
      {total !== 0 && (
        <>
          <Row gutter={[24, 24]} className={cx('card-wrapper')}>
            {slice(projectList, sliceByPagination(pagination).start, sliceByPagination(pagination).end).map(item => {
              return (
                <Col style={{ position: 'relative' }} key={item.id} {...colLayout}>
                  <Card className={cx('project-card')} onClick={() => handleTurn2Detail(item)}>
                    <div className={cx('status-tag')} hidden={item.status !== ProjectStatus.Creating}>
                      项目创建中...
                    </div>

                    {item.status !== ProjectStatus.Creating && (
                      <div className={cx('space')}>
                        <Tooltip title={item.type === CreateProjectType.ENTERPRISE ? '企业空间' : '个人空间'}>
                          {item.type === CreateProjectType.PERSONAL ? (
                            <Icon size={16} name="icon-yonghu" />
                          ) : (
                            <Icon size={16} name="icon-企业" />
                          )}
                        </Tooltip>
                      </div>
                    )}

                    <div style={{ textAlign: 'center' }}>
                      <Image
                        src={item.iconUrl && item.iconUrl.startsWith('https') ? item.iconUrl : ProjectImg}
                        preview={false}
                        className={cx('card-img')}
                      />
                    </div>
                    <div className={cx('project-info')}>
                      <p title={item.name} className={cx('project-name')}>
                        <Flex alignCenter justifyBetween>
                          <div className={cx('project-title')}>
                            <Tooltip title={item.name}>{item.name}</Tooltip>
                          </div>

                          <Flex gap={8} alignCenter>
                            <Tooltip title="可视化开发">
                              <div
                                onClick={e => {
                                  e.stopPropagation();
                                  openVisualWebsite(item.id);
                                }}
                                className={cx('project-v-icon')}>
                                <IconFont type="icon-keshihuakaifa" />
                              </div>
                            </Tooltip>
                          </Flex>
                        </Flex>
                      </p>
                      <Tooltip
                        overlayClassName={cx('des-tooltip')}
                        title={item.description}
                        className={cx('project-description')}>
                        {item.description}
                      </Tooltip>
                    </div>
                  </Card>
                  {isTemplateProject(item) && (
                    <div className={cx('tpl-mark')}>
                      <div className={cx('tpl-mark-top')} />
                      <div className={cx('tpl-mark-main')}>模板</div>
                    </div>
                  )}
                </Col>
              );
            })}
          </Row>
          <Pagination
            style={{ textAlign: 'right' }}
            onChange={handlePaginationChange}
            {...paginationProps}
            total={total}
          />
        </>
      )}
      {total === 0 && <Empty />}
    </>
  );
};

export default ProjectCard;

/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable max-depth */
import { useEffect, useMemo, useState } from 'react';
import { Modal, Form, Input, Select, InputNumber, Tooltip, message } from '@fuxi/eevee-ui';
import _ from 'lodash';
import { useAppSelector, useAppDispatch } from '@/hooks';
import {
  VirtualMachine,
  VirtualMachineGroup,
  VirtualMachineImage,
  createVirtualMachineGroupThunk,
  updateVirtualMachineGroupThunk,
} from '@/store/virtualTerminal';
import { validatePassword } from '@/utils/password';
import { ClusterResource, isClusterResourceOverflow, maxParallelNum } from '@/utils/isResourceOverflow';

import cx from './index.module.less';

export type VMCtrlModalProps = {
  initialValue?: VirtualMachine & VirtualMachineGroup;
  children;
};

const hardSourceOptions = [
  {
    value: 'CPU：2核，内存：4G，GPU：0卡',
  },
  {
    value: 'CPU：4核，内存：6G，GPU：0卡',
  },
  {
    value: 'CPU：5核，内存：5G，GPU：0卡',
  },
  {
    value: 'CPU：2核，内存：4G，GPU：1卡',
  },
  {
    value: 'CPU：4核，内存：6G，GPU：1卡',
  },
  {
    value: 'CPU：5核，内存：5G，GPU：1卡',
  },
];

const transImagesToImageAndVersionOptions = (virtualMachineImages: VirtualMachineImage[]) => {
  const exportedVirtualMachineImages = virtualMachineImages.filter(image => image.currentStatus === 'exported');
  const platformImageOptions = _.uniqBy(exportedVirtualMachineImages, 'imageRepo')
    .filter(item => !!item.public)
    .map(item => ({
      value: item.imageRepo,
      label: <Tooltip title={item.imageRepo}>{item.imageRepo}</Tooltip>,
    }));
  const customImageOptions = _.uniqBy(exportedVirtualMachineImages, 'imageRepo')
    .filter(item => !item.public)
    .map(item => ({
      value: item.imageRepo,
      label: <Tooltip title={item.imageRepo}>{item.imageRepo}</Tooltip>,
    }));
  const imageOptions = [
    { label: '平台镜像', options: platformImageOptions },
    { label: '自定义镜像', options: customImageOptions },
  ];
  const versionOptionsByImage = {};
  exportedVirtualMachineImages.forEach(item => {
    if (!versionOptionsByImage[item.imageRepo]) versionOptionsByImage[item.imageRepo] = [];
    versionOptionsByImage[item.imageRepo].push({
      label: item.imageName.split(':').pop(),
      value: item.imageName.split(':').pop(),
    });
  });

  return {
    imageOptions,
    versionOptionsByImage,
  };
};

export const VMAddModal: React.FC<VMCtrlModalProps> = props => {
  const { children } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [imageType, setImageType] = useState<'platform' | 'custom' | ''>('');
  const [currentImage, setCurrentImage] = useState('');
  const [visible, setVisible] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { creator } = useAppSelector(state => state.project.currentProject);
  const { virtualMachineImages, virtualMachineGroup, resource } = useAppSelector(state => state.virtualTerminal);

  const { imageOptions, versionOptionsByImage } = useMemo(
    () => transImagesToImageAndVersionOptions(virtualMachineImages),
    [virtualMachineImages]
  );

  const createVM = async () => {
    try {
      const createVMInfo = await form.validateFields();
      const hardSourceInfo = imageType === 'platform' ? createVMInfo.hardSource.split('，') : [];
      console.log(hardSourceInfo, createVMInfo);

      let requestCpu;
      let requestMemory;
      let gpu;

      if (imageType === 'platform') {
        requestCpu = Number(hardSourceInfo[0].split('：')[1][0]);
        requestMemory = Number(hardSourceInfo[1].split('：')[1][0]);
        gpu = Number(hardSourceInfo[2].split('：')[1][0]);
      } else {
        requestCpu = createVMInfo.requestCpu;
        requestMemory = createVMInfo.requestMemory;
        gpu = createVMInfo.gpu;
      }
      const parallelismNum = form.getFieldValue('number') || 1;

      const usageResource: ClusterResource = {
        cpu: resource.usage.cpu + requestCpu * parallelismNum,
        memory: resource.usage.memory + requestMemory * parallelismNum,
        gpu: resource.usage.gpu + gpu * parallelismNum,
      };

      const isResoureOverflow = isClusterResourceOverflow({ usage: usageResource, limitation: resource.limitation });
      if (isResoureOverflow) {
        message.error(
          `当前资源最大支持并行${maxParallelNum({
            usage: resource.usage,
            limitation: resource.limitation,
            itemResource: {
              cpu: requestCpu,
              memory: requestMemory,
              gpu: requestCpu,
            },
          })}份, 请修改份数或联系dengli@corp.netease.com`
        );
        return;
      }

      const info =
        imageType === 'platform'
          ? {
              ...createVMInfo,
              requestCpu,
              requestMemory,
              gpu,
              image: `${createVMInfo.image}:${createVMInfo.imageVersion}`,
            }
          : {
              ...createVMInfo,
              image: `${createVMInfo.image}:${createVMInfo.imageVersion}`,
            };
      delete info.hardSource;
      delete info.imageVersion;
      setConfirmLoading(true);
      await dispatch(createVirtualMachineGroupThunk(info));
      setVisible(false);
      setImageType('');
    } catch (error) {
      console.error(error);
    }
    setConfirmLoading(false);
  };

  const onImageSelectChange = (value: string) => {
    form.setFieldValue('imageVersion', undefined);
    if (imageOptions[0].options.find(item => item.value === value)) {
      setCurrentImage(value);
      setImageType('platform');
    } else if (imageOptions[1].options.find(item => item.value === value)) {
      setCurrentImage(value);
      setImageType('custom');
    }
  };

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      <Modal
        title={'添加虚拟终端'}
        visible={visible}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setConfirmLoading(false);
          setVisible(false);
          setImageType('');
        }}
        onOk={createVM}
        destroyOnClose>
        <Form form={form} preserve={false} autoComplete="off" labelAlign="right" labelCol={{ span: 5 }}>
          <Form.Item
            required
            label="终端名称"
            name={'name'}
            rules={[
              { required: true, message: '必填' },
              {
                pattern: /^[a-z]([0-9a-z-]*[0-9a-z])?$/,
                message:
                  '由小写字母、数字或连字符组成，须以小写字母开头，须以数字或小写字母结尾，45位以内字符，不得重复',
              },
              { max: 45, min: 1, message: '名称需要在1-45个字符以内！' },
              {
                validator(_rule, value, callback) {
                  try {
                    if (virtualMachineGroup.find(group => group.name === value)) {
                      throw new Error('该名称已存在');
                    }
                    return callback();
                  } catch (err) {
                    callback(err as string);
                  }
                },
              },
            ]}>
            <Input placeholder="请输入终端名称" maxLength={45} />
          </Form.Item>
          <Form.Item
            required
            label="选择镜像"
            name={'image'}
            rules={[
              {
                required: true,
                message: '必填',
              },
            ]}>
            <Select options={imageOptions} onChange={onImageSelectChange} allowClear placeholder="请选择镜像" />
          </Form.Item>
          {imageType === 'custom' && (
            <>
              <Form.Item
                required
                label="并行份数"
                name={'number'}
                rules={[
                  {
                    required: true,
                    message: '必填',
                  },
                ]}>
                <InputNumber
                  min={1}
                  max={99}
                  precision={0}
                  name="number"
                  style={{ width: '100%' }}
                  placeholder="请输入并行份数"
                />
              </Form.Item>
              <Form.Item
                required
                label="镜像版本"
                name={'imageVersion'}
                rules={[
                  {
                    required: true,
                    message: '必填',
                  },
                ]}>
                <Select options={versionOptionsByImage[currentImage]} allowClear placeholder="请选择镜像版本" />
              </Form.Item>
              <Form.Item
                required
                label="设置密码"
                name={'password'}
                tooltip={`请设置administrator的密码`}
                rules={[
                  {
                    validator: validatePassword(6, 20, 1),
                  },
                ]}>
                <Input
                  min={6}
                  max={20}
                  name="passward"
                  autoComplete="off"
                  className={cx('vm-password')}
                  placeholder="请输入大小写字母、数字、特殊字符组合的密码，6～20位"
                  onFocus={e => {
                    e.preventDefault();
                  }}
                />
              </Form.Item>
              <Form.Item required label="资源规格">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Form.Item
                    label="CPU"
                    rules={[
                      {
                        required: true,
                        message: '必填',
                      },
                    ]}
                    required={false}
                    name={'requestCpu'}
                    colon={false}
                    labelCol={{ span: 6 }}>
                    <InputNumber suffix="核" min={1} max={16} precision={0} />
                  </Form.Item>
                  <Form.Item
                    label="内存 "
                    rules={[
                      {
                        required: true,
                        message: '必填',
                      },
                    ]}
                    required={false}
                    name={'requestMemory'}
                    colon={false}
                    labelCol={{ span: 6 }}>
                    <InputNumber suffix="G" min={1} max={64} precision={0} />
                  </Form.Item>
                  <Form.Item
                    label="GPU"
                    rules={[
                      {
                        required: true,
                        message: '必填',
                      },
                    ]}
                    required={false}
                    name={'gpu'}
                    colon={false}
                    labelCol={{ span: 6 }}>
                    <InputNumber suffix="卡" min={0} max={8} precision={0} />
                  </Form.Item>
                </div>
              </Form.Item>
            </>
          )}
          {imageType === 'platform' && (
            <>
              <Form.Item
                required
                label="镜像版本"
                name={'imageVersion'}
                rules={[
                  {
                    required: true,
                    message: '必填',
                  },
                ]}>
                <Select options={versionOptionsByImage[currentImage]} allowClear placeholder="请选择镜像版本" />
              </Form.Item>
              <Form.Item
                required
                label="设置密码"
                name={'password'}
                tooltip={`请设置administrator的密码`}
                rules={[
                  {
                    validator: validatePassword(6, 20, 1),
                  },
                ]}>
                <Input
                  min={6}
                  max={20}
                  name="passward"
                  autoComplete="off"
                  className={cx('vm-password')}
                  placeholder="请输入大小写字母、数字、特殊字符组合的密码，6～20位"
                  onFocus={e => {
                    e.preventDefault();
                  }}
                />
              </Form.Item>
              <Form.Item
                required
                label="资源规格"
                name={'hardSource'}
                rules={[
                  {
                    required: true,
                    message: '必填',
                  },
                ]}>
                <Select options={hardSourceOptions} allowClear placeholder="请选择资源规格" />
              </Form.Item>
              {/* <Form.Item label="启动命令" name={'command'}>
                <Input placeholder="请输入启动命令" />
              </Form.Item> */}
              {/* <Form.Item label="选择文件包">
                <Select />
              </Form.Item> */}
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export const VMUpdateModal: React.FC<VMCtrlModalProps> = ({ initialValue, children }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { virtualMachineGroup } = useAppSelector(state => state.virtualTerminal);

  useEffect(() => {
    form.setFieldsValue(initialValue);
    form.setFieldValue('type', initialValue?.vmList?.[0]?.type);
  }, [visible]);

  const updateVM = async () => {
    try {
      const updateInfo = await form.validateFields();
      setConfirmLoading(true);
      dispatch(
        updateVirtualMachineGroupThunk({
          vmGroupParallelIdList: [initialValue?.parallelId],
          name: updateInfo.name,
          type: updateInfo.type,
        })
      );
      setVisible(false);
    } catch (error) {
      console.error(error);
    }
    setConfirmLoading(false);
  };

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      <Modal
        title={'编辑虚拟终端'}
        visible={visible}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setConfirmLoading(false);
          setVisible(false);
        }}
        onOk={updateVM}>
        <Form form={form} preserve={false} autoComplete="off">
          <Form.Item
            required
            name={'name'}
            label={'终端名称'}
            rules={[
              { required: true, message: '必填' },
              {
                pattern: /^[a-z]([0-9a-z-]*[0-9a-z])?$/,
                message:
                  '由小写字母、数字或连字符组成，须以小写字母开头，须以数字或小写字母结尾，45位以内字符，不得重复',
              },
              { max: 45, min: 1, message: '名称需要在1-45个字符以内！' },
              {
                validator(_rule, value, callback) {
                  try {
                    if (virtualMachineGroup.find(group => group.name === value)) {
                      if (value === initialValue?.name) {
                      } else throw new Error('该名称已存在');
                    }
                    return callback();
                  } catch (err) {
                    callback(err as string);
                  }
                },
              },
            ]}>
            <Input placeholder="请输入终端名称" maxLength={45} />
          </Form.Item>
          <Form.Item
            required
            name={'type'}
            label={'终端类型'}
            rules={[
              {
                required: true,
                message: '必填',
              },
              {
                whitespace: true,
                message: '请输入非空字符',
              },
            ]}>
            <Input placeholder="请输入终端类型" maxLength={127} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export const VMBatchOperationModal: React.FC<{
  option: { label: string; value: string };
  initialValue: (VirtualMachine & VirtualMachineGroup)[];
}> = ({ initialValue, option }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => setVisible(!!option.value), [option]);

  const updateVM = async () => {
    try {
      const updateInfo = await form.validateFields();
      setConfirmLoading(true);
      dispatch(
        updateVirtualMachineGroupThunk({
          vmGroupParallelIdList: initialValue.map(group => group.parallelId),
          name: updateInfo?.name || null,
          type: updateInfo?.type || null,
        })
      );
      setVisible(false);
    } catch (error) {
      console.error(error);
    }
    setConfirmLoading(false);
  };

  return (
    <>
      <Modal
        destroyOnClose
        onOk={updateVM}
        visible={visible}
        title={option.label}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          setConfirmLoading(false);
        }}>
        <Form form={form} preserve={false} autoComplete="off">
          <Form.Item
            required
            name={option.value}
            label={option.value === 'name' ? '终端名称' : '终端类型'}
            rules={[
              {
                required: true,
                message: '必填',
              },
              {
                whitespace: true,
                message: '请输入非空字符',
              },
            ]}>
            <Input placeholder={option.value === 'name' ? '请输入终端名称' : '请输入终端类型'} maxLength={127} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

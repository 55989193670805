import {
  ApolloClient,
  DocumentNode,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import cookie from 'react-cookies';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { ErrorList, openGraphqlErrorNotification, openNetworkErrorNotification } from './utils/openNotification';
import { youlingAuthenticator } from './handleYoulingLogin';

export type Query = <QV, RT>(query: DocumentNode, variables?: QV) => Promise<RT>;
export type Mutate = <MV, RT>(mutation: DocumentNode, variables?: MV) => Promise<RT>;

export type GraphQLClient = {
  query: Query;
  mutate: Mutate;
  client: ApolloClient<NormalizedCacheObject>;
};

// eslint-disable-next-line react-func/max-lines-per-function
const createGQLClient = (): GraphQLClient => {
  const cache = new InMemoryCache({
    /**open then cache won't work */
    // addTypename: false,
    // resultCaching: false,
  });

  const httpLink = new HttpLink({ uri: '/graphql', credentials: 'include' });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = cookie.load('RBAC_TOKEN');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'X-Rbac-Token': token || '',
      },
    };
  });

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      let errorList: ErrorList = [];
      graphQLErrors.forEach(({ message, extensions }) => {
        const code = extensions?.code as number;
        errorList.push({ message, code });
      });
      openGraphqlErrorNotification(errorList);
      errorList.forEach(error => {
        setTimeout(() => {
          error.code === 40301 && (window.location.href = '/platform');
        }, 500);
      });
    }
    if (networkError) {
      // get custom error code like 40101
      let code, message, httpStatus;

      const result = (networkError as ServerError)?.result;
      const response = (networkError as ServerError | ServerParseError)?.response;

      httpStatus = response.status;
      code = result?.code ? result.code : httpStatus;
      message = result?.message ? result.message : response.statusText;

      youlingAuthenticator({ httpStatus, code, message });
    }
  });

  const client = new ApolloClient({
    // Provide required constructor fields
    cache,
    link: errorLink.concat(authLink).concat(httpLink),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
  });

  const query: Query = (query, variables) => {
    return client
      .query({
        query,
        variables,
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => data)
      .catch(e => console.log('[QueryError]:', e));
  };

  const mutate: Mutate = (mutation, variables) => {
    return client
      .mutate({
        mutation,
        variables,
      })
      .then(({ data }) => data)
      .catch(e => console.log('[MutateError]:', e));
  };
  return { query, mutate, client };
};

// create client singleton
const client = createGQLClient();

export default client;

import React, { useEffect, useState } from 'react';
import { Button, Flex, Radio, Space, TablePaginationConfig, Input, Card, Empty, Checkbox } from '@fuxi/eevee-ui';
import { AppstoreOutlined, MenuOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { isProd } from '@/utils/getEnv';
import emptyImg from '@/assets/image/empty2.png';
import ProjectContainer from '../ProjectContainer';
import { defaultCurrentPage, defaultPageSize, ProjectType } from '../const';
import { useIsDataManager } from '../ProjectDetailMini/utils/useIsDataManager';
import ProjectTable from './ProjectTable';
import ProjectCard from './ProjectCard';
import cx from './ProjectList.module.less';

const Project: React.FC = () => {
  const navigate = useNavigate();
  const container = ProjectContainer.useContainer();
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: defaultPageSize,
    current: defaultCurrentPage,
  });

  const {
    projectList,
    total,
    showInTable,
    showOnlyTpl,
    projectListLoading,
    setShowCreateEntry,
    userInfo,
    setShowCreate,
    fetchProjects,
    editProject,
    deleteProject,
    setShowInTable,
    setShowOnlyTpl,
  } = container;
  const { Search } = Input;

  const [filteredProjectsList, setFilteredProjectsList] = useState(projectList);
  const [filteredTotal, setFilteredTotal] = useState(total);
  const isSuperAdmin = useIsDataManager(userInfo);
  const showCreateProjectButtonInProd = () => {
    return isSuperAdmin;
  };

  useEffect(() => {
    setFilteredProjectsList(projectList);
  }, [projectList]);

  useEffect(() => {
    total === 0 && showCreateProjectButtonInProd() && setShowCreateEntry(true);
    setFilteredTotal(total);
  }, [total]);

  const handleTurn2Detail = (rec: Project) => {
    navigate({ pathname: `/project/${rec.id}/detail/project-config/project-desc` });
  };

  const handleTableChange = (pageConfig: TablePaginationConfig) => {
    setPagination(pageConfig);
  };

  useEffect(() => {
    // TODO 默认请求全量数据，前端分页
    fetchProjects();
  }, []);

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setPagination({ pageSize: pageSize || defaultPageSize, current: page });
  };

  const paginationProps = {
    showTotal: () => `共 ${filteredTotal === -1 ? 0 : filteredTotal} 条`,
    showSizeChanger: true,
    pageSizeOptions: ['8', '16', '24'],
    ...pagination,
  };

  const handleProjectSearch = (value: string) => {
    const matchingList = projectList?.filter(project => project.name.includes(value));
    setFilteredProjectsList(matchingList);
    setPagination({ current: 1, pageSize: pagination.pageSize });
    setFilteredTotal(matchingList?.length || 0);
  };

  const commonProps = {
    projectList: filteredProjectsList || [],
    editProject: editProject,
    deleteProject: deleteProject,
    handleTurn2Detail: handleTurn2Detail,
    paginationProps: paginationProps,
  };

  return (
    <div className={cx('list')}>
      <Card bordered={false} loading={projectListLoading}>
        {total === 0 ? (
          <Empty
            image={emptyImg}
            imageStyle={{ height: 150 }}
            style={{ margin: 150 }}
            description={
              <>
                <p>暂无项目</p>
                {showCreateProjectButtonInProd() && (
                  <Button type="primary" onClick={() => setShowCreateEntry(true)}>
                    + 新建项目
                  </Button>
                )}
              </>
            }
          />
        ) : (
          <>
            <Flex justifyBetween>
              <Space size={24}>
                <div className={cx('all-items-text')}>全部项目</div>
                {/* 仅查看模板功能需求暂不明确，暂时隐藏 */}
                {/* <Space>
                  <Checkbox value={showOnlyTpl} onChange={e => setShowOnlyTpl(e.target.checked)}>
                    仅查看模板
                  </Checkbox>
                </Space> */}
              </Space>
              <Flex.Item>
                <Space size={16}>
                  <Search
                    placeholder="请输入 项目名称 进行搜索"
                    allowClear
                    style={{ width: 250 }}
                    onSearch={handleProjectSearch}
                  />
                  <Radio.Group defaultValue="card">
                    <Radio.Button onClick={() => setShowInTable(false)} value="card">
                      <Space>
                        <AppstoreOutlined />
                        卡片
                      </Space>
                    </Radio.Button>
                    <Radio.Button onClick={() => setShowInTable(true)} value="table">
                      <Space>
                        <MenuOutlined />
                        列表
                      </Space>
                    </Radio.Button>
                  </Radio.Group>
                </Space>
              </Flex.Item>
            </Flex>
            {total === -1 ? (
              <Empty
                image={emptyImg}
                imageStyle={{ height: 150 }}
                style={{ margin: 150 }}
                description={<p>暂无项目</p>}
              />
            ) : (
              <>
                {showInTable && <ProjectTable {...commonProps} handlePaginationChange={handleTableChange} />}
                {!showInTable && (
                  <ProjectCard
                    {...commonProps}
                    pagination={pagination}
                    total={filteredTotal}
                    handlePaginationChange={handlePaginationChange}
                  />
                )}
              </>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default Project;

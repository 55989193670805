import { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  getProjectWithAllJudgeAction,
  getReviewAppraisesAction,
  ReviewStage,
  setIsSpinTrue,
  setReviewStages,
  setSeasonStage,
  Stage,
} from '@/store/miniReview';
import { GET_PROJECT_SEASONS, GET_REVIEW_STAGES } from '@/service/schema/miniReview/miniReview-queries';
import { useAppDispatch, useAppSelector } from '@/hooks';

type SelectedSeason = {
  id: string;
  name: string;
};

type SelectedStage = {
  id: string;
  stage: Stage;
};

type SeasonStage = {
  selectedSeason?: SelectedSeason;
  selectedStage?: SelectedStage;
  currentIndex: number;
  projectsSearchText: string;
  summarySearchText: string;
};

const updateSeason = (selectedSeason: SelectedSeason) => ({ selectedSeason });
const updateStage = (selectedStage: SelectedStage) => ({ selectedStage });

const reducer = (state: SeasonStage, action: any) => {
  switch (action.type) {
    case 'updateSelectSeason':
      return { ...state, ...updateSeason(action.payload) };
    case 'updateSelectedStage':
      return { ...state, ...updateStage(action.payload) };
    case 'updateCurrentIndex':
      return { ...state, currentIndex: action.payload };
    case 'updateProjectSearchText':
      return { ...state, projectsSearchText: action.payload };
    case 'updateSummarySearchText':
      return { ...state, summarySearchText: action.payload };
    default:
      return state;
  }
};

export const useSeasonStageInfo = () => {
  const initState: SeasonStage = { currentIndex: 0, projectsSearchText: '', summarySearchText: '' };
  const { reviewStages, currentSeasonStage } = useAppSelector(state => state.miniReview);

  const [seasonStageInfo, dispatchSeasonStage]: [SeasonStage, Dispatch<any>] = useReducer(reducer, initState);
  const { data: projectSeasonsData } = useQuery<{ projectSeasons: SelectedSeason[] }>(GET_PROJECT_SEASONS, {
    fetchPolicy: 'cache-first',
  });
  const [getReviewStage, { data: stagesData, loading: stageLoading }] = useLazyQuery<
    { reviewStages: ReviewStage[] },
    { seasonId: string }
  >(GET_REVIEW_STAGES, { fetchPolicy: 'no-cache' });

  const miniSeasonList = useMemo(() => projectSeasonsData?.projectSeasons || [], [projectSeasonsData]);
  const dispatch = useAppDispatch();

  const resetState = () => {
    dispatchSeasonStage({ type: 'updateCurrentIndex', payload: 0 });
    dispatchSeasonStage({ type: 'updateProjectSearchText', payload: '' });
    dispatchSeasonStage({ type: 'updateSummarySearchText', payload: '' });
  };

  // 设置当前季度season
  useEffect(() => {
    const latestSeason = miniSeasonList?.[0];
    if (!latestSeason) {
      return;
    }
    if (currentSeasonStage.seasonId && !seasonStageInfo.selectedSeason?.id) {
      dispatchSeasonStage({ type: 'updateSelectSeason', payload: { id: currentSeasonStage.seasonId } });
    }
    if (!currentSeasonStage.seasonId) {
      dispatchSeasonStage({ type: 'updateSelectSeason', payload: latestSeason });
      dispatch(setSeasonStage({ seasonId: latestSeason.id }));
    }
  }, [miniSeasonList]);

  useEffect(() => {
    seasonStageInfo?.selectedSeason?.id &&
      getReviewStage({ variables: { seasonId: seasonStageInfo.selectedSeason.id } });
  }, [seasonStageInfo?.selectedSeason]);

  useEffect(() => {
    stagesData?.reviewStages && dispatch(setReviewStages(stagesData.reviewStages));
  }, [stagesData]);

  useEffect(() => {
    if (!reviewStages.length) {
      return;
    }
    const hasCurrentStage = reviewStages.find(stage => stage.id === currentSeasonStage.stageId);

    if (!hasCurrentStage) {
      !currentSeasonStage.stageId &&
        dispatch(setSeasonStage({ stageId: reviewStages[0].id, stage: reviewStages[0].stage }));
      dispatchSeasonStage({ type: 'updateSelectedStage', payload: reviewStages[0] });
    } else {
      dispatchSeasonStage({
        type: 'updateSelectedStage',
        payload: { id: currentSeasonStage.stageId, stage: currentSeasonStage.stage },
      });
    }
  }, [reviewStages]);

  useEffect(() => {
    if (currentSeasonStage.seasonId && currentSeasonStage.stageId) {
      dispatch(setIsSpinTrue());
      dispatch(getReviewAppraisesAction(currentSeasonStage.stageId));
      dispatch(getProjectWithAllJudgeAction(currentSeasonStage.stageId));
      resetState();
    }
  }, [currentSeasonStage]);

  return { seasonStageInfo, dispatchSeasonStage, miniSeasonList, stageLoading };
};

import { message } from '@fuxi/eevee-ui';
import { get } from 'lodash';
import { openNetworkErrorNotification } from '@/service/utils/openNotification';

// 不需要全局提示的错误
// const noGlobalPromptCodes: number[] = [];

export default function errorHandler(error: any) {
  // Ignore error when request is canceled by user:
  const isCanceled = !(error instanceof Error) && error && typeof error.message === 'string';

  if (isCanceled) return;

  const status: number = get(error, 'response.status', 0);

  // 处理401
  if (status === 401) {
    return (window.location.href = `/rbac/login?next=${window.location.href}`);
  } else {
    const msg = get(error, 'response.data.msg', '服务端异常');
    message.error(msg);
  }

  return Promise.reject(error);
}

export const webInstanceErrorHandler = (error: any) => {
  const data = get(error, 'response.data', {});
  // @ts-ignore
  openNetworkErrorNotification(data?.message, data?.code);
  return Promise.reject(error);
};

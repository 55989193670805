/* eslint-disable max-lines */
import bindClass from 'classnames/bind';
import { Form, Row, Typography, Col, Radio, Input, Icon, Tooltip, Switch, Upload, Button } from '@fuxi/eevee-ui';
import React, { useState, forwardRef, useRef, useImperativeHandle, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '@/hooks/useAppSelector';
import {
  GPU_LIST,
  SYSTEM_ENV_VARIABLE,
  FieldNamesForTrain,
  DEFAULT_VARIABLE_LIST,
} from '@ai-training/constants/ability';
import { ruleMap } from '@ai-training/utils/validator';
import { TrainFormDataConfig, ResourceValue, FormValues } from '@ai-training/types/ability';
import { VersionStatus } from '@ai-training/constants';
import { selectBaseCapability } from '@/store/aiTraining';
import UploadCDN from './UploadCDN';
import ResourceConfig from '../ResourceConfig';
import ResourceConfig1 from '../ResourceConfig1';
import EnvSetup from '../EnvSetup';
import styles from './index.module.less';

const cx = bindClass.bind(styles);
const { Text } = Typography;
const { TextArea } = Input;

interface Options {
  value: string;
  label: string;
}

export const rightAlignedFormItemLayout = {
  labelCol: { span: 3 }, // Adjust offset as necessary to align to the right
  wrapperCol: { span: 14 },
};

interface RuntimeConfigOfFormRef {
  extractDataFromFormValues: (values: FormValues) => TrainFormDataConfig;
}

interface RuntimeConfigProps extends React.RefAttributes<RuntimeConfigOfFormRef> {
  isTrainConfig?: boolean;
  isInferenceConfig?: boolean;
  title?: string;
  children?: React.ReactNode;
  isModelDeploy?: boolean;
}

interface CustomInputProps {
  value?: {
    name: string;
  };
  onChange?: (value: { name: string }) => void;
  children?: React.ReactNode;
  isFirst: boolean;
  isLast: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({ value = {}, onChange, isFirst, isLast, children = null }) => {
  const [name, setName] = useState<string>('');

  const triggerChange = (changedValue: { name?: string }) => {
    onChange?.({ name, ...value, ...changedValue });
  };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newKey = e.target.value;
    setName(newKey);
    triggerChange({ name: newKey });
  };

  const onNameBlur = () => {
    // triggerChange({ name });
  };

  return (
    <div
      className={cx({
        'single-custom-config': true,
        'first-single-config': isFirst,
        'last-single-config': isLast,
      })}>
      <Input
        type="text"
        className={cx('single-custom-config-input')}
        placeholder="请输入"
        value={value.name || name}
        onChange={onNameChange}
        onBlur={onNameBlur} // 新增 onBlur 事件
      />
      {children}
    </div>
  );
};

const RunTimeConfigOfForm: React.FC<RuntimeConfigProps> = forwardRef(
  ({ isTrainConfig = false, isInferenceConfig = false, title = '运行环境', isModelDeploy = false, children }, ref) => {
    const form = Form.useFormInstance();
    const isInnerUser = useAppSelector(state => state.user.isInner);
    // 用1个 ref 来保存所有验证成功了的 field 的 key，并在 onBlur 的时候，进行校验
    const envVariableKeyList = useRef<Map<string, string>>(new Map<string, string>(DEFAULT_VARIABLE_LIST));
    const baseCapability = useAppSelector(selectBaseCapability);
    const [isTipUserEnv, setIsTipUserEnv] = useState<boolean>(false);
    const [isSupportUseGpu, setIsSupportUseGpu] = useState<boolean>(false);
    const ports = Form.useWatch(FieldNamesForTrain.Ports, form);
    const [searchParams] = useSearchParams();
    const versionStatus = searchParams.get('version_status');

    // 在子组件中使用 form 的时候，需要使用 Form.useFormInstance() 来获取 form 实例

    const resource = form.getFieldValue(FieldNamesForTrain.Resource);
    const gpuValue = resource?.gpu;

    useImperativeHandle(ref, () => ({
      extractDataFromFormValues(values: FormValues) {
        const resources: ResourceValue = values[FieldNamesForTrain.Resource] ?? {};
        const finalFormData: TrainFormDataConfig = {} as TrainFormDataConfig;
        finalFormData[FieldNamesForTrain.ImageFile] = values[FieldNamesForTrain.ImageFile];

        // const image_uri = values[FieldNamesForTrain.ImageFile] ?? [];
        // if (image_uri.length === 2) {
        //   finalFormData[FieldNamesForTrain.ImageFile] = image_uri[1];
        // }
        const gpuCount = resources.gpu ?? 0;

        finalFormData[FieldNamesForTrain.Resource] = {
          cpu: {
            max: {
              value: resources.cpu,
              unit: resources.cpuUnit,
            },
            min: {
              value: resources.cpu,
              unit: resources.cpuUnit,
            },
          },
          gpu: {
            max: {
              value: gpuCount,
              unit: '卡',
            },
            min: {
              value: gpuCount,
              unit: '卡',
            },
          },
          memory: {
            max: {
              value: resources.memory,
              unit: resources.memoryUnit,
            },
            min: {
              value: resources.memory,
              unit: resources.memoryUnit,
            },
          },
        };
        if (isSupportUseGpu && isInnerUser && values[FieldNamesForTrain.GPU] && gpuCount > 0) {
          finalFormData[FieldNamesForTrain.GPU] = values[FieldNamesForTrain.GPU];
        } else {
          finalFormData[FieldNamesForTrain.GPU] = null;
        }
        finalFormData[FieldNamesForTrain.Script] = values[FieldNamesForTrain.Script];
        finalFormData[FieldNamesForTrain.Ports] = values[FieldNamesForTrain.Ports]
          ?.filter(item => !!item.port)
          ?.map(filterItem => ({
            ...filterItem,
            port: Number(filterItem.port),
            safe_team_work_number: !!filterItem?.public ? filterItem.safe_team_work_number : undefined,
            safe_team_pass_screenshot: !!filterItem?.public ? filterItem.safe_team_pass_screenshot : undefined,
          }));

        finalFormData[FieldNamesForTrain.Command] = values[FieldNamesForTrain.Command];
        if (isInferenceConfig) {
          finalFormData[FieldNamesForTrain.Args] = values[FieldNamesForTrain.Args]
            ?.filter(item => item?.name)
            ?.map(item => item?.name);
        } else {
          finalFormData[FieldNamesForTrain.Args] = values[FieldNamesForTrain.Args]
            ? [values[FieldNamesForTrain.Args]]
            : [];
        }

        finalFormData[FieldNamesForTrain.Env] = (values[FieldNamesForTrain.Env] ?? []).filter(item => item?.name);

        return finalFormData;
      },
    }));

    const handleIsUseGpu = (isUseGpu: boolean) => {
      setIsSupportUseGpu(isUseGpu);
    };

    useEffect(() => {
      if (gpuValue > 0) {
        setIsSupportUseGpu(true);
        const gpu = form.getFieldValue(FieldNamesForTrain.GPU);
        form.setFieldValue(FieldNamesForTrain.GPU, gpu ?? GPU_LIST[0].value);
      } else if (gpuValue === 0) {
        setIsSupportUseGpu(false);
        form.setFieldValue(FieldNamesForTrain.GPU, null);
      }
    }, [gpuValue]);

    return (
      <div className={styles['part-group-container']}>
        <Row className={styles['train-runtime']}>
          <Col span={2}>
            <Text style={{ display: 'block' }}>{title}</Text>
          </Col>
        </Row>

        {!isModelDeploy && (
          <Form.Item
            label="镜像"
            name={FieldNamesForTrain.ImageFile}
            className={cx('narrow-label-offest', 'docker-selection')}
            rules={[{ required: true, message: '请输入镜像' }, ruleMap.isValidImageFile]}
            {...rightAlignedFormItemLayout}>
            <Input
              placeholder="请输入镜像"
              className={cx('docker-input')}
              disabled={!!baseCapability?.name && versionStatus !== VersionStatus.Create}
            />

            {/* <Cascader
            options={imageUriOptions}
            placeholder="请选择"
            showSearch
            // TODO 通过在 search 的时候手动给用户添加1个自定义的选项，来实现用户可以自定义输入
            onSearch={handleSearch}
            className={cx('dockers', 'cascader-docker-group')}
            getPopupContainer={() => {
              return document.getElementsByClassName('cascader-docker-group')[0] as HTMLDivElement;
            }}
          /> */}
          </Form.Item>
        )}

        <Form.Item
          label="资源配额"
          className={styles['narrow-label-offest']}
          name={FieldNamesForTrain.Resource}
          rules={[{ required: true, message: '请进行资源配额' }, ruleMap.isValidCpuConfig]}
          {...{ ...rightAlignedFormItemLayout, wrapperCol: { span: 13 } }}>
          <ResourceConfig handleIsUseGpu={handleIsUseGpu} isEnableFullOption={isInferenceConfig} />
        </Form.Item>
        {isInnerUser && isSupportUseGpu && (
          <Form.Item
            label="GPU型号"
            initialValue={GPU_LIST[0].value}
            className={styles['narrow-label-offest']}
            name={FieldNamesForTrain.GPU}
            rules={[{ required: true, message: '请选择GPU型号' }]}
            {...rightAlignedFormItemLayout}>
            <Radio.Group style={{ backgroundColor: 'transparent' }}>
              {GPU_LIST.map((item: Options) => {
                return (
                  <Radio key={`${item.value}-${item.label}`} value={item.value}>
                    {item.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
        )}
        {/* 模型部署没有这些配置 */}
        {!isModelDeploy && (
          <>
            <Form.Item
              label="启动命令"
              name={FieldNamesForTrain.Command}
              className={styles['narrow-label-offest']}
              rules={[{ required: false }]}
              {...rightAlignedFormItemLayout}>
              <TextArea className={cx('custom-single-row')} rows={4} placeholder="请输入" showCount />
            </Form.Item>
            {!isInferenceConfig && (
              <Form.Item
                label="开放端口"
                className={cx('narrow-label-offest')}
                validateTrigger={['onBlur']}
                {...rightAlignedFormItemLayout}>
                <div className={styles['ports-wrapper']}>
                  <Form.List
                    name={FieldNamesForTrain.Ports}
                    rules={[
                      {
                        validator: async (_, values) => {
                          if (values?.length > 5) {
                            return Promise.reject(new Error('端口号不能超过五个'));
                          }
                        },
                      },
                    ]}>
                    {(fields, { add, remove }, { errors }) => {
                      return (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <div key={key} className={styles['port-wrapper-with-add-delete']}>
                              <div className={styles['port-wrapper']}>
                                <Form.Item
                                  {...restField}
                                  label={'开放外网'}
                                  style={{ marginLeft: isTrainConfig ? 39 : 0 }}
                                  name={[name, 'public']}
                                  valuePropName={'checked'}
                                  tooltip={isTrainConfig ? undefined : '评估配置不支持开放外网'}>
                                  <Switch disabled={!isTrainConfig} />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  style={{ marginLeft: isTrainConfig ? 53 : 32 }}
                                  label={'端口号'}
                                  name={[name, 'port']}
                                  rules={[
                                    ruleMap.isValidPort,
                                    {
                                      validator(_, value) {
                                        if (!value) return Promise.resolve();
                                        if (
                                          form.getFieldValue(FieldNamesForTrain.Ports)?.reduce((acc, port) => {
                                            if (Number(port.port) === Number(value)) {
                                              acc++;
                                            }
                                            return acc;
                                          }, 0) > 1
                                        ) {
                                          return Promise.reject(new Error('端口号不能重复'));
                                        } else {
                                          return Promise.resolve();
                                        }
                                      },
                                    },
                                  ]}>
                                  <Input placeholder="请输入" />
                                </Form.Item>
                                <Form.Item hidden {...restField} name={[name, 'protocol']} initialValue={'tcp'} />
                                <Form.Item hidden {...restField} name={[name, 'external']} initialValue={true} />
                                {!!ports?.[index]?.public && (
                                  <>
                                    <Form.Item
                                      {...restField}
                                      label={'安全扫描工单'}
                                      name={[name, 'safe_team_work_number']}
                                      rules={[
                                        ruleMap.isValidsafeTeamWorkNumber,
                                        { required: true, message: '请输入安全扫描工单地址' },
                                      ]}>
                                      <Input placeholder="请输入" />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      label={'审批通过截图'}
                                      name={[name, 'safe_team_pass_screenshot']}
                                      rules={[{ required: true, message: '请上传审批通过截图' }]}>
                                      <UploadCDN />
                                    </Form.Item>
                                  </>
                                )}
                              </div>
                              {index === fields.length - 1 ? (
                                <>
                                  {index === 0 ? null : (
                                    <Icon
                                      name="删除缩小"
                                      cursor={'pointer'}
                                      onClick={() => {
                                        if (fields.length <= 1) {
                                          return;
                                        }
                                        remove(name);
                                      }}
                                      className={styles['dynamic-delete-button']}
                                    />
                                  )}

                                  <Icon
                                    name="添加放大"
                                    cursor={'pointer'}
                                    onClick={() => {
                                      add();
                                    }}
                                    className={styles['dynamic-add-button']}
                                  />
                                </>
                              ) : (
                                <>
                                  <Icon
                                    name="删除缩小"
                                    cursor={'pointer'}
                                    onClick={() => remove(name)}
                                    className={styles['dynamic-delete-button']}
                                  />
                                  <span style={{ width: 24, overflow: 'hidden', display: 'inline-block' }}></span>
                                </>
                              )}
                            </div>
                          ))}
                          <Form.ErrorList errors={errors} />
                        </>
                      );
                    }}
                  </Form.List>
                </div>
              </Form.Item>
            )}
            {isInferenceConfig ? (
              <Form.Item
                label="命令行参数"
                className={cx('narrow-label-offest', 'env-variables')}
                {...rightAlignedFormItemLayout}
                wrapperCol={{ span: 15 }}>
                <Form.List name={FieldNamesForTrain.Args}>
                  {(fields, { add, remove }, { errors }) => {
                    return (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item
                            style={{ marginLeft: '0' }}
                            {...field}
                            noStyle
                            name={[field.name]}
                            key={field.key}
                            validateTrigger={['onChange']}>
                            <CustomInput isFirst={index === 0} isLast={index === fields.length - 1}>
                              {index === fields.length - 1 ? (
                                <>
                                  {index === 0 ? null : (
                                    <Icon
                                      name="删除缩小"
                                      cursor={'pointer'}
                                      onClick={() => {
                                        if (fields.length <= 1) {
                                          return;
                                        }
                                        remove(field.name);
                                      }}
                                      className={styles['dynamic-delete-button']}
                                    />
                                  )}

                                  <Icon
                                    name="添加放大"
                                    cursor={'pointer'}
                                    onClick={() => add()}
                                    className={styles['dynamic-add-button']}
                                  />
                                </>
                              ) : (
                                <>
                                  <Icon
                                    name="删除缩小"
                                    cursor={'pointer'}
                                    onClick={() => remove(field.name)}
                                    className={styles['dynamic-delete-button']}
                                  />
                                  <span style={{ width: 24, overflow: 'hidden', display: 'inline-block' }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </span>
                                </>
                              )}
                            </CustomInput>
                          </Form.Item>
                        ))}
                        <Form.Item noStyle key={'last-env-var-config'}>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </>
                    );
                  }}
                </Form.List>
              </Form.Item>
            ) : (
              <Form.Item
                label="命令行参数"
                className={cx('narrow-label-offest', 'env-variables')}
                name={FieldNamesForTrain.Args}
                rules={[ruleMap.isValidArgs]}
                {...rightAlignedFormItemLayout}
                wrapperCol={{ span: 15 }}>
                <TextArea className={cx('custom-single-row')} rows={4} placeholder="请输入" showCount />
              </Form.Item>
            )}
            <Form.Item
              label="环境变量"
              className={cx('narrow-label-offest', 'env-variables')}
              {...rightAlignedFormItemLayout}
              wrapperCol={{ span: 21 }}>
              <Form.List name={FieldNamesForTrain.Env}>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row className={cx('first-env-input')} key={field.key}>
                        <Form.Item
                          style={{ marginLeft: '0' }}
                          noStyle
                          {...field}
                          key={field.key}
                          rules={[ruleMap.isValidEnvVariable.bind(this, envVariableKeyList.current, setIsTipUserEnv)]}
                          validateTrigger={['onChange', 'onBlur']}>
                          <EnvSetup isFirst={index === 0} isLast={index === fields.length - 1}>
                            {index === fields.length - 1 ? (
                              <>
                                {index === 0 ? null : (
                                  <Icon
                                    name="删除缩小"
                                    cursor={'pointer'}
                                    onClick={() => {
                                      if (fields.length <= 1) {
                                        return;
                                      }
                                      remove(field.name);
                                    }}
                                    className={styles['dynamic-delete-button']}
                                  />
                                )}

                                <Icon
                                  name="添加放大"
                                  cursor={'pointer'}
                                  onClick={() => add()}
                                  className={styles['dynamic-add-button']}
                                />
                              </>
                            ) : (
                              <>
                                <Icon
                                  name="删除缩小"
                                  cursor={'pointer'}
                                  onClick={() => remove(field.name)}
                                  className={styles['dynamic-delete-button']}
                                />
                                <span style={{ minWidth: 24, overflow: 'hidden', display: 'inline-block' }}></span>
                              </>
                            )}
                          </EnvSetup>
                        </Form.Item>
                        {index === 0 ? (
                          <Tooltip title={SYSTEM_ENV_VARIABLE} className={styles['view-env-variables']}>
                            <Text>查看系统环境变量</Text>
                          </Tooltip>
                        ) : null}
                      </Row>
                    ))}
                    <Form.Item noStyle key={'last-env-var-config'}>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Row>
                <Text
                  style={{ marginTop: 9 }}
                  className={cx({
                    'tip-text': true,
                    'tip-user-env': isTipUserEnv,
                  })}>
                  说明：key由大小写字母、数字、下划线（_）组成, 不能以数字开头
                </Text>
              </Row>
            </Form.Item>
            {isInferenceConfig ? children : <></>}
          </>
        )}
      </div>
    );
  }
);

export default RunTimeConfigOfForm;

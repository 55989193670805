import { gql } from '@apollo/client';

export const SERVICE_FRAGMENT = gql`
  fragment ServiceFragment on Service {
    id
    description
    environment
    name
    status
    actorUrl
    serviceUrl
    type
    creator
    updater
    createdTime
    updatedTime
    taskId
    msg
    version
    debugUrl
    sshUrl
    isFrontendPublish
    modules {
      currentTag
      status
      type
      alias
    }
  }
`;

export const GET_SERVICES = gql`
  query GetService($projectId: ID!) {
    services(projectId: $projectId) {
      items {
        ...ServiceFragment
      }
      total
    }
  }
  ${SERVICE_FRAGMENT}
`;

export const SEARCH_USER_BY_EMAIL = gql`
  query SearchUserByEmail($email: String!, $pageNo: Int!, $pageSize: Int!) {
    searchUserByEmail(email: $email, pageNo: $pageNo, pageSize: $pageSize) {
      items {
        email
        name
        fullName
      }
      current
      total
    }
  }
`;

// 校验服务器名称是否重复
export const VALIDATE_SERVICE_NAME = gql`
  query ValidateServiceName($projectId: ID!, $name: String!) {
    validateServiceName(projectId: $projectId, name: $name)
  }
`;

// 通过serviceId获取最新的发布详情
export const GET_LATEST_RELEASE_BY_SERVICE_ID = gql`
  query GetLatestReleaseByServiceId($serviceId: ID!) {
    latestReleaseByServiceId(serviceId: $serviceId) {
      id
      serviceId
      pipelineId
      status
    }
  }
`;

// 通过projectId、serviceId、起止时间戳获取发布日志
export const GET_PUBLISH_LOGS = gql`
  query getPublishLogs($serviceId: ID!) {
    latestReleaseByServiceId(serviceId: $serviceId) {
      id
      serviceId
      pipelineId
      status
    }
  }
`;

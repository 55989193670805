import { MlCategory } from '@/store/mlCategory';

interface MlAbilityTagColorType {
  color: string;
  borderColor: string;
}

export const AbilityTagKey = {
  All: '全部',
  NLP: '自然语言处理',
  Multimodal: '多模态',
  TextGeneration: '文本生成',
  ImageTextGeneration: '图文生成',
  SubAll: '全部(子)',
}

const SubAll = {
  label: '全部',
  value: AbilityTagKey.SubAll,
}


const TextGeneration = {
  label: '文本生成',
  value: AbilityTagKey.TextGeneration,
}

const ImageTextGeneration = {
  label: '图文生成',
  value: AbilityTagKey.ImageTextGeneration,
}

const MlAbilityTagColor: Record<string, MlAbilityTagColorType> = {
  [AbilityTagKey.NLP]: {
    color: '#36BD1F',
    borderColor: 'rgba(54, 189, 31, 0.60)',
  },
  [AbilityTagKey.Multimodal]: {
    color: '#DB69A6',
    borderColor: 'rgba(219, 105, 166, 0.60)',
  },
}

export const AbilityTags = [
  {
    label: '全部',
    value: AbilityTagKey.All,
    children: [SubAll, TextGeneration, ImageTextGeneration]
  },
  {
    label: '自然语言处理',
    value: AbilityTagKey.NLP,
    children: [SubAll, TextGeneration],
    color: MlAbilityTagColor[AbilityTagKey.NLP]
  },
  {
    label: '多模态',
    value: AbilityTagKey.Multimodal,
    children: [SubAll, ImageTextGeneration],
    color: MlAbilityTagColor[AbilityTagKey.Multimodal]
  }
]

export const getAbilityTagValue = (abilityTags: MlCategory[] = [], categoryUid: string) => {
  const target = abilityTags.find(item => item.categoryUuid === categoryUid);
  return !!target ? `${target.firstCategory}/${target.secondCategory}` : '';
};
import { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Divider, Drawer, Input, List, Radio, Select, Space, Tooltip } from 'antd';
import { Flex } from '@fuxi/eevee-ui';
import { getPublishModules, ServiceModuleType, ServiceVersionMap } from '@/constants';
import { getServiceVersions } from '@/service/version';
import ProjectDetailContainer from '@/pages/project/ProjectDetail/ProjectDetailContainer';
import { ForcePushTerminalVersionModal } from '../ForcePushTerminalVersionModal';
import cx from './index.module.less';

interface Prop {
  children?: React.ReactNode;
  serviceId?: string;
}

export const PublishVersionDrawer = (props: Prop) => {
  const container = ProjectDetailContainer.useContainer();
  const serviceList = container.serviceList;
  const project = container.currentProject;
  const { children, serviceId } = props;
  // const { showDrawer, commonAntDrawerProps } = useAntDrawer();
  const [open, setOpen] = useState(false);
  const [currentServiceId, setCurrentServiceId] = useState(serviceId);
  const [category, setCategory] = useState(project?.isTerminal ? ServiceModuleType.Edge : ServiceModuleType.Cloud);
  const [queryServiceVersionsLoading, setQueryServiceVersionsStatus] = useState(false);
  const [serviceVersions, setServiceVersions] = useState<ServiceVersion>();

  const [searchValue, setSearchValue] = useState('');

  const fetchVersions = async () => {
    if (currentServiceId) {
      setQueryServiceVersionsStatus(true);
      try {
        const res = await getServiceVersions(currentServiceId);
        setServiceVersions(res?.serviceVersions);
        setQueryServiceVersionsStatus(false);
      } catch (e) {
        setQueryServiceVersionsStatus(false);
      }
    }
  };

  useEffect(() => {
    if (open) {
      setCategory(project?.isTerminal ? ServiceModuleType.Edge : ServiceModuleType.Cloud);
      fetchVersions();
    }
  }, [currentServiceId]);

  const getDataSource = (): PublishVersion[] => {
    // @ts-ignore
    const services: PublishVersion[] = serviceVersions?.[ServiceVersionMap[category]];
    return services?.filter((service: PublishVersion) => service.alias.includes(searchValue));
  };

  const onCategoryChange = (e: ServiceModuleType) => {
    setCategory(e);
  };

  const onSearchValueChange = (value: string) => {
    setSearchValue(value);
  };

  const init = (e: boolean) => {
    if (!e) {
      setServiceVersions(undefined);
      setCurrentServiceId(serviceId);
    } else {
      fetchVersions();
    }
  };
  // @ts-ignore
  return (
    <div>
      <div onClick={() => setOpen(true)}>{children}</div>
      <Drawer
        open={open}
        destroyOnClose
        afterVisibleChange={e => init(e)}
        width={970}
        title={'查看发布版本'}
        // closable={true}
        className={cx('drawer-container')}
        closable
        onClose={() => setOpen(false)}
        // {...commonAntDrawerProps}
      >
        <Flex alignCenter>
          <Flex.Item className={cx('service-title')} flex={0}>
            服务器名称：
          </Flex.Item>
          <Select
            dropdownClassName={cx('select-service-dropdown')}
            value={currentServiceId}
            className={currentServiceId ? cx('version-select') : cx('version-select-required')}
            disabled={!!serviceId}
            showSearch
            placeholder="请选择服务器"
            onChange={(id: string) => setCurrentServiceId(id)}
            options={serviceList.map(({ id, name }) => {
              return {
                value: id,
                label: name,
              };
            })}
            // @ts-ignore
            filterOption={(input, option) => {
              return String(option?.label).toLowerCase().includes(input.toLowerCase());
            }}
          />
        </Flex>
        <Divider></Divider>
        <Radio.Group
          className={cx('category-selector')}
          value={category}
          onChange={e => onCategoryChange(e.target.value)}>
          {Object.keys(getPublishModules(project?.isTerminal)).map(type => (
            <Radio.Button className={cx('category-button')} key={type} value={type}>
              <Space>{getPublishModules(project?.isTerminal)?.[type]?.name}</Space>
            </Radio.Button>
          ))}
        </Radio.Group>

        <Flex className={cx('search-wrapper')}>
          <Input
            onChange={e => onSearchValueChange(e.target.value)}
            placeholder="搜索版本别名"
            prefix={<SearchOutlined className={cx('search-icon')}></SearchOutlined>}></Input>
          {category === ServiceModuleType.Edge && (
            <ForcePushTerminalVersionModal
              serviceVersions={getDataSource()}
              service={serviceList.find(s => s.id === currentServiceId)!}
              // children={
              //   <Button disabled={!currentServiceId} className={cx('push-button')} type="primary">
              //     <Flex alignCenter>
              //       <IconFont className={cx('push-icon')} type="icon-qiangzhituisong"></IconFont>
              //       强制推送版本
              //     </Flex>
              //   </Button>
              // }
            ></ForcePushTerminalVersionModal>
          )}
        </Flex>

        <List
          className={cx('publish-list')}
          itemLayout="vertical"
          size="large"
          pagination={{
            pageSize: 7,
            hideOnSinglePage: true,
          }}
          dataSource={getDataSource()}
          footer={<div className={cx('list-footer')}>共 {getDataSource()?.length} 条发布记录</div>}
          renderItem={item => (
            <List.Item
              key={item.tag}
              extra={
                category === ServiceModuleType.Edge ? (
                  <ForcePushTerminalVersionModal
                    initialVersionId={item.tag}
                    serviceVersions={getDataSource()}
                    service={serviceList.find(s => s.id === currentServiceId)!}
                    // children={
                    //   <div className={cx('extra-button-wrapper')}>强制推送</div>
                    // }
                  ></ForcePushTerminalVersionModal>
                ) : (
                  <></>
                )
              }>
              <div className={cx('alias')}>
                <Tooltip placement="topLeft" title={item.alias}>
                  {item.alias}
                </Tooltip>
              </div>
              <div className={cx('publish-date')}>{item.publishTime}</div>
            </List.Item>
          )}
        />
      </Drawer>
    </div>
  );
};

export const RbacTokenName = 'RBAC_TOKEN';
export const RbacUserName = 'RBAC_USER';
export const DanluDomain = '.danlu.netease.com';
export const NeteaseDomain = 'youling.netease.com';
export const YLNeteaseDomain = 'yl.netease.com';

// 字母下划线开头 只允许字母下划线数字 最长64位 此方法全英文时不会做长度校验
export const PythonVariableNameReg = /^[_a-zA-Z]+[_a-zA-Z0-9]{0,63}$/;

// 匹配中文 python3支持中文 但是 数据库不支持
export const CNReg = /[\u4e00-\u9fa5]+$/;

export const RequiredMessage = '必填，名称不得为空';

export const PythonKeywordList = [
  'False',
  'None',
  'True',
  'and',
  'as',
  'assert',
  'break',
  'class',
  'continue',
  'def',
  'del',
  'elif',
  'else',
  'except',
  'finally',
  'for',
  'from',
  'global',
  'if',
  'import',
  'in',
  'is',
  'lambda',
  'nonlocal',
  'not',
  'or',
  'pass',
  'raise',
  'return',
  'try',
  'while',
  'with',
  'yield',
];

export const PythonBuiltInFuncNameList = [
  'abs',
  'dict',
  'help',
  'min',
  'setattr',
  'all',
  'dir',
  'hex',
  'next',
  'slice',
  'any',
  'divmod',
  'id',
  'object',
  'sorted',
  'ascii',
  'enumerate',
  'input',
  'oct',
  'staticmethod',
  'bin',
  'eval',
  'int',
  'open',
  'str',
  'bool',
  'exec',
  'isinstance',
  'ord',
  'sum',
  'bytearray',
  'filter',
  'issubclass',
  'pow',
  'super',
  'bytes',
  'float',
  'iter',
  'print',
  'tuple',
  'callable',
  'format',
  'len',
  'property',
  'type',
  'chr',
  'frozenset',
  'list',
  'range',
  'vars',
  'classmethod',
  'getattr',
  'locals',
  'repr',
  'zip',
  'compile',
  'globals',
  'map',
  'reversed',
  '__import__',
  'complex',
  'hasattr',
  'max',
  'round',
  'reload',
  'delattr',
  'hash',
  'memoryview',
  'set',
];

export const SystemNameList = [
  'object_id',
  'register_class',
  'define_state',
  'enable_persist',
  'register_txn',
  'register_rpc',
  'set_create_arguments',
  'enable_auto_load',
  'enable_auto_create',
  'enable_auto_recycle',
  'set_placement_func',
  'create_async',
  'create_by_id_async',
  'delete',
  'recycle',
  'id',
  'ObjectRef',
  'save_to_db_async',
  'load_from_db_async',
  'set_timer',
  'clear_timer',
  'set_object_rpc_timeout',
  'save_to_db_async',
];

export const JavascriptKeyWordList = [
  'break',
  'case',
  'catch',
  'continue',
  'default',
  'delete',
  'do',
  'else',
  'finally',
  'for',
  'function',
  'if',
  'in',
  'instanceof',
  'new',
  'return',
  'switch',
  'this',
  'throw',
  'try',
  'typeof',
  'var',
  'void',
  'while',
  'with',
  '$scope',
];

export const PodNameSuffix = '-edge-objserv-0';

export const RoutePathname = {
  project: '/project',
  publicAgent: '/ai-capability',
  review: '/review',
  template: '/template',
  ml: '/ml',
}
import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { Button, Flex, Form, Input, Space, Spin, Tooltip, message } from '@fuxi/eevee-ui';
import { useForm } from 'antd/es/form/Form';

import { ruleMap } from '@ai-training/utils/validator';
import { getServiceUrlSuffix } from '@ai-training/utils';

import { useRequest } from '@fuxi/eevee-hooks';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ml from '@/service/ml';
import { useAppSelector } from '@/hooks';

import { getCurrentUrl } from '../utils/getCurrentUrl';
import cx from './index.module.less';

const VITE_SERVICE_URL_SUFFIX = getServiceUrlSuffix();

export const ModalServiceCreate = () => {
  const location = useLocation();
  const [initialLocationUrl, setInitialLocationUrl] = useState<string>(`${location.pathname}${location.search}`);
  const [searchParamsState] = useSearchParams();
  const abilityIdInSearchParams = searchParamsState.get('abilityId');
  const versionIdInSearchParams = searchParamsState.get('versionId');
  const navigate = useNavigate();
  const { getMlPublicCapabilities, getCapabilityVersions, releaseCapability, domainCheck } = ml;
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const {
    data: capability,
    run: fetchCapabilities,
    loading: loadingCaps,
  } = useRequest(getMlPublicCapabilities, { manual: true });
  const {
    data: versions,
    run: fetchVersions,
    loading: loadingVersions,
  } = useRequest((params: { id: string }) => getCapabilityVersions(params), {
    manual: true,
  });

  const [submitLoading, setSubmitLoading] = useState<boolean>();

  useEffect(() => {
    if (!aiProjectId) {
      return;
    }
    fetchCapabilities({
      share: false,
    });

    form.setFieldsValue({
      capability_id: abilityIdInSearchParams,
      capability_version_id: versionIdInSearchParams,
    });

    setInitialLocationUrl(`${location.pathname}${location.search}`);

  }, [aiProjectId]);

  useEffect(() => {
    if (abilityIdInSearchParams) {
      fetchVersions({ id: abilityIdInSearchParams });
    }
  }, [abilityIdInSearchParams]);

  const handelAbilityChange = (versionId: string) => {
    fetchVersions({ id: versionId });
    form.setFieldsValue({
      capability_version_id: null,
    });
  };

  const cancel = () => {
    if (!!abilityIdInSearchParams && !!versionIdInSearchParams) {
      navigate(`../../ai-platform/ml-project-ability/${abilityIdInSearchParams}`);
    } else if (!!abilityIdInSearchParams) {
      navigate(`../../ai-platform/ml-project-ability`);
    } else {
      navigate('../');
    }
  };

  const handelSave = () => {
    form
      .validateFields()
      .then(async values => {
        setSubmitLoading(true);
        values.domain = values.domain + VITE_SERVICE_URL_SUFFIX;
        const { data } = await releaseCapability(values);
        message.success('发布成功');
        if (getCurrentUrl() === initialLocationUrl && initialLocationUrl.includes('/model-service/create')) {
          navigate(`../${data.id}`);
        }
      })
      .catch(() => {
        setSubmitLoading(false);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  const [form] = useForm();

  const domainValidatorCallback = async (_, value) => {
    if (value) {
      const { data } = await domainCheck({ domain: value + VITE_SERVICE_URL_SUFFIX });
      if (data === false) {
        return Promise.reject('接口地址已存在');
      }
    }
    return Promise.resolve();
  };

  return (
    <Spin spinning={loadingCaps || loadingVersions} tip="加载中...">
      <Flex justifyCenter>
        <Form
          className={cx('form')}
          form={form}
          labelAlign="right"
          autoComplete="off"
          initialValues={{}}
          labelCol={{ span: 5 }}>
          <Form.Item label="能力" name="capability_id" rules={[{ required: true, message: '请选择能力' }]}>
            <Select
              showSearch
              listHeight={160}
              placeholder="请选择"
              optionFilterProp="label"
              onSelect={handelAbilityChange}
              options={capability?.data?.list?.map(item => ({
                value: item.uuid,
                label: item.name,
                desc: item.description,
              }))}
              optionRender={option => (
                <Flex justifyBetween>
                  <div>{option.data.label}</div>
                  <Tooltip className={cx('desc-in-selector')} placement="topLeft" title={option.data.desc}>
                    {option.data.desc}
                  </Tooltip>
                </Flex>
              )}
            />
          </Form.Item>
          <Form.Item label="版本" name="capability_version_id" rules={[{ required: true, message: '请选择版本' }]}>
            <Select
              disabled={!form.getFieldValue('capability_id')}
              listHeight={160}
              placeholder="请选择"
              options={versions?.data?.list.map(item => ({
                value: item.uuid,
                label: item.code,
                desc: item.description,
              }))}
              optionRender={option => (
                <Flex justifyBetween>
                  <div>{option.data.label}</div>
                  <Tooltip className={cx('desc-in-selector')} placement="topLeft" title={option.data.desc}>
                    {option.data.desc}
                  </Tooltip>
                </Flex>
              )}
            />
          </Form.Item>
          <Form.Item
            label="服务名称"
            name="name"
            rules={[
              { required: true, message: '请输入服务名称' },
              { pattern: /^[a-z0-9-]+$/, message: '只能输入小写字母、数字和连字符（-）' },
              {
                pattern: /^[a-z].*/,
                message: '只能由小写字母开头',
              },
              {
                pattern: /.*[^-]$/,
                message: '不能以中划线结尾',
              },
            ]}
            extra={
              <span className={cx('extra')}>
                {'说明：由小写字母、数字、中划线组成 (开头为小写字母且不能以中划线结尾)'}
              </span>
            }>
            <Input placeholder="请输入" maxLength={20} showCount />
          </Form.Item>
          <Form.Item
            required
            label="接口地址"
            name="domain"
            validateDebounce={300}
            rules={[
              { required: true, message: '请输入接口地址' },
              ruleMap.isDomain,
              {
                validator: domainValidatorCallback,
                message: '接口地址已存在',
              },
            ]}
            extra={
              <span className={cx('extra')}>
                域名必须由小写字母、数字、“-”和“.”组成，首字符必须为字母或数字，尾字符必须为字母或数字!
              </span>
            }>
            <Input placeholder="请输入" addonAfter={VITE_SERVICE_URL_SUFFIX} maxLength={63} />
          </Form.Item>
          <Form.Item label="描述" name="desc">
            <Input.TextArea rows={4} placeholder="请输入" maxLength={200} showCount />
          </Form.Item>
          <Space direction="horizontal" size={16}>
            <div />
          </Space>
          <Form.Item label=" " colon={false}>
            <Space>
              <Button onClick={cancel}>取消</Button>
              <Button type="primary" onClick={handelSave} loading={submitLoading}>
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Flex>
    </Spin>
  );
};

import { gql } from '@apollo/client';

// 获取历史日志
export const GET_HISTORY_LOG = gql`
  query LogSearch($logSearchInput: LogSearchInput) {
    logSearch(logSearchInput: $logSearchInput)
  }
`;

// 获取发布日志
export const GET_PUBLISH_LOGS = gql`
  query PublishLog($input: String!) {
    publishLog(input: $input)
  }
`;

// pod list
export const GET_POD_INFO_LIST = gql`
  query PodInfoList($taskId: String!) {
    podInfoList(taskId: $taskId)
  }
`;

// 获取实时日志的url
export const GET_REALTIME_LOG_URL = gql`
  query GetRealtimeLogUrl($taskId: String!, $podNameList: String!) {
    massageRobotLogUrl(taskId: $taskId, podNameList: $podNameList)
  }
`;

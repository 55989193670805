/* eslint-disable react-func/max-lines-per-function */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { cloneDeep } from 'lodash';
import { getReviewAppraise, getStageProjects } from '@/service/miniReview';

import { AppThunk } from '@/store/index';

export type Stage =
  | 'DEMO' //demo评审
  | 'MID' //# 中期评审
  | 'FINAL'; //# 项目终审

export type ReviewStatus =
  | 'REVIEW_STATUS_UNKNOWN' //未保存
  | 'STAGING' //暂存
  | 'SUBMIT' //提交
  | 'APPRAISED'; //确定

export type ProjectSeason = {
  id: string;
  name: string;
};

export type ProjectWithAllJudge = {
  projectId: string;
  projectName: string;
  description: string;
  image: string;
  judges: {
    judge: string;
    judgeName: string;
  }[];
  demoStageOrder?: number;
  midStageOrder?: number;
  finalStageOrder?: number;
};

export type ProjectReview = {
  projectId: string; //项目Id
  projectName: string; //项目名称
  description: string; //项目简述
  image: string; //项目图片
  stage: Stage; //项目评审阶段
  status: ReviewStatus; //状态
  judge: string; //评委邮箱
  judgeName: string; //评委姓名
  experience: number; //核心体验
  innovation: number; //创新性
  artisticExpression: number; //艺术表现力
  effect: number; //技术实现效果
  difficulty: number; //上手难度
  prospect: number; //商业化前景
  score: number; //整体评分
  isRecommend: boolean; //是否推荐上线
  issue: string; //项目评价建议
  startTime: string; //评委评分开始时间
  endTime: string; //评委评分结束时间
  [props: string]: any;
};

export type ProjectReviewAppraise = {
  judge: string;
  judgeName: string;
  appraises: ProjectReview[];
};

export type ReviewStage = {
  id: string;
  startTime: string;
  endTime: string;
  stage: Stage;
  seasonId: string;
};

export type miniReviewState = {
  currentSeasonStage: {
    seasonId: string;
    stageId: string;
    stage?: Stage;
  };
  projectReviewAppraises: ProjectReviewAppraise[];
  reviewStages: ReviewStage[];
  projectWithAllJudges: ProjectWithAllJudge[];
  isSpin: boolean;
};

const initialState: miniReviewState = {
  currentSeasonStage: {
    seasonId: '',
    stageId: '',
  },
  projectReviewAppraises: [],
  reviewStages: [],
  projectWithAllJudges: [],
  isSpin: true,
};

const project = createSlice({
  name: 'miniReview',
  initialState,
  reducers: {
    setSeasonStage(
      state: miniReviewState,
      { payload }: PayloadAction<{ seasonId?: string; stageId?: string; stage?: Stage }>,
    ) {
      const { seasonId, stageId, stage } = payload;
      state.currentSeasonStage = {
        seasonId: seasonId || state.currentSeasonStage.seasonId,
        stageId: stageId || state.currentSeasonStage.stageId,
        stage: stage || state.currentSeasonStage.stage,
      };
    },
    setReviewAppraises(state: miniReviewState, { payload }: PayloadAction<ProjectReviewAppraise[]>) {
      state.projectReviewAppraises = payload;
    },
    setReviewStages(state: miniReviewState, { payload }: PayloadAction<ReviewStage[]>) {
      state.reviewStages = payload;
    },
    setProjectWithAllJudge(state: miniReviewState, { payload }: PayloadAction<ProjectWithAllJudge[]>) {
      state.projectWithAllJudges = payload;
    },
    setIsSpinTrue(state: miniReviewState) {
      state.isSpin = true;
    },
    setIsSpinFalse(state: miniReviewState) {
      state.isSpin = false;
    },
  },
});

export const getReviewAppraisesAction =
  (stageId: string): AppThunk =>
  async (dispatch, getState) => {
    const reviewAppraise = await getReviewAppraise(stageId);
    if (!reviewAppraise) return;
    try {
      reviewAppraise.forEach(item => {
        item.appraises.forEach(appraise => {
          const reviewDetail = JSON.parse(appraise.reviewDetail);
          reviewDetail?.status && delete reviewDetail.status;
          delete appraise.reviewDetail;
          Object.assign(appraise, reviewDetail);
        });
        item.appraises.sort((a: { score: number }, b: { score: number }) => b.score - a.score);
      });
    } catch (e) {
      console.log(e);
    }

    dispatch(setReviewAppraises(reviewAppraise));
    setTimeout(() => {
      dispatch(setIsSpinFalse());
    }, 500);
  };

export const getProjectWithAllJudgeAction =
  (stageId: string): AppThunk =>
  async (dispatch, getState) => {
    const res = await getStageProjects(stageId);
    if (!res) return;
    const projectWithAllJudges = cloneDeep(res.stageProjects.projects);
    projectWithAllJudges.forEach(item => {
      item.image = item.icon;
      item.projectId = item.id.toString();
      item.projectName = item.name;
      delete item.icon;
      delete item.id;
      delete item.name;
    });
    dispatch(setProjectWithAllJudge(projectWithAllJudges));
  };

export const {
  setReviewStages,
  setReviewAppraises,
  setSeasonStage,
  setProjectWithAllJudge,
  setIsSpinTrue,
  setIsSpinFalse,
} = project.actions;

export default project.reducer;

import { gql } from '@apollo/client';

export const UPLOAD_REVIEW_APPRAISE = gql`
  mutation UploadReviewAppraise($UploadReviewAppraiseInput: UploadReviewAppraiseInput) {
    uploadReviewAppraise(input: $UploadReviewAppraiseInput)
  }
`;

export const UPDATE_PROJECT_REVIEW = gql`
  mutation UpdateProjectReviewByManager($input: UpdateProjectReviewInput) {
    updateProjectReviewByManager(input: $input)
  }
`;

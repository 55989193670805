import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Switch, Form, Input } from '@fuxi/eevee-ui';
import bindClass from 'classnames/bind';
import { FieldNamesForTrain } from '@ai-training/constants/ability';
import { TrainFormDataConfig, FormValues } from '@ai-training/types/ability';
import { ruleMap } from '@ai-training/utils/validator';
import styles from './index.module.less';

const cx = bindClass.bind(styles);

const rightAlignedFormItemLayout = {
  labelCol: { span: 3 }, // Adjust offset as necessary to align to the right
  wrapperCol: { span: 12 },
};

interface DataSetOfFormRef {
  extractDataFromFormValues: (values: FormValues) => TrainFormDataConfig;
}

interface DatasetOfFormProps extends React.RefAttributes<DataSetOfFormRef> {
  datasetName: FieldNamesForTrain.EvalDatasetName | FieldNamesForTrain.TrainDatasetName;
}

const DataSetOfForm: React.FC<DatasetOfFormProps> = forwardRef(({ datasetName }, ref) => {
  const [isLoadDataset, setIsLoadDataset] = useState<boolean>(true); // 是否挂载数据集
  const form = Form.useFormInstance();
  const isMountDataset = form.getFieldValue(FieldNamesForTrain.IsMountDataset);

  const handleIsLoadDataset = (checked: boolean) => {
    setIsLoadDataset(checked);
  };

  useEffect(() => {
    if (isMountDataset) {
      setIsLoadDataset(true);
    } else {
      setIsLoadDataset(false);
    }
  }, [isMountDataset]);

  useImperativeHandle(ref, () => ({
    extractDataFromFormValues(values: FormValues) {
      return {
        [datasetName]: values[datasetName],
      };
    },
  }));

  return (
    <>
      <Form.Item
        label="数据集挂载"
        name={FieldNamesForTrain.IsMountDataset}
        valuePropName="checked"
        rules={[{ required: true, message: '' }]}
        {...rightAlignedFormItemLayout}>
        <Switch onChange={handleIsLoadDataset} />
      </Form.Item>

      {isLoadDataset && (
        <Form.Item
          label="数据集名称"
          name={datasetName}
          rules={[{ required: true, message: '请输入数据集名称' }, ruleMap.isValidDatasetName]}
          {...rightAlignedFormItemLayout}
          wrapperCol={{ span: 13 }}>
          <Input placeholder="请输入数据集名称" className={styles['datatset-input']} />
        </Form.Item>
      )}
    </>
  );
});

export default DataSetOfForm;

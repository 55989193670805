import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useAppDispatch, useAppSelector } from '@/hooks';
import createContainer from '@/hooks/createContainer';
import { setCurrentProject, setShowDeleteModal, setShowEditModal } from '@/store/project';
import { GET_PROJECTS } from '@/service/schema/project/project-queries';
import { CREATE_PROJECT, DELETE_PROJECT, UPDATE_PROJECT } from '@/service/schema/project/project-mutations';
import { ProjectType } from './const';
import { isTemplateProject } from './utils';

const useProjectState = () => {
  const [showCreateModal, setShowCreate] = useState(false);
  const [showCreateEntryModal, setShowCreateEntry] = useState(false);
  const [showInTable, setShowInTable] = useState(false);
  const [showOnlyTpl, setShowOnlyTpl] = useState(false);
  const mlProjectList = useAppSelector(state => state.project.mlProjectList);
  const projectListFetching = useAppSelector(state => state.project.projectListFetching);
  const currentProject = useAppSelector(state => state.project.currentProject);
  const showEditModal = useAppSelector(state => state.project.showEditModal);
  const showDeleteModal = useAppSelector(state => state.project.showDeleteModal);
  const industry = useAppSelector(state => state.template.industry);
  const userInfo = useAppSelector(state => state.user);

  // 列表数据
  const { data: projectsData, loading: projectListLoading } = useQuery<{
    projects: { items: Project[]; total: number };
  }>(GET_PROJECTS, {
    fetchPolicy: 'cache-first',
  });

  const { items: allProjectList = [], total: allTotal = -1 } = projectsData?.projects || {};
  // const projectList = mlProjectList.filter(p => !showOnlyTpl || isTemplateProject(p));
  const total = mlProjectList.length || -1;

  // 创建项目
  const [postCreateProject, { loading: createLoading }] = useMutation(CREATE_PROJECT, {
    refetchQueries: [GET_PROJECTS],
  });

  // 编辑项目
  const [postEditProject, { loading: editLoading }] = useMutation(UPDATE_PROJECT, { refetchQueries: [GET_PROJECTS] });

  // 删除项目
  const [postDelete, { loading: deleteLoading }] = useMutation(DELETE_PROJECT, { refetchQueries: [GET_PROJECTS] });

  const dispatch = useAppDispatch();

  const fetchProjects = () => {
    dispatch({
      type: 'project/fetchProjects',
    });
  };

  // TODO 用户列表，用于新建项目添加新成员
  // const fetchUsers = () => {
  //   dispatch({
  //     type: 'project/fetchUsers',
  //     payload: {},
  //   });
  // };

  const dispatchSetProject = (payload: Project) => {
    dispatch(setCurrentProject(payload));
  };

  const setShowEdit = (visible: boolean) => {
    dispatch(setShowEditModal(visible));
  };

  const setShowDelete = (visible: boolean) => {
    dispatch(setShowDeleteModal(visible));
  };

  const editProject = (payload: Project) => {
    dispatchSetProject(payload);
    setShowEdit(true);
  };

  const deleteProject = (payload: Project) => {
    dispatchSetProject(payload);
    setShowDelete(true);
  };

  return {
    showCreateModal,
    showEditModal,
    showDeleteModal,
    showCreateEntryModal,
    showOnlyTpl,
    showInTable,
    currentProject,
    projectList: mlProjectList,
    total,
    projectListLoading: projectListFetching,
    createLoading,
    editLoading,
    deleteLoading,
    userInfo,
    industry,
    setShowCreate,
    setShowEdit,
    setShowDelete,
    setShowOnlyTpl,
    setShowCreateEntry,
    editProject,
    deleteProject,
    setShowInTable,
    postCreateProject,
    postEditProject,
    postDelete,
    fetchProjects,
    dispatchSetProject,
  };
};

const ProjectContainer = createContainer(useProjectState);

export default ProjectContainer;

import client from '@/service/graphql';
import { UpdateEdgeNodeInput } from '@/store/terminal';
import { RESET_TERMINAL, UPDATE_TERMINAL } from './schema/terminal/terminal-mutation';
import { GET_TERMINALS, GET_WEB_TERMINAL_URL } from './schema/terminal/terminal-queries';

export const getTerminals = (projectId: ID) => {
  return client.query(GET_TERMINALS, { projectId: projectId });
};

export const updateTerminal = (input: UpdateEdgeNodeInput) => {
  return client.mutate(UPDATE_TERMINAL, {
    projectId: input.projectId,
    input: input.input,
  });
};

export const resetTerminal = (projectId: ID, edgeNodeIds: string[]) => {
  return client.mutate(RESET_TERMINAL, { projectId, edgeNodeIds });
};

export const getWebTerminalUrl = (edgeNodeId: ID) => {
  return client.query<{ edgeNodeId: ID }, { edgeNodeWebUrl: string }>(GET_WEB_TERMINAL_URL, { edgeNodeId });
};

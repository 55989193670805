import Axios, { WebInstance } from './common';

const user = {
  // 退出
  logout() {
    return Axios.get(`/api/v1/logout`);
  },
  // 获取单个用户
  getUser<T = any>(params) {
    return Axios.get<T>(`/api/v1/users/${params.userId}`);
  },
  // 获取用户列表
  getUserList<T = any>(params) {
    return Axios.get<T>(`/api/v1/users`, { params });
  },
  // 获取当前用户
  getCurrentUser<T = any>() {
    return Axios.get<T>(`/api/v1/user/current`);
  },
  // 获取用户的角色（是否为项目的内部用户）
  getUserRoles<T = any>(params) {
    return WebInstance.get<T>(`/auth/${params.projectId}/type`);
  },
  // 获取用户的角色（是否为伏羲的内部/外部用户）
  getUserRolesForFuxi<T = any>() {
    return WebInstance.get<T>(`/auth/user/type`);
  },
};

export default user;

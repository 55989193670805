import bindClass from 'classnames/bind';
import { Switch, Form, Row, TreeSelect, Typography, Col, Layout, message } from '@fuxi/eevee-ui';
import { useSearchParams } from 'react-router-dom';
import React, { forwardRef, useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  selectFormDataForEvaluateOfTrain,
  selectIsEnableEvaluteOfTrain,
  setIsEnableEvaluateOfTrain,
  selectCurrentVersionStatus,
  selectFormDataForBaseOfTrain,
  setFieldsForEvaluateOfTrain,
} from '@/store/aiTraining';
import { useAppSelector } from '@/hooks/useAppSelector';
import {
  CurrentVersionStatus,
  DEFAULT_PROJECT_STORAGE,
  FieldNamesForTrain,
  VersionStatus,
  IDL_CONFIG_ENV_VAS,
} from '@ai-training/constants/ability';
import RuntimeConfigOfForm from '../RuntimeConfigOfForm';
import DataSetOfForm from '../DataSetOfForm';
import styles from './index.module.less';
import { useParams } from 'react-router';
import { TrainFormDataConfig, TreeNodeForSelect } from '@ai-training/types/ability';
import { convertOriginFormDataToValues, isFormDataEmpty } from '../NewAIBodyVersion';

const { Text } = Typography;
const cx = bindClass.bind(styles);

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 21 },
};

interface FormValues {
  [key: string]: any;
}

type EvaluteOfTrainProps = {
  children: React.ReactNode;
};

export const getFieldsForEvaluateOfTrain = (formDataForEvalueOfTrain, formDataForBaseOfTrain) => {
  // format the data to display
  const fields = convertOriginFormDataToValues(formDataForEvalueOfTrain);
  const evaluateDataSetName = formDataForBaseOfTrain[FieldNamesForTrain.EvalDatasetName];
  if (evaluateDataSetName) {
    fields[FieldNamesForTrain.EvalDatasetName] = evaluateDataSetName;
    fields[FieldNamesForTrain.IsMountDataset] = true;
  } else {
    fields[FieldNamesForTrain.IsMountDataset] = false;
    fields[FieldNamesForTrain.EvalDatasetName] = null;
  }

  return fields;
};

const EvaluteOfTrain = forwardRef((props: EvaluteOfTrainProps, ref) => {
  const dispatch = useAppDispatch();
  const currentVersionStatus = useAppSelector(selectCurrentVersionStatus);
  const runtimeConfigRef = useRef<any>({});
  const datasetConfigRef = useRef<any>({});
  const [form] = Form.useForm();
  const isEnableEvaluteOfTrain = useAppSelector(selectIsEnableEvaluteOfTrain);
  const formDataForEvalueOfTrain = useAppSelector(selectFormDataForEvaluateOfTrain);
  const formDataForBaseOfTrain = useAppSelector(selectFormDataForBaseOfTrain);
  const [searchParams] = useSearchParams();
  const versionStatus = searchParams.get('version_status');

  const handleEnableConfigOfTrain = (checked: boolean) => {
    dispatch(setIsEnableEvaluateOfTrain(checked));
  };

  useImperativeHandle(ref, () => ({
    getFieldValues() {
      return form.getFieldsValue(true);
    },
    submit(cb: (finalFormData: TrainFormDataConfig) => Promise<void>) {
      form
        .validateFields()
        .then(async (values: FormValues) => {
          const runtimeConfig = runtimeConfigRef.current?.extractDataFromFormValues?.(values) ?? {};
          const datasetConfig = datasetConfigRef.current?.extractDataFromFormValues?.(values) ?? {};
          const finalFormData: TrainFormDataConfig = {
            ...runtimeConfig,
            ...datasetConfig,
          } as TrainFormDataConfig;

          finalFormData[FieldNamesForTrain.Env] = values[FieldNamesForTrain.Env].filter((item: any) => item?.name);
          finalFormData[FieldNamesForTrain.Env].push(...IDL_CONFIG_ENV_VAS);
          finalFormData[FieldNamesForTrain.ProjectStorage] = values[FieldNamesForTrain.ProjectStorage];
          // 调用回调函数，让其进行下一步
          typeof cb === 'function' && cb(finalFormData);
        })
        .catch(err => {
          message.error('请完成所有必填项!');
        });
    },
  }));

  useEffect(() => {
    if (currentVersionStatus === CurrentVersionStatus.Update) {
      // 判断下是否有值
      if (isFormDataEmpty(formDataForEvalueOfTrain)) {
        if (versionStatus === VersionStatus.Update) {
          dispatch(setIsEnableEvaluateOfTrain(false));
        }
        return;
      }
      const fields = getFieldsForEvaluateOfTrain(formDataForEvalueOfTrain, formDataForBaseOfTrain);
      fields[FieldNamesForTrain.Args] = (fields[FieldNamesForTrain.Args] ?? [])
        .filter(item => item?.name)
        .map(item => item?.name)
        .join('');
      form.setFieldsValue(fields ?? {});
    }
  }, [currentVersionStatus, versionStatus]);

  useEffect(() => {
    // 在组件卸载的时候，将表单数据保存到 store 中
    return () => {
      const fields = form.getFieldsValue(true);
      dispatch(setFieldsForEvaluateOfTrain(fields));
    };
  }, []);

  return (
    <Layout className={styles.container}>
      <Row className={styles.row}>
        <Text className={styles['train-config-text']}>评估配置</Text>
        <Switch checked={isEnableEvaluteOfTrain} onChange={handleEnableConfigOfTrain} />
        <Text className={styles['train-config-desc']}>若无配置需要请关闭</Text>
      </Row>
      {isEnableEvaluteOfTrain ? (
        <>
          <Form
            form={form}
            name="validate_other"
            colon
            className={styles['form-submit-resource']}
            labelAlign="right"
            initialValues={{
              [FieldNamesForTrain.Code]: false,
              [FieldNamesForTrain.Resource]: {
                cpu: 1,
                cpuUnit: '核',
                memory: 1,
                memoryUnit: 'GB',
                gpu: 0,
              },
              [FieldNamesForTrain.Ports]: [{ port: '', protocol: 'tcp', external: true, public: false }],
              [FieldNamesForTrain.Env]: [{ name: '', value: '' }],
              [FieldNamesForTrain.IsMountDataset]: true,
              [FieldNamesForTrain.Args]: '',
            }}
            {...formItemLayout}>
            <RuntimeConfigOfForm ref={runtimeConfigRef} />

            <div className={styles['part-group-container']}>
              <Row className={styles['train-runtime']}>
                <Col span={2}>
                  <Text style={{ display: 'block' }}>其他配置</Text>
                </Col>
              </Row>
              <DataSetOfForm ref={datasetConfigRef} datasetName={FieldNamesForTrain.EvalDatasetName} />
            </div>
          </Form>
          {props.children}
        </>
      ) : (
        <div className={styles['empty-body']}>{props.children}</div>
      )}
    </Layout>
  );
});

export default EvaluteOfTrain;

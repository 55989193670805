import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import { Flex, Form, Select, Tooltip } from '@fuxi/eevee-ui';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormItemProps } from 'antd/es/form';

import { GET_COMMIT_TAGS } from '@/service/schema/project/project-queries';

import ProjectDetailContainer from '../ProjectDetailContainer';

import style from './VersionFormItem.module.less';

interface Props extends FormItemProps {
  serviceVersions?: ServiceVersion;
  serviceId?: string;
  placeholder?: string;
  getCommitTags?: (data: CommitTag[]) => void;
  onCommitTagChange?: (tag?: string) => void;
}

export const VersionFormItem: React.FC<Props> = ({
  placeholder = '请选择要发布的版本',
  serviceId,
  serviceVersions,
  ...props
}) => {
  const container = ProjectDetailContainer.useContainer();
  const { currentProject } = container;
  const { id: projectId } = currentProject;
  const { loading: loadingTags, data: commitData } = useQuery<{ commitTags: CommitTag[] }>(GET_COMMIT_TAGS, {
    variables: { projectId, serviceId },
  });
  const [currentServiceVersions, setCurrentServiceVersions] = React.useState<ServiceVersion>(serviceVersions || {});
  useEffect(() => {
    props?.getCommitTags?.(commitData?.commitTags!);
  }, [commitData?.commitTags]);

  const handleSelect = (e?: string) => {
    props?.onCommitTagChange?.(e);
  };

  useEffect(() => {
    serviceVersions && setCurrentServiceVersions(serviceVersions);
  }, [serviceVersions]);

  const getVersionByCommmitId = (commitId: string) => {
    if (isEmpty(currentServiceVersions)) {
      return;
    }
    const allServices = [
      ...(currentServiceVersions?.cloudVersions || []),
      ...(currentServiceVersions?.pageVersions || []),
      ...(currentServiceVersions?.terminalVersions || []),
    ];
    const version = allServices.find(item => item.tag === commitId);
    console.log(currentServiceVersions, allServices, version, version?.alias, commitId);
    return version;
  };

  return (
    <Form.Item
      name="tag"
      label="发布版本"
      required
      rules={[
        {
          required: true,
          message: placeholder,
        },
      ]}
      {...props}>
      <Select
        disabled={!serviceId}
        dropdownClassName={style('dropdown-wrapper')}
        onSelect={(e: string) => handleSelect(e as string)}
        showSearch
        loading={loadingTags}
        placeholder={placeholder}
        optionLabelProp="version"
        filterOption={(input: any, option: any) => {
          return String(option?.version).toLowerCase().includes(input.toLowerCase());
        }}>
        {commitData?.commitTags.map(({ commitId, msg, userEmail, commitTime }) => {
          return (
            <Select.Option key={commitId} value={commitId} version={`版本号 ${commitTime}`}>
              <div className={style('version-option')}>
                <span>版本号 {commitTime}</span>
                <Flex alignCenter className={style('description')}>
                  {<div className={style('alias')}>{getVersionByCommmitId(commitId)?.alias}</div>}
                  <Tooltip
                    title={
                      <div>
                        <div>提交者：{userEmail}</div>
                        <div>提交标识：{commitId}</div>
                        <div>提交时间：{commitTime}</div>
                        <div>提交说明：{msg}</div>
                      </div>
                    }>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Flex>
              </div>
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

import React, { useState } from 'react';
import { useAntdTable } from '@fuxi/eevee-hooks';
import { Table, Flex, Button, Input } from '@fuxi/eevee-ui';

import service from '../../service';
import { publicColumns as columns } from './const';
import { getSorterTableData } from '../../utils/util';
import cx from './index.module.less';

const DataSetPublic: React.FC = () => {
  const [keyword, setKeyword] = useState<string>();

  const {
    tableProps,
    search: { submit },
  } = useAntdTable(getSorterTableData(service.dataSet.getDatasets, { extraParams: { keyword, is_common: true } }));

  return (
    <div className={cx['main-page-wrapper']}>
      <div className={cx['main-page-title']}>公共数据集</div>
      <Flex justifyBetween rowReverse style={{ marginBottom: 20 }}>
        <div>
          <Input
            placeholder="请输入数据集名称"
            style={{ width: 300, marginRight: 12 }}
            onChange={e => setKeyword(e.target.value)}
          />
          <Button type="primary" onClick={() => submit()}>
            搜索
          </Button>
        </div>
      </Flex>
      <Table
        columns={columns}
        {...tableProps}
        rowKey="id"
        pagination={{ ...tableProps.pagination, showTotal: total => `总共 ${total} 条` }}
      />
    </div>
  );
};

export default DataSetPublic;

import ETracker from '@fuxi/eevee-tracker';
import PluginPV from '@fuxi/eevee-tracker-plugin-pv';
import PluginEvent from '@fuxi/eevee-tracker-plugin-event';
import { getEnv } from './utils/getEnv';

// 设置pid等信息
ETracker.setConfig({
  pid: 'lcap-platform',
  requiredFields: ['pid', 'uid'], // 只有当uid设置后才发送埋点
  env: getEnv(),
});

// 挂载pv插件
const { switchPage, sendPV, sendLeave } = ETracker.use(PluginPV, {
  autoPV: true,
  autoLeave: true,
  enableHistory: true,
});

// 挂载自定义事件插件
const sendEvent = ETracker.use(PluginEvent);

export { ETracker, sendEvent, switchPage, sendPV, sendLeave };

export enum BuriedEventTrigger {
  CLK = 'CLK',
  EXP = 'EXP',
  OTHER = 'OTHER',
}

export type BuriedEventType = {
  key: string;
  event: BuriedEventTrigger;
};

export const BuriedEvent = {
  ServiceSocketConnent: { key: 'service-socket-connect', event: BuriedEventTrigger.OTHER },
  ServiceSocketDisconnect: { key: 'service-socket-disconnect', event: BuriedEventTrigger.OTHER },
  TrainingSocketConnect: { key: 'training-socket-connect', event: BuriedEventTrigger.OTHER },
  TrainingSocketDisconnect: { key: 'training-socket-disconnect', event: BuriedEventTrigger.OTHER },
};

export const sendServiceSocketInfo = (isConnect: boolean, serverInfo: {
  id: string;
  projectId: number;
}) => {
  sendEvent(isConnect ? BuriedEvent.ServiceSocketConnent.key : BuriedEvent.ServiceSocketDisconnect.key, {
    et: isConnect ? BuriedEvent.ServiceSocketConnent.event : BuriedEvent.ServiceSocketDisconnect.event,
    c1: serverInfo.projectId,
    c2: serverInfo.id,
  });
}

export const sendTrainingSocketInfo = (isConnect: boolean, serverInfo: {
  realtimeLogUrl: string;
  projectId: number;
}) => {
  sendEvent(isConnect ? BuriedEvent.TrainingSocketConnect.key : BuriedEvent.TrainingSocketDisconnect.key, {
    et: isConnect ? BuriedEvent.TrainingSocketConnect.event : BuriedEvent.TrainingSocketDisconnect.event,
    c1: serverInfo.projectId,
    c2: serverInfo.realtimeLogUrl,
  });
}


import { Badge, Tooltip, TableButtons, Space, message, Icon } from '@fuxi/eevee-ui';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import bindClass from 'classnames/bind';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import service from '../../service';
import { requestConfirm } from '../../utils';
import { serviceStatusTypeMap } from '../../constants/onlineService';
import { OnlineServiceDetail, ServiceStatusType } from '../../types/onlineService';
import pStyles from './index.module.css';

const styles = bindClass.bind(pStyles);

export const releaseServicePath = '../online-service/release-service';
export const serviceDetailPath = '../online-service/';

const startType = [ServiceStatusType.PAUSE_FINISH];
const pauseType = [ServiceStatusType.RUNNING];
export const getOnlineServiceColumns = ({ refresh, history, projectId }): ColumnsType<OnlineServiceDetail> => {
  return [
    {
      title: '服务名称',
      dataIndex: 'service_name',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <Tooltip placement="topLeft" title={record.service_name}>
          <div className={styles('public-service-cell-wrapper')}>
            <div className="list-link-ellipsis">
              <Link type="link" to={`${record.service_uuid}`}>
                {record.service_name}
              </Link>
            </div>
            {record.is_public && <div className={styles('public-service-tag')}>公共</div>}
          </div>
        </Tooltip>
      ),
    },
    {
      title: '服务ID',
      dataIndex: 'service_uuid',
      ellipsis: {
        showTitle: false,
      },
      render: value => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: '服务状态',
      dataIndex: 'status',
      render(value, record, index) {
        return (
          <Badge color={serviceStatusTypeMap[record.status]?.color} text={serviceStatusTypeMap[record.status]?.label} />
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updated_at',
      ellipsis: {
        showTitle: false,
      },
      render: val => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    },
    {
      title: '操作',
      render: (_, record) => {
        const isRestart = startType.includes(record.status) && !record.is_public;
        const isStop = pauseType.includes(record.status) && !record.is_public;
        return (
          <>
            <TableButtons
              data={[
                {
                  text: '启动',
                  disabled: !isRestart,
                  onClick: () => {
                    requestConfirm({
                      title: `确定启动 ${record.service_name}?`,
                      payload: {
                        service_uuid: record.service_uuid,
                        replicas: 1,
                      },
                      submitApi: service.onlineService.appScale,
                    }).then(() => {
                      refresh();
                    });
                  },
                },
                {
                  text: '停止',
                  disabled: !isStop,
                  onClick: () => {
                    requestConfirm({
                      title: `确定暂停 ${record.service_name}?`,
                      payload: {
                        service_uuid: record.service_uuid,
                        replicas: 0,
                      },
                      submitApi: service.onlineService.appScale,
                    }).then(() => {
                      refresh();
                    });
                  },
                },
                {
                  text: '删除',
                  type: 'danger' as any,
                  onClick: () =>
                    requestConfirm({
                      title: `确定删除服务${record.service_name}?`,
                      content: '删除后该服务的API将失效。',
                      payload: {
                        service_uuid: record.service_uuid,
                      },
                      submitApi: record.is_public
                        ? service.onlineService.publicServeUntie
                        : service.onlineService.appDelete,
                    }).then(() => {
                      refresh();
                    }),
                },
              ]}
            />
          </>
        );
      },
    },
  ];
};

export const serviceDetailColumns = [
  {
    label: '服务名称',
    dataIndex: 'service_name',
    ellipsis: true,
  },
  {
    label: '能力名称',
    dataIndex: 'capability_name',
  },
  {
    label: '接口地址',
    dataIndex: 'url',
    ellipsis: true,
    render: data => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Tooltip title={data.url}>
            <div className="ellipsis">{data.url}</div>
          </Tooltip>
          <CopyToClipboard text={data.url} onCopy={() => message.success('复制成功！')}>
            <Icon name="复制与粘贴" cursor={'pointer'} />
          </CopyToClipboard>
        </div>
      );
    },
  },
  {
    label: '服务ID',
    dataIndex: 'service_uuid',
    ellipsis: true,
  },
  {
    label: '能力版本',
    dataIndex: 'capability_version_name',
  },
  {
    label: '服务状态',
    dataIndex: 'status',
    render: data => {
      return <Badge color={serviceStatusTypeMap[data.status]?.color} text={serviceStatusTypeMap[data.status]?.label} />;
    },
  },
  {
    label: '能力ID',
    dataIndex: 'capability_uuid',
  },
];

/* eslint-disable react-func/max-lines-per-function */
/**
 * 实时日志
 */
import { v4 as uuid } from 'uuid';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {Dayjs} from 'dayjs';
import takeRight from 'lodash/takeRight';
import { keys } from 'lodash';
import { LogLevel, LogRole } from '@/constants';
import store, { AppThunk } from '@/store/index';
import { createWebsocketInstance } from '@/service/socket';
import { isJson } from '@/utils';
import { getPodInfoList, getRealtimeLogUrl } from '@/service/log';
import { sendServiceSocketInfo } from '@/tracker';

export type Filter = {
  keyword?: string;
  date?: string;
};

export type LogItem = {
  uid: string;
  // 日志内容
  content: string;
  // 日志时间
  createdAt: string;
  // 日志类型
  level?: LogLevel;
  // 日志堆栈
  traceback?: string;
  // 平台日志 or 引擎日志
  role?: LogRole;
  // 文件名
  filename?: string;
  // key
  key?: string;
  // 是否是历史日志
  isHistory?: boolean;
};

export type CacheItem = {
  filterDate?: [Dayjs, Dayjs];
  logLevels: LogLevel[];
  confirmSearchValue?: string;
  searchValue?: string;
  role: LogRole[];
  // realtimeLogStartUid: string;
  historyLogs: LogItem[];
  scrollToItemUid?: string;
  filter?: Filter;
  activeKeys: string[];
  selectPodList: string[];
};

export type RealtimeLogs = {
  // 需更新到组件中的日志数据
  updateLogs: { [key: string]: LogItem[] };
  realtimeMaxCount: number;
  cache: { [key: string]: CacheItem };
  realtimeLogs: { [key: string]: LogItem[] };
  podList: Pod[];
  retryFailedCount: number;
};

export enum PodStatus {
  Running = 'RUNNING',
  Error = 'ERROR',
}

export type Pod = {
  podName: string;
  label: PodLabel;
  status: PodStatus;
};

export enum PodLabel {
  edge = 'edge-server',
  cloud = 'cloud-server',
  gateway = 'gateway',
  name = 'nameserv',
}

const initialState: RealtimeLogs = {
  updateLogs: {},
  realtimeMaxCount: 10000,
  cache: {},
  realtimeLogs: {},
  podList: [],
  retryFailedCount: 0
};

let realtimeServerContainer: { [key: string]: { server?: WebSocket; podNameList: string; errorAfterReconnect: boolean } } = {};

export const getRealtimeServerContainer = (key: string) => {
  return realtimeServerContainer[key];
};

const realtimeLogs = createSlice({
  name: 'realtimeLogs',
  initialState,
  reducers: {
    setUpdateLogs(state, { payload }: PayloadAction<{ tabId: string; data: LogItem[] }>) {
      const { tabId, data } = payload;
      state.updateLogs[tabId] = data;
      if (!state.realtimeLogs[tabId]) {
        state.realtimeLogs[tabId] = [];
      }
      state.realtimeLogs[tabId] = takeRight(state.realtimeLogs[tabId].concat(data), state.realtimeMaxCount);
    },
    updateLogCache(state, { payload }: PayloadAction<{ data: CacheItem; tabId: string }>) {
      state.cache[payload.tabId] = payload.data;
    },
    clearLogCache(state, { payload }: PayloadAction<string | undefined>) {
      if (payload) {
        delete state.cache[payload];
      } else {
        state.cache = {};
      }
    },
    clearRealtimeLogs(state, { payload: { tabId } }: PayloadAction<{ tabId: string }>) {
      delete state.realtimeLogs[tabId];
    },
    updatePodList(state, { payload }: PayloadAction<Pod[]>) {
      state.podList = payload;
    },
    retryFailedCount(state) {
      state.retryFailedCount += 1;
    }
  },
});

export const { setUpdateLogs, updateLogCache, clearLogCache, clearRealtimeLogs, updatePodList, retryFailedCount } = realtimeLogs.actions;

export const resetRealtimeServer =
  ({ tabId, input }: { tabId: string; input: { taskId: string; podNameList: string } }): AppThunk =>
  async (dispatch, getState) => {
    if (!realtimeServerContainer[tabId]) {
      return;
    }
    await realtimeServerContainer[tabId]?.server?.close();
    delete realtimeServerContainer[tabId];

    dispatch(toGetPersonalRealtimeLogs({ tabId, input }));
  };

export const resetAllRealtimeServer =
  ({ taskId }: { taskId: string }): AppThunk =>
  (dispatch, getState) => {
    keys(realtimeServerContainer).forEach(key => {
      dispatch(
        resetRealtimeServer({
          tabId: key,
          input: {
            taskId,
            podNameList: realtimeServerContainer[key].podNameList,
          },
        })
      );
    });
  };

export const toGetPersonalRealtimeLogs = createAsyncThunk<
  void,
  { tabId: string; input: { taskId: string; podNameList: string } }
>('', async (params, { dispatch, getState }) => {
  const projectId = store.getState().project.currentProject?.id;
  const { tabId, input } = params;
  if (realtimeServerContainer[tabId] || !input.podNameList) {
    return;
  }
  const timeout = 1000;
  let timeoutId: number;
  const logContainer: LogItem[] = [];
  dispatch(clearRealtimeLogs({ tabId }));

  const toUpdateLog = () => {
    // 如果该服务被清掉了，就不执行 update
    if (!realtimeServerContainer[tabId]) {
      return;
    }
    let number = logContainer.length;
    if (number) {
      // 每次更新总条数的十分之一，总条数小于10时 flush
      dispatch(setUpdateLogs({ tabId, data: logContainer.splice(0, number > 10 ? Math.floor(number * 0.1) : number) }));
    }

    timeoutId = window.setTimeout(toUpdateLog, timeout);
  };

  const { data } = await getRealtimeLogUrl(input);
  realtimeServerContainer[tabId] = {
    server: createWebsocketInstance(handleLogUrl(data), {
      onOpen: () => {
        sendServiceSocketInfo(true, { id: input?.taskId, projectId });
      },
      onMessage: res => {
        const { data } = res;

        if (isJson(data)) {
          const { dt, level, msg, traceback, role, filename } = JSON.parse(data);
          // 应用输出日志中一定包含 dt 字段，以此判断是否是标准日志输出
          if (!dt) return;
          const logData = {
            uid: uuid(),
            level,
            content: msg,
            createdAt: dt,
            traceback,
            role,
            filename,
          };
          logContainer.push(logData);

          if (!timeoutId) {
            timeoutId = window.setTimeout(toUpdateLog, timeout);
          }
        }
      },
      onClose: (e, errorAfterReconnect: boolean) => {
        dispatch(clearRealtimeLogs({ tabId }));
        clearTimeout(timeoutId);
        sendServiceSocketInfo(false, { id: input?.taskId, projectId });
        if (errorAfterReconnect) {
          realtimeServerContainer[tabId].errorAfterReconnect = true;
          dispatch(retryFailedCount());
        }
      },
    }),
    podNameList: input.podNameList,
    // 重连ws仍然失败
    errorAfterReconnect: false,
  };
});

export const toCloseRealtimeLogSocket =
  (tabId: string): AppThunk =>
  (dispatch, getState) => {
    realtimeServerContainer[tabId]?.server?.close();
  };
  

function handleLogUrl(str: string) {
  // 去掉 host
  const url = new URL(str);
  const path = url.pathname;
  const search = url.search;
  return path + search;
}

export const asyncGetPodList = createAsyncThunk('asyncGetPodList', 
  async (taskId: string, { dispatch }) => {
    const res = await getPodInfoList(taskId);
    if (res?.data?.data) {
      dispatch(updatePodList(res?.data?.data));
    }
    return res?.data?.data;
  });

export const getPodList =
  (taskId: string): AppThunk =>
  async (dispatch, getState) => {
    const res = await getPodInfoList(taskId);
    if (res?.data?.data) {
      dispatch(updatePodList(res?.data?.data));
    }
  };

export const handleSocketWhenClose =
  (payload: { data: CacheItem; tabId: string }): AppThunk =>
  (dispatch, getState) => {
    const { tabId } = payload;
    realtimeServerContainer[tabId]?.server?.close();
    delete realtimeServerContainer[tabId];
    dispatch(clearLogCache(tabId));
  };

export default realtimeLogs.reducer;

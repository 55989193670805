export enum TerminalLogType {
  Log = 'log',
  HistoryLog = 'historyLog',
}

export enum TerminalLogLevel {
  Crit = 'CRIT',
  Error = 'ERROR',
  Warning = 'WARNING',
  Info = 'INFO',
  Debug = 'DEBUG',
}

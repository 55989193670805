import React, { ReactNode, useEffect, useState } from 'react';
import { Drawer, Descriptions, Typography, StatusTag, Tabs, Button, Tooltip, Spin, Flex } from '@fuxi/eevee-ui';
import { useAntDrawer } from '@fuxi/eevee-hooks';

import { ServerType, LogType } from '@/constants';
import { getRepoPullCommand } from '@/utils';

import ProjectDetailContainer from '../ProjectDetailContainer';
import { useServiceOperation } from '../useServiceOperation';
import { serverStatusMap } from '../constants';
import Log from '../ProjectDetailLogs';
import PublishModal from '../PublishModal';
import DownloadModal from '../DownloadModal';

import { LogsWithVirtualScroll } from '../LogsWithVirtualScroll';
import style from './ServiceDrawer.module.less';

const { TabPane } = Tabs;
const { Text } = Typography;
const { Item: DescriptionItem } = Descriptions;

enum TabKeys {
  Log = 'log',
  PublishLog = 'publishLog',
}

export const ServiceDrawer: React.FC<{ service: Service; children: ReactNode }> = ({ service, children }) => {
  const container = ProjectDetailContainer.useContainer();
  const {
    currentProject: { clientRepoGitUrl, customRepoGitUrl, serviceRepoGitUrl },
  } = container;
  const { id, name, taskId, type, creator, actorUrl, serviceUrl, status, description, msg, version, debugUrl, sshUrl } =
    service;
  const { showDrawer, commonAntDrawerProps, visible } = useAntDrawer();
  const { isMainServer, isProjectCreator, canPublishService, canRestoreService, handleRestoreService } =
    useServiceOperation(service);
  const [activeTab, setActiveTab] = useState<string>(TabKeys.Log);
  const [logLoading, setLogLoading] = useState<boolean>(false);
  const serverStatus = serverStatusMap[status];

  const publishLogRef = React.createRef<any>();

  const [clientCommand, , customCommand] = getRepoPullCommand({
    clientRepoGitUrl,
    customRepoGitUrl,
    serviceRepoGitUrl,
    serviceType: type,
    version,
    creator,
  });

  const handleFetch = (tabKey: string, loading?: boolean) => {
    setLogLoading(loading === true);
  };
  const setTabRequirementsMap = (tabKey: string, reqs: string[]) => {};

  const onPublished = () => {
    // 发布之后需要重新建立实时日志ws连接和发布日志接口刷新
    // dispatch(toGetPersonalRealtimeLogs({ tabId: TabKeys.Log, input: { taskId, podNameList: '' } }));
    // dispatch(toGetPublishLogs(taskId))
    publishLogRef.current.getPublishLogs();
  };

  useEffect(() => {
    // 添加私服创建失败或异常原因
    // if ([ServiceStatus.Error, ServiceStatus.InitError].includes(status as ServiceStatus) && msg) {
    //   dispatch(updateLog({ serviceId: id, type: LogType.PublishLog, data: { level: LogLevel.Error, content: msg } }));
    // }
  }, [msg]);

  return (
    <>
      <div onClick={showDrawer}>{children}</div>
      <Drawer
        width={1080}
        title={name}
        {...commonAntDrawerProps}
        closable
        destroyOnClose={true}
        className={style('drawer-container')}
        onClose={commonAntDrawerProps.onClose as any}>
        <div className={style('drawer-content')}>
          <Descriptions title={null} column={1} colon={false}>
            <DescriptionItem label="所属分支">
              {isMainServer ? '主干' : `${creator.split('@')[0]} 的个人分支`}
            </DescriptionItem>
            <DescriptionItem label="相关地址" contentStyle={{ display: 'block' }}>
              {service?.isFrontendPublish && (
                <div>
                  页面地址：
                  {serviceUrl ? <Text copyable>{serviceUrl}</Text> : '-'}
                </div>
              )}

              <div>
                服务地址：
                {actorUrl ? <Text copyable>{actorUrl}</Text> : '-'}
              </div>
              {!isMainServer && (
                <div>
                  调试地址：
                  {debugUrl ? <Text copyable>{debugUrl}</Text> : '-'}
                </div>
              )}
            </DescriptionItem>

            <DescriptionItem label="ssh 连接命令" contentStyle={{ display: 'block' }}>
              <div>{sshUrl ? <Text copyable>{sshUrl}</Text> : '-'}</div>
            </DescriptionItem>

            <DescriptionItem label="代码库拉取命令" contentStyle={{ display: 'block' }}>
              <div>客户端：{clientCommand ? <Text copyable>{clientCommand}</Text> : '-'}</div>
              <div>服务器代码：{customCommand ? <Text copyable>{customCommand}</Text> : '-'}</div>
              {/* <div>自定义代码：{customCommand ? <Text copyable>{customCommand}</Text> : '-'}</div> */}
            </DescriptionItem>
            <DescriptionItem label="服务状态">
              {serverStatus ? (
                <StatusTag style={{ background: 'transparent' }} color={serverStatus.color}>
                  {serverStatus.label}
                </StatusTag>
              ) : null}
            </DescriptionItem>
            <DescriptionItem label="描述">{description}</DescriptionItem>
          </Descriptions>
          <Tabs
            type="card"
            className={style('tab-container')}
            activeKey={activeTab}
            onTabClick={key => setActiveTab(key)}>
            <TabPane tab="日志" key={TabKeys.Log}>
              {/* <Log
              type={LogType.Log}
              activeKey={activeTab}
              currentKey={TabKeys.Log}
              taskId={taskId}
              serviceId={id}
              visible={visible}
              status={status}
            /> */}
              <Spin tip={'正在拉取日志'} spinning={logLoading}>
                <LogsWithVirtualScroll
                  tabKey={'log'}
                  height={470}
                  serverInfo={service}
                  toFetch={handleFetch}
                  toSetFilterRequirements={setTabRequirementsMap}
                />
              </Spin>
            </TabPane>
            {/* 发布日志暂未从服务端获取，并且项目中心也暂不支持发布私服，故先隐藏私服下的发布日志 */}
            {/* {isMainServer && ( */}
            <TabPane tab="发布记录" key={TabKeys.PublishLog}>
              <Log
                onRef={publishLogRef}
                type={LogType.PublishLog}
                activeKey={activeTab}
                currentKey={TabKeys.PublishLog}
                taskId={taskId}
                serviceId={id}
                visible={visible}
                status={status}
              />
            </TabPane>
            {/* )} */}
          </Tabs>
          <Flex className={style('operation')} alignCenter>
            {isMainServer && (
              <>
                {isProjectCreator ? (
                  <PublishModal server={{ id, name }} disabled={!canPublishService} onPublished={onPublished}>
                    <Button type="primary" disabled={!canPublishService}>
                      发布
                    </Button>
                  </PublishModal>
                ) : (
                  <Tooltip title="仅项目创建者有此权限">
                    <Button disabled>发布</Button>
                  </Tooltip>
                )}
              </>
            )}
            <DownloadModal isPersonal={type === ServerType.Personal} serviceId={id} serviceName={name}>
              <Button>下载客户端SDK</Button>
            </DownloadModal>
            <Button onClick={handleRestoreService} disabled={!canRestoreService}>
              修复
            </Button>
          </Flex>
        </div>
      </Drawer>
    </>
  );
};

import React, { useEffect } from 'react';
import { message, Modal } from '@fuxi/eevee-ui';
import { deleteModalWidth } from './const';

type Container = {
  showDeleteModal: boolean;
  setShowDelete: (visible: boolean) => void;
  currentProject: Project;
  deleteLoading?: boolean;
  postDelete: ({ variables }: { variables: { projectId: number } }) => PromiseGQL;
};

const DeleteProject: React.FC<{ useContainer: () => Container }> = ({ useContainer }) => {
  const container = useContainer();
  const { showDeleteModal, currentProject, deleteLoading, postDelete, setShowDelete } = container;

  useEffect(() => {
    if (showDeleteModal) {
      Modal.confirm({
        title: '你确定要删除该项目吗？',
        okText: '确认',
        cancelText: '取消',
        onOk: handleDeleteProject,
        onCancel: () => setShowDelete(false),
        centered: true,
        okButtonProps: { loading: deleteLoading },
        width: deleteModalWidth,
        content: '删除后将无法恢复',
        className: 'confirm-modal',
      });
    }
  }, [showDeleteModal]);

  const handleDeleteProject = async () => {
    if (!currentProject.id && currentProject.id !== 0) {
      message.error('删除失败！');
    } else {
      postDelete({ variables: { projectId: currentProject.id } }).then(res => {
        const data = res?.data?.deleteProject;
        if (data?.code !== 0) {
          message.error(`删除失败！错误原因：${data?.msg}`);
          return;
        }
        message.success('删除成功！');
        setShowDelete(false);
      });
    }
  };

  return <></>;
};

export default DeleteProject;

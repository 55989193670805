import bindClass from 'classnames/bind';
import { Switch, Form, Row, Typography, Col, Input } from '@fuxi/eevee-ui';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { DEFAULT_PROJECT_STORAGE, FieldNamesForTrain } from '@ai-training/constants/ability';
import { TrainFormDataConfig, ResourceValue, FormValues } from '@ai-training/types/ability';
import { ruleMap } from '@ai-training/utils/validator';
import styles from './index.module.less';

const { Text } = Typography;
const cx = bindClass.bind(styles);

const rightAlignedFormItemLayout = {
  labelCol: { span: 3 }, // Adjust offset as necessary to align to the right
  wrapperCol: { span: 12 },
};

interface CodeConfigOfFormRef {
  extractDataFromFormValues: (values: FormValues) => TrainFormDataConfig;
}

interface CodeConfigOfFormProps extends React.RefAttributes<CodeConfigOfFormRef> {}

const CodeConfigOfForm: React.FC<CodeConfigOfFormProps> = forwardRef((props, ref) => {
  const [isLoadCode, setIsLoadCode] = useState<boolean>(false); // 是否挂载代码
  const form = Form.useFormInstance();
  const isEnableCode = form.getFieldValue(FieldNamesForTrain.Code);

  const handleIsLoadCode = (checked: boolean) => {
    setIsLoadCode(checked);
  };

  useImperativeHandle(ref, () => ({
    extractDataFromFormValues(values: FormValues) {
      const finalFormData: TrainFormDataConfig = {
        [FieldNamesForTrain.Repo]: values[FieldNamesForTrain.Repo],
        [FieldNamesForTrain.GitBranch]: values[FieldNamesForTrain.GitBranch],
        [FieldNamesForTrain.CommitId]: values[FieldNamesForTrain.CommitId],
      };
      return finalFormData;
    },
  }));

  useEffect(() => {
    if (isEnableCode) {
      setIsLoadCode(true);
    } else {
      setIsLoadCode(false);
    }
  }, [isEnableCode]);

  return (
    <div className={styles['part-group-container']}>
      <Row className={styles['code-information']}>
        <Col span={2}>
          <Text style={{ display: 'block' }} className={styles['part-group-text']}>
            代码信息
          </Text>
        </Col>
      </Row>

      <Form.Item
        label="挂载代码"
        name={FieldNamesForTrain.Code}
        className={styles['narrow-label-offest']}
        valuePropName="checked"
        rules={[{ required: true, message: '请选择是否挂载代码' }]}
        {...rightAlignedFormItemLayout}>
        <Switch onChange={handleIsLoadCode} />
      </Form.Item>

      {isLoadCode ? (
        <Form.Item
          label="代码库"
          className={styles['narrow-label-offest']}
          name={FieldNamesForTrain.Repo}
          rules={[{ required: true, message: '请输入代码仓库地址' }, ruleMap.isValidGitRepo]}
          {...rightAlignedFormItemLayout}>
          <Input placeholder="请输入" className={styles['single-config-line']} />
        </Form.Item>
      ) : null}

      {isLoadCode ? (
        <Form.Item
          label="分支"
          className={styles['narrow-label-offest']}
          name={FieldNamesForTrain.GitBranch}
          rules={[{ required: true, message: '请输入代码分支' }]}
          {...rightAlignedFormItemLayout}>
          <Input placeholder="请输入" className={styles['single-config-line']} />
        </Form.Item>
      ) : null}

      {isLoadCode && (
        <Form.Item
          label="Commit ID"
          className={styles['narrow-label-offest']}
          name={FieldNamesForTrain.CommitId}
          rules={[ruleMap.isValidGitCommitId, { required: true, message: '请输入 commit ID' }]}
          {...rightAlignedFormItemLayout}>
          <Input placeholder="请输入 commit id" className={styles['single-config-line']} />
        </Form.Item>
      )}

      {isLoadCode && (
        <Form.Item label="挂载路径" className={styles['narrow-label-offest']} {...rightAlignedFormItemLayout}>
          <Text>{DEFAULT_PROJECT_STORAGE}</Text>
        </Form.Item>
      )}
    </div>
  );
});

export default CodeConfigOfForm;

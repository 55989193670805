import React, { useState, useEffect } from 'react';
import { Select, DatePicker } from '@fuxi/eevee-ui';
import dayjs from 'dayjs';
import { now } from 'lodash';

export type RangeType = 'day' | 'week' | 'month' | 'year';

interface Props {
  selectRangeType?: RangeType[];
  onChange?: (value, rangeType: RangeType) => void;
}

const RangeTypeList = [
  {
    value: 'day',
    label: '按日统计',
  },
  {
    value: 'week',
    label: '按周统计',
  },

  {
    value: 'month',
    label: '按月统计',
  },
  {
    value: 'year',
    label: '按年统计',
  },
];

const getRangeType = (rangeType: RangeType) => {
  if (rangeType === 'day') {
    return undefined;
  }
  return rangeType;
};

export const MlDatePicker: React.FC<Props> = ({ selectRangeType = ['month', 'year'], onChange }) => {
  const [rangeType, setRangeType] = useState(getRangeType(selectRangeType[0]));
  const [range, setRange] = useState<number>(now());
  const handelRangeType = val => {
    setRangeType(getRangeType(val));
  };
  const handelRange = val => {
    setRange(val);
  };

  useEffect(() => {
    onChange?.(dayjs(range), rangeType ?? 'day');
  }, [rangeType, range]);

  return (
    <>
      <Select
        listHeight={128}
        onChange={val => handelRangeType(val)}
        defaultValue={selectRangeType[0]}
        style={{ marginRight: 'var(--margin-xs)', width: '100px' }}
        options={RangeTypeList.filter(item => selectRangeType.includes(item.value as RangeType)).map(o => ({
          label: o.label,
          value: o.value,
        }))}
      />
      <DatePicker
        allowClear={false}
        disabledDate={current => current && current > dayjs().endOf('day')}
        defaultValue={dayjs(range)}
        onChange={handelRange}
        picker={rangeType}
      />
    </>
  );
};

import { getClusterName } from '@ai-training/utils/environment';
import { MlInstance, WebInstance } from './common';

export const ml = {
  downloadAbilityVersion(params: { id: string; aiProjectId: number }) {
    return MlInstance.get(`/capability/download?uuid=${params.id}&danlu_project_id=${params.aiProjectId}`);
  },
  getMlProjectList(params?: { pageNum: number; pageSize: number }): Promise<any> {
    return WebInstance.get<any>(`/project/list`, {
      params,
    });
  },

  getMlPublicCapabilities(params?: {
    current?: number;
    pageSize?: number;
    keyword?: string;
    share?: boolean;
    first_category?: string;
    second_category?: string;
  }): Promise<any> {
    const share = params?.share ?? true;
    return MlInstance.get<any>(`/capability/precise`, {
      params: {
        page: params?.current,
        page_size: params?.pageSize,
        keyword: params?.keyword,
        first_category: params?.first_category,
        second_category: params?.second_category,
        share,
      },
    });
  },

  // 域名重复校验
  domainCheck<T>(params: { domain: string }) {
    const clusterName = getClusterName();
    return MlInstance.post<T>('/domain/check', {
      cluster_name: clusterName,
      ...params,
    });
  },

  // 查询标签列表
  getTagList<T = any>() {
    return MlInstance.get<T>(`/capability/categories`);
  },

  // 能力基本信息
  getCapabilityDetails<T = any>(params: { id: string }) {
    return MlInstance.get<T>(`/capabilities/${params.id}`, { params });
  },

  // 能力版本
  getCapabilityVersions<T = any>(params) {
    return MlInstance.get<T>(`/capabilities/${params.id}/versions`, {
      params: {
        capability_uuid: params.id,
      },
    });
  },

  // 能力版本详情
  getVersionsDetails<T = any>(params: { abilityId: string; id: string }) {
    return MlInstance.get<T>(`/capabilities/${params.abilityId}/versions/${params.id}`);
  },

  // 编辑项目能力
  editCapability<T = any>(params: {
    id: string;
    category_uuid: string;
    image_url: string;
    markdown: string;
    description: string;
  }) {
    return MlInstance.patch<T>(`/capabilities/${params.id}`, params);
  },

  // 删除能力
  deleteCapability<T = any>(params) {
    return MlInstance.delete<T>(`/capabilities/${params.id}`);
  },

  // 删除智能体版本
  deleteCapabilityVersions<T = any>(params) {
    return MlInstance.delete<T>(`/capabilities/${params.abilityId}/versions/${params.versionId}`);
  },

  // 服务列表
  appList(params: { capability_id: string; capability_version_id: string }) {
    return MlInstance.get(`/services`, { params });
  },

  // 创建项目能力
  createCapability<T = any>(params) {
    return MlInstance.post<T>(`/capabilities`, params);
  },

  // 发布智能体
  releaseCapability<T = any>(params) {
    return MlInstance.post<T>(`/services`, {
      ...params,
      cluster_name: getClusterName(),
    });
  },

  // 创建数据集
  createDataset<T = any>(data) {
    return MlInstance.post<T>(`/datasets`, data);
  },
  getCategory() {
    return MlInstance.get<any, any>(`/dataset/category`);
  },
  //数据集文件上传初始化获取批次uuid
  getUploadUuid() {
    return MlInstance.get<any, any>(`/upload/init/uuid`);
  },
  // 数据集文件上传初始化获取单个文件id
  getUploadFileId(params: { upload_uuid?: string; filename?: string }) {
    return MlInstance.get<any, { data: { upload_id: string } }>(`/upload/init/id`, { params }).then(res => res.data);
  },
  // 不分片上传
  uploadTotalFile<T = any>(data: any) {
    return MlInstance.post<T>(`/upload/single`, data);
  },
  // 分片上传
  uploadPartFile<T = any>(data: any, options: { signal?: AbortSignal }) {
    return MlInstance.post<T>(`/upload/part`, data, options);
  },
  // 分片上传完成
  uploadPartFinish<T = any>(data: any) {
    return MlInstance.post<T>(`/upload/part/finish`, data);
  },
  // 取消上传，删除已上传的文件
  cancelUpload<T = any>(data: { upload_uuid?: string; filename: string }) {
    return MlInstance.post<T>(`/upload/cancel`, data);
  },
  // 取消正在上传的文件
  cancelUploading<T = any>(data: { upload_uuid?: string; filename: string; upload_id: string }) {
    return MlInstance.post<T>(`/upload/stop`, data);
  },
  // 数据集列表
  getDatasetList<T = any>(params: {
    current?: number;
    pageSize?: number;
    keyword?: string;
    with_annotation?: boolean;
  }) {
    return MlInstance.get<T>(`/datasets`, {
      params: {
        page: params?.current,
        page_size: params?.pageSize,
        keyword: params?.keyword,
        with_annotation: params?.with_annotation,
      },
    });
  },
  // 删除数据集
  deleteDataset<T = any>({ datasetId }: { datasetId: string }) {
    return MlInstance.delete<T>(`/dataset/${datasetId}/delete`);
  },
  // 数据集详情
  getDatasetDetail<T = any>(datasetVid: string) {
    return MlInstance.get<T>(`/datasets/${datasetVid}`);
  },
  // 更新name
  updateName<T = any>(data: { dataset_id?: number; name?: string }) {
    return MlInstance.post<T>(`/dataset/update_name`, data).then(res => res.data);
  },
  updateDescription<T = any>(data: { dataset_id?: number; description?: string }) {
    return MlInstance.post<T>(`/dataset/update_desc`, data).then(res => res.data);
  },
  // 查询数据集数据信息（图文/文本）
  getDatasetData<T = any>(
    datasetVid: string,
    params: {
      current?: number;
      pageSize?: number;
      isAnnotated?: string;
    }
  ) {
    return MlInstance.get<T>(`/dataset/${datasetVid}/data`, {
      params: {
        page: params?.current,
        page_size: params?.pageSize,
        is_annotated: params?.isAnnotated,
      },
    });
  },
  // 删除数据集数据
  deleteDatasets<T = any>(data: { datasetVid: string; ids: number[] }) {
    return MlInstance.delete<T>(`/dataset/${data.datasetVid}/data`, {
      data: {
        ids: data.ids,
      },
    });
  },
  // 标注数据
  annotateData<T = any>(
    datasetVid: string,
    data: {
      data?: any;
      id?: number;
      annotation?: string | null;
    }
  ) {
    const { annotation } = data;
    return MlInstance.post<T>(`/dataset/${datasetVid}/annotate`, {
      ...data,
      annotation: !annotation ? null : annotation,
    });
  },
  importData<T = any>(
    datasetVid: string,
    data: {
      classification: string;
      annotation_type: string;
      is_annotated: boolean;
      file_type: string;
      upload_uuid?: string;
    }
  ) {
    return MlInstance.post<T>(`/dataset/${datasetVid}/import`, data);
  },
  // 新增数据集数据
  addDatasetData<T = any>(datasetVid: string, data: { data?: string; annotation?: string | null; uuid?: string }) {
    return MlInstance.post<T>(`/dataset/${datasetVid}/data`, data);
  },
};

export const mlTrainingTask = {
  // 停止训练任务
  taskStop(data) {
    return MlInstance.post(`/trains/${data.id}/stop`);
  },
  // 重启训练任务
  taskRestart(data) {
    return MlInstance.post(`/trains/${data.id}/restart`);
  },
  // 创建训练任务
  taskCreate(data) {
    return MlInstance.post(`/trains`, data);
  },
  // 删除训练任务
  taskDelete(params) {
    return MlInstance.delete(`/trains/${params.id}`);
  },
  // 查询训练任务列表
  taskList(params) {
    return MlInstance.get(`/trains`, { params });
  },
  // 训练任务数据集
  taskDatasets(params) {
    return MlInstance.get(`/trains/${params.id}/datasets`, { params });
  },
  // 训练任务详情
  taskDetails(params: { task_id: string }) {
    return MlInstance.get(`/iterations/task/detail`, { params });
  },
};

export const mlModalService = {
  // 服务列表
  appList(params) {
    return MlInstance.get(`/services`, { params });
  },
  // 暂停服务
  appStop(params) {
    return MlInstance.post(`/services/${params.id}/pause`);
  },
  // 训练任务详情
  appDetails(params: { id: string }) {
    return MlInstance.get(`/services/${params.id}`);
  },
  // 智能体发布服务
  appRelease(data) {
    return MlInstance.post(`/apps`, { data });
  },
  // 重启服务
  appRestart(params) {
    return MlInstance.post(`/services/${params.id}/restart`);
  },
  // 删除服务
  appDelete(params) {
    return MlInstance.delete(`/services/${params.id}`);
  },
  // 修改服务描述
  editRelease(params) {
    return MlInstance.patch(`/services/${params.id}`, params);
  },
  // 公共服务绑定项目
  publicServeBind(params) {
    return MlInstance.post(`/apps/${params.id}/bind`);
  },
  // 公共服务解绑项目
  publicServeUntie(params) {
    return MlInstance.post(`/apps/${params.id}/unbind`);
  },
  // 监控
  monitor(params: {
    startTimestamp: number;
    endTimestamp: number;
    step: number;
    target: string;
    uuid: string;
    type: string;
  }) {
    return MlInstance.get(`/iteration/monitor`, { params });
  },
};

export const getMlPublicCapabilitiesByPage = async (params: {
  current?: number;
  pageSize?: number;
  keyword?: string;
  projectId?: string;
  share?: boolean;
  first_category?: string;
  second_category?: string;
}): Promise<{ total: number; list: any }> => {
  const { data } = await ml.getMlPublicCapabilities(params);
  return new Promise((resolve, reject) => {
    resolve({
      total: data.pagination.total,
      list: data.list,
    });
  });
};

export const getMlTrainingTaskByPage = async (params: {
  project_id?: string;
  current?: number;
  pageSize?: number;
  keyword?: string;
}): Promise<{ total: number; list: any }> => {
  const { data } = await mlTrainingTask.taskList({
    page: params.current,
    page_size: params.pageSize,
    keyword: params.keyword,
    project_id: params.project_id,
  });
  return new Promise((resolve, reject) => {
    resolve({
      total: data.pagination.total,
      list: data.list,
    });
  });
};

export const getModalServicesByPage = async (params: {
  current?: number;
  pageSize?: number;
  keyword?: string;
  projectId?: string;
}): Promise<{ total: number; list: any }> => {
  const { data } = await mlModalService.appList({
    page: params.current,
    page_size: params.pageSize,
    keyword: params.keyword,
    project_id: params.projectId,
  });
  return new Promise((resolve, reject) => {
    resolve({
      total: data.pagination.total,
      list: data.list,
    });
  });
};

export const getMlDatasetByPage = async (params: {
  current?: number;
  pageSize?: number;
  keyword?: string;
}): Promise<{ total: number; list: any }> => {
  const { data } = await ml.getDatasetList(params);
  return new Promise((resolve, reject) => {
    resolve({
      total: data.pagination.total,
      list: data.list,
    });
  });
};

export const getMlDatasetDataByPage = async (
  datasetVid: string,
  params: {
    current?: number;
    pageSize?: number;
    isAnnotated?: string;
  }
): Promise<{ total: number; list: any }> => {
  const { data } = await ml.getDatasetData(datasetVid, params);
  return new Promise((resolve, reject) => {
    resolve({
      total: data.pagination.total,
      list: data.list,
    });
  });
};

export default ml;

import { now } from "lodash";
import { CdnInstance } from "@/service/common";


type ResData = {
  items: string[];
  total: number;
}

export const uploadFilesToCdn = async (files: any[], dir: string, refresh = 'true') => {
  const project = 'lowcode';
  const module = 'fe';

  const formData = new FormData();

  files.forEach((file) => {
    formData.append(file.name, file);
  });

  const res = await CdnInstance.post('files', formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      refresh,
      project,
      module,
      _dir: dir + now(),
    },
  });
  return res.data as ResData;
}
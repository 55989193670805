import React, { useState } from 'react';
import type { DataNode } from 'antd/es/tree';
import { useRequest } from '@fuxi/eevee-hooks';
import { DownOutlined } from '@ant-design/icons';
import { Modal, Tree, Tabs, Radio, message, Empty, ConfigProvider } from '@fuxi/eevee-ui';

import service from '../../../service';
import Loading from '../../../components/Loading';
import { ComponentProps } from '../../../utils/popup';
import { commonTheme } from '../../../../../platform/src/styles/commonTheme';
import cx from './AddDataModal.module.less';

type value = {
  version_id: number;
  version: number;
  id: number;
  size: number;
  dataset: string;
};

const { algorithm = undefined, token = {}, components = {} } = commonTheme;

const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] => {
  return list.map(node => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });
};
const AddDataModal: React.FC<ComponentProps> = ({ onOk, onCancel, projectId }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [value, setValue] = useState<value>();
  const [publicData, setPublicData] = useState<DataNode>({
    key: 'public',
    title: '公共数据集',
    selectable: false,
  });
  const [projectData, setProjectData] = useState<DataNode>({
    key: 'project',
    title: '项目数据集',
    selectable: false,
  });

  const getTreeData = () => {
    return [publicData, projectData];
  };

  // 请求项目数据集
  const { loading: projectLoading } = useRequest(
    () => service.dataSet.getDatasets({ project_id: projectId, is_common: false }),
    {
      onSuccess(data) {
        const projectDataset = updateTreeData(
          getTreeData(),
          'project',
          data?.data?.list.map(item => ({ key: item.id, title: item.name, selectable: false, is_common: false })) || []
        )?.find(item => item.key === 'project');
        if (projectDataset) {
          setProjectData(projectDataset);
        }
      },
    }
  );

  // 请求公共数据集
  const { loading: publicLoading } = useRequest(() => service.dataSet.getDatasets({is_common: true}), {
    onSuccess({ data }) {
      const publicDataset = updateTreeData(
        getTreeData(),
        'public',
        data.list.map(item => ({ key: item.id, title: item.zh_name, selectable: false })) || []
      )?.find(item => item.key === 'public');
      if (publicDataset) {
        setPublicData(publicDataset);
      }
    },
  });
  const handelRadioChange = (val, data) => {
    if (val.target.checked) {
      setValue({
        version_id: data.id,
        id: data.key,
        dataset: data.title,
        version: data.version,
        size: data.size,
      });
    }
  };
  // 异步加载数据
  const onLoadData = async node => {
    if (node.children) return;
    return service.dataSet.getVersions({ dataset_id: node.key }).then(({ data }) => {
      const newChildren = data?.list.map(item => ({
        key: item.id,
        title: (
          <Radio value={item.id} onChange={val => handelRadioChange(val, { ...item, ...node })}>
            {item.version}
          </Radio>
        ),
        isLeaf: true,
      }));

      const treeData = updateTreeData(getTreeData(), node.key, newChildren || []);
      const projectData = treeData.find(item => item.key === 'project');
      const publicData = treeData.find(item => item.key === 'public');
      if (projectData) {
        setProjectData(projectData);
      }
      if (publicData) {
        setPublicData(publicData);
      }
    });
  };
  const handleOk = () => {
    if (!value) {
      message.error('请勾选数据集');
      return;
    }
    onOk && onOk([value]);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    onCancel && onCancel('Cancel');
    setIsModalOpen(false);
  };
  return (
    <ConfigProvider
      theme={{
        algorithm,
        token: { ...token },
        components: {
          ...components,
          Modal: {
            wireframe: true,
          },
        },
      }}>
      <Modal
        width={500}
        title="添加数据集"
        destroyOnClose
        onOk={handleOk}
        open={isModalOpen}
        onCancel={handleCancel}
        className={cx['add-data-modal']}>
        <Loading loading={projectLoading || publicLoading}>
          <Radio.Group className={cx['add-data-wrapper']}>
            <Tabs
              className={cx['add-data-tabs']}
              tabBarStyle={{ paddingLeft: 16 }}
              destroyInactiveTabPane={true}
              items={getTreeData().map((item: any) => ({
                key: item.key,
                label: item.title,
                forceRender: true,
                children: item?.children?.length ? (
                  <div className={cx['add-data-tree-wrapper']}>
                    <Tree
                      showIcon={true}
                      loadData={onLoadData}
                      className={cx['add-data-tree']}
                      switcherIcon={<DownOutlined />}
                      blockNode
                      checkStrictly
                      treeData={item.children}
                    />
                  </div>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无数据" />
                ),
              }))}
            />
          </Radio.Group>
        </Loading>
      </Modal>
    </ConfigProvider>
  );
};
export default AddDataModal;

import { gql } from '@apollo/client';

export const GET_TERMINALS = gql`
  query GetTerminals($projectId: ID!) {
    edgeNodes(projectId: $projectId) {
      id
      name
      status
      type
      serverId
      serverName
      mac
      nodeName
      createTime
      updateTime
      offlineTime
      sshUrl
      logUrl
      projectId
      taskId
      currentVersion
      currentVersionAlias
      webTerminalUrl
    }
  }
`;

export const GET_WEB_TERMINAL_URL = gql`
  query GetWebTerminalUrl($edgeNodeId: ID!) {
    edgeNodeWebUrl(edgeNodeId: $edgeNodeId)
  }
`;

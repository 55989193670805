import React, { useEffect, useState } from 'react';
import { Form } from '@fuxi/eevee-ui';
import { FieldNamesForTrain } from '@ai-training/constants/ability';

import { useAppSelector } from '@/hooks';
import { selectFormDataForEvaluateOfTrain, selectFormDataForBaseOfTrain } from '@/store/aiTraining';
import EmptyCard from '@ai-training/components/EmptyCard';

import { isFormDataEmpty } from '../../NewAIBodyVersion';
import ResourceConfig from './ResourceConfig';
import EnvSetup from './EnvSetup';
import { ConfigTitle, VersionInfoText, VersionInfoSwitchText } from './ConfigConstant';
import { convertOriginFormDataToValues } from '../ProjectVersionConfigInfo';
import cx from './Configs.module.less';

type Props = {};

export const Assess = (props: Props) => {
  const [form] = Form.useForm();
  const [isConfigEmpty, setIsConfigEmpty] = useState(true);
  const [isCodeOpen, setIsCodeOpen] = useState(false);
  const [isDatasetOpen, setIsDatasetOpen] = useState(false);
  const [isUseGpu, setIsUseGpu] = useState(false);
  const isInner = useAppSelector(state => state.user.isInner);
  const formDataForEvaluateOfTrain = useAppSelector(selectFormDataForEvaluateOfTrain);
  const formDataForBaseOfTrain = useAppSelector(selectFormDataForBaseOfTrain);

  useEffect(() => {
    if (isFormDataEmpty(formDataForEvaluateOfTrain)) {
      setIsConfigEmpty(true);
    } else {
      const fields = convertOriginFormDataToValues({
        eval_dataset_name: formDataForBaseOfTrain?.eval_dataset_name,
        ...formDataForEvaluateOfTrain,
      });
      setIsUseGpu(!!fields?.resources?.gpu && !!(fields?.resources?.gpu > 0));
      setIsCodeOpen(fields[FieldNamesForTrain.Code] === '已开启');
      setIsDatasetOpen(fields[FieldNamesForTrain.IsMountDataset] === '已开启');
      form.setFieldsValue(fields ?? {});

      setIsConfigEmpty(false);
    }
  }, [formDataForEvaluateOfTrain]);

  return (
    <div>
      {isConfigEmpty ? (
        <EmptyCard height={300} imageStyle={{ height: 143, width: 253 }} />
      ) : (
        <Form form={form} labelAlign="right" labelCol={{ style: { width: 130 } }}>
          <div className={cx['config-item']}>
            <div className={cx['config-item-title']}>{ConfigTitle.RuntimeEnv}</div>
            <div className={cx['config-item-body']}>
              <Form.Item label="镜像" required name={FieldNamesForTrain.ImageFile}>
                <VersionInfoText />
              </Form.Item>
              <Form.Item label="资源配额" required name={FieldNamesForTrain.Resource}>
                <ResourceConfig />
              </Form.Item>
              {isInner && isUseGpu && (
                <Form.Item label="GPU型号" required name={FieldNamesForTrain.GPU}>
                  <VersionInfoText />
                </Form.Item>
              )}
              <Form.Item label="启动命令" name={FieldNamesForTrain.Command}>
                <VersionInfoText />
              </Form.Item>
              <Form.Item label="开放端口" name={FieldNamesForTrain.Ports}>
                <Form.List name={FieldNamesForTrain.Ports}>
                  {fields =>
                    fields.length === 0 ? (
                      <VersionInfoText />
                    ) : (
                      <>
                        {fields.map(({ name, key, ...field }, index) => {
                          return (
                            <div key={key}>
                              <Form.Item
                                label={'开放外网'}
                                name={[name, 'public']}
                                style={{ marginBottom: 0 }}
                                {...field}>
                                <VersionInfoSwitchText />
                              </Form.Item>
                              <Form.Item
                                label={'端口号'}
                                name={[name, 'port']}
                                style={{ marginBottom: 0, marginLeft: 14 }}
                                {...field}>
                                <VersionInfoText />
                              </Form.Item>
                            </div>
                          );
                        })}
                      </>
                    )
                  }
                </Form.List>
              </Form.Item>
              <Form.Item label="命令行参数" name={FieldNamesForTrain.Args}>
                <Form.List name={FieldNamesForTrain.Args}>
                  {(fields, { add, remove }, { errors }) =>
                    fields.length === 0 ? (
                      <VersionInfoText />
                    ) : (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item style={{ marginBottom: '0' }} {...field}>
                            <VersionInfoText />
                          </Form.Item>
                        ))}
                      </>
                    )
                  }
                </Form.List>
              </Form.Item>
              <Form.Item label="环境变量" name={FieldNamesForTrain.Env}>
                <Form.List name={FieldNamesForTrain.Env}>
                  {(fields, { add, remove }, { errors }) =>
                    fields.length === 0 ? (
                      <VersionInfoText />
                    ) : (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item noStyle key={field.key}>
                            <Form.Item {...field} style={{ marginBottom: '0' }}>
                              <EnvSetup />
                            </Form.Item>
                          </Form.Item>
                        ))}
                        <Form.Item noStyle key={'last-env-var-config'}>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                        {/* <Tooltip title={SYSTEM_ENV_VARIABLE} className={styles['view-env-variables']}>
                      <Text>查看系统环境变量</Text>
                    </Tooltip> */}
                      </>
                    )
                  }
                </Form.List>
              </Form.Item>
            </div>
          </div>
          <div className={cx['config-item']}>
            <div className={cx['config-item-title']}>{ConfigTitle.OtherConfig}</div>
            <div className={cx['config-item-body']}>
              <Form.Item required label="数据集挂载" name={FieldNamesForTrain.IsMountDataset}>
                <VersionInfoText />
              </Form.Item>
              {isDatasetOpen ? (
                <Form.Item required label="数据集名称" name={FieldNamesForTrain.EvalDatasetName}>
                  <VersionInfoText />
                </Form.Item>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

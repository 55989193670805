import { ReactNode, useEffect } from 'react';
import { Button, Flex, message, Modal, Tooltip } from '@fuxi/eevee-ui';
import { useMutation } from '@apollo/client';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { LogType, ServerType } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { RESTART_SERVICE, TURN_OFF_SERVICE, TURN_ON_SERVICE } from '@/service/schema/service/service-mutation';
import { updateLog } from '@/store/log';
import startImg from '@/assets/image/start.png';
import shutdownImg from '@/assets/image/shutdown.png';
import restartImg from '@/assets/image/restart.png';
import fixImg from '@/assets/image/fix.png';
import { ServiceStatus } from '@/typings/common';
import { isProjectCreator } from '@/utils/getUserName';
import ProjectDetailContainer from './ProjectDetailContainer';
import { useServiceOperation } from './useServiceOperation';
import style from './ServiceList.module.less';

interface Prop {
  record: Service;
  showInIcon?: boolean;
  editPermission?: boolean;
  inCard?: boolean;
}

const ServiceBtn: React.FC<{
  inCard?: boolean;
  onClick: () => void;
  loading?: boolean;
  className?: string;
  icon: ReactNode;
  title: string;
  disabled?: boolean;
}> = ({ inCard, onClick, loading, icon, title, className, disabled }) => {
  const btnProps = { disabled, ghost: !inCard, size: inCard ? 'middle' : ('small' as SizeType) };

  const Btn: React.FC<{
    children?: ReactNode;
  }> = ({ children }) => (
    <Button
      {...btnProps}
      style={disabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
      icon={!inCard ? icon : null}
      className={className}
      loading={loading}
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}>
      {children}
    </Button>
  );

  return (
    <>
      {inCard ? (
        <Btn>{title}</Btn>
      ) : (
        <Tooltip
          trigger={'hover'}
          getPopupContainer={t => t.parentElement as HTMLElement}
          title={loading ? undefined : title}
          placement="bottom">
          <span>
            <Btn />
          </span>
        </Tooltip>
      )}
    </>
  );
};

export const ServiceStatusOperation = (prop: Prop) => {
  const { record, showInIcon = true, editPermission, inCard } = prop;
  const [turnOnServer, { data: turnOnServerRes, loading: serverOnStarting }] = useMutation(TURN_ON_SERVICE);
  const [turnOffServer, { data: turnOffServerRes, loading: serverOnClosing }] = useMutation(TURN_OFF_SERVICE);
  const [restartServer, { data: restartServerRes, loading: serverOnRestarting }] = useMutation(RESTART_SERVICE);
  const { restoringServer, canRestoreService, handleRestoreService, toSyncSSHKey } = useServiceOperation(record);

  const dispatch = useAppDispatch();

  const container = ProjectDetailContainer.useContainer();
  const { getServicesData, currentProject } = container;
  const projectId = currentProject.id;
  const userInfo = useAppSelector(state => state.user);
  const isCreator = isProjectCreator(currentProject.creator, userInfo.name);

  const getServicePermission = (record: Service) => {
    return record.type === ServerType.Main ? isCreator : record.creator === userInfo.email || isCreator;
  };

  const getOpenClosePermission = (record: Service) => {
    return record.type === ServerType.Main ? isCreator : record.creator === userInfo.email;
  };

  const getTurnOnDisabled = (record: Service) => {
    return (
      ((record.status !== ServiceStatus.Pending || !record.version) && record.type === ServerType.Main) ||
      (record.status !== ServiceStatus.Pending && record.type === ServerType.Personal) ||
      !getServicePermission(record) ||
      pending
    );
  };

  const getTurnOffAndRestartDisabled = (record: Service) => {
    return (
      ![ServiceStatus.Running, ServiceStatus.Error].includes(record.status as ServiceStatus) ||
      pending ||
      !getServicePermission(record)
    );
  };

  const getFixDisabled = (record: Service) => {
    return !canRestoreService || restoringServer || !editPermission;
  };

  const pending = serverOnStarting || serverOnClosing || serverOnRestarting;
  const turnOnService = async (record: Service) => {
    if (record.type === ServerType.Main) {
      Modal.confirm({
        title: `确认开启 ${record.name}吗`,
        onOk: () =>
          turnOnServer({ variables: { serviceId: record.id } }).then(() => {
            getServicesData({ variables: { projectId: currentProject.id } });
          }),
        className: 'confirm-modal',
      });
      return;
    }
    await turnOnServer({ variables: { serviceId: record.id } });
    await getServicesData({ variables: { projectId: currentProject.id } });
  };

  const turnOffService = async (record: Service) => {
    if (record.type === ServerType.Main) {
      Modal.confirm({
        title: `确认关闭 ${record.name}吗`,
        onOk: async () => {
          await turnOffServer({ variables: { serviceId: record.id } });
          await getServicesData({ variables: { projectId: currentProject.id } });
        },
        className: 'confirm-modal',
      });
      return;
    }
    await turnOffServer({ variables: { serviceId: record.id } });
    await getServicesData({ variables: { projectId: currentProject.id } });
  };

  const restartService = async (record: Service) => {
    if (record.type === ServerType.Main) {
      Modal.confirm({
        title: `确认重启 ${record.name}吗`,
        onOk: async () => {
          await restartServer({ variables: { serviceId: record.id } });
          await getServicesData({ variables: { projectId: currentProject.id } });
        },
        className: 'confirm-modal',
      });
      return;
    }
    await restartServer({ variables: { serviceId: record.id } });
    await getServicesData({ variables: { projectId: currentProject.id } });
  };

  useEffect(() => {
    if (turnOnServerRes) {
      message.success(`开启${record?.type === ServerType.Main ? '主干服务器' : '私服'}成功，请稍后刷新查看状态`);
      dispatch(
        updateLog({
          serviceId: record.id,
          type: LogType.PublishLog,
          data: { content: '开启私服成功', pending: true },
        })
      );
      getServicesData({ variables: { projectId } });
    }
  }, [turnOnServerRes]);

  useEffect(() => {
    if (turnOffServerRes) {
      message.success(`关闭${record?.type === ServerType.Main ? '主干服务器' : '私服'}成功，请稍后刷新查看状态`);
      dispatch(
        updateLog({
          serviceId: record.id,
          type: LogType.PublishLog,
          data: { content: `关闭${record?.type === ServerType.Main ? '主干服务器' : '私服'}成功`, pending: true },
        })
      );
      getServicesData({ variables: { projectId } });
    }
  }, [turnOffServerRes]);

  useEffect(() => {
    if (restartServerRes) {
      message.success(`重启${record?.type === ServerType.Main ? '主干服务器' : '私服'}成功，请稍后刷新查看状态`);
      dispatch(
        updateLog({
          serviceId: record.id,
          type: LogType.PublishLog,
          data: { content: '重启私服成功', pending: true },
        })
      );
      getServicesData({ variables: { projectId } });
    }
  }, [restartServerRes]);

  return (
    <Flex className={style('server-status-container')} justifyAround gap={8}>
      {record.type === ServerType.Personal && (
        <ServiceBtn
          inCard={inCard}
          icon={null}
          onClick={toSyncSSHKey}
          className={style('server-status-icon', 'sync-ssh-btn')}
          title="同步公钥"
        />
      )}
      {getOpenClosePermission(record) && getTurnOffAndRestartDisabled(record) && (
        <ServiceBtn
          inCard={inCard}
          icon={<img src={startImg} alt="start" />}
          onClick={() => turnOnService(record)}
          className={style('server-status-icon')}
          loading={serverOnStarting}
          title="开启"
          disabled={getTurnOnDisabled(record)}
        />
      )}
      {getOpenClosePermission(record) && getTurnOnDisabled(record) && (
        <ServiceBtn
          inCard={inCard}
          icon={<img src={shutdownImg} alt="shutdown" />}
          onClick={() => turnOffService(record)}
          className={style('server-status-icon')}
          loading={serverOnClosing}
          title="关闭"
          disabled={getTurnOffAndRestartDisabled(record)}
        />
      )}
      {record.type === ServerType.Main && !getTurnOffAndRestartDisabled(record) && (
        <ServiceBtn
          inCard={inCard}
          icon={<img src={restartImg} alt="shutdown" />}
          onClick={() => restartService(record)}
          className={style('server-status-icon')}
          loading={serverOnRestarting}
          title="重启"
        />
      )}
      {!getFixDisabled(record) && (
        <ServiceBtn
          inCard={inCard}
          icon={<img src={fixImg} alt="fix" />}
          loading={restoringServer}
          onClick={handleRestoreService}
          className={style('server-status-icon')}
          title="修复"
        />
      )}
    </Flex>
  );
};

import React, { useState, useRef, PropsWithChildren, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { UploadProps } from 'antd/es/upload';
import { UploadOutlined } from '@ant-design/icons';
import { useRequest, useUpdateEffect } from '@fuxi/eevee-hooks';
import { Button, message, Upload as AntUpload, UploadFile, Form } from '@fuxi/eevee-ui';
import { isEmpty } from 'lodash-es';

import { VersionBaseFieldNames } from './VersionBaseConfig';
import service from '../../../../service';
import model from '@ai-training/service/model';
import cx from './UploadModel.module.less';
import upload from '@ai-training/service/upload';

export enum UploadStatus {
  WAITING = 'waiting',
  UPLOADING = 'uploading',
  FAIL = 'error',
  SUCCESS = 'done',
  ASSEMBLE = 'assemble', // 发送分片finish请求，服务器组装文件中
}
type Slice = {
  start: number;
  end: number;
  seq: number;
  // 针对上传文件列表的情况，上传任务池面向分片为单位划分的任务集合，故需要每个数据携带fileMd5来维护数据信息
  file: UploadFile & File;
  fileID: string;
  totalSlices: number;
};
type UploadTask = {
  slice: Slice;
  controller: AbortController;
  isFetching: boolean;
};

export type MultipartUploadInterface = UploadProps &
  PropsWithChildren & {
    formatId: string;
    beforeUploadCallback: (uuid: string) => void;
    initialFileList?: UploadFile<any>[];
    isSavedModel?: boolean;
    value?: string[];
    // sliceUploadUrl: string;
    // uploadSuccessUrl: string;
    onSuccess?: (fileName: string, file: File) => any;
    onError?: (fileName: string, file: File) => any;
    onFileNameListChange?: (fileNameList: UploadFile<any>[]) => any;
    validateFileName?: (fileNameList: UploadFile<any>[]) => any;
    multipartLine_MB?: number;
    concurrencyMax?: number; // 网络并行请求数量限制
    retryMax?: number; // 请求错误重试次数限制
    payload?: {
      [key: string]: any;
    };
  };

const Upload: React.FC<MultipartUploadInterface> = props => {
  const {
    beforeUploadCallback,
    formatId,
    initialFileList,
    isSavedModel = false,
    value,
    multipartLine_MB = 30,
    concurrencyMax = 5,
    retryMax = 3,
    // sliceUploadUrl,
    // uploadSuccessUrl,
    onChange,
    payload,
    onError,
    onSuccess,
    onRemove,
    onFileNameListChange,
    validateFileName,
  } = props;
  const {
    getModelUploadId,
    modelUploadPart,
    modelMergePart,
    uploadPartByUrl,
    cancelModelUploaded,
    deleteModelUploaded,
  } = upload;
  const { getModelUploadUuid } = model;

  // const { data: uploadModelUuid, run: fetchUploadModelUuid } = useRequest(getModelUploadUuid, {
  //   manual: true,

  // });

  const [uploadModelUuid, setUploadModelUuid] = useState<any>(null);
  const [uploadModelId, setUploadModelId] = useState<{
    [key: string]: string;
  }>({});

  // const uploadModelUuid = payload?.uploadModelUuid;
  const formInstance = Form.useFormInstance();
  const [urlState] = useSearchParams();
  const abilityName = urlState.get('ability_name');
  const CHUNK_SIZE = 1024 * 1024 * multipartLine_MB; // 默认30M分片
  const [fileListState, setFileListState] = useState<UploadFile[]>([]); // 文件列表状态更新
  const completedFileEtagsRef = useRef({}); // 判断分片传输是否完成
  const [fetchUuidLoading, setFetchUuidLoading] = useState(false);
  const sliceQueue = useRef({}); // 分片集合
  const taskStack = useRef({}); // 网络并行请求进程集合
  const completedCountRef = useRef({}); // 判断分片传输是否完成
  const retryMap = useRef({}); // 记录分片的重试次数
  const status = useRef({}); // 分片逻辑判断
  const currentUploadId = useRef(''); //uploadId
  const currentSlices = useRef({} as any);

  const beforeUpload = async (file, fileList) => {
    setFetchUuidLoading(true);
    // try {
    const res = await getModelUploadUuid();
    // } finally {
    setFetchUuidLoading(false);
    // }

    setUploadModelUuid(res?.data?.upload_uuid);
    // 忽略隐藏文件
    // if (file.webkitRelativePath && file.webkitRelativePath.includes('/.')) {
    //   return false;
    // }

    // 大于1为上传
    if (isSavedModel) {
      // 检查是否有.pb和是否有variables文件夹
      const pbFile = fileList.find(file => file.webkitRelativePath.includes('.pb'));
      const variablesFile = fileList.find(file => file.webkitRelativePath.includes('variables/'));
      if (pbFile && variablesFile) {
        beforeUploadCallback(res?.data?.upload_uuid);
        return true;
      } else {
        const idx = fileList.findIndex(item => item.uid === file.uid);

        // message.error('请上传.pb文件和variables文件夹');
        if (idx === 0) {
          message.error(
            '您选择的是 Savedmodel 格式，请选择1个文件夹，文件夹下至少包括1份 saved_model.pb 文件和 1个 variables 文件夹'
          );
        }

        return false;
      }
    }

    if (file.size <= 20 * 1024 * 1024 * 1024) {
      beforeUploadCallback(res?.data?.upload_uuid);
      return true;
    }
    message.warning('单文件大小不超过20G');
    return false;
  };

  // 文件列表状态更新
  const updateFile = (fileMd5, upload) => {
    let changedFile;
    setFileListState(preFileList => {
      const changedFileList = preFileList?.map(file => {
        if (file.uid === fileMd5) {
          changedFile = file;
          return { ...file, ...upload };
        }
        return file;
      });
      onChange?.({ file: changedFile, fileList: changedFileList });

      return changedFileList;
    });
  };

  const addFile = file => {
    setFileListState(preFileList => {
      const fileList = [file, ...preFileList];
      // 为支持多文件上传时，维护分片级别的线程池，即文件b完成，会触发选取分片队列里的分片（可能为文件a）所以ant默认的onchange事件监听失效，需组件自行维护
      //   onChange?.({ file, fileList });
      return fileList;
    });
  };
  // 上传多个文件时， 组件维护一个线程池来限制分片上传的数量
  const getConcurrencySilceUploadingCount = () => {
    return (Object.values(taskStack?.current) as Array<any>)?.reduce((acc, cur) => {
      return cur?.length ? acc + cur?.length : acc;
    }, 0);
  };

  const getWaitingSilce = () => {
    // 上传多文件时，只要并行线程池没满，就取出并发上传
    const waitingFileIndex = (Object.values(sliceQueue.current) as Array<any>).findIndex(queue => !!queue?.length);
    const waitingFileMd5 = Object.keys(sliceQueue.current)?.[`${waitingFileIndex}`];
    return sliceQueue.current?.[`${waitingFileMd5}`].pop();
  };

  const getWaitingSilceCount = () => {
    // 上传多文件时，只要并行线程池没满，就取出并发上传
    return (Object.values(sliceQueue.current) as Array<any>).reduce((acc, cur) => {
      return cur?.length ? acc + cur?.length : acc;
    }, 0);
  };

  const cancelRequests = fileMd5 => {
    taskStack.current?.[`${fileMd5}`]?.forEach((item: UploadTask) => {
      item.controller.abort();
      item.isFetching = false;
    });
  };

  const wrapOnError = file => {
    const fileMd5 = file.uid;
    onError?.(file?.name, file);
    updateFile(fileMd5, { status: UploadStatus.FAIL });
    Object.assign(status.current, {
      [`${fileMd5}`]: UploadStatus.FAIL,
    });
    cancelRequests(fileMd5);
  };

  const uploadSlice = async (sliceInfo: Slice, retry = false) => {
    // 失败后不继续上传
    const fileMd5 = sliceInfo?.file?.uid;
    if (status.current?.[`${fileMd5}`] === UploadStatus.FAIL) return;
    const onSliceSuccess = slice => {
      // 上传请求成功后,  将当前分片出栈
      const fileMd5 = sliceInfo?.file?.uid;
      Object.assign(taskStack.current, {
        [`${fileMd5}`]: taskStack.current?.[`${fileMd5}`]?.filter(
          (item: UploadTask) => item.slice.seq !== sliceInfo.seq
        ),
      });
      // 更新分片上传进度
      Object.assign(completedCountRef.current, {
        [`${fileMd5}`]: {
          ...completedCountRef.current?.[`${fileMd5}`],
          completed: completedCountRef.current?.[`${fileMd5}`]?.completed + 1,
        },
      });

      // 更新文件上传的etags
      const prevEtags = completedFileEtagsRef.current?.[`${fileMd5}`];
      Object.assign(completedFileEtagsRef.current, {
        [`${fileMd5}`]: [...(prevEtags ? prevEtags : []), slice.etag],
      });

      updateFile(fileMd5, {
        percent:
          (completedCountRef.current?.[`${fileMd5}`]?.completed /
            (completedCountRef.current?.[`${fileMd5}`]?.total + 1)) *
          100,
      });
      // 检查是否还有分片待上传，或可以结束上传任务
      execNextTask();
    };
    /* 上传分片失败 */
    const onSliceError = (sliceInfo: Slice) => {
      const fileMd5 = sliceInfo?.file?.uid;
      // 重试分片上传
      const retry = retryMap.current?.[`${fileMd5}`];
      Object.assign(retry, { [sliceInfo.seq]: retry[sliceInfo.seq] || 0 });
      if (retry[sliceInfo.seq] < retryMax) {
        const _task = taskStack.current?.[`${fileMd5}`].find((item: UploadTask) => item.slice.seq === sliceInfo.seq);
        _task && Object.assign(_task, { isFetching: false });
        uploadSlice(sliceInfo, true);
        retry[sliceInfo.seq] = retry[sliceInfo.seq] + 1;
        return;
      }
      wrapOnError(sliceInfo?.file);
    };

    const getCancelToken = sliceInfo => {
      const fileMd5 = sliceInfo?.file?.uid;
      const controller = new AbortController(); // 浏览器fetch支持AbortController https://developer.mozilla.org/zh-CN/docs/Web/API/AbortController
      if (retry) {
        taskStack.current?.[`${fileMd5}`]?.forEach((item: UploadTask) => {
          if (item.slice.seq === sliceInfo.seq) {
            item.controller = controller;
            item.isFetching = true;
          }
        });
      } else {
        taskStack.current?.[`${fileMd5}`]?.push({ slice: sliceInfo, controller: controller, isFetching: true });
      }
      return controller.signal;
    };

    const sendRequest = (sliceInfo: Slice) => {
      const { start, end, seq, file, fileID, totalSlices } = sliceInfo;
      // fd.append('capability_name', abilityName!);
      // fd.append('slice_index', seq.toString());
      // fd.append('model_uuid', fileID);
      // fd.append('file_name', file.name);
      // fd.append('file', file.slice(start, end));
      // fd.append('size', `${file.size}`);

      // fd.append('part_num', (seq + 1).toString());
      // // fd.append('total_slices', totalSlices.toString()); // 分片总数量
      // const fd = new FormData();
      // fd.append('upload_id', fileID);
      // fd.append('upload_uuid', uploadModelUuid);
      // fd.append('filename', file.name);
      // fd.append('part_num', (seq + 1).toString());
      // fd.append('data', file.slice(start, end));

      // 去掉根目录
      // const webkitRelativePath = file.webkitRelativePath.replace(/[^/]+$/, '');

      let path = file.webkitRelativePath;
      let newPath = path.substring(path.indexOf('/') + 1);

      const body = {
        upload_uuid: uploadModelUuid,
        upload_id: fileID,
        filename: isSavedModel ? newPath : file.name,
        part_num: seq + 1,
        data: file.slice(start, end),
      };

      modelUploadPart(body, getCancelToken(sliceInfo))
        .then(res => {
          const url = res?.data?.url;
          // 如果是http，替换为https
          const secureUrl = url?.replace('http://', 'https://');
          if (secureUrl) {
            uploadPartByUrl(secureUrl, file.slice(start, end), getCancelToken(sliceInfo)).then(res => {
              const etag = res?.headers?.etag;
              onSliceSuccess({ ...sliceInfo, etag: etag });
            });
          }
        })
        .catch(e => {
          console.log(e, 'e');
          onSliceError(sliceInfo);
        });
    };
    sendRequest(sliceInfo);
  };

  const wrapOnSuccess = file => {
    const fileMd5 = file.uid;
    onSuccess?.(file?.name, file);
    updateFile(fileMd5, { status: UploadStatus.SUCCESS, percent: 100 });
    Object.assign(status.current, {
      [`${fileMd5}`]: UploadStatus.SUCCESS,
    });
    cancelRequests(fileMd5);
  };

  const checkCompleteTask = async () => {
    const completeFiles = (Object.values(completedCountRef?.current) as Array<any>).filter(
      completeTask => completeTask && completeTask?.completed === completeTask?.total
    );
    completeFiles.forEach(({ fileMd5, name, fileID, file }) => {
      if ([UploadStatus.SUCCESS, UploadStatus.ASSEMBLE, UploadStatus.FAIL].includes(status.current?.[`${fileMd5}`])) {
        return;
      }
      Object.assign(status.current, {
        [`${fileMd5}`]: UploadStatus.ASSEMBLE,
      });

      let path = file.webkitRelativePath;
      let newPath = path.substring(path.indexOf('/') + 1);

      const body = {
        upload_uuid: uploadModelUuid,
        upload_id: fileID,
        filename: isSavedModel ? newPath : name,
        part_etags: completedFileEtagsRef.current?.[`${fileMd5}`],
      };

      modelMergePart(body)
        .then(async res => {
          if (res?.data) {
            wrapOnSuccess(completedCountRef.current?.[`${fileMd5}`].file);

            // const data = res.data.model_info;
            // formInstance.setFieldValue(VersionBaseFieldNames.ModelName, data.model_name);
            // formInstance.setFieldValue(VersionBaseFieldNames.CustomModel, data);
            // formInstance.validateFields([[VersionBaseFieldNames.CustomModel, VersionBaseFieldNames.ModelName]]);

            // onModelUploadSuccess?.(uploadModelUuid);
          } else {
            wrapOnError(completedCountRef.current?.[`${fileMd5}`].file);
          }
        })
        .catch(e => {
          wrapOnError(completedCountRef.current?.[`${fileMd5}`].file);
        });

      const modelName = formInstance.getFieldValue([
        VersionBaseFieldNames.CustomModel,
        VersionBaseFieldNames.ModelName,
      ]);

      // const fd = new FormData();
      // fd.append('capability_name', abilityName!);
      // fd.append('file_name', name);
      // fd.append('model_uuid', currentUploadId.current);
      // fd.append('total_count', currentSlices.current?.totalSlices);
      // fd.append('size', file.size);
      // !!modelName && fd.append('model_name', modelName);

      // service.upload
      //   .mergeModelUpload(fd)
      //   .then(res => {
      //     if (res.status === 200) {
      //       wrapOnSuccess(completedCountRef.current?.[`${fileMd5}`].file);
      //       const data = res.data.model_info;
      //       formInstance.setFieldValue(VersionBaseFieldNames.ModelName, data.model_name);
      //       formInstance.setFieldValue(VersionBaseFieldNames.CustomModel, data);
      //       formInstance.validateFields([[VersionBaseFieldNames.CustomModel, VersionBaseFieldNames.ModelName]]);
      //     } else {
      //       wrapOnError(completedCountRef.current?.[`${fileMd5}`].file);
      //     }
      //   })
      //   .catch(e => {
      //     wrapOnError(completedCountRef.current?.[`${fileMd5}`].file);
      //   });
    });
  };

  // 动态加载新的并行下载进程
  const execNextTask = () => {
    // 限制并行请求数
    if (getWaitingSilceCount() > 0 && getConcurrencySilceUploadingCount() < concurrencyMax) {
      const slice = getWaitingSilce();
      currentSlices.current = slice;
      slice && uploadSlice(slice);
    }
    checkCompleteTask();

    // 是否可以继续执行
    return getWaitingSilceCount() > 0 && getConcurrencySilceUploadingCount() < concurrencyMax;
  };

  const slicesUploadInit = (file, totalSlices, fileID, blob) => {
    const fileMd5 = file?.uid;
    let i = 0;
    let queue: Slice[] = [];
    // 发起组装请求使用的信息
    Object.assign(completedCountRef.current, {
      [`${fileMd5}`]: { completed: 0, total: totalSlices, name: file?.name, fileID, fileMd5, file },
    });
    Object.assign(sliceQueue.current, { [`${fileMd5}`]: [] }); // 分片集合
    Object.assign(taskStack.current, { [`${fileMd5}`]: [] }); // 网络并行请求进程集合
    Object.assign(retryMap.current, { [`${fileMd5}`]: {} });
    while (i < totalSlices) {
      // 分片上传线程是分片级别，分片需要标记文件
      queue.unshift({
        file,
        seq: i,
        start: i * CHUNK_SIZE,
        end: Math.min((i + 1) * CHUNK_SIZE, blob.size),
        fileID,
        totalSlices: totalSlices,
      });
      i = i + 1;
    }
    Object.assign(sliceQueue.current, { [`${fileMd5}`]: queue }); // 分片集合
    let ok = true;
    while (ok) {
      ok = execNextTask();
    }
  };
  const customRequest = async ({ file }: any) => {
    fileListState?.[0] && (await wrapOnRemove(fileListState?.[0]));
    const normalInit = file => {
      const fileMd5 = file.uid;
      addFile({
        name: file?.name,
        uid: fileMd5,
        status: UploadStatus.UPLOADING,
        percent: 0,
      });

      Object.assign(status.current, {
        [`${fileMd5}`]: UploadStatus.UPLOADING,
      });
    };
    const beforeRequest = async () => {
      const totalSlices = Math.ceil(file.size / CHUNK_SIZE);
      // const data = await service.upload.getModelUploadId();
      const model_uuid = uploadModelUuid;

      if (!model_uuid) {
        message.error('上传失败');
        return;
      }

      let path = file.webkitRelativePath;
      let newPath = path.substring(path.indexOf('/') + 1);

      const fileID = await getModelUploadId({ filename: isSavedModel ? newPath : file?.name, upload_uuid: model_uuid });

      setUploadModelId(pre => {
        return {
          ...pre,
          [file.uid]: fileID,
        };
      });

      if (!fileID) {
        message.error('上传失败');
        return;
      }

      currentUploadId.current = model_uuid;
      slicesUploadInit(file, totalSlices === 0 ? 1 : totalSlices, fileID, file);
    };
    normalInit(file);
    beforeRequest();
  };

  const deleteFile = fileMd5 => {
    setFileListState(preFileList => {
      const fileList = preFileList?.filter(file => {
        return file.uid !== fileMd5;
      });

      return fileList;
    });
    onChange?.({ file: {} as any, fileList: fileListState });

    Object.assign(completedCountRef.current, {
      [`${fileMd5}`]: undefined,
    });
    Object.assign(sliceQueue.current, { [`${fileMd5}`]: undefined }); // 分片集合
    Object.assign(taskStack.current, { [`${fileMd5}`]: undefined }); // 网络并行请求进程集合
    Object.assign(retryMap.current, { [`${fileMd5}`]: undefined });
  };

  const wrapOnRemove = (file: UploadFile, manual = false) => {
    if (manual) {
      if (file?.percent === 100) {
        deleteModelUploaded({
          upload_uuid: uploadModelUuid,
          filename: file?.name,
        });
      } else {
        const fileID = uploadModelId?.[file?.uid];

        cancelModelUploaded({
          upload_uuid: uploadModelUuid,
          filename: file?.name,
          upload_id: fileID,
        });
      }
    }

    deleteFile(file?.uid);
    cancelRequests(file?.uid);
    onRemove?.(file);

    formInstance.setFieldValue(VersionBaseFieldNames.CustomModel, {
      [VersionBaseFieldNames.ModelName]: formInstance.getFieldValue([
        VersionBaseFieldNames.CustomModel,
        VersionBaseFieldNames.ModelName,
      ]),
    });
  };

  const handleChange = info => {
    if (fileListState?.length === 0) {
      setFileListState(info.fileList);
    }

    if (info.file.status === 'done') {
      message.success(`${info.file.name} 上传成功`);
    }
  };

  useUpdateEffect(() => {
    const fileNameList = fileListState?.filter(file => !(file.status === UploadStatus.FAIL));
    onFileNameListChange?.(fileNameList);
    validateFileName?.(fileNameList);
    onChange?.({ file: {} as any, fileList: fileListState });
  }, [fileListState]);

  useUpdateEffect(() => {
    // setFileListState(value);
  }, [value?.length]);

  useEffect(() => {
    !isEmpty(value) && setFileListState(value);
  }, []);

  useEffect(() => {
    setFileListState([]);
  }, [formatId]);

  return (
    <AntUpload
      directory={isSavedModel}
      name="file"
      fileList={fileListState}
      // fileList={fileListState}
      showUploadList={
        fileListState?.length > 0
          ? {
              showRemoveIcon: true,
              showDownloadIcon: false,
              showPreviewIcon: false,
            }
          : false
      }
      onRemove={file => wrapOnRemove(file, true)}
      onChange={handleChange}
      beforeUpload={beforeUpload}
      customRequest={customRequest}
      className={cx['ai-train-upload']}>
      <Button loading={fetchUuidLoading} type="dashed" icon={<UploadOutlined />}>
        点击上传
      </Button>
    </AntUpload>
  );
};

export default Upload;

/* eslint-disable react-func/max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { useAntDrawer } from '@fuxi/eevee-hooks';
import { Drawer } from '@fuxi/eevee-ui';
import { FitAddon } from 'xterm-addon-fit';
import { Terminal as XTerm } from 'xterm';
import { removeHttpUrl } from '@/utils/removeHttpHost';
import { getWebTerminalUrl } from '@/service/terminal';
import style from './index.module.less';

import 'xterm/css/xterm.css';

interface Prop {
  children: React.ReactNode;
  id: string;
  ssh: string;
}

const WebTerminalDrawer: React.FC<Prop> = ({ children, id, ssh }) => {
  const { showDrawer, commonAntDrawerProps, visible } = useAntDrawer();
  const [webTerminalWs, setWebTerminalWs] = useState<WebSocket | null>();
  const [term, setTerm] = useState<XTerm | null>();
  let timer: any;

  const fetchWebTerminalUrl = () => {
    return getWebTerminalUrl(+id);
  };

  useEffect(() => {
    if (visible) {
      fetchWebTerminalUrl().then(res => {
        const handledUrl = removeHttpUrl(res.edgeNodeWebUrl);
        setWebTerminalWs(new WebSocket(`wss://${window.location.host}/api-terminal-ws${handledUrl}`));
        setTerm(
          new XTerm({
            cursorBlink: true,
            fontFamily: '"Roboto Mono", "Courier New", "Courier", monospace',
            fontSize: 16,
          })
        );
      });
    }

    if (!visible) {
      webTerminalWs?.close();
      term?.dispose();
    }
  }, [visible]);

  useEffect(() => {
    if (term && webTerminalWs) {
      handleWsEvent();
    }
  }, [term]);

  const initTerminal = () => {
    const fitAddon = new FitAddon();
    const terminalContainerEle = document.getElementById(`webTerminalContainer${id}`)!;
    terminalContainerEle && term?.open(terminalContainerEle);
    term?.loadAddon(fitAddon);
    fitAddon.fit();

    if (webTerminalWs) {
      term?.focus();
      term?.write('connecting...');
    }

    term?.onData((data: any) => {
      const stdinMessageObj = {
        opType: 'stdin',
        data: {
          stdin: data,
        },
      };

      if (webTerminalWs) {
        webTerminalWs?.send(JSON.stringify(stdinMessageObj));
      }
    });
  };

  const handleWsEvent = () => {
    webTerminalWs!.binaryType = 'arraybuffer';
    webTerminalWs!.onopen = () => {
      const connectMessageObj = {
        opType: 'connect',
        data: {
          columns: term?.cols,
          lines: term?.rows,
          shell: 'sh',
        },
      };

      timer = setInterval(() => {
        webTerminalWs!.send(JSON.stringify({ type: 'ping' }));
      }, 30000);

      webTerminalWs!.send(JSON.stringify(connectMessageObj));
    };

    webTerminalWs!.onerror = (err: any) => {
      console.log('socket error', err);
    };

    webTerminalWs!.onmessage = (event: { data: string }) => {
      const messageObj = JSON.parse(event.data);
      if (messageObj.opType === 'stdout') {
        term?.write(messageObj.data.stdout);
      } else if (messageObj.opType === 'stderr') {
        term?.write(messageObj.data.stderr);
      } else if (messageObj.opType === 'connect') {
        if (messageObj.data.status === 'error') {
          term?.write(`\r\n${messageObj.data.message}`);
        } else {
          // 换行
          // this.connected = true;
          term?.write('\r\n');
        }
      }
    };

    webTerminalWs!.onclose = e => {
      clearInterval(timer);
      term?.write('\r\nconnection closed');
    };

    initTerminal();
  };

  return (
    <>
      <span onClick={showDrawer}>{children}</span>
      <Drawer
        destroyOnClose
        width={970}
        title={'终端'}
        {...commonAntDrawerProps}
        closable
        className={style('drawer-container')}
        onClose={commonAntDrawerProps.onClose as any}>
        <div className={style('terminal-wrapper')}>
          <div className={style('terminal-inner')} id={`webTerminalContainer${id}`}></div>
        </div>
      </Drawer>
    </>
  );
};

export default WebTerminalDrawer;

import { Input, Typography, Image } from '@fuxi/eevee-ui';

export enum ConfigTitle {
  CodeInfo = '代码信息',
  RuntimeEnv = '运行环境',
  OtherConfig = '其他配置',
  DeployMethod = '部署方式',
}

export const InfoInput = <Input placeholder="-" readOnly style={{ cursor: 'default' }} bordered={false} />;

export const VersionInfoText = ({ value }: { value?: string }) => {
  return <Typography.Text children={value || '-'} ellipsis={{ tooltip: value || undefined }} />;
};

export const VersionInfoSwitchText = ({ value }: { value?: boolean }) => {
  return <Typography.Text children={value ? '已开启' : '未开启'} ellipsis={{ tooltip: value || undefined }} />;
};

export const VersionInfoImage = ({ value }: { value?: string }) => {
  return !!value ? (
    <Image src={value} width={120} height={120} style={{ objectFit: 'contain' }} />
  ) : (
    <VersionInfoText value={value} />
  );
};

import { useEffect, useState } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import { Flex, Form, Input, Button, message, Space, Spin, Tooltip } from '@fuxi/eevee-ui';
import ml from '@/service/ml';

import { useAppSelector } from '@/hooks';
import { getCurrentUrl } from '../utils/getCurrentUrl';
import cx from './index.module.less';

const CreateAbility: React.FC = () => {
  const location = useLocation();
  const [initialLocationUrl, setInitialLocationUrl] = useState<string>(`${location.pathname}${location.search}`);
  const history = useNavigate();
  const aiProjectId = useAppSelector(state => state.project.currentMlProject.aiProjectId);
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const { getMlPublicCapabilities, getCapabilityVersions, createCapability } = ml;

  const {
    data: capability,
    loading: loadingCaps,
    run: fetchAbilities,
  } = useRequest(getMlPublicCapabilities, {
    manual: true,
  });
  useEffect(() => {
    if (!!aiProjectId) {
      fetchAbilities();
    }
    setInitialLocationUrl(`${location.pathname}${location.search}`);
  }, [aiProjectId]);

  const {
    data: versions,
    run: versionsRun,
    loading: loadingVersions,
  } = useRequest(getCapabilityVersions, { manual: true });

  const [form] = Form.useForm();
  const handelSave = () => {
    if (!aiProjectId) {
      message.error('未找到关联的丹炉项目');
      return;
    }
    form
      .validateFields()
      .then(async values => {
        setSubmitLoading(true);
        const currentCapability = capability?.data.list.find(item => item.uuid === values.base_capability.id);
        values.base_capability.share = currentCapability.share;
        const { data } = await createCapability({
          ...values,
          project_id: aiProjectId,
          base_capability: { ...values.base_capability, category_uuid: '1' },
        });
        message.success('创建成功');

        if (initialLocationUrl === getCurrentUrl() && initialLocationUrl.includes('/ml-project-ability/create')) {
          history(`../${data.uuid}`);
        }
      })
      .catch(() => {
        setSubmitLoading(false);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  const cancel = () => {
    history(-1);
  };
  const handelAbilityChange = val => {
    form.setFieldsValue({
      base_capability: {
        version_id: null,
      },
    });
    versionsRun({ id: val });
  };
  return (
    <Spin spinning={loadingCaps || loadingVersions} tip="加载中...">
      <Flex justifyCenter className={cx('wrapper')}>
        <Form
          className={cx('form')}
          form={form}
          labelAlign="right"
          autoComplete="off"
          initialValues={{}}
          style={{ width: 490 }}
          labelCol={{ span: 6 }}>
          <Form.Item
            label="基础能力："
            name={['base_capability', 'id']}
            rules={[{ required: true, message: '请选择基础能力' }]}>
            <Select
              showSearch
              listHeight={160}
              placeholder="请选择"
              optionFilterProp="label"
              onChange={handelAbilityChange}
              options={capability?.data?.list?.map(item => ({ value: item.uuid, label: item.name }))}
            />
          </Form.Item>
          <Form.Item
            label="版本："
            name={['base_capability', 'version_id']}
            rules={[{ required: true, message: '请选择版本' }]}>
            <Select
              showSearch
              listHeight={160}
              placeholder="请选择"
              optionFilterProp="label"
              options={versions?.data.list.map(item => ({
                value: item.uuid,
                label: item.code,
                desc: item.description,
              }))}
              optionRender={option => (
                <Flex justifyBetween>
                  <div>{option.data.label}</div>
                  <Tooltip className={cx('desc-in-selector')} placement="topLeft" title={option.data.desc}>
                    {option.data.desc}
                  </Tooltip>
                </Flex>
              )}
            />
          </Form.Item>
          <Form.Item
            label="能力名称："
            name="name"
            rules={[
              { required: true, message: '能力名称不得为空' },
              {
                pattern: /^[a-z0-9_]+$/,
                message: '只能输入小写字母、数字或下划线',
              },
              {
                pattern: /^[a-z].*/,
                message: '只能由小写字母开头',
              },
              {
                pattern: /.*[^_]$/,
                message: '不能以下划线结尾',
              },
            ]}
            extra={<div className={cx('extra')}>只能输入小写字母、数字或下划线, 小写字母开头且不能以下划线结尾</div>}>
            <Input placeholder="请输入" maxLength={20} showCount />
          </Form.Item>
          <Form.Item label="描述：" name="desc">
            <Input.TextArea placeholder="请输入" rows={4} maxLength={200} showCount />
          </Form.Item>
          <Space direction="horizontal" size={16}>
            <div />
          </Space>
          <Form.Item colon={false} label=" ">
            <Space>
              <Button onClick={cancel}>取消</Button>
              <Button type="primary" onClick={handelSave} loading={submitLoading}>
                确定
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Flex>
    </Spin>
  );
};

export default CreateAbility;

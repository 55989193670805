import { useEffect, useState } from 'react';
import { ConfigProvider, Empty, Modal, Radio, Spin, message } from '@fuxi/eevee-ui';
import { useRequest } from 'ahooks';
import ml from '@/service/ml';
import { commonTheme } from '@/styles/commonTheme';
import EmptyImg from '@/assets/image/empty2.png';

import { IDatasetItem, MlDatasetImportStatus, annotationTypeMap } from '../../MlDataset/const';
import cx from './index.module.less';

interface Prop {
  categories: string[];
  aiProjectId: number;
  onOk: (value: any) => void;
}

export const MlAddDatasetModal = (props: Prop) => {
  const { algorithm = undefined, token = {}, components = {} } = commonTheme;

  const { categories, aiProjectId, onOk } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [selectedDataset, setSelectedDataset] = useState<any>(undefined);
  const [fetchDatasetDetailLoading, setFetchDatasetDetailLoading] = useState<boolean>(false);
  const { getDatasetList, getDatasetDetail } = ml;
  const { data, run, loading } = useRequest(getDatasetList, {
    manual: true,
  });

  const handleOk = async () => {
    if (!selectedDataset) {
      message.error('请勾选数据集');
      return;
    }

    const info = await toGetDatasetDetail(selectedDataset.id);

    onOk?.([info]);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const toGetDatasetDetail = async (datasetId: string) => {
    const errorLog = '未找相关联的数据集';
    if (!datasetId) {
      message.error(errorLog);
      return;
    }
    try {
      setFetchDatasetDetailLoading(true);
      const data: {
        status: number;
        data: {
          name: string;
          first_version_data_count: number;
        };
      } = await getDatasetDetail(datasetId);
      setFetchDatasetDetailLoading(false);
      if (data.status !== 200) {
        message.error(errorLog);
        return;
      } else {
        const datasetInfo = {
          ...selectedDataset,
          data_count: data.data.first_version_data_count,
        };
        setSelectedDataset(datasetInfo);
        return datasetInfo;
      }
    } catch (error) {
      message.error(errorLog);
      setFetchDatasetDetailLoading(false);
      return;
    }
  };

  const handelRadioChange = (val, item) => {
    if (val.target.checked) {
      setSelectedDataset({
        id: item.id,
        first_version_id: item.first_version_id,
        name: item.zh_name,
        version: 1,
        data_count: item.first_version_data_count,
      });
    }
  };

  const getRadios = () => {
    const allDataset: IDatasetItem[] = data?.data?.list;
    const allValidDataset = allDataset?.filter(
      item => item.first_version_data_import_status !== MlDatasetImportStatus.Importing
    );    const currentProjectTagDataset = allValidDataset?.filter(
      item =>
        item.annotation_type &&
        item.classification === categories?.[0] &&
        annotationTypeMap[item.annotation_type] === categories?.[1]
    );
    return currentProjectTagDataset
      ?.filter(item => item.first_version_data_import_status !== MlDatasetImportStatus.Importing)
      .map(item => (
        <Radio
          className={cx('dataset-radio')}
          key={item.id}
          name={item.id + ''}
          value={item.id}
          onChange={val => handelRadioChange(val, item)}>
          {item.zh_name}
        </Radio>
      ));
  };

  useEffect(() => {
    if (!aiProjectId) {
      return;
    }
    run({
      with_annotation: false,
    });
  }, [aiProjectId]);

  return (
    <ConfigProvider
      theme={{
        algorithm,
        token: { ...token },
        components: {
          ...components,
          Modal: {
            wireframe: true,
          },
        },
      }}>
      <Modal
        okButtonProps={{
          loading: fetchDatasetDetailLoading,
        }}
        rootClassName={cx('modal-wrapper')}
        onOk={handleOk}
        onCancel={handleCancel}
        closable
        title="添加数据集"
        open={isModalOpen}>
        <Spin tip="正在请求数据集.." spinning={loading}>
          {!!getRadios()?.length ? (
            <Radio.Group>{getRadios()}</Radio.Group>
          ) : (
            <Empty description="暂无当前项目能力标签下对应数据集" image={EmptyImg}></Empty>
          )}
        </Spin>
      </Modal>
    </ConfigProvider>
  );
};

import React, { useEffect, useState } from 'react';
import { Flex, Tooltip } from '@fuxi/eevee-ui';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import MultiCascader, { TreeNode } from '@/components/MultiCascader';
import { useAppSelector } from '@/hooks';
import store from '@/store';
import { Pod, PodLabel, PodStatus } from '@/store/realtimeLogs';
import { LogRole } from '@/constants';
import { TerminalStatusMap } from '@/constants/terminal';
import cx from './NodeSelect.module.less';

interface Prop {
  onPodChange: (data: string[]) => void;
  selectPodList: string[];
}

interface NodeItemProps {
  name: string;
  status: string;
}

const NODE_CONTAINER = [
  {
    value: PodLabel.cloud,
    title: '云端节点',
    children: [],
  },
  {
    value: PodLabel.edge,
    title: '终端节点',
    children: [],
  },
  {
    value: PodLabel.gateway,
    title: PodLabel.gateway,
    children: [],
  },
  {
    value: PodLabel.name,
    title: PodLabel.name,
    children: [],
  },
];

const StatusMap = {
  [PodStatus.Running]: '运行',
  [PodStatus.Error]: '异常',
};

export const NodeItem: React.FC<NodeItemProps> = props => {
  const { name, status } = props;

  return (
    <Flex justifyBetween className={cx('node-select-item')}>
      <div className={cx('node-name')}>
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      </div>

      {status && (
        <Flex alignCenter className={cx('status-wrapper')}>
          <div style={{ backgroundColor: TerminalStatusMap[status]?.color }} className={cx('node-status-tag')}></div>
          <div className={cx('node-status')}>{StatusMap[status]}</div>
        </Flex>
      )}
    </Flex>
  );
};

function parsePodListToRealPodNameList(selectPodList: string[]) {
  const temp: string[] = [];
  const podList = store.getState().realtimeLogs.podList;
  selectPodList.forEach(item => {
    if (find(NODE_CONTAINER, { value: item })) {
      podList.forEach(pod => {
        if (pod.label === item) {
          temp.push(pod.podName);
        }
      });
    } else {
      temp.push(item);
    }
  });

  return temp;
}

export const parseNodeList = (data: string[], role: LogRole[]) => {
  const temp = getNodesList(data, role);
  return temp.join(',');
};

export const getNodesList = (data: string[], role: LogRole[]) => {
  const podList = store.getState().realtimeLogs.podList;
  let temp: string[] = parsePodListToRealPodNameList(data);

  // 如果没选引擎日志，将 gateway 和 namespace 节点剔除，因为这两个节点都是引擎日志
  if (!role.includes(LogRole.Engine)) {
    temp = temp.filter(
      item => ![PodLabel.name, PodLabel.gateway].includes(find<Pod>(podList, { podName: item })!.label)
    );
  }
  return temp;
}

export const ifSelectGatewayOrNamePod = (data: string[]) => {
  const podList = store.getState().realtimeLogs.podList;
  const temp: string[] = parsePodListToRealPodNameList(data);

  return temp.find(item => [PodLabel.name, PodLabel.gateway].includes(find<Pod>(podList, { podName: item })!.label));
};

export const NodesSelect: React.FC<Prop> = props => {
  const { onPodChange, selectPodList } = props;
  const podList = useAppSelector(state => state.realtimeLogs.podList);
  const [podTreeNode, setPodTreeNode] = useState<TreeNode[]>();

  useEffect(() => {
    // 生成pod tree node
    const options: TreeNode[] = cloneDeep(NODE_CONTAINER);

    podList.forEach(item => {
      const matching = find(options, { value: item.label });
      if (matching) {
        matching.children?.push({
          value: item.podName,
          title: <NodeItem name={item.podName} status={item.status} />,
        });
      }
    });

    setPodTreeNode(options);
  }, [podList]);

  return (
    <MultiCascader
      okText={'确定'}
      cancelText={'取消'}
      allowClear={false}
      value={selectPodList}
      onChange={(newVal, selectedItems) => {
        onPodChange(newVal);
      }}
      data={podTreeNode}
      placeholder=""
      maxTagCount={2}
      className="log-cascader-trigger"
      popupClassName="log-cascader-popup"
      getPopupContainer={() => document.getElementsByClassName('log-cascader-trigger')[0] as HTMLElement}
      renderTitle={node => {
        const { value } = node;
        const map = { [PodLabel.cloud]: '云端节点', [PodLabel.edge]: '终端节点' };
        return <span>{map[value] ? map[value] : value}</span>;
      }}
    />
  );
};

import React, { FC, useEffect, useState, useRef } from 'react';
import { Table, DatePicker, TableColumnsType, Empty } from '@fuxi/eevee-ui';
import dayjs, { Dayjs } from 'dayjs';

import { useAppSelector, useAppDispatch } from '@/hooks';
import { getConsumeRecords } from '@/service/cosumeRecord';
import { getConsumeRecordThunk, ConsumeRecordType, BillType, setLoading } from '@/store/ConsumeRecord';

import emptyImg from '@/assets/image/empty2.png';

import cx from './ConsumeRecord.module.less';

type ConsumeRecordProps = {};

const dateFormat = 'YYYY-MM-DD';

const formatEmptyText = val => val || '-';

const columns: TableColumnsType<ConsumeRecordType> = [
  { title: '消费日期', dataIndex: 'consumeDate', key: 'consumeDate', render: formatEmptyText, ellipsis: true },
  { title: '消费项', dataIndex: 'consumeItem', key: 'consumeItem', render: formatEmptyText, ellipsis: true },
  {
    title: '分类',
    dataIndex: 'billType',
    key: 'billType',
    filterMode: 'tree',
    filters: [
      { text: BillType.PUBLIC_AI, value: BillType.PUBLIC_AI },
      { text: BillType.PROJECT_AI, value: BillType.PROJECT_AI },
      { text: BillType.CROWD_SOURCING, value: BillType.CROWD_SOURCING },
      { text: BillType.TRAIN_RESOURCE, value: BillType.TRAIN_RESOURCE },
      { text: BillType.PARALLEL_DEPLOY, value: BillType.PARALLEL_DEPLOY },
    ],
    onFilter: (value, record) => value === record.billType,
    ellipsis: true,
  },
  { title: '操作者', dataIndex: 'operator', key: 'operator', render: formatEmptyText, ellipsis: true },
  { title: '账单号', dataIndex: 'orderId', key: 'orderId', render: formatEmptyText, ellipsis: true },
  { title: '开始使用时间', dataIndex: 'startTime', key: 'startTime', render: formatEmptyText, ellipsis: true },
  { title: '结束使用时间', dataIndex: 'endTime', key: 'endTime', render: formatEmptyText, ellipsis: true },
  { title: '使用量', dataIndex: 'cost', key: 'cost', render: formatEmptyText, ellipsis: true },
];

const ConsumeRecord: FC<ConsumeRecordProps> = () => {
  const dispatch = useAppDispatch();
  const { consumeRecordList, loading } = useAppSelector(state => state.consumeRecord);
  const { createdTime, id } = useAppSelector(state => state.project.currentProject);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([dayjs(createdTime), dayjs()]);
  const [rangePickerDisabled, setRangePickerDisabled] = useState(false);
  const initialPageSize = useRef(Math.floor((document.body.clientHeight - 344) / 56));

  useEffect(() => setDateRange([dayjs(createdTime), dayjs()]), [createdTime]);

  useEffect(() => {
    dispatch(setLoading(true));
    createdTime &&
      dispatch(
        getConsumeRecordThunk({
          projectId: Number(id),
          startTime: dateRange ? dateRange[0].format(dateFormat) : dayjs(createdTime).format(dateFormat),
          endTime: dateRange ? dateRange[1].format(dateFormat) : dayjs().format(dateFormat),
        })
      );
  }, [dateRange]);

  useEffect(() => {
    createdTime &&
      getConsumeRecords({
        projectId: Number(id),
        startTime: dateRange[0].format(dateFormat),
        endTime: dateRange[1].format(dateFormat),
      }).then((res: any) => {
        try {
          if (res?.projectBills) {
            setRangePickerDisabled(res?.projectBills?.length === 0);
          } else {
            throw new Error('获取消费记录失败');
          }
        } catch (error) {
          console.error(error);
        }
      });
  }, [createdTime]);

  const onDateRangeChange = date => {
    setDateRange(date);
  };

  return (
    <div className={cx('consume-record', { 'empty-record': consumeRecordList?.length === 0 })}>
      <div className={cx('consume-record-title')}>使用监控</div>
      <div className={cx('consume-record-date')}>
        <div className={cx('consume-record-date-label')}>消费日期</div>
        <DatePicker.RangePicker
          disabled={rangePickerDisabled}
          value={dateRange}
          onChange={onDateRangeChange}
          ranges={{
            今天: [dayjs(), dayjs()],
            最近三天: [dayjs().subtract(2, 'days'), dayjs()],
            最近七天: [dayjs().subtract(6, 'days'), dayjs()],
            创建至今: [dayjs(createdTime), dayjs()],
          }}
        />
      </div>
      {consumeRecordList.length > 0 ? (
        <Table
          columns={columns}
          dataSource={consumeRecordList}
          loading={loading}
          pagination={{
            showTotal: total => `共 ${total} 项`,
            defaultPageSize: initialPageSize.current,
            pageSizeOptions: Array.from(new Set([initialPageSize.current, 10, 20, 50, 100])).sort((a, b) => a - b),
          }}
        />
      ) : (
        <div className={cx('empty')}>
          <Empty image={emptyImg} description={'暂无消费记录'} imageStyle={{ width: '253px', height: '143px' }} />
        </div>
      )}
    </div>
  );
};

export default ConsumeRecord;

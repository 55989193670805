import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, TableButtons } from '@fuxi/eevee-ui';

import AddDataModal from './AddDataModal';
import { dataSelectColumns } from './const';
import { popup } from '../../../utils/popup';

import cx from './AddDataModal.module.less';

type DataType = {
  key: string;
  title: string;
};
interface Props {
  value?: DataType[];
  accept?: string;
  onChange?: (value?: DataType[]) => void;
}

const DataSelect: React.FC<Props> = ({ value, onChange }) => {
  const urlParams = useParams();
  const [dataList, setDataList] = useState<DataType[] | undefined>(value);
  const operation = {
    title: '操作',
    render: (_, record) => (
      <TableButtons
        data={[
          {
            text: '移除',
            type: 'danger',
            onClick: () => {
              handleRemove(record);
            },
          },
        ]}
      />
    ),
  };
  const handleRemove = record => {
    const index = dataList?.indexOf(record);
    index !== undefined && dataList?.splice(index, 1);
    const arrCopy = dataList?.slice();
    setDataList(arrCopy);
    onChange?.(arrCopy);
  };
  const handelOk = value => {
    onChange?.(value);
    setDataList(value);
  };
  return (
    <div className="data-select">
      <Button
        className={cx['add-dataset-btn']}
        type="dashed"
        onClick={() => {
          popup({
            Component: AddDataModal,
            onOk: handelOk,
            payload: { defaultTargetKeys: dataList?.map(item => item.key), projectId: urlParams.projectId },
          });
        }}>
        <PlusOutlined />
        添加
      </Button>
      {!!dataList?.length && (
        <Table
          className={cx['dataset-form']}
          childrenColumnName="off"
          columns={[...dataSelectColumns, operation]}
          dataSource={dataList}
          pagination={false}
          rowKey="dataset"
        />
      )}
    </div>
  );
};

export default DataSelect;

/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-lines */

import { useEffect, useState } from 'react';
import { Button, Col, Empty, Image, Row, Table, Tooltip } from 'antd';
import { PaperClipOutlined, UserOutlined } from '@ant-design/icons';
import { Flex, Space, message } from '@fuxi/eevee-ui';
import { ColumnType } from 'antd/lib/table';
import CopyToClipboard from 'react-copy-to-clipboard';
import 'react-svg-radar-chart/build/css/index.css';
import { Radar } from '@antv/g2plot';
import { useAppSelector } from '@/hooks';
import { getProjectReviews } from '@/service/miniReview';
import IconFont from '@/components/IconFont';
import { getAverage } from '@/pages/miniReview/utils';
import { Stage, stageTrans } from '@/constants/miniReviews';
import { reviewDimension, reviewStage } from '../../const';
import { ProjectMiniInformationSetting } from '../../ProjectDetailMini/ProjectMiniInformationSetting';
import { useIsDataManager } from '../../ProjectDetailMini/utils/useIsDataManager';
import { CommitsCard } from '../ProjectCommentsCard';
import style from './ProjectInformation.module.less';

interface ProjectInformationProps {
  editPermission: boolean;
}
export const ProjectInformation = (props: ProjectInformationProps) => {
  const [reviewMap, setReviewMap] = useState(new Map());
  const [scoreMap, setScoreMap] = useState(new Map());
  const [refresh, setRefresh] = useState(false);
  const { nameList, resource, id, name, description } = useAppSelector(state => state.project.currentProject);
  const userInfo = useAppSelector(state => state.user);
  const [stage, setStage] = useState<string>(Stage.DEMO);
  const [radarData, setRadarData] = useState<any>();
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);
  interface Score {
    artisticExpression: number[];
    difficulty: number[];
    effect: number[];
    experience: number[];
    innovation: number[];
    prospect: number[];
    score: number[];
    ai: number[];
  }
  useEffect(() => {
    if (props.editPermission) {
      getProjectReviews(id, '0').then((data: any) => {
        const demoReviews = data?.projectReviews?.filter((r: any) => r.stage === reviewStage.DEMO);
        const midReviews = data?.projectReviews?.filter((r: any) => r.stage === reviewStage.MID);
        const finalReviews = data?.projectReviews?.filter((r: any) => r.stage === reviewStage.FINAL);
        setReviewMap(reviewMap.set(reviewStage.DEMO, demoReviews));
        setReviewMap(reviewMap.set(reviewStage.MID, midReviews));
        setReviewMap(reviewMap.set(reviewStage.FINAL, finalReviews));
        if (demoReviews?.length > 0) {
          const initialScore: Score = {
            artisticExpression: [],
            difficulty: [],
            effect: [],
            experience: [],
            innovation: [],
            prospect: [],
            score: [],
            ai: [],
          };
          demoReviews.forEach((review: any, index: number) => {
            if (review.reviewDetail) {
              const detailJson = JSON.parse(review.reviewDetail);
              initialScore.artisticExpression.push(detailJson.artisticExpression);
              initialScore.difficulty.push(detailJson.difficulty);
              initialScore.effect.push(detailJson.effect);
              initialScore.experience.push(detailJson.experience);
              initialScore.innovation.push(detailJson.innovation);
              initialScore.prospect.push(detailJson.prospect);
              initialScore.score.push(detailJson.score);
              initialScore.ai.push(detailJson.ai || 0);
            }
            setScoreMap(scoreMap.set(reviewStage.DEMO, initialScore));
          });
        }
        if (midReviews?.length > 0) {
          const initialScore: Score = {
            artisticExpression: [],
            difficulty: [],
            effect: [],
            experience: [],
            innovation: [],
            prospect: [],
            score: [],
            ai: [],
          };
          midReviews.forEach((review: any) => {
            if (review.reviewDetail) {
              const detailJson = JSON.parse(review.reviewDetail);
              initialScore.artisticExpression.push(detailJson.artisticExpression);
              initialScore.difficulty.push(detailJson.difficulty);
              initialScore.effect.push(detailJson.effect);
              initialScore.experience.push(detailJson.experience);
              initialScore.innovation.push(detailJson.innovation);
              initialScore.prospect.push(detailJson.prospect);
              initialScore.score.push(detailJson.score);
              initialScore.ai.push(detailJson.ai || 0);
            }
            setScoreMap(scoreMap.set(reviewStage.MID, initialScore));
          });
        }
        if (finalReviews?.length > 0) {
          const initialScore: Score = {
            artisticExpression: [],
            difficulty: [],
            effect: [],
            experience: [],
            innovation: [],
            prospect: [],
            score: [],
            ai: [],
          };
          finalReviews.forEach((review: any) => {
            if (review.reviewDetail) {
              const detailJson = JSON.parse(review.reviewDetail);
              initialScore.artisticExpression.push(detailJson.artisticExpression);
              initialScore.difficulty.push(detailJson.difficulty);
              initialScore.effect.push(detailJson.effect);
              initialScore.experience.push(detailJson.experience);
              initialScore.innovation.push(detailJson.innovation);
              initialScore.prospect.push(detailJson.prospect);
              initialScore.score.push(detailJson.score);
              initialScore.ai.push(detailJson.ai || 0);
            }
            setScoreMap(scoreMap.set(reviewStage.FINAL, initialScore));
          });
        }
        setRefresh(true);
        const scoreData = getRadarData(stage);
        if (!document.getElementById('container')) {
          return;
        }
        const radarPlot = new Radar('container', {
          width: 320,
          height: 320,
          padding: 80,
          data: scoreData!.map((d: any) => ({ ...d })),
          xField: 'name',
          yField: 'score',
          meta: {
            score: {
              min: 0,
              max: 10,
              nice: true,
            },
          },
          area: {},
          lineStyle: {
            lineDash: [0, 0],
          },
          // point: {
          //   size: 4,
          // },
          xAxis: {
            line: null,
            label: {
              style: { fill: 'rgba(255, 255, 255, 0.65)' },
            },
            tickLine: null,
            grid: {
              line: {
                style: {
                  lineDash: null,
                  lineWidth: 1,
                  stroke: 'rgba(255, 255, 255, 0.2)',
                },
              },
            },
          },
          yAxis: {
            line: null,
            tickLine: null,
            label: false,
            grid: {
              line: {
                type: 'line',
                style: {
                  lineDash: null,
                  lineWidth: 1,
                  stroke: 'rgba(255, 255, 255, 0.2)',
                },
              },
              alternateColor: ['rgba(255, 255, 255, 0.03)', 'rgba(255, 255, 255, 0.03)'],
            },
          },
        });
        setRadarData(radarPlot);
        radarPlot.render();
      });
    }
  }, [props.editPermission]);
  const columns: ColumnType<any>[] = [
    { title: '职责', dataIndex: 'duty', key: 'duty', align: 'center', width: 96, className: style('duty-row') },
    {
      title: '名单',
      dataIndex: 'namesList',
      key: 'namesList',
      align: 'left',
      width: 500,
      className: style('names-row'),
    },
  ];
  let dataSource,
    resourceObj: Resource | undefined = undefined,
    nameListObj: MiniNameList | undefined = undefined;
  if (nameList && JSON.parse(nameList)) {
    nameListObj = JSON.parse(nameList);
    dataSource = [
      {
        key: '1',
        duty: '策划组',
        namesList: nameListObj?.Designer?.join('、 '),
      },
      {
        key: '2',
        duty: '程序组',
        namesList: nameListObj?.Programmer?.join('、 '),
      },
      {
        key: '3',
        duty: '美术组',
        namesList: nameListObj?.Art?.join('、 '),
      },
      {
        key: '4',
        duty: 'QA组',
        namesList: nameListObj?.QA?.join('、 '),
      },
    ];
  }
  if (resource && JSON.parse(resource)) {
    resourceObj = JSON.parse(resource);
  }
  const getRadarData = (stage: string) => {
    const demoRaderData: { name: string; score: number }[] = [];
    const midRaderData: { name: string; score: number }[] = [];
    const finalRaderData: { name: string; score: number }[] = [];

    const demoScore = scoreMap.get(reviewStage.DEMO);
    const midScore = scoreMap.get(reviewStage.MID);
    const finalScore = scoreMap.get(reviewStage.FINAL);

    Object.getOwnPropertyNames(reviewDimension).forEach((dim: string) => {
      demoScore
        ? demoRaderData.push({
            name:
              reviewDimension[dim].cn +
              ' ' +
              (isNaN(+getAverage(demoScore[dim], demoScore[dim].length).toFixed(1))
                ? '-'
                : getAverage(demoScore[dim], demoScore[dim].length).toFixed(1)),
            score: +getAverage(demoScore[dim], demoScore[dim].length).toFixed(1),
          })
        : demoRaderData.push({ name: reviewDimension[dim].cn, score: 0 });
      midScore
        ? midRaderData.push({
            name:
              reviewDimension[dim].cn +
              ' ' +
              ' ' +
              (isNaN(+getAverage(midScore[dim], midScore[dim].length).toFixed(1))
                ? '-'
                : getAverage(midScore[dim], midScore[dim].length).toFixed(1)),
            score: +getAverage(midScore[dim], midScore[dim].length).toFixed(1),
          })
        : midRaderData.push({ name: reviewDimension[dim].cn, score: 0 });
      finalScore
        ? finalRaderData.push({
            name:
              reviewDimension[dim].cn +
              ' ' +
              ' ' +
              (isNaN(+getAverage(finalScore[dim], finalScore[dim].length).toFixed(1))
                ? '-'
                : getAverage(finalScore[dim], finalScore[dim].length).toFixed(1)),
            score: +getAverage(finalScore[dim], finalScore[dim].length).toFixed(1),
          })
        : finalRaderData.push({ name: reviewDimension[dim].cn, score: 0 });
    });

    switch (stage) {
      case Stage.DEMO:
        return demoRaderData;
      case Stage.MID:
        return midRaderData;
      case Stage.FINAL:
        return finalRaderData;
    }
  };
  const judgeDataExist = () => {
    return reviewMap?.get?.(stage)?.length > 0;
  };
  const chooseStage = (stage: string) => {
    // setIsDemo(isDemo);
    setStage(stage);
    radarData.options.data = getRadarData(stage)?.map((d: any) => ({ ...d }));
    radarData.render();
  };
  const getDimensionAverageScore = () => {
    const data = getRadarData(stage);
    const length = data?.length || 0;
    if (!data) {
      return '暂无评分';
    }
    let sum = 0;
    for (let i = 0; i < length; i++) {
      sum += data[i].score;
    }
    const arerage = (sum / length).toFixed(1);
    return isNaN(+arerage) ? '数据非法' : arerage;
  };
  const getAverageScore = () => {
    const stageScoreMap = scoreMap.get(stage);
    const scoreArray = stageScoreMap?.score;
    if (!stageScoreMap) {
      return '暂无评分';
    }
    const averageScore = getAverage(scoreArray, scoreArray?.length);
    return isNaN(averageScore) ? '数据非法' : averageScore.toFixed(1);
  };
  return (
    <Flex className={style('wrapper')} flexDirection="column">
      {!!resourceObj?.assetsOnShow?.length && (
        <Flex>
          <Flex.Item style={{ width: '100%' }}>
            <Flex className={style('assets-container')}>
              {resourceObj?.assetsOnShow?.map(asset =>
                asset?.isVideo ? (
                  <Flex.Item key={asset.url}>
                    <video
                      key={asset.url}
                      className={style('asset-on-show')}
                      controls
                      preload=""
                      poster={asset.poster}
                      src={asset.url}
                    />
                  </Flex.Item>
                ) : (
                  <Flex.Item key={asset.url} className={style('asset-on-show')}>
                    <Image className={style('asset-on-show-image')} src={asset.url} />
                  </Flex.Item>
                )
              )}
            </Flex>
          </Flex.Item>
        </Flex>
      )}
      <Flex>
        <Flex.Item flex={1}>
          <Flex className={style('table-container')} flexDirection="column">
            <Flex.Item>
              <Flex alignCenter className={style('user-icon-container')}>
                <UserOutlined className={style('user-icon')} />
                <span className={style('user-name')}>成员列表</span>

                {useIsDataManager(userInfo) && (
                  <ProjectMiniInformationSetting
                    description={description}
                    name={name}
                    projectId={id}
                    resource={resourceObj}
                    nameList={nameListObj}></ProjectMiniInformationSetting>
                )}
              </Flex>
            </Flex.Item>
            <Flex.Item>
              {nameList && dataSource && (
                <Table
                  className={style('table')}
                  // rowClassName={style('row')}
                  showHeader={false}
                  pagination={false}
                  bordered={true}
                  columns={columns}
                  dataSource={dataSource}
                />
              )}
            </Flex.Item>
          </Flex>
        </Flex.Item>
        <Flex.Item className={style('resource-container')}>
          <Flex flexDirection="column">
            <Flex.Item>
              <Flex alignCenter className={style('user-icon-container')}>
                <PaperClipOutlined className={style('user-icon')} />
                <span className={style('user-name')}>资源</span>
              </Flex>
            </Flex.Item>
            <Flex.Item>
              <Flex className={style('resource-link-container')} justifyBetween>
                <Flex.Item>
                  <span>SVN链接：</span>
                </Flex.Item>
                <Flex.Item>
                  <Flex>
                    <CopyToClipboard text={resourceObj?.SVNUrl || ''} onCopy={() => message.success('复制成功！')}>
                      <Space className={style('copy-space')}>
                        <IconFont type="icon-fuzhi" className={style('copy-icon')} />
                      </Space>
                    </CopyToClipboard>
                  </Flex>
                </Flex.Item>
              </Flex>
              <div className={style('resource-link')} style={{ marginBottom: 24 }}>
                <Tooltip placement="topLeft" title={resourceObj?.SVNUrl}>
                  <span>{resourceObj?.SVNUrl}</span>
                </Tooltip>
              </div>
              <Flex className={style('resource-link-container')} justifyBetween>
                <Flex.Item>
                  <span className={style('link-title')}>云空间链接：</span>
                </Flex.Item>
                <Flex.Item>
                  <Flex>
                    <CopyToClipboard
                      text={resourceObj?.cloudSpaceUrl || ''}
                      onCopy={() => message.success('复制成功！')}>
                      <Space className={style('copy-space')}>
                        <IconFont type="icon-fuzhi" className={style('copy-icon')} />
                      </Space>
                    </CopyToClipboard>
                  </Flex>
                </Flex.Item>
              </Flex>
              <div className={style('resource-link')}>
                <Tooltip placement="topLeft" title={resourceObj?.cloudSpaceUrl}>
                  <span>{resourceObj?.cloudSpaceUrl}</span>
                </Tooltip>
              </div>
            </Flex.Item>
          </Flex>
        </Flex.Item>
      </Flex>
      {props.editPermission && (
        <Flex className={style('review-records')}>
          <Flex.Item flex={1}>
            <div className={style('comments-container')}>
              <Flex alignCenter className={style('user-icon-container')}>
                <IconFont type="icon-pingshenjilu" className={style('user-icon')} />
                <span className={style('user-name')}>评审记录</span>
              </Flex>
              <Flex style={{ width: 200 }} justifyBetween>
                <Button
                  className={style('stage-button')}
                  type={stage === Stage.DEMO ? 'primary' : 'default'}
                  onClick={() => chooseStage(Stage.DEMO)}
                  shape="round"
                  value="large">
                  {stageTrans.DEMO}
                </Button>
                <Button
                  className={style('stage-button')}
                  type={stage === Stage.MID ? 'primary' : 'default'}
                  onClick={() => chooseStage(Stage.MID)}
                  shape="round"
                  value="default">
                  {stageTrans.MID}
                </Button>
                <Button
                  className={style('stage-button')}
                  type={stage === Stage.FINAL ? 'primary' : 'default'}
                  onClick={() => chooseStage(Stage.FINAL)}
                  shape="round"
                  value="default">
                  {stageTrans.FINAL}
                </Button>
              </Flex>
              <div className={style('reviews')}>
                {!judgeDataExist() ? (
                  <div>
                    <Empty style={{ marginTop: 150 }} description={<p>暂无评审记录</p>} />
                  </div>
                ) : (
                  <Row gutter={[16, 16]}>
                    {reviewMap?.get(stage)?.map((review: any, index: number) => {
                      if (review.reviewDetail) {
                        const detailJson = JSON.parse(review.reviewDetail);
                        return (
                          <Col key={review.id} span={8}>
                            <CommitsCard
                              key={review.id}
                              index={index}
                              judgeName={review.judgeName}
                              info={!!detailJson?.issue ? detailJson?.issue : '该评委未发表评论'}></CommitsCard>
                          </Col>
                        );
                      }
                    })}
                  </Row>
                )}
              </div>
            </div>
          </Flex.Item>
          <Flex.Item>
            <div className={style('score-container')}>
              <Flex alignCenter className={style('score-title')}>
                <span>项目整体评分：</span>
                <span className={style('score')}>{getAverageScore()}</span>
              </Flex>
              <div id="container" className={style('rader-container')}></div>
              <div className={style('six-dimension-average-container')}>
                {getRadarData(stage)?.length}维平均分：
                <span className={style('six-dimension-score')}>{getDimensionAverageScore()}</span>
              </div>
            </div>
          </Flex.Item>
        </Flex>
      )}
    </Flex>
  );
};

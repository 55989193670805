import { useState } from 'react';
import { message } from '@fuxi/eevee-ui';
import { upload } from '@/service/upload';
import { nullImage } from '@/pages/project/const';

export const useImgUpload = () => {
  const [iconFile, setIconFile] = useState('');
  const [imgChanged, setImgChanged] = useState(false);

  const uploadIcon = async (iconFile: string) => {
    try {
      const res = await upload(iconFile);
      return res?.data[0]?.download_url;
    } catch (e) {
      return { error: 'upload failed' };
    }
  };

  const waitImgUpload = async (imgChanged: boolean, iconFile: string, payload: Record<string, any>) => {
    if (imgChanged && iconFile !== '') {
      message.info('上传图片中...');
      const iconResult = await uploadIcon(iconFile);
      !iconResult.error && (payload.iconUrl = iconResult);
    }

    if (iconFile === '' && imgChanged) {
      payload.iconUrl = nullImage;
    }
  };

  return { iconFile, setIconFile, imgChanged, setImgChanged, waitImgUpload };
};

import { useEffect, useState } from 'react';
import { CloseOutlined, EyeOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Flex,
  Modal,
  Popconfirm,
  Tooltip,
  Image,
  Pagination,
  message,
  Icon,
  Spin,
  Empty,
} from '@fuxi/eevee-ui';
import type { PaginationConfig } from 'antd/lib/pagination';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { cloneDeep, last } from 'lodash-es';
import service from '@/service';
import { IInfoItem } from '../const';
import { getOrder } from '../util';
import cx from './index.module.less';

const ImageText = ({
  pagination,
  fetchDetail,
  inDelete,
  checkedList,
  onCheckChange,
  list,
  datasetVid,
}: {
  fetchDetail: () => void;
  pagination: PaginationConfig;
  inDelete: boolean;
  checkedList: any[];
  onCheckChange: (item: any) => void;
  datasetVid: string;
  list: IInfoItem[];
}) => {
  const navigate = useNavigate();
  const tmpList = cloneDeep(list);
  const [modalList, setModalList] = useState<IInfoItem[]>([]);

  useEffect(() => {
    setModalList(tmpList);
  }, [list]);

  const [previewItem, setPreviewItem] = useState<IInfoItem | null>(list[0]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const onPreview = item => {
    setPreviewVisible(true);
    setPreviewItem(item);
  };
  const switchPreview = (type: 'prev' | 'next') => {
    if (type === 'prev' && modalList[0].id === previewItem?.id) return;
    if (type === 'next' && last(modalList)?.id === previewItem?.id) return;

    const currentIndex = modalList.findIndex(item => item.id === previewItem?.id);
    if (type === 'prev') {
      setPreviewItem(modalList[currentIndex - 1]);
    } else {
      setPreviewItem(modalList[currentIndex + 1]);
    }
  };

  const [isDelete, setIsDelete] = useState(false);
  const handleClose = () => {
    if (isDelete) {
      fetchDetail();
    }
    setPreviewItem(null);
    setIsDelete(false);
    setPreviewVisible(false);
  };

  const { mutate: deleteItem, isLoading: deleteLoading } = useMutation<any, any, any>({
    mutationFn: ({ datasetVid, ids }) => service.ml.deleteDatasets({ datasetVid, ids }),
    onSuccess: res => {
      message.success('删除成功');
      setIsDelete(true);
      let currentIndex = modalList.findIndex(item => item.id === previewItem?.id);
      modalList.splice(currentIndex, 1);
      if (modalList.length === 0) {
        handleClose();
        return;
      }
      // 当删除的是最后一条时，需要切换到上一条
      if (currentIndex === modalList.length) {
        currentIndex = currentIndex - 1;
      }
      // fetchDetail();
      setModalList([...modalList]);
      setPreviewItem(modalList[currentIndex]);
    },
  });

  return (
    <>
      {!list.length ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无数据" />
      ) : (
        <div className={cx('info-list')}>
          {list?.map((item, idx) => (
            <div className={cx('info-card')} key={item.id}>
              <div className={cx('info-card-top')}>
                <img src={item.data} className={cx('info-card-img')} alt=""></img>
                <div className={cx('info-card-img-mask')} onClick={e => onPreview(item)}>
                  <EyeOutlined style={{ paddingRight: 4, cursor: 'pointer' }} />
                  <span style={{ cursor: 'pointer' }}>预览</span>
                </div>
              </div>

              <Flex alignCenter justifyBetween className={cx('info-card-bottom')}>
                <Flex.Item flex={'0 0 calc(100%-56px)'} className={cx('annotation')}>
                  <Tooltip title={item.annotation} placement="topLeft">
                    {item.annotation}
                  </Tooltip>
                </Flex.Item>
                <Flex.Item flex={'0 0 56px'} style={{ textAlign: 'right' }}>
                  {inDelete ? (
                    <Checkbox
                      checked={checkedList.includes(item.id)}
                      onChange={e => {
                        onCheckChange({ ...item, checked: e.target.checked });
                      }}
                    />
                  ) : (
                    <Icon
                      name="icon-biaozhu"
                      style={{ fontSize: 18, cursor: 'pointer' }}
                      onClick={() => navigate(`annotate-image?order=${getOrder(idx, pagination)}`)}
                    />
                  )}
                </Flex.Item>
              </Flex>
            </div>
          ))}
        </div>
      )}

      <div className={cx('pagination')}>
        <Pagination
          {...{
            pageSize: pagination.pageSize,
            showQuickJumper: true,
            onChange: pagination.onChange,
            current: pagination.current,
            total: pagination.total,
            showSizeChanger: false,
            pageSizeOptions: [16, 18, 32, 36],
            showTotal: () => `共 ${pagination.total} 条`,
          }}
        />
      </div>
      <Modal
        className={cx('preview-modal')}
        open={previewVisible}
        width={892}
        title={
          <Flex alignCenter justifyBetween style={{}}>
            <Flex.Item>预览</Flex.Item>
            <Flex.Item>
              <CloseOutlined onClick={handleClose} />
            </Flex.Item>
          </Flex>
        }
        footer={
          <Flex alignCenter justifyBetween>
            <Flex.Item>
              <Popconfirm
                title="提示"
                description="确定删除此图片吗？"
                onConfirm={() => deleteItem({ datasetVid, ids: [previewItem?.id] as number[] })}
                okText="确定"
                cancelText="取消">
                <Button type="text" danger icon={<Icon name="删除" />}>
                  删除
                </Button>
              </Popconfirm>
            </Flex.Item>
            <Flex.Item>
              <Flex>
                <div onClick={() => switchPreview('prev')}>
                  <Flex alignCenter className={cx('btn', previewItem?.id === modalList[0]?.id ? 'btn-disabled' : '')}>
                    <span>上一条</span>
                    <Icon name="icon-shangyitiao" size={32} style={{ marginLeft: 8 }} />
                  </Flex>
                </div>
                <div onClick={() => switchPreview('next')}>
                  <Flex
                    alignCenter
                    className={cx('btn', previewItem?.id === last(modalList)?.id ? 'btn-disabled' : '')}>
                    <Icon name="icon-xiayitiao" size={32} style={{ marginRight: 8 }} />
                    <span>下一条</span>
                  </Flex>
                </div>
              </Flex>
            </Flex.Item>
            <Flex.Item>
              <Button
                type="primary"
                icon={<Icon name="icon-biaozhu" />}
                onClick={() => navigate(`annotate-image?order=${getOrder(+previewItem?.order! - 1, pagination)}`)}>
                标注
              </Button>
            </Flex.Item>
          </Flex>
        }
        closable={false}
        onCancel={() => setPreviewVisible(false)}>
        <Spin spinning={deleteLoading}>
          <Flex className={cx('content')}>
            <Flex.Item style={{ marginRight: 16, borderRadius: 4, background: '#1E2127' }}>
              <Image
                style={{ height: 358, width: 550, objectFit: 'contain' }}
                preview={false}
                src={previewItem?.data}></Image>
            </Flex.Item>
            <Flex.Item className={cx('right')}>
              {previewItem?.annotation ? previewItem?.annotation : <span className={cx('white3')}>无标注信息</span>}
            </Flex.Item>
          </Flex>
        </Spin>
      </Modal>
    </>
  );
};

export default ImageText;

import { Card, Flex, Form, Select, Button, message } from '@fuxi/eevee-ui';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useRequest } from '@fuxi/eevee-hooks';

import service from '../../service';
import { serviceDetailPath } from '../../pages/onlineService/const';
import { OnlineServiceDetail } from '../../types/onlineService';

import cx from './transfer.module.less';

// import { abilityPublicPath } from './const';

const Transfer: React.FC = () => {
  const history = useNavigate();
  const urlParams = useParams();
  const [urlState] = useSearchParams();
  const [serviceDetail, getServiceDetail] = useState<OnlineServiceDetail>();
  const [submitLoading, setSubmitLoading] = useState<boolean>();
  const [form] = Form.useForm();

  const abilityId = urlState.get('abilityId') || urlParams.abilityId;
  const initialVersionId = urlParams.versionId;

  const { data: detail } = useRequest(() =>
    service.ability.getCapabilityDetails({
      capability_uuid: abilityId || '',
    })
  );
  const { data: versions } = useRequest(() =>
    service.ability.getCapabilityVersions({ capability_uuid: abilityId || '' })
  );
  const { run: serviceDetailRun } = useRequest(
    capabilityVersionId =>
      service.onlineService.appList({
        capability_uuid: abilityId,
        capability_version_uuid: capabilityVersionId,
      }),
    {
      onSuccess: ({ data }) => {
        getServiceDetail(data?.list?.[0] || {});
      },
      manual: true,
    }
  );

  useEffect(() => {
    if (initialVersionId) {
      serviceDetailRun(initialVersionId);
    }
  }, []);
  const handelSave = () => {
    form
      .validateFields()
      .then(async values => {
        setSubmitLoading(true);
        await service.onlineService.publicServeBind({
          service_uuid: serviceDetail?.service_uuid + '',
        });
        message.success('创建成功');
        const underVersion = versions?.data.list.find(item => item.id === initialVersionId);
        history(
          underVersion
            ? `../../../${serviceDetailPath}${serviceDetail?.service_uuid}`
            : `../../${serviceDetailPath}${serviceDetail?.service_uuid}`
        );
      })
      .catch(() => {
        setSubmitLoading(false);
      });
  };
  const cancel = () => {
    history('../');
  };

  return (
    <Card className={cx.transfer_bg} bordered={false}>
      <Flex justifyCenter>
        <Form labelAlign="right" form={form} style={{ width: 490 }} initialValues={{}} labelCol={{ span: 5 }}>
          <Form.Item label="能力名称：">
            <div>{detail?.data.name}</div>
          </Form.Item>
          <Form.Item label="能力ID：">
            <div>{abilityId}</div>
          </Form.Item>
          <Form.Item
            label="版本："
            name={['base_capability', 'version_id']}
            rules={[{ required: true, message: '请选择版本' }]}
            initialValue={initialVersionId}>
            {!!initialVersionId ? (
              <>{versions?.data.list.find(item => item.uuid === initialVersionId)?.code || '-'}</>
            ) : (
              <Select
                showSearch
                listHeight={128}
                placeholder="请选择"
                optionFilterProp="label"
                options={versions?.data.list.map(item => ({ value: item.uuid, label: item.code }))}
                onChange={value => {
                  serviceDetailRun(value);
                }}
              />
            )}
          </Form.Item>
          <Form.Item label="服务名称：" name="name">
            <div>{serviceDetail?.service_name || '-'}</div>
          </Form.Item>
          <Form.Item label="接口地址：" name="desc">
            <div>{serviceDetail?.url || '-'}</div>
          </Form.Item>
          <Flex justifyCenter gapSM>
            <Button onClick={cancel}>取消</Button>
            <Button
              disabled={!(serviceDetail?.url && serviceDetail?.service_name)}
              type="primary"
              onClick={handelSave}
              loading={submitLoading}>
              确定
            </Button>
          </Flex>
        </Form>
      </Flex>
    </Card>
  );
};

export default Transfer;

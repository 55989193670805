import { Select, Input, Row, Typography } from '@fuxi/eevee-ui';
import React, { useState } from 'react';
import bindClass from 'classnames/bind';
import { IMemoryUnit, ResourceValue } from '@ai-training/types/ability';
import styles from './index.module.less';

const cx = bindClass.bind(styles);
const { Option } = Select;
const { Text } = Typography;

interface ResourceConfigProps {
  value?: ResourceValue;
  onChange?: (value: ResourceValue) => void;
  handleIsUseGpu: (value: boolean) => void;
  isEnableFullOption?: boolean;
}

const ResourceConfig: React.FC<ResourceConfigProps> = ({
  value = {},
  onChange,
  handleIsUseGpu,
  isEnableFullOption,
}) => {
  const [cpu, setCpu] = useState<number>();
  const [cpuUnit, setCpuUnit] = useState<string>('核');
  const [memory, setMemory] = useState<number>();
  const [memoryUnit, setMemoryUnit] = useState<IMemoryUnit>(IMemoryUnit.MB);
  const [gpu, setGpu] = useState<number>(0);

  const triggerChange = (changedValue: {
    cpuUnit?: string;
    cpu?: number;
    memory?: number;
    memoryUnit?: IMemoryUnit;
    gpu?: number;
  }) => {
    onChange?.({ cpuUnit, cpu, memory, memoryUnit, gpu, ...value, ...changedValue });
  };

  const onCpuChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber = parseInt(e.target.value || '0', 10);
    if (Number.isNaN(cpu)) {
      return;
    }
    if (!('cpu' in value)) {
      setCpu(newNumber);
    }
    triggerChange({ cpu: newNumber });
  };

  const onCpuUnitChange = (newCpuUnit: string) => {
    if (!('cpuUnit' in value)) {
      setCpuUnit(newCpuUnit);
    }
    triggerChange({ cpuUnit: newCpuUnit });
  };

  const onMemoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber = parseInt(e.target.value || '0', 10);
    if (Number.isNaN(cpu)) {
      return;
    }
    if (!('memory' in value)) {
      setMemory(newNumber);
    }
    triggerChange({ memory: newNumber });
  };

  const onMemoryUnitChange = (newMemoryUnit: IMemoryUnit) => {
    if (!('memoryUnit' in value)) {
      setMemoryUnit(newMemoryUnit);
    }
    triggerChange({ memoryUnit: newMemoryUnit });
  };

  const onGpuChange = (newGpuNumber: number) => {
    if (!('gpu' in value)) {
      setGpu(newGpuNumber);
    }

    if (typeof handleIsUseGpu === 'function') {
      if (newGpuNumber > 0) {
        handleIsUseGpu(true);
      } else {
        handleIsUseGpu(false);
      }
    }
    triggerChange({ gpu: newGpuNumber });
  };

  return (
    <div className={styles['resource-config-group']}>
      <Row>
        <Row className={styles['single-row']}>
          <Text className={styles['tip-text']}>CPU:</Text>
          <Input
            className={styles.cpu}
            type="number"
            value={value.cpu || cpu}
            onChange={onCpuChange}
            style={{ width: 248 }}
          />
          <Select value={value.cpuUnit || cpuUnit} style={{ width: 100, margin: '0 8px' }} onChange={onCpuUnitChange}>
            <Option value="核">核</Option>
            {isEnableFullOption && <Option value="m">m</Option>}
          </Select>
        </Row>
        <div className={styles['tip-user-cpu']}>
          <Text className={styles['tip-user-cpu-text']}>1核=1000m</Text>
        </div>
      </Row>
      <Row className={styles['single-row']}>
        <Text className={styles['tip-text']}>内存:</Text>
        <Input
          className={styles.memory}
          type="number"
          value={value.memory || memory}
          onChange={onMemoryChange}
          style={{ width: 248 }}
        />
        <Select
          value={value.memoryUnit || memoryUnit}
          style={{ width: 100, margin: '0 8px' }}
          onChange={onMemoryUnitChange}>
          <Option value={IMemoryUnit.GB}>GB</Option>
          {isEnableFullOption && <Option value={IMemoryUnit.MB}>MB</Option>}
        </Select>
      </Row>
      <Row className={styles['single-row']}>
        <Text className={cx('tip-text')}>GPU:</Text>
        <Select className={styles.gpu} value={value.gpu || gpu} style={{ width: 248 }} onChange={onGpuChange}>
          <Option value={0}>0卡</Option>
          <Option value={0.25}>0.25卡</Option>
          <Option value={0.5}>0.5卡</Option>
          <Option value={0.75}>0.75卡</Option>
          <Option value={1}>1卡</Option>
          <Option value={2}>2卡</Option>
          <Option value={3}>3卡</Option>
          <Option value={4}>4卡</Option>
          <Option value={5}>5卡</Option>
          <Option value={6}>6卡</Option>
          <Option value={7}>7卡</Option>
          <Option value={8}>8卡</Option>
        </Select>
      </Row>
    </div>
  );
};

export default ResourceConfig;

import { gql } from '@apollo/client';
import { SERVICE_FRAGMENT } from '@/service/schema/service/service-queries';

export const PROJECT_FRAGMENT = gql`
  fragment ProjectFragment on Project {
    id
    name
    identifier
    description
    clientRepoGitUrl
    customRepoGitUrl
    serviceRepoGitUrl
    baseUrl
    creator
    updater
    config
    createdTime
    updatedTime
    status
    resource
    iconUrl
    tag
    nameList
    includePage
    tags {
      tagId
      tagName
    }
    versionInfo {
      version
      description
      updateTime
    }
    industry {
      industryId
      industryName
    }
    contactInfo
    clientSampleGitUrl
    services {
      ...ServiceFragment
    }
    imageAddr
    isTerminal
    aiProjectId
    isAiAvailable
    danluAccessKey
    danluSecretKey
  }
  ${SERVICE_FRAGMENT}
`;

const RELEASE_INFO_FRAGMENT = gql`
  fragment ReleaseInfoFragment on ReleaseInfo {
    id
    serviceId
    pipelineId
    status
  }
`;

export const PROJECTS_FRAGMENT = gql`
  fragment ProjectsFragment on PageResultProject {
    items {
      ...ProjectFragment
    }
    total
  }
  ${PROJECT_FRAGMENT}
`;

export const GET_USERS = gql`
  query GetUsers {
    users {
      email
      name
    }
  }
`;

export const GET_PROJECTS = gql`
  query GetProjects {
    projects {
      ...ProjectsFragment
    }
  }
  ${PROJECTS_FRAGMENT}
`;

export const GET_PROJECT = gql`
  query GetProject($projectId: ID!) {
    project(projectId: $projectId) {
      includeClientSample
      ...ProjectFragment
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const CHECK_IDENTIFIER = gql`
  query CheckIdentifier($identifier: String!) {
    projectIdentifier(identifier: $identifier)
  }
`;

export const GET_PROJECT_USERS = gql`
  query GetProject($projectId: ID!) {
    project(projectId: $projectId) {
      users {
        email
        name
        fullName
        roles
      }
    }
  }
`;

// 查询项目发布状态 releaseId 在发布接口中获取
export const GET_RELEASE_INFO = gql`
  query GetReleaseInfo($releaseId: ID!) {
    release(releaseId: $releaseId) {
      ...ReleaseInfoFragment
    }
  }
  ${RELEASE_INFO_FRAGMENT}
`;

// 查询 commit tag, 不传 tag 默认返回全部提交记录。可输入 tag 进行模糊查询。如果传 serviceId 则是下载 sdk 时对版本的查询，不传 serviceId 是发布时的版本查询
export const GET_COMMIT_TAGS = gql`
  query GetCommitTags($projectId: ID!, $serviceId: ID, $tag: String) {
    commitTags(projectId: $projectId, serviceId: $serviceId, tag: $tag)
  }
`;

// 模板的tags
export const GET_PROJECT_README = gql`
  query TemplateReadme($templateId: ID!) {
    templateReadme(templateId: $templateId)
  }
`;

// 查询项目是否存在客户端  接口独立 优化性能
export const PROJECT_HAS_CLIENT_SAMPLE = gql`
  query ProjectHasClientSample($projectId: ID!) {
    projectHasClientSample(projectId: $projectId)
  }
`;
